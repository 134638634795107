require("react-native");
var io = require("socket.io-client");

export function createSocket(serverURL: string, options: any) {
  return io(serverURL, {
    transports: ["websocket"],
    autoConnect: false,
    ...options,
  });
}
