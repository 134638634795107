import React from "react";
import { cancelFileUpload, uploadFile } from "../Server";
import ImageUploaderUI from "./ImageUploaderUI";
import { UploadStatusType } from "../Utils/FileUtils";
import ImageUploaderUINative from "./ImageUploaderUINative";

type Props = {
  isVisible;
  isMobile;
  onImageUploaded;
  descriptionText;
  style;
  isUINative?: boolean;
};

type State = {
  uploadStatus;
};

export default class ImageUploader extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      uploadStatus: UploadStatusType.ready,
    };
  }

  uploadFileToServer(file) {
    uploadFile(
      file,
      (res) => {
        this.updateUploadStatus(UploadStatusType.ready);
        this.props.onImageUploaded(file);
      },
      (err) => {
        this.updateUploadStatus(UploadStatusType.networkError);
      },
      () => {
        this.updateUploadStatus(UploadStatusType.ready);
      }
    );
  }

  cancelServerFileUpload() {
    if (this.state.uploadStatus == UploadStatusType.uploading) {
      cancelFileUpload();
    }
  }

  updateUploadStatus(newStatus: UploadStatusType) {
    if (this.state.uploadStatus != newStatus) {
      this.setState({ uploadStatus: newStatus });
    }
  }

  render() {
    if (!this.props.isVisible) {
      return null;
    }
    if (this.props.isUINative) {
      return (
        <ImageUploaderUINative
          descriptionText={this.props.descriptionText}
          uploadFile={this.uploadFileToServer.bind(this)}
          cancelUpload={this.cancelServerFileUpload.bind(this)}
          uploadStatus={this.state.uploadStatus}
          updateUploadStatus={this.updateUploadStatus.bind(this)}
          isMobile={this.props.isMobile}
        />
      );
    }
    return (
      <ImageUploaderUI
        descriptionText={this.props.descriptionText}
        style={this.props.style}
        uploadFile={this.uploadFileToServer.bind(this)}
        cancelUpload={this.cancelServerFileUpload.bind(this)}
        uploadStatus={this.state.uploadStatus}
        updateUploadStatus={this.updateUploadStatus.bind(this)}
        isMobile={this.props.isMobile}
      />
    );
  }
}
