import React from "react";
import { View } from "react-native";
import { useState } from "react";

const ViewPager = React.forwardRef((props: any, ref) => {
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(
    props.initialPage
  );
  return (
    <View style={props.style}>
      {props.children.filter((page, index) => index == currentPageIndex)}
    </View>
  );
});

export { ViewPager };
