import { defaultSubjectLevels, defaultSubjects } from "../Models/Subjects";

const initialState = {
  subjects: defaultSubjects,
  subjectLevels: defaultSubjectLevels,
  photo: null,
  comment: null,
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SUBJECTS":
      return {
        ...state,
        subjects: action.subjects,
      };
    case "SET_SUBJECT_LEVELS":
      return {
        ...state,
        subjectLevels: action.subjectLevels,
      };
    default:
      return state;
  }
};
export default infoReducer;

export const setSubjects = (subjects) => ({
  type: "SET_SUBJECTS",
  subjects: subjects,
});
export const setSubjectLevels = (subjectLevels) => ({
  type: "SET_SUBJECT_LEVELS",
  subjectLevels: subjectLevels,
});
