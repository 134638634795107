import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import StyleUtils, {
  COLOR_TRUE_WHITE,
  COLOR_MUTED,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import { analytics } from "../Analytics";

type Props = {
  show;
  close;
  question;
  areas;
  level;
  width;
  schoolYear;
  index;
};

type State = {
  selectedSingle: string | null;
  modifiedAreas;
};

export default class MultipleChoicePopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSingle: null,
      modifiedAreas: [],
    };
  }

  componentDidMount() {
    if (this.props.level === 1 && this.props.question.includes("série")) {
      this.setState({ modifiedAreas: this.props.areas.slice(0, 3) });
    } else {
      this.setState({ modifiedAreas: this.props.areas });
    }
    if (this.props.question.includes("idade")) {
      if (this.props.level === 0) {
        let age = 4 + this.props.schoolYear;
        let areas = [
          {
            id: 0,
            name: age + " anos",
          },
          {
            id: 1,
            name: age + 1 + " anos",
          },
          {
            id: 2,
            name: age + 2 + " anos",
          },
          {
            id: 3,
            name: age + 3 + " anos",
          },
          {
            id: 4,
            name: age + 4 + " anos",
          },
        ];
        this.setState({ modifiedAreas: areas });
      } else {
        let age = 13 + this.props.schoolYear;
        let areas = [
          {
            id: 0,
            name: age + " anos",
          },
          {
            id: 1,
            name: age + 1 + " anos",
          },
          {
            id: 2,
            name: age + 2 + " anos",
          },
          {
            id: 3,
            name: age + 3 + " anos",
          },
          {
            id: 4,
            name: age + 4 + " anos",
          },
        ];
        this.setState({ modifiedAreas: areas });
      }
    }
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
    }
    analytics.sendStudyPlanQuestion(
      this.props.question,
      item.name,
      this.props.index
    );
    setTimeout(() => {
      this.props.close(item);
    }, 600);
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;

    return (
      <TouchableOpacity
        key={item.id}
        style={[
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: 12,
            height: 67,
            width:
              this.props.width !== 784 && this.props.question.includes("série")
                ? 142
                : 220,
            borderRadius: 6,
            alignItems: "center",
            flexDirection: "row",
            marginVertical: 8,
            justifyContent: "center",
            flexWrap: "wrap",
            alignContent: "center",
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text style={[styles.cellText]}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  renderMobileLayout() {
    return (
      <View>
        <View
          style={[
            styles.modalView,
            {
              width: this.props.width,
            },
          ]}
        >
          <Text style={styles.modalTextHeader}>{this.props.question}</Text>
          {!this.props.question.includes("série") ? (
            <>
              <View style={styles.containerCenterColumn}>
                {this.state.modifiedAreas.map((area) =>
                  this.renderLevelCell(area)
                )}
              </View>
            </>
          ) : (
            <>
              <View style={styles.containerCenter}>
                {this.state.modifiedAreas
                  .slice(0, 2)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.state.modifiedAreas
                  .slice(2, 4)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.state.modifiedAreas
                  .slice(4, 6)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.state.modifiedAreas
                  .slice(6, 8)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.state.modifiedAreas
                  .slice(8)
                  .map((area) => this.renderLevelCell(area))}
              </View>
            </>
          )}
        </View>
      </View>
    );
  }

  renderDesktopLayout() {
    return (
      <View>
        <View
          style={[
            styles.modalView,
            {
              width: this.props.width,
            },
          ]}
        >
          <Text style={styles.modalTextHeader}>{this.props.question}</Text>

          <View style={styles.containerCenter}>
            {this.state.modifiedAreas
              .slice(0, 3)
              .map((area) => this.renderLevelCell(area))}
          </View>
          {this.state.modifiedAreas.length > 3 && (
            <View style={styles.containerCenter}>
              {this.state.modifiedAreas
                .slice(3, 6)
                .map((area) => this.renderLevelCell(area))}
            </View>
          )}
          {this.state.modifiedAreas.length > 6 && (
            <View style={styles.containerCenter}>
              {this.state.modifiedAreas
                .slice(6)
                .map((area) => this.renderLevelCell(area))}
            </View>
          )}
        </View>
      </View>
    );
  }

  render() {
    return this.props.width < 500
      ? this.renderMobileLayout()
      : this.renderDesktopLayout();
  }
}

const styles = StyleSheet.create({
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 30,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    zIndex: 1,
  },
  modalTextHeader: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    textAlign: "center",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 25,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  containerCenterColumn: {
    justifyContent: "center",
    alignItems: "center",
  },
});
