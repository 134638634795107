import React, { useRef, useEffect, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  Animated,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import VideoComponent from "../Elements/VideoElement";
import StyleUtils from "../Utils/StyleUtils";

type Props = {
  searching?: boolean;
  onVideoEnded?: () => void;
  isLowFriction?: boolean;
  loadingDescription;
};

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void | null>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback.current !== "undefined") {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function TutorMatchSearching(props: Props) {
  let { width, height } = Dimensions.get("window");
  height = Math.floor((667 * width) / 750);

  const [searching, setSearching] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  let animation = useRef(new Animated.Value(0));
  const [progress, setProgress] = useState(0);
  useInterval(() => {
    if (progress < 100) {
      setProgress(progress + 1);
    }
  }, 35);

  useEffect(() => {
    Animated.timing(animation.current, {
      toValue: progress,
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [progress]);

  const widthBar = animation.current.interpolate({
    inputRange: [0, 100],
    outputRange: ["0%", "100%"],
    extrapolate: "clamp",
  });

  useEffect(() => {
    const handle = setTimeout(() => {
      props.onVideoEnded && props.onVideoEnded();
    }, 15000);
    return () => clearTimeout(handle);
  }, []);
  useEffect(() => {
    if (!playing) {
      return;
    }
    const handle = setTimeout(() => setSearching(false), 4500);
    return () => clearTimeout(handle);
  }, [playing]);
  useEffect(() => {
    if (Platform.OS === "android") {
      const handle = setTimeout(() => setLoading(false), 900);
      return () => clearTimeout(handle);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <SafeAreaView
      style={[StyleUtils.screen_default, { backgroundColor: "#0D9AFF" }]}
    >
      <View style={{ alignItems: "center", height: "100%" }}>
        <View style={{ height: "15%" }} />
        {loading && (
          <Image
            source={require("../../assets/loadingWait.png")}
            style={{ height: "104%" }}
          />
        )}
        <VideoComponent
          style={{
            width: width,
            height: height,
            backgroundColor: "#0D9AFF",
          }}
          source={require("../../assets/loading.mp4")}
          onPlaying={() => setPlaying(true)}
          onEnded={props.onVideoEnded}
        />
        {searching && (
          <>
            <Text
              style={[
                styles.text,
                {
                  marginTop: 6,
                  height: 24,
                },
              ]}
            >
              Aguarde um momento
            </Text>
            <Text
              style={[
                styles.textLarge,
                {
                  marginTop: 6,
                },
              ]}
            >
              {props.loadingDescription}
            </Text>
          </>
        )}
        {!searching && (
          <Text
            style={[
              styles.textLarge,
              {
                marginTop: 30,
              },
            ]}
          >
            Busca finalizada
          </Text>
        )}
        {!!props.isLowFriction && (
          <View style={styles.container}>
            <View style={styles.progressBar}>
              <Animated.View
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: "#50D7A7",
                  width: widthBar,
                  borderRadius: 50,
                }}
              />
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  textLarge: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    textAlign: "center",
  },
  text: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary_light),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
  container: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    width: "80%",
    backgroundColor: "#ecf0f1",
    borderRadius: 50,
    margin: 24,
  },
  progressBar: {
    flexDirection: "row",
    height: 20,
    width: "100%",
    borderRadius: 50,
  },
});
