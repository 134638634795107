import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import Exercise from "../../assets/exercise.svg";
import CheckWhite from "../../assets/checkWhite.svg";
import Clock from "../../assets/clock.svg";
import Homework, {
  homeworkStats,
  homeworkState,
  HomeworkState,
} from "../Models/Homework";

type Props = {
  mobile?: boolean;
  homework: Homework;
  isTutor?: boolean;
  onPress?: () => void;
};

const HomeworkCard = (props: Props) => {
  const hmState = homeworkState(props.homework);
  const stats = homeworkStats(props.homework);
  //console.log(stats);
  const user = props.isTutor ? props.homework.tutee : props.homework.tutor;

  let pendingNotification: number = stats.pendingCorrection;
  let showPendingNumber = hmState == HomeworkState.ANSWERED;
  let pendingNumber: number = stats.pendingCorrection;
  let showClockIcon: boolean = hmState == HomeworkState.SUBMITTED;
  let showUpperMessage = false;
  let upperMessage = "Correções disponíveis";
  let answeredAllCorrected = hmState == HomeworkState.CORRECTED;

  if (props.homework.assignments) {
    props.homework.assignments.forEach((assignment) => {
      if (
        assignment.exercise &&
        assignment.tuteeAnswer != assignment.exercise.correctAnswer
      ) {
        answeredAllCorrected = false;
      }
    });
  }

  let showCheckIcon: boolean = hmState >= HomeworkState.CORRECTED;

  if (!props.isTutor) {
    pendingNotification = stats.pending;
    showPendingNumber = hmState == HomeworkState.SUBMITTED;
    pendingNumber = stats.pending;
    showClockIcon = hmState == HomeworkState.ANSWERED;
    showUpperMessage =
      hmState == HomeworkState.CORRECTED && !answeredAllCorrected;
    showCheckIcon = hmState >= HomeworkState.COMPLETED || answeredAllCorrected;
  }

  return (
    <HomeworkContainer
      onPress={props.onPress}
      color={"white"}
      pending={pendingNotification}
      showUpperMessage={showUpperMessage}
      showClockIcon={showClockIcon}
      showCheckIcon={showCheckIcon}
      upperMessage={upperMessage}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_light,
              StyleUtils.font_size_14,
              { marginBottom: 3 },
            ]}
          >
            {user && user.name}
          </Text>
          <View
            style={{
              flexDirection: "row",
              minHeight: 20,
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
              ]}
            >
              {props.homework.name || ""}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {props.homework.createdAt && (
            <Text
              style={[
                StyleUtils.font_btn_regular,
                StyleUtils.color_txt_light,
                StyleUtils.font_size_14,
              ]}
            >
              {new Date(props.homework.createdAt).toLocaleDateString("pt-br", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Text>
          )}
          <View style={{ minHeight: 20 }}>
            {showPendingNumber && (
              <View
                style={[
                  StyleUtils.shape_btn_default,
                  StyleUtils.color_bg_primary,
                  { width: 32, alignSelf: "flex-end", marginTop: 7 },
                ]}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_white,
                    { alignSelf: "center" },
                  ]}
                >
                  {pendingNumber}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </HomeworkContainer>
  );
};

const HomeworkContainer = (props: {
  onPress?: () => void;
  children?: any;
  color?: "primary" | "white";
  pending?: number;
  showUpperMessage: boolean;
  showCheckIcon: boolean;
  upperMessage: string;
  showClockIcon: boolean;
}) => {
  return (
    <TouchableOpacity
      style={[
        StyleUtils.color_bg_white,
        {
          flexDirection: "column",
          marginHorizontal: 24,
          borderColor: COLOR_MUTED,
          borderBottomWidth: 1,
        },
      ]}
      onPress={props.onPress}
    >
      {props.showUpperMessage && (
        <View
          style={[
            StyleUtils.color_bg_light_icon,
            StyleUtils.color_bg_primarylight,
            StyleUtils.shape_btn_default,
            {
              alignItems: "center",
              alignSelf: "flex-start",
              marginVertical: 8,
            },
          ]}
        >
          <Text
            style={[
              StyleUtils.font_btn_bold,
              StyleUtils.color_txt_white,
              StyleUtils.font_size_14,
              StyleUtils.color_txt_primary,
              { marginHorizontal: 8, marginVertical: 3 },
            ]}
          >
            {props.upperMessage}
          </Text>
        </View>
      )}
      <View
        style={[
          {
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
          },
        ]}
      >
        <View style={[!props.showUpperMessage && { alignSelf: "center" }]}>
          <View
            style={[
              StyleUtils.color_bg_light,
              { height: 40, width: 40, borderRadius: 6 },
            ]}
          >
            <SVGImage source={<Exercise />} />
          </View>
          {props.showClockIcon && <ClockIcon />}
          {props.showCheckIcon && <Check />}
        </View>
        <View
          style={[
            {
              flex: 1,
              padding: 23,
            },
            props.showUpperMessage && {
              paddingTop: 0,
            },
          ]}
        >
          {props.children}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const Check = () => {
  return (
    <View
      style={[
        StyleUtils.color_bg_primary,
        {
          position: "absolute",
          top: -5,
          left: 27,
          height: 18,
          width: 18,
          borderRadius: 9,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        },
      ]}
    >
      <SVGImage source={<CheckWhite />} />
    </View>
  );
};

const ClockIcon = () => {
  return (
    <View
      style={[
        StyleUtils.color_btn_primary,
        {
          position: "absolute",
          top: -5,
          left: 27,
          height: 18,
          width: 18,
          borderRadius: 9,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        },
      ]}
    >
      <SVGImage source={<Clock />} />
    </View>
  );
};

export default HomeworkCard;
