import React, { Component } from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import StyleUtils, { COLOR_DANGER } from "../Utils/StyleUtils";
import { SCREEN_SIZE_600 } from "../Utils/SizeUtils";
import SVGImage from "../Utils/SVGImage";
import Calendar from "../../assets/calendar.svg";
import { userShortName } from "../Utils/Utils";
import { selectTutor, setCounselorNewMessages } from "./../Actions";
import { getTutor } from "../Server";
import Counselor from "../../assets/iconVocation.svg";

type Props = {
  user;
  onPress;
  cellStyle;
  separatorStyle;
  history;
  selectTutor;
  userID;
  Icon?;
  unreadMessageCounselor?: boolean;
  dispatchSetCounselorNewMessages;
};

type State = {
  unreadMessages;
  compactLayout;
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
    unreadMessageCounselor: state.userRed.unreadMessageCounselor,
  };
}
const mapDispatchToProps = (dispatch) => ({
  selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
  dispatchSetCounselorNewMessages: (value) =>
    dispatch(setCounselorNewMessages(value)),
});

const tutoreId = "4c8eed66-0c37-49ed-a5d8-b575487c0fa1";

class MessengerUser extends Component<Props, State> {
  constructor(props) {
    super(props);
    const refDate = new Date();
    refDate.setHours(0, 0, 0, 0);
    this.state = {
      unreadMessages: 0,
      compactLayout: true,
    };
  }
  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.chatData &&
      this.props.user.chatData.unreadMessageCount > 0
    ) {
      this.setState({
        unreadMessages: this.props.user.chatData.unreadMessageCount,
      });
    }
  }
  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let isMobileLayout = layout.width < SCREEN_SIZE_600;
    if (isMobileLayout != this.state.compactLayout) {
      this.setState({
        compactLayout: isMobileLayout,
      });
    }
  };
  renderUserIcon(): React.ReactNode {
    return (
      <View
        style={{
          height: 40,
          width: 40,
          borderRadius: 6,
          overflow: "hidden",
        }}
      >
        {!!this.props.user.imageURL && (
          <Image
            source={{
              uri: this.props.user.imageURL,
            }}
            style={{
              backgroundColor: "#D1D1D1",
              width: "100%",
              height: "100%",
            }}
          />
        )}
        {!this.props.user.imageURL && (
          <Image
            source={require("../../assets/userPlaceholderSquare.png")}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </View>
    );
  }

  renderUserName(): React.ReactNode {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={[
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_16,
              {
                marginLeft: 13,
                alignSelf: "center",
              },
            ]}
          >
            {userShortName(this.props.user.name) +
              (this.props.user.isCounselor ? " (Orientador)" : "")}
          </Text>
          {(this.state.unreadMessages > 0 ||
            (this.props.user.isCounselor &&
              this.props.unreadMessageCounselor)) && (
            <View
              style={{
                backgroundColor: COLOR_DANGER,
                height: 10,
                width: 10,
                borderRadius: 1000,
                marginLeft: 4,
                marginBottom: 16,
              }}
            />
          )}
        </View>
      </>
    );
  }

  render() {
    return (
      <>
        <View
          style={[
            this.props.cellStyle,
            {
              marginTop: 22,
            },
          ]}
          onLayout={this.onLayout}
        >
          <TouchableOpacity
            onPress={() => {
              if (this.props.user.isCounselor) {
                this.props.dispatchSetCounselorNewMessages(false);
              }
              this.props.onPress(this.props.user);
            }}
          >
            <View
              style={{
                flexDirection: "row",
                marginLeft: 24,
              }}
            >
              {this.renderUserIcon()}
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {this.renderUserName()}
              </View>
            </View>
          </TouchableOpacity>
          {!!this.state.compactLayout && this.props.user.id !== tutoreId && (
            <TouchableOpacity
              style={{
                position: "absolute",
                right: 24,
              }}
              onPress={() => {
                getTutor(this.props.userID, this.props.user.id)
                  .then((t) => {
                    this.props.selectTutor(t);
                    this.props.history.push("/booking", {
                      source: "chat_messenger",
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <SVGImage
                style={{ marginRight: this.props.user.isCounselor ? -7 : 0 }}
                source={
                  this.props.user.isCounselor ? <Counselor /> : <Calendar />
                }
              />
            </TouchableOpacity>
          )}
          <View style={this.props.separatorStyle}></View>
        </View>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessengerUser);
