import React from "react";
import { Text, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { LinearGradient } from "../Utils/LinearGradient";
import SearchLevelSubjectBar from "./SearchLevelSubjectBar";
import { createShimmerPlaceholder } from "react-native-shimmer-placeholder";
import { HeaderBackGround } from "./HeaderMatchList";
const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

const TutorMatchLoadingState = ({ filter, onFilterChanged }) => {
  return (
    <View style={{ flex: 1 }}>
      <HeaderBackGround />
      <View
        style={{
          alignSelf: "stretch",
          alignItems: "center",
        }}
      >
        <View style={{ position: "absolute", zIndex: 10, width: "100%" }}>
          <SearchLevelSubjectBar
            filter={filter}
            onFilterChanged={onFilterChanged}
          />
        </View>
        <View
          style={{
            marginTop: 72,
            marginHorizontal: 24,
            flex: 1,
            alignItems: "center",
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_white,
              StyleUtils.font_size_22,
              { marginVertical: 16 },
            ]}
          >
            Professor Recomendado
          </Text>
          <ShimmerPlaceHolder
            style={[
              StyleUtils.shape_card_default,
              { width: 279, height: 400, marginTop: 32 },
            ]}
          />
        </View>
      </View>
      <View
        style={{
          alignItems: "center",
          padding: 24,
        }}
      />
    </View>
  );
};
export default TutorMatchLoadingState;
