import { EventEmitter } from "events";
import { createSockeckIO } from "../Server";

const lobbySocket = createSockeckIO({ namespace: "/lobby" });

export interface LobbyUser {
  id: string;
}
interface LobbyConnectionEvents {
  userListUpdate: (userList: Array<LobbyUser>) => void;
}
class TutorLobbyConnection {
  socket;
  emitter: EventEmitter;
  tuteesInLobby: Array<LobbyUser>;

  constructor() {
    this.socket = lobbySocket;
    this.emitter = new EventEmitter();
    this.tuteesInLobby = [];
  }

  open() {
    this.socket.on("connect", this.onConnect);
    this.socket.on("disconnect", this.onDisconnect);
    this.socket.on("lobbyConnectedUsers", this.connectedList);
    this.socket.on("lobbyJoin", this.onLobbyJoin);
    this.socket.on("lobbyLeave", this.onLobbyLeave);
    this.socket.open();
  }
  close() {
    this.socket.close();
    this.socket.removeListener("connect", this.onConnect);
    this.socket.removeListener("disconnect", this.onDisconnect);
    this.socket.removeListener("lobbyConnectedUsers", this.connectedList);
    this.socket.removeListener("lobbyJoin", this.onLobbyJoin);
    this.socket.removeListener("lobbyLeave", this.onLobbyLeave);
  }

  joinQuickClass(eventData) {
    this.socket.emit("joinQuickClass", eventData);
  }

  refresh() {
    this.tuteesInLobby = [];
    this.socket.emit("lobbyPing");
  }

  private onConnect = () => {
    console.log(`lobby/connect`);
    this.socket.emit("lobbyTutorJoin");
  };

  private onDisconnect = () => {
    console.log(`disconnect`);
  };

  private connectedList = (clientList) => {
    console.log(`/lobby/connectedList`);
    console.log(clientList);
    var array = Object.keys(clientList);
    // this.setState({ clientList: array });
  };

  private onLobbyJoin = (lobbyUser?: LobbyUser) => {
    if (lobbyUser) {
      console.log(lobbyUser);
      const users = this.tuteesInLobby.filter((u) => u.id != lobbyUser.id);
      users.push(lobbyUser);
      this.updateList(users);
    }
  };

  private onLobbyLeave = (lobbyUser?: LobbyUser) => {
    if (lobbyUser) {
      console.log(lobbyUser);
      this.updateList(this.tuteesInLobby.filter((u) => u.id != lobbyUser.id));
    }
  };
  private updateList(userList: Array<LobbyUser>) {
    console.log(userList);
    this.tuteesInLobby = userList;
    this.emit("userListUpdate", userList);
  }

  on<U extends keyof LobbyConnectionEvents>(
    eventName: U,
    listener: LobbyConnectionEvents[U]
  ): this {
    this.emitter.on(eventName, listener);
    return this;
  }

  private emit<U extends keyof LobbyConnectionEvents>(
    eventName: U,
    ...args: Parameters<LobbyConnectionEvents[U]>
  ): boolean {
    this.emitter.emit(eventName, ...args);
    return true;
  }

  removeListener<U extends keyof LobbyConnectionEvents>(
    eventName: U,
    listener: LobbyConnectionEvents[U]
  ): this {
    this.emitter.removeListener(eventName, listener);
    return this;
  }
}

export const tutorLobbyConnection = new TutorLobbyConnection();

interface TuteeLobbyConnectionEvents {
  joinQuickClass: (info: any) => void;
}

class TuteeLobbyConnection {
  socket;
  emitter: EventEmitter;
  tutorId;

  constructor() {
    this.socket = lobbySocket;
    this.emitter = new EventEmitter();
    this.tutorId = "";
  }

  open(tutorId) {
    this.tutorId = tutorId;
    this.socket.on("connect", this.onConnect);
    this.socket.on("disconnect", this.onDisconnect);
    this.socket.on("lobbyPing", this.onPing);
    this.socket.on("joinQuickClass", this.onJoinQuickClass);
    this.socket.open();
  }
  close() {
    console.log("close");
    this.socket.emit("lobbyLeave");
    this.socket.close();
    this.socket.removeListener("connect", this.onConnect);
    this.socket.removeListener("disconnect", this.onDisconnect);
    this.socket.removeListener("lobbyPing", this.onPing);
    this.socket.removeListener("joinQuickClass", this.onJoinQuickClass);
  }

  private onConnect = () => {
    console.log(`lobby/connect`);
    this.socket.emit("lobbyJoin", {
      tutorId: this.tutorId,
    });
  };

  private onDisconnect = () => {
    console.log(`disconnect`);
  };

  private onPing = (message) => {
    const { to } = message;
    this.socket.emit("lobbyPong", { to });
  };

  private onJoinQuickClass = (info) => {
    this.emit("joinQuickClass", info);
  };

  on<U extends keyof TuteeLobbyConnectionEvents>(
    eventName: U,
    listener: TuteeLobbyConnectionEvents[U]
  ): this {
    this.emitter.on(eventName, listener);
    return this;
  }

  private emit<U extends keyof TuteeLobbyConnectionEvents>(
    eventName: U,
    ...args: Parameters<TuteeLobbyConnectionEvents[U]>
  ): boolean {
    this.emitter.emit(eventName, ...args);
    return true;
  }

  removeListener<U extends keyof TuteeLobbyConnectionEvents>(
    eventName: U,
    listener: TuteeLobbyConnectionEvents[U]
  ): this {
    this.emitter.removeListener(eventName, listener);
    return this;
  }
}
export const tuteeLobbyConnection = new TuteeLobbyConnection();
