import React from "react";
import Card from "./Card";
import ExerciseBadge from "../../../assets/exerciseBadge.svg";
import * as Utils from "../../Utils/Utils";

type Props = {
  mobile?: boolean;
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  numberOfCorrectedExercises: number;
  numberOfPendingExercises: number;
  onPress?: () => void;
};

const ExercisesTuteeCard = (props: Props) => {
  const {
    numberOfCorrectedExercises,
    numberOfPendingExercises,
    ...other
  } = props;
  let exercises = numberOfCorrectedExercises + numberOfPendingExercises;
  if (exercises > 99) {
    exercises = 99;
  }

  return (
    <Card
      {...other}
      badge={<ExerciseBadge />}
      text="Exercícios"
      description={`${Utils.formatPositiveInteger(
        numberOfCorrectedExercises,
        2
      )} corrigidos e ${Utils.formatPositiveInteger(
        numberOfPendingExercises,
        2
      )} pendentes`}
      buttonText="Concluir exercícios"
      notification={exercises > 0 ? "" + exercises : undefined}
    ></Card>
  );
};

export default ExercisesTuteeCard;
