import React, { Component } from "react";
import {
  BackHandler,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import { analytics } from "../Analytics";
import Canvas2 from "../Canvas/Canvas2";
import { CanvasConnection } from "../Canvas/CanvasConnection";
import ChatConnection from "../Chat/ChatConnection";
import ChatHolder from "../Chat/ChatHolder";
import ButtonElement from "../Elements/ButtonElement";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import KeepAwake from "../Utils/KeepAwake";
import StyleUtils, { COLOR_DANGER } from "../Utils/StyleUtils";
import VideoConnection from "../Video/VideoConnection";
import VideoHolder, { VideoMode } from "../Video/VideoHolder";
import { ClassroomTimer } from "./ClassroomComponents";
import { classroomTimer } from "./ClassroomTimer";
import { eventUpdate, getTutor } from "../Server";
import { selectTutor } from "./../Actions";
import Booking from "./../Booking/Booking";
import { EventsFilter, getEvents } from "../Server";
import ConfirmationPage from "../Payment/ConfirmationPage";

import { useToast } from "react-native-toast-notifications";
import { getChannelName } from "../Utils/Chat";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToast();
    return <Component {...props} toast={toastFuncs} />;
  };
}

type Props = {
  selectedEvent;
  currentUser;
  userWithoutLogin;
  selectedUser;
  history;
  tutorInfo;
  selectTutor;
  toast;
};

type State = {
  backHandler;
  mobile;
  chat_opened;
  whiteboard_opened;
  video_hidden;
  videoConnection: VideoConnection;
  chatConnection?: ChatConnection;
  canvasConnection: CanvasConnection;
  cameraPermissionShown;
  closePopupShow;
  showClassEndingPopup?: boolean;
  showClassEndedPopup?: boolean;
  booking_opened;
  booked_class;
  confirmation_opened;
  newMessage;
  whiteboardChanged;
};

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    userWithoutLogin: state.userRed.userWithoutLogin,
    selectedUser: state.selRed.selectedUser,
    selectedEvent: state.selRed.selectedEvent,
    tutorInfo: state.userRed.tutorInfo,
  };
};

const dispatchToProps = (dispatch) => ({
  selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
});

class Classroom extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const channel = getChannelName(props.currentUser, props.selectedUser);
    const canvasConnection = props.selectedEvent.quickclass
      ? undefined
      : new ChatConnection(
          props.currentUser,
          props.selectedUser,
          channel,
          props.toast
        );
    this.state = {
      mobile: true,
      chat_opened: false,
      whiteboard_opened: false,
      video_hidden: false,
      newMessage: false,
      videoConnection: new VideoConnection(props.selectedEvent.id),
      chatConnection: canvasConnection,
      canvasConnection: new CanvasConnection(props.selectedEvent.id),
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      cameraPermissionShown: false,
      closePopupShow: false,
      booking_opened: false,
      booked_class: false,
      confirmation_opened: false,
      whiteboardChanged: false,
    };
    eventUpdate(props.selectedEvent.id, 1);
  }

  componentDidMount() {
    this.state.canvasConnection.open();
    if (this.state.chatConnection) this.state.chatConnection.classMount();
    if (this.props.selectedEvent.startDate)
      classroomTimer.start(this.props.selectedEvent);
    classroomTimer.on("timeEnding", this.onTimeEnding);
    classroomTimer.on("classEnded", this.onClassEnded);
    analytics.sendClassEnter();
    if (!this.props.tutorInfo) {
      analytics.sendClassStartedStudent();
    }
    getTutor(this.props.currentUser.id, this.props.selectedUser.id)
      .then((t) => {
        this.props.selectTutor(t);
      })
      .catch((err) => {
        console.log(err);
      });

    this.state.chatConnection &&
      this.state.chatConnection.client.on("channelMessageReceived", () => {
        this.setState({ newMessage: true });
      });
  }

  componentWillUnmount() {
    classroomTimer.removeListener("classEnded", this.onClassEnded);
    classroomTimer.removeListener("timeEnding", this.onTimeEnding);
    this.state.backHandler.remove();
    if (this.state.videoConnection) {
      this.state.videoConnection.classUnmount();
    }
    if (this.state.chatConnection) {
      this.state.chatConnection.classUnmount();
    }
    classroomTimer.end();
    this.state.canvasConnection.close();
  }

  backPress = () => {
    if (this.state.chat_opened) {
      this.setState({ chat_opened: false });
    } else if (this.state.whiteboard_opened) {
      this.setState({ whiteboard_opened: false });
    } else if (this.state.booking_opened) {
      this.setState({ booking_opened: false });
    } else if (this.state.confirmation_opened) {
      this.setState({ confirmation_opened: false });
    } else {
      this.setState({
        closePopupShow: true,
      });
    }
    return true;
  };

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let full = layout.width < 900;
    if (full != this.state.mobile) {
      this.setState({
        mobile: full,
        chat_opened: full ? this.state.chat_opened : false,
        whiteboard_opened: full ? this.state.whiteboard_opened : false,
        booking_opened: full ? this.state.booking_opened : false,
        confirmation_opened: full ? this.state.confirmation_opened : false,
      });
    }
  };

  exitClassroom = () => {
    if (this.props.tutorInfo) {
      this.props.history.push("classroom/endtutor");
      return;
    }

    if (this.props.currentUser) {
      this.fetchUpcomingEvent()
        .then(() => {
          if (this.state.booked_class) this.props.history.push("classroom/end");
          else {
            this.props.history.push({
              pathname: "/classroom/endschedule",
              state: {
                subject: this.props.selectedEvent.subject,
                subjectLevel: this.props.selectedEvent.subjectLevel,
              },
            });
          }
        })
        .catch(console.log);
    } else {
      this.props.history.push("/quickclass/end");
    }
  };

  onTimeEnding = () => {
    this.setState({ showClassEndingPopup: true });
  };

  onClassEnded = () => {
    this.setState({ showClassEndingPopup: false, showClassEndedPopup: true });
  };

  async fetchUpcomingEvent() {
    const now = new Date();

    var eventFilter: EventsFilter = {
      tutorId: this.props.selectedUser.id,
      tuteeId: this.props.currentUser.id,
      periodStart: now.toISOString(),
      periodEnd: undefined,
      limit: 1,
    };

    return getEvents(eventFilter).then((result: any) => {
      const ev = (result && result.length > 0 && result[0]) || null;
      this.setState({ booked_class: ev !== null });
    });
  }

  render() {
    const isMinimizedVideo =
      this.state.chat_opened ||
      this.state.whiteboard_opened ||
      this.state.booking_opened ||
      this.state.confirmation_opened;

    return (
      <View style={[StyleUtils.screen_default]}>
        <KeepAwake />
        <View style={{ flex: 1, overflow: "hidden" }} onLayout={this.onLayout}>
          {
            <Canvas2
              setWhiteboardChangedToTrue={() => {
                this.setState({ whiteboardChanged: true });
              }}
              canvasConnection={this.state.canvasConnection}
              userID={
                this.props.currentUser
                  ? this.props.currentUser.id
                  : this.props.userWithoutLogin.id
              }
              isTutor={!!this.props.tutorInfo}
              style={[
                (!this.state.mobile && !this.state.chat_opened) ||
                this.state.whiteboard_opened
                  ? null
                  : StyleUtils.hidden,
                StyleUtils.color_bg_whiteboard,
                this.state.mobile
                  ? style.whiteboard_mobile
                  : style.whiteboard_web,
              ]}
              mobile={this.state.mobile}
              closeWhiteboard={() => {
                this.setState({
                  whiteboard_opened: false,
                });
              }}
              openWhiteboard={() => {
                this.setState({ whiteboard_opened: true });
              }}
              hideVideo={(hide) => {
                console.log(hide);
                this.setState({ video_hidden: hide });
              }}
              selectedUser={this.props.selectedUser}
              channelID={this.props.selectedEvent.id}
              chatConnection={this.state.chatConnection}
            />
          }
          {!this.state.whiteboard_opened && (
            <View
              style={[
                this.state.mobile
                  ? style.videochat_full
                  : style.videochat_sidebar,
                { flexDirection: "column" },
              ]}
            >
              {(!this.state.mobile || this.state.chat_opened) &&
                this.state.chatConnection && (
                  <ChatHolder
                    mobile={this.state.mobile}
                    chatConnection={this.state.chatConnection}
                    style={style.chatHolder}
                    closeChat={() => {
                      this.setState({ chat_opened: false });
                    }}
                  />
                )}
            </View>
          )}
          {!!this.state.mobile && this.state.booking_opened && (
            <Booking
              mobile={this.state.mobile}
              closeBooking={() => {
                this.setState({ booking_opened: false });
              }}
              history={this.props.history}
              openConfirmation={() => {
                this.setState({ confirmation_opened: true });
              }}
            />
          )}
          {!!this.state.mobile && this.state.confirmation_opened && (
            <ConfirmationPage
              onBack={() =>
                this.setState({
                  confirmation_opened: false,
                  booking_opened: true,
                })
              }
              onBookingFinished={() =>
                this.setState({
                  confirmation_opened: false,
                  booking_opened: false,
                })
              }
              subject={this.props.selectedEvent.subject}
              subjectLevel={this.props.selectedEvent.subjectLevel}
            />
          )}
        </View>

        <VideoHolder
          videoConnection={this.state.videoConnection}
          videoMode={isMinimizedVideo ? VideoMode.minimized : VideoMode.mobile}
          style={[
            this.state.mobile
              ? style.videoHolder_mobile
              : style.videoHolder_web,
          ]}
          openChat={
            this.state.chatConnection
              ? () => {
                  if (this.state.chatConnection) {
                    this.state.chatConnection.classUnmount().then(() => {
                      const channel = getChannelName(
                        this.props.currentUser,
                        this.props.selectedUser
                      );
                      const connection = new ChatConnection(
                        this.props.currentUser,
                        this.props.selectedUser,
                        channel,
                        this.props.toast
                      );
                      connection.classMount();
                      this.setState({
                        chat_opened: true,
                        chatConnection: connection,
                      });
                    });
                  }
                }
              : undefined
          }
          whiteboardChanged={this.state.whiteboardChanged}
          setWhiteboardChangedToFalse={() => {
            this.setState({ whiteboardChanged: false });
          }}
          newChatMessage={this.state.newMessage}
          setNewMessageToFalse={() => {
            this.setState({ newMessage: false });
          }}
          openWhiteboard={() => {
            this.setState({ whiteboard_opened: true });
          }}
          mobile={this.state.mobile}
          exit={() => {
            this.setState({
              closePopupShow: true,
            });
          }}
          videoHidden={this.state.video_hidden}
          selectedUser={this.props.selectedUser}
          tutorInfo={this.props.tutorInfo}
          history={this.props.history}
          openBooking={() => {
            this.setState({ booking_opened: true });
          }}
        />

        <CameraPermissionPopup
          show={!this.state.cameraPermissionShown}
          close={() => {
            this.setState({
              cameraPermissionShown: true,
            });
            this.state.videoConnection.classMount(true, true);
          }}
          onAllow={() => {
            this.setState({
              cameraPermissionShown: true,
            });
            this.state.videoConnection.classMount(false, false);
          }}
          onDeny={() => {
            this.setState({
              cameraPermissionShown: true,
            });
            this.state.videoConnection.classMount(true, true);
          }}
        />
        <CloseClassPopup
          show={this.state.closePopupShow}
          close={() => {
            this.setState({
              closePopupShow: false,
            });
          }}
          onClose={() => {
            analytics.sendClassFinished();
            this.exitClassroom();
          }}
          onReturn={() => {
            this.setState({
              closePopupShow: false,
            });
          }}
        />
        <ClassEndingPopup
          show={this.state.showClassEndingPopup}
          onClose={() => this.setState({ showClassEndingPopup: false })}
        />
        <ClassEndedPopup
          eventId={this.props.selectedEvent.id}
          show={this.state.showClassEndedPopup}
          onClose={this.exitClassroom}
        />
      </View>
    );
  }
}
export default withToast(connect(stateToProps, dispatchToProps)(Classroom));

const style = StyleSheet.create({
  whiteboard_mobile: {
    flex: 1,
  },
  whiteboard_web: {
    flex: 1,
    marginRight: 336,
  },
  videochat_sidebar: {
    position: "absolute",
    width: 336,
    top: "50%",
    right: 0,
    bottom: 0,
    height: "50%",
  },
  videochat_full: {
    flex: 1,
  },
  videoHolder_mobile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
  videoHolder_web: {
    position: "absolute",
    width: 336,
    height: "50%",
    top: 0,
    right: 0,
  },
  chatHolder: {
    flex: 1,
  },
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});

const CameraPermissionPopup = ({ show, close, onAllow, onDeny }) => {
  return (
    <AnimatedPopUp show={show} close={close}>
      <Text
        style={[
          {
            marginTop: 0,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_dark,
        ]}
      >
        {"Por favor, ligue seu\nMicrofone e Câmera"}
      </Text>

      <ButtonElement
        colorScheme="primary"
        text="Ligar Microfone e Câmera"
        onPress={onAllow}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
      <TouchableOpacity style={[{ width: 252 }]} onPress={onDeny}>
        <Text
          style={[
            StyleUtils.color_txt_dark,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            {
              textAlign: "center",
              textDecorationLine: "underline",
              marginTop: 24,
            },
          ]}
        >
          {"Permanecer com microfone\ne câmera desligados"}
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};

const CloseClassPopup = ({ show, close, onClose, onReturn }) => {
  return (
    <AnimatedPopUp show={show} close={close} bgColor={COLOR_DANGER}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_white,
        ]}
      >
        {"Tem certeza que você\nquer sair da aula?"}
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_white,
        ]}
      >
        {"Você ainda poderá voltar dentro\ndo seu horário agendado"}
      </Text>
      <ButtonElement
        colorScheme="danger_white"
        text="Retornar para a aula"
        onPress={onReturn}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
      <TouchableOpacity
        style={[{ width: 252, marginTop: 24 }]}
        onPress={onClose}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            { textAlign: "center", textDecorationLine: "underline" },
          ]}
        >
          Sair da aula
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};

const ClassEndingPopup = (props: { show?: boolean; onClose?: () => void }) => {
  return (
    <AnimatedPopUp show={props.show} close={props.onClose}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_dark,
        ]}
      >
        {"Essa aula será encerrada\nautomaticamente em"}
      </Text>
      <ClassroomTimer
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            paddingVertical: 6,
            paddingLeft: 16,
            borderRadius: 60,
            width: 92,
          },
          StyleUtils.color_btn_muted,
        ]}
        textStyle={[
          StyleUtils.font_btn_regular,
          StyleUtils.font_size_24,
          StyleUtils.color_txt_dark,
          { textAlignVertical: "center" },
        ]}
      />
      <Text
        style={[
          {
            marginTop: 2,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_btn_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_dark,
        ]}
      >
        Minutos
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_btn_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_light,
        ]}
      >
        {"Após o término, você será\nenviado para a página principal"}
      </Text>
      <ButtonElement
        colorScheme="primary"
        text="Entendi"
        onPress={props.onClose}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
    </AnimatedPopUp>
  );
};

const ClassEndedPopup = (props: {
  eventId: string;
  show?: boolean;
  onClose?: () => void;
}) => {
  classroomTimer.useExitTimer(props.show, props.onClose);
  if (props.show) {
    eventUpdate(props.eventId, 2);
  }
  return (
    <AnimatedPopUp show={props.show} close={props.onClose}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_dark,
        ]}
      >
        Sua aula foi encerrada
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_btn_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_light,
        ]}
      >
        Obrigado por participar da sua aula com seu professor
      </Text>
      <ButtonElement
        colorScheme="primary"
        text="Finalizar"
        onPress={props.onClose}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
    </AnimatedPopUp>
  );
};
