import React from "react";
import { View, FlatList } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import Exercise from "../Models/Exercise";
import AssignHomeworkQuestionCard from "./AssignHomeworkQuestionCard";
import QuestionNumber from "../Elements/QuestionNumber";

export type ExerciseState = {
  exercise: Exercise;
  selected?: boolean;
};

type Props = {
  style?: any;
  list: Exercise[];
  selectedIds?: Set<string>;
  hasExercise?: (exercise: Exercise) => boolean;
  onExercisePress?: (exercise: Exercise, index: number) => void;
};

const AssignHomeworkList = (props: Props) => {
  return (
    <FlatList
      style={[
        StyleUtils.color_bg_light,
        {
          height: "100%",
          width: "100%",
        },
        props.style,
        { maxHeight: "100%" },
      ]}
      data={props.list}
      renderItem={(item) => (
        <View
          style={{
            margin: 20,
            width: "80%",
            maxWidth: 900,
            alignSelf: "center",
          }}
        >
          <QuestionNumber number={item.index + 1} />
          <AssignHomeworkQuestionCard
            exercise={item.item}
            selected={props.hasExercise && props.hasExercise(item.item)}
            onPress={() =>
              props.onExercisePress &&
              props.onExercisePress(item.item, item.index)
            }
          />
        </View>
      )}
    />
  );
};

export default AssignHomeworkList;
