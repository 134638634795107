import React, { Component } from "react";
import { StyleSheet } from "react-native";

export default class SVGImage extends Component<any, any> {
  render() {
    return (
      <img
        src={this.props.source.type}
        alt="HTML5"
        style={this.props.style && StyleSheet.flatten(this.props.style)}
      />
    );
  }
}
