import React, { Component } from "react";
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Alert,
} from "react-native";
import { connect } from "react-redux";
import HeaderDecor from "../../assets/backgroundDots.svg";
import Background from "../../assets/blueLinearGradientBackground.svg";
import {
  selectEvent,
  selectTutor,
  selectUser,
  setHomeworks,
  logout,
} from "../Actions";
import { analytics } from "../Analytics";
import AssignmentState from "../Models/AssignmentState";
import TuteeInfo from "../Models/TuteeInfo";
import User from "../Models/User";
import { withRouter } from "../Router";
import {
  AssignmentsFilter,
  EventsFilter,
  getEvents,
  getHomeworks,
  getTutor,
  getSubjectTests,
} from "../Server";
import StyleUtils, { COLOR_MUTED, COLOR_DANGER } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import OngoingEventCard from "./Cards/OngoingEventCard";
import EventCard from "./Cards/EventCard";
import BookEventCard from "./Cards/BookEventCard";
import ExercisesTuteeCard from "./Cards/ExercisesTuteeCard";
import { userShortName } from "../Utils/Utils";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import SubjectTestsBanner from "../../assets/subjectTestsBanner.svg";
import ExerciseWhite from "../../assets/exerciseWhite.svg";
import Premium from "../../assets/premium.svg";
import TutoreFutureBanner from "../../assets/tutoreFutureBanner.svg";
import { setViewedHome } from "../Actions";
import EmptyCard from "./Cards/EmptyCard";
import { RescheduleClassPopup } from "../Popups/RescheduleClassPopup";

type Props = {
  history;
  mobile?: boolean;
  user: User;
  tuteeInfo?: TuteeInfo;
  selectEvent;
  selectUser;
  selectTutor;
  dispatch;
  pendingAnswer;
  pendingComplete;
  logout;
  location;
  homeworkNotification;
  viewedHome;
};

type State = {
  loading: boolean;
  event;
  subjects;
  showSubjectTests;
  paywall;
  tutoreFutureOnboarding;
  hasTutor;
  hasLesson;
  reschedulePopupShow;
  classIn24Hours;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
    tuteeInfo: state.userRed.tuteeInfo,
    pendingAnswer: state.homeworkRed.pendingAnswer,
    pendingComplete: state.homeworkRed.pendingComplete,
    homeworkNotification: state.homeworkRed.notification,
    viewedHome: state.userRed.viewedHome,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectEvent: (event) => dispatch(selectEvent(event)),
    selectUser: (user) => dispatch(selectUser(user)),
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
    logout: () => dispatch(logout()),
    setViewedHome: () => dispatch(setViewedHome()),
    dispatch: dispatch,
  };
}

class TuteeHome extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      event: null,
      subjects: null,
      showSubjectTests: false,
      paywall: false,
      tutoreFutureOnboarding: false,
      hasTutor: false,
      hasLesson: false,
      reschedulePopupShow: false,
      classIn24Hours: false,
    };
  }

  componentDidMount() {
    RemoteConfigBridge.remoteConfig("onboardingType", (value) => {
      switch (parseInt(value)) {
        case 1:
          analytics.sendOpenHomeExerciseOnboarding();
          break;
        default:
          if (this.props.tuteeInfo && this.props.tuteeInfo.preferredTutor) {
            analytics.sendOpenHome(
              "false",
              this.props.tuteeInfo.preferredTutor.name
            );
          } else {
            analytics.sendOpenHome("false", "none");
          }
      }
    });
    RemoteConfigBridge.remoteConfig("tutoreFuturePaywall", (value) => {
      if (value === "1") {
        this.setState({ paywall: true });
        if (!this.props.viewedHome) {
          this.props.dispatch(setViewedHome());
          this.props.history.push("/tutorefuturepaywall");
        }
      }
    });

    RemoteConfigBridge.remoteConfig("tutoreFutureOnboarding", (value) => {
      if (value === "1") {
        this.setState({ tutoreFutureOnboarding: true });
      }
    });

    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
    }
    this.fetchUpcomingEvent()
      .then(() => this.fetchAssignments())
      .catch(console.log)
      .finally(() => {
        this.setState({
          loading: false,
        });
      });

    if (this.props.tuteeInfo && this.props.tuteeInfo.preferredTutor) {
      this.setState({ hasTutor: true });
      getTutor(this.props.user.id, this.props.tuteeInfo.preferredTutor.id)
        .then((t) => {
          if (t) {
            let subjects = t.tutorInfo ? t.tutorInfo.subjects : {};
            const subjectNames: string[] = [];
            let orientadorVocacional = false;
            for (const property in subjects) {
              if (subjects[property].name === "Orientação/Teste Vocacional")
                orientadorVocacional = true;
              else subjectNames.push(subjects[property].name);
            }
            let subjectsJoined = "Prof. de " + subjectNames.join(", ");
            if (orientadorVocacional) {
              subjectsJoined = "Orientador Vocacional e " + subjectsJoined;
            }
            this.setState({ subjects: subjectsJoined });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    const ongoing = this.isOngoingEvent();
    let large: boolean = !this.props.mobile || !this.isExercisePending();
    return (
      <>
        <ScrollView style={[StyleUtils.screen_default]}>
          <View
            style={{
              position: "absolute",
              zIndex: 0,
              right: 0,
              left: 0,
              backgroundColor: "#0179ff",
            }}
          >
            <SVGImage
              source={<Background />}
              style={{ width: "100%", height: 265 }}
            />
            <View
              style={{
                zIndex: 1,
                position: "absolute",
                marginTop: 32,
                marginLeft: 40,
              }}
            >
              <SVGImage source={<HeaderDecor />} />
            </View>
          </View>
          <View
            style={[this.props.mobile ? style.page_mobile : style.page_web]}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={[
                    StyleUtils.color_txt_white,
                    StyleUtils.font_size_18,
                    StyleUtils.font_cera_regular,
                    { marginTop: 32 },
                  ]}
                >
                  Bem-vindo
                </Text>
                <Text
                  style={[
                    StyleUtils.color_txt_white,
                    StyleUtils.font_size_20,
                    StyleUtils.font_cera_bold,
                    { marginBottom: 32 },
                  ]}
                >
                  {userShortName(this.props.user.name)}
                </Text>
              </View>
              {!this.state.tutoreFutureOnboarding && this.state.paywall && (
                <TouchableOpacity
                  onPress={() => {
                    analytics.sendPremiumHome();
                    this.props.history.push("/tutorefuturepaywall");
                  }}
                >
                  <SVGImage source={<Premium />} style={{ marginTop: 32 }} />
                </TouchableOpacity>
              )}
              {this.state.tutoreFutureOnboarding && (
                <TouchableOpacity
                  onPress={() => {
                    this.props.history.push("/tutorefutureonboarding");
                  }}
                >
                  <SVGImage
                    source={<TutoreFutureBanner />}
                    style={{ marginTop: 32 }}
                  />
                </TouchableOpacity>
              )}
            </View>
            {this.props.homeworkNotification && (
              <TouchableOpacity
                onPress={() => {
                  this.props.history.push("/exercises");
                }}
              >
                <View
                  style={[
                    StyleUtils.shape_card_default,
                    StyleUtils.color_bg_white,
                    {
                      height: 48,
                      marginBottom: 16,
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderWidth: 1,
                      borderColor: COLOR_MUTED,
                    },
                  ]}
                >
                  <Text
                    style={[
                      StyleUtils.color_txt_dark,
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                      {
                        paddingVertical: Platform.OS === "ios" ? 16 : 10,
                        paddingLeft: 16,
                      },
                    ]}
                  >
                    Responder Lições
                  </Text>
                  <Text
                    style={[
                      StyleUtils.color_txt_white,
                      StyleUtils.font_size_14,
                      StyleUtils.font_cera_bold,
                      StyleUtils.color_bg_primary,
                      {
                        width: 32,
                        height: 16,
                        marginTop: 15,
                        marginRight: 16,
                        textAlign: "center",
                        overflow: "hidden",
                        borderRadius: 8,
                        paddingTop: Platform.OS === "ios" ? 1 : 0,
                        lineHeight: Platform.OS === "ios" ? 14 : 16,
                      },
                    ]}
                  >
                    {this.props.pendingAnswer}
                  </Text>
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 1000,
                      backgroundColor: COLOR_DANGER,
                      position: "absolute",
                      top: 12,
                      right: 16,
                    }}
                  />
                </View>
              </TouchableOpacity>
            )}
            <View style={{ minHeight: 64 }}>
              {this.state.showSubjectTests && (
                <TouchableOpacity
                  onPress={() => {
                    analytics.sendSubjectTestHome();
                    this.props.history.push({
                      pathname: "/subjecttests",
                      state: { fromHome: true },
                    });
                  }}
                >
                  <View
                    style={[
                      StyleUtils.shape_card_default,
                      {
                        height: 48,
                        marginBottom: 16,
                        justifyContent: "space-between",
                        flexDirection: "row",
                        backgroundColor: "#203557",
                      },
                    ]}
                  >
                    <SVGImage
                      source={<SubjectTestsBanner />}
                      style={{ position: "absolute", top: 5, left: 4 }}
                    />
                    <Text
                      style={[
                        StyleUtils.color_txt_white,
                        StyleUtils.font_size_16,
                        StyleUtils.font_cera_regular,
                        {
                          paddingVertical: Platform.OS === "ios" ? 16 : 10,
                          paddingLeft: 16,
                        },
                      ]}
                    >
                      Testar meu conhecimento
                    </Text>
                    <SVGImage
                      source={<ExerciseWhite />}
                      style={{ marginRight: 16, marginTop: 9 }}
                    />
                  </View>
                </TouchableOpacity>
              )}
            </View>

            {this.props.mobile && (
              <View style={style.cardHolder_mobile}>
                <View>
                  {!this.state.hasTutor &&
                    !this.state.hasLesson &&
                    this.empty()}
                  {ongoing && this.ongoingEvent(true)}
                  {!ongoing && this.state.event && this.upcomingEvent(true)}
                  {!this.state.event && this.bookEvent(true)}
                </View>
              </View>
            )}
            {!this.props.mobile && (
              <View style={style.cardHolder_web}>
                {ongoing && this.ongoingEvent(true)}
                {!ongoing && this.state.event && this.upcomingEvent(true)}
                {!this.state.event && this.bookEvent(true)}
              </View>
            )}
          </View>
        </ScrollView>
        {this.props.mobile && (
          <RescheduleClassPopup
            show={this.state.reschedulePopupShow}
            close={() => {
              this.setState({
                reschedulePopupShow: false,
              });
            }}
            onClose={() => {
              let subject = "";
              let subjectLevel = "";
              if (this.state.event.subject) {
                subject = this.state.event.subject;
                subjectLevel = this.state.event.subjectLevel;
              }
              this.props.selectTutor(this.state.event.tutor);
              this.props.history.push({
                pathname: "/booking",
                state: {
                  subject: subject,
                  subjectLevel: subjectLevel,
                  reschedule: true,
                  eventID: this.state.event.id,
                  source: "reschedule_popup",
                },
              });
            }}
            onReturn={() => {
              this.setState({
                reschedulePopupShow: false,
              });
            }}
            onAlert={() => {
              Alert.alert(
                "",
                "Esta aula não pode ser remarcada pois ela acontecerá em menos de 24h",
                [
                  {
                    text: "OK",
                    onPress: () => {
                      this.setState({
                        reschedulePopupShow: false,
                      });
                    },
                  },
                ],
                { cancelable: false }
              );
            }}
            classIn24Hours={this.state.classIn24Hours}
          />
        )}
      </>
    );
  }

  async fetchUpcomingEvent() {
    const now = new Date();
    // TODO melhorar a logica para pegar eventos já iniciados.
    // supõe aula de 30 minutos
    now.setMinutes(now.getMinutes() - 59);

    var eventFilter: EventsFilter = {
      tutorId: undefined,
      tuteeId: this.props.user.id,
      periodStart: now.toISOString(),
      periodEnd: undefined,
      limit: 1,
    };

    return getEvents(eventFilter).then((result: any) => {
      if (result && result.length > 0 && result[0]) {
        this.setState({ hasLesson: true });
      }
      const ev = (result && result.length > 0 && result[0]) || null;
      this.setState({
        event: ev,
      });
      let date = new Date(ev.startDate);
      if (date.getTime() < new Date().getTime() + 60 * 60 * 24 * 1000) {
        this.setState({ classIn24Hours: false });
      }
    });
  }

  async fetchAssignments() {
    var assignmentsFilter: AssignmentsFilter = {
      tutorId: undefined,
      tuteeId: this.props.user.id,
      limit: undefined,
      states: [AssignmentState.SUBMITTED, AssignmentState.REVIEWED],
    };
    return getHomeworks(assignmentsFilter).then((homeworks) => {
      this.props.dispatch(setHomeworks(homeworks, false));
      if (!this.props.homeworkNotification) {
        getSubjectTests(this.props.user.id).then((result) => {
          result.subjectTests.forEach((subjectTest) => {
            if (!subjectTest.score) {
              this.setState({ showSubjectTests: true });
            }
          });
        });
      }
    });
  }

  ongoingEvent(large?: boolean) {
    const startDate = new Date(this.state.event.startDate);
    let subject = "";
    if (this.state.event.subject && this.state.event.subjectLevel) {
      subject =
        "Aula de " +
        this.state.event.subject +
        " - " +
        this.state.event.subjectLevel;
    }

    return (
      <OngoingEventCard
        mobile={this.props.mobile}
        size={large ? "large" : "regular"}
        user={this.state.event.tutor}
        date={startDate}
        onPress={this.goToEvent}
        subject={subject}
      />
    );
  }

  upcomingEvent(large?: boolean) {
    let cardStyle: "regular" | "success" | "primary";
    if (!this.props.mobile) {
      cardStyle = "primary";
    } else {
      cardStyle = "regular";
    }
    const startDate = new Date(this.state.event.startDate);
    let subject = "";
    if (this.state.event.subject && this.state.event.subjectLevel) {
      if (this.state.event.subject === "Orientação/Teste Vocacional") {
        subject = "Aula de " + this.state.event.subject;
      } else {
        subject =
          "Aula de " +
          this.state.event.subject +
          " - " +
          this.state.event.subjectLevel;
      }
    }

    return (
      <EventCard
        mobile={this.props.mobile}
        cardStyle={cardStyle}
        size={large ? "large" : "regular"}
        user={this.state.event.tutor}
        date={startDate}
        onPress={this.goToMessages}
        subject={subject}
        onPressReschedule={this.rescheduleEvent}
        onPressTutor={this.goToTutorProfile}
      />
    );
  }

  bookEvent(large?: boolean) {
    let cardStyle: "regular" | "success" | "primary";
    if (!this.props.mobile || !this.isExercisePending()) {
      cardStyle = "primary";
    } else {
      cardStyle = "regular";
    }

    return (
      this.props.tuteeInfo &&
      this.props.tuteeInfo.preferredTutor && (
        <BookEventCard
          mobile={this.props.mobile}
          cardStyle={cardStyle}
          size={large ? "large" : "regular"}
          user={this.props.tuteeInfo.preferredTutor}
          onPress={this.goToBooking}
          onPress2={this.goToMessages2}
          subjects={this.state.subjects}
          onPressTutor={this.goToTutorProfile}
        />
      )
    );
  }

  empty() {
    return <EmptyCard history={this.props.history} />;
  }

  execiseCard() {
    let cardStyle: "regular" | "success" | "primary";
    if (!this.isOngoingEvent() || !this.props.mobile) {
      cardStyle = "primary";
    } else {
      cardStyle = "regular";
    }

    return (
      <ExercisesTuteeCard
        mobile={this.props.mobile}
        cardStyle={cardStyle}
        size={!this.props.mobile ? "large" : "regular"}
        numberOfPendingExercises={this.props.pendingAnswer}
        numberOfCorrectedExercises={this.props.pendingComplete}
        onPress={this.goToExercises}
      />
    );
  }

  isExercisePending(): boolean {
    return this.props.pendingAnswer > 0 || this.props.pendingComplete > 0;
  }

  isOngoingEvent(now?: Date): boolean {
    const startDate = this.state.event && new Date(this.state.event.startDate);
    const availableClassroom = new Date(startDate);
    availableClassroom.setMinutes(availableClassroom.getMinutes() - 5);
    return startDate && (now || new Date()) >= availableClassroom;
  }

  goToEvent = () => {
    this.props.selectEvent(this.state.event);
    this.props.selectUser(this.state.event.tutor);
    this.props.history.push("/eventinfo");
  };

  goToEvents = () => {
    this.props.history.push("/events");
  };

  goToBooking = () => {
    if (this.props.tuteeInfo && this.props.tuteeInfo.preferredTutor) {
      getTutor(this.props.user.id, this.props.tuteeInfo.preferredTutor.id)
        .then((t) => {
          this.props.selectTutor(t);
          this.props.history.push("/booking", {
            source: "tutee_home",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  rescheduleEvent = () => {
    this.setState({ reschedulePopupShow: true });
  };

  goToMessages = () => {
    if (this.state.event && this.state.event.tutor) {
      this.props.history.push({
        pathname: `/messages/${this.state.event.tutor.id}`,
        state: {
          shouldGoBack: true,
        },
      });
    }
  };

  goToMessages2 = () => {
    if (
      this.props.tuteeInfo &&
      this.props.tuteeInfo.preferredTutor &&
      this.props.tuteeInfo.preferredTutor.id
    ) {
      this.props.history.push({
        pathname: `/messages/${this.props.tuteeInfo.preferredTutor.id}`,
        state: {
          shouldGoBack: true,
        },
      });
    }
  };

  goToExercises = () => {
    this.props.history.push("/exercises");
  };

  goToTutorProfile = () => {
    if (this.props.tuteeInfo && this.props.tuteeInfo.preferredTutor) {
      getTutor(this.props.user.id, this.props.tuteeInfo.preferredTutor.id)
        .then((t) => {
          this.props.selectTutor(t);
          this.props.history.push("/TutorProfile");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
}

const style = StyleSheet.create({
  page_mobile: {
    padding: 24,
    flex: 10,
    height: "100%",
  },
  page_web: {
    marginLeft: "10%",
    marginRight: "10%",
  },
  cardHolder_web: {
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  cardHolder_mobile: {
    flexShrink: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    borderWidth: 1,
    borderColor: COLOR_MUTED,
    borderRadius: 12,
  },
});

export default withRouter(connect(stateToProps, dispatchToProps)(TuteeHome));
