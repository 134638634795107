import React, { Component } from "react";
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  View,
  AppState,
} from "react-native";
import { connect } from "react-redux";
import StyleUtils from "../Utils/StyleUtils";
import VocationalTestStart from "./VocationalTestStart";
import ChooseGoals from "./ChooseGoals";
import ChooseYear from "./ChooseYear";
import ChooseInterest from "./ChooseInterest";
import { analytics } from "../Analytics";
import VTParts from "./VTParts";
import VTResults from "./VTResults";
import DetailedResult from "./DetailedResult";
import ZeroToFiveQuestions from "./ZeroToFiveQuestions";
import BasicQuestions from "./BasicQuestions";
import {
  defaultCareers,
  defaultInterests,
  defaultAbilities,
} from "../Models/VocationalTest";
import { setOnboardingData } from "../Actions";
import {
  getUserPersonalInfo,
  postVocationalTest,
  updateUserPersonalInfo,
  getUserCredit,
  EventsFilter,
  getEvents,
} from "../Server";
import UserPersonalInfoScreen from "./UserPersonalInfoScreen";
import SignUpPopUp from "../SignUp/SignUpPopUp";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

type Props = {
  location;
  history;
  setOnboardingData;
  onCancel?;
  user;
  talkspaceOnboarding;
  fromDynamicLink;
};

type State = {
  componentStack;
  backHandler;
  translateX;
  isTransitioning;
  appState;
  abilities;
  interests;
  activities;
  avgActivities;
  avgInterests;
  showLogin;
  initialAnswers: InitialAnswersType;
  personalInfoValues: PersonalInfoValuesType;
  firstClassFree;
};

type InitialAnswersType = {
  goals: string[];
  collegeEnteringPeriod: string;
  mentorshipInterest: string;
};

export type PersonalInfoValuesType = {
  birthdayYear: string;
  phoneNumber: string;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
    talkspaceOnboarding: state.userRed.talkspaceOnboarding,
    fromDynamicLink: state.userRed.fromDynamicLink,
  };
}

function dispatchToProps(dispatch) {
  return {
    setOnboardingData: (data) => dispatch(setOnboardingData(data)),
    dispatch: dispatch,
  };
}

const { width } = Dimensions.get("window");

class VocationalTest extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);
    this.state = {
      componentStack: [
        <VocationalTestStart
          onContinue={
            this.props.user
              ? () =>
                  this.pushComponent(
                    <UserPersonalInfoScreen
                      onNext={() => {
                        this.onContinueStart();
                      }}
                      onBack={() => {
                        this.backPress();
                      }}
                      getInitialValues={() => this.state.personalInfoValues}
                      onSubmit={(value) => {
                        this.setState({ personalInfoValues: value });
                      }}
                    />
                  )
              : () => {
                  this.setState({ showLogin: true });
                }
          }
          onCancel={this.props.onCancel}
          history={this.props.history}
        />,
      ],
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      translateX: new Animated.Value(0),
      isTransitioning: false,
      appState: AppState.currentState,
      initialAnswers: {
        goals: [],
        mentorshipInterest: "",
        collegeEnteringPeriod: "",
      },
      personalInfoValues: {
        birthdayYear: "",
        phoneNumber: "",
      },
      abilities: [],
      interests: [],
      activities: [],
      avgActivities: [],
      avgInterests: [],
      showLogin: false,
      firstClassFree: false,
    };

    props.user &&
      getUserPersonalInfo(props.user.id).then((data) => {
        if (data) {
          if (data.goals) {
            this.setState({
              componentStack: [
                <VocationalTestStart
                  onContinue={
                    this.props.user
                      ? () => this.onContinueInterest()
                      : () => {
                          this.setState({ showLogin: true });
                        }
                  }
                  history={this.props.history}
                  onCancel={this.props.onCancel}
                />,
              ],
            });
          } else {
            this.setState({
              componentStack: [
                <VocationalTestStart
                  onContinue={
                    this.props.user
                      ? () => this.onContinueStart()
                      : () => {
                          this.setState({ showLogin: true });
                        }
                  }
                  history={this.props.history}
                  onCancel={this.props.onCancel}
                />,
              ],
            });
          }
        }
      });
  }

  componentDidMount() {
    AppState.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    this.state.backHandler.remove();
    AppState.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    this.setState({ appState: nextAppState });
  };

  backPress = () => {
    if (this.state.componentStack.length > 1) {
      this.safelyPopComponent();
      return true;
    }
    return false;
  };

  executeWithTransition(method, back?: boolean, useFade?: boolean) {
    this.setState({ isTransitioning: true });
    if (useFade) {
      Animated.timing(this.fadeAnim, {
        toValue: 0,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        method();
        Animated.timing(this.fadeAnim, {
          toValue: 1,
          duration: 400,
          easing: Easing.cubic,
          useNativeDriver: true,
        }).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    } else {
      Animated.sequence([
        Animated.timing(this.state.translateX, {
          toValue: back ? -1 * width : width,
          duration: 1,
          useNativeDriver: true,
          delay: 400,
        }),
      ]).start(() => {
        method();
        Animated.sequence([
          Animated.timing(this.state.translateX, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }),
        ]).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    }
  }

  pushComponent(component, useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack.concat(component);
        this.setState({
          componentStack: tempComponents,
        });
      },
      false,
      useFade
    );
  }

  safelyPopComponent(useFade?: boolean) {
    if (this.state.isTransitioning) return;
    this.popComponent(useFade);
  }

  popComponent(useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack;
        tempComponents.pop();
        this.setState({ componentStack: tempComponents });
      },
      true,
      useFade
    );
  }

  onContinueStart() {
    RemoteConfigBridge.remoteConfig("firstClassFree", (value) => {
      var valueBool = value == "1";
      if (valueBool && !this.props.fromDynamicLink) {
        if (this.props.user.id) {
          getUserCredit(this.props.user.id).then((res) => {
            if (res.amount === 0) {
              var eventFilter: EventsFilter = {
                tutorId: undefined,
                tuteeId: this.props.user.id,
                periodStart: undefined,
                periodEnd: undefined,
                limit: 1,
              };
              getEvents(eventFilter).then((result: any) => {
                if (result && result.length === 0) {
                  this.setState({ firstClassFree: true });
                }
              });
            }
          });
        } else {
          this.setState({ firstClassFree: true });
        }
      }
    });
    if (this.state.isTransitioning) return;
    const defaultGoals = [
      { id: "0", name: "Passar de ano na escola" },
      { id: "1", name: "Passar no vestibular" },
      { id: "2", name: "Encontrar meu primeiro emprego" },
      { id: "3", name: "Estudar fora do país" },
      { id: "4", name: "Fazer uma transição de carreira" },
      { id: "5", name: "Outros" },
    ];
    this.pushComponent(
      <ChooseGoals
        areas={defaultGoals}
        onContinue={(arr) => this.onContinueGoals(arr)}
        onBack={() => this.safelyPopComponent(true)}
        headerText={"Qual/quais seus principais objetivos?"}
        getInitialGoals={() => this.state.initialAnswers.goals}
      />
    );
  }

  onContinueGoals(arr) {
    if (this.state.isTransitioning) return;
    let goals: string[] = [];
    for (const item of arr) {
      goals.push(item.name);
    }
    analytics.sendGoals(goals.toString());

    this.setState({
      initialAnswers: { ...this.state.initialAnswers, goals: goals },
    });
    const defaultAreas = [
      { id: "0", name: "Não sei" },
      { id: "1", name: "1º Sem/2022" },
      { id: "2", name: "2º Sem/2022" },
      { id: "3", name: "1º Sem/2023" },
      { id: "4", name: "2º Sem/2023" },
      { id: "5", name: "2024 em diante" },
    ];
    this.pushComponent(
      <ChooseYear
        areas={defaultAreas}
        onContinue={(arr) => this.onContinueYear(arr)}
        onBack={() => this.safelyPopComponent(true)}
        headerText={"Quando você pretende ingressar na faculdade?"}
        getInitialYear={() => this.state.initialAnswers.collegeEnteringPeriod}
      />
    );
  }

  onContinueYear(arr) {
    if (this.state.isTransitioning) return;
    this.setState({
      initialAnswers: {
        ...this.state.initialAnswers,
        collegeEnteringPeriod: arr.name,
      },
    });
    analytics.sendYearOfCollegeEntry(arr.name);
    const defaultAreas = [
      { id: "0", name: "Não sei" },
      { id: "1", name: "Sim" },
      { id: "2", name: "Não" },
    ];

    this.pushComponent(
      <ChooseInterest
        areas={defaultAreas}
        onContinue={(ans) => this.onContinueInterest(ans)}
        onBack={() => this.safelyPopComponent(true)}
        headerText={
          "Teria interesse em uma mentoria personalizada para te ajudar nessa decisão?"
        }
        getInitialValue={() => this.state.initialAnswers.mentorshipInterest}
      />
    );
  }

  onContinueInterest(ans?) {
    if (this.state.isTransitioning) return;
    if (ans) {
      this.setState(
        {
          initialAnswers: {
            ...this.state.initialAnswers,
            mentorshipInterest: ans.name,
          },
        },
        () => {
          updateUserPersonalInfo(
            this.props.user.id,
            this.state.initialAnswers.goals,
            this.state.initialAnswers.collegeEnteringPeriod,
            this.state.initialAnswers.mentorshipInterest
          );
        }
      );
    }

    this.pushComponent(
      <VTParts
        onContinue={() => this.onContinuePartOne()}
        onBack={() => this.safelyPopComponent(true)}
        headerText={"Vamos para\no que importa?"}
        subHeaderText={"Atividades que\nvocê gosta"}
        buttonText={"Vamos lá!"}
        partNumber={1}
      />
    );
  }

  onContinuePartOne() {
    if (this.state.isTransitioning) return;
    analytics.sendStartPartOneVT();
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => this.safelyPopComponent(true)}
        onContinue={(result) => this.onContinueActivityOne(result)}
        headerText={
          "Gosto de acompanhar notícias e tendências de coisas que me interessam"
        }
        percentage={"0%"}
      />
    );
  }

  onContinueActivityOne(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: [result] });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivityTwo(result)}
        headerText={
          "Gosto de analisar e entender tópicos/coisas que me interessam"
        }
        percentage={"6%"}
      />
    );
  }
  onContinueActivityTwo(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivityThree(result)}
        headerText={"Gosto de criar, desenhar ou projetar"}
        percentage={"12%"}
      />
    );
  }
  onContinueActivityThree(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivityFour(result)}
        headerText={
          "Gosto de estudar, pesquisar e me aprofundar em tópicos que me interessam"
        }
        percentage={"18%"}
      />
    );
  }
  onContinueActivityFour(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivityFive(result)}
        headerText={"Gosto de fazer cálculos e análises matemáticas"}
        percentage={"24%"}
      />
    );
  }
  onContinueActivityFive(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivitySix(result)}
        headerText={"Gosto de gerenciar ou cuidar de pessoas/animais"}
        percentage={"29%"}
      />
    );
  }
  onContinueActivitySix(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivitySeven(result)}
        headerText={
          "Gosto de me comunicar, conversar e/ou ensinar outras pessoas"
        }
        percentage={"35%"}
      />
    );
  }
  onContinueActivitySeven(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onContinueActivityEight(result)}
        headerText={"Gosto de praticar atividades físicas"}
        percentage={"41%"}
      />
    );
  }
  onContinueActivityEight(result) {
    if (this.state.isTransitioning) return;
    this.setState({ activities: this.state.activities.concat([result]) });
    this.pushComponent(
      <ZeroToFiveQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.state.activities.pop();
        }}
        onContinue={(result) => this.onFinishLastPartOne(result)}
        headerText={"Gosto de resolver problemas e pensar em diversas soluções"}
        percentage={"47%"}
      />
    );
  }

  onFinishLastPartOne(result) {
    if (this.state.isTransitioning) return;
    let results: any[] = [];
    this.setState(
      { activities: this.state.activities.concat([result]) },
      () => {
        // Administração e Contabilidade
        let avg =
          (this.state.activities[0] * 3 +
            this.state.activities[1] * 3 +
            this.state.activities[2] * 3 +
            this.state.activities[4] * 6 +
            this.state.activities[5] * 5 +
            this.state.activities[6] * 7 +
            this.state.activities[8] * 5) /
          10;
        results.push(avg);
        // Arquitetura e Eng. Civil
        avg =
          (this.state.activities[0] * 4 +
            this.state.activities[1] * 7 +
            this.state.activities[2] * 14 +
            this.state.activities[3] * 2 +
            this.state.activities[4] * 4 +
            this.state.activities[5] * 3 +
            this.state.activities[6] * 3 +
            this.state.activities[8] * 4) /
          11;
        results.push(avg);
        // Direito
        avg =
          (this.state.activities[0] * 3 +
            this.state.activities[3] * 8 +
            this.state.activities[5] * 2 +
            this.state.activities[6] * 18 +
            this.state.activities[8] * 5) /
          9;
        results.push(avg);
        // Economia e Setor Público
        avg =
          (this.state.activities[0] * 5 +
            this.state.activities[1] * 6 +
            this.state.activities[2] * 2 +
            this.state.activities[3] * 8 +
            this.state.activities[4] * 8 +
            this.state.activities[6] * 6 +
            this.state.activities[8] * 6) /
          12;
        results.push(avg);
        // Computação e áreas relacionadas
        avg =
          (this.state.activities[1] * 6 +
            this.state.activities[2] * 4 +
            this.state.activities[3] * 10 +
            this.state.activities[4] * 5 +
            this.state.activities[8] * 10) /
          8;
        results.push(avg);
        // Engenharias, exceto Civil e Computação
        avg =
          (this.state.activities[1] * 8 +
            this.state.activities[2] * 7 +
            this.state.activities[4] * 10 +
            this.state.activities[5] * 3 +
            this.state.activities[6] * 3 +
            this.state.activities[8] * 5) /
          9;
        results.push(avg);
        // Esportes
        avg =
          (this.state.activities[3] * 3 +
            this.state.activities[5] * 4 +
            this.state.activities[6] * 4 +
            this.state.activities[7] * 5 +
            this.state.activities[8] * 4) /
          5;
        results.push(avg);
        // Gastronomia
        avg =
          (this.state.activities[0] * 4 +
            this.state.activities[1] * 3 +
            this.state.activities[2] * 5 +
            this.state.activities[3] * 6 +
            this.state.activities[5] * 7 +
            this.state.activities[8] * 3) /
          8;
        results.push(avg);
        // História, Geografia e ciências humanas
        avg =
          (this.state.activities[0] * 4 +
            this.state.activities[1] * 5 +
            this.state.activities[3] * 12 +
            this.state.activities[6] * 6) /
          7;
        results.push(avg);
        // Hotelaria e Turismo
        avg =
          (this.state.activities[0] * 4 +
            this.state.activities[1] * 2 +
            this.state.activities[3] * 8 +
            this.state.activities[5] * 7 +
            this.state.activities[6] * 9 +
            this.state.activities[7] * 3) /
          9;
        results.push(avg);
        // Matemática e Estatística
        avg =
          (this.state.activities[3] * 8 +
            this.state.activities[4] * 8 +
            this.state.activities[8] * 9) /
          6;
        results.push(avg);
        // Medicina e áreas da saúde
        avg =
          (this.state.activities[1] * 5 +
            this.state.activities[3] * 3 +
            this.state.activities[5] * 9 +
            this.state.activities[6] * 8 +
            this.state.activities[8] * 9) /
          9;
        results.push(avg);
        // Moda, Design e Artes no Geral
        avg =
          (this.state.activities[0] * 5 +
            this.state.activities[1] * 2 +
            this.state.activities[2] * 13 +
            this.state.activities[3] * 6 +
            this.state.activities[6] * 3) /
          8;
        results.push(avg);
        // Pedagogia e Licenciatura
        avg =
          (this.state.activities[3] * 3 +
            this.state.activities[5] * 10 +
            this.state.activities[6] * 13 +
            this.state.activities[8] * 7) /
          8;
        results.push(avg);
        // Publicidade, Marketing e Comunicação
        avg =
          (this.state.activities[0] * 4 +
            this.state.activities[2] * 3 +
            this.state.activities[3] * 4 +
            this.state.activities[4] * 3 +
            this.state.activities[6] * 5) /
          5;
        results.push(avg);
        // Química, Biologia, e outras ciências
        avg =
          (this.state.activities[1] * 9 +
            this.state.activities[2] * 3 +
            this.state.activities[3] * 5 +
            this.state.activities[4] * 4 +
            this.state.activities[8] * 4) /
          6;
        results.push(avg);
        this.setState({ avgActivities: results });
      }
    );
    this.pushComponent(
      <VTParts
        onContinue={() => this.onContinuePartTwo()}
        headerText={"Ok, agora vamos\nfalar sobre os\nseus interesses!"}
        subHeaderText={"Escolha todos os tópicos que te\ninteressam."}
        buttonText={"Continuar teste"}
        partNumber={2}
      />
    );
  }

  onContinuePartTwo() {
    if (this.state.isTransitioning) return;
    analytics.sendStartPartTwoVT();
    this.pushComponent(
      <BasicQuestions
        onBack={() => this.safelyPopComponent(true)}
        areas={defaultInterests.slice(0, 6)}
        onContinue={(result) => this.onContinuePartTwoFirst(result)}
        headerText={"Selecione os tópicos que você tem interesse"}
        percentage={"53%"}
      />
    );
  }

  onContinuePartTwoFirst(result) {
    if (this.state.isTransitioning) return;
    this.setState({ interests: result });
    this.pushComponent(
      <BasicQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.setState({ interests: [] });
        }}
        areas={defaultInterests.slice(6, 12)}
        onContinue={(result) => this.onContinuePartTwoSecond(result)}
        headerText={"Selecione os tópicos que você tem interesse"}
        percentage={"59%"}
      />
    );
  }

  onContinuePartTwoSecond(result) {
    if (this.state.isTransitioning) return;
    this.setState({ interests: this.state.interests.concat(result) });
    this.pushComponent(
      <BasicQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.setState({ interests: this.state.interests.slice(0, 6) });
        }}
        areas={defaultInterests.slice(12, 18)}
        onContinue={(result) => this.onContinuePartTwoThird(result)}
        headerText={"Selecione os tópicos que você tem interesse"}
        percentage={"65%"}
      />
    );
  }

  onContinuePartTwoThird(result) {
    if (this.state.isTransitioning) return;
    this.setState({ interests: this.state.interests.concat(result) });
    this.pushComponent(
      <BasicQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.setState({ interests: this.state.interests.slice(0, 12) });
        }}
        areas={defaultInterests.slice(18, 22)}
        onContinue={(result) => this.onContinuePartTwoFourth(result)}
        headerText={"Selecione os tópicos que você tem interesse"}
        percentage={"71%"}
      />
    );
  }

  onContinuePartTwoFourth(result) {
    if (this.state.isTransitioning) return;
    this.setState({ interests: this.state.interests.concat(result) });
    this.pushComponent(
      <BasicQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.setState({ interests: this.state.interests.slice(0, 18) });
        }}
        areas={defaultInterests.slice(22)}
        onContinue={(result) => this.onContinueLastPartTwo(result)}
        headerText={"Selecione os tópicos que você tem interesse"}
        percentage={"76%"}
      />
    );
  }

  onContinueLastPartTwo(result) {
    if (this.state.isTransitioning) return;
    let results: any[] = [];
    this.setState({ interests: this.state.interests.concat(result) }, () => {
      // Administração e Contabilidade
      let avg =
        (this.state.interests[17] * 4 +
          this.state.interests[11] * 4 +
          this.state.interests[18] * 5 +
          this.state.interests[14] * 5) /
        4;
      results.push(avg);
      // Arquitetura e Eng. Civil
      avg =
        (this.state.interests[5] * 5 +
          this.state.interests[17] * 4 +
          this.state.interests[12] * 5 +
          this.state.interests[10] * 5) /
        4;
      results.push(avg);
      // Direito
      avg =
        (this.state.interests[22] * 3 +
          this.state.interests[15] * 3 +
          this.state.interests[23] * 4 +
          this.state.interests[6] * 2 +
          this.state.interests[16] * 4) /
        5;
      results.push(avg);
      // Economia e Setor Público
      avg =
        (this.state.interests[15] * 5 +
          this.state.interests[6] * 4 +
          this.state.interests[17] * 4 +
          this.state.interests[11] * 3 +
          this.state.interests[23] * 4 +
          this.state.interests[9] * 4 +
          this.state.interests[13] * 3 +
          this.state.interests[19] * 5) /
        8;
      results.push(avg);
      // Computação e áreas relacionadas
      avg =
        (this.state.interests[20] * 5 +
          this.state.interests[22] * 4 +
          this.state.interests[17] * 4 +
          this.state.interests[9] * 4 +
          this.state.interests[24] * 5) /
        5;
      results.push(avg);
      // Engenharias, exceto Civil e Computação
      avg =
        (this.state.interests[17] * 3 +
          this.state.interests[11] * 3 +
          this.state.interests[18] * 5 +
          this.state.interests[24] * 4 +
          this.state.interests[22] * 4 +
          this.state.interests[12] * 3 +
          this.state.interests[21] * 3 +
          this.state.interests[9] * 3) /
        8;
      results.push(avg);
      // Esportes
      avg =
        (this.state.interests[14] * 4 +
          this.state.interests[4] * 5 +
          this.state.interests[8] * 3) /
        3;
      results.push(avg);
      // Gastronomia
      avg =
        (this.state.interests[4] * 2 +
          this.state.interests[21] * 5 +
          this.state.interests[3] * 4 +
          this.state.interests[0] * 5) /
        4;
      results.push(avg);
      // História, Geografia e ciências humanas
      avg =
        (this.state.interests[15] * 4 +
          this.state.interests[6] * 5 +
          this.state.interests[23] * 5 +
          this.state.interests[13] * 3 +
          this.state.interests[16] * 4 +
          this.state.interests[19] * 5) /
        6;
      results.push(avg);
      // Hotelaria e Turismo
      avg =
        (this.state.interests[16] * 4 +
          this.state.interests[0] * 3 +
          this.state.interests[15] * 3 +
          this.state.interests[14] * 2 +
          this.state.interests[2] * 3 +
          this.state.interests[7] * 5) /
        6;
      results.push(avg);
      // Matemática e Estatística
      avg =
        (this.state.interests[17] * 4 +
          this.state.interests[20] * 5 +
          this.state.interests[9] * 5 +
          this.state.interests[22] * 4 +
          this.state.interests[19] * 3 +
          this.state.interests[24] * 5) /
        6;
      results.push(avg);
      // Medicina e áreas da saúde
      avg =
        (this.state.interests[4] * 4 +
          this.state.interests[21] * 4 +
          this.state.interests[22] * 3 +
          this.state.interests[2] * 5 +
          this.state.interests[1] * 4) /
        5;
      results.push(avg);
      // Moda, Design e Artes no Geral
      avg =
        (this.state.interests[0] * 5 +
          this.state.interests[5] * 3 +
          this.state.interests[15] * 5 +
          this.state.interests[25] * 5) /
        4;
      results.push(avg);
      // Pedagogia e Licenciatura
      avg =
        (this.state.interests[22] * 5 +
          this.state.interests[14] * 5 +
          this.state.interests[19] * 3 +
          this.state.interests[7] * 4) /
        4;
      results.push(avg);
      // Publicidade, Marketing e Comunicação
      avg =
        (this.state.interests[18] * 2 +
          this.state.interests[24] * 3 +
          this.state.interests[9] * 3 +
          this.state.interests[5] * 5 +
          this.state.interests[0] * 3) /
        5;
      results.push(avg);
      // Química, Biologia, e outras ciências
      avg =
        (this.state.interests[21] * 4 +
          this.state.interests[2] * 4 +
          this.state.interests[9] * 3 +
          this.state.interests[19] * 4 +
          this.state.interests[4] * 5 +
          this.state.interests[12] * 5 +
          this.state.interests[1] * 5) /
        7;
      results.push(avg);
      this.setState({ avgInterests: results });
    });

    this.pushComponent(
      <VTParts
        onContinue={() => this.onContinuePartThree()}
        headerText={"Última parte!\nAgora quero saber no\nque você manda bem!"}
        subHeaderText={"Escolha todas as habilidades que você\nmanda bem."}
        buttonText={"Fazer a última parte"}
        partNumber={3}
      />
    );
  }

  onContinuePartThree() {
    if (this.state.isTransitioning) return;
    analytics.sendStartPartThreeVT();
    this.pushComponent(
      <BasicQuestions
        onBack={() => this.safelyPopComponent(true)}
        areas={defaultAbilities.slice(0, 6)}
        onContinue={(result) => this.onContinuePartThreeFirst(result)}
        headerText={"Selecione as habilidades que você já tem"}
        percentage={"82%"}
      />
    );
  }

  onContinuePartThreeFirst(result) {
    if (this.state.isTransitioning) return;
    this.setState({ abilities: result });
    this.pushComponent(
      <BasicQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.setState({ abilities: [] });
        }}
        areas={defaultAbilities.slice(6, 12)}
        onContinue={(result) => this.onContinuePartThreeSecond(result)}
        headerText={"Selecione as habilidades que você já tem"}
        percentage={"88%"}
      />
    );
  }

  onContinuePartThreeSecond(result) {
    if (this.state.isTransitioning) return;
    this.setState({ abilities: this.state.abilities.concat(result) });
    this.pushComponent(
      <BasicQuestions
        onBack={() => {
          this.safelyPopComponent(true);
          this.setState({ abilities: this.state.abilities.slice(0, 6) });
        }}
        areas={defaultAbilities.slice(12, 17)}
        onContinue={(result) => this.onContinueResult(result)}
        headerText={"Selecione as habilidades que você já tem"}
        percentage={"94%"}
      />
    );
  }

  onContinueResult(result) {
    if (this.state.isTransitioning) return;
    this.setState({ abilities: this.state.abilities.concat(result) }, () => {
      // Administração e Contabilidade
      let results: any[] = [];
      let avg =
        (this.state.abilities[3] * 3 +
          this.state.abilities[4] * 5 +
          this.state.abilities[13] * 4 +
          this.state.abilities[16] * 5) /
        4;
      results.push(avg);
      // Arquitetura e Eng. Civil
      avg =
        (this.state.abilities[3] * 4 +
          this.state.abilities[6] * 5 +
          this.state.abilities[9] * 3 +
          this.state.abilities[13] * 3) /
        4;
      results.push(avg);
      // Direito
      avg =
        (this.state.abilities[14] * 4 +
          this.state.abilities[1] * 5 +
          this.state.abilities[10] * 4 +
          this.state.abilities[4] * 4 +
          this.state.abilities[0] * 3 +
          this.state.abilities[12] * 4 +
          this.state.abilities[11] * 4) /
        7;
      results.push(avg);
      // Economia e Setor Público
      avg =
        (this.state.abilities[3] * 4 +
          this.state.abilities[10] * 4 +
          this.state.abilities[0] * 3 +
          this.state.abilities[13] * 2) /
        4;
      results.push(avg);
      // Computação e áreas relacionadas
      avg =
        (this.state.abilities[3] * 5 +
          this.state.abilities[15] * 5 +
          this.state.abilities[11] * 5) /
        3;
      results.push(avg);
      // Engenharias, exceto Civil e Computação
      avg =
        (this.state.abilities[3] * 5 +
          this.state.abilities[15] * 5 +
          this.state.abilities[11] * 5 +
          this.state.abilities[16] * 3 +
          this.state.abilities[13] * 3) /
        5;
      results.push(avg);
      // Esportes
      avg =
        (this.state.abilities[7] * 5 +
          this.state.abilities[2] * 5 +
          this.state.abilities[13] * 4) /
        3;
      results.push(avg);
      // Gastronomia
      avg =
        (this.state.abilities[7] * 4 +
          this.state.abilities[16] * 4 +
          this.state.abilities[13] * 3 +
          this.state.abilities[5] * 5 +
          this.state.abilities[9] * 5) /
        5;
      results.push(avg);
      // História, Geografia e ciências humanas
      avg =
        (this.state.abilities[14] * 4 +
          this.state.abilities[10] * 5 +
          this.state.abilities[0] * 4) /
        3;
      results.push(avg);
      // Hotelaria e Turismo
      avg =
        (this.state.abilities[2] * 3 +
          this.state.abilities[8] * 4 +
          this.state.abilities[4] * 5) /
        3;
      results.push(avg);
      // Matemática e Estatística
      avg =
        (this.state.abilities[3] * 5 +
          this.state.abilities[15] * 5 +
          this.state.abilities[11] * 4 +
          this.state.abilities[0] * 3) /
        4;
      results.push(avg);
      // Medicina e áreas da saúde
      avg =
        (this.state.abilities[7] * 4 +
          this.state.abilities[2] * 3 +
          this.state.abilities[8] * 4 +
          this.state.abilities[16] * 3 +
          this.state.abilities[13] * 3) /
        5;
      results.push(avg);
      // Moda, Design e Artes no Geral
      avg =
        (this.state.abilities[6] * 5 +
          this.state.abilities[9] * 4 +
          this.state.abilities[5] * 5) /
        3;
      results.push(avg);
      // Pedagogia e Licenciatura
      avg =
        (this.state.abilities[8] * 5 +
          this.state.abilities[4] * 5 +
          this.state.abilities[13] * 3 +
          this.state.abilities[12] * 4 +
          this.state.abilities[9] * 3) /
        5;
      results.push(avg);
      // Publicidade, Marketing e Comunicação
      avg =
        (this.state.abilities[3] * 3 +
          this.state.abilities[5] * 4 +
          this.state.abilities[4] * 5) /
        3;
      results.push(avg);
      // Química, Biologia, e outras ciências
      avg =
        (this.state.abilities[3] * 4 +
          this.state.abilities[10] * 4 +
          this.state.abilities[0] * 3) /
        3;
      results.push(avg);
      let finalResult: any[] = [];
      let maxResult = [
        140,
        158.4090909,
        144,
        123.8541667,
        205.625,
        154,
        173.3333333,
        143.5,
        167.1428571,
        134.4444444,
        178.8194444,
        139.7777778,
        166.1458333,
        170.15625,
        136.8,
        174.952381,
      ];
      for (let i = 0; i < 16; i++) {
        let count =
          (results[i] + this.state.avgInterests[i]) *
          this.state.avgActivities[i];
        finalResult.push(count / maxResult[i]);
      }
      var len = finalResult.length;
      var indices = new Array(len);
      for (let i = 0; i < len; ++i) indices[i] = i;
      indices.sort(function (a, b) {
        return finalResult[a] < finalResult[b]
          ? -1
          : finalResult[a] > finalResult[b]
          ? 1
          : 0;
      });
      let topFourIndices = indices.reverse().slice(0, 4);
      let topFour = [
        {
          id: topFourIndices[0],
          name: defaultCareers[topFourIndices[0]].name,
          percentage: finalResult[topFourIndices[0]],
        },
        {
          id: topFourIndices[1],
          name: defaultCareers[topFourIndices[1]].name,
          percentage: finalResult[topFourIndices[1]],
        },
        {
          id: topFourIndices[2],
          name: defaultCareers[topFourIndices[2]].name,
          percentage: finalResult[topFourIndices[2]],
        },
        {
          id: topFourIndices[3],
          name: defaultCareers[topFourIndices[3]].name,
          percentage: finalResult[topFourIndices[3]],
        },
      ];
      postVocationalTest(
        this.props.user.id,
        topFour,
        this.state.activities,
        this.state.interests,
        this.state.abilities
      );
      this.pushComponent(
        <VTResults
          history={this.props.history}
          headerText={"Excelente!"}
          subHeaderText={
            "Separamos algumas carreiras que mais se encaixam com o seu perfil"
          }
          buttonText={"Próximo"}
          underlineText={"Refazer Teste"}
          onContinue={() => {
            if (this.state.firstClassFree) {
              this.onContinueScheduleFree();
            } else {
              this.onContinueSchedule();
            }
          }}
          onContinueDetailedResult={(career) =>
            this.onContinueDetailedResult(career)
          }
          onRetake={() => {
            analytics.sendRetakeVT();
            this.pushComponent(
              <VTParts
                onContinue={() => this.onContinuePartOne()}
                onBack={() => this.safelyPopComponent(true)}
                headerText={"Vamos para\no que importa?"}
                subHeaderText={"Atividades que\nvocê gosta"}
                buttonText={"Vamos lá!"}
                partNumber={1}
              />
            );
          }}
          results={topFour}
        />
      );
    });
  }
  goToCounselor() {
    this.props.history.push({
      pathname: "/messages/" + this.props.user.counselor.id,
      state: {
        messages:
          "Acabei de fazer o teste vocacional e gostaria de agendar uma conversa com um mentor para me ajudar!",
      },
    });
  }

  onContinueSchedule() {
    if (this.state.isTransitioning) return;
    analytics.sendNextResultsVT();
    this.pushComponent(
      <VTResults
        history={this.props.history}
        headerText={"E agora?"}
        subHeaderText={
          "Testes vocacionais são ótimos mas não vão te dar todas as respostas!"
        }
        buttonText={"Agendar mentoria"}
        underlineText={"Finalizar"}
        onContinue={() => {
          if (this.props.talkspaceOnboarding) {
            this.goToCounselor();
          } else {
            this.goToTutorMatch();
          }
        }}
        mainText={
          "Que tal fazer uma sessão com um de nossos mentores para te ajudar a alcançar seus objetivos?"
        }
        onBack={() => this.safelyPopComponent(false)}
      />
    );
  }

  goToTutorMatch() {
    analytics.sendScheduleMentorship();
    this.props.setOnboardingData({
      subject: {
        id: "88055d6c-7ea9-4bc8-912c-fbdb27948106",
        name: "Orientação/Teste Vocacional",
      },
      level: {
        id: "3de1f15d-3725-4254-919d-ac43c21e3496",
        name: "Mentoria de Carreira",
        index: 1,
      },
    });
    this.props.history.push({
      pathname: "/landing/tutors",
      state: {
        showFooter: true,
      },
    });
  }

  onContinueScheduleFree() {
    if (this.state.isTransitioning) return;
    this.pushComponent(
      <VTResults
        history={this.props.history}
        headerText={"E agora?"}
        subHeaderText={
          "Testes vocacionais são ótimos mas não vão te dar todas as respostas!"
        }
        buttonText={"Agendar mentoria grátis"}
        underlineText={"Finalizar"}
        onContinue={() => {
          if (this.props.talkspaceOnboarding) {
            this.goToCounselor();
          } else {
            this.goToTutorMatch();
          }
        }}
        mainText={
          "Que tal fazer uma primeira sessão grátis com um de nossos mentores para te ajudar a alcançar seus objetivos?"
        }
        onBack={() => this.safelyPopComponent(false)}
      />
    );
  }

  onContinueDetailedResult(career) {
    if (this.state.isTransitioning) return;
    analytics.sendDetailedResultVT();
    this.pushComponent(
      <DetailedResult
        onBack={() => this.safelyPopComponent(true)}
        career={career}
        activities={this.state.activities}
        interests={this.state.activities}
        abilities={this.state.abilities}
        onContinue={() => this.goToTutorMatch()}
      />
    );
  }

  getCurrentComponent() {
    return (
      <Animated.View
        style={{
          flex: 1,
          opacity: this.fadeAnim,
          transform: [{ translateX: this.state.translateX }],
        }}
      >
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        {this.getCurrentComponent()}
        <SignUpPopUp
          show={this.state.showLogin}
          isLogin={false}
          onLoginSuccess={() => {
            this.pushComponent(
              <UserPersonalInfoScreen
                onNext={() => {
                  this.onContinueStart();
                }}
                onBack={() => {
                  this.backPress();
                }}
                getInitialValues={() => this.state.personalInfoValues}
                onSubmit={(value) => {
                  this.setState({ personalInfoValues: value });
                }}
              />
            );
            this.setState({ showLogin: false });
          }}
          onSignUpSuccess={() => {
            this.pushComponent(
              <UserPersonalInfoScreen
                onNext={() => {
                  this.onContinueStart();
                }}
                onBack={() => {
                  this.backPress();
                }}
                getInitialValues={() => this.state.personalInfoValues}
                onSubmit={(value) => {
                  this.setState({ personalInfoValues: value });
                }}
              />
            );
            this.setState({ showLogin: false });
          }}
          close={() => {
            this.setState({ showLogin: false });
          }}
          history={this.props.history}
          isExerciseOnboarding={false}
        />
      </View>
    );
  }
}

export default connect(mapStateToProps, dispatchToProps)(VocationalTest);
