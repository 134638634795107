import React, { useState } from "react";
import {
  SafeAreaView,
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
} from "react-native";
import StyleUtils, {
  COLOR_TXT_DARK,
  COLOR_MUTED,
  COLOR_PRIMARY,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import BackButton from "../../assets/backbutton.svg";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  onBack;
  progress;
  question;
};

export default function TextboxTemplate(props: Props) {
  const [comment, setComment] = useState("");
  const [onFocus, setOnFocus] = useState(false);

  return (
    <>
      <TouchableWithoutFeedback
        onPress={() => {
          setOnFocus(false);
          Keyboard.dismiss();
        }}
      >
        <SafeAreaView style={{ flex: 1 }}>
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : undefined}
            style={[StyleUtils.screen_default]}
            keyboardVerticalOffset={40}
          >
            <View style={[StyleUtils.screen_default]}>
              <View style={[{ justifyContent: "center", width: "100%" }]}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    margin: 24,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <TouchableOpacity
                      style={{ flexDirection: "row" }}
                      onPress={() => {
                        props.onBack();
                      }}
                    >
                      <SVGImage source={<BackButton />} />
                    </TouchableOpacity>
                  </View>
                  <Text
                    style={[
                      StyleUtils.font_cera_bold,
                      StyleUtils.color_txt_dark,
                      StyleUtils.font_size_16,
                    ]}
                  >
                    Responda e ganhe uma aula grátis!
                  </Text>
                  <View style={{ flex: 1 }}></View>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: COLOR_MUTED,
                  borderBottomWidth: 1,
                  width: "100%",
                  zIndex: 10,
                }}
              />
              <View style={{ height: 4 }}>
                <View
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: COLOR_PRIMARY,
                    width: props.progress,
                  }}
                />
              </View>
              <ScrollView
                style={{
                  maxWidth: 375,
                  width: "100%",
                }}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      margin: "8%",
                      marginTop: 80,
                      marginHorizontal: 40,
                    },
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_btn_bold,
                    StyleUtils.font_size_20,
                  ]}
                >
                  {props.question}
                </Text>
                <View
                  style={[
                    {
                      alignItems: "center",
                      justifyContent: "center",
                      marginHorizontal: 24,
                      alignContent: "center",
                    },
                  ]}
                >
                  <TextInput
                    style={[
                      StyleUtils.color_txt_dark,
                      {
                        borderRadius: 10,
                        padding: 12,
                        width: "100%",
                        textAlignVertical: "auto",
                        borderColor: onFocus ? COLOR_TXT_DARK : COLOR_MUTED,
                        borderWidth: 1,
                        height: 136,
                        marginTop: 24,
                      },
                    ]}
                    multiline={true}
                    onChangeText={(text) => setComment(text)}
                    maxLength={255}
                    placeholder={"Escreva aqui..."}
                    onFocus={() => setOnFocus(true)}
                  />
                </View>
              </ScrollView>
            </View>
            <View
              style={{
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: -2,
                },
                shadowOpacity: 0.04,
                shadowRadius: 6,
                elevation: 8,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
                backgroundColor: COLOR_TRUE_WHITE,
              }}
            >
              <ButtonElement
                text="Avançar"
                colorScheme="primary"
                styte={{
                  borderRadius: 6,
                  marginHorizontal: 24,
                  marginVertical: 24,
                }}
                textStyle={StyleUtils.font_size_16}
                onPress={() => {
                  analytics.sendSurveyResponse(props.question, comment.trim());
                  props.onContinue();
                }}
                disabled={!comment}
              />
            </View>
          </KeyboardAvoidingView>
        </SafeAreaView>
      </TouchableWithoutFeedback>
    </>
  );
}
