import React, { useEffect, useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Animated,
  FlatList,
  ScrollView,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "../../assets/backButtonBorder.svg";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_PRIMARY,
  COLOR_SUCCESS,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import IconSchool from "../../assets/iconSchoolBlue.svg";
import CorrectIcon from "../../assets/iconCheckTest.svg";
import WrongIcon from "../../assets/iconWrongTest.svg";
import {
  defaultActivities,
  defaultInterests,
  defaultAbilities,
  defaultCareerActivities,
  defaultCareerInterests,
  defaultCareerAbilities,
} from "../Models/VocationalTest";

type Props = {
  onBack;
  history;
  selectedTutor;
  userID?;
  onContinue;
  career;
  activities;
  interests;
  abilities;
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
    selectedTutor: state.selRed.selectedTutor,
  };
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

function DetailedResult(props: Props) {
  const [activities, setActivities] = useState<object[]>([]);
  const [interests, setInterests] = useState<object[]>([]);
  const [abilities, setAbilities] = useState<object[]>([]);
  const [percentage, setPercentage] = useState(0);

  const renderNativeItem = (item, activities) => {
    let num = 1;
    if (activities) num = 3;
    return (
      <View style={{ flexDirection: "row", marginBottom: 6 }}>
        <SVGImage
          source={item.num < num ? <WrongIcon /> : <CorrectIcon />}
          style={{ paddingHorizontal: 8, top: -5 }}
        />
        <Text style={[styles.text, { top: Platform.OS === "ios" ? 0 : -4 }]}>
          {item.name}
        </Text>
      </View>
    );
  };

  useEffect(() => {
    let calculation = Math.round(props.career.percentage * 100);
    if (calculation > 0) {
      setPercentage(calculation);
    }
    let careerActivities = defaultCareerActivities[props.career.id];
    let careerInterests = defaultCareerInterests[props.career.id];
    let careerAbilities = defaultCareerAbilities[props.career.id];
    let activities: any[] = [];
    for (let i = 0; i < careerActivities.length; i++) {
      let activity: any = careerActivities[i];
      let row = {
        name: defaultActivities[activity].name,
        num: props.activities[activity.question],
      };
      activities.push(row);
    }
    setActivities(activities);
    let interests: any[] = [];
    for (let i = 0; i < careerInterests.length; i++) {
      let row = {
        name: defaultInterests[careerInterests[i]].name,
        num: props.interests[careerInterests[i]],
      };
      interests.push(row);
    }
    setInterests(interests);
    let abilities: any[] = [];
    for (let i = 0; i < careerAbilities.length; i++) {
      let row = {
        name: defaultAbilities[careerAbilities[i]].name,
        num: props.abilities[careerAbilities[i]],
      };
      abilities.push(row);
    }
    setAbilities(abilities);
  }, []);
  return (
    <View style={StyleUtils.screen_default}>
      <View
        style={[
          {
            backgroundColor: COLOR_WHITE,
            justifyContent: "center",
            width: "100%",
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: 16,
          }}
        >
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                props.onBack();
              }}
            >
              <SVGImage source={<BackButton />} />
            </TouchableOpacity>
          </View>

          <View
            style={{
              alignContent: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
                { textAlign: "center" },
              ]}
            >
              Resultado Detalhado
            </Text>
          </View>
          <View style={{ flex: 1 }}></View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginBottom: 8,
          }}
        >
          <SVGImage
            source={<IconSchool />}
            style={{
              top: Platform.OS === "ios" ? -8 : -4,
              marginLeft: 24,
              marginRight: -10,
            }}
          />
          <Text
            style={[
              styles.text,
              {
                marginHorizontal: 24,
              },
            ]}
          >
            {props.career.name}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.container}>
            <View style={styles.progressBar}>
              <Animated.View
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: COLOR_SUCCESS,
                  width: percentage.toString() + "%",
                  borderRadius: 50,
                }}
              />
            </View>
          </View>
          <Text style={styles.textPercentage}>{percentage + "%"}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomColor: COLOR_MUTED,
          borderBottomWidth: 1,
          width: "100%",
          zIndex: 8,
          shadowColor: "#000000",
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowRadius: 3,
          shadowOpacity: 0.3,
          marginBottom: 25,
        }}
      />
      <ScrollView style={{ backgroundColor: COLOR_WHITE }}>
        <Text
          style={[
            styles.text,
            {
              marginHorizontal: 24,
            },
          ]}
        >
          Dentre os pontos importantes para este curso, você se dá bem com:
        </Text>
        <View style={styles.box}>
          <Text style={styles.textBlue}>Atividades</Text>
          <View
            style={{
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
              width: "100%",
              marginBottom: 20,
            }}
          />
          <FlatList
            data={activities}
            renderItem={({ item }) => renderNativeItem(item, true)}
            style={{ marginBottom: 12 }}
            keyExtractor={(index) => index.toString()}
          />
        </View>
        <View style={styles.box}>
          <Text style={styles.textBlue}>Interesses</Text>
          <View
            style={{
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
              width: "100%",
              marginBottom: 20,
            }}
          />
          <FlatList
            data={interests}
            renderItem={({ item }) => renderNativeItem(item, false)}
            style={{ marginBottom: 12 }}
          />
        </View>
        <View style={styles.box}>
          <Text style={styles.textBlue}>Habilidades</Text>
          <View
            style={{
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
              width: "100%",
              marginBottom: 20,
            }}
          />
          <FlatList
            data={abilities}
            renderItem={({ item }) => renderNativeItem(item, false)}
            style={{ marginBottom: 12 }}
          />
        </View>
        <TouchableOpacity
          onPress={() => props.onContinue()}
          style={{
            backgroundColor: COLOR_PRIMARY,
            borderRadius: 6,
            marginBottom: 30,
            marginHorizontal: 24,
            marginTop: 16,
          }}
        >
          <Text
            style={[
              {
                textAlign: "center",
                paddingVertical: 14,
              },
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_white,
            ]}
          >
            Agendar uma sessão com mentor
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailedResult);

const styles = StyleSheet.create({
  text: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    paddingRight: 30,
  },
  container: {
    flexDirection: "row",
    height: 8,
    width: "75%",
    backgroundColor: "#ecf0f1",
    borderRadius: 50,
    marginBottom: 24,
    marginHorizontal: 24,
  },
  progressBar: {
    height: 8,
    width: "100%",
    borderRadius: 50,
  },
  textPercentage: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_normal),
    top: Platform.OS === "ios" ? -4 : -8,
  },
  box: {
    backgroundColor: COLOR_TRUE_WHITE,
    borderRadius: 10,
    marginVertical: 24,
    marginHorizontal: 24,
    borderWidth: 1,
    borderColor: COLOR_MUTED,
  },
  textBlue: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
    marginHorizontal: 16,
    marginVertical: 8,
    top: 2,
  },
});
