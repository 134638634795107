import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  ScrollView,
  Alert,
  Linking,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "../../../assets/backButtonBorder.svg";
import { bookingInfo, setTuteeInfo } from "../../Actions";
import { UserImage } from "../../Elements/UserImages";
import { Tutor } from "../../Models/TutorInfo";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_DARK,
  COLOR_SUCCESS,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import TuteeInfo from "../../Models/TuteeInfo";
import { analytics } from "../../Analytics";
import Check from "../../../assets/checkGray.svg";
import CheckSuccess from "../../../assets/checkSuccess.svg";
import CheckGreen from "../../../assets/checkGreen.svg";
import * as Utils from "../../Utils/Utils";
import { CLASS_MINUTES } from "../../Booking/Booking";
import PaymentOutcomeLayout from "../../Payment/PaymentOutcomeLayout";
import {
  bookTutor,
  chooseTutor,
  requestPurchaseSubscription,
  getUserCredit,
  consumeTicket,
  requestPurchaseIAP,
} from "../../Server";
import IAP from "react-native-iap";
import Loader from "../../Elements/Loader";

type Props = {
  history;
  lessonInfo: { selectedDate; selectedTutor: Tutor; endDate };
  selectedTutor: Tutor;
  dispatch;
  userID: string;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  mobile;
  location;
  onBack?;
  onBookingFinished?;
  subject?;
  subjectLevel?;
  bookingInfo;
};

function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
    userID: state.userRed.user.id,
    lessonInfo: state.selRed.lessonInfo,
    mobile: state.layoutRed.mobile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingInfo: (event) => dispatch(bookingInfo(event)),
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch: dispatch,
  };
}

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

const subscriptionID =
  Platform.OS === "ios"
    ? "subs_tutorefuture1_month_3daysfree_50reais"
    : "subs_tutorefuture1_month_3daysfree_50reais_google";

const productID =
  Platform.OS === "ios" ? "1un_credito_50reais" : "1un_credito_50reais_google";

const TFConfirmationPage = (props: Props) => {
  const [selectedFreeTrial, setSelectedFreeTrial] = useState(true);
  const [selectedSingle, setSelectedSingle] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [calledOnce, setCalledOnce] = useState(false);
  const [productPrice, setProductPrice] = useState("");

  useEffect(() => {
    initializeIAPConnection();
  }, []);

  useEffect(() => {
    const purchaseUpdateSubscription = IAP.purchaseUpdatedListener(
      async (purchase) => {
        if (purchase) {
          const receipt = purchase.transactionReceipt;
          if (receipt)
            try {
              setCalledOnce(true);
              if (Platform.OS === "ios" && purchase.transactionId) {
                let transactionId = purchase.transactionId;
                IAP.finishTransactionIOS(purchase.transactionId);
                IAP.finishTransaction(purchase)
                  .then(() => {
                    // if subscription
                    if (purchase.productId[0] === "s") {
                      requestPurchaseSubscription(
                        "apple",
                        receipt,
                        props.userID,
                        purchase.productId,
                        transactionId
                      )
                        .then((response) => {
                          if (response.error) {
                            console.log(response);
                            Alert.alert(
                              "Compra inválida. Entre em contato com o nosso suporte."
                            );
                            analytics.sendSubscriptionPurchaseFailed();
                            setLoadingPayment(false);
                          } else {
                            analytics.sendSubscriptionPurchaseSuccess();
                            fetchAndConsumeUserCredits();
                            bookTutor(
                              props.userID,
                              props.selectedTutor.id,
                              props.lessonInfo.selectedDate,
                              props.lessonInfo.endDate,
                              "",
                              "",
                              "tutore_future"
                            )
                              .then(() => {
                                setLoadingPayment(false);
                                setPurchaseSuccess(true);
                                return chooseTutor(
                                  props.userID,
                                  props.selectedTutor.email
                                );
                              })
                              .then((tuteeInfo) => {
                                props.setTuteeInfo(tuteeInfo);
                              });
                          }
                        })
                        .catch((err) => {
                          setLoadingPayment(false);
                          console.log(err);
                        });
                    } else {
                      requestPurchaseIAP(
                        "apple",
                        receipt,
                        props.userID,
                        purchase.productId,
                        transactionId
                      )
                        .then((response) => {
                          analytics.sendIAPPurchaseSuccess();
                          bookTutor(
                            props.userID,
                            props.selectedTutor.id,
                            props.lessonInfo.selectedDate,
                            props.lessonInfo.endDate,
                            "",
                            "",
                            "tutore_future"
                          )
                            .then(() => {
                              setLoadingPayment(false);
                              setPurchaseSuccess(true);
                              return chooseTutor(
                                props.userID,
                                props.selectedTutor.email
                              );
                            })
                            .then((tuteeInfo) => {
                              props.setTuteeInfo(tuteeInfo);
                            });
                        })
                        .catch((err) => {
                          analytics.sendIAPPurchaseFailed();
                          console.log(err);
                        });
                    }
                  })
                  .catch((err) => {
                    Alert.alert("Erro na compra");
                    console.log(err.code, err.message);
                    setLoadingPayment(false);
                    analytics.sendSubscriptionPurchaseFailed();
                  });
              } else if (
                Platform.OS === "android" &&
                purchase.purchaseToken &&
                purchase.transactionId &&
                !calledOnce
              ) {
                console.log("1");
                if (purchase.productId[0] === "s") {
                  console.log("2");
                  setCalledOnce(true);
                  let response = await requestPurchaseSubscription(
                    "google",
                    purchase.purchaseToken,
                    props.userID,
                    purchase.productId,
                    purchase.transactionId
                  );
                  if (!response.error) {
                    analytics.sendSubscriptionPurchaseSuccess();
                    if (purchase.purchaseToken) {
                      await IAP.acknowledgePurchaseAndroid(
                        purchase.purchaseToken
                      );
                      await IAP.finishTransaction(purchase, false);
                      console.log("finish transaction");
                      fetchAndConsumeUserCredits();
                      bookTutor(
                        props.userID,
                        props.selectedTutor.id,
                        props.lessonInfo.selectedDate,
                        props.lessonInfo.endDate,
                        "",
                        "",
                        "tutore_future"
                      )
                        .then(() => {
                          setLoadingPayment(false);
                          setPurchaseSuccess(true);
                          return chooseTutor(
                            props.userID,
                            props.selectedTutor.email
                          );
                        })
                        .then((tuteeInfo) => {
                          props.setTuteeInfo(tuteeInfo);
                        });
                    }
                  }
                  setLoadingPayment(false);
                } else {
                  IAP.acknowledgePurchaseAndroid(purchase.purchaseToken)
                    .then(() => {
                      IAP.finishTransaction(purchase, true)
                        .then(() => {
                          if (
                            purchase.purchaseToken &&
                            purchase.transactionId
                          ) {
                            requestPurchaseIAP(
                              "google",
                              purchase.purchaseToken,
                              props.userID,
                              purchase.productId,
                              purchase.transactionId
                            )
                              .then((response) => {
                                if (response.error === true) {
                                  Alert.alert(
                                    "Compra inválida. Entre em contato com o nosso suporte."
                                  );
                                  analytics.sendIAPPurchaseFailed();
                                } else {
                                  analytics.sendIAPPurchaseSuccess();
                                  bookTutor(
                                    props.userID,
                                    props.selectedTutor.id,
                                    props.lessonInfo.selectedDate,
                                    props.lessonInfo.endDate,
                                    "",
                                    "",
                                    "tutore_future"
                                  )
                                    .then(() => {
                                      setLoadingPayment(false);
                                      setPurchaseSuccess(true);
                                      return chooseTutor(
                                        props.userID,
                                        props.selectedTutor.email
                                      );
                                    })
                                    .then((tuteeInfo) => {
                                      props.setTuteeInfo(tuteeInfo);
                                    });
                                }
                                setLoadingPayment(false);
                              })
                              .catch((err) => {
                                console.log("api purchases error ", err);
                                setLoadingPayment(false);
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err.code, err.message);
                          setLoadingPayment(false);
                        });
                    })
                    .catch((err) => {
                      console.log(err.code, err.message);
                      setLoadingPayment(false);
                    });
                }
              }
            } catch (ackErr) {
              console.warn("ackErr", ackErr);
              setLoadingPayment(false);
              analytics.sendSubscriptionPurchaseFailed();
            }
        }
      }
    );
    const purchaseErrorSubscription = IAP.purchaseErrorListener((error) => {
      console.log("purchaseErrorListener INAPP>>>>", error);
      setLoadingPayment(false);
    });
    return () => {
      if (purchaseUpdateSubscription) {
        purchaseUpdateSubscription.remove();
      }
      if (purchaseErrorSubscription) {
        purchaseErrorSubscription.remove();
      }
    };
  }, []);

  const fetchAndConsumeUserCredits = () => {
    getUserCredit(props.userID).then((res) => {
      console.log(res.amount);
      const ticket = {
        tickets: [res.tickets[0].id],
        userId: props.userID,
        tutorId: props.selectedTutor.id,
      };
      consumeTicket(ticket);
      console.log("consumeTicket");
    });
  };

  const initializeIAPConnection = async () => {
    await IAP.initConnection()
      .then(async (connection) => {
        IAP.getSubscriptions([subscriptionID]).then((res) => {});
        console.log("connected iap");
        IAP.getProducts([productID]).then((res) => {
          setProductPrice(res[0].localizedPrice);
        });
      })
      .catch((err) => {
        console.warn(`IAP ERROR ${err.code}`, err.message);
      });
  };

  const handleConfirmFreeSession = async () => {
    analytics.sendClassScheduled();
    setLoadingPayment(true);
    if (selectedFreeTrial) {
      await IAP.requestSubscription(subscriptionID)
        .then(() => {
          setLoadingPayment(true);
        })
        .catch(() => {
          setLoadingPayment(false);
          Alert.alert("Assinatura não concluída");
          analytics.sendSubscriptionPurchaseFailed();
        });
    } else {
      await IAP.requestPurchase(productID)
        .then(() => {
          setLoadingPayment(true);
        })
        .catch(() => {
          Alert.alert("Compra não concluída");
          setLoadingPayment(false);
          analytics.sendIAPPurchaseFailed();
        });
    }
  };

  const fullClassEndDate = new Date(props.lessonInfo.selectedDate).setMinutes(
    props.lessonInfo.selectedDate.getMinutes() + CLASS_MINUTES
  );

  if (purchaseSuccess !== undefined) {
    return (
      <PaymentOutcomeLayout
        tutorImage={props.selectedTutor.imageURL}
        lessonDate={props.lessonInfo.selectedDate}
        lessonEndDate={props.lessonInfo.endDate}
        tutorName={Utils.userShortName(props.selectedTutor.name)}
        history={props.history}
        classroomEnd={false}
        tutorID={props.selectedTutor.id}
        numRecurringClass={1}
      />
    );
  }

  return (
    <ScrollView style={[StyleUtils.screen_default]}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: 16,
        }}
      >
        <Loader loading={loadingPayment} />
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={() => {
              analytics.sendFirstClassFreeBack();
              props.history.replace("/booking", {
                source: "tutore_future",
              });
            }}
          >
            <SVGImage source={<BackButton />} />
          </TouchableOpacity>
        </View>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_16,
          ]}
        >
          Confirmar Mentoria
        </Text>
        <View style={{ flex: 1 }}></View>
      </View>
      <View
        style={{
          borderBottomColor: COLOR_MUTED,
          borderBottomWidth: 1,
        }}
      />
      <View>
        <View style={{ flexDirection: "row" }}>
          <UserImage
            style={{
              marginLeft: 24,
              marginRight: 16,
              marginVertical: 24,
              width: 64,
              height: 74,
              borderRadius: 6,
            }}
            imageURL={props.selectedTutor.imageURL}
          />
          <View style={{ flex: 1, marginTop: 18 }}>
            <Text
              style={[
                styles.textBoldBlack,
                {
                  paddingTop: 4,
                  marginBottom: 8,
                },
              ]}
            >
              {Utils.userShortName(props.selectedTutor.name)}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  color: COLOR_TXT_DARK,
                },
              ]}
            >
              {`${Utils.dateFormatLongDateNoYear(
                new Date(props.lessonInfo.selectedDate)
              )}`}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  textDecorationStyle: "solid",
                  color: COLOR_TXT_DARK,
                },
              ]}
            >
              {`Horário: ${Utils.dateFormatHHhmm(
                new Date(props.lessonInfo.selectedDate)
              )}-${Utils.dateFormatHHhmm(new Date(fullClassEndDate))}`}
            </Text>
          </View>
        </View>
        <View>
          <TouchableOpacity
            style={[
              styles.cellBigBase,
              selectedFreeTrial ? styles.cellSelected : styles.cellUnselected,
            ]}
            onPress={() => {
              setSelectedSingle(false);
              setSelectedFreeTrial(true);
            }}
          >
            {selectedFreeTrial ? (
              <View
                style={{
                  height: "8%",
                  marginHorizontal: 14,
                  aspectRatio: 1,
                  position: "absolute",
                  top: 25,
                }}
              >
                <SVGImage source={<CheckGreen />} />
              </View>
            ) : (
              <View style={[styles.circle]} />
            )}
            <View style={{ width: "100%", marginTop: 10 }}>
              <View style={{ flex: 1, marginLeft: 45 }}>
                <Text style={[styles.textBoldBlack, { marginBottom: -4 }]}>
                  Mentoria Educacional
                </Text>
                <Text style={[styles.textRegularBlack]}>
                  +3 dias de acesso grátis
                </Text>
                <Text
                  style={[
                    styles.textRegularBlack,
                    {
                      alignSelf: "flex-end",
                      right: 20,
                      position: "absolute",
                      top: 10,
                    },
                  ]}
                >
                  R$0,00
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  marginTop: -110,
                  backgroundColor: selectedFreeTrial ? "#DBF4E4" : "#F7F9FD",
                  borderRadius: 6,
                }}
              >
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <SVGImage
                    source={selectedFreeTrial ? <CheckSuccess /> : <Check />}
                    style={{ marginTop: 10, marginHorizontal: 12 }}
                  />
                  <Text
                    style={[styles.textRegularBlackSmall, { width: "85.1%" }]}
                  >
                    <B>2 Sessões de Orientação Vocacional</B> com um Mentor
                    exclusivo por mês
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SVGImage
                    source={selectedFreeTrial ? <CheckSuccess /> : <Check />}
                    style={{ marginTop: 10, marginHorizontal: 12 }}
                  />
                  <Text
                    style={[styles.textRegularBlackSmall, { width: "85.1%" }]}
                  >
                    <B>Planejamento Personalizado</B> para você alcançar seus
                    sonhos
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SVGImage
                    source={selectedFreeTrial ? <CheckSuccess /> : <Check />}
                    style={{ marginTop: 10, marginHorizontal: 12 }}
                  />
                  <Text
                    style={[styles.textRegularBlackSmall, { width: "85.1%" }]}
                  >
                    <B>10% de desconto em todo o app </B>para suas aulas e
                    sessões especializadas
                  </Text>
                </View>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.cellBase,
              selectedSingle ? styles.cellSelected : styles.cellUnselected,
            ]}
            onPress={() => {
              setSelectedFreeTrial(false);
              setSelectedSingle(true);
            }}
          >
            <View style={[styles.checkmarkBase, styles.circleBig]}>
              {selectedSingle && <SVGImage source={<CheckGreen />} />}
            </View>
            <Text
              style={[
                styles.textRegularBlack,
                {
                  paddingTop: Platform.OS === "ios" ? 12 : 8,
                  marginBottom: 8,
                },
              ]}
            >
              <B>Sessão Única de Mentoria</B> {"    "} {productPrice}
            </Text>
          </TouchableOpacity>
          <Text
            style={[
              styles.textRegularBlackSmall,
              {
                marginHorizontal: 24,
                marginTop: 16,
                textAlign: "center",
              },
            ]}
          >
            Após o periodo grátis, R$49,90/mês.{"\n"}Cancele quando quiser.
          </Text>
          <TouchableOpacity
            style={[
              {
                margin: 24,
                backgroundColor: COLOR_SUCCESS,
              },
              StyleUtils.shape_btn_small,
            ]}
            onPress={() => {
              handleConfirmFreeSession();
            }}
          >
            <Text
              style={[
                { textAlign: "center", paddingVertical: 14 },
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                StyleUtils.color_txt_white,
              ]}
            >
              Confirmar Mentoria Grátis e Assinar
            </Text>
          </TouchableOpacity>
          <Text
            style={{
              textDecorationLine: "underline",
              marginTop: 16,
              textAlign: "center",
            }}
            onPress={() =>
              Linking.openURL("https://politicas.learning-machine.org/")
            }
          >
            Termos de Uso e Política de Privacidade
          </Text>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  textRegularBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
  },
  textBoldBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
  },
  cellUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    borderColor: COLOR_SUCCESS,
    borderWidth: 2,
    backgroundColor: "#E8F9EE",
  },
  cellBase: {
    height: 68,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginTop: 8,
    marginHorizontal: 24,
  },
  cellBigBase: {
    height: 255,
    borderRadius: 6,
    flexDirection: "row",
    marginHorizontal: 24,
  },
  checkmarkBase: {
    height: "28%",
    marginHorizontal: 11,
    aspectRatio: 1,
    borderRadius: 1000,
    alignItems: "center",
    justifyContent: "center",
  },
  textRegularBlackSmall: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
    marginBottom: 12,
    paddingRight: 8,
  },
  circle: {
    height: "8%",
    marginHorizontal: 14,
    aspectRatio: 1,
    borderRadius: 1000,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
    position: "absolute",
    top: 25,
  },
  circleBig: {
    height: "25%",
    marginLeft: 11,
    marginRight: 11,
    aspectRatio: 1,
    borderRadius: 1000,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: COLOR_MUTED,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TFConfirmationPage);
