import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  ActionSheetIOS,
  Alert,
  TouchableHighlight,
  Platform,
} from "react-native";
import ImagePicker from "react-native-image-crop-picker";

import { standardizeFileName, UploadStatusType } from "../Utils/FileUtils";

import ButtonNextBlue from "../SignUp/ButtonNextBlue";

type Props = {
  descriptionText;
  isMobile;
  uploadFile;
  cancelUpload;
  uploadStatus;
  updateUploadStatus;
};

type State = {
  file;
};

export default class ImageUploaderUINative extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <ButtonNextBlue
          isValidInfo={true}
          onClick={() => {}}
          text={this.props.descriptionText}
        />
      </View>
    );
  }
}
