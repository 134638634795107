import React, { useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_LIGHT,
  COLOR_PRIMARY,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import BackButton from "../../assets/backbutton.svg";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  onBack;
  progress;
  question;
};

export default function AgeTemplate(props: Props) {
  const [age, setAge] = useState(0);

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : undefined}
        style={[StyleUtils.screen_default]}
        keyboardVerticalOffset={40}
      >
        <View style={{ flex: 1 }}>
          <View style={[{ justifyContent: "center", width: "100%" }]}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                margin: 24,
              }}
            >
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  style={{ flexDirection: "row" }}
                  onPress={() => {
                    props.onBack();
                  }}
                >
                  <SVGImage source={<BackButton />} />
                </TouchableOpacity>
              </View>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_16,
                ]}
              >
                Responda e ganhe uma aula grátis!
              </Text>
              <View style={{ flex: 1 }}></View>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
              width: "100%",
              zIndex: 10,
            }}
          />
          <View style={{ height: 4 }}>
            <View
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: COLOR_PRIMARY,
                width: props.progress,
              }}
            />
          </View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text
              style={[
                StyleUtils.color_txt_dark,
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "center",
                  marginBottom: 8,
                },
              ]}
            >
              {props.question}
            </Text>
            <TextInput
              maxLength={2}
              keyboardType={"numeric"}
              editable={true}
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 32,
                  textAlign: "center",
                },
              ]}
              onChangeText={(text) => setAge(parseInt(text))}
            />
            <View
              style={{
                borderBottomColor: COLOR_TXT_LIGHT,
                borderBottomWidth: 1,
                width: 78,
                alignSelf: "center",
              }}
            />
          </View>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              analytics.sendSurveyResponse("Qual a sua idade?", age.toString());
              props.onContinue();
            }}
            disabled={!age}
          />
        </View>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}
