import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { getTutor } from "../Server";
import { selectTutor } from "../Actions";
import { analytics } from "../Analytics";
import SVGImage from "../Utils/SVGImage";
import Logo from "../../assets/logo.svg";

type Props = {
  history;
  match;
  location;
  userID: string;
  selectTutor;
  selectedTutor;
};

const mapStateToProps = (state) => ({
  selectedTutor: state.selRed.selectedTutor,
});

function mapDispatchToProps(dispatch) {
  return {
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
    dispatch,
  };
}

type State = {
  selectedTutor;
};

class DeepLinkTutorProfile extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedTutor: this.props.selectedTutor,
    };
  }

  selectTutorFromUrl = () => {
    const tutorID = this.props.match.params.tutorID;
    if (!this.state.selectedTutor || this.state.selectedTutor.id != tutorID) {
      console.log("Tutor era nulo");
      const tutorID = this.props.match.params.tutorID;

      getTutor(this.props.userID, tutorID, { include: ["rating", "favorite"] })
        .then((t) => {
          this.props.selectTutor(t);
          this.setState({ selectedTutor: t });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.goToTutorProfileScreen();
    }
  };

  goToTutorProfileScreen() {
    analytics.sendTutorProfileWhatsapp();
    this.props.history.replace({ pathname: "/TutorProfile" });
  }

  componentDidMount() {
    this.selectTutorFromUrl();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      prevState.selectedTutor !== this.state.selectedTutor &&
      this.state.selectedTutor
    ) {
      this.goToTutorProfileScreen();
    }
  }

  render() {
    return (
      <View
        style={{
          alignItems: "center",
          alignContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <SVGImage
          style={{ flex: 1, alignSelf: "center", justifySelf: "center" }}
          source={<Logo />}
        ></SVGImage>
      </View>
    );
  }
}

const styles = StyleSheet.create({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeepLinkTutorProfile);
