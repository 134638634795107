import React, { Component } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import SignUpPopUp from "../../SignUp/SignUpPopUp";
import { LinearGradient } from "../../Utils/LinearGradient";
import StyleUtils from "../../Utils/StyleUtils";
import { ViewPager } from "../../Utils/ViewPager";
import RemoteConfigBridge from "../../RemoteConfig/RemoteConfigBridge";
import { analytics } from "../../Analytics";

type Props = {
  onContinue;
  onLogin;
  onBeTeacher;
  history;
};

type State = {
  currentPage: number;
  onboBeTeacherEnabled;
  enableLogin;
  showLogin;
};

export default class SpcBenefits extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      onboBeTeacherEnabled: false,
      enableLogin: false,
      showLogin: false,
    };

    RemoteConfigBridge.remoteConfig("onboBeTeacherEnabled", (value) => {
      var valueBool = value == "true";
      switch (valueBool) {
        case true: {
          this.setState({ onboBeTeacherEnabled: true });
          break;
        }
        default: {
          this.setState({ onboBeTeacherEnabled: false });
          break;
        }
      }
    });
    RemoteConfigBridge.remoteConfig("enableLogin", (value) => {
      var valueBool = value == "1";
      this.setState({ enableLogin: valueBool });
    });
  }

  pages = [
    {
      title: "Encontre seu\nprofessor particular",
      subtitle:
        "Diversos professores disponíveis\npara você evoluir em conhecimento",
      image: require("../../../assets/onboarding1.png"),
    },
    {
      title: "Responda exercícios\ndireto no app",
      subtitle: "Seus professores enviam exercícios\npara você praticar",
      image: require("../../../assets/onboarding2.png"),
    },
    {
      title: "Agende sua aula\nno melhor horário",
      subtitle:
        "Veja os horários disponíveis de cada\nprofessor particular e agende",
      image: require("../../../assets/onboarding3.png"),
    },
  ];

  viewPager;

  onContinue() {
    analytics.sendOnboarding(`${this.state.currentPage + 1}`);
    if (this.state.currentPage < this.pages.length - 1) {
      const newPageIndex = this.state.currentPage + 1;
      this.viewPager.setPage(newPageIndex);
      this.setState({ currentPage: newPageIndex });
    } else {
      this.props.onContinue();
    }
  }

  getButtonText() {
    if (this.state.currentPage < this.pages.length - 1) {
      return "Próximo";
    }
    return "Começar";
  }

  onLoginSuccessPopUp = () => {
    this.props.history.push("/");
  };
  onSignUpSuccessPopUp = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <LinearGradient
        colors={["#0179FF", "#18BAFF"]}
        style={{ flexDirection: "column", flex: 1 }}
      >
        {this.state.enableLogin && (
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_default_rounded,
              StyleUtils.border_color_txt_white,
              {
                alignSelf: "flex-end",
                borderRadius: 6,
                overflow: "hidden",
                margin: 16,
                marginTop: 50,
              },
            ]}
            onPress={() => {
              analytics.sendOnboardingLogin();
              setTimeout(() => {
                this.setState({
                  showLogin: true,
                });
              }, 300);
            }}
          >
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_14,
                {
                  paddingTop: 8,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 6,
                },
              ]}
            >
              Login
            </Text>
          </TouchableOpacity>
        )}
        <View
          style={[
            { height: "62%", alignItems: "center" },
            !this.state.enableLogin && { marginTop: 80 },
          ]}
        >
          <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={["#0055B5", "rgba(1, 121, 255, 0)"]}
            locations={[0.0453, 0.5993]}
            style={{
              position: "absolute",
              top: -30,
              left: -30,
              right: -30,
              bottom: 0,
              height: "100%",
              borderRadius: 10000,
            }}
          ></LinearGradient>
          <ViewPager
            ref={(viewPager) => (this.viewPager = viewPager)}
            style={{ flex: 1, marginTop: "3%", width: "100%" }}
            initialPage={this.state.currentPage}
            onPageSelected={(e) =>
              this.setState({ currentPage: e.nativeEvent.position })
            }
          >
            {this.pages.map((item, index) => {
              return (
                <View key={index}>
                  <Image
                    source={item.image}
                    resizeMode="contain"
                    style={{
                      height: "60%",
                      maxHeight: "60%",
                      alignSelf: "center",
                      width: "100%",
                    }}
                  />
                  <Text
                    style={[
                      StyleUtils.font_size_22,
                      StyleUtils.font_cera_bold,
                      StyleUtils.color_txt_white,
                      {
                        alignSelf: "center",
                        textAlign: "center",
                        margin: 8,
                      },
                    ]}
                  >
                    {item.title}
                  </Text>
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                      StyleUtils.color_txt_white,
                      {
                        alignSelf: "center",
                        textAlign: "center",
                        lineHeight: 20,
                        width: 300,
                      },
                    ]}
                  >
                    {item.subtitle}
                  </Text>
                </View>
              );
            })}
          </ViewPager>
          <PageIndicator
            numberOfPages={this.pages.length}
            currentPage={this.state.currentPage}
          />
        </View>
        <TouchableOpacity
          style={[
            StyleUtils.color_bg_white,
            {
              alignSelf: "center",
              borderRadius: 6,
              overflow: "hidden",
              position: "absolute",
              bottom: 20,
              width: "80%",
              marginBottom: "19%",
              height: 40,
            },
          ]}
          onPress={() => {
            this.onContinue();
          }}
        >
          <View
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.color_txt_dark,
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_18,
                {
                  textAlign: "center",
                  textAlignVertical: "center",
                },
              ]}
            >
              {this.getButtonText()}
            </Text>
          </View>
        </TouchableOpacity>
        {this.state.onboBeTeacherEnabled && (
          <TouchableOpacity
            style={{
              position: "absolute",
              bottom: 15,
              width: "70%",
              alignSelf: "center",
              marginBottom: "6%",
              flex: 1,
            }}
            onPress={() => this.props.onBeTeacher()}
          >
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_cera_medium,
                StyleUtils.font_size_16,
                {
                  textAlign: "center",
                  textDecorationLine: "underline",
                },
              ]}
            >
              Quero ser professor
            </Text>
          </TouchableOpacity>
        )}
        <SignUpPopUp
          show={this.state.showLogin}
          isLogin={true}
          onLoginSuccess={this.onLoginSuccessPopUp}
          onSignUpSuccess={this.onSignUpSuccessPopUp}
          close={() => {
            this.setState({ showLogin: false });
          }}
          history={this.props.history}
          isExerciseOnboarding={false}
        />
      </LinearGradient>
    );
  }
}

function PageIndicator(props: { currentPage: number; numberOfPages: number }) {
  return (
    <View style={{ flexDirection: "row", alignSelf: "center" }}>
      {Array.from(Array(props.numberOfPages).keys()).map((index) => (
        <View
          key={index}
          style={[
            { height: 4, width: 16, borderRadius: 30 },
            index > 0 && { marginLeft: 6 },
            StyleUtils.color_bg_white,
            index != props.currentPage && { opacity: 0.2 },
          ]}
        />
      ))}
    </View>
  );
}
