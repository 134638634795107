import React from "react";
import { Component } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { withRouter } from "../Router";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import LogoMuted from "./../../assets/logo_muted.svg";
import { makeTutor } from "../Server";
import { connect } from "react-redux";

type Props = {
  user;
  history;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

class Footer extends Component<Props, any> {
  render() {
    return (
      <View
        style={[
          StyleUtils.color_bg_overlay,
          {
            height: 68,
            overflow: "hidden",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          },
        ]}
      >
        <SVGImage source={<LogoMuted />} />
        <Text style={[StyleUtils.color_txt_light_icon]}>Termos de Uso</Text>
        <Text style={[StyleUtils.color_txt_light_icon]}>
          Política de Privacidade
        </Text>
        <Text style={[StyleUtils.color_txt_light_icon]}>
          © Tutore 2020. Todos os direitos reservados.
        </Text>
      </View>
    );
  }
}

export default withRouter(connect(stateToProps, null)(Footer));
