import React, { Component } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import SVGImage from "../Utils/SVGImage";
import CircleBlack from "../../assets/circle_black.svg";
import CircleWhite from "../../assets/circle_white.svg";
import StyleUtils from "../Utils/StyleUtils";
import ArrowDown from "../../assets/arrowdown_blue.svg";
import {
  SafeAreaFrameContext,
  SafeAreaInsetsContext,
} from "react-native-safe-area-context";

type Props = {
  sendToCanvas;
  mobile;
};

type State = {
  pages;
  currentPage;
  currentPageOther;
};

export class CanvasPageTools extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pages: [""],
      currentPage: 0,
      currentPageOther: -1,
    };
  }

  addPageControl = (setCurrentPage) => {
    const pageAdd = this.state.pages;
    pageAdd.push("");
    this.setState({
      pages: pageAdd,
    });
    if (setCurrentPage) {
      this.setState({
        currentPage: this.state.pages.length - 1,
      });
    }
  };

  removePageControl = () => {
    const pageAdd = this.state.pages;
    pageAdd.pop();
    this.setState({
      pages: pageAdd,
    });
  };

  renderPageButton = (data) => {
    return (
      <TouchableOpacity
        style={{
          justifyContent: "center",
          height: 32,
          width: 40,
          alignSelf: "center",
          alignItems: "center",
        }}
        onPress={() => {
          if (data.item === "+") {
            this.props.sendToCanvas("addPage", null);
            this.addPageControl(true);
          } else {
            this.props.sendToCanvas("goToPage", data.index);
            this.setState({ currentPage: data.index });
          }
        }}
      >
        <SVGImage
          source={
            this.state.currentPage === data.index ? (
              <CircleBlack />
            ) : (
              <CircleWhite />
            )
          }
        />
        <Text
          style={[
            this.state.currentPage === data.index
              ? StyleUtils.color_txt_white
              : StyleUtils.color_txt_dark,
            {
              position: "absolute",
              alignSelf: "center",
              textAlign: "center",
            },
          ]}
        >
          {data.item === "+" ? data.item : data.index + 1}
        </Text>
        {data.index === this.state.currentPageOther && (
          <View style={{ position: "absolute", top: -10, alignSelf: "center" }}>
            <SVGImage source={<ArrowDown />} />
          </View>
        )}
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          <View
            style={[
              {
                position: "absolute",
                left: this.props.mobile ? 0 : 70,
                right: 0,
                height: 50,
                alignItems: "center",
              },
              this.props.mobile
                ? { bottom: 55 + (insets ? insets.bottom : 0) }
                : { top: 0 },
            ]}
          >
            <FlatList
              style={{
                maxWidth: 550,
              }}
              horizontal={true}
              data={[...this.state.pages, "+"]}
              renderItem={this.renderPageButton}
            />
          </View>
        )}
      </SafeAreaInsetsContext.Consumer>
    );
  }

  handleCanvasAction = (data) => {
    switch (data.action) {
      case "canvasActionLocal":
        switch (data.info.action) {
          case "canvas:added":
            this.addPageControl(false);
            break;
          case "canvas:removed":
            this.removePageControl();
            break;
        }
        break;
    }
  };

  handleRemoteAction = (data) => {
    switch (data.obj.action) {
      case "canvas:added":
        this.addPageControl(false);
        break;
      case "canvas:changePage":
        this.setState({
          currentPageOther: data.obj.canvasIndex,
        });
        break;
    }
  };
}
