import React from "react";
import {
  Image,
  ImageResizeMode,
  ImageStyle,
  View,
  ViewStyle,
} from "react-native";
import AsyncImage from "../Utils/AsyncImage";

interface UserImageBaseProps {
  resizeMode?: ImageResizeMode;
  resizeMethod?: "auto" | "resize" | "scale";
  imageURL?: string;
}

export function UserImage(
  props: {
    style: ImageStyle;
  } & UserImageBaseProps
) {
  const { imageURL, ...other } = props;
  const placeHolder = require("../../assets/userPlaceholderSquare.png");
  const image = props.imageURL ? { uri: props.imageURL } : placeHolder;
  return <Image source={image} defaultSource={placeHolder} {...other} />;
}

export function UserImageBackGround(
  props: {
    imageStyle?: ImageStyle;
    style?: ViewStyle;
    children?: any;
  } & UserImageBaseProps
) {
  const { imageURL, imageStyle, style, ...other } = props;
  const placeHolder = require("../../assets/userPlaceholderSquare.png");
  const image = props.imageURL ? { uri: props.imageURL } : placeHolder;
  return (
    <View style={style}>
      <AsyncImage
        source={image}
        style={[{ width: "100%", height: "100%" }, imageStyle]}
      />
      {props.children}
    </View>
  );
}
