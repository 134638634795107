import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View, Platform } from "react-native";
import { connect } from "react-redux";
import { bookingInfo, setTutorSearchBack, setTuteeInfo } from "../Actions";
import { analytics } from "../Analytics";
import ButtonElement from "../Elements/ButtonElement";
import { Calendar, CalendarHours } from "../Elements/Calendar";
import TitleWithNavigation from "../Elements/TitleWithNavigation";
import { Tutor } from "../Models/TutorInfo";
import {
  getEventsFreeBusy,
  EventsFilter,
  getEvents,
  getUserCredit,
  bookTutor,
  chooseTutor,
  getSubscription,
} from "../Server";
import StyleUtils, { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import * as Utils from "../Utils/Utils";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import { userShortName } from "../Utils/Utils";
import TuteeInfo from "../Models/TuteeInfo";
import ParentsSessionScheduled from "../Onboarding/ParentsOnboarding/ParentsSessionScheduled";
import _ from "lodash";

type Props = {
  dispatch;
  location;
  history;
  mobile?: boolean;
  selectedTutor: Tutor;
  userID: string;
  closeBooking?;
  openConfirmation?;
  onBookingFinished?;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  fromDynamicLink;
};

function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
    userID: state.userRed.user.id,
    mobile: state.layoutRed.mobile,
    fromDynamicLink: state.userRed.fromDynamicLink,
  };
}

function dispatchToProps(dispatch) {
  return {
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch,
  };
}
export const CLASS_MINUTES = 60;

function Booking(props: Props) {
  const [availableDates, setAvailableDates] = useState(false);
  const BookingComponent = props.mobile
    ? !availableDates ||
      (props.location &&
        props.location.state &&
        props.location.state.showCalendar)
      ? BookingMobile
      : BookingNoCalendar
    : BookingWeb;
  const [selectedTime, setSelectedTime] = useState<Date | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    Utils.nextDay(new Date(), 0)
  );
  const [selectedMonth, setSelectedMonth] = useState(
    Utils.nextMonth(selectedDate || new Date(), 0)
  );
  const [availableHours, setAvailableHours] = useState<Array<Date[]>>([]);
  const [firstClassFree, setFirstClassFree] = useState(false);
  const [firstFiveDaysAvailable, setFirstFiveDaysAvailable] = useState<
    Array<Date[]>
  >([]);
  const [purchaseSuccess, setPurchaseSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [tutoreFutureSubs, setTutoreFutureSubs] = useState(false);
  const [firstClassFreeSubscription, setFirstClassFreeSubscription] = useState(
    false
  );
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    analytics.sendScheduleOpen();
    RemoteConfigBridge.remoteConfig("availableDates", (value) => {
      var valueBool = value == "1";
      setAvailableDates(valueBool);
    });
  }, []);

  useEffect(() => {
    fetchAvailableHours(
      props.userID,
      props.selectedTutor.id,
      selectedMonth,
      Utils.nextMonth(selectedMonth)
    )
      .then((hours) => {
        setAvailableHours(hours);
        let filtered = hours.filter(function (x) {
          return x !== undefined;
        });
        var tempHours = filtered.slice(0, 5);
        let length = tempHours.length;
        if (length < 5) {
          let nextMonth = Utils.nextMonth(selectedDate);
          fetchAvailableHours(
            props.userID,
            props.selectedTutor.id,
            nextMonth,
            Utils.nextMonth(nextMonth)
          ).then((hours) => {
            let filteredTwo = hours.filter(function (x) {
              return x !== undefined;
            });
            for (let i = 0; i < 5 - length; ++i) {
              tempHours.push(filteredTwo[i]);
            }
            setFirstFiveDaysAvailable(tempHours);
          });
        } else {
          setFirstFiveDaysAvailable(tempHours);
        }
      })
      .catch(console.log);
  }, [selectedMonth]);

  useEffect(() => {
    RemoteConfigBridge.remoteConfig("firstClassFree", (value) => {
      var valueBool = value == "1";
      if (valueBool && !props.fromDynamicLink) {
        getUserCredit(props.userID).then((res) => {
          if (res.amount === 0) {
            var eventFilter: EventsFilter = {
              tutorId: undefined,
              tuteeId: props.userID,
              periodStart: undefined,
              periodEnd: undefined,
              limit: 1,
            };
            getEvents(eventFilter).then((result: any) => {
              if (result && result.length === 0) {
                setFirstClassFree(true);
              }
            });
          }
        });
      }
    });
    RemoteConfigBridge.remoteConfig("tutoreFutureSubscription", (value) => {
      var valueBool = value == "1";
      if (valueBool) {
        setTutoreFutureSubs(true);
      }
    });
    RemoteConfigBridge.remoteConfig("subscriptionType", (value) => {
      if (parseInt(value) === 1) {
        setFirstClassFreeSubscription(true);
      }
    });
    const subscriptionID =
      Platform.OS === "ios"
        ? "subs_1un_credito_week_7daysfree_40reais"
        : "subs_1un_credito_week_7daysfree_40reais_google";
    const platform = Platform.OS === "ios" ? "apple" : "google";
    getSubscription(platform, props.userID)
      .then((message) => {
        console.log(message);
        if (!message.error) {
          setIsPremium(true);
        }
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
    const tutoreSubscriptionID =
      Platform.OS === "ios"
        ? "subs_tutorefuture1_month_3daysfree_50reais"
        : "subs_tutorefuture1_month_3daysfree_50reais_google";
    getSubscription(platform, props.userID)
      .then((message) => {
        console.log(message);
        if (!message.error) {
          setIsPremium(true);
        }
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  }, []);

  const schedule = () => {
    if (selectedTime) {
      let date = new Date(selectedTime);

      let endDate = new Date(date);
      if (
        props.location &&
        props.location.state &&
        props.location.state.parentsOnboarding
      ) {
        endDate.setMinutes(date.getMinutes() + 15);
      } else {
        props.selectedTutor.tutorInfo?.firstSessionFreeDuration &&
        !firstClassFree
          ? endDate.setMinutes(
              date.getMinutes() +
                props.selectedTutor.tutorInfo.firstSessionFreeDuration
            )
          : endDate.setMinutes(date.getMinutes() + CLASS_MINUTES);
      }

      analytics.sendScheduleConfirmed();
      props.dispatch(
        bookingInfo({
          selectedDate: selectedTime,
          selectedTutor: props.selectedTutor,
          endDate,
        })
      );
      let pathname = "";
      if (
        props.location &&
        props.location.state &&
        props.location.state.tfOnboarding &&
        tutoreFutureSubs
      ) {
        pathname = "/tutorefuture/confirmationpage";
      } else if (firstClassFreeSubscription && !isPremium) {
        pathname = "/SubscriptionConfirmationPage";
      } else if (firstClassFree) {
        pathname = "/FirstClassFreeConfirmationPage";
      } else {
        pathname = props.selectedTutor.tutorInfo?.firstSessionFreeDuration
          ? "/FreeSessionConfirmationPage"
          : "/ConfirmationPage";
      }

      const source = _.get(props, "location.state.source", null);

      if (
        props.location &&
        props.location.state &&
        props.location.state.reschedule
      ) {
        props.history.push({
          pathname: "/ConfirmationPage",
          state: {
            subject: props.location.state.subject,
            subjectLevel: props.location.state.subjectLevel,
            reschedule: true,
            eventID: props.location.state.eventID,
            source: source,
          },
        });
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.classroomEnd
      ) {
        props.history.push({
          pathname: "/ConfirmationPage",
          state: { classroomEnd: true, source: source },
        });
      } else if (props.closeBooking) {
        props.closeBooking();
        props.openConfirmation();
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.subject
      ) {
        let subjectTestID = "";
        if (props.location.state.subjectTestID) {
          subjectTestID = props.location.state.subjectTestID;
          pathname = "/ConfirmationPage";
        }
        props.history.push({
          pathname,
          state: {
            subject: props.location.state.subject,
            subjectLevel: props.location.state.subjectLevel,
            subjectTestID: subjectTestID,
            source: source,
          },
        });
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.parentsOnboarding
      ) {
        bookTutor(
          props.userID,
          props.selectedTutor.id,
          date,
          endDate,
          "",
          "",
          "parents_onboarding"
        )
          .then(() => {
            return chooseTutor(props.userID, props.selectedTutor.email);
          })
          .then((tuteeInfo) => {
            props.setTuteeInfo(tuteeInfo);
            setPurchaseSuccess(true);
          });
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.mentorship
      ) {
        props.history.push("/tutorefuture/confirmationpage", {
          source: source,
        });
      } else {
        props.history.push({
          pathname,
          state: { classroomEnd: false, source: source },
        });
      }
    }
  };

  if (purchaseSuccess !== undefined) {
    if (selectedTime) {
      let date = new Date(selectedTime);
      let endDate = new Date(date);
      endDate.setMinutes(date.getMinutes() + 15);
      return (
        <ParentsSessionScheduled
          tutorImage={props.selectedTutor.imageURL}
          lessonDate={date}
          lessonEndDate={endDate}
          tutorName={userShortName(props.selectedTutor.name)}
          history={props.history}
          classroomEnd={false}
          tutorID={props.selectedTutor.id}
          numRecurringClass={1}
        />
      );
    }
  }

  return (
    <View
      style={[
        StyleUtils.screen_default,
        StyleUtils.color_bg_white,
        { flex: 6 },
      ]}
    >
      <BookingComponent
        {...props}
        onGoBackPress={() => {
          if (
            props.location &&
            props.location.state &&
            props.location.state.useTutorSearchBack
          ) {
            props.dispatch(setTutorSearchBack());
          } else if (props.closeBooking) {
            props.closeBooking();
          } else {
            props.history.goBack();
          }
        }}
        selectedDate={selectedDate}
        selectedMonth={selectedMonth}
        onMonthChange={(month) => {
          setSelectedMonth(month);
          setSelectedDate(undefined);
          setSelectedTime(undefined);
        }}
        onDatePress={(date) => {
          setSelectedDate(date);
          setSelectedTime(undefined);
        }}
        selectedTime={selectedTime}
        onHourPress={(hour) => setSelectedTime(hour)}
        hours={(selectedDate && availableHours[selectedDate.getDate()]) || []}
        selectableDates={availableHours}
        onSchedulePress={() => schedule()}
        availableDates={firstFiveDaysAvailable}
      />
    </View>
  );
}

type BookingProps = Props & {
  onGoBackPress: () => void;
  selectedDate?: Date;
  selectedMonth: Date;
  onMonthChange?: (month: Date) => void;
  onDatePress?: (day: Date) => void;
  selectedTime?: Date;
  hours: Date[];
  onHourPress?: (hour: Date) => void;
  selectableDates?: Array<any>;
  onSchedulePress?: () => void;
  onPressCalendar?: () => void;
  availableDates: Array<Date[]>;
};

function BookingMobile(props: BookingProps) {
  const timePressed = (time: Date) => {
    if (props.onHourPress) {
      analytics.sendScheduleTimeChosen(time);
      props.onHourPress(time);
    }
  };

  return (
    <>
      <TitleWithNavigation
        title={
          props.location &&
          props.location.state &&
          props.location.state.classroomEnd
            ? "Agendar Próxima Aula"
            : "Escolha um horário"
        }
        mobile={props.mobile}
        onGoBackPress={props.onGoBackPress}
        options={{ colorStyle: "dark" }}
        history={props.history}
        skip={
          props.location &&
          props.location.state &&
          props.location.state.classroomEnd
        }
      />
      <View style={{ flex: 1 }}>
        <Calendar
          selectedDate={props.selectedDate}
          selectedMonth={props.selectedMonth}
          onMonthChange={props.onMonthChange}
          onDayPress={props.onDatePress}
          selectableDates={props.selectableDates}
        />
        {props.selectedDate && (
          <CalendarHours
            selectedDate={props.selectedDate}
            hours={props.hours}
            onHourPress={timePressed}
            selectedTime={props.selectedTime}
            mobile={true}
          />
        )}
      </View>
      {props.selectedTime && (
        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Agendar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={props.onSchedulePress}
          />
        </View>
      )}
    </>
  );
}

function BookingWeb(props: BookingProps) {
  return (
    <View
      style={[{ width: "80%", alignSelf: "center", alignItems: "stretch" }]}
    >
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.font_size_22,
          StyleUtils.color_txt_dark,
          { marginVertical: 32 },
        ]}
      >
        Meu calendário
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <View
          style={[
            StyleUtils.border_color_btn_muted,
            { borderRadius: 16, overflow: "hidden", height: 556, width: 375 },
          ]}
        >
          <Calendar
            selectedDate={props.selectedDate}
            selectedMonth={props.selectedMonth}
            onMonthChange={props.onMonthChange}
            onDayPress={props.onDatePress}
            selectableDates={props.selectableDates}
          />
        </View>
        <View style={{ width: 8 }} />
        <View
          style={[
            StyleUtils.border_color_btn_muted,
            {
              borderRadius: 16,
              overflow: "hidden",
              height: 556,
              width: 758,
              flex: 1,
            },
          ]}
        >
          {props.selectedDate && (
            <CalendarHours
              selectedDate={props.selectedDate}
              hours={props.hours}
              onHourPress={props.onHourPress}
              selectedTime={props.selectedTime}
            />
          )}
          {props.selectedTime && (
            <View style={styles.buttonContainer}>
              <ButtonElement
                text="Agendar"
                colorScheme="primary"
                styte={{
                  borderRadius: 6,
                  marginHorizontal: 24,
                  marginVertical: 24,
                }}
                textStyle={StyleUtils.font_size_16}
                onPress={props.onSchedulePress}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

function BookingNoCalendar(props: BookingProps) {
  const timePressed = (time: Date) => {
    if (props.onHourPress) {
      analytics.sendScheduleTimeChosen(time);
      props.onHourPress(time);
    }
  };

  return (
    <>
      <TitleWithNavigation
        title={
          props.location &&
          props.location.state &&
          props.location.state.classroomEnd
            ? "Agendar Próxima Aula"
            : "Escolha um horário"
        }
        mobile={props.mobile}
        onGoBackPress={props.onGoBackPress}
        options={{ colorStyle: "dark" }}
        history={props.history}
        skip={
          props.location &&
          props.location.state &&
          props.location.state.classroomEnd
        }
        onPressCalendar={() => {
          analytics.sendShowCalendar();
          props.history.push({
            pathname: "/booking",
            state: {
              showCalendar: true,
              source: _.get(props, "location.state.source", null),
            },
          });
        }}
      />
      <ScrollView style={{ flex: 1, marginBottom: 24 }}>
        <Text
          style={[
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_22,
            StyleUtils.font_cera_bold,
            { marginTop: 24, marginLeft: 24 },
          ]}
        >
          Próximas datas disponíveis
        </Text>
        {props.availableDates.map((d, index) => {
          return (
            d && (
              <CalendarHours
                selectedDate={d[0]}
                hours={d}
                onHourPress={timePressed}
                selectedTime={props.selectedTime}
                mobile={true}
                noScroll={true}
              />
            )
          );
        })}
      </ScrollView>
      {props.selectedTime && (
        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Agendar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={props.onSchedulePress}
          />
        </View>
      )}
    </>
  );
}

export function fetchAvailableHours(
  userId: string,
  tutorId: string,
  timeStart: Date,
  timeEnd: Date
): Promise<Date[][]> {
  const now = new Date();
  now.setHours(now.getHours() + 12);
  if (now > timeEnd) {
    return Promise.resolve<Date[][]>([]);
  }
  let nextHour = timeStart;
  if (now >= timeStart && now < timeEnd) {
    const dayStart = Utils.nextDay(now, 0);
    const minutesOfDay = (now.getTime() - dayStart.getTime()) / 60000;
    const nextHourMinutes =
      (Math.floor(minutesOfDay / CLASS_MINUTES) + 1) * CLASS_MINUTES;
    nextHour = new Date(dayStart.getTime() + nextHourMinutes * 60000);
  }
  return getEventsFreeBusy(userId, {
    timeMin: nextHour,
    timeMax: timeEnd,
    items: [{ id: userId }, { id: tutorId }],
  })
    .then((busy) => {
      const month: Array<Date[]> = [];
      function pushDate(date: Date) {
        const d = date.getDate();
        if (month[d] === undefined) {
          month[d] = [];
        }
        month[d].push(date);
      }
      let busyIndex = 0;
      function nextBusy() {
        if (busyIndex < busy.length) {
          return busy[busyIndex++];
        }
        return { start: timeEnd, end: timeEnd };
      }

      let nextFreeHour = new Date(nextHour);
      while (nextFreeHour < timeEnd) {
        const nextBusyValue = nextBusy();
        while (nextFreeHour < nextBusyValue.start) {
          pushDate(new Date(nextFreeHour));
          nextFreeHour.setMinutes(nextFreeHour.getMinutes() + CLASS_MINUTES);
        }

        // round up minutes
        if (nextBusyValue.end.getMinutes() > 0) {
          nextBusyValue.end.setMinutes(
            nextBusyValue.end.getMinutes() + CLASS_MINUTES
          );
          nextBusyValue.end.setMinutes(0);
        }
        nextFreeHour = nextBusyValue.end;
      }

      return month;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
});

export default connect(mapStateToProps, dispatchToProps)(Booking);
