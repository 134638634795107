import { NativeModules } from "react-native";

class FacebookAnalytics {
  constructor() {}

  sendEvent(eventName: string, params: any) {
    NativeModules.FacebookAnalyticsManager.sendEvent(eventName, params || null);
  }
}

const FacebookAnalyticsManager = new FacebookAnalytics();

export { FacebookAnalyticsManager };
