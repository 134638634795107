import axios from "axios";
import Assignment from "../Models/Assignment";
import AssignmentState from "../Models/AssignmentState";
import Exercise from "../Models/Exercise";
import Homework from "../Models/Homework";
import Rating from "../Models/Rating";
import { Subject, SubjectLevel } from "../Models/Subjects";
import TuteeInfo from "../Models/TuteeInfo";
import {
  NotificationType,
  TutoreNotification,
} from "../Models/TutoreNotification";
import TutorInfo, { Tutor } from "../Models/TutorInfo";
import User from "../Models/User";
import { getDeviceToken } from "../Notifications/NotificationsManager";
import { createSocket } from "./ServerSocket";
import { uuidv4 } from "../Utils/Utils";
import ChatUser from "../Models/ChatUser";
import { analytics } from "../Analytics";
import TestQuestionState from "../Models/TestQuestionState";
import Environment from "../Environment";
import { Platform } from "react-native";

const serverURL = Environment.API_URL;
const websiteURL = Environment.WEBSITE_URL;
const whiteboardURL = Environment.WHITEBOARD_URL;
const signature = Environment.API_SIGNATURE;
const getnetUrl = Environment.GETNET_URL;
const getnetSellerId = Environment.GETNET_SELLER_ID;

const socketIOServerURL = serverURL;

export { whiteboardURL };

const imageUploadPath = "persistent/";
let axiosSource: any = null;
let xhrUpload: any = null;

let subjects: Subject[] | null = null;
let subjectLevels: SubjectLevel[] | null = null;

let cachedMessages = {};

export type AssignmentsFilter = {
  tuteeId: string | undefined;
  tutorId: string | undefined;
  limit: number | undefined;
  states?: AssignmentState[];
};

export type TestQuestionsFilter = {
  tuteeId: string | undefined;
  tutorId: string | undefined;
  states?: TestQuestionState[];
};

export type EventsFilter = {
  tuteeId: string | undefined;
  tutorId: string | undefined;
  periodStart: string | undefined;
  periodEnd: string | undefined;
  limit: number | undefined;
};

export type WhiteboardData = {
  uuid: string | undefined;
  token: string | undefined;
  error: any;
};

export type MessageData = {
  from: any;
  to: any;
  text: any;
  timestamp: any;
  channel: any;
  imageUrl?: any;
};

export function createSockeckIO(options?: { namespace?: string }) {
  const { namespace, ...otherOptions } = options || { namespace: undefined };
  return createSocket(socketIOServerURL + (namespace || ""), {
    ...otherOptions,
  });
}

function createQueryString(query?: { paramNames: string[]; obj }) {
  let queryObj: any = undefined;
  if (query && query.obj) {
    query.paramNames.forEach((param) => {
      if (query.obj[param]) {
        if (!queryObj) {
          queryObj = {};
        }
        queryObj[param] = query.obj[param];
      }
    });
  }
  return !queryObj ? "" : "?" + new URLSearchParams(queryObj);
}

function getMethod<T>(
  userId: string | undefined,
  path: string,
  query?: { paramNames: string[]; obj },
  allowCache: boolean = true
): Promise<T> {
  //console.log(`getMethod req ${path}`);
  return new Promise(function (resolve, reject) {
    let tmpHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      signature: signature,
      ...(userId ? { userid: userId } : {}),
    };
    if (!allowCache) {
      tmpHeaders["Cache-Control"] = "no-cache, no-store, must-revalidate";
      tmpHeaders["Pragma"] = "no-cache";
      tmpHeaders["Expires"] = 0;
    }

    fetch(`${serverURL}${path}${createQueryString(query)}`, {
      method: "GET",
      headers: tmpHeaders,
    })
      .then((res) => res.json())
      .then((res) => {
        //console.log(`getMethod rsp ${path}`);
        //console.log(res);
        resolve(res);
      })
      .catch((error) => {
        console.log(`getMethod err ${path}`);
        console.log(error);
        reject(error);
      });
  });
}

function putMethod<T>(
  userId: string,
  path: string,
  validParams: string[],
  obj: any
): Promise<T> {
  return new Promise(function (resolve, reject) {
    console.log(`putMethod req ${path}`);
    const updateParams = {};
    validParams.forEach((p) => {
      if (obj && obj[p] !== undefined) {
        updateParams[p] = obj[p];
      }
    });

    fetch(`${serverURL}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userId,
      },
      body: JSON.stringify(updateParams),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`putMethod rsp ${path}`);
        resolve(res);
      })
      .catch((error) => {
        console.log(`putMethod err ${path}`);
        console.log(error);
        reject(error);
      });
  });
}

function postMethod<T>(
  userId: string,
  path: string,
  validParams: string[],
  obj: any
): Promise<T> {
  return new Promise(function (resolve, reject) {
    console.debug(`POST req ${path}`);
    const updateParams = {};
    validParams.forEach((p) => {
      if (obj && obj[p] !== undefined) {
        updateParams[p] = obj[p];
      }
    });

    fetch(`${serverURL}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userId,
      },
      body: JSON.stringify(updateParams),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((res) => {
        console.debug(`POST rsp ${path}`);
        resolve(res);
      })
      .catch((error) => {
        console.log(`POST err ${path}`);
        console.log(error);
        reject(error);
      });
  });
}

//--- LOGIN ---//

export type LoginResponse = {
  user: User;
  tuteeInfo?: TuteeInfo;
  tutorInfo?: TutorInfo;
};

export function getUser(options?: { email?: string }): Promise<{ user: User }> {
  return getMethod<{ user: User }>(undefined, "/users", {
    paramNames: ["email"],
    obj: options,
  }).then((res) => {
    console.log(res);
    return res;
  });
}

export function getUserFromId(userId: string): Promise<{ user: User }> {
  return getMethod<{ user: User }>(undefined, `/users/${userId}`);
}

export function validateAppleId(
  token,
  nonce
): Promise<{ code: number; user?: User }> {
  return postMethod<{ code: number; user?: User }>(
    "null",
    "/validate/asi",
    ["token", "nonce"],
    { token, nonce }
  );
}

export function serverLogin(
  name: string,
  email: string,
  imageURL: string,
  gender?: string,
  birthday?: string,
  appleId?: string
): Promise<LoginResponse> {
  return new Promise(function (resolve, reject) {
    getDeviceToken()
      .then((token) => {
        console.log("TOKEN: " + token);
        if (name === "") name = "No Name";
        return fetch(serverURL + "/user", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            signature: signature,
          },
          body: JSON.stringify({
            email: email,
            name: name,
            imageURL: imageURL,
            token: token,
            birthday: birthday,
            gender: gender,
            appleId: appleId,
          }),
        });
      })
      .then((res) => res.json())
      .then((res: any) => {
        console.log("serverLogin rsp");
        console.log(res);
        const {
          id,
          email,
          name,
          imageURL,
          gender,
          birthday,
          tuteeInfo,
          tutorInfo,
        } = res[0];
        let loginResponse: LoginResponse = {
          user: { id, name, email, imageURL, gender, birthday },
          tuteeInfo,
          tutorInfo,
        };
        analytics.setUserID(id);
        resolve(loginResponse);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function updateUser(
  userId: string,
  name?: string,
  guardian?: boolean,
  survey_answered?: boolean
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/users/" + userId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        name,
        guardian,
        survey_answered,
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        reject(error);
      });
  });
}
export function serverLogout(user: User): Promise<LoginResponse> {
  return new Promise(function (resolve, reject) {
    return fetch(serverURL + "/user/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        id: user.id,
      }),
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log("Could not log out. Error:" + error);
        reject(error);
      });
  });
}

//--- TUTOR ---//

export function getTutees(userid: string, tutorId: string) {
  return new Promise<User[]>(function (resolve, reject) {
    fetch(serverURL + `/tutors/${tutorId}/tutees`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((tutees) => {
        resolve(tutees);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function getTutorFromEmail(email: string) {
  const getUrl = serverURL + "/tutor?email=" + email;
  return new Promise(function (resolve, reject) {
    fetch(getUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function getTutors(userID: string) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/tutors", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        tuteeid: userID,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

type TutorQueryIncludes = "rating" | "favorite" | "onlineState" | "superTutor";

interface GetTutorOptions {
  limit?: number;
  offset?: number;
  include?: Array<TutorQueryIncludes>;
  subject?: string;
  level?: string;
  subjectId?: string;
  levelId?: string;
}

export async function getTutors2(
  userID: string,
  options?: GetTutorOptions
): Promise<{ tutors: Tutor[] }> {
  return await getMethod(userID, "/tutors", {
    paramNames: [
      "limit",
      "offset",
      "include",
      "filter",
      "subject",
      "level",
      "subjectId",
      "levelId",
    ],
    obj: options,
  }).then((res: any) => {
    const tutors =
      res &&
      res.map((t: any) => {
        const { user, ...tutorInfo } = t;
        const tutor: Tutor = user;
        tutor.tutorInfo = tutorInfo;
        if (tutorInfo.hourPrice && tutorInfo.currencyCode) {
          tutorInfo.hourPrice = Number(tutorInfo.hourPrice);
        } else {
          // TODO remove
          if (tutor.id === "4a60c1ae-532c-4dd1-8352-d11577e2f0e2") {
            tutorInfo.hourPrice = 0;
          } else {
            tutorInfo.hourPrice = 34.9;
          }
          tutorInfo.currencyCode = "BRL";
        }
        return user;
      });
    return { tutors: tutors };
  });
}

export async function getTutorsByArea(
  area: string,
  userId
): Promise<{ tutors: Tutor[] }> {
  return new Promise(function (resolve, reject) {
    let paramNames = ["area"];
    let obj: any = { area };
    if (userId) {
      paramNames = ["userId", "area"];
      obj = { userId: userId, area: area };
    }
    fetch(
      `${serverURL}/tutorsByArea${createQueryString({
        paramNames,
        obj,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.tutors);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTutorInfo(
  userID: string,
  tutorId: string
): Promise<TutorInfo> {
  // TODO fazer um endpoint específico
  return new Promise(function (resolve, reject) {
    getTutors(userID)
      .then((tutors: any) => {
        let tutorInfo: TutorInfo | null = null;
        if (tutors && tutors.length) {
          for (let i = 0; i < tutors.length; i++) {
            let tutor = tutors[i];
            if (tutor.id === tutorId && tutor.schedule) {
              tutorInfo = { schedule: tutor.schedule };
            }
          }
        }
        if (tutorInfo) {
          console.log("getTutorInfo rsp");
          resolve(tutorInfo);
        } else {
          throw "Tutor info not found";
        }
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function setTutorInfo(
  userID: string,
  tutorId: string,
  obj: any
): Promise<TutorInfo> {
  return putMethod(
    userID,
    `/tutors/${tutorId}/info`,
    [
      "schedule",
      "tagLine",
      "shortDescription",
      "longDescription",
      "specialties",
      "fiveMinutesOffer",
      "subjects",
      "subjectLevels",
    ],
    obj
  );
}

export function getTutor(
  userId: string,
  tutorId: string,
  options?: { include?: Array<TutorQueryIncludes> }
): Promise<Tutor> {
  return getMethod<Tutor>(userId, `/tutors/${tutorId}`, {
    paramNames: ["include"],
    obj: options,
  }).then((tutor) => {
    const tutorInfo = tutor.tutorInfo || {};
    if (tutorInfo.hourPrice && tutorInfo.currencyCode) {
      tutorInfo.hourPrice = Number(tutorInfo.hourPrice);
    } else {
      // TODO remove
      if (tutor.id === "5e050ff0-f6c5-4c38-9d91-ec4cc2b0b128") {
        tutorInfo.hourPrice = 0;
      } else {
        tutorInfo.hourPrice = 34.9;
      }
      tutorInfo.currencyCode = "BRL";
    }
    return tutor;
  });
}

export function setTutor(
  userId: string,
  tutorId: string,
  params: { favorite?: boolean }
): Promise<any> {
  return postMethod<any>(
    userId,
    `/users/${userId}/tutors/${tutorId}`,
    ["favorite"],
    params
  );
}

export function bookTutor(
  userID: string,
  tutorID: string,
  startDate: Date,
  endDate: Date,
  subject: string,
  subjectLevel: string,
  source: string
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/event", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        tuteeid: userID,
      },
      body: JSON.stringify({
        tutorID: tutorID,
        startDate: startDate,
        endDate: endDate,
        subject: subject,
        subjectLevel: subjectLevel,
        source: source,
      }),
    })
      .then((res) => {
        resolve();
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function rescheduleEvent(
  userID: string,
  tutorID: string,
  eventID: string,
  startDate: Date,
  endDate: Date,
  comments: string
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/events/reschedule", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId: userID,
        tutorId: tutorID,
        eventId: eventID,
        startDate: startDate,
        endDate: endDate,
        comments: comments,
      }),
    })
      .then((res) => {
        resolve();
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function getEvents(eventsFilter: EventsFilter) {
  let queryObj = {};
  let propertyNames = [
    "tuteeId",
    "tutorId",
    "periodStart",
    "periodEnd",
    "limit",
  ];
  for (const prop of propertyNames) {
    if (eventsFilter[prop]) {
      queryObj[prop] = eventsFilter[prop];
    }
  }

  const url = serverURL + "/events?" + new URLSearchParams(queryObj);
  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: null,
    })
      .then((res) => {
        return res.json();
      })
      .then((events) => {
        const validEvents = events.reduce((acc, it) => {
          return it.tutor && it.tutee ? acc.concat(it) : acc;
        }, []);
        resolve(validEvents);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

//--- WHITEBOARD ---//

export async function fetchWhiteboardData(
  eventid: string,
  createIfMissing: boolean
): Promise<WhiteboardData> {
  const url =
    serverURL +
    "/whiteboard?" +
    new URLSearchParams({
      eventid: eventid,
      createIfMissing: "" + createIfMissing,
    });
  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: null,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(
          "Could not fetch whiteboard data for eventid:" +
            eventid +
            ". Error:" +
            error
        );
        reject(error);
      });
  });
}

export function chooseTutor(userid, tutorInput): Promise<TuteeInfo> {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + `/tutees/${userid}/info`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: JSON.stringify({
        tutor: tutorInput,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("chooseTutor request fail");
        }
      })
      .then((tuteeInfo) => {
        resolve(tuteeInfo);
      })
      .catch((error) => {
        console.log("erro");
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

//--- EXERCISES ---//
export function deleteExercise(userId, exerciseId) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exercises", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,
        exerciseId,
      }),
    })
      .then((res) => {
        if (res.ok) {
          resolve(res);
        } else {
          console.log("ERROR", res);
          throw new Error("failed to delete exercise");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteExerciseDirectory(userId, directoryId) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exerciseDirectory", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId: userId,
        directoryId: directoryId,
      }),
    })
      .then((res) => {
        if (res.ok) {
          resolve(res);
        } else {
          console.log("ERROR", res);
          throw new Error("failed to delete exercise directory");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateExerciseDirectory(userId, directoryId, name, parentId?) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exerciseDirectory", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,
        directoryId,
        name,
        parentId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateExercise(
  userId,
  exerciseId,
  name?,
  description?,
  directoryId?
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exercises", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,

        directoryId,
        description,
        name,
        exerciseId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data);
        }
        throw new Error("Failed to update exercise");
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postExerciseDirectory(userId, name, parentId?) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exerciseDirectory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        name: name,
        userId: userId,
        parentId: parentId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getExerciseDirectories(userId, directoryId?): Promise<any> {
  return getMethod<any>(userId, "/exerciseDirectory", {
    paramNames: ["userId", "directoryId"],
    obj: { userId, directoryId },
  }).catch((err) => {
    console.log(err);
    return undefined;
  });
}

export function getHomework(homeworkID): Promise<undefined | Homework> {
  return getMethod<undefined | Homework>(homeworkID, "/homework", {
    paramNames: ["homeworkID"],
    obj: { homeworkID },
  }).catch((err) => {
    console.log(err);
    return undefined;
  });
}

export function getHomeworks(
  assignmentsFilter: AssignmentsFilter
): Promise<Homework[]> {
  let queryObj = {};
  let propertyNames = ["tuteeId", "tutorId", "limit"];
  for (const prop of propertyNames) {
    if (assignmentsFilter[prop]) {
      queryObj[prop] = assignmentsFilter[prop];
    }
  }

  const url = serverURL + "/homeworks?" + new URLSearchParams(queryObj);
  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: null,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function getAssignments(
  assignmentsFilter: AssignmentsFilter
): Promise<any> {
  let queryObj = {};
  let propertyNames = ["tuteeId", "tutorId", "limit", "states"];
  for (const prop of propertyNames) {
    if (assignmentsFilter[prop]) {
      queryObj[prop] = assignmentsFilter[prop];
    }
  }

  const url = serverURL + "/assignments?" + new URLSearchParams(queryObj);
  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: null,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function putAssignment(assignmentID, components): Promise<Assignment> {
  return new Promise(function (resolve, reject) {
    const { userAnswer, answerImageURL, tutorCorrectionComment } = components;
    fetch(serverURL + "/assignments", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        assignmentID: assignmentID,
        userAnswer: userAnswer,
        userAnswerImageURL: answerImageURL,
        tutorCorrectionComment: tutorCorrectionComment,
      }),
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((res: Assignment) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function deleteAssignmentImage(assignmentID) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/assignments/deleteImage", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        assignmentID: assignmentID,
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        console.log("Error deleting image for assignment " + assignmentID);
        reject(error);
      });
  });
}

//--- FILE UPLOAD ---//
export function uploadFile(file, onSuccess, onFailure, onCancel) {
  if (!file) {
    console.log("Error: missing file to upload");
    onFailure(null);
    return;
  }

  axiosSource = axios.CancelToken.source();
  const generatePutUrl = serverURL + "/images/put";
  const options = {
    params: {
      Key: imageUploadPath + file.name,
      ContentType: file.type,
    },
    headers: {
      signature: signature,
    },
    cancelToken: axiosSource.token,
  };

  axios
    .get(generatePutUrl, options)
    .then((res) => {
      const {
        data: { putURL },
      } = res;
      return axios.put(putURL, file, {
        cancelToken: axiosSource.token,
        headers: {
          signature: signature,
        },
      });
    })
    .then((res) => {
      axiosSource = null;
      if (onSuccess) {
        onSuccess(res);
      }
    })
    .catch((err) => {
      console.log(err);
      axiosSource = null;
      if (axios.isCancel(err) && onCancel) {
        onCancel();
      } else if (onFailure) {
        onFailure(err);
      }
    });
}

export function uploadNativeFile(file, onSuccess, onFailure, onCancel) {
  if (!file) {
    console.log("Error: missing file to upload");
    onFailure(null);
    return;
  }

  axiosSource = axios.CancelToken.source();
  const generatePutUrl = serverURL + "/images/put";
  const options = {
    params: {
      Key: imageUploadPath + file.fileName,
      ContentType: file.type,
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      signature: signature,
    },
    cancelToken: axiosSource.token,
  };

  axios
    .get(generatePutUrl, options)
    .then((res) => {
      const {
        data: { putURL },
      } = res;

      const xhr = new XMLHttpRequest();
      xhr.open("PUT", putURL);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.setRequestHeader("signature", signature);
      xhr.onabort = () => {
        console.log("CANCEL upload");
        if (onCancel) {
          onCancel();
        }
      };

      xhrUpload = xhr;
      xhr.send({
        uri: file.uri,
        type: file.type,
        name: file.fileName,
      });

      xhr.onload = () => {
        console.log("FINISHED upload");
        xhrUpload = null;
        if (xhr.status != 200) {
          if (onFailure) {
            onFailure(`${xhr.status}: ${xhr.statusText}`);
          }
        } else {
          if (onSuccess) {
            onSuccess(xhr.response);
          }
        }
      };

      xhr.onerror = () => {
        console.log("ERROR upload");
        if (onFailure) {
          onFailure(`${xhr.status}: ${xhr.statusText}`);
        }
      };
    })
    .catch((err) => {
      console.log(err);
      xhrUpload = null;
      if (onFailure) {
        onFailure(err);
      }
    });
}

export function cancelFileUpload() {
  if (axiosSource) {
    axiosSource.cancel();
  }
  if (xhrUpload) {
    xhrUpload.abort();
    xhrUpload = null;
  }
}

export function getFileURL(filename) {
  return websiteURL + "/" + imageUploadPath + filename;
}

//--- CHAT ---//

export function getRelatedUsers(
  userId: string
): Promise<{ users: ChatUser[] }> {
  return getMethod<{ users: ChatUser[] }>(userId, `/chat/relatedUsers`);
}

export function visualizeMessages(from, to, channel) {
  return new Promise(function (resolve, reject) {
    axios
      .get(serverURL + "/chat/visualize", {
        params: {
          from: from,
          to: to,
          channel: channel,
        },
        headers: {
          signature: signature,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("visualize message error from: error:" + error);
        reject(error);
      });
  });
}

export function saveMessageWeb(message: MessageData) {
  return saveMessageCommon(message, saveCachedMessageWeb);
}

export function saveMessageNative(message: MessageData) {
  return saveMessageCommon(message, saveCachedMessageNative);
}

function saveMessageCommon(message: MessageData, saveCachedMessage) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/chat/save", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify(message),
    })
      .then((res) => {
        saveCachedMessage(message);
        resolve(res);
      })
      .catch((error) => {
        console.log("Error on chat save:" + error);
        reject(error);
      });
  });
}

export function loadMessagesWeb(channel, users) {
  return loadMessages(channel, users, (message: any, name: string): any => {
    const messageDict = {
      id: message.id,
      text: message.text,
      user: {
        id: message.from,
        name: name,
      },
      createdAt: message.timestamp,
      sent: true,
    };
    if (message.imageUrl) {
      messageDict["image"] = message.imageUrl;
    }
    return messageDict;
  });
}

export function loadMessagesNative(channel, users) {
  return loadMessages(channel, users, (message: any, name: string): any => {
    let messageDict = {
      _id: message.id,
      text: message.text,
      user: {
        _id: message.from,
        name: name,
      },
      createdAt: message.timestamp,
      sent: true,
    };
    if (message.imageUrl) {
      messageDict["image"] = message.imageUrl;
    }
    return messageDict;
  });
}

function loadMessages(channel, users, buildMessage) {
  const userIds: string[] = [];
  const userIdToName = {};

  for (const user of users) {
    userIds.push(user.id);
    userIdToName[user.id] = user.name;
  }

  return new Promise(function (resolve, reject) {
    axios
      .get(serverURL + "/chat/load", {
        params: {
          channel: channel,
          users: userIds,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      })
      .then((res) => {
        const messages: any[] = [];
        for (const message of res.data) {
          messages.push(buildMessage(message, userIdToName[message.from]));
        }

        const cacheId = generateMessageCacheId(channel, userIds);
        cachedMessages[cacheId] = messages.slice(0);
        resolve(messages);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

function generateMessageCacheId(channel, userIds) {
  const sortedIds: User[] = [].concat(userIds).sort();

  let cacheId = channel;
  for (const userId of sortedIds) {
    cacheId += userId;
  }
  return cacheId;
}

function saveCachedMessageWeb(message) {
  saveCachedMessageCommon(message, (message) => {
    const messageDict = {
      id: uuidv4(),
      text: message.text,
      user: {
        id: message.from,
        name: "Cache",
      },
      createdAt: message.timestamp,
    };
    if (message.imageUrl) {
      messageDict["image"] = message.imageUrl;
    }
    return messageDict;
  });
}

function saveCachedMessageNative(message) {
  saveCachedMessageCommon(message, (message) => {
    const messageDict = {
      _id: uuidv4(),
      text: message.text,
      user: {
        _id: message.from,
        name: "Cache",
      },
      createdAt: message.timestamp,
    };
    if (message.imageUrl) {
      messageDict["image"] = message.imageUrl;
    }
    return messageDict;
  });
}

// TODO: limit number of cached messages, it could get nasty
function saveCachedMessageCommon(message, messageBuilder) {
  const cacheId = generateMessageCacheId(message.channel, [
    message.from,
    message.to,
  ]);

  const structuredMessage = messageBuilder(message);
  if (cachedMessages[cacheId]) {
    cachedMessages[cacheId].splice(0, 0, structuredMessage);
  } else {
    cachedMessages[cacheId] = [structuredMessage];
  }
}

export function getCachedMessages(channel, users) {
  const userIds: string[] = [];
  for (const user of users) {
    userIds.push(user.id);
  }

  const cacheId = generateMessageCacheId(channel, userIds);
  if (cachedMessages[cacheId]) {
    return cachedMessages[cacheId];
  }
  return [];
}

export function getExercises(
  userid: string
): Promise<{ exercises?: Exercise[] }> {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exercises", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((exercise) => {
        console.log("getExercises rsp");
        resolve(exercise);
      })
      .catch((error) => {
        console.log("getExercises error:" + error);
        reject(error);
      });
  });
}

export function postHomework(
  id: string,
  userid: string,
  tuteeId: string,
  name: string,
  exercises: Exercise[]
): Promise<{ exercises?: Exercise[] }> {
  let bodyValue = {
    name,
    tutorID: userid,
    tuteeID: tuteeId,
    assignmets: exercises.map((exercise) => {
      return { exerciseID: exercise.id };
    }),
  };
  if (id !== null) bodyValue["id"] = id;
  const body = JSON.stringify(bodyValue);

  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/homeworks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: body,
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((homework) => {
        console.log("postHomework rsp");
        resolve(homework);
      })
      .catch((error) => {
        console.log("postHomework error:" + error);
        reject(error);
      });
  });
}
export function putHomework(
  userid: string,
  homeworkId: string,
  action: "ANSWER" | "CORRECT" | "COMPLETE"
): Promise<Homework> {
  const body = JSON.stringify({
    action,
  });

  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/homeworks/${homeworkId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: body,
    })
      .then((res) => {
        return res.json();
      })
      .then((res: Homework) => {
        console.log("putHomework rsp");
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function putHomeworkExercises(
  userid: string,
  tuteeID: string,
  homeworkId: string,
  exercises: Exercise[]
): Promise<Homework> {
  let bodyValue = {
    tutorID: userid,
    tuteeID: tuteeID,
    assignments: exercises.map((exercise) => {
      return { exerciseID: exercise.id };
    }),
  };
  const body = JSON.stringify(bodyValue);

  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/homeworks/addassignments/${homeworkId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: body,
    })
      .then((res) => {
        return res.json();
      })
      .then((res: Homework) => {
        console.log("putHomework rsp");
        console.log(res);
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

//--- CHEATS ---//

export function makeTutor(userid) {
  return new Promise<TutorInfo>(function (resolve, reject) {
    fetch(serverURL + "/tutor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: null,
    })
      .then((res) => {
        if (res.ok) {
          resolve(res.json());
        } else {
          throw "" + res;
        }
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function postExercise(
  userid: string,
  description: string,
  possibleAnswers: string[],
  correctAnswer: string,
  imageUrl: string,
  name: string,
  directoryId?
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/exercises", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: JSON.stringify({
        description,
        possibleAnswers,
        correctAnswer,
        imageUrl,
        directoryId,
        name,
      }),
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((exercise) => {
        console.log("postExercise rsp");
        resolve(exercise);
      })
      .catch((error) => {
        console.log("postExercise error:" + error);
        reject(error);
      });
  });
}

//----- ANALYTICS -----//
export function sendAnalyticsEvent(event, info) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/analytics/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        event: event,
        info: JSON.stringify(info),
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        console.log(
          "Error: could not send event: " + event + " with info: " + info
        );
        reject(error);
      });
  });
}

// Ratings

export function postRating(
  tutorId: string,
  tuteeId: string,
  rating: number,
  comment: string
): Promise<{ rating: Rating }> {
  const body = JSON.stringify({
    tutorID: tutorId,
    tuteeID: tuteeId,
    rating: rating,
    comment: comment,
  });

  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/ratings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: body,
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((rating) => {
        console.log("postRating rsp");
        resolve(rating);
      })
      .catch((error) => {
        console.log("postRating error:" + error);
        reject(error);
      });
  });
}

export function postAirbnbRating(
  tutorId: string,
  tuteeId: string,
  rating: number,
  comment: string,
  videoRating: number,
  tutorComment: string,
  videoComment: string,
  tutorCommentOptions: string[],
  videoCommentOptions: string[]
): Promise<{ rating: Rating }> {
  const body = JSON.stringify({
    tutorID: tutorId,
    tuteeID: tuteeId,
    rating: rating,
    comment: comment,
    videoRating: videoRating,
    tutorComment: tutorComment,
    videoComment: videoComment,
    tutorCommentOptions: tutorCommentOptions,
    videoCommentOptions: videoCommentOptions,
  });

  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/ratings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: body,
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((rating) => {
        console.log("postRating rsp");
        resolve(rating);
      })
      .catch((error) => {
        console.log("postRating error:");
        console.log(JSON.stringify(error));
        reject(error);
      });
  });
}

export function getRatings(
  userId: string | undefined,
  options?: {
    tutorId?: string;
    olderThan?: Date;
    limit?: number;
    offset?: number;
    include?: any;
    hasComment?: boolean;
  }
): Promise<{ ratings: Rating[]; total: number }> {
  return getMethod<{ ratings: Rating[]; total: number }>(userId, "/ratings", {
    paramNames: [
      "tutorId",
      "olderThan",
      "hasComment",
      "limit",
      "offset",
      "include",
    ],
    obj: options,
  }).then((res) => (Array.isArray(res) ? { ratings: res, total: 0 } : res));
}

// Loads from server on first call, then loads from memory unless specified
export function getMainSubjects(onSuccess, onFailure, reload = false) {
  if (!reload && subjects !== null) {
    onSuccess(subjects);
    return;
  }

  getMethod(undefined, "/subjects")
    .then((data: any) => {
      subjects = data.subjects;
      onSuccess(subjects);
    })
    .catch((err) => {
      onFailure(err);
    });
}

// Loads from server on first call, then loads from memory unless specified
export function getMainSubjectLevels(onSuccess, onFailure, reload = false) {
  if (!reload && subjectLevels !== null) {
    onSuccess(subjectLevels);
    return;
  }

  getMethod(undefined, "/subjectLevels")
    .then((data: any) => {
      subjectLevels = data.levels;
      onSuccess(subjectLevels);
    })
    .catch((err) => {
      onFailure(err);
    });
}

interface Dictionary<T> {
  [key: string]: T;
}
interface FreeBusy {
  errors?: Array<any>;
  busy?: Array<{ start: Date; end: Date }>;
}

export function getEventsFreeBusy(
  userID: string,
  obj: {
    timeMin: Date;
    timeMax: Date;
    items: Array<{ id: string }>;
  }
): Promise<Array<{ start: Date; end: Date }>> {
  return postMethod<Dictionary<FreeBusy>>(
    userID,
    `/events/freeBusy`,
    ["timeMin", "timeMax", "items"],
    obj
  ).then((res) => {
    let freeBusy = Object.values(res)
      .filter((obj) => !!obj.busy)
      .map((obj) => obj.busy);

    const busyIntervals = mergeBusyIntervals(...freeBusy);
    return busyIntervals;
  });
}

function mergeBusyIntervals(...busys) {
  const mergedBusy: Array<{ start: Date; end: Date }> = [];
  const currentIndexs = new Array(busys.length).fill(0);
  let lastBusyItem: { start: Date; end: Date } | undefined = undefined;
  for (;;) {
    let minStartIndex = -1;
    let minStartValue = undefined;
    for (let i = 0; i < busys.length; i++) {
      let currentIndex = currentIndexs[i];
      let busy = busys[i];
      if (
        currentIndex < busy.length &&
        (!minStartValue || busy[currentIndex].start < minStartValue)
      ) {
        minStartValue = busy[currentIndex].start;
        minStartIndex = i;
      }
    }
    if (minStartIndex == -1) {
      break;
    }
    const busyItem = busys[minStartIndex][currentIndexs[minStartIndex]];
    currentIndexs[minStartIndex]++;
    if (lastBusyItem && busyItem.start <= lastBusyItem.end) {
      if (busyItem.end > lastBusyItem.end) {
        lastBusyItem.end = busyItem.end;
      }
    } else {
      lastBusyItem = { ...busyItem };
      lastBusyItem && mergedBusy.push(lastBusyItem);
    }
  }
  return mergedBusy.map((val) => ({
    start: new Date(val.start),
    end: new Date(val.end),
  }));
}

// Gets notifications from the database for the Notifications screen.
// Not to be confused with NotificationsManager, which deals with push notifications.
export function getStoredNotifications(
  userId,
  limit,
  offset
): Promise<{ notifications: TutoreNotification[] }> {
  return getMethod<{ notifications: TutoreNotification[] }>(
    userId,
    "/notifications",
    {
      paramNames: ["userId", "limit", "offset"],
      obj: { userId, limit, offset },
    },
    false
  ).catch((err) => {
    console.log(err);
    return { notifications: [] };
  });
}

export function getStoredNotificationCount(
  userId,
  notificationTypes?: [NotificationType]
): Promise<{ count: number }> {
  let paramNames = ["userId"];
  let obj = { userId };

  if (notificationTypes) {
    paramNames.push("types");
    obj["types"] = notificationTypes;
  }

  return getMethod<{ count: number }>(
    userId,
    "/notifications/new",
    {
      paramNames: paramNames,
      obj: obj,
    },
    false
  ).catch((err) => {
    console.log(err);
    return { count: 0 };
  });
}

export function setScheduleHours(
  calendar: object,
  userID: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/schedule/hour`, {
      method: "post",
      body: JSON.stringify(calendar),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userID,
      },
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getScheduleHours(userID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/schedule/hour/${userID}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(`get scheduleHours error: ${err}`);
        reject(err);
      });
  });
}

export function getUserCredit(userID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/tickets/list/${userID}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      mode: "cors",
    })
      .then((res) => {
        // console.log(res.json())
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("get data error from:" + " error:" + error);
        reject(error);
      });
  });
}

export function consumeTicket(ticket: object): any {
  fetch(`${serverURL}/ticket/apply`, {
    method: "post",
    body: JSON.stringify(ticket),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      signature: signature,
    },
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function redeemTicket(couponCode: string, userID: string): Promise<any> {
  var data = JSON.stringify({
    couponCode: couponCode,
    userId: userID,
  });
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/coupon/apply`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      mode: "cors",
      body: data,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function eventUpdate(eventID: string, type: number): void {
  let data = JSON.stringify({
    eventId: eventID,
    type: type,
  });
  fetch(`${serverURL}/event/update`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      signature: signature,
    },
    mode: "cors",
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      console.warn("event updated");
    })
    .catch((err) => {
      console.warn("event updated fail");
    });
}

export function updateDeviceToken(userID: string, token: string): Promise<any> {
  let data = JSON.stringify({
    userId: userID,
    token: token,
  });
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/user/token/update`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      mode: "cors",
      body: data,
    })
      .then((res) => {
        console.log(`updateDevice :${res}`);
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createReferralCoupon(userID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/coupon/referral/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userID,
      },
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getReferralCoupon(userID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/coupon/referral/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userID,
      },
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Subject tests

export function getSubjectTests(userId: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(
      `${serverURL}/subjectTests${createQueryString({
        paramNames: ["userId"],
        obj: { userId },
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve({ subjectTests: data.subjectTests, user: data.user });
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSubjectTest(subjectTestID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(
      `${serverURL}/subjectTest${createQueryString({
        paramNames: ["subjectTestID"],
        obj: { subjectTestID },
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.subjectTest);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSubjectTest(
  subjectTestID: string,
  action: string,
  tutorID?: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/subjectTest", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        subjectTestID,
        action,
        tutorID,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.subjectTest);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTestQuestion(
  testQuestionID: string,
  components
): Promise<any> {
  const { userAnswer, tutorCorrectionComment } = components;
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/testQuestion", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        testQuestionID,
        userAnswer: userAnswer,
        tutorCorrectionComment: tutorCorrectionComment,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.testQuestion);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTutorSubjectTests(tutorID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(
      `${serverURL}/tutorSubjectTests${createQueryString({
        paramNames: ["tutorID"],
        obj: { tutorID },
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.subjectTests);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendTutorSubjectTestNotification(
  tutorId: string,
  tuteeId: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/tutorSubjectTestNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        tutorId,
        tuteeId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postVocationalTest(
  userid: string,
  result: object[],
  activities: number[],
  interests: number[],
  abilities: number[]
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/vocationalTest", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userid,
      },
      body: JSON.stringify({
        result,
        activities,
        interests,
        abilities,
      }),
    })
      .then((res: Response) => {
        if (res.ok) {
          return res.json();
        } else {
          throw "" + res;
        }
      })
      .then((exercise) => {
        console.log("postVocationalTest rsp");
        resolve(exercise);
      })
      .catch((error) => {
        console.log("postVocationalTest error:" + JSON.stringify(error));
        reject(error);
      });
  });
}

// User Personal Info
export function getUserPersonalInfo(userId: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(
      `${serverURL}/userPersonalInfo${createQueryString({
        paramNames: ["userId"],
        obj: { userId },
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.info);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserPersonalInfo(
  userId: string,
  goals?: string[],
  collegeEnteringPeriod?: string,
  mentorshipInterest?: string,
  guardianPhoneVerified?: boolean,
  phone?: string,
  accessedTutoreFutureFirstTime?: Date
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/userPersonalInfo", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,
        goals,
        collegeEnteringPeriod,
        mentorshipInterest,
        guardianPhoneVerified,
        phone,
        accessedTutoreFutureFirstTime,
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        reject(error);
      });
  });
}

export function createUserPersonalInfo(
  userId: string,
  phone?: string,
  birthdayYear?: string,
  guardianPhone?: string,
  sendVerificationCode?: boolean,
  token?: string,
  studentName?: string,
  studentBirthdayYear?: string,
  goals?: string[]
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/userPersonalInfo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,
        phone,
        birthdayYear,
        guardianPhone,
        sendVerificationCode,
        token,
        studentName,
        studentBirthdayYear,
        goals,
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        reject(error);
      });
  });
}

// user counselor
export function getCounselor(userId: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(
      `${serverURL}/counselor${createQueryString({
        paramNames: ["userId"],
        obj: { userId },
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          signature: signature,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          resolve(data.counselor);
        } else {
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCreditCards(userID: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/user/cards/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userid: userID,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function paymentCharge(charge: object): Promise<any> {
  return new Promise(function (resolve, reject) {
    console.log("charge init");
    fetch(`https://sandbox.api.pagseguro.com/charges`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "ADA00E4E320C4495A7388DE1C957F095",
        "x-api-version": "1.0",
      },
      body: JSON.stringify(charge),
    })
      .then((res) => {
        // console.log('success payment charge');
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // console.log('error payment charge');
        // console.log(error);
        reject(error);
      });
  });
}

export function userCardAdd(userCard: object): Promise<any> {
  return new Promise(function (resolve, reject) {
    console.log("add card init");
    fetch(`${serverURL}/user/cards/add`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify(userCard),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userCardRemove(cardId: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    console.log("card remove init");
    fetch(`${serverURL}/user/cards/remove`, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({ cardId: cardId }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function requestPurchaseIAP(
  platform: string,
  receipt: string,
  userId: string,
  couponCode: string,
  orderReference: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/order/iap`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        platform,
        receipt,
        userId,
        couponCode,
        orderReference,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addUserPhoneNumber(userId: string, phone: string) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/userPersonalInfo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,
        phone,
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        reject(error);
      });
  });
}

export function generateOrder(
  userId: string,
  couponCode: string,
  orderReference: string,
  orderId?: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    console.log("generate Orders");
    fetch(`${serverURL}/order/new`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId: userId,
        couponCode: couponCode,
        orderReference: orderReference,
        orderId: orderId,
      }),
    })
      .then((res) => {
        console.log(`generateOrderSERVER`);
        console.log(res);
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log("error generate order");
        console.log(err);
        reject(err);
      });
  });
}

export function oAuthGetNet(): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/getnet/oauth`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function cardTokenization(
  cardNumber: string,
  oAuthToken: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${getnetUrl}/v1/tokens/card`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + oAuthToken,
      },
      body: JSON.stringify({
        card_number: cardNumber,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function cardVaultAdd(
  numberToken: string,
  cardHolderName: string,
  expirationMonth: string,
  expirationYear: string,
  customerId: string,
  oAuthToken: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${getnetUrl}v1/cards`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + oAuthToken,
      },
      body: JSON.stringify({
        number_token: numberToken,
        cardholder_name: cardHolderName,
        expiration_month: expirationMonth,
        expiration_year: expirationYear,
        customer_id: customerId,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function getCardFromVault(
  cardId: string,
  oAuthToken: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${getnetUrl}v1/cards/` + cardId, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + oAuthToken,
      },
    })
      .then((result) => {
        console.log("getCardVault Success");
        return result.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log("getCardVault error");
        console.log(err);
        reject(err);
      });
  });
}

export function creditCardOrderPayment(
  amount: string,
  orderId: string,
  customerId: string,
  numberToken: string,
  cardHolderName: string,
  expirationMonth: string,
  expirationYear: string,
  oAuthToken: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${getnetUrl}v1/payments/credit`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + oAuthToken,
      },
      body: JSON.stringify({
        seller_id: getnetSellerId,
        amount: amount,
        order: {
          order_id: orderId,
        },
        customer: {
          customer_id: customerId,
          billing_address: {},
        },
        device: {},
        shippings: [
          {
            address: {},
          },
        ],
        credit: {
          delayed: false,
          save_card_data: false,
          transaction_type: "FULL",
          number_installments: 1,
          card: {
            number_token: numberToken,
            cardholder_name: cardHolderName,
            expiration_month: expirationMonth,
            expiration_year: expirationYear,
          },
        },
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function createUserStudyPlan(
  userId: string,
  mainGoal: string,
  goalDeadline: string,
  timeInvestment: string,
  schoolType: string,
  age: string,
  studyPlanName: string
) {
  return new Promise(function (resolve, reject) {
    fetch(serverURL + "/userStudyPlan", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        userId,
        mainGoal,
        goalDeadline,
        timeInvestment,
        schoolType,
        age,
        studyPlanName,
      }),
    })
      .then((res) => resolve(res))
      .catch((error) => {
        reject(error);
      });
  });
}

export function requestPurchaseSubscription(
  platform: string,
  receipt: string,
  userId: string,
  couponCode: string,
  orderReference: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/order/iap/subscription`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
      },
      body: JSON.stringify({
        platform,
        receipt,
        userId,
        couponCode,
        orderReference,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSubscription(
  platform: string,
  userId: string
): Promise<any> {
  let params = new URLSearchParams();
  params.append("platform", platform);
  params.append("couponCodes", "subscription_weekly_pagseguro");
  params.append("couponCodes", "subscription_monthly_pagseguro");
  if (platform === "apple") {
    params.append("couponCodes", "subs_1un_credito_week_7daysfree_40reais");
    params.append("couponCodes", "subs_tutorefuture1_month_3daysfree_50reais");
  } else {
    params.append(
      "couponCodes",
      "subs_1un_credito_week_7daysfree_40reais_google"
    );
    params.append(
      "couponCodes",
      "subs_tutorefuture1_month_3daysfree_50reais_google"
    );
  }
  return new Promise(function (resolve, reject) {
    fetch(`${serverURL}/order/subscription?` + params, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        signature: signature,
        userId: userId,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(`get scheduleHours error: ${err}`);
        reject(err);
      });
  });
}

export function getRandomCounselor(
  userId: string,
  options?: { include?: Array<TutorQueryIncludes> }
): Promise<Tutor> {
  const today = new Date();
  const counselorId =
    today.getMinutes() % 2 === 0
      ? "4a60c1ae-532c-4dd1-8352-d11577e2f0e2"
      : "8e61e13e-25a9-4fe8-8192-768c1771f63b";
  return getMethod<Tutor>(userId, `/tutors/${counselorId}`, {
    paramNames: ["include"],
    obj: options,
  }).then((tutor) => {
    const tutorInfo = tutor.tutorInfo || {};
    if (tutorInfo.hourPrice && tutorInfo.currencyCode) {
      tutorInfo.hourPrice = Number(tutorInfo.hourPrice);
    } else {
      tutorInfo.hourPrice = 34.9;
      tutorInfo.currencyCode = "BRL";
    }
    return tutor;
  });
}
