import React, { useState } from "react";
import {
  TextInput,
  Text,
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import BackButton from "../../../assets/backButtonBorder.svg";
import StyleUtils, { COLOR_MUTED, COLOR_WHITE } from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import ButtonElement from "../../Elements/ButtonElement";

type Props = {
  onContinue;
  onBack;
};

export default function TFStudyPlanName(props: Props) {
  const [text, setText] = useState("");

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        Keyboard.dismiss();
      }}
    >
      <View style={StyleUtils.screen_default}>
        <View
          style={[
            {
              backgroundColor: COLOR_WHITE,
              justifyContent: "center",
              width: "100%",
            },
          ]}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 16,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  props.onBack();
                }}
              >
                <SVGImage source={<BackButton />} />
              </TouchableOpacity>
            </View>

            <View
              style={{
                alignContent: "center",
              }}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_16,
                  { textAlign: "center" },
                ]}
              >
                Meu plano de estudo
              </Text>
            </View>
            <View style={{ flex: 1 }}></View>
          </View>
        </View>
        <View
          style={{
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
            width: "100%",
            zIndex: 10,
            marginBottom: 25,
          }}
        />
        <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          style={[StyleUtils.screen_default, { justifyContent: "center" }]}
          keyboardVerticalOffset={40}
        >
          <View style={{ justifyContent: "center", flex: 1, marginTop: -50 }}>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_20,
                { marginLeft: 24 },
              ]}
            >
              Dê um nome para o seu{"\n"}plano de estudos:
            </Text>
            <TextInput
              value={text}
              onChangeText={(text) => setText(text)}
              style={[
                StyleUtils.font_cera_regular,
                {
                  borderRadius: 6,
                  color: "black",
                  textAlign: "auto",
                  marginTop: 16,
                  marginLeft: 24,
                  fontSize: 32,
                },
              ]}
              placeholder={"Ex.: Medicina USP"}
              placeholderTextColor={COLOR_MUTED}
            />
          </View>
          <ButtonElement
            colorScheme="primary"
            text="Avançar"
            onPress={() => props.onContinue(text)}
            disabled={!text}
            styte={[
              StyleUtils.shape_btn_default,
              { margin: 24, borderRadius: 6 },
            ]}
            textStyle={{ fontSize: 16 }}
          />
        </KeyboardAvoidingView>
      </View>
    </TouchableWithoutFeedback>
  );
}
