import React from "react";
import { Component } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import HeaderDecor from "../../assets/headerDecor.svg";
import SVGImage from "../Utils/SVGImage";
import { connect } from "react-redux";
import { withRouter } from "../Router";
import {
  AssignmentsFilter,
  EventsFilter,
  getEvents,
  getHomeworks,
} from "../Server";
import User from "../Models/User";
import { selectEvent, selectUser, setHomeworks } from "../Actions";
import OngoingEventCard from "./Cards/OngoingEventCard";
import EventCard from "./Cards/EventCard";
import ExercisesTutorCard from "./Cards/ExercisesTutorCard";
import ExercisesCatologCard from "./Cards/ExercisesCatologCard";
import CalendarCard from "./Cards/CalendarCard";
import AssignmentState from "../Models/AssignmentState";
import QuickClassList from "../QuickClass/QuickClassList";

type Props = {
  history;
  mobile?: boolean;
  user: User;
  tutorInfo;
  selectEvent;
  selectUser;
  dispatch;
  pendingCorrection;
};

type State = {
  loading: boolean;
  event;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    pendingCorrection: state.homeworkRed.pendingCorrection,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectEvent: (event) => dispatch(selectEvent(event)),
    selectUser: (user) => dispatch(selectUser(user)),
    dispatch: dispatch,
  };
}

//only show quick class links to allison, lucas, marcelo, and sabrina
const showQuickClassIds = [
  "4a60c1ae-532c-4dd1-8352-d11577e2f0e2",
  "70be658a-db03-4ffe-88c5-ff90cb6b5679",
  "8e61e13e-25a9-4fe8-8192-768c1771f63b",
  "1643172d-b44a-4379-aa1d-c809d70bde42",
  "76490f6e-e6a4-4698-a810-c7de78dba491",
];

class TutorHome extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      event: null,
    };
  }

  componentDidMount() {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
    }
    this.fetchUpcomingEvent()
      .then(() => this.fetchAssignments())
      .catch(console.log)
      .finally(() => {
        console.log("loaded tutor upcoming event");
        // console.log(this.props.user);
        // console.log(this.state.event);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const ongoing = this.isOngoingEvent();
    let large = !this.props.mobile;
    return (
      <ScrollView>
        <View
          style={[
            StyleUtils.color_bg_dark,
            {
              width: "100%",
              height: 230,
              overflow: "hidden",
              position: "absolute",
            },
          ]}
        >
          <SVGImage source={<HeaderDecor />} />
        </View>
        <View
          style={[
            this.props.mobile ? style.page_mobile : style.page_web,
            { marginTop: 24 },
          ]}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_20,
              { marginBottom: 50 },
            ]}
          >
            Bem-vindo, {this.props.user.name}
          </Text>

          {this.props.mobile && (
            <View style={style.cardHolder_mobile}>
              <View style={{}}>
                {ongoing && this.ongoingEvent(true)}
                {!ongoing && this.state.event && this.upcomingEvent(true)}
                {!ongoing && !this.state.event && this.calendarCard(true)}
              </View>
              <View style={{ width: 15, height: 15 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1, marginRight: 15 }}>
                  {this.execiseCard()}
                </View>
                <View style={{ flex: 1 }}>
                  <ExercisesCatologCard
                    mobile={this.props.mobile}
                    cardStyle={!this.props.mobile ? "primary" : "regular"}
                    onPress={this.goToExerciseCatalog}
                  />
                </View>
              </View>
            </View>
          )}
          {!this.props.mobile && (
            <View style={style.cardHolder_web}>
              {ongoing && this.ongoingEvent(true)}
              {!ongoing && this.state.event && this.upcomingEvent(true)}
              {!ongoing && !this.state.event && this.calendarCard(true)}
              <View style={{ width: 10 }} />
              {this.execiseCard()}
              <View style={{ width: 10 }} />
              <ExercisesCatologCard
                mobile={this.props.mobile}
                cardStyle={!this.props.mobile ? "primary" : "regular"}
                onPress={this.goToExerciseCatalog}
              />
            </View>
          )}
        </View>
        {showQuickClassIds.includes(this.props.user.id) && (
          <View
            style={[
              this.props.mobile ? style.page_mobile : style.page_web,
              { marginTop: 16, marginBottom: 24 },
            ]}
          >
            <QuickClassList
              history={this.props.history}
              style={{
                height: 300,
                backgroundColor: "white",
                borderRadius: 16,
              }}
            />
          </View>
        )}
      </ScrollView>
    );
  }

  async fetchUpcomingEvent() {
    const now = new Date();
    // TODO melhorar a logica para pegar eventos já iniciados.
    // supõe aula de 30 minutos
    now.setMinutes(now.getMinutes() - 29);

    var eventFilter: EventsFilter = {
      tutorId: this.props.user.id,
      tuteeId: undefined,
      periodStart: now.toISOString(),
      periodEnd: undefined,
      limit: 1,
    };

    return getEvents(eventFilter).then((result: any) => {
      console.log("NEXT EVENT:");
      const ev = (result && result.length > 0 && result[0]) || null;

      this.setState({
        event: ev,
      });
    });
  }

  async fetchAssignments() {
    var assignmentsFilter: AssignmentsFilter = {
      tutorId: this.props.user.id,
      tuteeId: undefined,
      limit: undefined,
      states: [AssignmentState.ANSWERED, AssignmentState.REVIEWED],
    };
    return getHomeworks(assignmentsFilter).then((homeworks) => {
      this.props.dispatch(setHomeworks(homeworks, true));
    });
  }

  ongoingEvent(large?: boolean) {
    const startDate = new Date(this.state.event.startDate);
    let subject = "";
    if (this.state.event.subject && this.state.event.subjectLevel) {
      if (this.state.event.subject === "Orientação/Teste Vocacional") {
        subject = "Aula de " + this.state.event.subject;
      } else {
        subject =
          "Aula de " +
          this.state.event.subject +
          " - " +
          this.state.event.subjectLevel;
      }
    }

    return (
      <OngoingEventCard
        mobile={this.props.mobile}
        size={large ? "large" : "regular"}
        user={this.state.event.tutee}
        date={startDate}
        onPress={this.goToEvent}
        subject={subject}
      />
    );
  }

  upcomingEvent(large?: boolean) {
    let cardStyle: "regular" | "success" | "primary";
    if (!this.props.mobile) {
      cardStyle = "primary";
    } else {
      cardStyle = "regular";
    }

    const startDate = new Date(this.state.event.startDate);
    let subject = "";
    if (this.state.event.subject && this.state.event.subjectLevel) {
      subject =
        "Aula de " +
        this.state.event.subject +
        " - " +
        this.state.event.subjectLevel;
    }

    return (
      <EventCard
        mobile={this.props.mobile}
        cardStyle={cardStyle}
        size={"large"}
        user={this.state.event.tutee}
        date={startDate}
        onPress={this.goToMessages}
        subject={subject}
      />
    );
  }

  calendarCard(large?: boolean) {
    let cardStyle: "regular" | "success" | "primary";
    if (!this.props.mobile) {
      cardStyle = "primary";
    } else {
      cardStyle = "regular";
    }

    return (
      <CalendarCard
        mobile={this.props.mobile}
        size={large ? "large" : "regular"}
        cardStyle={cardStyle}
        eventsOfToday={0}
        onPress={this.goToEvents}
      />
    );
  }

  execiseCard() {
    let cardStyle: "regular" | "success" | "primary";
    if (!this.props.mobile) {
      cardStyle = "primary";
    } else {
      cardStyle = "regular";
    }

    return (
      <ExercisesTutorCard
        mobile={this.props.mobile}
        cardStyle={cardStyle}
        numberToReviewExercises={this.props.pendingCorrection}
        onPress={this.goToExercises}
      />
    );
  }

  isOngoingEvent(now?: Date): boolean {
    const startDate = this.state.event && new Date(this.state.event.startDate);
    const availableClassroom = new Date(startDate);
    availableClassroom.setMinutes(availableClassroom.getMinutes() - 5);
    return startDate && (now || new Date()) >= availableClassroom;
  }

  goToEvent = () => {
    this.props.selectEvent(this.state.event);
    this.props.selectUser(this.state.event.tutee);
    this.props.history.push("/eventinfo");
  };

  goToEvents = () => {
    this.props.history.push("/events");
  };

  goToExercises = () => {
    this.props.history.push("/exercises");
  };

  goToExerciseCatalog = () => {
    this.props.history.push("/homeworkassignment");
  };
  goToMessages = () => {
    this.props.history.push("/messages");
  };
}

const style = StyleSheet.create({
  page_mobile: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  page_web: {
    marginLeft: "10%",
    marginRight: "10%",
  },
  cardHolder_web: {
    flexDirection: "row",
    alignSelf: "flex-start",
  },
  cardHolder_mobile: {
    minHeight: 344,
    flexShrink: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
});

export default withRouter(connect(stateToProps, dispatchToProps)(TutorHome));
