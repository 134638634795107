import React from "react";
import { View, Image, Text } from "react-native";
import { withRouter } from "../Router";
import StyleUtils from "../Utils/StyleUtils";
import Homework from "../Models/Homework";
import ButtonElement from "../Elements/ButtonElement";
import ExercisesAnswerdPictureBorder from "../../assets/exercisesAnswerdPictureBorder.svg";
import UserPlaceHolder from "../../assets/userPlaceHolder.svg";
import SVGImage from "../Utils/SVGImage";

type Props = {
  homework: Homework;
  history: any;
  mobile?: boolean;
};

const ExercisesAnswered = (props: Props) => {
  const user = props.homework.tutor;
  let imageUrl = (user && user.imageURL) || "";
  return (
    <View style={[StyleUtils.screen_default, { alignItems: "center" }]}>
      <View
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          margin: 24,
          flex: 1,
          maxWidth: 280,
        }}
      >
        <View
          style={{
            //justifyContent: "center",
            alignItems: "center",
            width: 263,
            height: 279,
            marginBottom: 42,
          }}
        >
          <SVGImage
            source={<ExercisesAnswerdPictureBorder />}
            style={{ position: "absolute", top: 0, left: 0 }}
          />
          <View style={{ marginRight: 8, marginBottom: 13 }}>
            {!!imageUrl && (
              <Image
                source={{ uri: imageUrl }}
                style={{
                  width: 126,
                  height: 126,
                  borderRadius: 63,
                  top: 75,
                  position: "absolute",
                  overflow: "hidden",
                  alignSelf: "center",
                }}
              />
            )}
          </View>
          {!imageUrl && (
            <SVGImage
              style={{
                width: 126,
                height: 126,
                borderRadius: 63,
                position: "absolute",
                top: 75,
                overflow: "hidden",
                alignSelf: "center",
                justifyContent: "center",
              }}
              source={<UserPlaceHolder width={126} height={126} />}
            />
          )}
        </View>
        <Text
          style={[
            StyleUtils.font_btn_bold,
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_20,
            { marginBottom: 8, textAlign: "center" },
          ]}
        >
          Seus exercícios foram enviados ao tutor
        </Text>
        <Text
          style={[
            StyleUtils.font_btn_regular,
            StyleUtils.color_txt_light,
            StyleUtils.font_size_16,
            { textAlign: "center" },
          ]}
        >
          Você será notificado quando as correções estiverem disponíveis.
        </Text>
      </View>
      <ButtonElement
        text="Ir para Home"
        onPress={() => props.history.push("/")}
        styte={[{ margin: 32 }, props.mobile && { alignSelf: "stretch" }]}
      />
    </View>
  );
};
export default withRouter(ExercisesAnswered);
