import React, { Component } from "react";
import SVGImage from "../Utils/SVGImage";
import StarWhite from "../../assets/starWhite.svg";
import StarBlue from "../../assets/starBlue.svg";
import { TouchableOpacity, TouchableWithoutFeedback } from "react-native";

type Props = {
  rating;
  value;
  onClick;
};

type State = {};

class Star extends Component<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TouchableOpacity
        style={{ paddingRight: 4 }}
        onPress={() => this.props.onClick(this.props.value)}
      >
        {this.props.rating >= this.props.value ? (
          <SVGImage source={<StarBlue />} />
        ) : (
          <SVGImage source={<StarWhite />} />
        )}
      </TouchableOpacity>
    );
  }
}

export default Star;
