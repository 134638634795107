import React from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import { connect } from "react-redux";
import ChatConnection from "./ChatConnection";
import GiftedChatHolder from "./GiftedChatHolder";
import StyleUtils, {
  COLOR_BG_LIGHT,
  COLOR_MUTED,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import BackButtonBlack from "../../assets/backButtonBorder.svg";
import { userShortName, uuidv4 } from "../Utils/Utils";
import Calendar from "../../assets/calendar.svg";
import { selectTutor, isSuperTutor } from "../Actions";
import NotificationPopUp from "../Onboarding/ExerciseUpload/NotificationPopUp";
import { analytics } from "../Analytics";
import { visualizeMessages } from "../Server";
import { SCREEN_SIZE_600 } from "../Utils/SizeUtils";
import { getTutor } from "../Server";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

type Props = {
  chatConnection: ChatConnection;
  headerInfo: any;
  currentUser;
  userWithoutLogin;
  showBackButton;
  onBack;
  previousMessage?;
  exerciseImage?;
  initialMessages;
  history?;
  selectTutor;
  rescheduleReason?;
  talkspaceOnboarding?: boolean;
  dispatch;
};

type State = {
  messages;
  showNotification;
  isExerciseOnboarding;
  compactLayout;
  rescheduleReason;
};

function mapStateToProps(state) {
  return {
    talkspaceOnboarding: state.userRed.talkspaceOnboarding,
    currentUser: state.userRed.user,
    userWithoutLogin: state.userRed.userWithoutLogin,
  };
}

const mapDispatchToProps = (dispatch) => ({
  selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
  dispatch: dispatch,
});

// const tutoreEmail = "tutoreaulas@gmail.com";
// const tutoreId = "4c8eed66-0c37-49ed-a5d8-b575487c0fa1";

const allisonEmail = "allison@learning-machine.org";
const allisonId = "4a60c1ae-532c-4dd1-8352-d11577e2f0e2";

class ChatRoom extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      messages: this.props.initialMessages,
      showNotification: false,
      isExerciseOnboarding: false,
      compactLayout: true,
      rescheduleReason: this.props.rescheduleReason
        ? this.props.rescheduleReason
        : "",
    };
  }

  componentDidMount() {
    this.linkConnection();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.props.chatConnection != prevProps.chatConnection) {
      this.linkConnection();
    }
  }

  componentWillUnmount() {
    this.props.chatConnection.stateUpdate = null;
  }

  linkConnection = () => {
    if (!this.props.chatConnection) return;
    this.props.chatConnection.stateUpdate = () => {
      this.setState({
        //TODO: Gambi pra fazer o state atualizar. Refactor later
        messages: this.props.chatConnection.messages,
      });
      RemoteConfigBridge.remoteConfig("onboardingType", (value) => {
        if (
          this.props.headerInfo &&
          this.props.headerInfo.user.email === allisonEmail &&
          this.props.chatConnection.messages.length < 1 &&
          value == 4
        ) {
          let t = new Date();
          const studentMessage = [
            {
              _id: allisonId,
              image: this.props.exerciseImage,
              text: this.props.previousMessage,
              createdAt: t,
              user: {
                _id: this.props.currentUser
                  ? this.props.currentUser.id
                  : this.props.userWithoutLogin.id,
                name: this.props.currentUser
                  ? this.props.currentUser.name
                  : "User",
              },
            },
          ];

          t.setSeconds(t.getSeconds() + 60);
          const tutoreMessage = [
            {
              _id: 2,
              text:
                "Olá! Obrigado pela confiança! Estou olhando com calma a sua dúvida, mas se quiser já pode marcar uma sessão comigo no ícone de calendário aí em cima!",
              createdAt: t,
              user: {
                _id: allisonId,
                name: "Allison Hirata",
              },
            },
          ];
          this.props.chatConnection.sendChatMessage(studentMessage);
          this.props.chatConnection.tutoreSendChatMessage(tutoreMessage);
          this.setState({ showNotification: true });
          this.setState({ isExerciseOnboarding: true });
        } else if (this.state.rescheduleReason) {
          let t = new Date();
          const rescheduleReason = [
            {
              _id: uuidv4(),
              text: this.props.rescheduleReason,
              createdAt: t,
              user: {
                _id: this.props.currentUser
                  ? this.props.currentUser.id
                  : this.props.userWithoutLogin.id,
                name: this.props.currentUser
                  ? this.props.currentUser.name
                  : "User",
              },
            },
          ];
          this.props.chatConnection.sendChatMessage(rescheduleReason);
          this.setState({ rescheduleReason: false });
        }
        // else if (
        //   this.props.headerInfo &&
        //   this.props.headerInfo.user.email === tutoreEmail &&
        //   this.props.chatConnection.messages.length < 1
        // ) {
        //   let t = new Date();
        //   const studentMessage = [
        //     {
        //       _id: tutoreId,
        //       image: this.props.exerciseImage,
        //       text: this.props.previousMessage,
        //       createdAt: t,
        //       user: {
        //         _id: this.props.currentUser
        //           ? this.props.currentUser.id
        //           : this.props.userWithoutLogin.id,
        //         name: this.props.currentUser
        //           ? this.props.currentUser.name
        //           : "User",
        //       },
        //     },
        //   ];

        //   t.setSeconds(t.getSeconds() + 60);
        //   const tutoreMessage = [
        //     {
        //       _id: 2,
        //       text:
        //         "Obrigado! Nós recebemos seu exercício e em breve um professor irá entrar em contato com você.",
        //       createdAt: t,
        //       user: {
        //         _id: tutoreId,
        //         name: "Tutore Aulas",
        //       },
        //     },
        //   ];
        //   this.props.chatConnection.sendChatMessage(studentMessage);
        //   this.props.chatConnection.tutoreSendChatMessage(tutoreMessage);
        //   this.setState({ showNotification: true });
        //   this.setState({ isExerciseOnboarding: true });
        // }
      });
      if (
        this.props.talkspaceOnboarding &&
        this.props.headerInfo.user.id === this.props.currentUser.counselor.id &&
        this.props.initialMessages.length === 0 &&
        this.props.chatConnection.messages.length === 0
      ) {
        this.props.chatConnection.sendServerMessages(
          [
            {
              _id: uuidv4(),

              text: `Olá! Tudo bom?\nAqui é o ${this.props.currentUser.counselor.name}, sou orientador educacional do Tutore e estou aqui para te ajudar a alcançar os seus objetivos!`,
              createdAt: new Date().setMinutes(new Date().getMinutes() - 3),
            },
          ],
          this.props.currentUser.counselor.id,
          this.props.currentUser.id
        );
        setTimeout(() => {
          this.props.chatConnection.sendServerMessages(
            [
              {
                _id: uuidv4(),
                text:
                  "Se você quiser aprender (ou melhorar) em uma matéria, temos aulas particulares com professores incríveis!",
                createdAt: new Date().setMinutes(new Date().getMinutes() - 2),
              },
            ],
            this.props.currentUser.counselor.id,
            this.props.currentUser.id
          );
        }, 4000);
        setTimeout(() => {
          this.props.chatConnection.sendServerMessages(
            [
              {
                _id: uuidv4(),
                text:
                  "Ou se ainda não decidiu qual carreira seguir ou que faculdade prestar, também temos pessoas super legais pra te ajudar!",
                createdAt: new Date().setMinutes(new Date().getMinutes() - 1),
              },
            ],
            this.props.currentUser.counselor.id,
            this.props.currentUser.id
          );
        }, 8000);
        setTimeout(() => {
          this.props.chatConnection.sendServerMessages(
            [
              {
                _id: uuidv4(),
                text:
                  "Mas antes da gente começar a falar sobre isso, consegue me falar seu nome e sua idade?",
                createdAt: new Date(),
              },
            ],
            this.props.currentUser.counselor.id,
            this.props.currentUser.id
          );
        }, 12000);
      }
    };
    if (this.props.headerInfo) {
      visualizeMessages(
        this.props.headerInfo.user.id,
        this.props.currentUser.id,
        null
      ).then((res) => {
        console.log("message visualized " + res);
      });
    }
  };

  backButtonPressed() {
    if (this.state.isExerciseOnboarding) {
      analytics.sendExerciseOnboardingChatback();
    }
    this.props.onBack();
  }

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let isMobileLayout = layout.width < SCREEN_SIZE_600;
    if (isMobileLayout != this.state.compactLayout) {
      this.setState({
        compactLayout: isMobileLayout,
      });
    }
  };

  render() {
    const isMobile = Platform.OS !== "web";

    return (
      <View
        onLayout={this.onLayout}
        style={{ flex: 1, flexDirection: "column" }}
      >
        {this.renderBackButton()}
        {this.renderHeader()}
        {isMobile &&
          this.props.currentUser.counselor &&
          this.props.headerInfo.user.id ===
            this.props.currentUser.counselor.id && (
            <View style={[StyleUtils.color_bg_primarylight, { padding: 16 }]}>
              <Text
                style={[StyleUtils.font_cera_bold, StyleUtils.font_size_14]}
              >
                Bem-vindo(a) ao Tutore!
              </Text>
              <Text
                style={[StyleUtils.font_cera_medium, StyleUtils.font_size_14]}
              >
                Aqui é o seu espaço para te ajudarmos a alcançar seus sonhos!
              </Text>
            </View>
          )}
        <GiftedChatHolder
          messages={this.state.messages}
          onSend={(messages) =>
            this.props.chatConnection.sendChatMessage(messages)
          }
          userID={
            this.props.currentUser
              ? this.props.currentUser.id
              : this.props.userWithoutLogin.id
          }
        />

        {Platform.OS === "ios" && (
          <NotificationPopUp
            show={this.state.showNotification}
            close={() => {
              this.setState({ showNotification: false });
            }}
          />
        )}
      </View>
    );
  }

  renderBackButton(): React.ReactNode {
    if (!this.props.showBackButton) {
      return null;
    }

    return (
      <TouchableOpacity
        style={{
          marginTop: 10,
          marginLeft: 10,
          height: 48,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 1000,
          aspectRatio: 1,
          backgroundColor: COLOR_BG_LIGHT,
        }}
        onPress={this.backButtonPressed.bind(this)}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SVGImage source={<BackButtonBlack />} />
        </View>
      </TouchableOpacity>
    );
  }

  renderHeader(): React.ReactNode {
    if (!this.props.headerInfo) {
      return null;
    }

    return (
      <View
        style={[
          StyleUtils.color_bg_white,
          {
            height: 60,
            alignItems: "center",
            flexDirection: "row",
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
          },
        ]}
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginHorizontal: 8,
          }}
          onPress={this.backButtonPressed.bind(this)}
        >
          <SVGImage source={<BackButtonBlack />} />
        </TouchableOpacity>

        <View
          style={{
            width: 32,
            height: 32,
            borderWidth: 1,
            borderColor: COLOR_WHITE,
            borderRadius: 6,
            overflow: "hidden",
            backgroundColor: COLOR_BG_LIGHT,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.props.dispatch(isSuperTutor(true));
              getTutor(this.props.currentUser.id, this.props.headerInfo.user.id)
                .then((t) => {
                  this.props.dispatch(selectTutor(t));
                  this.props.history.push("/TutorProfile");
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            {!!this.props.headerInfo.user.imageURL && (
              <Image
                source={{
                  uri: this.props.headerInfo.user.imageURL,
                }}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
            {!this.props.headerInfo.user.imageURL && (
              <Image
                source={require("../../assets/userPlaceholderSquare.png")}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </TouchableOpacity>
        </View>
        <Text
          style={[
            StyleUtils.font_btn_bold,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_dark,
            {
              marginHorizontal: 12,
              textAlign: "center",
              marginTop: Platform.OS === "ios" ? 5 : 0,
              marginBottom: Platform.OS === "android" ? 3 : 0,
            },
          ]}
        >
          {userShortName(this.props.headerInfo.user.name)}
        </Text>
        {!!this.state.compactLayout &&
          this.props.history &&
          this.props.headerInfo &&
          this.props.headerInfo.user &&
          !this.props.talkspaceOnboarding && (
            <TouchableOpacity
              style={{
                position: "absolute",
                right: 24,
              }}
              onPress={() => {
                getTutor(
                  this.props.currentUser.id,
                  this.props.headerInfo.user.id
                )
                  .then((t) => {
                    this.props.selectTutor(t);
                    this.props.history.push("/booking", {
                      source: "chat",
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              <SVGImage source={<Calendar />} />
            </TouchableOpacity>
          )}
      </View>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);
