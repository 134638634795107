import React from "react";
import { TouchableOpacity, Text } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import ButtonElement from "../Elements/ButtonElement";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";

type Props = {
  mobile?: boolean;
  visible?: boolean;
  onConfirm?: () => void;
  onExit?: () => void;
};

const AnswerPopup = (props: Props) => {
  return (
    <AnimatedPopUp show={props.visible} close={props.onExit}>
      <Text
        style={[
          StyleUtils.font_cera_bold,
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_26,
          { textAlign: "center" },
        ]}
      >
        Finalizar e enviar os exercícios para correção?
      </Text>
      <Text
        style={[
          StyleUtils.font_cera_regular,
          StyleUtils.color_txt_light,
          StyleUtils.font_size_16,
          { marginTop: 24, textAlign: "center" },
        ]}
      >
        {"As respostas serão enviadas para\no seu tutor corrigir."}
      </Text>
      <ButtonElement
        colorScheme="primary"
        text="Confirmar"
        onPress={props.onConfirm}
        styte={[
          StyleUtils.shape_btn_default,
          { margin: 0, marginTop: 40, alignSelf: "stretch" },
        ]}
      />
      <TouchableOpacity onPress={props.onExit}>
        <Text
          style={[
            StyleUtils.font_btn_regular,
            StyleUtils.color_txt_normal,
            StyleUtils.font_size_16,
            {
              marginTop: 24,
              textDecorationLine: "underline",
              textAlign: "center",
            },
          ]}
        >
          Voltar
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};

export default AnswerPopup;
