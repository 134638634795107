import React from "react";
import { connect } from "react-redux";
import {
  Text,
  TouchableOpacity,
  View,
  Platform,
  Dimensions,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import Check from "../../assets/checkBlue.svg";
import Background from "../../assets/backgroundShapesBlue.svg";
import { UserImage } from "../Elements/UserImages";
import { getTutor } from "../Server";
import { selectTutor } from "../Actions";

type Props = {
  currentUser;
  selectedUser;
  history;
  tutorInfo;
};

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    selectedUser: state.selRed.selectedUser,
    tutorInfo: state.userRed.tutorInfo,
  };
};

const dispatchToProps = (dispatch) => ({
  selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
});

const ClassroomEndSchedule = (props) => {
  var dimen = Dimensions.get("window");
  dimen.width = dimen.width < 375 ? 375 : dimen.width;

  function isIphoneXorAbove() {
    return (
      Platform.OS === "ios" &&
      !Platform.isPad &&
      !Platform.isTVOS &&
      dimen.height > 800 &&
      dimen.height < 1000 &&
      dimen.width < 1000
    );
  }

  return (
    <View style={[StyleUtils.screen_default, { justifyContent: "center" }]}>
      <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
        <View
          style={{
            width: "70%",
            height: "40%",
            margin: "15%",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <SVGImage source={<Background />} style={{ marginBottom: 100 }} />
          <UserImage
            style={{
              width: 148,
              height: 174,
              borderRadius: 15,
              position: "absolute",
              bottom: 0,
            }}
            imageURL={props.selectedUser.imageURL}
          />
          <SVGImage
            source={<Check />}
            style={{
              marginLeft: 140,
              bottom: isIphoneXorAbove() ? 30 : 65,
            }}
          />
        </View>
        <View>
          <Text
            style={[
              { textAlign: "center" },
              StyleUtils.color_txt_dark,
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_20,
            ]}
          >
            Você concluiu sua aula
          </Text>

          <Text
            style={[
              { textAlign: "center", margin: "12%", marginTop: "2%" },
              StyleUtils.color_txt_light,
              StyleUtils.font_size_16,
              StyleUtils.font_cera_regular,
            ]}
          >
            Veja os horários disponíveis do seu professor e agende sua próxima
          </Text>
        </View>

        <View>
          <TouchableOpacity
            style={[
              { margin: "9%", borderRadius: 6 },
              StyleUtils.color_bg_primary,
            ]}
            onPress={() => {
              getTutor(props.currentUser.id, props.selectedUser.id)
                .then((t) => {
                  props.selectTutor(t);
                  let subject = "";
                  let subjectLevel = "";
                  if (
                    props.location &&
                    props.location.state &&
                    props.location.state.subject
                  ) {
                    subject = props.location.state.subject;
                    subjectLevel = props.location.state.subjectLevel;
                  }
                  props.history.push({
                    pathname: "/booking",
                    state: {
                      classroomEnd: true,
                      subject: subject,
                      subjectLevel: subjectLevel,
                      source: "class_finished",
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 16 },
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
                StyleUtils.font_cera_bold,
              ]}
            >
              Agendar próxima aula
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => props.history.push("/classroom/airbnbrating")}
          >
            <Text
              style={[
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                { textDecorationLine: "underline", textAlign: "center" },
              ]}
            >
              Finalizar
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default connect(stateToProps, dispatchToProps)(ClassroomEndSchedule);
