import StyleUtils, {
  COLOR_DANGER,
  COLOR_PRIMARY,
  SpcStyle,
} from "../Utils/StyleUtils";
import { StyleSheet, TextInput, View } from "react-native";
import React from "react";

const DateInput = ({ date, onFocus, onBlur, onDateChange, isFocused }) => {
  return (
    <TextInput
      maxLength={10}
      style={[SpcStyle.textInput, isFocused && SpcStyle.textInputFocused]}
      placeholder="dd/mm/aaaa"
      onFocus={onFocus}
      onBlur={onBlur}
      onChangeText={onDateChange}
      value={date}
    />
  );
};

export default DateInput;
