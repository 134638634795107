import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  ScrollView,
  FlatList,
  SafeAreaView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
  COLOR_SUCCESS,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";

type Props = {
  onContinue;
  onBack;
  headerText;
  percentage;
};

type State = {
  selectedSingle: string | null;
  areas;
};

export default class ZeroToFiveQuestions extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedSingle: null,
      areas: [0, 1, 2, 3, 4, 5],
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
      this.props.onContinue(item);
      setTimeout(() => {
        this.setState({
          selectedSingle: null,
        });
      }, 500);
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text
          style={[
            styles.cellText,
            isSelectedArea && { color: COLOR_WHITE, textAlign: "center" },
          ]}
        >
          {item}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{
            flex: 1,
            flexDirection: "column",
            backgroundColor: COLOR_TRUE_WHITE,
          }}
        >
          <View
            style={{ flexDirection: "row", marginTop: 16, marginBottom: 32 }}
          >
            <TouchableOpacity
              style={[
                {
                  alignSelf: "flex-start",
                  alignItems: "center",
                  justifyContent: "center",
                  marginHorizontal: 24,
                },
              ]}
              onPress={() => {
                this.props.onBack();
                this.setState({
                  selectedSingle: null,
                });
              }}
            >
              <SVGImage source={<BackArrow />} />
            </TouchableOpacity>
            <View style={styles.container}>
              <View style={styles.progressBar}>
                <View
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: COLOR_SUCCESS,
                    width: this.props.percentage,
                    borderRadius: 50,
                  }}
                />
              </View>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              paddingHorizontal: 32,
              alignItems: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  marginBottom: 20,
                },
              ]}
            >
              {this.props.headerText}
            </Text>
            <Text style={styles.grayText}>Discordo totalmente</Text>
            <FlatList
              data={this.state.areas}
              renderItem={({ item }) => this.renderLevelCell(item)}
              style={{ marginBottom: 12 }}
              keyExtractor={(index) => index.toString()}
            />
          </View>
          <Text style={[styles.grayText, { marginBottom: 20 }]}>
            Concordo totalmente
          </Text>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cellBase: {
    height: 53,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY,
  },
  grayText: {
    textAlign: "center",
    paddingHorizontal: 20,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_light),
  },
  container: {
    height: 12,
    width: "65%",
    backgroundColor: "#ecf0f1",
    borderRadius: 50,
    marginTop: 6,
  },
  progressBar: {
    height: 12,
    width: "100%",
    borderRadius: 50,
  },
});
