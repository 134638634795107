import React, { Component } from "react";
import {
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import BackArrow from "../../../assets/backbutton.svg";
import OnboardingTeacher from "../../../assets/onboardingTeacher.svg";
import StyleUtils from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";

type Props = {
  onBack;
};

type State = {
  selectedTiming: string | null;
  levelArray: string[];
};

export default class SpcBeTeacher extends Component<Props, State> {
  render() {
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        <TouchableOpacity
          style={[
            {
              alignSelf: "flex-start",
              width: "15%",
              aspectRatio: 1,
              alignItems: "center",
              justifyContent: "center",
              margin: 16,
              marginTop: 38,
            },
          ]}
          onPress={() => {
            this.props.onBack();
          }}
        >
          <SVGImage source={<BackArrow />} />
        </TouchableOpacity>
        <View
          style={{
            height: "75%",
            width: "100%",
            paddingLeft: 32,
            paddingRight: 32,
            minHeight: 407,
            overflow: "hidden",
            alignContent: "center",
          }}
        >
          <SVGImage
            source={<OnboardingTeacher />}
            style={{ alignSelf: "center" }}
          />
          <View style={{ marginTop: "17%" }}>
            <Text style={styles.baseText}>Professor, acesse</Text>
            <Text
              style={[styles.baseText, styles.hyperlink, {}]}
              onPress={() =>
                Linking.openURL("https://tutore.learning-machine.org")
              }
            >
              tutore.learning-machine.org
            </Text>
            <Text style={styles.baseText}>através do computador</Text>
            <Text style={[styles.lightText, { marginTop: "7%" }]}>
              Neste momento, o aplicativo está disponível apenas para alunos.
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  baseText: {
    lineHeight: 25,
    width: "100%",
    textAlign: "center",
    ...StyleSheet.flatten([
      StyleUtils.font_cera_bold,
      StyleUtils.font_size_22,
      StyleUtils.color_txt_dark,
    ]),
  },
  hyperlink: { ...StyleSheet.flatten(StyleUtils.color_txt_primary) },
  lightText: {
    width: "100%",
    textAlign: "center",
    ...StyleSheet.flatten([
      StyleUtils.font_cera_regular,
      StyleUtils.font_size_16,
      StyleUtils.color_txt_light,
    ]),
  },
});
