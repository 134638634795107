import React from "react";
import { Alert, View } from "react-native";
import OneWebView from "../../Utils/OneWebView";
import MathInputStaticServer from "./MathInputStaticServer";

type Props = {
  processedLatex;
};

class MathInput extends React.Component<Props, any> {
  webView;

  handleMessage(event) {
    let msgData;
    try {
      const nativeEvent = event.nativeEvent;
      if (nativeEvent) {
        msgData = JSON.parse(event.nativeEvent.data);
      } else {
        msgData = event;
        console.log("handling message from web");
      }
      this.props.processedLatex(msgData.latex);
    } catch (err) {
      console.log("error", err);
    }
  }

  requestLatex = () => {
    console.log("requesting latex");
    this.webView.injectJavaScript({
      action: "requestLatex",
    });
  };

  render() {
    return (
      <View style={[{ flex: 1 }]}>
        <MathInputStaticServer
          ref={(webView) => (this.webView = webView)}
          handleMessage={(event) => this.handleMessage(event)}
        />
      </View>
    );
  }
}

export default MathInput;
