import React from "react";
import { Component } from "react";
import { View, Text, TextInput } from "react-native";
import { connect } from "react-redux";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import * as Server from "../Server";
import User from "../Models/User";
import { setTuteeInfo, setTutorInfo } from "../Actions";
import TuteeInfo from "../Models/TuteeInfo";
import ButtonElement from "../Elements/ButtonElement";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  history;
  user: User;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  dispatch;
};

type State = {
  tutorInput?: string;
  failMessage?: string;
  failMessageMakeTutor?: string;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch,
  };
}

class ChooseTutor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tutorInput: "",
    };
  }
  render() {
    return (
      <View
        style={[
          StyleUtils.screen_default,
          { flexDirection: "row", justifyContent: "center" },
        ]}
      >
        <View
          style={{
            maxWidth: 370,
            flex: 1,
            alignItems: "stretch",
          }}
        >
          <View
            style={{
              flex: 1,
              padding: 32,
              justifyContent: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.font_btn_bold,
                StyleUtils.font_size_20,
                StyleUtils.color_txt_dark,
                { textAlign: "center" },
              ]}
            >
              Conecte-se com seu tutor
            </Text>
            <Text
              style={[
                StyleUtils.font_btn_bold,
                StyleUtils.font_size_14,
                StyleUtils.color_txt_light,
                {
                  alignSelf: "flex-start",
                  marginTop: 40,
                },
              ]}
            >
              Email do tutor*
            </Text>
            <TextInput
              style={[
                StyleUtils.border_color_btn_muted,
                StyleUtils.font_size_16,
                StyleUtils.font_btn_regular,
                StyleUtils.color_txt_light,
                {
                  borderRadius: 10,
                  height: 48,
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  marginTop: 8,
                },
                !!this.state.failMessage && StyleUtils.border_color_btn_danger,
              ]}
              placeholder="tutore@tutore.com.br"
              placeholderTextColor={COLOR_MUTED}
              onChangeText={(text) => this.setState({ tutorInput: text })}
              value={this.state.tutorInput}
            />
            <FailMessage
              message={this.state.failMessage}
              style={{ marginTop: 4 }}
            />

            <ButtonElement
              styte={{ margin: undefined, marginTop: 40 }}
              colorScheme="primary"
              text="Continuar"
              onPress={() => this.chooseTutor()}
              disabled={!this.state.tutorInput}
            />
          </View>
          <View
            style={{
              padding: 32,
            }}
          >
            <Text
              style={[
                StyleUtils.font_btn_regular,
                StyleUtils.font_size_14,
                StyleUtils.color_txt_light,
                { textAlign: "center" },
              ]}
            >
              {
                "**Caso você não saiba o email,\nentre em contato com o seu tutor."
              }
            </Text>

            <FailMessage
              message={this.state.failMessageMakeTutor}
              style={{ marginTop: 16 }}
            />
            <ButtonElement
              styte={[
                { margin: undefined, marginTop: 16, alignSelf: "center" },
                !!this.state.failMessageMakeTutor && { marginTop: 4 },
              ]}
              textStyle={{ padding: 8, paddingHorizontal: 16 }}
              colorScheme="white"
              text="Eu sou um tutor"
              onPress={() => this.makeTutor()}
            />
          </View>
        </View>
      </View>
    );
  }

  chooseTutor() {
    if (!this.state.tutorInput) {
      return;
    }
    Server.chooseTutor(this.props.user.id, this.state.tutorInput)
      .then((tuteeInfo) => {
        this.setState({ failMessage: undefined });
        this.props.setTuteeInfo(tuteeInfo);
        this.props.history.push("/");
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          failMessage: "Tutor não encontrado. Verifique o email.",
        });
      });
  }

  makeTutor() {
    Server.makeTutor(this.props.user.id)
      .then((tutorInfo) => {
        this.props.dispatch(setTutorInfo(tutorInfo));
        this.props.history.push("/");
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          failMessageMakeTutor: "Falha na execução",
        });
      });
  }
}

function FailMessage(props: { message?: string; style?: ViewStyle }) {
  return (
    <>
      {!!props.message && (
        <View
          style={[
            StyleUtils.color_btn_danger,
            StyleUtils.shape_card_default,
            {
              paddingVertical: 8,
              paddingHorizontal: 16,
            },
            props.style,
          ]}
        >
          <Text
            style={[
              StyleUtils.font_btn_regular,
              StyleUtils.font_size_14,
              StyleUtils.color_txt_white,
              {
                alignSelf: "flex-start",
              },
            ]}
          >
            {props.message}
          </Text>
        </View>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTutor);
