import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import BackbuttonWhite from "../../assets/backbuttonWhite.svg";
import SVGImage from "../Utils/SVGImage";

type Props = {
  mobile?: boolean;
  style?: any;
  onPress?: () => void;
};

const AssignHomeworkHeaderBack = (props: Props) => {
  return (
    <View
      style={[
        StyleUtils.color_bg_dark,
        { justifyContent: "center" },
        !props.mobile && {
          height: 60,
          alignItems: "center",
        },
      ]}
    >
      <View
        style={[
          { alignItems: "flex-start", justifyContent: "center" },
          props.style,
        ]}
      >
        <TouchableOpacity
          style={[{ flexDirection: "row" }, props.mobile && { margin: 16 }]}
          onPress={props.onPress}
        >
          <SVGImage style={{ marginRight: 2 }} source={<BackbuttonWhite />} />

          <Text
            style={[
              StyleUtils.font_btn_regular,
              StyleUtils.color_txt_white,
              StyleUtils.font_size_14,
            ]}
          >
            Voltar
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default AssignHomeworkHeaderBack;
