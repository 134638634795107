import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import BackArrow from "../../assets/backButtonDark.svg";
import SVGImage from "../Utils/SVGImage";
import StyleUtils, { COLOR_TXT_DARK } from "../Utils/StyleUtils";

type Props = {
  onBack;
};

export default function BackButtonDesktop(props: Props) {
  return (
    <View style={{ position: "absolute", left: '4%', top: 296, zIndex: 1 }}>
      <TouchableOpacity
        style={[
          StyleUtils.color_bg_white,
          {
            width: 149,
            height: 48,
            borderWidth: 2,
            borderColor: COLOR_TXT_DARK,
            borderRadius: 6,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginRight: 12
          },
        ]}
        onPress={() => { props.onBack() }}
      >
        <SVGImage source={<BackArrow />} />
        <Text style={[StyleUtils.font_cera_regular, StyleUtils.font_size_18, StyleUtils.color_txt_dark]}>
         Voltar
        </Text>
      </TouchableOpacity>
    </View>
  );
}
