import React from "react";
import { Text, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  onPress?: () => void;
};

const AssignHomeworkFinalize = (props: Props) => {
  return (
    <View style={[{ justifyContent: "center", alignItems: "center" }]}>
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_20,
        ]}
      >
        Exercícios Enviados
      </Text>
      <ButtonElement text="Finalizar" onPress={props.onPress} />
    </View>
  );
};

export default AssignHomeworkFinalize;
