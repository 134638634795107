import { useState, useEffect } from "react";

const useControlHeld = (onDownPress?, onUpPress?) => {
  const [controlHeld, setControlHeld] = useState(false);

  const downHandler = ({ key }) => {
    if (key === "Control") {
      onDownPress && onDownPress();
      setControlHeld(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === "Control") {
      onUpPress && onUpPress();
      setControlHeld(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return controlHeld;
};

export default useControlHeld;
