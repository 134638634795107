import React, { useRef } from "react";
import { Text, TextInput, TouchableOpacity, View } from "react-native";
import StyleUtils, { COLOR_MUTED } from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import BackButtonDark from "../../../assets/backButtonDark.svg";
import ButtonElement from "../../Elements/ButtonElement";
import { useState } from "react";

import ErrorPopUp from "./ErrorPopUp";
import useOnClickOutside from "../../hooks/useClickOnOutside";
type Props = {
  style?;
  userId;
  currentDirectory;
  history;
  handleSubmit;
  buttonText;
  handleBackButtonpress;
  refetchData;
  closePopUp;
};

const NewDirectoryPopUp = ({
  style,
  userId,
  currentDirectory,
  history,
  handleSubmit,
  buttonText,
  handleBackButtonpress,
  refetchData,
  closePopUp,
}: Props) => {
  const [name, setName] = useState("");
  let error = useRef("");
  const [showErrorPopUp, setShowErrorPopUp] = useState(false);

  const handleSubmitPress = () => {
    handleSubmit(name)
      .then(() => {
        closePopUp();
        refetchData(currentDirectory.id);
      })
      .catch((err) => {
        error.current = err.message;

        setShowErrorPopUp(true);
      });
  };
  const ref: any = useRef();
  useOnClickOutside(ref, () => closePopUp());
  return (
    <div ref={ref}>
      <View
        style={[
          StyleUtils.border_color_btn_muted,
          StyleUtils.color_bg_white,
          {
            width: 245,
            height: 175,
            borderRadius: 10,
            opacity: 1,
          },
          style,
        ]}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 16,
            borderColor: COLOR_MUTED,
            borderBottomWidth: 1,
          }}
        >
          <TouchableOpacity onPress={handleBackButtonpress}>
            <SVGImage
              style={{ width: 24, height: 24 }}
              source={<BackButtonDark />}
            />
          </TouchableOpacity>

          <Text
            style={[
              StyleUtils.font_size_16,
              StyleUtils.font_cera_bold,
              { marginLeft: 9 },
            ]}
          >
            Nova Pasta
          </Text>
        </View>
        <TextInput
          style={[
            StyleUtils.font_size_16,
            StyleUtils.font_cera_regular,
            {
              paddingVertical: 9,
              paddingHorizontal: 14,
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
            },
          ]}
          placeholder="Nome da pasta"
          placeholderTextColor={COLOR_MUTED}
          onChangeText={(text) => {
            setName(text);
          }}
        />
        <ButtonElement
          colorScheme="primary"
          text={buttonText}
          styte={[StyleUtils.shape_btn_default, { marginTop: 24 }]}
          textStyle={{ paddingVertical: 11, paddingHorizontal: 20 }}
          onPress={handleSubmitPress}
        />
      </View>
      <ErrorPopUp
        show={showErrorPopUp}
        header={"Não foi possível criar a pasta"}
        body={error.current}
        onReturn={() => {
          setShowErrorPopUp(false);
          handleBackButtonpress();
        }}
      />
    </div>
  );
};

export default NewDirectoryPopUp;
