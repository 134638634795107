import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
} from "react-native";
import StyleUtils, {
  COLOR_WHITE,
  COLOR_MUTED,
  COLOR_PRIMARY,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../../Analytics";
import BackButton from "../../../assets/closeIconSmall.svg";
import TutoreFutureLogo from "../../../assets/tutoreFutureLogo.svg";
import TutoreFuturePerson from "../../../assets/tutoreFuturePerson.svg";

type Props = {
  onContinueStudyPlan;
  onContinueVocationalTest;
  history;
};

export default function TFChoosePath(props: Props) {
  return (
    <LinearGradient
      colors={["#272B3E", "#005EC8"]}
      style={[
        StyleUtils.screen_default,
        {
          paddingVertical: 40,
        },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          marginLeft: 24,
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            analytics.sendClosePremiumPaywall();
            props.history.push("/home");
          }}
          style={{ position: "absolute", left: 0 }}
        >
          <SVGImage source={<BackButton />} />
        </TouchableOpacity>
        <SVGImage
          source={<TutoreFutureLogo />}
          style={{ alignSelf: "center" }}
        />
      </View>
      <SVGImage
        source={<TutoreFuturePerson />}
        style={{
          justifyContent: "center",
          alignSelf: "center",
          top: 120,
          position: "absolute",
        }}
      ></SVGImage>
      <View
        style={{
          borderRadius: 310,
          backgroundColor: COLOR_WHITE,
          left: -124,
          width: 244 + Dimensions.get("window").width,
          height: 750,
          zIndex: 0,
          position: "absolute",
          marginTop: Dimensions.get("window").width / 2 + 100,
        }}
      />
      <View
        style={{
          flex: 0.5,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          top: "47%",
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_22,
            StyleUtils.color_txt_dark,
            { textAlign: "center" },
          ]}
        >
          Já sabe qual carreira seguir?
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_light,
            { textAlign: "center" },
          ]}
        >
          O Tutore te ajuda em todos os passos,{"\n"} desde a escolha até a
          conquista!
        </Text>
        <TouchableOpacity
          style={{
            borderRadius: 6,
            borderWidth: 2,
            borderColor: COLOR_PRIMARY,
            width: "85%",
            paddingVertical: 12,
          }}
          onPress={() => props.onContinueStudyPlan()}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_primary,
              { textAlign: "center" },
            ]}
          >
            Sim! Criar plano de estudos
          </Text>
        </TouchableOpacity>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View style={styles.lineMuted}></View>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_light,
              StyleUtils.font_size_14,
            ]}
          >
            OU
          </Text>
          <View style={styles.lineMuted}></View>
        </View>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            StyleUtils.color_bg_primary,
            { marginTop: 8, width: "85%" },
          ]}
          onPress={() => {
            props.onContinueVocationalTest();
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_white,
              StyleUtils.font_size_16,
              { padding: 14, textAlign: "center" },
            ]}
          >
            Ainda não! Fazer teste vocacional
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  lineMuted: {
    flex: 1,
    borderTopWidth: 0,
    borderWidth: 1,
    borderColor: COLOR_MUTED,
    height: 0,
    marginVertical: 12,
    marginHorizontal: 32,
  },
});
