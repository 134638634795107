import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import Check from "../../assets/checkSmallPrimary.svg";
import { analytics } from "../Analytics";

type Props = {
  areas;
  onContinue;
  onBack;
  progress;
  question;
};

type State = {
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
  selectedGoals: string | null;
  otherGoals;
};

export default class ManyMultipleChoiceTemplate extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedZero: null,
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      selectedFive: null,
      selectedGoals: null,
      otherGoals: "",
    };
  }
  private myScrollView: any;

  toggleSelectedLevel(item) {
    switch (parseInt(item.id)) {
      case 0:
        if (item.name === "Outros") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (item.name === "Outros") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (item.name === "Outros") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (item.name === "Outros") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (item.name === "Outros") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (item.name === "Outros") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedLevel;
    switch (parseInt(item.id)) {
      case 0:
        isSelectedLevel = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedLevel = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedLevel = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedLevel = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedLevel = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedLevel = this.state.selectedFive === item;
        break;
    }
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedLevel ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: item.id % 2 ? 0 : 12,
            marginLeft: item.id % 2 ? 12 : 0,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <View
          style={[
            styles.checkmarkBase,
            isSelectedLevel
              ? styles.checkmarkSelected
              : styles.checkmarkUnselected,
          ]}
        >
          {isSelectedLevel && <SVGImage source={<Check />} />}
        </View>
        <Text style={[styles.cellText]}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : undefined}
          style={[StyleUtils.screen_default]}
          keyboardVerticalOffset={80}
        >
          <ScrollView
            style={{
              flex: 1,
              flexDirection: "column",
              backgroundColor: COLOR_WHITE,
            }}
            ref={(component) => {
              this.myScrollView = component;
            }}
          >
            <View style={[{ justifyContent: "center", width: "100%" }]}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 24,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{ flexDirection: "row" }}
                    onPress={() => {
                      this.props.onBack();
                    }}
                  >
                    <SVGImage source={<BackArrow />} />
                  </TouchableOpacity>
                </View>
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_size_16,
                  ]}
                >
                  Responda e ganhe uma aula grátis!
                </Text>
                <View style={{ flex: 1 }}></View>
              </View>
            </View>
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                width: "100%",
                zIndex: 10,
              }}
            />
            <View style={styles.container}>
              <View
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: COLOR_PRIMARY,
                  width: this.props.progress,
                }}
              />
            </View>
            <View
              style={{
                paddingHorizontal: 32,
                marginTop: 24,
              }}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_22,
                  {
                    textAlign: "left",
                    width: "100%",
                    marginBottom: 20,
                  },
                ]}
              >
                {this.props.question}
              </Text>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(0, 2)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(2, 4)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(4)
                  .map((area) => this.renderLevelCell(area))}
              </View>

              {this.state.selectedGoals && (
                <>
                  <Text
                    style={[
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_14,
                      StyleUtils.color_txt_normal,
                      {
                        textAlign: "left",
                        marginTop: 20,
                      },
                    ]}
                  >
                    Por favor, informe qual objetivo:
                  </Text>
                  <TextInput
                    value={this.state.otherGoals}
                    onChangeText={(text) => {
                      this.setState({ otherGoals: text });
                    }}
                    style={[
                      StyleUtils.font_cera_regular,
                      {
                        borderRadius: 6,
                        color: "black",
                        textAlign: "auto",
                        padding: 8,
                        borderWidth: 1,
                        borderColor: this.state.otherGoals
                          ? "#000000"
                          : COLOR_MUTED,
                        marginTop: 8,
                      },
                    ]}
                    onFocus={() =>
                      this.myScrollView.scrollTo({
                        x: 0,
                        y: 600,
                      })
                    }
                    placeholderTextColor={COLOR_MUTED}
                    placeholder={"Ex.: Estudar fora do país..."}
                  />
                </>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (
                this.state.selectedZero ||
                this.state.selectedOne ||
                this.state.selectedTwo ||
                this.state.selectedThree ||
                this.state.selectedFour ||
                this.state.selectedFive
              ) {
                let arr: string[] = [];
                if (this.state.selectedZero !== null) {
                  arr.push(this.state.selectedZero);
                }
                if (this.state.selectedOne !== null) {
                  arr.push(this.state.selectedOne);
                }
                if (this.state.selectedTwo !== null) {
                  arr.push(this.state.selectedTwo);
                }
                if (this.state.selectedThree !== null) {
                  arr.push(this.state.selectedThree);
                }
                if (this.state.selectedFour !== null) {
                  arr.push(this.state.selectedFour);
                }
                if (this.state.selectedFive !== null) {
                  arr.push(this.state.selectedFive);
                }
                analytics.sendSurveyResponse(
                  this.props.question,
                  arr.toString()
                );
                this.props.onContinue();
              }
            }}
            disabled={
              !this.state.selectedZero &&
              !this.state.selectedOne &&
              !this.state.selectedTwo &&
              !this.state.selectedThree &&
              !this.state.selectedFour &&
              !this.state.selectedFive
            }
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: COLOR_TRUE_WHITE,
  },
  cellBase: {
    height: 88,
    width: 155,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 25,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
  },
  container: {
    height: 4,
  },
  checkmarkSelected: {
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
    borderWidth: 1,
  },
  checkmarkBase: {
    height: "20%",
    marginLeft: 6,
    aspectRatio: 1,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 6,
  },
  checkmarkUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
});
