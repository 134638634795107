import React from "react";
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import CardArrowWhite from "../../assets/cardArrowWhite.svg";
import CardArrowPrimary from "../../assets/cardArrowPrimary.svg";
import CheckPrimary from "../../assets/checkPrimary.svg";
import { ViewStyle, TextStyle } from "react-web-gifted-chat";

type Options = {
  colorScheme?: "white" | "success" | "primary";
  type?: "arrow" | "selection" | "none";
};

type Props = {
  buttonText?: string;
  onPress?: (event: GestureResponderEvent) => void;
  selected?: boolean;
  children?: any;
  options?: Options;
  styte?: ViewStyle;
  textStyle?: TextStyle;
};

export const CardElement = (props: Props) => {
  const colorScheme = (props.options && props.options.colorScheme) || "white";
  const type = (props.options && props.options.type) || "arrow";
  const cardStyles = elementStyles(props.selected);
  const Arrow = cardStyles.arrows[colorScheme];

  return (
    <View
      style={[styles.mobileCard, cardStyles.borders[colorScheme], props.styte]}
    >
      {props.children}
      <TouchableOpacity
        style={[cardStyles.buttonStyles[colorScheme]]}
        onPress={props.onPress}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: type === "arrow" ? "space-between" : "flex-start",
            marginHorizontal: 24,
            marginVertical: 20,
            alignItems: "center",
          }}
        >
          {type === "selection" && (
            <Check
              selected={props.selected}
              style={cardStyles.selection[colorScheme]}
            />
          )}
          <Text style={[cardStyles.textStyles[colorScheme], props.textStyle]}>
            {props.buttonText}
          </Text>
          {type === "arrow" && <SVGImage source={<Arrow />} />}
        </View>
      </TouchableOpacity>
    </View>
  );
};

const Check = (props: { selected?: boolean; style?: any }) => {
  return (
    <View
      style={[
        StyleUtils.color_bg_white,
        {
          height: 22,
          width: 22,
          borderRadius: 11,
          justifyContent: "center",
          alignItems: "center",
          marginRight: 8,
        },
        props.style,
      ]}
    >
      {props.selected && <SVGImage source={<CheckPrimary />} />}
    </View>
  );
};

function elementStyles(selected?: boolean) {
  return !selected
    ? {
        buttonStyles: {
          white: styles.button_white,
          primary: styles.button_primary,
          success: styles.button_success,
        },
        borders: {
          white: styles.border_white,
          primary: styles.border_primary,
          success: styles.border_success,
        },
        textStyles: {
          white: styles.button_text_primary,
          primary: styles.button_text_white,
          success: styles.button_text_white,
        },
        arrows: {
          white: CardArrowPrimary,
          primary: CardArrowWhite,
          success: CardArrowWhite,
        },
        selection: {
          white: styles.selection_border_primary,
          primary: styles.selection_border_primary,
          success: styles.selection_border_success,
        },
      }
    : {
        buttonStyles: {
          white: styles.button_primary,
          primary: styles.button_white,
          success: styles.button_white,
        },
        borders: {
          white: styles.border_primary,
          primary: styles.border_white,
          success: styles.border_white,
        },
        textStyles: {
          white: styles.button_text_white,
          primary: styles.button_text_primary,
          success: styles.button_text_success,
        },
        arrows: {
          white: CardArrowWhite,
          primary: CardArrowPrimary,
          success: CardArrowPrimary,
        },
        selection: {
          white: styles.selection_border_primary,
          primary: styles.selection_border_primary,
          success: styles.selection_border_success,
        },
      };
}

const styles = StyleSheet.create({
  mobileCard: {
    ...StyleSheet.flatten(StyleUtils.shape_card_default),
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  border_white: {
    ...StyleSheet.flatten(StyleUtils.border_color_txt_white),
    borderWidth: 0,
  },
  border_primary: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_primary),
    borderWidth: 0,
  },
  border_success: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_success),
    borderWidth: 0,
  },
  button_white: {
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  button_primary: {
    ...StyleSheet.flatten(StyleUtils.color_btn_primary),
  },
  button_success: {
    ...StyleSheet.flatten(StyleUtils.color_btn_success),
  },
  button_text_white: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  button_text_primary: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
  },
  button_text_success: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_success),
  },
  selection_border_white: {
    ...StyleSheet.flatten(StyleUtils.border_color_txt_white),
  },
  selection_border_primary: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_primary),
  },
  selection_border_success: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_success),
  },
});

export default CardElement;
