import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import StyleUtils, {
  COLOR_WHITE,
  COLOR_MUTED,
  COLOR_PRIMARY,
} from "../Utils/StyleUtils";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  show;
  close;
  onPay;
  creditOptions;
  premium?;
};

type State = {
  selectedLevel: string | null;
};

export default class CreditOptionsPopup extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedLevel: null,
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedLevel === item) {
      this.setState({ selectedLevel: null });
    } else {
      this.setState({ selectedLevel: item });
    }
  }

  renderLevelCell = (item) => {
    const isSelectedLevel = this.state.selectedLevel === item;
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedLevel ? styles.cellSelected : styles.cellUnselected,
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            marginHorizontal: 16,
          }}
        >
          <Text
            style={[
              styles.cellTextBold,
              { paddingTop: item.discount !== "" ? 12 : 4 },
            ]}
          >
            {item.label}
          </Text>
          <View style={{ flexDirection: "column", justifyContent: "center" }}>
            {this.props.premium ? (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={[
                    styles.cellText,
                    { marginRight: 10, textDecorationLine: "line-through" },
                  ]}
                >
                  {item.id[0] == 1
                    ? "R$ 70,00"
                    : item.id[0] == 2
                    ? "R$ 100,00"
                    : item.id[0] == 4
                    ? "R$ 160,00"
                    : item.id[0] == 3
                    ? "R$ 180,00"
                    : item.id[0] == 6
                    ? "R$ 330,00"
                    : item.id[0] == 10
                    ? "R$ 500,00"
                    : ""}
                </Text>
                <Text style={[styles.cellText]}>{item.price}</Text>
              </View>
            ) : (
              <Text style={[styles.cellText]}>{item.price}</Text>
            )}
            {item.discount !== "" && (
              <Text
                style={[styles.cellTextDiscount, { alignSelf: "flex-end" }]}
              >
                {item.discount}
              </Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <AnimatedPopUp
        show={this.props.show}
        close={this.props.close}
        bgColor={COLOR_WHITE}
      >
        <Text
          style={[
            {
              alignSelf: "center",
              textAlign: "center",
            },
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_20,
            StyleUtils.color_txt_dark,
          ]}
        >
          {this.props.premium
            ? "Preços especiais para você:"
            : "Selecione uma opção:"}
        </Text>
        {this.props.premium && (
          <Text
            style={[
              {
                alignSelf: "center",
                textAlign: "center",
              },
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_20,
              StyleUtils.color_txt_success,
            ]}
          >
            Estudante Premium
          </Text>
        )}
        <View style={{ marginTop: 20 }}>
          {this.props.creditOptions.length > 0 &&
            this.props.creditOptions.map((level) =>
              this.renderLevelCell(level)
            )}
        </View>

        <ButtonElement
          colorScheme="primary"
          text="Realizar Pagamento"
          onPress={() => this.props.onPay(this.state.selectedLevel)}
          textStyle={styles.popupButtonText}
          styte={[styles.popupButton, { marginTop: 32 }]}
          disabled={!this.state.selectedLevel}
        />
      </AnimatedPopUp>
    );
  }
}

const styles = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
  cellBase: {
    height: 64,
    width: "100%",
    borderRadius: 8,
    alignItems: "center",
    flexDirection: "row",
    marginTop: 4,
    marginBottom: 4,
  },
  cellUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    borderColor: COLOR_PRIMARY,
    borderWidth: 2,
  },
  checkmarkUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  checkmarkSelected: {
    backgroundColor: COLOR_PRIMARY,
  },
  cellText: {
    textAlign: "right",
    paddingTop: 4,
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellTextBold: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellTextDiscount: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.color_txt_success),
  },
});
