import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import TitleWithNavigation from "../Elements/TitleWithNavigation";
import { CreditCardManager } from "./CreditCardManager";

type Props = {
  user;
  history;
  location;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

export function CreditCardSettings(props: Props) {
  return (
    <>
      <TitleWithNavigation
        title={"Meus Cartões"}
        onGoBackPress={props.history.goBack}
        options={{ colorStyle: "white" }}
        help
      />
      <View style={{ flex: 1 }}>
        <ScrollView style={styles.container}>
          <CreditCardManager
            user={props.user}
            location={props.location}
            history={props.history}
          />
        </ScrollView>
      </View>
    </>
  );
}

export default connect(stateToProps)(CreditCardSettings);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingTop: 20,
  },
});
