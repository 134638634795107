import React, { useState, useEffect } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
} from "react-native";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import { logout } from "../Actions";
import SettingsIcon from "../../assets/hamburger.svg";
import HeaderDecor from "../../assets/backgroundDots.svg";
import Background from "../../assets/gradientSettings.svg";
import { userShortName } from "../Utils/Utils";
import { connect } from "react-redux";
import { UserImage } from "../Elements/UserImages";
import ForwardArrow from "../../assets/forwardArrowSlim.svg";
import BuyCreditPopup from "../Popups/BuyCreditPopup";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

type Props = {
  user;
  history;
  logout;
  mobile;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
    mobile: state.layoutRed.mobile,
  };
}

function dispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

export function Settings(props: Props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentType, setPaymentType] = useState(0);

  const logout = () => {
    if (props.user) {
      props.logout();
    }
    props.history.push("/landing");
  };

  const acccountItems = [
    { id: 0, name: "Editar conta" },
    { id: 1, name: "Histórico de aulas" },
    { id: 2, name: "Indique um amigo" },
  ];

  const settingsItems = [
    { id: 0, name: "Suporte ao cliente", onClick: () => setModalVisible(true) },
    {
      id: 1,
      name: "Meus cartões",
      onClick: () => props.history.push("/user/cards/settings"),
    },
    { id: 2, name: "Sair da conta", onClick: logout },
  ];

  const settingsItemsWithoutCard = [
    { id: 0, name: "Suporte ao cliente", onClick: () => setModalVisible(true) },
    { id: 1, name: "Sair da conta", onClick: logout },
  ];

  useEffect(() => {
    RemoteConfigBridge.remoteConfig("paymentType", (value) => {
      setPaymentType(parseInt(value));
    });
  }, []);

  const renderNativeItem = (item) => {
    return (
      <>
        <TouchableOpacity style={styles.cell} onPress={() => item.onClick()}>
          <Text
            style={[
              StyleUtils.font_size_16,
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              { flex: 1 },
            ]}
          >
            {item.name}
          </Text>
          <SVGImage
            style={{
              marginRight: 12,
            }}
            source={<ForwardArrow />}
          />
        </TouchableOpacity>
        <View style={styles.cellSeparator} />
      </>
    );
  };

  return (
    <View style={StyleUtils.screen_default}>
      <View
        style={[
          {
            backgroundColor: "#0179FF",
            height: 160,
            justifyContent: "center",
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: 16,
          }}
        >
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                props.history.goBack();
              }}
            >
              <SVGImage source={<SettingsIcon />} />
            </TouchableOpacity>
          </View>
          <View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
                {
                  paddingTop: 8,
                },
              ]}
            >
              Configurações
            </Text>
          </View>
          <View style={{ flex: 1 }}></View>
        </View>
        <View
          style={{ flex: 1, borderColor: "#009BFF", borderWidth: 1 }}
        ></View>
        <View>
          <SVGImage
            source={<Background />}
            style={{ width: "100%", height: 112, position: "absolute" }}
          />
          <View
            style={{
              position: "absolute",
              marginTop: 16,
              marginLeft: 100,
            }}
          >
            <SVGImage source={<HeaderDecor />} />
          </View>
          <View style={{ flexDirection: "row" }}>
            <UserImage
              style={{
                width: 53,
                height: 62,
                borderRadius: 15,
                marginLeft: 24,
                marginTop: 24,
              }}
              imageURL={props.user ? props.user.imageURL : ""}
            />
            <View
              style={{ flexDirection: "column", marginLeft: 8, marginTop: 8 }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_18,
                  StyleUtils.font_cera_regular,
                  { marginTop: 32 },
                ]}
              >
                Bem-vindo
              </Text>
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_20,
                  StyleUtils.font_cera_bold,
                  { marginBottom: 32 },
                ]}
              >
                {userShortName(props.user ? props.user.name : "")}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          paddingHorizontal: 24,
          marginTop: props.user ? 16 : 48,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_light,
            {
              textAlign: "left",
              width: "100%",
            },
          ]}
        >
          Sobre o serviço
        </Text>
        <View style={{ marginTop: 20 }}>
          <FlatList
            data={paymentType === 2 ? settingsItems : settingsItemsWithoutCard}
            renderItem={({ item }) => renderNativeItem(item)}
          />
        </View>
      </View>
      {!!props.mobile && (
        <BuyCreditPopup
          close={() => setModalVisible(false)}
          show={modalVisible}
          header={"Tem alguma dúvida? Fale conosco pelo nosso Whatsapp!"}
          buttonText={"Falar agora"}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  cell: {
    height: 50,
    alignItems: "center",
    flexDirection: "row",
  },
  cellSeparator: {
    position: "absolute",
    bottom: 0,
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: COLOR_MUTED,
  },
});

export default connect(stateToProps, dispatchToProps)(Settings);
