import React, { useState, useEffect } from "react";
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { connect } from "react-redux";
import StarYellow from "../../assets/starYellow.svg";
import { LikeIcon } from "../Elements/IconElements";
import { UserImageBackGround } from "../Elements/UserImages";
import { Tutor } from "../Models/TutorInfo";
import StyleUtils, {
  COLOR_SUCCESS,
  COLOR_TRUE_WHITE,
  COLOR_TXT_DARK,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import { formatCurrency, userShortName } from "../Utils/Utils";
import { DiscountBadge } from "../Payment/DiscountBadge";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import FirstSessionFreeBannerBig from "../../assets/greenRibbon.svg";
import FirstSessionFreeBanner from "../../assets/firstSessionFreeBanner.svg";
import { getUserCredit, getEvents, EventsFilter } from "../Server";

type Props = {
  tutor: Tutor;
  style?: StyleProp<ViewStyle>;
  onSelectTutor?: (tutor: Tutor) => void;
  onChatPress?: () => void;
  onLikePress?: (favorite: boolean) => void;
  userID?: string;
  fromDynamicLink;
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
    fromDynamicLink: state.userRed.fromDynamicLink,
  };
}

function TutorCard(props: Props) {
  const { tutor } = props;
  const hasRatings = tutor.rating && tutor.rating.count > 0;
  const hasLessons = tutor.rating && tutor.rating.eventsCompleted > 0;
  const online = false;
  const favorite = !!(
    props.userID &&
    props.tutor.tutorInfo &&
    props.tutor.tutorInfo.tutees &&
    props.tutor.tutorInfo.tutees[props.userID] &&
    props.tutor.tutorInfo.tutees[props.userID].favorite
  );
  const [priceShowABTest, setPriceShowABTest] = useState(0);
  const [firstClassFree, setFirstClassFree] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pricePackageShow, setPricePackageShow] = useState(false);

  useEffect(() => {
    RemoteConfigBridge.remoteConfig("pricePackageShow", (value) => {
      var valueBool = value == "1";
      setPricePackageShow(valueBool);
    });

    RemoteConfigBridge.remoteConfig("priceShow", (value) => {
      setPriceShowABTest(parseInt(value));
    });

    RemoteConfigBridge.remoteConfig("firstClassFree", (value) => {
      var valueBool = value == "1";
      if (valueBool && !props.fromDynamicLink) {
        if (props.userID) {
          getUserCredit(props.userID).then((res) => {
            if (res.amount === 0) {
              var eventFilter: EventsFilter = {
                tutorId: undefined,
                tuteeId: props.userID,
                periodStart: undefined,
                periodEnd: undefined,
                limit: 1,
              };
              getEvents(eventFilter).then((result: any) => {
                if (result && result.length === 0) {
                  setFirstClassFree(true);
                }
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          setFirstClassFree(true);
        }
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => props.onSelectTutor && props.onSelectTutor(tutor)}
      delayPressIn={100}
    >
      <View style={{ flex: 1, minHeight: 174 }}>
        <UserImageBackGround
          imageURL={tutor.imageURL}
          style={styles.tutorImage}
        />
        {tutor.tutorInfo!.firstSessionFreeDuration &&
          !firstClassFree &&
          !loading && (
            <View
              style={{
                marginLeft: 4,
                top: 142,
                position: "absolute",
                justifyContent: "center",
              }}
            >
              <SVGImage source={<FirstSessionFreeBanner />}></SVGImage>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_12,
                  {
                    color: COLOR_TRUE_WHITE,
                    left: 20,
                    position: "absolute",
                    top: Platform.OS === "ios" ? 5 : 0,
                  },
                ]}
              >
                {`${tutor.tutorInfo!.firstSessionFreeDuration}min Grátis`}
              </Text>
            </View>
          )}
        {firstClassFree && (
          <View
            style={{
              marginLeft: 8,
              top: 142,
              position: "absolute",
              justifyContent: "center",
            }}
          >
            <SVGImage source={<FirstSessionFreeBannerBig />} />
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_12,
                {
                  color: COLOR_TRUE_WHITE,
                  left: 10,
                  position: "absolute",
                  paddingTop: Platform.OS === "ios" ? 2 : 0,
                },
              ]}
            >
              1a Aula Grátis
            </Text>
          </View>
        )}

        {online && (
          <View
            style={[
              StyleUtils.color_bg_white_transparency,
              {
                flexDirection: "row",
                alignItems: "flex-start",
                borderRadius: 6,
                overflow: "hidden",
                position: "absolute",
                top: 9,
                left: 9,
                paddingHorizontal: 4,
                height: 16,
              },
            ]}
          >
            <View
              style={{
                height: 10,
                width: 10,
                borderRadius: 5,
                overflow: "hidden",
                backgroundColor: COLOR_SUCCESS,
                marginRight: 4,
              }}
            />
            <Text
              style={[
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_12,
                StyleUtils.font_btn_regular,
                { textAlignVertical: "bottom" },
              ]}
            >
              Online
            </Text>
          </View>
        )}
        <LikeIcon
          onPress={() => props.onLikePress && props.onLikePress(!favorite)}
          selected={favorite}
          height={20}
          width={20}
          color="white"
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            height: 24,
            width: 24,
            borderRadius: 12,
            backgroundColor: COLOR_TXT_DARK + "4C",
          }}
        />
      </View>

      <View style={styles.tutorDetailsContainer}>
        <Text style={styles.textTutorName}>{userShortName(tutor.name)}</Text>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {hasRatings && (
            <SVGImage
              style={{ width: 12, height: 12, bottom: 3 }}
              source={<StarYellow />}
            />
          )}
          {tutor.rating && (
            <>
              {hasRatings && (
                <Text
                  style={[
                    StyleUtils.font_size_14,
                    styles.textDetails,
                    {
                      paddingLeft: 4,
                    },
                  ]}
                >
                  {tutor.rating.average.toFixed(1)} •
                </Text>
              )}
              {hasLessons && (
                <Text style={[StyleUtils.font_size_14, styles.textDetails]}>
                  {`${tutor.rating.eventsCompleted} ${
                    tutor.rating.eventsCompleted === 1 ? "Aula" : "Aulas"
                  }`}
                </Text>
              )}
            </>
          )}
        </View>

        {(priceShowABTest === 0 || pricePackageShow) && (
          <Text
            style={[
              StyleUtils.font_size_14,
              styles.textDetails,
              {
                paddingTop: !hasRatings && !hasLessons ? 4 : 0,
              },
            ]}
          >
            {tutor.tutorInfo &&
            tutor.tutorInfo.hourPrice &&
            tutor.tutorInfo.hourPrice > 0
              ? `${formatCurrency(
                  tutor.tutorInfo.currencyCode,
                  tutor.tutorInfo.hourPrice,
                  0
                )} por aula`
              : "Aula Grátis"}
          </Text>
        )}

        {priceShowABTest === 1 && !pricePackageShow && (
          <DiscountBadge style={[]} discount={50} light={true} />
        )}

        {priceShowABTest === 3 && !pricePackageShow && (
          <Text
            style={[
              StyleUtils.font_size_14,
              styles.textDetails,
              {
                paddingTop: !hasRatings && !hasLessons ? 4 : 0,
              },
            ]}
          >
            {tutor.tutorInfo &&
              tutor.tutorInfo.creditPrice &&
              tutor.tutorInfo.creditPrice}
            {tutor.tutorInfo &&
            tutor.tutorInfo.creditPrice &&
            tutor.tutorInfo.creditPrice === 1
              ? " crédito/aula"
              : " créditos/aula"}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    maxWidth: 152,
    maxHeight: 240,
    borderRadius: 14,
    margin: 16,
  },
  tutorImage: {
    width: "100%",
    height: 174,
    position: "absolute",
    overflow: "hidden",
    borderRadius: 14,
    zIndex: -1,
  },
  onlineBackground: {
    maxWidth: "80%",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 6,
    margin: 10,
    padding: 1,
    paddingRight: 10,
    paddingLeft: 10,
    ...StyleSheet.flatten(StyleUtils.color_bg_white_transparency),
  },
  tutorDetailsContainer: { width: "100%", marginTop: 10, height: "100%" },
  textDetails: {
    padding: 1,
    marginLeft: 2,
    textAlign: "left",
    ...StyleSheet.flatten(StyleUtils.font_cera_medium),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  textTutorName: {
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
  },
});

export default connect(mapStateToProps, null)(TutorCard);
