import React from "react";
import { Dimensions, StyleProp, StyleSheet, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { ViewStyle } from "react-web-gifted-chat";
import { LinearGradient } from "../Utils/LinearGradient";
import StyleUtils from "../Utils/StyleUtils";

type Props = {
  children?;
  style?: StyleProp<ViewStyle>;
};

export function getCircleBackgroundContainerCenter() {
  const height = Dimensions.get("window").height;
  return Math.ceil((height * 0.62) / 2);
}

export function CircleBackgroundContainer(props: Props) {
  return (
    <LinearGradient
      colors={["#0179FF", "#18BAFF"]}
      style={StyleUtils.screen_default}
    >
      <SafeAreaView style={StyleSheet.flatten([{ flex: 1 }, props.style])}>
        <View
          style={[
            {
              height: "62%",
              width: "100%",
              alignItems: "center",
              position: "absolute",
              flexDirection: "column",
              alignSelf: "center",
            },
          ]}
        >
          <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={["#0055B5", "rgba(1, 121, 255, 0)"]}
            locations={[0.0453, 0.5993]}
            style={{
              position: "absolute",
              top: 10,
              left: -10,
              right: -10,
              bottom: 0,
              height: "100%",
              borderRadius: 10000,
              alignItems: "center",
              justifyContent: "center",
            }}
          ></LinearGradient>
        </View>
        {props.children}
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  textLarge: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    textAlign: "center",
  },
  text: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary_light),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
});
