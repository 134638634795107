import React, { Component } from "react";
import {
  Image,
  Keyboard,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { connect } from "react-redux";
import StyleUtils, { COLOR_MUTED, SpcStyle } from "../Utils/StyleUtils";
import BackArrow from "../../assets/backbutton.svg";
import SVGImage from "../Utils/SVGImage";
import PlaceHolderGirl from "../../assets/placeHolderGirl.svg";
import PlaceHolderBoy from "../../assets/placeHolderBoy.svg";
import BlueCamera from "../../assets/blueCamera.svg";
import ButtonNextBlue from "./ButtonNextBlue";
import ImageUploader from "../ImageUploader/ImageUploader";
import { getFileURL } from "../Server";
import SignUpUser from "../Models/SignUpUser";

type Props = {
  onBack;
  userData: SignUpUser;
  onNext;
  onSkipScreen;
};

type State = {
  userData: SignUpUser;
};

class SignUpPhotoUploader extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    //console.log();
  }

  onImageUploadSuccessful(file) {
    console.log("upload successful");
    const filename = file.name ? file.name : file.fileName;
    const fileURL = getFileURL(filename);
    this.setState({ userData: { ...this.state.userData, imageURL: fileURL } });
  }

  getProfileImage() {
    if (this.state.userData.imageURL) {
      return (
        <Image
          style={{ height: 176, width: 152, borderRadius: 14 }}
          source={{ uri: this.state.userData.imageURL }}
        />
      );
    }

    if (this.props.userData.gender === "masculino") {
      return <SVGImage source={<PlaceHolderBoy />} />;
    } else {
      return <SVGImage source={<PlaceHolderGirl />} />;
    }
  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 32,
              marginRight: 24,
              marginLeft: 24,
            }}
          >
            <TouchableOpacity
              style={[
                {
                  alignSelf: "flex-start",
                  aspectRatio: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 16,
                  marginLeft: 0,
                },
              ]}
              onPress={() => {
                this.props.onBack();
              }}
            >
              <SVGImage source={<BackArrow />} />
            </TouchableOpacity>
            <TouchableOpacity onPress={this.props.onSkipScreen}>
              <Text
                style={[
                  { padding: 10, paddingRight: 0 },
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_16,
                  StyleUtils.font_cera_regular,
                ]}
              >
                Pular
              </Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              width: "100%",
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text style={SpcStyle.subheaderText}>
              Para finalizar,{"\n"}
              <Text style={SpcStyle.headerText}>envie sua foto do perfil</Text>
            </Text>
            <View
              style={{
                flex: 2,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  marginTop: 80,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SVGImage
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "-10%",
                    right: "20%",
                  }}
                  source={<BlueCamera />}
                ></SVGImage>

                {this.getProfileImage()}
              </View>
              {!this.state.userData.imageURL ? (
                <Text
                  style={[
                    { margin: 24, textAlign: "center" },
                    StyleUtils.color_txt_grey_light,
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_regular,
                  ]}
                >
                  Selecione uma foto para {"\n"}o seu perfil de estudante
                </Text>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    this.setState({
                      userData: { ...this.state.userData, imageURL: undefined },
                    });
                  }}
                >
                  <Text
                    style={[
                      {
                        margin: 24,
                        textAlign: "center",
                        textDecorationLine: "underline",
                      },
                      StyleUtils.color_txt_dark,
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                    ]}
                  >
                    Remover foto
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>

          {this.state.userData.imageURL ? (
            <ButtonNextBlue
              onClick={this.props.onNext}
              isValidInfo={true}
              text={"Confirmar"}
            />
          ) : (
            <ImageUploader
              isVisible={true}
              isMobile={true}
              onImageUploaded={this.onImageUploadSuccessful.bind(this)}
              descriptionText={"Selecionar foto..."}
              isUINative={true}
            />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default SignUpPhotoUploader;
