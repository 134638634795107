import React, { useEffect, useState, useRef } from "react";
import { Text, Platform, AppState, Dimensions } from "react-native";
import { Provider } from "react-redux";
import { Router } from "./Router";
import Routes from "./Routes";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./Redux/Store";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/pt-BR";
import VersionManager from "./Utils/VersionManager";
import StyleUtils from "./Utils/StyleUtils";
import { analytics } from "./Analytics";
import RNUxcam from "react-native-ux-cam";
import Environment from "./Environment";
import { ToastProvider } from "react-native-toast-notifications";

function initUXCam() {
  if (Environment.ENV != "production") return;
  try {
    RNUxcam && RNUxcam.optIntoSchematicRecordings(); // Add this line to enable iOS screen recordings
    RNUxcam && RNUxcam.startWithKey(Environment.UX_CAM_KEY); // Add this line after RNUxcam.optIntoSchematicRecordings();
  } catch (err) {
    console.warn(err);
  }
}
initUXCam();

export function App() {
  const [buildNumber, setBuildNumber] = useState<string>("x");
  const [appVersion, setAppVersion] = useState<string>("x");
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  if (Environment.ENV === "production") {
    try {
      useEffect(() => {
        AppState.addEventListener("change", _handleAppStateChange);
        return () => {
          AppState.removeEventListener("change", _handleAppStateChange);
        };
      }, []);
    } catch (err) {
      console.warn(err);
    }
  }

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active") {
      RNUxcam.allowShortBreakForAnotherApp(false);
    } else if (nextAppState === "inactive") {
      RNUxcam.allowShortBreakForAnotherApp(true);
    }
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  useEffect(() => {
    VersionManager.getBuildNumber((build) => setBuildNumber(build));
    VersionManager.getAppVersion((version) => setAppVersion(version));
    analytics.sendSessionStarted();
  }, []);

  function buildInfo() {
    return (
      <Text
        style={[
          StyleUtils.color_txt_muted,
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_12,
          {
            position: "absolute",
            right: "8%",
            zIndex: 1000,
          },
          Platform.OS === "web" && { bottom: 4 },
          Platform.OS !== "web" && { top: 4 },
          Platform.OS === "web" &&
            Dimensions.get("window").width < 500 && { opacity: 0 },
        ]}
      >
        b{buildNumber}v{appVersion}
      </Text>
    );
  }

  return (
    <ToastProvider placement="top" offset={50}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <Router>
              {buildInfo()}
              <Routes />
            </Router>
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    </ToastProvider>
  );
}
