import React, { Component } from "react";
import { Text, TouchableOpacity, View, SafeAreaView } from "react-native";
import BackArrow from "../../../assets/backbutton.svg";
import Checkmark from "../../../assets/checkWhite.svg";
import StyleUtils, { COLOR_PRIMARY, SpcStyle } from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import Footer from "../../Footer/Footer";

type Props = {
  levels;
  onContinue;
  onBack;
  headerText;
  showFooter?;
};

type State = {
  selectedLevel: string | null;
};

export default class SpcLevel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedLevel: null,
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedLevel === item) {
      this.setState({ selectedLevel: null });
    } else {
      this.setState({ selectedLevel: item });
      let showFooter = false;
      if (this.props.showFooter) {
        showFooter = true;
      }
      this.props.onContinue(item, showFooter);
    }
  }

  renderLevelCell = (item) => {
    const isSelectedLevel = this.state.selectedLevel === item;
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          SpcStyle.cellBase,
          isSelectedLevel ? SpcStyle.cellSelected : SpcStyle.cellUnselected,
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <View
          style={[
            SpcStyle.checkmarkBase,
            isSelectedLevel
              ? SpcStyle.checkmarkSelected
              : SpcStyle.checkmarkUnselected,
          ]}
        >
          {isSelectedLevel && <SVGImage source={<Checkmark />} />}
        </View>
        <Text
          style={[
            SpcStyle.cellText,
            isSelectedLevel && { color: COLOR_PRIMARY },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <TouchableOpacity
            style={[
              {
                alignSelf: "flex-start",
                margin: 16,
                marginTop: 70,
              },
            ]}
            onPress={() => {
              this.props.onBack();
            }}
          >
            <SVGImage source={<BackArrow />} />
          </TouchableOpacity>
          <View
            style={{
              height: "60%",
              width: "100%",
              justifyContent: "center",
              marginTop: 16,
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  marginTop: 30,
                  width: "100%",
                },
              ]}
            >
              {this.props.headerText}
            </Text>
            <View style={{ marginTop: 20 }}>
              {this.props.levels.map((level) => this.renderLevelCell(level))}
            </View>
          </View>
        </View>
        {!!this.props.showFooter && (
          <SafeAreaView>
            <Footer />
          </SafeAreaView>
        )}
      </>
    );
  }
}
