import React from "react";
import { Text, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import DiscountBadgeWhite from "../../assets/discountBadgeWhite.svg";
import DiscountBadgeGreen from "../../assets/discountBadgeGreen.svg";

export function DiscountBadge(props) {
  const { discount, style, light } = props;
  return (
    <View
      style={[
        ...style,
        {
          height: 22,
          borderRadius: 6,
          padding: 5,
          flexDirection: "row",
          alignItems: "center",
        },
        light ? StyleUtils.color_bg_successlight : StyleUtils.color_bg_success,
      ]}
    >
      <SVGImage
        source={light ? <DiscountBadgeGreen /> : <DiscountBadgeWhite />}
      />
      <Text
        style={[
          StyleUtils.font_cera_regular,
          light ? StyleUtils.color_txt_success : StyleUtils.color_txt_white,
          StyleUtils.font_size_14,
          { marginLeft: 5 },
        ]}
      >
        {discount}% Desconto
      </Text>
    </View>
  );
}
