import React, { Component } from "react";
import { Button, FlatList, Text, View } from "react-native";
import { connect } from "react-redux";
import { selectEvent, selectUser } from "../Actions";
import StyleUtils from "../Utils/StyleUtils";
import { LobbyUser, tutorLobbyConnection } from "./QuickClassConnection";

type State = { clientList };

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectUser: (selectedUser) => dispatch(selectUser(selectedUser)),
    selectEvent: (selectedEvent) => dispatch(selectEvent(selectedEvent)),
  };
}

class QuickClassList extends Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
    };
  }

  componentDidMount() {
    tutorLobbyConnection.open();
    tutorLobbyConnection.on("userListUpdate", this.onUserListUpdate);
  }

  componentWillUnmount() {
    tutorLobbyConnection.removeListener(
      "userListUpdate",
      this.onUserListUpdate
    );
    tutorLobbyConnection.close();
  }

  onUserListUpdate = (users: Array<LobbyUser>) => {
    this.setState({ clientList: users.map((u) => u.id) });
  };

  createClass = (clientID) => {
    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + 15 * 60 * 1000);
    const event = {
      id: "86667d84-e646-4a19-adf6-9b7107decc97",
      startDate: startDate,
      endDate: endDate,
      quickclass: true,
    };
    tutorLobbyConnection.joinQuickClass({
      clientId: clientID,
      selectedEvent: event,
      userInfo: this.props.currentUser,
    });
    this.props.selectUser({
      id: clientID,
    });
    this.props.selectEvent(event);
    this.props.history.push("/classroom");
  };

  render() {
    return (
      <View style={this.props.style}>
        <View
          style={{ flexDirection: "row", margin: 16, alignItems: "center" }}
        >
          <Text
            style={[
              StyleUtils.font_size_18,
              StyleUtils.font_cera_bold,
              { marginRight: 8 },
            ]}
          >
            Quick Class queue
          </Text>
          <Button
            title={"Refresh"}
            onPress={() => {
              tutorLobbyConnection.refresh();
            }}
          />
        </View>
        <FlatList
          data={this.state.clientList}
          renderItem={(client) => (
            <View
              style={[
                StyleUtils.shape_btn_small,
                StyleUtils.color_bg_light,
                {
                  padding: 5,
                  margin: 5,
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <Text style={[StyleUtils.font_size_16, { marginRight: 16 }]}>
                {client.item}
              </Text>
              <Button
                title={"Join Quick Class"}
                onPress={() => {
                  this.createClass(client.item);
                }}
              />
            </View>
          )}
        />
      </View>
    );
  }
}

export default connect(stateToProps, mapDispatchToProps)(QuickClassList);
