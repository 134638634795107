import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import ReactModal from "react-modal";
import { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  visible?: boolean;
  onRequestClose?: () => void;
  children?: any;
  style?: ViewStyle;
};

export default class Popup extends Component<Props, any> {
  render() {
    return (
      <ReactModal
        isOpen={!!this.props.visible}
        onRequestClose={this.props.onRequestClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.25)",
          },
          content: {
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            height: "50%",
            minWidth: 300,
            minHeight: 500,
            transform: "translate(-50%, -50%)",
          },
        }}
        ariaHideApp={false}
      >
        <View style={[styles.modalView, this.props.style]}>
          {this.props.children}
        </View>
      </ReactModal>
    );
  }
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(52, 52, 52, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    backgroundColor: COLOR_TRUE_WHITE,
    borderRadius: 8,
    shadowColor: "#000",
    maxWidth: 350,
    maxHeight: 550,
    alignSelf: "center",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    flexDirection: "column",
    overflow: "hidden",
  },
});
