import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  View,
} from "react-native";
import { analytics } from "../../Analytics";
import StyleUtils, { COLOR_TXT_DARK } from "../../Utils/StyleUtils";
import BackButton from "../../../assets/backButtonBorder.svg";
import SVGImage from "../../Utils/SVGImage";

type Props = {
  onContinue;
  onBack;
};

type State = {
  comment: string;
};

export default class TutorComment extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
    };
  }
  private myScrollView: any;

  render() {
    return (
      <>
        <TouchableOpacity
          style={{ marginLeft: 8, marginTop: 16, alignSelf: "flex-start" }}
          onPress={() => {
            this.props.onBack();
          }}
        >
          <SVGImage source={<BackButton />} />
        </TouchableOpacity>
        <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          style={[StyleUtils.screen_default]}
        >
          <ScrollView
            style={{ flex: 1 }}
            ref={(component) => {
              this.myScrollView = component;
            }}
          >
            <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
              <Text
                style={[
                  {
                    textAlign: "center",
                    margin: "8%",
                    marginTop: 80,
                    marginBottom: 0,
                  },
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_btn_bold,
                  StyleUtils.font_size_22,
                ]}
              >
                Deixe um comentário público para o tutor!
              </Text>
              <View
                style={[
                  {
                    margin: "5%",
                    alignItems: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_btn_bold,
                    StyleUtils.color_txt_dark,
                    { textAlign: "center" },
                  ]}
                >
                  Nos ajude a garantir um ambiente incrível para que todos da
                  comunidade Tutore aprendam da melhor forma possível!
                </Text>

                <View
                  style={[
                    {
                      flexDirection: "row",
                      width: 311,
                      borderRadius: 10,
                      height: 136,
                      marginTop: 32,
                    },
                  ]}
                >
                  <TextInput
                    style={[
                      StyleUtils.color_txt_dark,
                      {
                        borderRadius: 10,
                        padding: 12,
                        width: "100%",
                        height: "100%",
                        textAlignVertical: "top",
                        borderColor: COLOR_TXT_DARK,
                        borderWidth: 1,
                      },
                    ]}
                    multiline={true}
                    onChangeText={(text) => this.setState({ comment: text })}
                    maxLength={255}
                    onFocus={() =>
                      this.myScrollView.scrollTo({
                        x: 0,
                        y: 300,
                        animated: true,
                      })
                    }
                  />
                </View>
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
        <TouchableOpacity
          style={[
            { margin: "7%", borderRadius: 6, width: 311, alignSelf: "center" },
            StyleUtils.color_bg_primary,
          ]}
          onPress={() => {
            analytics.sendTutorRatingSubmitted();
            this.props.onContinue(this.state.comment.trim());
          }}
        >
          <Text
            style={[
              { textAlign: "center", margin: 16 },
              StyleUtils.font_btn_bold,
              StyleUtils.color_txt_white,
              StyleUtils.font_size_16,
            ]}
          >
            Finalizar
          </Text>
        </TouchableOpacity>
      </>
    );
  }
}
