import React from "react";
import { Text, StyleSheet } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";

type Props = {
  msg?: string;
  visible?: boolean;
};

const CreditCardErrorPopup = (props: Props) => {
  return (
    <AnimatedPopUp show={props.visible}>
      <Text style={[StyleUtils.font_cera_bold, StyleUtils.font_size_16]}>
        Não foi possível adicionar seu cartão.
      </Text>
      <Text style={styles.msgError}>{props.msg}</Text>
    </AnimatedPopUp>
  );
};

export default CreditCardErrorPopup;

const styles = StyleSheet.create({
  msgError: {
    padding: 5,
  },
});
