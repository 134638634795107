import { GiftedChat } from "react-web-gifted-chat";

import RtmClient from "./RtmClient";
import {
  getCachedMessages,
  loadMessagesWeb as serverLoadMessages,
  saveMessageWeb as serverSaveMessage,
} from "../Server";
import AsyncStorage from "@react-native-community/async-storage";
import { ToastType } from "react-native-toast-notifications";
import _ from "lodash";
import { analytics } from "../Analytics";

export default class ChatConnection {
  constructor(currentUser, selectedUser, channelID, toast: ToastType) {
    this.channelID = channelID;
    this.currentUser = currentUser;
    this.selectedUser = selectedUser;
    this.toast = toast;
    this.UNSENT_MESSAGES_KEY = `@UNSENT_MESSAGES-${this.channelID}`;
  }

  client;
  channelID;
  currentUser;
  selectedUser;
  messages: any[] = [];
  stateUpdate;
  UNSENT_MESSAGES_KEY;
  toast: ToastType;

  async classUnmount() {
    if (this.client) {
      try {
        await this.client.logout();
      } catch (err) {
        console.log(err);
      }
    }
  }

  classMount() {
    this.client = new RtmClient();
    try {
      this.client.init("8e6218b6e6984290a74036fe3abd0160");
      this.client
        .login(this.currentUser.id, null)
        .then(() => {
          console.log("login");
          return serverLoadMessages(this.channelID, [
            this.currentUser,
            this.selectedUser,
          ]);
        })
        .then(async (messages) => {
          const unsentMessages = await this.getUnsentMessages();
          messages.push(...unsentMessages);

          if (unsentMessages && unsentMessages.length) {
            this.toast.show(
              "Existem mensagens que não foram enviadas. \n\nToque e segure sobre a mensagem para reenviar.",
              { type: "danger", duration: 8000 }
            );
          }
          messages.sort((a: any, b: any) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });

          this.messages = messages;
          this.subscribeChannelMessage();
          return this.client.joinChannel(this.channelID);
        })
        .then(() => {
          this.client.channels[this.channelID].joined = true;
          if (this.stateUpdate) {
            this.stateUpdate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.error(err);
    }
  }

  getInitialMessages() {
    return getCachedMessages(this.channelID, [
      this.currentUser,
      this.selectedUser,
    ]);
  }

  subscribeChannelMessage() {
    this.client.on("ChannelMessage", ({ channelName, args }) => {
      const [message, memberId] = args;
      const text = message.text;
      if (channelName === this.channelID) {
        this.processChatMessage(memberId, text);
      }
    });
  }

  processChatMessage = (memberId, text) => {
    if (text.indexOf("https") === -1) {
      this.messages = GiftedChat.append(this.messages, [
        {
          id: +new Date(),
          text,
          user: {
            id: memberId,
            name: this.selectedUser.name,
          },
          createdAt: new Date(),
        },
      ]);
    } else {
      this.messages = GiftedChat.append(this.messages, [
        {
          id: +new Date(),
          user: {
            id: memberId,
            name: this.selectedUser.name,
          },
          createdAt: new Date(),
          image: text,
        },
      ]);
    }
    console.log("message from current channel", text);
    if (this.stateUpdate) this.stateUpdate();
  };

  async sendChatMessage(messages: any[] = []) {
    try {
      messages.forEach((message: any) => {
        message.id = message.id ? message.id : message._id;

        // Resend case
        const sentMessageIndex = this.messages.findIndex(
          (msg) => msg.id == message.id && message.pending == true
        );
        if (sentMessageIndex >= 0) {
          message.createdAt = new Date().toISOString();
          this.messages.splice(sentMessageIndex, 1);
        }

        message.sent = true;
        message.pending = false;

        this.messages = GiftedChat.append(this.messages, message);
        if (this.stateUpdate) {
          this.stateUpdate();
        }

        serverSaveMessage({
          from: this.currentUser.id,
          to: this.selectedUser.id,
          text: `${message.text}`,
          imageUrl: `${message.image ? message.image : ""}`,
          timestamp: `${new Date().toISOString()}`,
          channel: this.channelID,
        })
          .then(() => {
            this.cleanUpSentMessages([message]);

            if (message.text === "") {
              this.client.sendChannelMessage(message.image, this.channelID);
            } else {
              this.client.sendChannelMessage(message.text, this.channelID);
            }
          })
          .catch((error) => {
            this.addUnsentMessage(message);

            this.toast.show("Não foi possível enviar sua mensagem!", {
              type: "danger",
              duration: 5000,
            });
            analytics.sendChatMessageFailed(
              this.currentUser,
              this.selectedUser,
              error
            );
          })
          .finally(() => {
            if (this.stateUpdate) {
              this.stateUpdate();
            }
          });
      });
    } catch (err) {
      console.warn(err);
    }
  }

  async addUnsentMessage(message) {
    try {
      message.pending = true;

      let cachedMessages: any = await AsyncStorage.getItem(
        this.UNSENT_MESSAGES_KEY
      );
      cachedMessages = cachedMessages ? JSON.parse(cachedMessages) : [];

      // prevent to save same message
      if (cachedMessages.find((msg) => msg.id == message.id)) {
        return;
      }
      cachedMessages.push(message);
      await AsyncStorage.setItem(
        this.UNSENT_MESSAGES_KEY,
        JSON.stringify(cachedMessages)
      );
    } catch (err) {
      return err;
    }
  }

  async cleanUpSentMessages(messages) {
    try {
      let cachedMessages: any = await AsyncStorage.getItem(
        this.UNSENT_MESSAGES_KEY
      );
      cachedMessages = cachedMessages ? JSON.parse(cachedMessages) : [];
      if (
        !messages ||
        !messages.length ||
        !cachedMessages ||
        !cachedMessages.length
      )
        return null;

      for (const message of messages) {
        const index = cachedMessages.findIndex((m) => m.id == message.id);
        if (index >= 0) {
          cachedMessages.splice(index, 1);
        }
      }

      await AsyncStorage.setItem(
        this.UNSENT_MESSAGES_KEY,
        JSON.stringify(cachedMessages)
      );
    } catch (err) {
      return null;
    }
  }

  async getUnsentMessages() {
    try {
      let messages: any = await AsyncStorage.getItem(this.UNSENT_MESSAGES_KEY);
      messages = messages ? JSON.parse(messages) : [];
      messages = messages.map((m) => ({
        ...m,
        sent: false,
        pending: true,
      }));

      return messages;
    } catch (err) {
      return [];
    }
  }
}
