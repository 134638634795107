import React from "react";
import { Component } from "react";
import { Text, TouchableOpacity, View, StyleSheet } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { connect } from "react-redux";
import { scheduleDraftDaysChosen, selectUser } from "../Actions";
import Select from "react-select";
import TimeFive from "../../assets/timeFive.svg";
import SVGImage from "../Utils/SVGImage";
import { getScheduleHours, setScheduleHours } from "../Server";

const weekDaysNumber: string[] = ["0", "1", "2", "3", "4", "5", "6"];
const weekDaysLetter: string[] = ["D", "S", "T", "Q", "Q", "S", "S"];
const hours = [
  { value: ["00:00", "01:00"], label: "00:00 - 01:00" },
  { value: ["01:00", "02:00"], label: "01:00 - 02:00" },
  { value: ["02:00", "03:00"], label: "02:00 - 03:00" },
  { value: ["03:00", "04:00"], label: "03:00 - 04:00" },
  { value: ["04:00", "05:00"], label: "04:00 - 05:00" },
  { value: ["05:00", "06:00"], label: "05:00 - 06:00" },
  { value: ["06:00", "07:00"], label: "06:00 - 07:00" },
  { value: ["07:00", "08:00"], label: "07:00 - 08:00" },
  { value: ["08:00", "09:00"], label: "08:00 - 09:00" },
  { value: ["09:00", "10:00"], label: "09:00 - 10:00" },
  { value: ["10:00", "11:00"], label: "10:00 - 11:00" },
  { value: ["11:00", "12:00"], label: "11:00 - 12:00" },
  { value: ["12:00", "13:00"], label: "12:00 - 13:00" },
  { value: ["13:00", "14:00"], label: "13:00 - 14:00" },
  { value: ["14:00", "15:00"], label: "14:00 - 15:00" },
  { value: ["15:00", "16:00"], label: "15:00 - 16:00" },
  { value: ["16:00", "17:00"], label: "16:00 - 17:00" },
  { value: ["17:00", "18:00"], label: "17:00 - 18:00" },
  { value: ["18:00", "19:00"], label: "18:00 - 19:00" },
  { value: ["19:00", "20:00"], label: "19:00 - 20:00" },
  { value: ["20:00", "21:00"], label: "20:00 - 21:00" },
  { value: ["21:00", "22:00"], label: "21:00 - 22:00" },
  { value: ["22:00", "23:00"], label: "22:00 - 23:00" },
  { value: ["23:00", "00:00"], label: "23:00 - 00:00" },
];
const week = [] as any;
const calendar: any = [];
type Props = {
  history;
  scheduleDraftDaysChosen;
  scheduleDraftDays;
  userID;
  selectUser;
  tutorInfo;
};

type State = {
  selectedDays: string[];
  selectedOptions: any;
  sunday: any;
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  calendarConfig: any;
  loading: number;
};

function dispatchToProps(dispatch) {
  return {
    scheduleDraftDaysChosen: (days) => dispatch(scheduleDraftDaysChosen(days)),
    selectUser: (user) => dispatch(selectUser(user)),
  };
}

function stateToProps(state) {
  return {
    scheduleDraftDays: state.scheduleDraftDays,
    userID: state.userRed.user.id,
    tutorInfo: state.userRed.tutorInfo,
  };
}

function getStateLoading(state) {
  switch (state) {
    case 1:
      return "Salvar alterações";
      break;
    case 2:
      return "Salvando...";
      break;
    case 3:
      return "Pronto!";
      break;
  }
}

class ScheduleDayPicker extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: props.scheduleDraftDays,
      selectedOptions: null,
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      calendarConfig: [],
      loading: 1,
    };
  }

  componentDidMount() {
    try {
      getScheduleHours(this.props.userID)
        .then((res) => {
          // todo refactor
          const sundayHours: any = res["weekWorkHours"][0].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setSunday(sundayHours);

          const mondayHours: any = res["weekWorkHours"][1].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setMonday(mondayHours);

          const tuesdayHours: any = res["weekWorkHours"][2].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setTuesday(tuesdayHours);

          const wednesdayHours: any = res["weekWorkHours"][3].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setWednesday(wednesdayHours);

          const thursdayHours: any = res["weekWorkHours"][4].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setThursday(thursdayHours);

          const fridayHours: any = res["weekWorkHours"][5].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setFriday(fridayHours);

          const saturdayHours: any = res["weekWorkHours"][6].map((i) => {
            let label = i.join(" - ");
            return hours.find((h) => h.label === label);
          });
          this.setSaturday(saturdayHours);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log("error fetch data");
    }
  }

  continueButtonEnabled = () => {
    return this.state.selectedDays.length > 0;
  };

  handleChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  };

  setSunday = (sunday) => {
    this.setState({ sunday });
  };

  setMonday = (monday) => {
    this.setState({ monday });
  };

  setTuesday = (tuesday) => {
    this.setState({ tuesday });
  };

  setWednesday = (wednesday) => {
    this.setState({ wednesday });
  };

  setThursday = (thursday) => {
    this.setState({ thursday });
  };

  setFriday = (friday) => {
    this.setState({ friday });
  };

  setSaturday = (saturday) => {
    this.setState({ saturday });
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  updateHours() {
    // TODO REFACTOR
    const sundayHours: any = [];
    this.state.sunday.map((i) => {
      sundayHours.push(i.value);
    });

    const mondayHours: any = [];
    this.state.monday.map((i) => {
      mondayHours.push(i.value);
    });

    const tuesdayHours: any = [];
    this.state.tuesday.map((i) => {
      tuesdayHours.push(i.value);
    });

    const wednesdayHours: any = [];
    this.state.wednesday.map((i) => {
      wednesdayHours.push(i.value);
    });

    const thursdayHours: any = [];
    this.state.thursday.map((i) => {
      thursdayHours.push(i.value);
    });

    const fridayHours: any = [];
    this.state.friday.map((i) => {
      fridayHours.push(i.value);
    });

    const saturdayHours: any = [];
    this.state.saturday.map((i) => {
      saturdayHours.push(i.value);
    });

    const week: any[] = [];
    week.push(
      sundayHours,
      mondayHours,
      tuesdayHours,
      wednesdayHours,
      thursdayHours,
      fridayHours,
      saturdayHours
    );
    const weekWork = {
      timeZone: "America/Sao_Paulo",
      weekWorkHours: week,
    };

    this.setLoading(2);
    setScheduleHours(weekWork, this.props.userID)
      .then((res) => {
        // console.log(res)
        setTimeout(() => {
          this.setLoading(3);
        }, 400);
        setTimeout(() => {
          this.setLoading(1);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <View style={style.container}>
        <View style={style.col_lm}>
          <Text
            style={[
              { textAlign: "left", margin: 8 },
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_20,
              StyleUtils.font_btn_bold,
            ]}
          >
            > Editar meus horários
          </Text>
          <Text
            style={[
              { textAlign: "left", margin: 8 },
              StyleUtils.color_txt_light,
              StyleUtils.font_size_14,
            ]}
          >
            > Seus alunos poderão agendar aulas com você em qualquer horário
            disponível ao lado
          </Text>
          <View style={style.divisor_bar}></View>
          <View style={style.sub_header}>
            <SVGImage style={style.icon} source={<TimeFive />} />
            <Text
              style={[
                { textAlign: "left", margin: 8 },
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
              ]}
            >
              > Horário de Brasília
            </Text>
          </View>
          <Text
            style={[
              { textAlign: "left", margin: 8 },
              StyleUtils.color_txt_light,
              StyleUtils.font_size_14,
            ]}
          >
            > Todos os horários de agendamento são baseados no horário de
            Brasília.
          </Text>
        </View>
        <View style={style.col_lg}>
          <View style={[{ zIndex: 9999999 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Domingo:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="sunday"
                closeMenuOnSelect={false}
                value={this.state.sunday}
                isMulti
                onChange={this.setSunday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <View style={[{ zIndex: 999999 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Segunda:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="monday"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.monday}
                onChange={this.setMonday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <View style={[{ zIndex: 99999 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Terça:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="tuesday"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.tuesday}
                onChange={this.setTuesday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <View style={[{ zIndex: 9999 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Quarta:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="wednesday"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.wednesday}
                onChange={this.setWednesday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <View style={[{ zIndex: 999 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Quinta:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="thursday"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.thursday}
                onChange={this.setThursday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <View style={[{ zIndex: 99 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Sexta:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="friday"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.friday}
                onChange={this.setFriday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <View style={[{ zIndex: 9 }, style.days]}>
            <Text
              style={[
                { textAlign: "left", fontWeight: "600", marginBottom: 5 },
                style.labelDay,
                StyleUtils.color_txt_dark,
              ]}
            >
              > Sábado:
            </Text>
            <View style={style.customSelect}>
              <Select
                name="saturday"
                closeMenuOnSelect={false}
                isMulti
                value={this.state.saturday}
                onChange={this.setSaturday}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione o horário"
                options={hours}
              />
            </View>
          </View>
          <TouchableOpacity
            style={[{ zIndex: 1 }, style.btnSave, StyleUtils.color_bg_primary]}
            onPress={() => {
              this.updateHours();
            }}
          >
            <Text
              style={[StyleUtils.color_txt_white, StyleUtils.font_btn_bold]}
            >
              {getStateLoading(this.state.loading)}
            </Text>
          </TouchableOpacity>
        </View>
      </View>

      // <View
      //   style={{
      //     alignSelf: "center",
      //     justifyContent: "center",
      //     maxWidth: 375,
      //     flexGrow: 1,
      //     margin: 20,
      //   }}
      // >
      //   <Text
      //     style={[
      //       { textAlign: "center", margin: 8 },
      //       StyleUtils.color_txt_dark,
      //       StyleUtils.font_size_20,
      //       StyleUtils.font_btn_bold,
      //     ]}
      //   >
      //     Quais dias você pode lecionar?
      //   </Text>
      //   <Text
      //     style={[
      //       { textAlign: "center", margin: 8 },
      //       StyleUtils.color_txt_light,
      //       StyleUtils.font_size_14,
      //     ]}
      //   >
      //     Esses dias ficarão disponíveis para os alunos agendarem aulas com
      //     você.
      //   </Text>
      //   <View
      //     style={{
      //       flexDirection: "row",
      //       justifyContent: "space-evenly",
      //       marginTop: 30,
      //     }}
      //   >
      //     {weekDaysNumber.map((letter) => {
      //       return (
      //         <TouchableOpacity
      //           onPress={() => {
      //             console.log(this);
      //             let selectedDays = this.state.selectedDays;
      //             if (this.state.selectedDays.includes(letter))
      //               selectedDays = selectedDays.filter((a) => {
      //                 return a != letter;
      //               });
      //             else selectedDays.push(letter);
      //             this.setState({
      //               selectedDays: selectedDays,
      //             });
      //           }}
      //           style={[
      //             this.state.selectedDays.includes(letter)
      //               ? StyleUtils.color_btn_primary
      //               : StyleUtils.border_color_btn_muted,
      //             StyleUtils.shape_btn_default_rounded,
      //             { width: 40, height: 40, justifyContent: "center" },
      //           ]}
      //         >
      //           <Text
      //             style={[
      //               StyleUtils.font_size_16,
      //               this.state.selectedDays.includes(letter)
      //                 ? StyleUtils.color_txt_white
      //                 : StyleUtils.color_txt_dark,
      //               { textAlign: "center" },
      //             ]}
      //           >
      //             {weekDaysLetter[weekDaysNumber.indexOf(letter)]}
      //           </Text>
      //         </TouchableOpacity>
      //       );
      //     })}
      //   </View>
      //   <TouchableOpacity
      //     onPress={() => {
      //       this.props.scheduleDraftDaysChosen(this.state.selectedDays);
      //       this.props.history.push("/schedulehour");
      //     }}
      //     disabled={!this.continueButtonEnabled()}
      //     style={[
      //       this.continueButtonEnabled()
      //         ? StyleUtils.color_btn_primary
      //         : StyleUtils.color_btn_muted,
      //       StyleUtils.shape_btn_default_rounded,
      //       { marginTop: "20%" },
      //     ]}
      //   >
      //     <Text
      //       style={[
      //         { margin: 20, textAlign: "center" },
      //         StyleUtils.color_txt_white,
      //         StyleUtils.font_size_16,
      //         StyleUtils.font_btn_bold,
      //       ]}
      //     >
      //       Continuar
      //     </Text>
      //   </TouchableOpacity>
      // </View>
    );
  }
}

const style = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignSelf: "center",
    margin: 20,
    marginTop: 60,
    marginBottom: 240,
    maxWidth: 1140,
    width: "100%",
    justifyContent: "space-evenly",
  },
  divisor_bar: {
    borderWidth: 1,
    borderColor: "#eeeeee",
    width: "80%",
    marginTop: 60,
    marginBottom: 60,
  },
  icon: {
    width: 24,
  },
  sub_header: {
    flexDirection: "row",
  },
  col_lm: {
    width: "40%",
    paddingRight: 60,
  },
  col_lg: {
    width: "60%",
  },
  days: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "center",
  },
  labelDay: {},
  customSelect: {
    width: "90%",
  },
  btnSave: {
    marginTop: 30,
    width: 340,
    padding: 10,
    borderRadius: 8,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontWeight: "600",
    zIndex: 0,
  },
});

export default connect(stateToProps, dispatchToProps)(ScheduleDayPicker);
