import React from "react";
import { View, StyleSheet, Text } from "react-native";
import SVGImage from "../Utils/SVGImage";
import Shield from "../../assets/shieldTutore.svg";
import StyleUtils from "../Utils/StyleUtils";

export function PaymentSeals() {
  return (
    <View>
      <View style={style.bottomAlter}>
        <View style={style.alterArea}>
          <SVGImage source={<Shield />} />
          <Text style={[StyleUtils.color_txt_primary, style.alertTitle]}>
            Realizando Compra Segura
          </Text>
        </View>
        <View style={style.divisor}>
          <Text style={style.alertText}>
            Todas as suas informações são criptogradas e asseguradas pela
          </Text>
        </View>
      </View>
    </View>
  );
}

export default PaymentSeals;

const style = StyleSheet.create({
  bottomAlter: {
    backgroundColor: "rgba(225, 232, 243, 0.2)",
    padding: 35,
  },
  alterArea: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alertTitle: {
    fontSize: 14,
    fontWeight: "600",
    marginLeft: 15,
  },
  alertText: {
    fontSize: 14,
    color: "rgba(186, 198, 218, 1)",
  },
  divisor: {
    borderTopColor: "#eee",
    borderTopWidth: 1,
    marginTop: 15,
    paddingTop: 10,
  },
});
