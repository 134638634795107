import React, { Component } from "react";
import CanvasWebView from "./CanvasWebView";
import { StyleSheet, TouchableOpacity, View, Alert } from "react-native";
import CanvasTools from "./CanvasTools";
import SVGImage from "../Utils/SVGImage";
import LogoIcon from "../../assets/logo_icon.svg";
import StyleUtils, { COLOR_MUTED, COLOR_WHITE } from "../Utils/StyleUtils";
import { getFileURL, whiteboardURL, uploadFile } from "../Server";
import { CanvasConnection } from "./CanvasConnection";
import CanvasTextTools from "./CanvasTextTools";
import { CanvasPageTools } from "./CanvasPageTools";
import CanvasAuxiliarTextView from "./CanvasAuxiliarTextView";
import BackButton from "../../assets/backButtonBorder.svg";
import CanvasSelectionTools from "./CanvasSelectionTools";
import CanvasExercisesHolder from "../Exercises/CanvasExercises/CanvasExercisesHolder";
import { uuidv4 } from "../Utils/Utils";

type Props = {
  userID;
  mobile;
  hideVideo;
  isTutor;
  canvasConnection: CanvasConnection;
  channelID;
  selectedUser;
  closeWhiteboard;
  style;
  chatConnection?;
};

type State = {
  exerciseOpen;
  screenSize;
  myRef;
  selectedTool;
  submenu;
  blink;
  zoom;
};

let startTime = new Date();

export default class Canvas2 extends Component<Props, State> {
  canvas;
  canvasTools;
  canvasPageTools;
  canvasTextTools;
  canvasAuxTextView;
  canvasSelectionTools;
  canvasExercisesHolder;

  constructor(props) {
    super(props);
    this.state = {
      exerciseOpen: false,
      screenSize: { width: 0, height: 0 },
      myRef: React.createRef(),
      blink: false,
      selectedTool: "pencil",
      submenu: "",
      zoom: false,
    };
  }

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    this.setState({
      screenSize: layout,
    });
    setTimeout(() => {
      this.canvas.sendAction("setupSize", "");
      console.log("setupSize");
    }, 500); //TODO: remover essa gambi
  };

  componentDidMount() {
    this.props.canvasConnection.addOnActionListener(this.onRemoteAction);
  }

  componentWillUnmount() {
    this.props.canvasConnection.removeOnActionListener(this.onRemoteAction);
  }

  captureScreenshot = (uri) => {
    //Restrict from sending screenshot twice
    if (Math.abs(new Date().getTime() - new Date(startTime).getTime()) > 100) {
      let filename = uuidv4() + "_whiteboard";
      this.start();
      var arr = uri.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      let file = new File([u8arr], filename, { type: mime });
      uploadFile(
        file,
        (res) => {
          const fileURL = getFileURL(filename);
          const imageMessage = [
            {
              id: uuidv4(),
              text: "",
              user: {
                id: this.props.userID,
              },
              createdAt: new Date(),
              image: fileURL,
            },
          ];
          if (this.props.chatConnection) {
            this.props.chatConnection.sendChatMessage(imageMessage);
          }
        },
        (err) => {
          console.log(err);
        },
        () => {
          console.log("cancel");
        }
      );
    }
  };

  renderTools = () => {
    return (
      <View style={this.props.mobile ? [style.mobile] : style.web}>
        {this.props.mobile && (
          <View
            style={[
              StyleUtils.color_bg_white,
              StyleUtils.shape_btn_default,
              {
                shadowOffset: { height: -2, width: 0 },
                shadowRadius: 6,
                shadowColor: "#000",
                shadowOpacity: 0.1,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                top: -5,
                height: 55,
              },
            ]}
          />
        )}
        {!this.props.mobile && (
          <View>
            <SVGImage style={{ margin: 16 }} source={<LogoIcon />} />
            <View style={{ height: 1, backgroundColor: COLOR_MUTED }} />
          </View>
        )}
        <CanvasTools
          ref={(canvasTools) => (this.canvasTools = canvasTools)}
          selectedTool={this.state.selectedTool}
          submenu={this.state.submenu}
          changeSubmenu={(toolName) => this.setState({ submenu: toolName })}
          changeTool={(toolName) => this.setState({ selectedTool: toolName })}
          style={[]}
          isTutor={this.props.isTutor}
          mobile={this.props.mobile}
          onToolSelected={(toolName) => {
            this.setState({ exerciseOpen: toolName === "exercise" });
            if (this.props.mobile && toolName === "text") {
              this.canvasAuxTextView.open();
            }
            this.canvas.sendAction("changeTool", toolName);
          }}
          onChangeColor={(color) => {
            this.canvas.sendAction("changeColor", color);
          }}
          onChangeStroke={(stroke) => {
            this.canvas.sendAction("changeStroke", stroke);
          }}
          onAddShape={(shape) => {
            this.canvas.sendAction("addShape", shape);
          }}
          onImageUploaded={(file) => {
            const filename = file.name ? file.name : file.fileName;
            const fileURL = getFileURL(filename);
            this.canvas.sendAction("addImage", fileURL);
          }}
          onSetFormula={(data) => {
            this.canvasTools.toolButtonPressed("hand");
            console.log(data);
            this.canvas.sendAction("addLatexFormula", { formula: data });
          }}
          onSetZoom={(value) => {
            this.setState({ zoom: value });
            this.canvas.sendAction("zoom", { zoom: value });
          }}
          onSetScreenshot={() => {
            this.canvas.sendAction("screenshot");
            this.setState({ blink: true });
            setTimeout(() => {
              this.setState({ blink: false });
            }, 300);
            setTimeout(() => {
              Alert.alert("Lousa salva no chat!", "");
            }, 600);
          }}
        />
      </View>
    );
  };

  start() {
    startTime = new Date();
  }

  render() {
    return (
      <View
        style={[
          ...this.props.style,
          this.props.mobile
            ? style.canvasHolder_mobile
            : style.canvasHolder_web,
          {
            opacity: this.state.blink ? 0 : 1,
          },
        ]}
        onLayout={this.onLayout}
      >
        {!this.props.mobile && this.renderTools()}
        <CanvasWebView
          ref={(canvas) => (this.canvas = canvas)}
          boardUrl={whiteboardURL}
          otherUserName={this.props.selectedUser.name}
          channelName={this.props.channelID}
          onMessage={this.onMessage}
        />
        {!this.props.mobile && (
          <CanvasTextTools
            ref={(canvasTextTools) => (this.canvasTextTools = canvasTextTools)}
            mobile={this.props.mobile}
            sendToCanvas={(a, b) => this.canvas.sendAction(a, b)}
            sendRemote={(data) => this.props.canvasConnection.sendAction(data)}
            zoom={this.state.zoom}
          />
        )}
        <CanvasPageTools
          ref={(canvasPageTools) => (this.canvasPageTools = canvasPageTools)}
          sendToCanvas={(a, b) => this.canvas.sendAction(a, b)}
          mobile={this.props.mobile}
        />
        <CanvasSelectionTools
          ref={(canvasSelectionTools) =>
            (this.canvasSelectionTools = canvasSelectionTools)
          }
          mobile={this.props.mobile}
          sendToCanvas={(a, b) => this.canvas.sendAction(a, b)}
          zoom={this.state.zoom}
        />

        {this.props.mobile && (
          <TouchableOpacity
            style={{
              top: 40,
              width: 40,
              height: 48,
              justifyContent: "center",
              position: "absolute",
            }}
            onPress={this.props.closeWhiteboard}
          >
            <View
              style={{
                flex: 1,
                marginLeft: 15,
                marginTop: 15,
                justifyContent: "center",
              }}
            >
              <SVGImage
                source={<BackButton />}
                style={{ width: 40, height: 40 }}
              />
            </View>
          </TouchableOpacity>
        )}
        {this.state.exerciseOpen && (
          <CanvasExercisesHolder
            ref={(canvasExercisesHolder) =>
              (this.canvasExercisesHolder = canvasExercisesHolder)
            }
            channelID={this.props.channelID}
            isTutor={this.props.isTutor}
            style={[
              {
                backgroundColor: COLOR_WHITE,
                position: "absolute",
              },
              this.props.mobile
                ? {
                    width: this.state.screenSize.width,
                    height: this.state.screenSize.height - 150,
                    top: 100,
                    overflow: "hidden",
                  }
                : {
                    width: 700,
                    height: this.state.screenSize.height,
                    left: 72,
                    overflow: "hidden",
                  },
            ]}
            onUpdate={() => {
              this.props.canvasConnection.sendAction({
                action: "exercises:refresh",
              });
            }}
            selectedUser={this.props.selectedUser}
          />
        )}
        {this.props.mobile && this.renderTools()}
        {this.props.mobile && (
          <CanvasAuxiliarTextView
            ref={(canvasAuxTextView) =>
              (this.canvasAuxTextView = canvasAuxTextView)
            }
            onSetText={(data) => {
              this.canvasTools.toolButtonPressed("hand");
              this.canvas.sendAction("addStyledText", data);
              this.canvasAuxTextView.close();
            }}
            onSetFormula={(data) => {
              this.canvasTools.toolButtonPressed("hand");
              this.canvas.sendAction("addLatexFormula", data);
              this.canvasAuxTextView.close();
            }}
          />
        )}
      </View>
    );
  }

  onMessage = (data) => {
    switch (data.action) {
      case "canvasAction":
        this.props.canvasConnection.sendAction(data.info);
      case "canvasActionLocal":
        if (this.canvasTextTools != null)
          this.canvasTextTools.handleCanvasAction(data);
        if (this.canvasAuxTextView != null)
          this.canvasAuxTextView.handleCanvasAction(data);
        if (this.canvasPageTools != null)
          this.canvasPageTools.handleCanvasAction(data);
        if (this.canvasSelectionTools != null)
          this.canvasSelectionTools.handleCanvasAction(data);
        if (data.info.action === "screenshot:taken")
          this.captureScreenshot(data.info.imageURL);
        break;
    }
  };

  onRemoteAction = (data) => {
    console.log(data);
    if (this.canvasExercisesHolder)
      this.canvasExercisesHolder.handleRemoteAction(data);
    this.canvasPageTools.handleRemoteAction(data);
    this.canvas.sendAction("canvasAction", data);
  };
}

const style = StyleSheet.create({
  mobile: {
    position: "absolute",
    alignItems: "center",
    alignSelf: "center",
    bottom: 0,
    left: 0,
    right: 0,
  },
  web: {
    width: 72,
    backgroundColor: COLOR_WHITE,
    shadowColor: "#000000",
    shadowOpacity: 0.15,
    shadowRadius: 10,
    zIndex: 1,
  },
  canvasHolder_web: {
    flex: 1,
    flexDirection: "row",
  },
  canvasHolder_mobile: {
    flex: 1,
    flexDirection: "column",
  },
});
