import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
} from "react-native";
import StyleUtils, { COLOR_WHITE } from "../Utils/StyleUtils";
import SubjectTestListHeader from "../../assets/subjectTestListHeader.svg";
import SVGImage from "../Utils/SVGImage";
import BackButtonWhite from "../../assets/backbuttonWhite.svg";
import { connect } from "react-redux";
import { selectSubjectTest, setSubjectTests } from "../Actions";
import {
  getSubjectTest,
  getSubjectTests,
  getTutorSubjectTests,
} from "../Server";
import SubjectTest from "../Models/SubjectTest";
import SubjectTestCard from "./SubjectTestCard";
import Footer from "../Footer/Footer";
import { analytics } from "../Analytics";

type Props = {
  location;
  dispatch;
  tutorInfo;
  user;
  history;
  selectSubjectTest;
  mobile?: boolean;
  talkspaceOnboading: boolean;
};

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    user: state.userRed.user,
    talkspaceOnboading: state.userRed.talkspaceOnboarding,
    tutorInfo: state.userRed.tutorInfo,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectSubjectTest: (subjectTest) =>
      dispatch(selectSubjectTest(subjectTest)),
    dispatch: dispatch,
  };
}

function SubjectTestList(props: Props) {
  const [subjectTests, setSubjectTest] = useState(new Array<SubjectTest>());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    analytics.sendShowSubjectTestList();
    if (props.tutorInfo) {
      getTutorSubjectTests(props.user.id).then((result) => {
        props.dispatch(setSubjectTests(result, !!props.tutorInfo));
        setSubjectTest(result);
        setLoading(false);
      });
    } else {
      getSubjectTests(props.user.id).then((result) => {
        props.dispatch(setSubjectTests(result.subjectTests, !!props.tutorInfo));
        setSubjectTest(result.subjectTests);
        setLoading(false);
      });
    }
  }, []);

  return (
    <>
      <View
        style={[
          StyleUtils.color_bg_primary,
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            position: "relative",
          },
        ]}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_22,
            { position: "absolute", zIndex: 1, color: COLOR_WHITE, top: 80 },
          ]}
        >
          Simulados
        </Text>
        <TouchableOpacity
          style={{
            position: "absolute",
            zIndex: 1,
            flex: 1,
            left: 20,
            top: 36,
          }}
          onPress={() => {
            if (
              props.location &&
              props.location.state &&
              props.location.state.fromHome
            ) {
              props.history.push("/home");
            } else if (props.talkspaceOnboading) {
              props.history.push("/home");
            } else {
              props.history.push("/exercises");
            }
          }}
        >
          <SVGImage source={<BackButtonWhite />}></SVGImage>
        </TouchableOpacity>
        <SVGImage source={<SubjectTestListHeader />}></SVGImage>
      </View>
      <View
        style={[
          !props.mobile && {
            alignItems: "center",
          },
          { flex: 1 },
        ]}
      >
        {!loading && (
          <FlatList
            style={{
              flex: 1,
              width: "100%",
              maxWidth: 564,
              marginVertical: 8,
            }}
            data={subjectTests}
            renderItem={(item) => (
              <SubjectTestCard
                subjectTest={item.item}
                isTutor={!!props.tutorInfo}
                mobile={props.mobile}
                onPress={() => {
                  if (item.item.id) {
                    getSubjectTest(item.item.id).then((subjectTest) => {
                      props.selectSubjectTest(subjectTest);
                      if (props.tutorInfo) {
                        props.history.push("/subjecttests/review");
                      } else {
                        if (subjectTest.firstTimeOpen) {
                          props.history.push("/subjecttests/start");
                        } else {
                          props.history.push("/subjecttests/exercise");
                        }
                      }
                    });
                  }
                }}
              />
            )}
          />
        )}
      </View>
      {!props.talkspaceOnboading && (
        <SafeAreaView>
          <Footer />
        </SafeAreaView>
      )}
    </>
  );
}

export default connect(stateToProps, dispatchToProps)(SubjectTestList);
