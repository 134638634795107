import React, { useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";
import * as Animatable from "react-native-animatable";
import { connect, useSelector } from "react-redux";
import TitleWithNavigation from "../Elements/TitleWithNavigation";
import {
  creditCardOrderPayment,
  getCardFromVault,
  oAuthGetNet,
} from "../Server";
import { CreditCardManager } from "./CreditCardManager";
import PaymentSeals from "./PaymentSeals";
import ProcessPayment from "./ProcessPayment";
import { redeemTicket, generateOrder } from "../Server";
import CreditCardDeletePopup from "./CreditCardDeletePopup";
import { analytics } from "../Analytics";
import { uuidv4 } from "../Utils/Utils";

type Props = {
  user;
  history;
  price;
  location;
  selectedCard?: string;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

export function OrderPayment(props: Props) {
  const [selectedCard, setSelectedCard] = useState("");
  const [readyToPay, setReadyToPay] = useState(false);
  const [processPayment, setProcessPayment] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [cardToRemovePopup, setCardToRemovePopup] = useState(false);
  const { price, label, id } = props.location.state.price;

  const card = useSelector((state) => state.paymentRed);

  useEffect(() => {
    card.cardToRemove.length
      ? setCardToRemovePopup(true)
      : setCardToRemovePopup(false);
  });

  useEffect(() => {
    if (selectedCard !== undefined && selectedCard !== "") {
      console.log("card selected");
      setReadyToPay(true);
    } else {
      setReadyToPay(false);
    }
  });

  useEffect(() => {
    console.log(`processPayment ---> ${processPayment}`);
    console.log(`ready to pay --->${readyToPay}`);
  }, [processPayment]);

  const charge = async () => {
    let planPrice = price.replace(/[R$.,]/g, "");
    const oAuth = await oAuthGetNet()
      .then((res) => {
        return res.access_token;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (!oAuth) {
      // analytics.sendOrderPaymentFailed(planPrice)
      setPaymentSuccess(false);
    }

    const cardVault = await getCardFromVault(selectedCard, oAuth)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => {
        console.log("invalid card from vault");
        return false;
      });

    if (!cardVault) {
      // analytics.sendOrderPaymentFailed(planPrice)
      setPaymentSuccess(false);
    }

    const orderId = uuidv4();
    const sendOrderGateWay = await creditCardOrderPayment(
      planPrice,
      orderId,
      cardVault.customer_id,
      cardVault.number_token,
      cardVault.cardholder_name,
      cardVault.expiration_month.toString(),
      cardVault.expiration_year.toString(),
      oAuth
    )
      .then((res) => {
        console.log("sendOrder Success");
        // console.log(res)
        return res;
      })
      .catch((err) => {
        console.log("sendOrder Error");
        return false;
      });

    if (sendOrderGateWay) {
      console.log("sendOrderGateway True");
      const { payment_id, status } = sendOrderGateWay;
      if (status === "APPROVED") {
        console.log("orderGateway approved");
        generateOrder(cardVault.customer_id, id, payment_id, orderId)
          .then((result) => {
            console.log("generateOrderSuccess");
            if (!result.error) {
              console.log("generateOrder no errors");
              redeemTicket(id, props.user.id).then((result) => {
                setPaymentComplete(true);
                setPaymentSuccess(true);
              });
            }
          })
          .catch((err) => {
            console.log("generateOrder error");
          });
      } else {
        console.log("order not approved");
        analytics.sendOrderPaymentFailed(planPrice);
        setPaymentComplete(true);
        setPaymentSuccess(false);
      }
    }
  };
  return (
    <>
      <TitleWithNavigation
        title={"Realizar Pagamento"}
        onGoBackPress={() => {
          analytics.sendOrderPaymentBack();
          props.history.goBack();
        }}
        options={{ colorStyle: "white" }}
        help
      />
      <View style={{ flex: 1 }}>
        <ScrollView style={style.container}>
          <CreditCardManager
            user={props.user}
            location={props.location}
            history={props.history}
            cardToPay={(selectedCard) => setSelectedCard(selectedCard)}
            isSelected={true}
          />
        </ScrollView>
        <PaymentSeals />
        <Animatable.View animation="fadeInUp" duration={500}>
          <View style={style.blockOrder}>
            <TouchableOpacity
              style={[
                style.btnOrder,
                readyToPay ? style.btnOrderReady : style.btnOrderNotReady,
              ]}
              onPress={() => {
                setProcessPayment(true);
                charge();
              }}
              disabled={!readyToPay || processPayment}
            >
              {processPayment ? (
                <ActivityIndicator color="#fff" />
              ) : (
                <Text style={style.btnText}>Pagar {price}</Text>
              )}
            </TouchableOpacity>
          </View>
        </Animatable.View>
      </View>
      {processPayment ? (
        <ProcessPayment
          processPayment={processPayment}
          paymentComplete={paymentComplete}
          paymentSuccess={paymentSuccess}
          history={props.history}
          credits={label}
          changeProcess={(processPayment) => setProcessPayment(processPayment)}
          tryAgain={(paymentComplete) => setPaymentComplete(paymentComplete)}
        />
      ) : (
        <CreditCardDeletePopup
          visible={cardToRemovePopup}
          lastDigits={card.cardLastDigits}
        />
      )}
    </>
  );
}

const style = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingTop: 20,
  },
  topBar: {
    borderBottomColor: "#ccc",
    padding: 25,
    borderBottomWidth: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  title: {
    fontSize: 16,
    fontWeight: "700",
  },
  blockOrder: {
    padding: 25,
    borderTopColor: "#eee",
    borderTopWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  btnOrder: {
    paddingTop: 15,
    paddingBottom: 15,
    width: "100%",
    alignItems: "center",
    borderRadius: 10,
  },
  btnOrderReady: {
    backgroundColor: "rgba(19, 206, 116, 1)",
  },
  btnOrderNotReady: {
    backgroundColor: "#ccc",
  },
  btnText: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "600",
  },
});

export default connect(stateToProps)(OrderPayment);
