import React from "react";
import { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import StyleUtils, { COLOR_BG_LIGHT } from "../Utils/StyleUtils";
import { SCREEN_SIZE_600 } from "../Utils/SizeUtils";
import { getTutees, getTutors } from "../Server";
import MessengerUserList from "../Messenger/MessengerUserList";
import User from "../Models/User";

type Props = {
  mobile?: boolean;
  history;
  user?;
  tutorInfo?;
  onUserSelected?: (user: User) => void;
  selectedUser?: User;
  onPress?: () => void;
  children?: any;
};

type State = {
  selectedUser?: User;
  dataSource: any;
  compactLayout: boolean;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
  };
}

class AssignHomeworkUsers extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      compactLayout: true,
      dataSource: [],
    };
  }

  componentDidMount() {
    this.fetchUserList()
      .then((users: any) => {
        for (const user in users) {
          if (users[user] === null || users[user] === undefined) {
            delete users[user];
          }
        }
        this.setState({
          dataSource: users.reduce((acc, it) => acc.concat(it), []),
        });
      })
      .catch((err) => {
        console.log(`Error: Could not fetch users - ${err}`);
      });
  }

  fetchUserList = () => {
    if (this.props.tutorInfo) {
      return getTutees(this.props.user.id, this.props.user.id);
    } else {
      return getTutors(this.props.user.id);
    }
  };

  userSelected = (pressedUser) => {
    if (this.state.selectedUser !== pressedUser) {
      this.setState({
        selectedUser: pressedUser,
      });
    }
  };

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let isMobileLayout = layout.width < SCREEN_SIZE_600;
    if (isMobileLayout != this.state.compactLayout) {
      this.setState({
        compactLayout: isMobileLayout,
      });
    }
  };

  render() {
    console.log("rendering");
    return this.props.mobile
      ? this.renderMobileLayout()
      : this.renderDesktopLayout();
  }

  renderDesktopLayout() {
    return (
      <View
        style={[
          {
            borderRadius: 14,
            width: "90%",
            alignSelf: "center",
            alignContent: "center",
            overflow: "hidden",
            flex: 1,
            flexDirection: "row",
            marginVertical: 24,
            marginHorizontal: 32,
          },
        ]}
      >
        <MessengerUserList
          style={{
            width: 300,
            borderColor: COLOR_BG_LIGHT,
          }}
          dataSource={this.state.dataSource}
          selectedUser={this.props.selectedUser}
          onUserSelected={(item) => {
            this.props.onUserSelected && this.props.onUserSelected(item);
          }}
          headerText={"Enviar para..."}
          headerStyle={{ height: 60, justifyContent: "flex-end" }}
          history={this.props.history}
        ></MessengerUserList>
        <View
          style={[
            StyleUtils.color_bg_overlay,
            {
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: COLOR_BG_LIGHT,
            },
          ]}
        >
          {this.props.selectedUser && this.props.children}
        </View>
      </View>
    );
  }

  renderMobileLayout() {
    return !this.props.selectedUser ? (
      <MessengerUserList
        dataSource={this.state.dataSource}
        selectedUser={this.props.selectedUser}
        onUserSelected={(item) => {
          this.props.onUserSelected && this.props.onUserSelected(item);
        }}
        headerText={"Enviar para..."}
        history={this.props.history}
      />
    ) : (
      this.props.children
    );
  }
}

const style = StyleSheet.create({
  half: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-around",
    flexGrow: 0,
    alignItems: "center",
    alignSelf: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  full: {},
});

export default connect(mapStateToProps, null)(AssignHomeworkUsers);
