import React from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Platform,
  Clipboard,
} from "react-native";
import Tutore from "../../assets/tutore.svg";
import SVGImage from "../Utils/SVGImage";
import { analytics } from "../Analytics";
import ButtonElement from "../Elements/ButtonElement";
import Celebration from "../../assets/celebration.svg";
import FreeCreditCoupon from "../../assets/freeCreditCoupon.svg";
import StyleUtils, {
  COLOR_TXT_LIGHT,
  COLOR_TXT_NORMAL,
} from "../Utils/StyleUtils";
import CopyIcon from "../../assets/copyIcon.svg";
import Timer from "../Elements/Timer";
import ClockBlack from "../../assets/clockBlack.svg";

type Props = {
  onContinue;
  history;
  location;
};

const EndForm = ({ onContinue, history, location }: Props) => {
  return (
    <>
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            alignItems: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <SVGImage source={<Tutore />}></SVGImage>
        </View>
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              paddingTop: 100,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <SVGImage
              style={{ position: "absolute", left: -80, overflow: "hidden" }}
              source={<Celebration />}
            />
            <View
              style={[
                {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_28,
                  {
                    textAlign: "center",
                  },
                ]}
              >
                {"Obrigado\npor responder!"}
              </Text>
            </View>
          </View>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_16,

              {
                color: COLOR_TXT_LIGHT,
                textAlign: "center",
                marginTop: 8,
                marginBottom: 26,
              },
            ]}
          >
            Use o cupom nos próximos 15 minutos,
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,

                {
                  color: COLOR_TXT_NORMAL,
                  textAlign: "center",
                },
              ]}
            >
              {"\ne ganhe 100% de desconto\nna primeira aula."}
            </Text>
          </Text>
          <View>
            <SVGImage source={<FreeCreditCoupon />} />
            <TouchableOpacity
              style={{
                position: "absolute",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                top: 40,
              }}
              onPress={() => {
                analytics.sendCopiedFreeCoupon();
                Clipboard.setString("BEMVINDO");
                alert("Cupom copiado com sucesso!");
              }}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_22,
                  { left: 8, top: Platform.OS === "ios" ? 2 : 0 },
                ]}
              >
                BEMVINDO
              </Text>
              <SVGImage
                source={<CopyIcon />}
                style={{
                  left: 18,
                  top: Platform.OS === "ios" ? 2 : 0,
                }}
              />
            </TouchableOpacity>
          </View>

          <Timer
            style={[
              StyleUtils.shape_card_default,
              {
                paddingRight: 16,
                paddingLeft: 8,
                borderColor: COLOR_TXT_LIGHT,
                borderWidth: 1,
                marginVertical: 8,
              },
            ]}
            showRestantes
            initialTime={15 * 60}
            ClockIcon={ClockBlack}
          />
          <ButtonElement
            colorScheme={"primary"}
            text={"Agendar aula grátis"}
            styte={[
              StyleUtils.shape_btn_default,
              { marginBottom: 60, width: "85%" },
              ,
            ]}
            textStyle={[
              { marginHorizontal: 10, paddingHorizontal: 10 },
              StyleUtils.font_size_18,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
            ]}
            onPress={() => {
              analytics.sendEndSurvey();
              if (location && location.state && location.state.onboarding) {
                if (location && location.state && location.state.isGuardian) {
                  history.push({
                    pathname: "/landing/subjects",
                    state: {
                      isGuardian: true,
                    },
                  });
                } else {
                  history.push({
                    pathname: "/landing/subjects",
                    state: {
                      fromStudentSurvey: true,
                    },
                  });
                }
              } else {
                history.push({
                  pathname: "/landing/subjects",
                  state: {
                    showFooter: true,
                  },
                });
              }
            }}
          />
        </View>
      </View>
    </>
  );
};

export default EndForm;
