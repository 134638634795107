import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE, COLOR_MUTED } from "../Utils/StyleUtils";
import BoyLaptop from "../../assets/boyLaptop.svg";
import StudyPlanPrice from "../../assets/studyPlanPrice.svg";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import PersonBookIcon from "../../assets/personBookIcon.svg";
import WebIcon from "../../assets/webIcon.svg";
import DiplomaIcon from "../../assets/diplomaIcon.svg";
import WebChatIcon from "../../assets/webChatIcon.svg";
import { UserImage } from "../Elements/UserImages";
import { userShortName } from "../Utils/Utils";
import StarYellow from "../../assets/starYellow.svg";
import StudyPlanPriceMobile from "../../assets/studyPlanPriceMobile.svg";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  selectedTutors;
  subjects;
  numClasses;
  index;
};

const divider = () => (
  <View
    style={{
      borderBottomColor: COLOR_MUTED,
      borderBottomWidth: 1,
      width: "60%",
      justifyContent: "center",
      marginHorizontal: 24,
      marginVertical: 40,
    }}
  />
);

export default function StudyPlanTutors(props: Props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );
  const [costPerClass, setCostPerClass] = useState("");
  const [totalCost, setTotalCost] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    switch (props.numClasses) {
      case 1:
        setCostPerClass("R$ 70,00");
        setTotalCost("R$ 70,00");
        break;
      case 2:
        setCostPerClass("R$ 68,00");
        setTotalCost("R$ 136,00");
        break;
      case 3:
        setCostPerClass("R$ 65,00");
        setTotalCost("R$ 195,00");
        break;
      case 4:
        setCostPerClass("R$ 63,00");
        setTotalCost("R$ 252,00");
        break;
      case 5:
        setCostPerClass("R$ 60,00");
        setTotalCost("R$ 300,00");
        break;
      case 6:
        setCostPerClass("R$ 58,00");
        setTotalCost("R$ 348,00");
        break;
      case 7:
        setCostPerClass("R$ 56,00");
        setTotalCost("R$ 392,00");
        break;
      case 8:
        setCostPerClass("R$ 55,00");
        setTotalCost("R$ 440,00");
        break;
    }
    Dimensions.addEventListener("change", _handleAppStateChange);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  const renderMobileLayout = () => {
    return (
      <View style={[StyleUtils.screen_default]}>
        <LinearGradient
          colors={["#011F42", "#016EE8"]}
          style={[
            {
              paddingVertical: 100,
            },
          ]}
        />
        <View
          style={{
            width: windowWidth,
            height: windowWidth,
            borderRadius: windowWidth / 2,
            backgroundColor: COLOR_TRUE_WHITE,
            transform: [{ scaleX: 2 }],
            marginTop: 80,
            position: "absolute",
          }}
        />
        <SVGImage
          source={<BoyLaptop />}
          style={{
            alignSelf: "center",
            zIndex: 1,
            width: 149,
            height: 146,
            position: "absolute",
            top: 100,
          }}
        />
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              {
                textAlign: "center",
                fontSize: 24,
                marginTop: 50,
                marginBottom: 20,
                lineHeight: 28,
              },
            ]}
          >
            Professores recomendados
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "center",
                fontSize: 16,
                lineHeight: 28,
              },
            ]}
          >
            de acordo com o perfil, necessidades e disponibilidade de horário do
            estudante
          </Text>
          <View style={{ marginVertical: 40 }}>
            {props.selectedTutors.map((area, index) =>
              renderTutorMobile(area, index)
            )}
          </View>
          {divider()}

          <View style={{ marginHorizontal: 20 }}>
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 16,
                  marginBottom: 24,
                },
              ]}
            >
              Só lembrando que todas as nossas aulas são:
            </Text>
            <View style={{ flexDirection: "row", marginBottom: 20 }}>
              <SVGImage
                source={<PersonBookIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 10,
                  },
                ]}
              >
                Individuais e personalizadas
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <SVGImage
                source={<WebIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 10,
                  },
                ]}
              >
                100% online em plataforma própria{"\n"}para aulas, com duração
                de 1 hora
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <SVGImage
                source={<DiplomaIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 10,
                  },
                ]}
              >
                Com professores selecionados{"\n"}das melhores faculdades
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <SVGImage
                source={<WebChatIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 10,
                  },
                ]}
              >
                Acesso 24 horas aos professores {"\n"}através de chat
              </Text>
            </View>
          </View>
          {divider()}
          <View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                {
                  textAlign: "left",
                  fontSize: 24,
                  marginBottom: 20,
                  marginLeft: 20,
                },
              ]}
            >
              Investimento recomendado
            </Text>
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 16,
                  textAlign: "left",
                  marginHorizontal: 20,
                },
              ]}
            >
              De acordo com as necessidades, recomendamos que faça a seguinte
              quantidade de aulas por mês:
            </Text>
            <View>
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_normal,
                  {
                    textAlign: "center",
                    fontSize: 19,
                    position: "absolute",
                    alignSelf: "center",
                    top: 100,
                  },
                ]}
              >
                {props.numClasses} {props.numClasses === 1 ? "aula" : "aulas"}{" "}
                por mês
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  position: "absolute",
                  top: 190,
                  alignSelf: "center",
                }}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_primary,
                    {
                      textAlign: "center",
                      fontSize: 24,
                    },
                  ]}
                >
                  {costPerClass}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 24,
                    },
                  ]}
                >
                  /aula
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  position: "absolute",
                  top: 225,
                }}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 19,
                    },
                  ]}
                >
                  Total:
                </Text>
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 19,
                    },
                  ]}
                >
                  {" "}
                  {totalCost}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 19,
                    },
                  ]}
                >
                  /mês
                </Text>
              </View>

              <SVGImage
                source={<StudyPlanPriceMobile />}
                style={{
                  alignSelf: "center",
                }}
              />
            </View>

            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 14,
                  alignSelf: "center",
                  textAlign: "center",
                },
              ]}
            >
              Esta é apenas uma recomendação, você pode personalizar sua
              assinatura da forma que preferir.
            </Text>
          </View>
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.color_bg_primary,
              { marginTop: 28, width: 264, marginBottom: 100 },
            ]}
            onPress={() => {
              analytics.sendStudyPlanScheduleClass(props.index);
              props.onContinue();
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
                { padding: 14, textAlign: "center" },
              ]}
            >
              Marcar primeira aula
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const renderDesktopLayout = () => {
    return (
      <View style={[StyleUtils.screen_default]}>
        <LinearGradient
          colors={["#011F42", "#016EE8"]}
          style={[
            {
              paddingVertical: 250,
            },
          ]}
        />
        <View
          style={{
            width: windowWidth,
            height: windowWidth,
            borderRadius: windowWidth / 2,
            backgroundColor: COLOR_TRUE_WHITE,
            transform: [{ scaleX: 2 }],
            marginTop: 80,
            position: "absolute",
          }}
        />
        <SVGImage
          source={<BoyLaptop />}
          style={{
            alignSelf: "center",
            zIndex: 1,
            width: 301,
            height: 296,
            position: "absolute",
            top: 100,
          }}
        />
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              {
                textAlign: "center",
                fontSize: 24,
                marginTop: -100,
                marginBottom: 20,
                lineHeight: 28,
              },
            ]}
          >
            Professores recomendados
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "center",
                fontSize: 16,
                lineHeight: 28,
              },
            ]}
          >
            de acordo com o perfil, necessidades e disponibilidade de horário do
            estudante
          </Text>
          <View style={{ marginVertical: 40 }}>
            {props.selectedTutors.map((area, index) =>
              renderTutor(area, index)
            )}
          </View>
          {divider()}

          <View>
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 16,
                  marginBottom: 24,
                },
              ]}
            >
              Só lembrando que todas as nossas aulas são:
            </Text>
            <View style={{ flexDirection: "row", marginBottom: 20 }}>
              <SVGImage
                source={<PersonBookIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 16,
                  },
                ]}
              >
                Individuais e personalizadas
              </Text>
              <SVGImage
                source={<WebIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                  marginLeft: 100,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 16,
                  },
                ]}
              >
                100% online em plataforma própria{"\n"}para aulas, com duração
                de 1 hora
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <SVGImage
                source={<DiplomaIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 16,
                  },
                ]}
              >
                Com professores selecionados{"\n"}das melhores faculdades
              </Text>
              <SVGImage
                source={<WebChatIcon />}
                style={{
                  alignSelf: "center",
                  width: 51,
                  height: 51,
                  marginLeft: 83,
                }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    fontSize: 16,
                    alignSelf: "center",
                    marginLeft: 16,
                  },
                ]}
              >
                Acesso 24 horas aos professores {"\n"}através de chat
              </Text>
            </View>
          </View>
          {divider()}
          <View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                {
                  textAlign: "left",
                  fontSize: 24,
                  marginBottom: 20,
                  lineHeight: 28,
                },
              ]}
            >
              Investimento recomendado
            </Text>
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 16,
                  textAlign: "left",
                },
              ]}
            >
              De acordo com as necessidades, recomendamos que faça a seguinte{" "}
              {"\n"}
              quantidade de aulas por mês:
            </Text>
            <View>
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_normal,
                  {
                    textAlign: "center",
                    fontSize: 19,
                    position: "absolute",
                    left: 160,
                    top: 135,
                  },
                ]}
              >
                {props.numClasses} {props.numClasses === 1 ? "aula" : "aulas"}{" "}
                por mês
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  position: "absolute",
                  left: 368,
                  top: 100,
                }}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_primary,
                    {
                      textAlign: "center",
                      fontSize: 24,
                    },
                  ]}
                >
                  {costPerClass}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 24,
                    },
                  ]}
                >
                  /aula
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  position: "absolute",
                  left: 368,
                  top: 135,
                }}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 19,
                    },
                  ]}
                >
                  Total:
                </Text>
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 19,
                    },
                  ]}
                >
                  {" "}
                  {totalCost}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_normal,
                    {
                      textAlign: "center",
                      fontSize: 19,
                    },
                  ]}
                >
                  /mês
                </Text>
              </View>

              <SVGImage
                source={<StudyPlanPrice />}
                style={{
                  alignSelf: "center",
                  marginLeft: 83,
                }}
              />
            </View>

            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  fontSize: 16,
                  alignSelf: "center",
                  textAlign: "left",
                },
              ]}
            >
              Esta é apenas uma recomendação, você pode personalizar sua
              assinatura da forma que preferir.
            </Text>
          </View>
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.color_bg_primary,
              { marginTop: 28, width: 264, marginBottom: 100 },
            ]}
            onPress={() => {
              props.onContinue();
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
                { padding: 14, textAlign: "center" },
              ]}
            >
              Marcar primeira aula
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const renderTutor = (area, index) => {
    return (
      <>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            {
              fontSize: 20,
              marginBottom: 20,
            },
          ]}
        >
          • {props.subjects[index] ? props.subjects[index] : "Matemática"}
        </Text>
        <View
          style={{
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.2,
            shadowRadius: 6,
            elevation: 5,
            borderRadius: 6,
            marginBottom: 40,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginBottom: 20,
              backgroundColor: COLOR_TRUE_WHITE,
            }}
          >
            <UserImage
              style={{
                marginLeft: 40,
                marginRight: 16,
                marginBottom: 33,
                width: 102,
                height: 132,
                marginTop: 40,
                borderRadius: 4,
              }}
              imageURL={area.tutors[0].imageURL}
            />
            <View style={{ flex: 1 }}>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_18,
                  {
                    paddingTop: 4,
                    color: "#656B7F",
                    marginTop: 40,
                  },
                ]}
              >
                {userShortName(area.tutors[0].name)}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <>
                  <SVGImage source={<StarYellow />} />
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_bold,

                      {
                        paddingHorizontal: 4,
                        color: "#656B7F",
                      },
                    ]}
                  >
                    {area.tutors[0].rating &&
                      area.tutors[0].rating.average.toFixed(1)}
                  </Text>

                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                      {
                        color: "#656B7F",
                      },
                    ]}
                  >
                    •{" "}
                    {`${
                      area.tutors[0].rating && area.tutors[0].rating.count
                    } Avaliações`}
                  </Text>
                </>

                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_regular,
                    {
                      color: "#656B7F",
                    },
                  ]}
                >
                  {" "}
                  • {`${area.tutors[0].rating.eventsCompleted} `}
                  {area.tutors[0].rating.eventsCompleted === 1
                    ? "Aula"
                    : "Aulas"}
                </Text>
              </View>
              <Text
                style={[
                  StyleUtils.font_size_16,
                  StyleUtils.font_cera_regular,
                  {
                    color: "#656B7F",
                    width: 542,
                    marginRight: 24,
                    marginTop: 12,
                  },
                ]}
              >
                {area.tutors[0].tutorInfo.shortDescription}
              </Text>
            </View>
          </View>
          <View style={{ marginHorizontal: 40 }}>
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                width: "100%",
                alignSelf: "center",
                marginHorizontal: 24,
              }}
            />
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 40,
                },
              ]}
            >
              Matérias
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  width: 542,
                  marginRight: 24,
                  marginTop: 4,
                },
              ]}
            >
              {area.tutors[0].tutorInfo &&
                area.tutors[0].tutorInfo.subjects &&
                area.tutors[0].tutorInfo.subjects
                  .map((level) => level.name)
                  .join(", ")}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  width: 542,
                  marginRight: 24,
                  marginTop: 12,
                },
              ]}
            >
              Especialidades
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 4,
                },
              ]}
            >
              {area.tutors[0].tutorInfo.specialties}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 12,
                },
              ]}
            >
              Nível das aulas
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  width: 542,
                  marginRight: 24,
                  marginTop: 4,
                  marginBottom: 40,
                },
              ]}
            >
              {area.tutors[0].tutorInfo &&
                area.tutors[0].tutorInfo.subjectLevels &&
                area.tutors[0].tutorInfo.subjectLevels
                  .map((level) => level.name)
                  .join(", ")}
            </Text>
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                width: "100%",
                alignSelf: "center",
                marginHorizontal: 24,
              }}
            />
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  width: 542,
                  marginRight: 24,
                  marginTop: 24,
                },
              ]}
            >
              Sobre
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  width: 542,
                  marginRight: 24,
                  marginTop: 12,
                  marginBottom: 40,
                },
              ]}
            >
              {area.tutors[0].tutorInfo.longDescription}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const renderTutorMobile = (area, index) => {
    return (
      <>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            {
              fontSize: 20,
              marginBottom: 20,
            },
          ]}
        >
          • {props.subjects[index] ? props.subjects[index] : "Matemática"}
        </Text>
        <View
          style={{
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.2,
            shadowRadius: 6,
            elevation: 5,
            borderRadius: 6,
            marginBottom: 40,
            width: 312,
          }}
        >
          <View
            style={{
              backgroundColor: COLOR_TRUE_WHITE,
              flexDirection: "row",
            }}
          >
            <UserImage
              style={{
                marginLeft: 20,
                marginRight: 16,
                marginBottom: 20,
                width: 46,
                height: 54,
                marginTop: 20,
                borderRadius: 4,
              }}
              imageURL={area.tutors[0].imageURL}
            />
            <View>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_16,
                  {
                    paddingTop: 4,
                    color: "#656B7F",
                    marginTop: 20,
                  },
                ]}
              >
                {userShortName(area.tutors[0].name)}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <SVGImage source={<StarYellow />} />
                <Text
                  style={[
                    StyleUtils.font_size_14,
                    StyleUtils.font_cera_bold,

                    {
                      paddingHorizontal: 4,
                      color: "#656B7F",
                    },
                  ]}
                >
                  {area.tutors[0].rating &&
                    area.tutors[0].rating.average.toFixed(1)}
                </Text>

                <Text
                  style={[
                    StyleUtils.font_size_14,
                    StyleUtils.font_cera_regular,
                    {
                      color: "#656B7F",
                      width: "100%",
                    },
                  ]}
                >
                  •{" "}
                  {`${
                    area.tutors[0].rating && area.tutors[0].rating.count
                  } Avaliações`}
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.font_cera_regular,
              {
                color: "#656B7F",
                marginRight: 24,
                marginHorizontal: 20,
                marginBottom: 20,
              },
            ]}
          >
            {area.tutors[0].tutorInfo.shortDescription}
          </Text>
          <View style={{ marginHorizontal: 20 }}>
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                width: "100%",
                alignSelf: "center",
                marginHorizontal: 24,
              }}
            />
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 40,
                },
              ]}
            >
              Matérias
            </Text>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 4,
                },
              ]}
            >
              {area.tutors[0].tutorInfo &&
                area.tutors[0].tutorInfo.subjects &&
                area.tutors[0].tutorInfo.subjects
                  .map((level) => level.name)
                  .join(", ")}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  width: 542,
                  marginRight: 24,
                  marginTop: 12,
                },
              ]}
            >
              Especialidades
            </Text>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 4,
                },
              ]}
            >
              {area.tutors[0].tutorInfo.specialties}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 12,
                },
              ]}
            >
              Nível das aulas
            </Text>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 4,
                  marginBottom: 40,
                },
              ]}
            >
              {area.tutors[0].tutorInfo &&
                area.tutors[0].tutorInfo.subjectLevels &&
                area.tutors[0].tutorInfo.subjectLevels
                  .map((level) => level.name)
                  .join(", ")}
            </Text>
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                width: "100%",
                alignSelf: "center",
                marginHorizontal: 24,
              }}
            />
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_bold,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 24,
                },
              ]}
            >
              Sobre
            </Text>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_regular,
                {
                  color: "#656B7F",
                  marginRight: 24,
                  marginTop: 12,
                  marginBottom: 40,
                },
              ]}
            >
              {area.tutors[0].tutorInfo.longDescription}
            </Text>
          </View>
        </View>
      </>
    );
  };

  return windowWidth < 500 ? renderMobileLayout() : renderDesktopLayout();
}
