import React, { Component } from "react";
import {
  Dimensions,
  FlatList,
  Modal,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import ArrowDownBlack from "../../assets/arrowDownBlack.svg";
import ArrowUpBlack from "../../assets/arrowUpBlack.svg";
import IconSearchBlack from "../../assets/iconSearchBlack.svg";
import { setSearchFilter } from "../Actions";
import ButtonElement from "../Elements/ButtonElement";
import {
  defaultSubjectLevels,
  defaultSubjects,
  Subject,
  SubjectLevel,
} from "../Models/Subjects";
import { getMainSubjectLevels, getMainSubjects } from "../Server";
import StyleUtils, { COLOR_BG_LIGHT } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";

type Props = {
  filter;
  currentSubject;
  currentLevel;
  setSearchFilter;
  onFilterChanged?;
  onSearchBarPressed?;
  onModalExit?;
};

const mapStateToProps = (state) => ({
  currentSubject: state.searchFilterRed.subject,
  currentLevel: state.searchFilterRed.level,
});

function mapDispatchToProps(dispatch) {
  return {
    setSearchFilter: (data) => dispatch(setSearchFilter(data)),
  };
}

type State = {
  subjects: Subject[];
  levels: SubjectLevel[];

  selectedSubject;
  selectedLevel;
  isFilterSubjectSelected: boolean;
  isFilterLevelSelected: boolean;
  isSearchSelected: boolean;
  isSubjectSelected: boolean;
  isLevelSelected: boolean;
};

class SearchLevelSubjectBar extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      levels: [],
      subjects: [],
      selectedSubject: props.filter && props.filter.subject,
      selectedLevel: props.filter && props.filter.level,
      isFilterSubjectSelected: false,
      isFilterLevelSelected: false,
      isSearchSelected: props.onSearchBarPressed
        ? props.onSearchBarPressed
        : false,
      isSubjectSelected: false,
      isLevelSelected: false,
    };
  }

  componentDidMount() {
    getMainSubjects(
      (subjects) => {
        this.setState({ subjects: subjects });
      },
      (error) => {
        this.setState({ subjects: defaultSubjects });
      }
    );

    getMainSubjectLevels(
      (levels) => {
        var filtered = levels.filter(function (value, index, arr) {
          return value.index != 4;
        });
        this.setState({ levels: filtered });
      },
      (error) => {
        this.setState({ levels: defaultSubjectLevels });
      }
    );
  }

  onSelectSubjectFilter() {
    if (
      !this.state.isFilterSubjectSelected &&
      this.state.isFilterLevelSelected
    ) {
      this.setState((prev) => ({
        isFilterLevelSelected: !prev.isFilterLevelSelected,
        isFilterSubjectSelected: !prev.isFilterSubjectSelected,
      }));
    } else {
      this.setState((prev) => ({
        isFilterSubjectSelected: !prev.isFilterSubjectSelected,
      }));
    }
  }

  onSelectFilterLevel() {
    if (
      this.state.isFilterSubjectSelected &&
      !this.state.isFilterLevelSelected
    ) {
      this.setState((prev) => ({
        isFilterSubjectSelected: !prev.isFilterSubjectSelected,
        isFilterLevelSelected: !prev.isFilterLevelSelected,
      }));
    } else {
      this.setState((prev) => ({
        isFilterLevelSelected: !prev.isFilterLevelSelected,
      }));
    }
  }

  onSelectLevel(level) {
    this.setState({
      selectedLevel: level,
      isLevelSelected: true,
      isFilterLevelSelected: false,
    });
  }

  onSelectSubject(subject) {
    this.setState({
      selectedSubject: subject,
      isSubjectSelected: true,
      isFilterSubjectSelected: false,
    });
  }

  setModalVisible = () => {
    if (
      this.state.isSearchSelected &&
      (this.state.selectedSubject !== this.props.currentSubject ||
        this.state.selectedLevel !== this.props.currentLevel)
    ) {
      this.updateFilter();
    }
    if (this.state.isSearchSelected) {
      this.setState({
        isSearchSelected: false,
        isSubjectSelected: false,
        isLevelSelected: false,
      });
    } else {
      this.setState({
        isSearchSelected: true,
      });
    }
    if (this.props.onModalExit) {
      this.props.onModalExit();
    }
  };

  updateFilter() {
    let filterLevel = this.props.filter.level;
    let filterSubject = this.props.filter.subject;

    if (this.state.selectedLevel) {
      filterLevel = this.state.selectedLevel;
    }

    if (this.state.selectedSubject) {
      filterSubject = this.state.selectedSubject;
    }

    const filter = {
      level: filterLevel,
      subject: filterSubject,
    };

    this.props.setSearchFilter(filter);
    this.props.onFilterChanged && this.props.onFilterChanged(filter);
  }

  render() {
    return (
      <View>
        {!this.state.isSearchSelected && (
          <View style={[styles.container]}>
            <View
              style={{
                flex: 1,
                alignItems: "stretch",
              }}
            >
              <SearchBarComponent
                onPress={() => this.setModalVisible()}
                subject={this.state.selectedSubject}
                level={this.state.selectedLevel}
              />
            </View>
          </View>
        )}
        {this.state.isSearchSelected && (
          <Modal
            animationType="fade"
            transparent={true}
            visible={this.state.isSearchSelected}
            onRequestClose={this.setModalVisible}
          >
            <SafeAreaView
              style={[
                styles.centeredView,
                { width: Dimensions.get("window").width },
              ]}
            >
              <TouchableOpacity
                style={[styles.modalView]}
                activeOpacity={1}
                onPressOut={() => this.setModalVisible()}
              >
                <View style={{ width: "100%" }}>
                  <SearchBarComponent
                    onPress={() => this.setModalVisible()}
                    subject={this.state.selectedSubject}
                    level={this.state.selectedLevel}
                  />

                  <View
                    style={[
                      StyleUtils.color_bg_white,
                      {
                        borderRadius: 14,
                        overflow: "hidden",
                        alignItems: "stretch",
                        marginHorizontal: 24,
                      },
                    ]}
                  >
                    <FilterSection
                      sectionName="Matéria"
                      selectedItem={this.state.selectedSubject}
                      items={this.state.subjects}
                      onSelect={(item) => this.onSelectSubject(item)}
                      opened={this.state.isFilterSubjectSelected}
                      onPress={() => this.onSelectSubjectFilter()}
                    />
                    <Separator />
                    <FilterSection
                      sectionName="Nível das Aulas"
                      selectedItem={this.state.selectedLevel}
                      items={this.state.levels}
                      onSelect={(item) => this.onSelectLevel(item)}
                      opened={this.state.isFilterLevelSelected}
                      onPress={() => this.onSelectFilterLevel()}
                    />
                    <Separator />
                    <ButtonElement
                      text="Buscar Professores"
                      colorScheme="primary"
                      styte={{
                        borderRadius: 5,
                        overflow: "hidden",
                        margin: 16,
                      }}
                      disabled={
                        !this.state.isSubjectSelected &&
                        !this.state.isLevelSelected
                      }
                      onPress={() => this.setModalVisible()}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            </SafeAreaView>
          </Modal>
        )}
      </View>
    );
  }
}

function SearchBarComponent(props: {
  onPress?: () => void;
  subject?: any;
  level?: any;
}) {
  const subject = (props.subject && props.subject.name) || "";
  const level = (props.level && props.level.name) || "";
  const text = subject + " - " + level;
  return (
    <TouchableHighlight
      underlayColor={COLOR_BG_LIGHT}
      style={[
        styles.searchInput,
        { backgroundColor: "#FFFFFF", alignSelf: "stretch" },
      ]}
      onPress={props.onPress}
    >
      <>
        <SVGImage source={<IconSearchBlack />} />
        <Text
          style={[styles.defaultText, { margin: 12 }]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {text}
        </Text>
      </>
    </TouchableHighlight>
  );
}

function FilterSection(props: {
  sectionName?: string;
  selectedItem?: any;
  items?: any;
  onSelect?: (item: any) => void;
  opened?: boolean;
  onPress?: () => void;
}) {
  return (
    <View style={{ maxHeight: 400 }}>
      <TouchableHighlight
        underlayColor={COLOR_BG_LIGHT}
        style={[
          StyleUtils.color_bg_overlay,
          {
            height: 75,
            padding: 16,
            maxHeight: 400,
          },
        ]}
        onPress={props.onPress}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignContent: "space-between",
          }}
        >
          <View>
            <Text style={[styles.defaultText, StyleUtils.font_cera_bold]}>
              {props.sectionName}
            </Text>
            <Text style={[styles.defaultText, { marginTop: 4 }]}>
              {getName(props.selectedItem)}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            {props.opened ? (
              <SVGImage source={<ArrowUpBlack />} />
            ) : (
              <SVGImage source={<ArrowDownBlack />} />
            )}
          </View>
        </View>
      </TouchableHighlight>
      {props.opened && <Separator />}
      {props.opened && (
        <FlatList
          data={props.items}
          renderItem={({ item, index }) => (
            <FilterItem
              item={item}
              selected={equals(item, props.selectedItem)}
              onPress={() => {
                props.onSelect && props.onSelect(item);
              }}
            />
          )}
          ItemSeparatorComponent={Separator}
          keyExtractor={(item, index) => item.id || "" + index}
        />
      )}
    </View>
  );
}

function FilterItem(props: {
  item: any;
  onPress?: () => void;
  selected?: boolean;
}) {
  return (
    <TouchableHighlight
      underlayColor={COLOR_BG_LIGHT}
      style={[
        StyleUtils.color_bg_white,
        {
          height: 48,
          padding: 16,
        },
      ]}
      onPress={props.onPress}
    >
      <Text
        style={[
          styles.defaultText,
          props.selected && StyleUtils.color_txt_primary,
        ]}
      >
        {getName(props.item)}
      </Text>
    </TouchableHighlight>
  );
}

function Separator() {
  return <View style={[StyleUtils.border_color_btn_muted, { height: 1 }]} />;
}

const getName = (item?: any) => {
  return item ? item.name : "";
};
const equals = (item1?: any, item2?: any) => {
  if (!!item1 && !!item2) {
    return item1.name === item2.name;
  }
  return false;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignContent: "stretch",
  },
  containerSearchOpened: {
    height: "100%",
    backgroundColor: "rgba(59, 63, 80, 0.85)",
  },
  searchInput: {
    borderRadius: 50,
    height: 40,
    flexDirection: "row",
    paddingHorizontal: 30,
    marginHorizontal: 24,
    marginVertical: 16,
    alignItems: "center",
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  defaultText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },

  centeredView:
    Platform.OS === "web"
      ? {
          flex: 1,
          zIndex: 2,
          alignItems: "center",
          width: "100%",
        }
      : {
          flex: 1,
          zIndex: 2,
          height: "100%",
          backgroundColor: "rgba(52, 52, 52, 0.5)",
          alignItems: "center",
        },
  modalView: {
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowRadius: 5,
    elevation: 5,
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchLevelSubjectBar);
