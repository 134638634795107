import User from "./User";
import Assignment, { assignmentState } from "./Assignment";
import AssignmentState from "./AssignmentState";

interface Homework {
  id?: string;
  createdAt?: string;
  name?: string;
  tutor?: User;
  tutee?: User;
  assignments?: Assignment[];
  answeredAt?: string;
  correctedAt?: string;
  completedAt?: string;
}

export enum HomeworkState {
  SUBMITTED,
  ANSWERED,
  CORRECTED,
  COMPLETED,
}

export interface HomeworkStats {
  total: number;
  answered: number;
  reviewed: number;
  pending: number;
  pendingCorrection: number;
}

export function homeworkState(homework?: Homework): HomeworkState {
  if (!!homework) {
    if (!!homework.completedAt) {
      return HomeworkState.COMPLETED;
    } else if (!!homework.correctedAt) {
      return HomeworkState.CORRECTED;
    } else if (!!homework.answeredAt) {
      return HomeworkState.ANSWERED;
    }
  }
  return HomeworkState.SUBMITTED;
}

export function homeworkStats(homework?: Homework): HomeworkStats {
  let assignments: Assignment[] = (homework && homework.assignments) || [];
  let total = assignments.length;
  let answered = assignments.filter(
    (a) => assignmentState(a) >= AssignmentState.ANSWERED
  ).length;
  let reviewed = assignments.filter(
    (a) => assignmentState(a) >= AssignmentState.REVIEWED
  ).length;
  let pending = total - answered;
  let pendingCorrection = answered - reviewed;
  return {
    total,
    answered,
    reviewed,
    pending,
    pendingCorrection,
  };
}

export default Homework;
