import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Clipboard,
  Share,
} from "react-native";
import { connect } from "react-redux";
import StyleUtils, { COLOR_PRIMARY } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import BackgroundShapesBlue from "../../assets/referralShapes.svg";
import Coupon from "../../assets/coupon.svg";
import CopyIcon from "../../assets/copyIcon.svg";
import { useEffect } from "react";
import { createReferralCoupon, getReferralCoupon } from "../Server/index";
import { analytics } from "../Analytics";

type Props = {
  user;
  history;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function dispatchToProps(dispatch) {
  return {};
}

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

function ClassroomEndReferral(props: Props) {
  const [referralCode, setReferralCode] = useState("");
  const [constructorHasRun, setConstructorHasRun] = useState(false);
  const referralText1 =
    "Oi - Tenho um convite para o Tutore, um app de aulas particulares online e com preços bem acessíveis, para você resolver dúvidas de qualquer matéria. Tem uns professores incríveis, e até orientação profissional se estiver em dúvida do que fazer! Se você usar o meu código na hora de marcar a sua primeira aula, ";
  const referralText2 =
    ", eu e você ganhamos uma aula de graça! Aqui está o link do app: https://tutore-react.web.app/";

  const constructor = () => {
    if (constructorHasRun) return;
    analytics.sendOpenReferralPopup("Review");
    setConstructorHasRun(true);
  };

  constructor();

  useEffect(() => {
    getReferralCoupon(props.user.id).then((res) => {
      if (res.length === 0) {
        if (referralCode === "") {
          createReferralCoupon(props.user.id).then((res) => {
            setReferralCode(res.coupon);
          });
        }
      } else {
        setReferralCode(res[0].couponCode);
      }
    });
  });

  return (
    <View
      style={{
        flex: 1,
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 30,
        paddingTop: 50,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SVGImage
        source={<BackgroundShapesBlue />}
        style={{
          marginTop: 14,
          zIndex: 0,
          position: "absolute",
          width: "100%",
          alignItems: "center",
        }}
      />
      <Text style={styles.modalTextHeader}>Quem ajuda, amigo é!</Text>
      <Text style={styles.modalText}>
        Ganhe aulas grátis para você e seus amigos!
      </Text>
      <View>
        <SVGImage
          source={<Coupon />}
          style={{
            marginVertical: 32,
          }}
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            marginTop: 50,
          }}
          onPress={() => {
            Clipboard.setString(referralCode);
            alert("Cupom copiado com sucesso!");
          }}
        >
          <Text style={[styles.modalTextHeader, { left: 8 }]}>
            {referralCode}
          </Text>
          <SVGImage
            source={<CopyIcon />}
            style={{
              left: 18,
              top: 2,
            }}
          />
        </TouchableOpacity>
      </View>
      <Text style={styles.cancelText}>
        Compartilhe este cupom e, quando seu amigo utilizar, você e ele ganham{" "}
        <B>1 crédito-aula grátis para qualquer sessão</B>
      </Text>
      <TouchableOpacity
        style={[
          StyleUtils.shape_btn_small,
          {
            height: 48,
            width: "100%",
            justifyContent: "center",
            marginTop: 24,
            backgroundColor: COLOR_PRIMARY,
            flexDirection: "row",
            alignItems: "center",
          },
        ]}
        onPress={async () => {
          analytics.sendGetFreeClassReferral();
          try {
            const result = await Share.share({
              title: "Faça aulas no app Tutore!",
              message: referralText1 + referralCode + referralText2,
            });
            if (
              result.action === Share.sharedAction ||
              result.action === Share.dismissedAction
            ) {
              props.history.push("/");
            }
          } catch (error) {
            alert(error);
          }
        }}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_16,
            {
              textAlign: "center",
              paddingTop: 2,
            },
          ]}
        >
          Ganhar aulas grátis
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          props.history.push("/");
          analytics.sendCancelReferral();
        }}
      >
        <Text style={[styles.cancelText, { textDecorationLine: "underline" }]}>
          Quero passar sozinho na faculdade
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  modalTextHeader: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    marginTop: 25,
    textAlign: "center",
  },
  modalText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    textAlign: "center",
  },
  cancelText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    textAlign: "center",
  },
});

export default connect(stateToProps, dispatchToProps)(ClassroomEndReferral);
