import React, { useRef, useState } from "react";
import {
  Text,
  View,
  TextInput,
  KeyboardTypeOptions,
  ScrollView,
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
} from "react-native";
import StyleUtils, { COLOR_WHITE, SpcStyle } from "../Utils/StyleUtils";
import ButtonElement from "../Elements/ButtonElement";
import SVGImage from "../Utils/SVGImage";
import BackArrow from "../../assets/backbutton.svg";
import { connect } from "react-redux";
import { createUserPersonalInfo } from "../Server/index";
import { PersonalInfoValuesType } from "./VocationalTest";
type Props = {
  onBack: () => void;
  onNext: () => void;
  getInitialValues: () => PersonalInfoValuesType;
  onSubmit: (value: PersonalInfoValuesType) => void;
  user: any;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

const UserPersonalInfoScreen = ({
  onBack,
  onNext,
  user,
  getInitialValues,
  onSubmit,
}: Props) => {
  const initialValues = getInitialValues();
  const [phoneNumber, setPhoneNumber] = useState<string>(
    initialValues.phoneNumber
  );
  const handlePhoneNumberChange = (number: string) => {
    setPhoneNumber(formatPhoneNumber(number));
  };

  const [birthdayYear, setBirthdayYear] = useState<string>(
    initialValues.birthdayYear
  );
  const handleBirthdayYearChange = (number: string) => {
    setBirthdayYear(number);
  };
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[StyleUtils.screen_default]}
      keyboardVerticalOffset={80}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: "row", marginTop: 32 }}>
          <TouchableOpacity
            style={[
              {
                alignSelf: "flex-start",
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 24,
              },
            ]}
            onPress={onBack}
          >
            <SVGImage source={<BackArrow />} />
          </TouchableOpacity>
        </View>
        <ScrollView style={{ marginHorizontal: 32, marginTop: 30 }}>
          <Text
            style={[StyleUtils.font_cera_regular, StyleUtils.font_size_22, {}]}
          >
            Por favor,
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_22,
              { marginBottom: 32 },
            ]}
          >
            complete seu cadastro
          </Text>
          <CustomTextInput
            editable={false}
            header="Nome"
            value={user.name}
          ></CustomTextInput>
          <CustomTextInput
            editable={false}
            header="Email"
            value={user.email}
          ></CustomTextInput>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CustomTextInput
              maxLength={15}
              editable
              header="Telefone"
              placeholder="(xx) xxxxx-xxxx"
              value={phoneNumber}
              keyboardType="phone-pad"
              onChange={handlePhoneNumberChange}
            ></CustomTextInput>
            <CustomTextInput
              maxLength={4}
              editable
              header="Ano de Nascimento"
              placeholder="aaaa"
              value={birthdayYear}
              keyboardType="numeric"
              onChange={handleBirthdayYearChange}
            ></CustomTextInput>
          </View>
        </ScrollView>

        <ButtonElement
          disabled={birthdayYear.length !== 4 || phoneNumber.length < 14}
          text="Avançar"
          styte={[
            StyleUtils.shape_btn_default,
            {
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            },
          ]}
          textStyle={StyleUtils.font_size_16}
          onPress={() => {
            createUserPersonalInfo(user.id, phoneNumber, birthdayYear);
            onSubmit({ birthdayYear, phoneNumber });
            onNext();
          }}
        ></ButtonElement>
      </View>
    </KeyboardAvoidingView>
  );
};

type CustomTextInputProps = {
  header: string;
  placeholder?: string;
  maxLength?: number;
  value: string;
  editable?: boolean;
  style?;
  keyboardType?: KeyboardTypeOptions | undefined;
  onChange?: (text: string) => void;
};

const formatPhoneNumber = (newNumber: string) => {
  const onlyNumbers = newNumber
    .split("")
    .filter((char) => !isNaN(parseInt(char)))
    .join("");
  const numberCount = onlyNumbers.length;

  if (numberCount === 1) {
    return `(${onlyNumbers}`;
  } else if (numberCount >= 3 && numberCount <= 6) {
    const lastNumbers = onlyNumbers.slice(2);
    const intialNumbers = onlyNumbers.slice(0, 2);
    return `(${intialNumbers}) ${lastNumbers}`;
  } else if (numberCount >= 7 && numberCount <= 10) {
    const intialNumbers = onlyNumbers.slice(0, 2);
    const midNumbers = onlyNumbers.slice(2, 6);
    const lastNumbers = onlyNumbers.slice(6);
    return `(${intialNumbers}) ${midNumbers}-${lastNumbers}`;
  } else if (numberCount === 11) {
    const intialNumbers = onlyNumbers.slice(0, 2);
    const midNumbers = onlyNumbers.slice(2, 7);
    const lastNumbers = onlyNumbers.slice(7);
    return `(${intialNumbers}) ${midNumbers}-${lastNumbers}`;
  } else {
    return newNumber;
  }
};

export const CustomTextInput = ({
  header,
  placeholder,
  maxLength,
  value,
  editable,
  style,
  keyboardType = "default",
  onChange,
}: CustomTextInputProps) => {
  return (
    <View style={[{ marginBottom: 24 }, style]}>
      <Text
        style={[
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_14,
          StyleUtils.color_txt_normal,
        ]}
      >
        {header}
      </Text>
      <TextInput
        maxLength={maxLength}
        keyboardType={keyboardType}
        editable={editable}
        style={[
          SpcStyle.textInput,
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_dark,
          {
            backgroundColor: editable ? COLOR_WHITE : "#F7F9FD",
            minWidth: 136,
          },
        ]}
        placeholder={placeholder}
        value={value}
        onChangeText={onChange}
      ></TextInput>
    </View>
  );
};

export default connect(mapStateToProps, null)(UserPersonalInfoScreen);
