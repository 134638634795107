import React, { useMemo } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import StyleUtils, { COLOR_TXT_DARK } from "../Utils/StyleUtils";
import * as Utils from "../Utils/Utils";
import { NavigationButton } from "./NavigationButton";

type Props = {
  mobile?: boolean;
  selectedDate?: Date | null;
  selectedMonth: Date;
  onMonthChange?: (month: Date) => void;
  onDayPress?: (day: Date) => void;
  selectableDates?: Array<any>;
  children?: any;
};

const weekDayNames = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

export default function Calendar2(props: Props & { children?: any }) {
  const { children, ...other } = props;

  return (
    <View style={{ flex: 1 }}>
      <Calendar {...other} />
      {props.children}
    </View>
  );
}

export function Calendar(props: Props) {
  const calendarRows = useMemo(() => calcCalendarRows(props.selectedMonth), [
    props.selectedMonth,
  ]);
  return (
    <View>
      <View
        style={[
          styles.header,
          {
            flexDirection: "row",
            justifyContent: "space-between",
          },
        ]}
      >
        <Text
          style={[
            StyleUtils.font_btn_regular,
            StyleUtils.font_size_22,
            StyleUtils.color_txt_white,
            { textTransform: "capitalize" },
          ]}
        >
          {props.selectedMonth.toLocaleString("pt-br", { month: "long" })}
        </Text>
        <View style={{ flexDirection: "row" }}>
          <NavigationButton
            style={styles.calandarNavigation}
            size={16}
            type="backward"
            onPress={() =>
              props.onMonthChange &&
              props.onMonthChange(Utils.nextMonth(props.selectedMonth, -1))
            }
          />
          <NavigationButton
            style={styles.calandarNavigation}
            size={16}
            type="forward"
            onPress={() =>
              props.onMonthChange &&
              props.onMonthChange(Utils.nextMonth(props.selectedMonth, 1))
            }
          />
        </View>
      </View>
      <View
        style={[
          styles.header,
          {
            flexDirection: "row",
            justifyContent: "space-around",
            paddingBottom: 8,
          },
        ]}
      >
        {weekDayNames.map((name, index) => (
          <View key={index} style={{ flex: 1 }}>
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_cera_regular,
                StyleUtils.font_size_12,
                { textAlign: "center" },
              ]}
            >
              {name}
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.calandarContent}>
        {calendarRows.map((calendarRow, index) => (
          <View key={index}>
            {index > 0 && <View style={[StyleUtils.border_color_light]} />}
            <View style={styles.calandarRow}>
              {calendarRow.map((day) => (
                <CalendarDay
                  key={day.day.getTime()}
                  {...day}
                  onDayPress={props.onDayPress}
                  selectedDate={props.selectedDate}
                  selectableDates={props.selectableDates}
                />
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

type CalendarHoursProps = {
  selectedDate: Date;
  selectedTime?: Date;
  hours: Date[];
  onHourPress?: (hour: Date) => void;
  mobile?: boolean;
  noScroll?: boolean;
};

export function CalendarHours(props: CalendarHoursProps) {
  const DayContainer = props.mobile
    ? props.noScroll
      ? CalendarDayContainerNoScroll
      : CalendarDayContainer
    : CalendarDayContainerWeb;
  return (
    <DayContainer selectedDate={props.selectedDate}>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          paddingLeft: 24,
          paddingRight: 22,
        }}
      >
        {props.hours.map((timeDate, index) => {
          return (
            <TimeOption
              key={index}
              hour={timeDate}
              selected={
                props.selectedTime &&
                props.selectedTime.getTime() === timeDate.getTime()
              }
              onHourPress={props.onHourPress}
            />
          );
        })}
      </View>
    </DayContainer>
  );
}

function CalendarDay(
  props: DayType & {
    onDayPress?: (day: Date) => void;
    selectedDate?: Date | null;
    selectableDates?: Array<any>;
  }
) {
  const selected =
    props.selectedDate && props.selectedDate.getTime() == props.day.getTime();
  const day = props.day.getDate();
  const selectable =
    !props.otherMonth &&
    !!props.selectableDates &&
    !!props.selectableDates[day];
  return (
    <TouchableOpacity
      style={[
        {
          marginHorizontal: 2,
          marginVertical: 8,
          flex: 1,
          borderRadius: 40,
          justifyContent: "center",
        },
        selectable && StyleUtils.color_bg_primarylight,
        selected && StyleUtils.color_bg_primary,
      ]}
      disabled={!selectable}
      onPress={() => props.onDayPress && props.onDayPress(props.day)}
    >
      <Text
        style={[
          styles.text,
          {
            textAlign: "center",
          },
          props.otherMonth && StyleUtils.color_txt_muted,
          !props.otherMonth && props.today && StyleUtils.color_txt_danger,
          selected && StyleUtils.color_txt_white,
        ]}
      >
        {day}
      </Text>
    </TouchableOpacity>
  );
}

function CalendarDayContainer(props: { selectedDate: Date; children?: any }) {
  return (
    <ScrollView>
      <Text
        style={[
          styles.text,
          {
            marginVertical: 24,
            marginHorizontal: 24,
          },
        ]}
      >
        {Utils.dateFormatLong(props.selectedDate)}
      </Text>
      {props.children}
    </ScrollView>
  );
}

function CalendarDayContainerNoScroll(props: {
  selectedDate: Date;
  children?: any;
}) {
  return (
    <>
      <Text
        style={[
          styles.text,
          {
            marginVertical: 24,
            marginHorizontal: 24,
          },
        ]}
      >
        {Utils.dateFormatLong(props.selectedDate)}
      </Text>
      {props.children}
    </>
  );
}

function CalendarDayContainerWeb(props: {
  selectedDate: Date;
  children?: any;
}) {
  return (
    <ScrollView>
      <View style={styles.dayTextContainerWeb}>
        <Text
          style={[
            styles.text,
            StyleUtils.font_btn_bold,
            {
              margin: 32,
            },
          ]}
        >
          {Utils.dateFormatLong(props.selectedDate)}
        </Text>
      </View>
      {props.children}
    </ScrollView>
  );
}

function TimeOption(props: {
  hour: Date;
  selected?: boolean;
  onHourPress?: (hour: Date) => void;
}) {
  return (
    <TouchableOpacity
      style={[styles.timeOption, props.selected && styles.timeOptionSelected]}
      onPress={() => {
        props.onHourPress && props.onHourPress(props.hour);
      }}
    >
      <Text
        style={[styles.timeText, props.selected && styles.timeTextSelected]}
      >
        {Utils.dateFormatHHhmm(props.hour)}
      </Text>
    </TouchableOpacity>
  );
}

type DayType = {
  day: Date;
  otherMonth?: boolean;
  today?: boolean;
};

function calcCalendarRows(month: Date) {
  const rows: Array<Array<DayType>> = [];
  const nextMonth = Utils.nextMonth(month);
  let rowStart = Utils.addDays(month, -1 * month.getDay());
  const today = Utils.nextDay(new Date(), 0);
  while (rowStart < nextMonth) {
    const week: Array<DayType> = [];
    rows.push(week);
    for (let i = 0; i < 7; i++) {
      const dateProps: DayType = { day: rowStart };
      if (rowStart < month || rowStart >= nextMonth) {
        dateProps.otherMonth = true;
      }
      if (rowStart.getTime() == today.getTime()) {
        dateProps.today = true;
      }
      week.push(dateProps);
      rowStart = Utils.addDays(rowStart, 1);
    }
  }
  return rows;
}

const styles = StyleSheet.create({
  horizontal: { marginHorizontal: 8 },
  calandarNavigation: {
    ...StyleSheet.flatten(StyleUtils.color_bg_primary),
    marginHorizontal: 8,
  },
  header: {
    backgroundColor: COLOR_TXT_DARK,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  calandarContent: {
    ...StyleSheet.flatten(StyleUtils.color_bg_overlay),
    alignItems: "stretch",
  },
  calandarRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingHorizontal: 24,
  },
  text: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
  },
  timeOption: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    ...StyleSheet.flatten(StyleUtils.border_color_btn_dark),
    marginVertical: 6,
    marginLeft: "1%",
    marginRight: "1%",
    width: "23%",
  },
  timeOptionSelected: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_primary),
    ...StyleSheet.flatten(StyleUtils.color_bg_primarylight),
  },
  timeText: {
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
    marginVertical: 12,
  },
  timeTextSelected: {
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
  },
  dayTextContainerWeb: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.03,
    shadowRadius: 6,
    elevation: 8,
    marginBottom: 8,
  },
});
