import { useEffect } from "react";

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };
    setTimeout(() => {
      window.addEventListener("mousedown", listener);
      window.addEventListener("click", listener);
      window.addEventListener("touchstart", listener);
    }, 0); // to avoid bug of element getting created and destroyed right after

    return () => {
      window.removeEventListener("mousedown", listener);
      window.removeEventListener("click", listener);
      window.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
