import React, { useState, createRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Platform,
} from "react-native";
import StyleUtils, { COLOR_WHITE, COLOR_MUTED } from "../Utils/StyleUtils";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import ButtonElement from "../Elements/ButtonElement";
import Timer from "../Elements/Timer";
import Placeholder from "../../assets/dotWhite.svg";
import { createUserPersonalInfo } from "../Server";
import { analytics } from "../Analytics";

type Props = {
  show;
  close;
  onSuccess;
  changeNumber;
  phoneNumber;
  userID;
};

export default function ConfirmToken(props: Props) {
  const [token, setToken] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [resendCode, setResendCode] = useState(false);
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const CODE_LENGTH = new Array(6).fill(0);
  const openKeyboard = createRef();
  const [tempToken, setTempToken] = useState("");

  const generateToken = (n) => {
    var add = 1,
      max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if (n > max) {
      return generateToken(max) + generateToken(n - max);
    }

    max = Math.pow(10, n + add);
    var min = max / 10; // Math.pow(10, n) basically
    var number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ("" + number).substring(add);
  };

  useEffect(() => {
    setTempToken(generateToken(6));
    analytics.sendShowConfirmTokenPopup();
  }, []);

  useEffect(() => {
    if (props.phoneNumber.length > 0) {
      createUserPersonalInfo(
        props.userID,
        undefined,
        undefined,
        props.phoneNumber,
        true,
        tempToken
      );
    }
  }, [props.phoneNumber]);

  return (
    <AnimatedPopUp show={props.show} bgColor={COLOR_WHITE}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_dark,
        ]}
      >
        Digite o código de autenticação recebido no Whatsapp
      </Text>
      {tokenInvalid && (
        <Text
          style={[
            {
              alignSelf: "center",
              textAlign: "left",
              position: "absolute",
              top: 100,
            },
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_danger,
          ]}
        >
          Código Inválido
        </Text>
      )}
      <TextInput
        maxLength={6}
        keyboardType={"numeric"}
        editable={true}
        style={[
          StyleUtils.font_cera_regular,
          StyleUtils.color_txt_dark,
          {
            fontSize: 32,
            alignSelf: "flex-start",
            letterSpacing: Platform.OS === "ios" ? 32 : 31,
            marginLeft: Platform.OS === "ios" ? 18 : 0,
            width: "100%",
          },
        ]}
        onChangeText={(text) => {
          if (tokenInvalid) setTokenInvalid(false);
          setToken(text);
        }}
        value={token}
        autoFocus={!!openKeyboard}
      />
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
          marginBottom: 16,
        }}
      >
        {CODE_LENGTH.map(() => {
          return (
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                marginHorizontal: 4,
                width: Platform.OS === "ios" ? 43 : 48,
              }}
            />
          );
        })}
      </View>
      {resendCode && (
        <TouchableOpacity
          onPress={() => {
            analytics.sendResendToken();
            let generatedToken = generateToken(6);
            setTempToken(generatedToken);
            createUserPersonalInfo(
              props.userID,
              undefined,
              undefined,
              props.phoneNumber,
              true,
              generatedToken
            );
            setResendCode(false);
          }}
        >
          <Text
            style={[
              {
                alignSelf: "center",
                textAlign: "left",
                textDecorationLine: "underline",
              },
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_14,
              StyleUtils.color_txt_dark,
            ]}
          >
            Reenviar Código
          </Text>
        </TouchableOpacity>
      )}
      {!resendCode && (
        <View style={{ flexDirection: "row" }}>
          <Text
            style={[
              {
                alignSelf: "center",
                textAlign: "left",
              },
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_14,
              StyleUtils.color_txt_muted,
            ]}
          >
            Reenviar Código em
          </Text>
          <Timer
            initialTime={60}
            onEnded={() => setResendCode(true)}
            onChange={(time) => {
              setTimeLeft(60 - time);
            }}
            ClockIcon={Placeholder}
            textStyle={styles.clockTextStyle}
          />
        </View>
      )}

      <ButtonElement
        colorScheme="sucess"
        text="Confirmar Código e Agendar Aula"
        onPress={() => {
          if (tempToken == token) {
            analytics.sendConfirmTokenSuccess();
            props.onSuccess();
          } else {
            analytics.sendConfirmTokenFailure();
            setToken("");
            setTokenInvalid(true);
          }
        }}
        textStyle={styles.popupButtonText}
        styte={[styles.popupButton, { marginTop: 32 }]}
        disabled={token.length < 6}
      />
      <TouchableOpacity
        onPress={() => {
          analytics.sendChangeNumber();
          props.changeNumber();
        }}
        style={{
          marginTop: 25,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.color_txt_dark,
            {
              textDecorationLine: "underline",
              alignItems: "center",
              textAlign: "center",
            },
          ]}
        >
          Trocar Número
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
}

const styles = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
  clockTextStyle: {
    color: COLOR_MUTED,
  },
});
