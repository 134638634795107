import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Animated,
  StyleSheet,
  Image,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { SwipeListView } from "react-native-swipe-list-view";
import * as Animatable from "react-native-animatable";
import SVGImage from "../Utils/SVGImage";
import IconDelete from "../../assets/iconDelete.svg";
import Dots from "../../assets/dots.svg";
import IconAdd from "../../assets/iconPlus.svg";
import { getCreditCards } from "../Server";
import { analytics } from "../Analytics";

type Props = {
  user;
  location;
  history;
  cardToPay?;
  isSelected?;
};

export function CreditCardManager(props: Props) {
  // use PaymentReducer
  const [cardToDelete, setCardToDelete] = useState({
    cardId: "",
    lastDigits: "",
    rowMap: {},
  });
  const [cardOpen, setCardOpen] = useState("");

  const cardState = useSelector((state) => state.paymentRed);
  const dispatch = useDispatch();
  const addCardToRemove = () => {
    dispatch({
      type: "SET_CARD_TO_REMOVE",
      card: { id: cardToDelete.cardId, lastDigits: cardToDelete.lastDigits },
    });
  };

  useEffect(() => {
    addCardToRemove();
  }, [cardToDelete]);

  useEffect(() => {
    if (cardState.confirmDelete) {
      confirmDelete(cardToDelete.rowMap, cardToDelete.cardId);
    }
  }, [cardState]);

  const [isLoading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [cardToPay, setCardToPay] = useState("");

  const cardBrands = {
    visa: require("../../assets/card_visa.png"),
    mastercard: require("../../assets/card_mastercard.png"),
    amex: require("../../assets/card_amex.png"),
    elo: require("../../assets/card_elo.png"),
    hipercard: require("../../assets/card_hipercard.png"),
  };

  const cardTitle = {
    visa: "Visa - Crédito",
    mastercard: "Mastercard - Crédito",
    amex: "American Express - Crédito",
    elo: "Elo - Crédito",
    hipercard: "Hipercard - Crédito",
  };

  useEffect(() => {
    getCreditCards(props.user.id).then((res) => {
      const cards = res.map((cardItem, index) => ({
        // key: `${index}`,
        key: cardItem.cardId,
        title: cardItem.cardBrand,
        details: cardItem.cardLastDigits,
      }));
      setTimeout(() => {
        setListData(cards);
        setLoading(false);
      }, 400);
    });
  }, []);

  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };

  const openRow = (rowMap, rowKey) => {
    // verify card if open or close.
    if (rowMap[rowKey]) {
      if (rowKey === cardOpen) {
        rowMap[rowKey].manuallySwipeRow(0);
        setCardOpen("");
      } else {
        rowMap[rowKey].manuallySwipeRow(-90);
        setCardOpen(rowKey);
      }
    }
  };

  const deleteRow = (rowMap, rowKey, lastDigits) => {
    console.log(`card to delete ${rowKey}  -> ${lastDigits}`);
    setCardToDelete({
      cardId: rowKey,
      lastDigits: lastDigits,
      rowMap: rowMap,
    });
  };

  const confirmDelete = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    const newData = [...listData];
    // @ts-ignore
    const prevIndex = listData.findIndex((item) => item.key === rowKey);
    console.log(prevIndex);
    newData.splice(prevIndex, 1);
    setListData(newData);
    analytics.sendCreditCardRemove();
  };

  const selectedCard = (rowKey) => {
    if (props.isSelected) {
      props.cardToPay(rowKey);
      setCardToPay(rowKey);
    }
  };

  const VisibleItem = (props) => {
    const { data, rowMap } = props;
    // let image = '../../assets/card_visa.png';
    return (
      <View style={styles.rowFront}>
        <TouchableWithoutFeedback
          onPress={() => {
            selectedCard(data.item.key);
          }}
        >
          {cardToPay === data.item.key ? (
            <View style={[styles.rowFrontVisible, styles.rowFrontActive]}>
              <View style={styles.cardBrand}>
                <Image
                  style={styles.cardIcon}
                  source={cardBrands[data.item.title]}
                />
              </View>
              <View style={styles.cardDetails}>
                <Text style={styles.title} numberOfLines={1}>
                  {cardTitle[data.item.title]}
                </Text>
                <Text style={styles.details} numberOfLines={1}>
                  **** **** **** {data.item.details}
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  console.log("teste click active");
                  openRow(rowMap, data.item.key);
                }}
                style={styles.cardDots}
              >
                <SVGImage source={<Dots />} />
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.rowFrontVisible}>
              <View style={styles.cardBrand}>
                <Image
                  style={styles.cardIcon}
                  source={cardBrands[data.item.title]}
                />
              </View>
              <View style={styles.cardDetails}>
                <Text style={styles.title} numberOfLines={1}>
                  {cardTitle[data.item.title]}
                </Text>
                <Text style={styles.details} numberOfLines={1}>
                  **** **** **** {data.item.details}
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  openRow(rowMap, data.item.key);
                }}
                style={styles.cardDots}
              >
                <SVGImage source={<Dots />} />
              </TouchableOpacity>
            </View>
          )}
        </TouchableWithoutFeedback>
      </View>
    );
  };

  const renderItem = (data, rowMap) => {
    return (
      <VisibleItem
        data={data}
        rowMap={rowMap}
        onOpen={() => openRow(rowMap, data.item.key)}
      />
    );
  };

  const HiddenItemWithActions = (props) => {
    const { swipeAnimatedValue, onClose, onDelete } = props;
    return (
      <View style={styles.rowBack}>
        <Text>Left</Text>
        <TouchableOpacity
          style={[styles.backRightBtn, styles.backRightBtnRight]}
          onPress={onDelete}
        >
          <Animated.View
            style={[
              styles.trash,
              {
                transform: [
                  {
                    scale: swipeAnimatedValue.interpolate({
                      inputRange: [-90, -45],
                      outputRange: [1, 0],
                      extrapolate: "clamp",
                    }),
                  },
                ],
              },
            ]}
          >
            <SVGImage source={<IconDelete />} />
          </Animated.View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderHiddenItem = (data, rowMap) => {
    return (
      <HiddenItemWithActions
        data={data}
        rowMap={rowMap}
        onClose={() => closeRow(rowMap, data.item.key)}
        onDelete={() => deleteRow(rowMap, data.item.key, data.item.details)}
      />
    );
  };

  return (
    <>
      <View>
        {isLoading ? (
          <View style={[styles.loader]}>
            <ActivityIndicator size="large" color="#0179FF" />
          </View>
        ) : (
          <Animatable.View
            animation="fadeInDown"
            duration={500}
            easing="ease-in"
          >
            <SwipeListView
              useFlatList={true}
              data={listData}
              renderItem={renderItem}
              renderHiddenItem={renderHiddenItem}
              disableRightSwipe
              rightOpenValue={-90}
              // rightActionValue={-90}
              onRowOpen={(key) => setCardOpen(key)}
            />
          </Animatable.View>
        )}
        <Animatable.View animation="fadeInUp" duration={300}>
          <TouchableOpacity
            style={styles.addCard}
            onPress={() => {
              props.history.push({ pathname: "/payment/card/add" });
            }}
          >
            <View>
              <SVGImage source={<IconAdd />} />
            </View>
            <Text style={styles.addCardText}>Adicionar Novo Cartão</Text>
          </TouchableOpacity>
        </Animatable.View>
      </View>
    </>
  );
}

export default CreditCardManager;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  backTextWhite: {
    color: "#FFF",
  },
  loader: {
    padding: 20,
    height: 40,
    flex: 1,
    justifyContent: "center",
    // backgroundColor:'#000',
    alignItems: "center",
  },
  rowFront: {
    // backgroundColor: '#FFF',
    borderRadius: 8,
    height: 60,
    margin: 5,
    marginBottom: 5,
    // shadowColor: '#999',
    // shadowOffset: {width: 0, height: 1},
    // shadowOpacity: 0.8,
    // shadowRadius: 2,
    // elevation: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  rowFrontVisible: {
    borderWidth: 1,
    borderColor: "rgba(221, 234, 248, 1)",
    backgroundColor: "#FFF",
    borderRadius: 8,
    height: 60,
    padding: 10,
    marginBottom: 15,
    paddingLeft: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowFrontActive: {
    borderColor: "rgba(19, 206, 116, 1)",
  },
  rowBack: {
    alignItems: "center",
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 35,
    margin: 5,
    marginBottom: 15,
    borderRadius: 8,
    marginLeft: 25,
    marginRight: 25,
  },
  backRightBtn: {
    alignItems: "flex-end",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
    paddingRight: 17,
  },
  backRightBtnLeft: {
    backgroundColor: "#fff",
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: "#fff",
    right: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  trash: {
    height: 25,
    width: 25,
    marginRight: 2,
  },
  btnEdit: {
    height: 25,
    width: 25,
    marginRight: -8,
    marginTop: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#666",
  },
  details: {
    fontSize: 12,
    color: "#999",
  },
  cardBrand: {
    maxWidth: 40,
    marginRight: 10,
    alignItems: "center",
  },
  cardIcon: {
    maxWidth: 50,
    resizeMode: "contain",
    alignItems: "center",
  },
  cardDetails: {
    flexDirection: "column",
    width: "70%",
    justifyContent: "flex-start",
  },
  cardDots: {
    width: 30,
    height: 20,
  },
  addCard: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
    marginLeft: 25,
    marginRight: 25,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: "rgba(221, 234, 248, 1)",
    borderRadius: 8,
    borderWidth: 1,
    paddingTop: 15,
    paddingBottom: 15,
  },
  addCardText: {
    marginLeft: 30,
  },
});
