import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  SafeAreaView,
} from "react-native";
import BackArrow from "../../../assets/backbutton.svg";
import Checkmark from "../../../assets/checkWhite.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  SpcStyle,
  COLOR_TRUE_WHITE,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import ButtonElement from "../../Elements/ButtonElement";
import Footer from "../../Footer/Footer";

type Props = {
  areas;
  onContinue;
  onBack;
  showFooter?;
};

type State = {
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
};

export default class SubInterest extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedZero: null,
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      selectedFive: null,
    };
  }

  toggleSelectedLevel(item) {
    console.log(this.props.showFooter);
    switch (parseInt(item.id)) {
      case 0:
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea;
    switch (parseInt(item.id)) {
      case 0:
        isSelectedArea = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedArea = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedArea = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedArea = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedArea = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedArea = this.state.selectedFive === item;
        break;
    }
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          SpcStyle.cellBase,
          isSelectedArea ? SpcStyle.cellSelected : SpcStyle.cellUnselected,
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <View
          style={[
            SpcStyle.checkmarkBase,
            isSelectedArea
              ? SpcStyle.checkmarkSelected
              : SpcStyle.checkmarkUnselected,
          ]}
        >
          {isSelectedArea && <SVGImage source={<Checkmark />} />}
        </View>
        <Text
          style={[
            SpcStyle.cellText,
            isSelectedArea && { color: COLOR_PRIMARY },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <TouchableOpacity
            style={[
              {
                alignSelf: "flex-start",
                width: "15%",
                aspectRatio: 1,
                alignItems: "center",
                justifyContent: "center",
                margin: 16,
                marginTop: 38,
              },
            ]}
            onPress={() => {
              this.props.onBack();
            }}
          >
            <SVGImage source={<BackArrow />} />
          </TouchableOpacity>
          <View
            style={{
              height: "60%",
              width: "100%",
              justifyContent: "center",
              marginTop: 16,
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  marginTop: 30,
                  width: "100%",
                },
              ]}
            >
              Quais áreas te interessam?
            </Text>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                {
                  textAlign: "left",
                  marginTop: 10,
                  width: "100%",
                },
              ]}
            >
              (pode marcar mais de uma)
            </Text>
            <View style={{ marginTop: 20 }}>
              {this.props.areas.map((area) => this.renderLevelCell(area))}
            </View>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Próximo"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (
                this.state.selectedZero ||
                this.state.selectedOne ||
                this.state.selectedTwo ||
                this.state.selectedThree ||
                this.state.selectedFour ||
                this.state.selectedFive
              ) {
                let arr: string[] = [];
                if (this.state.selectedZero !== null) {
                  arr.push(this.state.selectedZero);
                }
                if (this.state.selectedOne !== null) {
                  arr.push(this.state.selectedOne);
                }
                if (this.state.selectedTwo !== null) {
                  arr.push(this.state.selectedTwo);
                }
                if (this.state.selectedThree !== null) {
                  arr.push(this.state.selectedThree);
                }
                if (this.state.selectedFour !== null) {
                  arr.push(this.state.selectedFour);
                }
                if (this.state.selectedFive !== null) {
                  arr.push(this.state.selectedFive);
                }
                let showFooter = false;
                if (this.props.showFooter) {
                  showFooter = true;
                }
                this.props.onContinue(arr, showFooter);
              }
            }}
            disabled={
              !this.state.selectedZero &&
              !this.state.selectedOne &&
              !this.state.selectedTwo &&
              !this.state.selectedThree &&
              !this.state.selectedFour &&
              !this.state.selectedFive
            }
          />
        </View>
        {!!this.props.showFooter && (
          <SafeAreaView>
            <Footer />
          </SafeAreaView>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
});
