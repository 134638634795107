import React from "react";
import { Component } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import StyleUtils, { COLOR_MUTED, SpcStyle } from "../../Utils/StyleUtils";
import * as Server from "../../Server";
import { ViewStyle } from "react-web-gifted-chat";
import SVGImage from "../../Utils/SVGImage";
import BackArrow from "../../../assets/backbutton.svg";

type Props = {
  onContinue;
  onBack;
};

type State = {
  tutorInput?: string;
  isValidEmail: boolean;
  failMessage?: string;
  failMessageMakeTutor?: string;
  titleString: string;
  isFocused: boolean;
};

export default class SpcChooseTutor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tutorInput: "",
      titleString: "Informe o email\ndo seu professor",
      isFocused: false,
      isValidEmail: false,
    };
  }

  parseInput(text) {
    const trimmedInput = text.trim();
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.setState({
      isValidEmail: emailRegex.test(trimmedInput),
      tutorInput: trimmedInput,
    });
  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <TouchableOpacity
            style={[
              {
                alignSelf: "flex-start",
                width: "15%",
                aspectRatio: 1,
                alignItems: "center",
                justifyContent: "center",
                margin: 16,
                marginTop: 38,
              },
            ]}
            onPress={() => {
              this.props.onBack();
            }}
          >
            <SVGImage source={<BackArrow />} />
          </TouchableOpacity>
          <View
            style={{
              height: "60%",
              width: "100%",
              marginTop: 32,
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text style={SpcStyle.headerText}>{this.state.titleString}</Text>
            <View>
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_14,
                  StyleUtils.color_txt_normal,
                  {
                    alignSelf: "flex-start",
                    marginTop: 30,
                  },
                ]}
              >
                Email do professor
              </Text>

              <TextInput
                style={[
                  SpcStyle.textInput,
                  this.state.isFocused && SpcStyle.textInputFocused,
                  !!this.state.failMessage && SpcStyle.textInputError,
                ]}
                keyboardType={"email-address"}
                autoCapitalize="none"
                placeholder="Digite aqui..."
                placeholderTextColor={COLOR_MUTED}
                onFocus={() => {
                  this.setState({ isFocused: true });
                }}
                onBlur={() => {
                  this.setState({ isFocused: false });
                }}
                onChangeText={(text) => this.parseInput(text)}
                value={this.state.tutorInput}
              />
              <FailMessage
                message={this.state.failMessage}
                style={{ marginTop: 4 }}
              />
            </View>
          </View>
          <TouchableOpacity
            disabled={!this.state.isValidEmail}
            style={[
              SpcStyle.continueButtonBase,
              this.state.isValidEmail
                ? SpcStyle.continueButtonActive
                : SpcStyle.continueButtonInactive,
            ]}
            onPress={() => {
              this.findTutor();
            }}
          >
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                {
                  padding: 16,
                  paddingTop: 16,
                  paddingBottom: 12,
                  paddingLeft: 40,
                  paddingRight: 40,
                  alignSelf: "center",
                },
              ]}
            >
              Conectar com o professor
            </Text>
          </TouchableOpacity>
        </View>
      </TouchableWithoutFeedback>
    );
  }

  //TODO: Activity indicator ?
  findTutor() {
    if (!this.state.tutorInput) {
      return;
    }
    const email = this.state.tutorInput.trim().toLowerCase();
    Server.getTutorFromEmail(email)
      .then((data: any) => {
        if (data.tutor) {
          this.props.onContinue(data.tutor);
        } else {
          this.setState({
            failMessage: "Tutor não encontrado, verifique o email.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          failMessage: "Falha na rede. Tente novamente mais tarde.",
        });
      });
  }
}

function FailMessage(props: { message?: string; style?: ViewStyle }) {
  return (
    <>
      {!!props.message && (
        <View
          style={[
            StyleUtils.color_btn_danger,
            StyleUtils.shape_card_default,
            {
              paddingVertical: 8,
              paddingHorizontal: 16,
            },
            props.style,
          ]}
        >
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_14,
              StyleUtils.color_txt_white,
              {
                alignSelf: "flex-start",
                paddingTop: 2,
              },
            ]}
          >
            {props.message}
          </Text>
        </View>
      )}
    </>
  );
}
