import React from "react";
import {
  Image,
  GestureResponderEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_GREY_LIGHT,
  COLOR_BG_LIGHT,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import CardLargeImageBorder from "../../../assets/cardLargeImageBorder.svg";
import CircleDots from "../../../assets/circleDots.svg";
import CardElement from "../../Elements/CardElement";
import { ViewStyle } from "react-web-gifted-chat";
import { userShortName } from "../../Utils/Utils";
import CardElementNew from "../../Elements/CardElementNew";

type Props = {
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  text?: string;
  description?: string;
  imageSource?: any;
  imageLabel?: string;
  imageIcon?: any;
  badge?: any;
  notification?: string;
  buttonText?: string;
  onPress?: (event: GestureResponderEvent) => void;
  onPress2?: (event: GestureResponderEvent) => void;
  style?: ViewStyle;
  subjects?;
  onPressTutor?: (event: GestureResponderEvent) => void;
  onPressReschedule?: (event: GestureResponderEvent) => void;
};

const Card = (props: Props & { mobile?: boolean }) => {
  const { mobile, ...other } = props;
  const CurrentCard = mobile ? MobileCard : DesktopCard;
  return <CurrentCard {...other} />;
};

export const MobileCard = (props: Props) => {
  const Info = props.size === "large" ? MobileInfoLarge : MobileInfo;
  const buttonColor: "white" | "success" | "primary" =
    props.cardStyle === "regular" ? "white" : props.cardStyle;
  const { style, ...other } = props;
  return props.buttonText === "Revisar" || props.buttonText === "Submeter" ? (
    <CardElement
      buttonText={props.buttonText}
      onPress={props.onPress}
      options={{ type: "arrow", colorScheme: buttonColor }}
      styte={[{ minHeight: 200 }, style]}
    >
      <Info {...other} />
    </CardElement>
  ) : (
    <CardElementNew
      buttonText={props.buttonText}
      onPress={props.onPress}
      onPress2={props.onPress2}
      options={{ type: "arrow", colorScheme: buttonColor }}
      styte={[{ minHeight: 200 }, style]}
      onPressReschedule={props.onPressReschedule}
    >
      <Info {...other} />
    </CardElementNew>
  );
};

export const DesktopCard = (props: Props) => {
  const { style, ...other } = props;
  return (
    <View
      style={[
        styles.mobileCard,
        {
          width: props.size === "large" ? 570 : 280,
          height: 300,
        },
        style,
      ]}
    >
      {props.size === "large" && (
        <SVGImage
          style={{ position: "absolute", right: 0 }}
          source={<CircleDots />}
        />
      )}
      <DesktopInfo {...other} />
      <TouchableOpacity
        style={[
          styles["button_" + props.cardStyle],
          StyleUtils.shape_btn_default_rounded,
          {
            paddingVertical: 16,
            margin: 32,
            width: 200,
          },
        ]}
        onPress={props.onPress}
      >
        <Text
          style={[
            styles.button_text,
            styles["button_text_" + props.cardStyle],
            { alignContent: "center", alignSelf: "center" },
          ]}
        >
          {props.buttonText}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const MobileInfo = (props: Props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        marginTop: 24,
        marginHorizontal: 24,
        alignItems: "center",
        height: 100,
        flex: 1,
        flexWrap: "wrap",
      }}
    >
      {props.imageSource && (
        <View style={{ marginRight: 8, marginBottom: 13 }}>
          <TouchableOpacity onPress={() => props.onPressTutor}>
            <Image
              source={props.imageSource}
              style={{
                width: 48,
                height: 48,
                borderRadius: 24,
                overflow: "hidden",
              }}
            />
          </TouchableOpacity>
        </View>
      )}
      {props.badge && (
        <View style={{ marginRight: 8, marginBottom: 13 }}>
          <SVGImage source={props.badge} style={{ height: 48, width: 48 }} />
          {(props.notification || props.notification === "") && (
            <View
              style={[
                styles.text_bg_danger,
                {
                  position: "absolute",
                  top: -7,
                  left: 36,
                  height: 18,
                  width: 18,
                  borderRadius: 9,
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                },
              ]}
            >
              <Text style={[styles.text_bg_danger, StyleUtils.font_size_12]}>
                {props.notification}
              </Text>
            </View>
          )}
        </View>
      )}
      <View style={{ flex: 1, minWidth: 140 }}>
        <Text style={styles.imageLabel}>{props.imageLabel}</Text>
        <Text style={[styles.text, styles["text_" + props.cardStyle]]}>
          {props.text}
        </Text>
        <Text style={styles.description}>{props.description}</Text>
      </View>
    </View>
  );
};

const MobileInfoLarge = (props: Props) => {
  const lessonDate = props.text && props.text.replace("-feira", "");
  return (
    <>
      <View
        style={{
          margin: 12,
          flexShrink: 1,
          flexDirection: "row",
        }}
      >
        {props.imageSource && (
          <TouchableOpacity onPress={props.onPressTutor}>
            <Image
              source={props.imageSource}
              style={{
                width: 106,
                height: 125,
                borderRadius: 15,
                overflow: "hidden",
                marginRight: 12,
                borderWidth: 1,
                borderColor: COLOR_MUTED,
              }}
            />
          </TouchableOpacity>
        )}

        <View style={{ flex: 1 }}>
          <Text style={[styles.imageLabel, { marginTop: 12, marginBottom: 4 }]}>
            {userShortName(props.imageLabel)}
          </Text>
          <Text style={[styles.text_grey]}>{props.subjects}</Text>
        </View>
      </View>
      <View
        style={[
          {
            borderBottomColor: COLOR_BG_LIGHT,
            borderBottomWidth: 1,
          },
        ]}
      />
      <View style={[StyleUtils.color_bg_overlay]}>
        <Text
          style={[
            styles.text_grey,
            {
              textAlign: "center",
              marginTop: 33,
            },
          ]}
        >
          {props.cardStyle && props.cardStyle === "primary"
            ? "Agende sua próxima aula"
            : "Sua próxima aula"}
        </Text>
        <View
          style={[
            styles["background_" + props.cardStyle],
            {
              marginTop: 8,
              marginBottom: 33,
              alignSelf: "center",
              borderRadius: 70,
              paddingHorizontal: 12,
            },
          ]}
        >
          <Text
            style={[
              styles["text_" + props.cardStyle],
              StyleUtils.font_size_16,
              StyleUtils.font_cera_regular,
              {
                textAlign: "center",
                paddingTop: 4,
                paddingBottom: Platform.OS === "ios" ? 0 : 4,
              },
            ]}
          >
            {lessonDate}
            {props.description}
          </Text>
        </View>
      </View>
    </>
  );
};

const DesktopInfo = (props: Props) => {
  return (
    <View
      style={{
        marginTop: 32,
        marginHorizontal: 32,
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
      }}
    >
      {!props.badge && (
        <View
          style={{
            height: 112,
            width: 112,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.imageSource && (
            <Image
              source={props.imageSource}
              style={{
                width: 82,
                height: 82,
                borderRadius: 41,
                overflow: "hidden",
                position: "absolute",
              }}
            />
          )}
          {props.imageSource && (
            <SVGImage
              style={{ position: "absolute", height: 112, width: 112 }}
              source={<CardLargeImageBorder />}
            />
          )}

          {props.imageIcon && (
            <SVGImage
              source={props.imageIcon}
              style={{
                position: "absolute",
                top: 8,
                left: 7,
                height: 32,
                width: 32,
              }}
            />
          )}
        </View>
      )}
      {props.badge && (
        <View style={{}}>
          <SVGImage source={props.badge} style={{ height: 48, width: 48 }} />
          {(props.notification || props.notification === "") && (
            <View
              style={[
                styles.text_bg_danger,
                {
                  position: "absolute",
                  top: -7,
                  left: 36,
                  height: 18,
                  width: 18,
                  borderRadius: 9,
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                },
              ]}
            >
              <Text style={[styles.text_bg_danger, StyleUtils.font_size_12]}>
                {props.notification}
              </Text>
            </View>
          )}
        </View>
      )}
      <View style={{ marginLeft: 8, flex: 1 }}>
        {!!props.imageLabel && (
          <Text style={[styles.imageLabel]}>{props.imageLabel}</Text>
        )}
        {!!props.text && (
          <Text style={[styles.text, styles["text_" + props.cardStyle]]}>
            {props.text}
          </Text>
        )}
        {!!props.description && (
          <Text style={styles.description}>{props.description}</Text>
        )}
        {!!props.subjects && (
          <Text style={styles["text_" + props.cardStyle]}>
            {props.subjects}
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileCardHolder: {
    height: 344,
    flexShrink: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  desktopCardHolder: {
    flexDirection: "row",
  },
  mobileCard: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_default),
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
    minHeight: 150,
  },
  imageLabel: {
    ...StyleSheet.flatten(StyleUtils.font_size_20),
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
  },
  text: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.color_txt_info),
  },
  text_success: {
    ...StyleSheet.flatten(StyleUtils.color_txt_success),
  },
  description: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.color_txt_light),
  },
  description_large: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
    ...StyleSheet.flatten(StyleUtils.color_bg_primarylight),
    paddingHorizontal: 12,
    paddingVertical: 3,
    borderRadius: 50,
  },
  button_regular: {
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  button_primary: {
    ...StyleSheet.flatten(StyleUtils.color_btn_primary),
  },
  button_success: {
    ...StyleSheet.flatten(StyleUtils.color_btn_success),
  },
  button_text: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  button_text_regular: {
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
  },
  text_bg_danger: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.color_btn_danger),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  text_grey: {
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    color: COLOR_TXT_GREY_LIGHT,
  },
  background_success: {
    ...StyleSheet.flatten(StyleUtils.color_bg_successlight),
  },
  background_primary: {
    ...StyleSheet.flatten(StyleUtils.color_bg_light),
  },
  background_regular: {
    ...StyleSheet.flatten(StyleUtils.color_bg_primarylight),
  },
  text_primary: {
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  text_regular: {
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
  },
});

export default Card;
