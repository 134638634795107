import { useEffect, useMemo } from "react";
import User from "../Models/User";
import { createSockeckIO } from "../Server";

const socket = createSockeckIO();

class CanvasConnection {
  classroomId: string;

  constructor(classroomId: string) {
    this.classroomId = classroomId;

    this.onConnect = this.onConnect.bind(this);
    socket.on("connect", this.onConnect);
    this.onDisconnect = this.onDisconnect.bind(this);
    socket.on("disconnect", this.onDisconnect);
  }

  open() {
    console.log(`open ${this.classroomId}`);
    socket.open();
  }

  close() {
    console.log(`close ${this.classroomId}`);
    socket.removeListener("connect", this.onConnect);
    socket.removeListener("disconnect", this.onDisconnect);
    socket.close();
  }

  sendAction(obj: any) {
    socket.emit("sendCanvasAction", { canvasId: this.classroomId, obj: obj });
  }

  addOnActionListener(listener: any) {
    socket.on("receiveCanvasAction", listener);
  }

  removeOnActionListener(listener: any) {
    socket.removeListener("receiveCanvasAction", listener);
  }

  private onConnect() {
    console.log(`connect ${this.classroomId}`);
    socket.emit("registerCanvas", this.classroomId);
    this.sendAction({ action: "canvas:requestLoad" });
  }
  private onDisconnect() {
    console.log(`disconnect ${this.classroomId}`);
  }
}

export function useCanvasConnection(
  classroomId: string,
  user: User,
  peerUser: User
) {
  const canvasConnection = useMemo(() => new CanvasConnection(classroomId), [
    classroomId,
  ]);
  useEffect(() => {
    canvasConnection.open();
    return () => canvasConnection.close();
  }, [canvasConnection]);

  return canvasConnection;
}

export { CanvasConnection };
