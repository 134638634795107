import React from "react";
import { useState, useCallback } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import * as Server from "../Server";
import User from "../Models/User";
import ImageUploader from "../ImageUploader/ImageUploader";
import { getFileURL } from "../Server";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_PRIMARY_LIGHT,
  COLOR_TXT_DARK,
} from "../Utils/StyleUtils";
import Checkmark from "../../assets/checkWhite.svg";
import SVGImage from "../Utils/SVGImage";
import Select from "react-select";

type Props = {
  history;
  user: User;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const PostExercise = (props: Props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [possibleAnswers, setPossibleAnswers] = useState(["", "", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageUploaderVisible, setImageUploaderVisible] = useState(true);
  const [isSelected, setSelected] = useState(false);

  const options = [
    { value: "a", label: "a" },
    { value: "b", label: "b" },
    { value: "c", label: "c" },
    { value: "d", label: "d" },
    { value: "e", label: "e" },
  ];
  const onImageUploadSuccessful = useCallback(
    (file) => {
      setImageUploaderVisible(false);
      const filename = file.name ? file.name : file.fileName;
      const fileURL = getFileURL(filename);
      setImageUrl(fileURL);
    },
    [] // Tells React to memoize regardless of arguments.
  );

  const indexToChar = (index) => {
    if (index === 0) return "a)";
    if (index === 1) return "b)";
    if (index === 2) return "c)";
    if (index === 3) return "d)";
    if (index === 4) return "e)";
  };

  const stringAnswer = (answer) => {
    if (answer.value === "a") return possibleAnswers[0];
    if (answer.value === "b") return possibleAnswers[1];
    if (answer.value === "c") return possibleAnswers[2];
    if (answer.value === "d") return possibleAnswers[3];
    if (answer.value === "e") return possibleAnswers[4];
    return "";
  };

  return (
    <View style={[StyleUtils.screen_default, { alignItems: "center" }]}>
      <View style={{}}>
        <QuestionTitle text="Acrescentar exercício à biblioteca" />
        <QuestionLabel text="Nome">
          <QuestionTextInput defaultValue={name} onChangeText={setName} />
        </QuestionLabel>
        <QuestionLabel text="Questão">
          <QuestionTextInput
            defaultValue={description}
            onChangeText={setDescription}
          />
        </QuestionLabel>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: imageUrl !== "" ? 30 : 100,
            marginBottom: imageUrl !== "" ? 30 : 90,
          }}
        >
          <ImageUploader
            descriptionText={"Arraste caso o enunciado \n possua alguma imagem"}
            style={[
              {
                position: "absolute",
                minWidth: "90%",
                minHeight: 148,
              },
            ]}
            isVisible={imageUploaderVisible}
            onImageUploaded={onImageUploadSuccessful}
            isMobile={false}
          />
          {imageUrl !== "" && (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: imageUrl }}
                style={{
                  width: 600,
                  height: 400,
                  resizeMode: "contain",
                  borderRadius: 6,
                }}
              />
            </View>
          )}
        </View>
        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            style={[
              styles.cellBase,
              isSelected ? styles.cellSelected : styles.cellUnselected,
            ]}
            onPress={(e) => setSelected(!isSelected)}
          >
            <View
              style={[
                styles.checkmarkBase,
                isSelected
                  ? styles.checkmarkSelected
                  : styles.checkmarkUnselected,
              ]}
            >
              {isSelected && <SVGImage source={<Checkmark />} />}
            </View>
          </TouchableOpacity>
          <Text style={[styles.cellText]}>
            Esse exercício é um exercício dissertativo?
          </Text>
        </View>
        {!isSelected && (
          <>
            <QuestionLabel text="Alternativas">
              {possibleAnswers.map((possibleAnswer, index) => (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.alternativeText]}>
                    {indexToChar(index)}
                  </Text>
                  <QuestionTextInput
                    key={index}
                    defaultValue={possibleAnswer}
                    onChangeText={(text) => {
                      possibleAnswers[index] = text;
                      setPossibleAnswers(possibleAnswers);
                    }}
                    styte={{ width: 310 }}
                  />
                </View>
              ))}
            </QuestionLabel>
            <QuestionLabel text="Resposta">
              <Select
                name="Resposta"
                closeMenuOnSelect={true}
                value={correctAnswer}
                onChange={(text) => setCorrectAnswer(text)}
                noOptionsMessage={() => "nenhum horário disponível"}
                placeholder="Selecione a resposta certa"
                options={options}
              />
            </QuestionLabel>
          </>
        )}
        <QuestionButton
          text="Submeter"
          styte={{ marginTop: 200 }}
          onPress={() => {
            if (description !== "") {
              let answer = stringAnswer(correctAnswer);
              submitExercise(
                props.user.id,
                description,
                possibleAnswers,
                answer,
                imageUrl,
                name,
                history.state.state.currentDirectory.id
              )
                .then(() =>
                  props.history.push("/exerciseDirectories", {
                    directoryId: history.state.state.currentDirectory.id,
                  })
                )
                .catch();
            }
          }}
        />
      </View>
    </View>
  );
};

function submitExercise(
  userId: string,
  description: string,
  possibleAnswers: string[],
  correctAnswer: string,
  imageUrl: string,
  name: string,
  directoryId: any
): Promise<any> {
  if (description) {
    if (directoryId === null) {
      directoryId = undefined;
    }
    return Server.postExercise(
      userId,
      description,
      possibleAnswers,
      correctAnswer,
      imageUrl,
      name,
      directoryId
    );
  } else {
    return Promise.reject("invalid parameter");
  }
}

const QuestionTitle = (props: { text?: string }) => {
  return (
    <Text
      style={[
        StyleUtils.font_btn_bold,
        StyleUtils.font_size_20,
        { alignSelf: "center", textAlign: "center", marginVertical: 24 },
      ]}
    >
      {props.text}
    </Text>
  );
};

const QuestionLabel = (props: {
  text?: string;
  children?: any;
  styte?: any;
}) => {
  return (
    <View style={[{ margin: 8 }, props.styte]}>
      {props.text && (
        <Text
          style={[
            StyleUtils.font_btn_bold,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_light,
          ]}
        >
          {props.text}
        </Text>
      )}
      {props.children && props.children}
    </View>
  );
};

const QuestionTextInput = (props: {
  defaultValue?: string;
  styte?: any;
  onChangeText?: (text: string) => void;
}) => {
  return (
    <TextInput
      style={[
        StyleUtils.border_color_btn_muted,
        StyleUtils.font_size_18,
        StyleUtils.font_btn_regular,
        StyleUtils.color_txt_normal,
        {
          borderRadius: 10,
          height: 48,
          padding: 8,
        },
        props.styte,
      ]}
      onChangeText={props.onChangeText}
      defaultValue={props.defaultValue}
    />
  );
};

const QuestionButton = (props: {
  text?: string;
  styte?: any;
  textStyle?: any;
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity
      style={[
        StyleUtils.shape_btn_default_rounded,
        StyleUtils.color_bg_primary,
        { margin: 8 },
        props.styte,
      ]}
      onPress={props.onPress}
    >
      <Text
        style={[
          StyleUtils.color_txt_white,
          StyleUtils.font_btn_bold,
          {
            padding: 16,
            paddingHorizontal: 80,
            alignSelf: "center",
          },
          props.textStyle,
        ]}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cellBase: {
    height: 30,
    width: 31,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginTop: 4,
    marginBottom: 4,
  },
  cellUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    borderColor: COLOR_PRIMARY,
    borderWidth: 1,
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  checkmarkBase: {
    height: "50%",
    width: 15,
    marginHorizontal: 7,
    borderRadius: 1000,
    alignItems: "center",
    justifyContent: "center",
  },
  checkmarkUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  checkmarkSelected: {
    backgroundColor: COLOR_PRIMARY,
  },
  cellText: {
    paddingTop: 10,
    color: COLOR_TXT_DARK,
    fontFamily: "CeraPro-Regular",
    fontWeight: "normal",
    fontSize: 16,
    marginLeft: 8,
  },
  alternativeText: {
    paddingTop: 14,
    color: COLOR_TXT_DARK,
    fontFamily: "CeraPro-Regular",
    fontSize: 16,
    marginRight: 8,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PostExercise);
