import React from "react";
import { Component } from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Alert,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { connect } from "react-redux";
import { selectTutor } from "../Actions";
import SVGImage from "../Utils/SVGImage";
import BackButton from "../../assets/backButtonBorder.svg";
import { RescheduleClassPopup } from "../Popups/RescheduleClassPopup";
import { analytics } from "../Analytics";

type Props = {
  selectedEvent;
  selectedUser;
  history;
  tutorInfo;
  selectTutor;
  mobile;
};

type State = {
  reschedulePopupShow;
  classFinished;
  classIn24Hours;
};

const stateToProps = (state) => {
  return {
    mobile: state.layoutRed.mobile,
    selectedUser: state.selRed.selectedUser,
    selectedEvent: state.selRed.selectedEvent,
  };
};

function dispatchToProps(dispatch) {
  return {
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
    dispatch: dispatch,
  };
}
class EventInfo extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      reschedulePopupShow: false,
      classFinished: false,
      classIn24Hours: false,
    };
  }

  componentDidMount() {
    let date = new Date(this.props.selectedEvent.startDate);
    if (date.getTime() < new Date().getTime()) {
      this.setState({ classFinished: true });
    } else if (date.getTime() < new Date().getTime() + 60 * 60 * 24 * 1000) {
      this.setState({ classIn24Hours: true });
    }
  }

  dateString = () => {
    let date = new Date(this.props.selectedEvent.startDate);
    return date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
  };

  render() {
    return (
      <View style={[StyleUtils.screen_default, { justifyContent: "center" }]}>
        <TouchableOpacity
          style={{
            top: 40,
            width: 40,
            height: 48,
            justifyContent: "center",
            position: "absolute",
          }}
          onPress={() => {
            this.props.history.goBack();
          }}
        >
          <View
            style={{
              flex: 1,
              marginLeft: 15,
              marginTop: 15,
              justifyContent: "center",
            }}
          >
            <SVGImage
              source={<BackButton />}
              style={{ width: 40, height: 40 }}
            />
          </View>
        </TouchableOpacity>
        <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
          <View
            style={{
              width: 104,
              height: 104,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={
                this.props.selectedUser.imageURL
                  ? {
                      uri: this.props.selectedUser.imageURL,
                    }
                  : require("../../assets/userPlaceHolder.png")
              }
              style={{
                width: 80,
                height: 80,
                position: "absolute",
                borderRadius: 40,
                overflow: "hidden",
              }}
            />
          </View>
          <Text
            style={[
              { textAlign: "center", marginBottom: 16 },
              StyleUtils.color_txt_dark,
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_16,
            ]}
          >
            {this.props.selectedUser.name}
          </Text>
          <Text
            style={[
              {
                alignSelf: "center",
                marginBottom: 35,
                textAlign: "center",
                padding: 5,
                flexShrink: 0,
              },
              StyleUtils.color_txt_success,
              StyleUtils.font_size_14,
              StyleUtils.shape_btn_default,
              StyleUtils.color_bg_successlight,
            ]}
          >
            {this.dateString()}
          </Text>

          <TouchableOpacity
            style={[
              { margin: 8 },
              StyleUtils.color_btn_success,
              StyleUtils.shape_btn_default_rounded,
            ]}
            onPress={() => {
              analytics.sendStartClassSelected();
              this.props.history.push("/classroom");
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 16 },
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
              ]}
            >
              Entrar na aula
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              { margin: 8 },
              StyleUtils.border_color_btn_primary,
              StyleUtils.shape_btn_default_rounded,
            ]}
            onPress={() => {
              this.props.history.push({
                pathname: `/messages/${this.props.selectedUser.id}`,
                state: { shouldGoBack: true },
              });
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 16 },
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_primary,
                StyleUtils.font_size_16,
              ]}
            >
              Enviar uma mensagem
            </Text>
          </TouchableOpacity>
          {!this.state.classFinished && this.props.mobile && (
            <TouchableOpacity
              onPress={() => this.setState({ reschedulePopupShow: true })}
            >
              <Text
                style={[
                  StyleUtils.font_btn_bold,
                  StyleUtils.color_txt_primary,
                  StyleUtils.font_size_16,
                  {
                    textDecorationLine: "underline",
                    textAlign: "center",
                    marginTop: 24,
                  },
                ]}
              >
                Remarcar aula
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <RescheduleClassPopup
          show={this.state.reschedulePopupShow}
          close={() => {
            this.setState({
              reschedulePopupShow: false,
            });
          }}
          onClose={() => {
            let subject = "";
            let subjectLevel = "";
            if (this.props.selectedEvent.subject) {
              subject = this.props.selectedEvent.subject;
              subjectLevel = this.props.selectedEvent.subjectLevel;
            }
            this.props.selectTutor(this.props.selectedUser);
            this.props.history.push({
              pathname: "/booking",
              state: {
                subject: subject,
                subjectLevel: subjectLevel,
                reschedule: true,
                eventID: this.props.selectedEvent.id,
                source: "reschedule_popup",
              },
            });
          }}
          onReturn={() => {
            this.setState({
              reschedulePopupShow: false,
            });
          }}
          onAlert={() => {
            Alert.alert(
              "",
              "Esta aula não pode ser remarcada pois ela acontecerá em menos de 24h",
              [
                {
                  text: "OK",
                  onPress: () => {
                    this.setState({
                      reschedulePopupShow: false,
                    });
                  },
                },
              ],
              { cancelable: false }
            );
          }}
          classIn24Hours={this.state.classIn24Hours}
        />
      </View>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(EventInfo);

const style = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});
