import React from "react";
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import CardArrowWhite from "../../assets/cardArrowWhite.svg";
import CardArrowPrimary from "../../assets/cardArrowPrimary.svg";
import Chat from "../../assets/chatButtonWhite.svg";
import { ViewStyle, TextStyle } from "react-web-gifted-chat";
import ChatBlack from "../../assets/chatBlack.svg";

type Options = {
  colorScheme?: "white" | "success" | "primary";
  type?: "arrow" | "selection" | "none";
};

type Props = {
  buttonText?: string;
  onPress?: (event: GestureResponderEvent) => void;
  onPress2?: (event: GestureResponderEvent) => void;
  onPressReschedule?: (event: GestureResponderEvent) => void;
  selected?: boolean;
  children?: any;
  options?: Options;
  styte?: ViewStyle;
  textStyle?: TextStyle;
};

export const CardElementNew = (props: Props) => {
  const colorScheme = (props.options && props.options.colorScheme) || "white";
  const type = (props.options && props.options.type) || "arrow";
  const cardStyles = elementStyles(props.selected);
  const Arrow = cardStyles.arrows[colorScheme];
  return (
    <View
      style={[styles.mobileCard, cardStyles.borders[colorScheme], props.styte]}
    >
      {props.children}
      {colorScheme === "primary" ? (
        <>
          <View
            style={{
              flexDirection: "row",
              marginBottom: 16,
              height: 48,
            }}
          >
            <TouchableOpacity
              onPress={props.onPress2}
              style={[
                StyleUtils.border_color_btn_dark,
                {
                  width: 61,
                  borderRadius: 6,
                  marginHorizontal: 15,
                  alignItems: "center",
                  justifyContent: "center",
                },
              ]}
            >
              <SVGImage source={<ChatBlack />} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={props.onPress}
              style={[
                cardStyles.buttonStyles[colorScheme],
                {
                  borderRadius: 6,
                  justifyContent: "center",
                  alignSelf: "stretch",
                  paddingHorizontal: 15,
                  flex: 1,
                  marginRight: 15,
                },
              ]}
            >
              <Text
                style={[
                  cardStyles.textStyles[colorScheme],
                  props.textStyle,
                  {
                    paddingTop: Platform.OS === "ios" ? 4 : 0,
                    textAlign: "center",
                  },
                ]}
              >
                {props.buttonText}
              </Text>
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <>
          <TouchableOpacity
            style={[
              cardStyles.buttonStyles[colorScheme],
              {
                marginHorizontal: 15,
                borderRadius: 6,
              },
            ]}
            onPress={props.onPress}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 48,
              }}
            >
              {colorScheme === "white" && (
                <SVGImage source={<Chat />} style={{ paddingRight: 20 }} />
              )}
              <Text
                style={[
                  cardStyles.textStyles[colorScheme],
                  props.textStyle,
                  {
                    paddingTop: 4,
                    lineHeight: Platform.OS === "ios" ? 16 : 14,
                  },
                ]}
              >
                {props.buttonText}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={props.onPressReschedule}>
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
                {
                  textDecorationLine: "underline",
                  textAlign: "center",
                  marginVertical: 16,
                },
              ]}
            >
              Remarcar aula
            </Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
};

function elementStyles(selected?: boolean) {
  return !selected
    ? {
        buttonStyles: {
          white: styles.button_primary,
          primary: styles.button_primary,
          success: styles.button_success,
        },
        borders: {
          white: styles.border_white,
          primary: styles.border_primary,
          success: styles.border_success,
        },
        textStyles: {
          white: styles.button_text_white,
          primary: styles.button_text_white,
          success: styles.button_text_white,
        },
        arrows: {
          white: CardArrowPrimary,
          primary: CardArrowWhite,
          success: CardArrowWhite,
        },
      }
    : {
        buttonStyles: {
          white: styles.button_primary,
          primary: styles.button_white,
          success: styles.button_white,
        },
        borders: {
          white: styles.border_primary,
          primary: styles.border_white,
          success: styles.border_white,
        },
        textStyles: {
          white: styles.button_text_white,
          primary: styles.button_text_primary,
          success: styles.button_text_success,
        },
        arrows: {
          white: CardArrowWhite,
          primary: CardArrowPrimary,
          success: CardArrowPrimary,
        },
      };
}

const styles = StyleSheet.create({
  mobileCard: {
    ...StyleSheet.flatten(StyleUtils.shape_card_default),
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  border_white: {
    ...StyleSheet.flatten(StyleUtils.border_color_txt_white),
    borderWidth: 0,
  },
  border_primary: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_primary),
    borderWidth: 0,
  },
  border_success: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_success),
    borderWidth: 0,
  },
  button_white: {
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  button_primary: {
    ...StyleSheet.flatten(StyleUtils.color_btn_primary),
  },
  button_success: {
    ...StyleSheet.flatten(StyleUtils.color_btn_success),
  },
  button_text_white: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  button_text_primary: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
  },
  button_text_success: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_success),
  },
});

export default CardElementNew;
