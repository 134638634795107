import React, { Component, useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { ViewStyle } from "react-web-gifted-chat";
import CorrectIcon from "../../assets/check.svg";
import SendIcon from "../../assets/sendIcon.svg";
import WrongIcon from "../../assets/closeRed.svg";
import QuestionNumber from "../Elements/QuestionNumber";
import CustomLightBox from "../ImageUploader/CustomLightBox";
import CustomLightBoxLarge from "../ImageUploader/CustomLightBoxLarge";
import SubjectTest, {
  subjectTestState,
  SubjectTestState,
} from "../Models/SubjectTest";
import TestQuestion from "../Models/TestQuestion";
import User from "../Models/User";
import { updateTestQuestion } from "../Server";
import StyleUtils, {
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_SUCCESS,
  COLOR_TXT_LIGHT,
  COLOR_TXT_NORMAL,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import * as Utils from "../Utils/Utils";
import { AnswerState } from "../Exercises/Exercises";
import {
  answerState,
  exerciseViewMode,
  ExerciseViewMode,
  itemState,
} from "../Exercises/Models/AssignmentUtils";
import LinearGradient from "react-native-linear-gradient";
import { withRouter } from "../Router";
import { connect } from "react-redux";

type Props = {
  subjectTest: SubjectTest;
  assignment: TestQuestion;
  assignmentIndex;
  tutorInfo;
  selectImagePopup;
  updateAssignment;
  reviewMode?: boolean;
  user;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function dispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
  };
}

class SubjectTestExerciseCard extends Component<Props, any> {
  render() {
    const hmState = subjectTestState(this.props.subjectTest);
    const editable = hmState < SubjectTestState.ANSWERED;
    const tutorEditable =
      hmState >= SubjectTestState.ANSWERED &&
      hmState < SubjectTestState.CORRECTED;
    const isTutor = !!this.props.tutorInfo;
    const containsImageUrl =
      this.props.assignment.exercise &&
      !!this.props.assignment.exercise.imageUrl;
    const writtenQuestion =
      this.props.assignment.exercise &&
      this.props.assignment.exercise.possibleAnswers &&
      this.props.assignment.exercise.possibleAnswers[0] === "";

    return (
      <LinearGradient
        colors={["#ECF0F9", "#F7F9FD"]}
        angle={180}
        style={[
          StyleUtils.color_bg_light,
          StyleUtils.shape_btn_default,
          {
            width: "100%",
            maxWidth: 548,
            alignSelf: "center",
            paddingVertical: 40,
            paddingHorizontal: 24,
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            marginBottom: 16,
            alignItems: "center",
          }}
        >
          <QuestionNumber
            number={this.props.assignmentIndex + 1}
            style={{ marginRight: 4 }}
          />
          {exerciseViewMode(this.props.assignment, this.props.reviewMode) !=
            ExerciseViewMode.exerciseView &&
            itemState(this.props.assignment, this.props.reviewMode) ===
              AnswerState.Correct &&
            !writtenQuestion && (
              <View
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 1000,
                  backgroundColor: COLOR_SUCCESS,
                  marginLeft: 4,
                  marginTop: -5,
                }}
              />
            )}
          {exerciseViewMode(this.props.assignment, this.props.reviewMode) !=
            ExerciseViewMode.exerciseView &&
            itemState(this.props.assignment, this.props.reviewMode) ===
              AnswerState.Wrong &&
            !writtenQuestion && (
              <View
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 1000,
                  backgroundColor: COLOR_DANGER,
                  marginLeft: 4,
                  marginTop: -5,
                }}
              />
            )}
        </View>
        <Text
          style={[
            StyleUtils.font_size_16,
            StyleUtils.color_txt_normal,
            { marginBottom: containsImageUrl ? 0 : 24 },
          ]}
        >
          {this.props.assignment.exercise &&
            this.props.assignment.exercise.description}
        </Text>
        {containsImageUrl && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 10,
            }}
          >
            {this.props.assignment.exercise && (
              <CustomLightBoxLarge
                style={{
                  width: 300,
                  height: 200,
                  resizeMode: "contain",
                }}
                activeProps={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "contain",
                }}
                source={this.props.assignment.exercise.imageUrl}
              />
            )}
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          {this.props.assignment.exercise &&
            this.props.assignment.exercise.possibleAnswers &&
            this.props.assignment.exercise.possibleAnswers.map(
              (answer, answerIndex) => {
                if (answer !== "") {
                  const cAnswerState = answerState(
                    this.props.assignment,
                    answer,
                    this.props.reviewMode
                  );
                  return (
                    <ExerciseAlternative
                      key={answerIndex}
                      answerState={cAnswerState}
                      answerIndex={answerIndex}
                      answer={answer}
                      correctAnswer={
                        this.props.assignment.exercise &&
                        this.props.assignment.exercise.correctAnswer
                      }
                      onPress={() => {
                        if (this.props.assignment.id) {
                          updateTestQuestion(this.props.assignment.id, {
                            userAnswer: answer,
                          }).then((assignment) =>
                            this.props.updateAssignment(assignment)
                          );
                        }
                      }}
                      disabled={!editable || isTutor}
                      editable={editable}
                    />
                  );
                }
              }
            )}
          {writtenQuestion && (
            <Comment
              style={{
                marginBottom: 16,
                marginTop: 16,
                flex: 1,
              }}
              user={this.props.user}
              text={this.props.assignment.tuteeAnswer}
              date={
                !!this.props.assignment.answeredAt
                  ? new Date(this.props.assignment.answeredAt)
                  : undefined
              }
              onPost={(text) => {
                if (this.props.assignment.id) {
                  updateTestQuestion(this.props.assignment.id, {
                    userAnswer: text,
                  }).then((assignment) => {
                    this.props.updateAssignment(assignment);
                  });
                }
              }}
              placeholder={"Escreva sua resposta..."}
            />
          )}
        </View>
        {this.props.assignment.exercise &&
          !!this.props.assignment.exercise.solutionImageUrl &&
          hmState >= SubjectTestState.ANSWERED && (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 16,
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_btn_bold,
                    StyleUtils.font_size_16,
                    { flex: 1 },
                  ]}
                >
                  Resolução Tutore
                </Text>
              </View>
              {Platform.OS === "web" ? (
                <Image
                  style={{ alignSelf: "center", width: 500, height: 500 }}
                  source={{
                    uri: this.props.assignment.exercise.solutionImageUrl,
                  }}
                />
              ) : (
                <CustomLightBox
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    margin: 8,
                    borderRadius: 8,
                    overflow: "hidden",
                  }}
                  source={this.props.assignment.exercise.solutionImageUrl}
                />
              )}
            </View>
          )}
        {((isTutor && tutorEditable) ||
          !!this.props.assignment.tutorCorrectionComment) && (
          <Comment
            style={{
              marginBottom: 16,
              marginTop: 16,
            }}
            user={this.props.subjectTest.tutor}
            text={this.props.assignment.tutorCorrectionComment}
            date={undefined}
            onPost={(text) => {
              if (this.props.assignment.id) {
                updateTestQuestion(this.props.assignment.id, {
                  tutorCorrectionComment: text,
                }).then((assignment) => {
                  this.props.updateAssignment(assignment);
                });
              }
            }}
          />
        )}
      </LinearGradient>
    );
  }
}

const ExerciseAlternative = (props: {
  answerState: AnswerState;
  answerIndex: number;
  answer: string;
  onPress?: () => void;
  disabled?: boolean;
  correctAnswer?: string;
  editable?: boolean;
}) => {
  const { answerState, answer, answerIndex } = props;

  return (
    <TouchableOpacity
      style={[
        StyleUtils.shape_btn_default,
        StyleUtils.shadow_soft_bottom,
        props.correctAnswer === answer && !props.editable
          ? cardStyleForAnswerState(AnswerState.Correct)
          : cardStyleForAnswerState(answerState),
        {
          minHeight: 85,
          minWidth: "48%",
          maxWidth: "100%",
          margin: "1%",
          padding: 8,
          paddingTop: 36,
          paddingBottom: 36,
          flexGrow: 1,
          alignItems: "stretch",
          elevation: 2,
        },
      ]}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      <View
        style={[
          StyleUtils.border_color_btn_muted,
          StyleUtils.shape_btn_default_rounded,
          props.correctAnswer === answer && !props.editable
            ? cardStyleForAnswerState(AnswerState.Correct)
            : cardStyleForAnswerState(answerState),
          {
            position: "absolute",
            top: 6,
            left: 6,
            height: 24,
            width: 24,
            overflow: "hidden",
            justifyContent: "center",
          },
        ]}
      >
        <Text
          style={[
            props.correctAnswer === answer && !props.editable
              ? textStyleForAnswerState(AnswerState.Correct)
              : textStyleForAnswerState(answerState),
            {
              textAlign: "center",
              textAlignVertical: "center",
              flexWrap: "wrap",
            },
          ]}
        >
          {String.fromCharCode(97 + answerIndex)}
        </Text>
      </View>
      {(answerState === AnswerState.Correct ||
        answerState === AnswerState.Wrong ||
        (props.correctAnswer === answer && !props.editable)) && (
        <View
          style={[
            {
              position: "absolute",
              top: 6,
              right: 6,
              height: 24,
              width: 24,
              overflow: "hidden",
            },
          ]}
        >
          {(answerState === AnswerState.Correct ||
            (props.correctAnswer === answer && !props.editable)) && (
            <SVGImage source={<CorrectIcon />} style={{ left: 6 }} />
          )}
          {answerState === AnswerState.Wrong && (
            <SVGImage source={<WrongIcon />} style={{ left: 6 }} />
          )}
        </View>
      )}
      <Text
        style={[
          props.correctAnswer === answer && !props.editable
            ? textStyleForAnswerState(AnswerState.Correct)
            : textStyleForAnswerState(answerState),
          StyleUtils.font_size_16,
          { textAlign: "center" },
        ]}
      >
        {answer}
      </Text>
    </TouchableOpacity>
  );
};

const cardStyleForAnswerState = (answerState: AnswerState) => {
  switch (answerState) {
    case AnswerState.Answered:
      return style.card_answered;
    case AnswerState.Wrong:
      return style.card_wrong;
    case AnswerState.Correct:
      return style.card_correct;
    case AnswerState.Unanswered:
    default:
      return style.card_unanswered;
  }
};

const textStyleForAnswerState = (answerState: AnswerState) => {
  switch (answerState) {
    case AnswerState.Answered:
      return style.text_answered;
    case AnswerState.Wrong:
      return style.text_answered_wrong;
    case AnswerState.Correct:
      return style.text_answered_correct;
    case AnswerState.Unanswered:
    default:
      return style.text_unanswered;
  }
};

const Comment = (props: {
  user?: User;
  text?: string;
  date?: Date;
  onPost?: (text: string) => void;
  disabled?: boolean;
  style?: ViewStyle;
  placeholder?;
}) => {
  const [commentDraft, setCommentDraft] = useState("");
  return (
    <View
      style={[
        StyleUtils.color_bg_white,
        StyleUtils.shape_btn_default,
        props.style,
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          paddingLeft: 8,
          paddingRight: 16,
        }}
      >
        <Image
          style={[
            { height: 40, width: 40, margin: 8 },
            StyleUtils.shape_btn_default_rounded,
          ]}
          source={
            props.user && props.user.imageURL
              ? {
                  uri: props.user.imageURL,
                }
              : require("../../assets/userPlaceHolder.png")
          }
        />
        <Text
          style={[
            StyleUtils.color_txt_dark,
            StyleUtils.font_btn_bold,
            { flex: 1, alignSelf: "center" },
          ]}
        >
          {!!props.user && props.user.name}
        </Text>
        <Text style={[StyleUtils.color_txt_normal, { alignSelf: "center" }]}>
          {formatCommentDate(props.date)}
        </Text>
      </View>
      <View style={[StyleUtils.color_btn_muted, { height: 1 }]} />
      {!!props.text && (
        <Text style={[StyleUtils.color_txt_normal, { padding: 16 }]}>
          {props.text}
        </Text>
      )}
      {!props.text && (
        <>
          <TextInput
            style={[
              StyleUtils.color_txt_normal,
              { padding: 12, margin: 3, marginTop: 2 },
            ]}
            multiline={true}
            defaultValue={props.text}
            onChangeText={(text) => {
              setCommentDraft(text);
            }}
            placeholder={
              props.placeholder ? props.placeholder : "Escreva um comentário..."
            }
            placeholderTextColor={COLOR_TXT_LIGHT}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: 16,
            }}
          >
            <TouchableOpacity
              style={[
                StyleUtils.color_btn_muted,
                {
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                },
                !!commentDraft && StyleUtils.color_bg_primary,
              ]}
              disabled={!commentDraft}
              onPress={() => {
                if (
                  commentDraft &&
                  props.text !== commentDraft &&
                  props.onPost
                ) {
                  props.onPost(commentDraft);
                }
              }}
            >
              <SVGImage
                style={{ position: "absolute", top: 11, left: 11 }}
                source={<SendIcon />}
              />
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
};
function formatCommentDate(date?: Date) {
  if (!date) {
    return "";
  }
  const today = Utils.nextDay(new Date(), 0);
  const tomorrow = Utils.nextDay(today);
  if (date >= today && date < tomorrow) {
    return date.getHours() + "h";
  }
  return date.toLocaleDateString("pt-br");
}

const style = StyleSheet.create({
  card_unanswered: {
    backgroundColor: COLOR_WHITE,
  },
  card_answered: {
    backgroundColor: COLOR_PRIMARY,
  },
  card_wrong: {
    backgroundColor: "#FFF1F5",
    borderColor: COLOR_DANGER,
    borderWidth: 1,
  },
  card_correct: {
    backgroundColor: "#F2FFF7",
    borderColor: COLOR_SUCCESS,
    borderWidth: 1,
  },
  text_unanswered: {
    color: COLOR_TXT_NORMAL,
  },
  text_answered_correct: {
    color: COLOR_SUCCESS,
  },
  text_answered_wrong: {
    color: COLOR_DANGER,
  },
  text_answered: {
    color: COLOR_WHITE,
  },
  dot_unanswered: {
    backgroundColor: "#494D77",
  },
});

export default withRouter(
  connect(stateToProps, dispatchToProps)(SubjectTestExerciseCard)
);
