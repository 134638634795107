import React from "react";
import { TouchableOpacity, Text } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import ButtonElement from "../Elements/ButtonElement";
import Popup from "../Elements/Popup";

type Props = {
  mobile?: boolean;
  visible?: boolean;
  onConfirm?: () => void;
  onExit?: () => void;
};

const CorrectPopup = (props: Props) => {
  return (
    <Popup
      visible={props.visible}
      onRequestClose={props.onExit}
      style={{
        paddingHorizontal: 32,
        paddingBottom: 32,
        paddingTop: 40,
        alignItems: "center",
        //margin: 24,
        //alignSelf: "center",
        maxWidth: 327,
      }}
    >
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_20,
          { textAlign: "center" },
        ]}
      >
        Finalizar a correção?
      </Text>
      <Text
        style={[
          StyleUtils.font_btn_regular,
          StyleUtils.color_txt_light,
          StyleUtils.font_size_16,
          { marginTop: 24, textAlign: "center" },
        ]}
      >
        Os comentários serão enviados para o seu aluno.
      </Text>
      <ButtonElement
        colorScheme="sucess"
        text="Confirmar"
        onPress={props.onConfirm}
        styte={[{ margin: 0, marginTop: 40, alignSelf: "stretch" }]}
      />
      <TouchableOpacity onPress={props.onExit}>
        <Text
          style={[
            StyleUtils.font_btn_regular,
            StyleUtils.color_txt_normal,
            StyleUtils.font_size_16,
            {
              marginTop: 24,
              textDecorationLine: "underline",
              textAlign: "center",
            },
          ]}
        >
          Voltar
        </Text>
      </TouchableOpacity>
    </Popup>
  );
};

export default CorrectPopup;
