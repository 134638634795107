import User from "./User";
import Assignment, { assignmentState } from "./Assignment";
import AssignmentState from "./AssignmentState";
import EventInfo from "../EventInfo/EventInfo";
import Homework from "./Homework";

export enum NotificationType {
  HOUR_BEFORE_CLASS,
  MINUTE_BEFORE_CLASS,
  MESSAGE_SENT,
  HOMEWORK_ASSIGNED,
  HOMEWORK_INACTIVITY,
  HOMEWORK_INACTIVITY_DEADLINE,
  HOMEWORK_FINISHED,
  HOMEWORK_CORRECTION_AVAILABLE,
  TUTEE_CHURN_7_DAYS,
  TUTOR_CHURN_7_DAYS,
  TEN_MINUTES_BEFORE_CLASS,
}

export interface TutoreNotification {
  createdAt: string;
  notificationType: NotificationType;
  receiver: User;
  sender: User;
  seen: boolean;
}
