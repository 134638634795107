import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  svg;
  headerText;
  subHeaderText;
  buttonText;
  index;
};

export default function StudyPlanParts(props: Props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );
  useEffect(() => {
    analytics.sendStudyPlanIntermediate(props.headerText, props.index);
    window.scrollTo(0, 0);
    Dimensions.addEventListener("change", _handleAppStateChange);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  return (
    <View style={[StyleUtils.screen_default]}>
      <LinearGradient
        colors={["#011F42", "#016EE8"]}
        style={[
          {
            paddingVertical: 100,
          },
        ]}
      />
      <View
        style={{
          width: windowWidth,
          height: windowWidth,
          borderRadius: windowWidth / 2,
          backgroundColor: COLOR_TRUE_WHITE,
          transform: [{ scaleX: 2 }],
          marginTop: 80,
          position: "absolute",
        }}
      />
      <SVGImage
        source={<props.svg />}
        style={{
          alignSelf: "center",
          zIndex: 1,
          width: 317,
          height: 327,
          position: "absolute",
          top: 100,
        }}
      />
      <View
        style={{
          alignItems: "center",
          marginHorizontal: 20,
          marginTop: props.svg.includes("girlComputer") ? 190 : 250,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            { textAlign: "center", fontSize: windowWidth < 500 ? 20 : 28 },
          ]}
        >
          {props.headerText}
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_normal,
            { textAlign: "center", marginTop: 21 },
          ]}
        >
          {props.subHeaderText}
        </Text>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            StyleUtils.color_bg_white,
            {
              marginVertical: 28,
              width: 264,
              borderWidth: 1,
              borderColor: COLOR_PRIMARY,
            },
          ]}
          onPress={() => {
            props.onContinue();
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_primary,
              StyleUtils.font_size_16,
              { padding: 14, textAlign: "center" },
            ]}
          >
            {props.buttonText}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
