import React, { useState, createRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Linking,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import StyleUtils, { COLOR_WHITE, COLOR_MUTED } from "../Utils/StyleUtils";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  show;
  close;
  onSuccess;
};

export default function RequestWhatsappPopup(props: Props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const openKeyboard = createRef();

  useEffect(() => {
    analytics.sendShowRequestWhatsappPopup();
  }, []);

  const handlePhoneNumberChange = (number: string) => {
    setPhoneNumber(formatPhoneNumber(number));
  };

  const formatPhoneNumber = (newNumber: string) => {
    const onlyNumbers = newNumber
      .split("")
      .filter((char) => !isNaN(parseInt(char)))
      .join("");
    const numberCount = onlyNumbers.length;

    if (numberCount === 1) {
      return `(${onlyNumbers}`;
    } else if (numberCount >= 3 && numberCount <= 6) {
      const lastNumbers = onlyNumbers.slice(2);
      const intialNumbers = onlyNumbers.slice(0, 2);
      return `(${intialNumbers}) ${lastNumbers}`;
    } else if (numberCount >= 7 && numberCount <= 10) {
      const intialNumbers = onlyNumbers.slice(0, 2);
      const midNumbers = onlyNumbers.slice(2, 6);
      const lastNumbers = onlyNumbers.slice(6);
      return `(${intialNumbers}) ${midNumbers}-${lastNumbers}`;
    } else if (numberCount === 11) {
      const intialNumbers = onlyNumbers.slice(0, 2);
      const midNumbers = onlyNumbers.slice(2, 7);
      const lastNumbers = onlyNumbers.slice(7);
      return `(${intialNumbers}) ${midNumbers}-${lastNumbers}`;
    } else {
      return newNumber;
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <AnimatedPopUp show={props.show} bgColor={COLOR_WHITE}>
        <Text
          style={[
            {
              alignSelf: "center",
              textAlign: "center",
            },
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_20,
            StyleUtils.color_txt_dark,
          ]}
        >
          Precisamos do Whatsapp dos seus pais ou responsável para confirmar sua
          aula grátis:
        </Text>
        <View
          style={{
            flexDirection: "row",
            marginBottom: 16,
            alignSelf: "center",
          }}
        >
          <TextInput
            maxLength={15}
            keyboardType={"numeric"}
            editable={true}
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                fontSize: 32,
                marginHorizontal: 8,
                width: "83%",
                alignSelf: "center",
                marginTop: 8,
              },
            ]}
            value={phoneNumber}
            onChangeText={handlePhoneNumberChange}
            placeholder={"(00) 00000-0000"}
            placeholderTextColor={COLOR_MUTED}
            autoFocus={!!openKeyboard}
          />
        </View>
        <Text
          style={[
            {
              alignSelf: "center",
              textAlign: "left",
            },
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_normal,
          ]}
        >
          Ao clicar no botão abaixo, você concorda com os{" "}
          <Text
            style={{ textDecorationLine: "underline" }}
            onPress={() =>
              Linking.openURL("https://politicas.learning-machine.org/")
            }
          >
            termos de uso
          </Text>{" "}
          e de{" "}
          <Text
            style={{ textDecorationLine: "underline" }}
            onPress={() =>
              Linking.openURL("https://policy.learning-machine.org/")
            }
          >
            privacidade
          </Text>{" "}
          do Tutore e a receber mensagens e ligações do Tutore no número
          providenciado, inclusive para fins de marketing.
        </Text>
        <ButtonElement
          colorScheme="sucess"
          text="Validar Whatsapp"
          onPress={() => {
            analytics.sendValidateNumber();
            props.onSuccess(
              phoneNumber
                .replace(/[()]/g, "")
                .replace(/-/g, "")
                .replace(/\s/g, "")
            );
          }}
          textStyle={styles.popupButtonText}
          styte={[styles.popupButton, { marginTop: 32 }]}
          disabled={phoneNumber.length < 14}
        />
        <TouchableOpacity
          onPress={() => {
            analytics.sendCancelWhatsappPopup();
            props.close();
          }}
          style={{
            marginTop: 25,
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textDecorationLine: "underline",
                alignItems: "center",
                textAlign: "center",
              },
            ]}
          >
            Cancelar
          </Text>
        </TouchableOpacity>
      </AnimatedPopUp>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});
