import React, { useEffect, useRef } from "react";
import ReactModal from "react-modal";
import { Animated } from "react-native";
import { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";

type Props = {
  show?: boolean;
  close?: () => void;
  bgColor?: string;
  children?: any;
};

const AnimatedPopUp = (props: Props) => {
  let { show, close, bgColor, children } = props;
  bgColor = bgColor || COLOR_TRUE_WHITE;
  const state = {
    opacity: useRef(new Animated.Value(0)).current,
  };

  const openModal = () => {
    Animated.timing(state.opacity, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const closeModal = () => {
    Animated.timing(state.opacity, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    if (show) {
      openModal();
    } else {
      closeModal();
    }
  }, [show]);

  return (
    <ReactModal
      isOpen={!!show}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: "rgba(59,63,80,0.8)",
        },
        content: {
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(0,0,0,0)",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          height: "50%",
          minWidth: 300,
          transform: "translate(-50%, -50%)",
        },
      }}
      ariaHideApp={false}
    >
      <Animated.View
        style={[
          {
            backgroundColor: bgColor,
            borderRadius: 14,
            overflow: "hidden",
            alignItems: "center",
            padding: 32,
            width: 375,
            opacity: state.opacity,
          },
        ]}
      >
        {children}
      </Animated.View>
    </ReactModal>
  );
};

export default AnimatedPopUp;
