import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import StudyPlanBook from "../../assets/studyPlanBook.svg";
import StudyPlanPercentage from "../../assets/studyPlanPercentage.svg";
import GraphColorful from "../../assets/graphColorful.svg";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  age;
  schoolLevel;
  schoolYear;
  subjects;
  topics;
  hours;
  index;
};

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

export default function StudyPlanResult(props: Props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    Dimensions.addEventListener("change", _handleAppStateChange);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  const renderMobileLayout = () => {
    return (
      <View
        style={[
          StyleUtils.screen_default,
          { backgroundColor: COLOR_TRUE_WHITE },
        ]}
      >
        <LinearGradient
          colors={["#011F42", "#016EE8"]}
          style={[
            {
              paddingVertical: 100,
            },
          ]}
        />
        <View
          style={{
            width: windowWidth,
            height: windowWidth,
            borderRadius: windowWidth / 2,
            backgroundColor: COLOR_TRUE_WHITE,
            transform: [{ scaleX: 2 }],
            marginTop: 80,
            position: "absolute",
          }}
        />
        <SVGImage
          source={<StudyPlanBook />}
          style={{
            alignSelf: "center",
            zIndex: 1,
            width: 225,
            height: 134,
            position: "absolute",
            top: 100,
          }}
        />
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "center",
                fontSize: 20,
                marginTop: 100,
                marginBottom: 40,
                lineHeight: 28,
              },
            ]}
          >
            Plano de estudos para o estudante,{"\n"}
            {props.age}, {props.schoolYear}, {props.schoolLevel}:{"\n"}
            <B>Plano Reforço Escolar Tutore</B>
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "left",
                fontSize: 16,
                marginBottom: 40,
                lineHeight: 28,
                marginHorizontal: 40,
              },
            ]}
          >
            <B>Objetivo:</B> melhorar o desempenho de {props.subjects} para que
            o estudante consiga acompanhar a escola e se torne um aluno de
            destaque.
          </Text>
          <SVGImage
            source={<StudyPlanPercentage />}
            style={{
              zIndex: 1,
              width: 182,
              height: 155,
            }}
          />
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              {
                textAlign: "left",
                fontSize: 16,
                marginBottom: 40,
                marginHorizontal: 40,
                lineHeight: 28,
              },
            ]}
          >
            95% dos nossos estudantes com {props.age} conseguiram alcançar
            objetivos similares em menos de 6 meses.
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "left",
                fontSize: 16,
                marginBottom: 40,
                marginHorizontal: 40,
                lineHeight: 28,
              },
            ]}
          >
            Primeiro avaliaremos o nível de dificuldade do estudante nos tópicos
            com maior dificuldade
            {props.topics.length !== 0 && ": " + props.topics}.{"\n"}
            {"\n"}Em seguida, o professor irá montar um plano de estudos com o
            passo a passo do que o estudante precisa estudar, acompanhando todo
            o progresso do plano e enviando relatórios periódicos para os pais.
          </Text>
          <SVGImage
            source={<GraphColorful />}
            style={{
              zIndex: 1,
              width: 309,
              height: 211,
              marginHorizontal: 30,
              marginTop: -20,
            }}
          />
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "left",
                fontSize: 16,
                marginBottom: 40,
                marginHorizontal: 40,
                lineHeight: 28,
                marginTop: 20,
              },
            ]}
          >
            Nossa estimativa é que estudando{"\n"}
            {props.hours.charAt(0).toLowerCase() + props.hours.slice(1)} por
            semana, é possível melhorar o desempenho de {props.subjects} do
            estudante, com 2 aulas particulares por semana.
          </Text>
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.color_bg_primary,
              { marginTop: 28, width: 312, marginBottom: 100 },
            ]}
            onPress={() => {
              analytics.sendSeeRecommendedTutors(props.index);
              props.onContinue();
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
                { padding: 14, textAlign: "center" },
              ]}
            >
              Ver Professores Recomendados
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const renderDesktopLayout = () => {
    return (
      <View style={[StyleUtils.screen_default]}>
        <LinearGradient
          colors={["#011F42", "#016EE8"]}
          style={[
            {
              paddingVertical: 250,
            },
          ]}
        />
        <View
          style={{
            width: windowWidth,
            height: windowWidth,
            borderRadius: windowWidth / 2,
            backgroundColor: COLOR_TRUE_WHITE,
            transform: [{ scaleX: 2 }],
            marginTop: 80,
            position: "absolute",
          }}
        />
        <SVGImage
          source={<StudyPlanBook />}
          style={{
            alignSelf: "center",
            zIndex: 1,
            width: 317,
            height: 327,
            position: "absolute",
            top: 50,
          }}
        />
        <View
          style={{
            alignItems: "center",
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "center",
                fontSize: 24,
                marginTop: -180,
                marginBottom: 40,
                lineHeight: 28,
              },
            ]}
          >
            Plano de estudos para o estudante, {props.age},{"\n"}{" "}
            {props.schoolYear}, {props.schoolLevel}:{" "}
            <B>Plano Reforço Escolar Tutore</B>
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                textAlign: "left",
                fontSize: 16,
                marginBottom: 40,
                lineHeight: 28,
              },
            ]}
          >
            <B>Objetivo:</B> melhorar o desempenho de {props.subjects} para que
            o estudante
            {"\n"}consiga acompanhar a escola e se torne um aluno de destaque.
          </Text>
          <View style={{ flexDirection: "row" }}>
            <SVGImage
              source={<StudyPlanPercentage />}
              style={{
                zIndex: 1,
                width: 317,
                height: 327,
              }}
            />
            <View style={{ flexDirection: "column" }}>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  {
                    textAlign: "left",
                    fontSize: 16,
                    marginBottom: 40,
                    marginLeft: 40,
                    lineHeight: 28,
                  },
                ]}
              >
                95% dos nossos estudantes com {props.age} {"\n"}conseguiram
                alcançar objetivos similares em {"\n"}menos de 6 meses.
              </Text>
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.color_txt_dark,
                  {
                    textAlign: "left",
                    fontSize: 16,
                    marginBottom: 40,
                    marginLeft: 40,
                    lineHeight: 28,
                  },
                ]}
              >
                Primeiro avaliaremos o nível de dificuldade {"\n"} do estudante
                nos tópicos com maior {"\n"}dificuldade
                {props.topics.length !== 0 && ": " + props.topics}. {"\n"}
                Em seguida, o professor irá montar um plano {"\n"}de estudos com
                o passo a passo do que{"\n"} o estudante precisa estudar,
                acompanhando todo {"\n"}o progresso do plano e enviando
                relatórios {"\n"}
                periódicos para os pais.
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_dark,
                {
                  textAlign: "left",
                  fontSize: 16,
                  marginBottom: 40,
                  marginLeft: 40,
                  lineHeight: 28,
                },
              ]}
            >
              Nossa estimativa é que estudando{"\n"}
              {props.hours.charAt(0).toLowerCase() + props.hours.slice(1)}{" "}
              {"\n"}por semana, é possível melhorar o {"\n"}desempenho de{" "}
              {props.subjects} {"\n"}do estudante com 4 aulas particulares{" "}
              {"\n"}dentro do prazo informado.
            </Text>
            <SVGImage
              source={<GraphColorful />}
              style={{
                zIndex: 1,
                width: 317,
                height: 327,
                marginLeft: 40,
                marginTop: -60,
              }}
            />
          </View>
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.color_bg_primary,
              { marginTop: 28, width: 372, marginBottom: 100 },
            ]}
            onPress={() => {
              analytics.sendSeeRecommendedTutors(props.index);
              props.onContinue();
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
                { padding: 14, textAlign: "center" },
              ]}
            >
              Ver Professores Recomendados
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return windowWidth < 500 ? renderMobileLayout() : renderDesktopLayout();
}
