import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  ScrollView,
  Alert,
  Linking,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "../../assets/backButtonBorder.svg";
import { bookingInfo, setTuteeInfo } from "../Actions";
import { UserImage } from "../Elements/UserImages";
import { Tutor } from "../Models/TutorInfo";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_DARK,
  COLOR_SUCCESS,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import TuteeInfo from "../Models/TuteeInfo";
import { analytics } from "../Analytics";
import CheckSuccess from "../../assets/checkSuccess.svg";
import CheckGreen from "../../assets/checkGreen.svg";
import * as Utils from "../Utils/Utils";
import { CLASS_MINUTES } from "../Booking/Booking";
import PaymentOutcomeLayout from "../Payment/PaymentOutcomeLayout";
import {
  bookTutor,
  chooseTutor,
  requestPurchaseSubscription,
  getUserCredit,
  consumeTicket,
} from "../Server";
import IAP from "react-native-iap";
import Loader from "../Elements/Loader";
import _ from "lodash";

type Props = {
  history;
  lessonInfo: { selectedDate; selectedTutor: Tutor; endDate };
  selectedTutor: Tutor;
  dispatch;
  userID: string;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  mobile;
  location;
  onBack?;
  onBookingFinished?;
  subject?;
  subjectLevel?;
  bookingInfo;
};

function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
    userID: state.userRed.user.id,
    lessonInfo: state.selRed.lessonInfo,
    mobile: state.layoutRed.mobile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingInfo: (event) => dispatch(bookingInfo(event)),
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch: dispatch,
  };
}

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

const subscriptionID =
  Platform.OS === "ios"
    ? "subs_1un_credito_week_7daysfree_40reais"
    : "subs_1un_credito_week_7daysfree_40reais_google";

const SubscriptionConfirmationPage = (props: Props) => {
  const [purchaseSuccess, setPurchaseSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [calledOnce, setCalledOnce] = useState(false);

  useEffect(() => {
    initializeIAPConnection();
  }, []);

  const fetchAndConsumeUserCredits = () => {
    getUserCredit(props.userID).then((res) => {
      console.log(res.amount);
      const ticket = {
        tickets: [res.tickets[0].id],
        userId: props.userID,
        tutorId: props.selectedTutor.id,
      };
      consumeTicket(ticket);
      console.log("consumeTicket");
    });
  };

  const initializeIAPConnection = async () => {
    await IAP.initConnection()
      .then(async (connection) => {
        IAP.getSubscriptions([subscriptionID]).then((res) => {});
        console.log("connected iap");
      })
      .catch((err) => {
        console.warn(`IAP ERROR ${err.code}`, err.message);
      });
  };

  useEffect(() => {
    const source = _.get(props, "location.state.source", null);
    let subject = "";
    let subjectLevel = "";
    if (
      props.location &&
      props.location.state &&
      props.location.state.subject
    ) {
      subject = props.location.state.subject;
      subjectLevel = props.location.state.subjectLevel;
    }

    const purchaseUpdateSubscription = IAP.purchaseUpdatedListener(
      async (purchase) => {
        if (purchase) {
          const receipt = purchase.transactionReceipt;
          if (receipt)
            try {
              setCalledOnce(true);
              if (Platform.OS === "ios" && purchase.transactionId) {
                let transactionId = purchase.transactionId;
                IAP.finishTransactionIOS(purchase.transactionId);
                IAP.finishTransaction(purchase)
                  .then(() => {
                    requestPurchaseSubscription(
                      "apple",
                      receipt,
                      props.userID,
                      purchase.productId,
                      transactionId
                    )
                      .then((response) => {
                        if (response.error) {
                          console.log(response);
                          Alert.alert(
                            "Compra inválida. Entre em contato com o nosso suporte."
                          );
                          analytics.sendSubscriptionPurchaseFailed();
                          setLoadingPayment(false);
                        } else {
                          analytics.sendSubscriptionPurchaseSuccess();
                          fetchAndConsumeUserCredits();
                          bookTutor(
                            props.userID,
                            props.selectedTutor.id,
                            props.lessonInfo.selectedDate,
                            props.lessonInfo.endDate,
                            subject,
                            subjectLevel,
                            source
                          )
                            .then(() => {
                              setLoadingPayment(false);
                              setPurchaseSuccess(true);
                              return chooseTutor(
                                props.userID,
                                props.selectedTutor.email
                              );
                            })
                            .then((tuteeInfo) => {
                              props.setTuteeInfo(tuteeInfo);
                            });
                        }
                      })
                      .catch((err) => {
                        setLoadingPayment(false);
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    Alert.alert("Erro na compra");
                    console.log(err.code, err.message);
                    setLoadingPayment(false);
                    analytics.sendSubscriptionPurchaseFailed();
                  });
              } else if (
                Platform.OS === "android" &&
                purchase.purchaseToken &&
                purchase.transactionId &&
                !calledOnce
              ) {
                setCalledOnce(true);
                let response = await requestPurchaseSubscription(
                  "google",
                  purchase.purchaseToken,
                  props.userID,
                  purchase.productId,
                  purchase.transactionId
                );
                if (!response.error) {
                  analytics.sendSubscriptionPurchaseSuccess();
                  if (purchase.purchaseToken) {
                    await IAP.acknowledgePurchaseAndroid(
                      purchase.purchaseToken
                    );
                    await IAP.finishTransaction(purchase, false);
                    console.log("finish transaction");
                    fetchAndConsumeUserCredits();
                    bookTutor(
                      props.userID,
                      props.selectedTutor.id,
                      props.lessonInfo.selectedDate,
                      props.lessonInfo.endDate,
                      subject,
                      subjectLevel,
                      source
                    )
                      .then(() => {
                        setLoadingPayment(false);
                        setPurchaseSuccess(true);
                        return chooseTutor(
                          props.userID,
                          props.selectedTutor.email
                        );
                      })
                      .then((tuteeInfo) => {
                        props.setTuteeInfo(tuteeInfo);
                      });
                  }
                }
                setLoadingPayment(false);
              }
            } catch (ackErr) {
              console.warn("ackErr", ackErr);
              setLoadingPayment(false);
              analytics.sendSubscriptionPurchaseFailed();
            }
        }
      }
    );
    const purchaseErrorSubscription = IAP.purchaseErrorListener((error) => {
      console.log("purchaseErrorListener INAPP>>>>", error);
      setLoadingPayment(false);
    });
    return () => {
      if (purchaseUpdateSubscription) {
        purchaseUpdateSubscription.remove();
      }
      if (purchaseErrorSubscription) {
        purchaseErrorSubscription.remove();
      }
    };
  }, []);

  const handleConfirmFreeSession = async () => {
    analytics.sendClassScheduled();
    setLoadingPayment(true);
    await IAP.requestSubscription(subscriptionID)
      .then(() => {
        setLoadingPayment(true);
      })
      .catch(() => {
        setLoadingPayment(false);
        Alert.alert("Assinatura não concluída");
        analytics.sendSubscriptionPurchaseFailed();
      });
  };

  const handleCompleteSessionPress = () => {
    const source = _.get(props, "location.state.source", null);
    analytics.sendScheduleFullSession();
    let endDate = new Date(props.lessonInfo.selectedDate);
    const event = {
      ...props.lessonInfo,
      endDate: endDate.setMinutes(endDate.getMinutes() + CLASS_MINUTES),
    };
    props.bookingInfo(event);
    if (
      props.location &&
      props.location.state &&
      props.location.state.subject
    ) {
      props.history.push({
        pathname: "/ConfirmationPage",
        state: {
          subject: props.location.state.subject,
          subjectLevel: props.location.state.subjectLevel,
          source: source,
        },
      });
    } else {
      props.history.push({
        pathname: "/ConfirmationPage",
        state: {
          source: source,
        },
      });
    }
  };

  const fullClassEndDate = new Date(props.lessonInfo.selectedDate).setMinutes(
    props.lessonInfo.selectedDate.getMinutes() + CLASS_MINUTES
  );

  if (purchaseSuccess !== undefined) {
    let subject = "";
    let subjectLevel = "";
    if (
      props.location &&
      props.location.state &&
      props.location.state.subject
    ) {
      subject = props.location.state.subject;
      subjectLevel = props.location.state.subjectLevel;
    }
    return (
      <PaymentOutcomeLayout
        tutorImage={props.selectedTutor.imageURL}
        lessonDate={props.lessonInfo.selectedDate}
        lessonEndDate={props.lessonInfo.endDate}
        tutorName={Utils.userShortName(props.selectedTutor.name)}
        history={props.history}
        classroomEnd={false}
        tutorID={props.selectedTutor.id}
        numRecurringClass={1}
        subject={subject}
        subjectLevel={subjectLevel}
      />
    );
  }

  return (
    <ScrollView style={[StyleUtils.screen_default]}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: 16,
        }}
      >
        <Loader loading={loadingPayment} />
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={() => {
              props.history.replace("/booking");
            }}
          >
            <SVGImage source={<BackButton />} />
          </TouchableOpacity>
        </View>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_16,
          ]}
        >
          Confirmar Mentoria
        </Text>
        <View style={{ flex: 1 }}></View>
      </View>
      <View
        style={{
          borderBottomColor: COLOR_MUTED,
          borderBottomWidth: 1,
        }}
      />
      <View>
        <View style={{ flexDirection: "row" }}>
          <UserImage
            style={{
              marginLeft: 24,
              marginRight: 16,
              marginVertical: 24,
              width: 64,
              height: 74,
              borderRadius: 6,
            }}
            imageURL={props.selectedTutor.imageURL}
          />
          <View style={{ flex: 1, marginTop: 18 }}>
            <Text
              style={[
                styles.textBoldBlack,
                {
                  paddingTop: 4,
                  marginBottom: 8,
                },
              ]}
            >
              {Utils.userShortName(props.selectedTutor.name)}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  color: COLOR_TXT_DARK,
                },
              ]}
            >
              {`${Utils.dateFormatLongDateNoYear(
                new Date(props.lessonInfo.selectedDate)
              )}`}
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                {
                  textDecorationStyle: "solid",
                  color: COLOR_TXT_DARK,
                },
              ]}
            >
              {`Horário: ${Utils.dateFormatHHhmm(
                new Date(props.lessonInfo.selectedDate)
              )}-${Utils.dateFormatHHhmm(new Date(fullClassEndDate))}`}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 20 }}>
          <View
            style={[
              styles.cellBigBase,
              styles.cellSelected,
              { backgroundColor: "#E8F9EE" },
            ]}
          >
            <View
              style={{
                height: "8%",
                marginHorizontal: 14,
                aspectRatio: 1,
                position: "absolute",
                top: 25,
              }}
            >
              <SVGImage source={<CheckGreen />} />
            </View>

            <View style={{ marginTop: 10, width: "100%" }}>
              <View style={{ flex: 1, marginLeft: 45 }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={[styles.textBoldBlack, { marginBottom: -4 }]}>
                    Plano VIP Aulas Particulares
                  </Text>
                  <Text
                    style={[
                      styles.textRegularBlack,
                      {
                        textDecorationLine: "line-through",
                        position: "absolute",
                        right: 0,
                      },
                    ]}
                  >
                    R$49,90
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={[styles.textRegularBlack]}>
                    +7 dias de acesso grátis{" "}
                  </Text>
                  <Text
                    style={[
                      styles.textRegularBlack,
                      {
                        position: "absolute",
                        right: 0,
                      },
                    ]}
                  >
                    R$39,90
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  marginTop: -110,
                  backgroundColor: "#DBF4E4",
                  borderRadius: 6,
                }}
              >
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                  <SVGImage
                    source={<CheckSuccess />}
                    style={{ marginTop: 10, marginHorizontal: 12 }}
                  />
                  <Text
                    style={[styles.textRegularBlackSmall, { paddingRight: 60 }]}
                  >
                    <B>Primeira aula de graça</B> para conhecer o app, sem
                    compromisso
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SVGImage
                    source={<CheckSuccess />}
                    style={{ marginTop: 10, marginHorizontal: 12 }}
                  />
                  <Text
                    style={[styles.textRegularBlackSmall, { paddingRight: 60 }]}
                  >
                    <B>1 aula por semana inclusa</B> para aprender qualquer
                    matéria que quiser
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SVGImage
                    source={<CheckSuccess />}
                    style={{ marginTop: 10, marginHorizontal: 12 }}
                  />
                  <Text
                    style={[styles.textRegularBlackSmall, { paddingRight: 60 }]}
                  >
                    <B>10% de desconto em todo o app </B>para marcar mais aulas
                    ou mentorias
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Text
            style={[
              styles.textRegularBlackSmall,
              {
                marginHorizontal: 24,
                marginVertical: 16,
                textAlign: "center",
              },
            ]}
          >
            Após o periodo grátis, R$39,90/semana.{"\n"}Cancele quando quiser.
          </Text>
          <TouchableOpacity
            style={[
              {
                margin: 24,
                backgroundColor: COLOR_SUCCESS,
                marginTop: 40,
              },
              StyleUtils.shape_btn_small,
            ]}
            onPress={handleConfirmFreeSession}
          >
            <Text
              style={[
                { textAlign: "center", paddingVertical: 14 },
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                StyleUtils.color_txt_white,
              ]}
            >
              Confirmar Sessão Grátis e Assinar
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleCompleteSessionPress}
            style={{ alignSelf: "center", marginBottom: 27 }}
          >
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_medium,
                StyleUtils.color_txt_dark,
                { textDecorationLine: "underline" },
              ]}
            >
              Agendar sessão paga
            </Text>
          </TouchableOpacity>
          <Text
            style={[
              StyleUtils.font_size_12,
              StyleUtils.font_cera_medium,
              StyleUtils.color_txt_dark,
              {
                textDecorationLine: "underline",
                textAlign: "center",
              },
            ]}
            onPress={() =>
              Linking.openURL("https://politicas.learning-machine.org/")
            }
          >
            Termos de Uso e Política de Privacidade
          </Text>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  textRegularBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
    paddingRight: 6,
  },
  textBoldBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
  },
  cellSelected: {
    borderColor: COLOR_SUCCESS,
    borderWidth: 2,
    backgroundColor: "#DBF4E4",
  },
  cellBigBase: {
    height: 255,
    borderRadius: 6,
    flexDirection: "row",
    marginHorizontal: 24,
  },
  textRegularBlackSmall: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
    marginBottom: 12,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionConfirmationPage);
