import React from "react";
import { View, TouchableOpacity } from "react-native";
import BackArrow from "../../assets/chevronLeftWhite.svg";
import SVGImage from "../Utils/SVGImage";

type Props = {
  onBack;
};

export default function BackButtonMobile(props: Props) {
  return (
    <View style={{ position: "absolute", left: 32, top: 32, zIndex: 1 }}>
      <TouchableOpacity
        style={{ flexDirection: "row" }}
        onPress={() => { props.onBack() }}
      >
        <SVGImage source={<BackArrow />} />
      </TouchableOpacity>
    </View>
  );
}
