import React, { Component } from "react";
import { View } from "react-native";
import WebView from "react-native-webview";

type Props = {
  scrollEnabled;
  onMessage;
  source;
  keyboardDisplayRequiresUserAction;
};

export default class OneWebView extends Component<Props, any> {
  componentDidMount() {
    window.addEventListener("message", this.handleIframeTask);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleIframeTask);
  }

  injectJavaScript = (obj) => {
    const iframe: any = document.getElementById("mathInput");
    console.log(iframe);
    iframe.contentWindow.postMessage(obj, this.props.source);
  };

  handleIframeTask = (e) => {
    if (e.origin !== this.props.source) {
      return;
    }
    try {
      let data = JSON.parse(e.data);
      if (this.props.onMessage) {
        this.props.onMessage(data);
      }
    } catch (e) {}
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <iframe
          style={{ borderWidth: 0 }}
          id={"mathInput"}
          src={this.props.source}
          height={"100%"}
          width={"100%"}
        />
      </View>
    );
  }
}
