import React, { Component } from "react";
import {
  Animated,
  Easing,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native";
import SignUpPopUp from "../../SignUp/SignUpPopUp";
import StyleUtils from "../../Utils/StyleUtils";

import { analytics } from "../../Analytics";
import SVGImage from "../../Utils/SVGImage";
import Logo from "../../../assets/logoWhiteNewBig.svg";
import WhatsIcon from "../../../assets/whatsappIcon.svg";
import VideoComponent from "../../Elements/VideoElement";
import RemoteConfigBridge from "../../RemoteConfig/RemoteConfigBridge";

type Props = {
  onContinue;
  onContinueProf;
  onLogin;
  continueButtonText;
  continueProfButtonText;
  headerText;
  isWhatsappOnboarding?;
  isLowFrictionOnboarding?;
  history;
};

type State = {
  enableLogin;
  showLogin;
};

const { width, height } = Dimensions.get("window");

export default class VideoOnboarding extends Component<Props, State> {
  fadeAnim = new Animated.Value(0);
  constructor(props) {
    super(props);
    this.state = {
      enableLogin: false,
      showLogin: false,
    };
    RemoteConfigBridge.remoteConfig("enableLogin", (value) => {
      var valueBool = value == "1";
      this.setState({ enableLogin: valueBool });
    });
  }
  componentDidMount() {
    setTimeout(() => {
      this.fadeIn();
    }, 2000);
  }

  fadeIn = () => {
    Animated.timing(this.fadeAnim, {
      toValue: 1,
      duration: 300,
      easing: Easing.ease,
      useNativeDriver: true,
    }).start();
  };

  onLoginSuccessPopUp = () => {
    this.props.history.push("/");
  };
  onSignUpSuccessPopUp = () => {
    this.props.history.push("/");
  };

  render() {
    const AnimatedTouchable = Animated.createAnimatedComponent(
      TouchableOpacity
    );
    return (
      <View
        style={{ flexDirection: "column", flex: 1, backgroundColor: "#10345c" }}
      >
        <VideoComponent
          source={require("../../../assets/backgroundOnboardingVideo.mp4")}
          style={styles.backgroundVideo}
          muted={true}
          repeat={true}
          resizeMode={"cover"}
          rate={1.0}
          ignoreSilentSwitch={"obey"}
        />
        <View style={{ marginTop: 40, alignItems: "center", flex: 1 }}>
          <SVGImage source={<Logo />}></SVGImage>
          {this.state.enableLogin && (
            <TouchableOpacity
              style={[
                StyleUtils.shape_btn_default_rounded,
                StyleUtils.border_color_txt_white,
                {
                  alignSelf: "flex-end",
                  borderRadius: 6,
                  overflow: "hidden",
                  margin: 16,
                  marginTop: -30,
                },
              ]}
              onPress={() => {
                analytics.sendOnboardingLogin();
                setTimeout(() => {
                  this.setState({ showLogin: true });
                }, 300);
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_14,
                  {
                    paddingTop: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: 6,
                  },
                ]}
              >
                Login
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={{ padding: 32 }}>
          <Text
            style={[
              StyleUtils.font_size_26,
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_white,
              {
                alignSelf: "center",
                textAlign: "center",
                position: "absolute",
                bottom: 122,
              },
            ]}
          >
            {this.props.headerText}
          </Text>
          {this.props.isLowFrictionOnboarding && (
            <AnimatedTouchable
              style={[
                StyleUtils.color_bg_white,
                {
                  opacity: this.fadeAnim,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignSelf: "center",
                  borderRadius: 6,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 50,
                  width: "100%",
                },
              ]}
              onPressOut={() => {
                this.props.onContinue();
              }}
            >
              <View>
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_cera_bold,
                    StyleUtils.font_size_16,
                    {
                      padding: 16,
                      paddingRight: this.props.isWhatsappOnboarding ? 8 : 16,
                      paddingBottom: 12,
                      alignSelf: "center",
                    },
                  ]}
                >
                  {this.props.continueButtonText}
                </Text>
              </View>
            </AnimatedTouchable>
          )}
          {!this.props.isLowFrictionOnboarding && (
            <>
              <TouchableOpacity
                style={[
                  StyleUtils.color_bg_white,
                  {
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignSelf: "center",
                    borderRadius: 6,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 90,
                    width: "100%",
                  },
                ]}
                onPress={() => {
                  this.props.onContinue();
                  analytics.sendTalkToTutorWhatsapp();
                }}
              >
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_cera_bold,
                    StyleUtils.font_size_16,
                    {
                      padding: 16,
                      paddingRight: this.props.isWhatsappOnboarding ? 8 : 16,
                      paddingBottom: 12,
                      alignSelf: "center",
                    },
                  ]}
                >
                  {this.props.continueButtonText}
                </Text>

                {this.props.isWhatsappOnboarding && (
                  <SVGImage source={<WhatsIcon />} />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  position: "absolute",
                  bottom: 40,
                  alignSelf: "center",
                }}
                onPress={() => {
                  this.props.onContinueProf();
                  analytics.sendLookForTutorWhatsapp();
                }}
              >
                <Text
                  style={[
                    StyleUtils.color_txt_white,
                    StyleUtils.font_cera_regular,
                    StyleUtils.font_size_16,
                    {
                      alignSelf: "center",
                      fontWeight: "500",
                      textDecorationLine: "underline",
                    },
                  ]}
                >
                  {this.props.continueProfButtonText}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
        <SignUpPopUp
          show={this.state.showLogin}
          isLogin={true}
          onLoginSuccess={this.onLoginSuccessPopUp}
          onSignUpSuccess={this.onSignUpSuccessPopUp}
          close={() => {
            this.setState({ showLogin: false });
          }}
          history={this.props.history}
          isExerciseOnboarding={false}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backgroundVideo: {
    height: height,
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "stretch",
    bottom: 0,
    right: 0,
  },
});
