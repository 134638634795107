import { Text, View } from "react-native";
import StyleUtils, { COLOR_TXT_DARK } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import React from "react";

export const SignUpButtonStyle = (props) => {
  return (
    <View
      style={{
        height: 40,
        width: 312,
        borderWidth: 1,
        borderColor: COLOR_TXT_DARK,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
        marginBottom: 16,
      }}
    >
      <SVGImage
        style={{ position: "absolute", left: "5%" }}
        source={props.icon}
      ></SVGImage>

      <Text
        style={[
          { textAlign: "center" },
          StyleUtils.font_size_14,
          StyleUtils.font_cera_regular,
        ]}
      >
        {props.text}
      </Text>
    </View>
  );
};
