import React from "react";
import { connect } from "react-redux";
import { Image, Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ProfileImageBackground from "../../assets/profileImageBackground.svg";

type Props = {
  currentUser;
  selectedUser;
  history;
  tutorInfo;
};

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    selectedUser: state.selRed.selectedUser,
    tutorInfo: state.userRed.tutorInfo,
  };
};

const dispatchToProps = (dispatch) => ({});

const ClassroomEnd = (props) => {
  return (
    <View style={[StyleUtils.screen_default, { justifyContent: "center" }]}>
      <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
        <View
          style={{
            width: "70%",
            height: "40%",
            margin: "15%",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SVGImage source={<ProfileImageBackground />} />
          <Image
            source={
              !!props.selectedUser && !!props.selectedUser.imageURL
                ? {
                    uri: props.selectedUser.imageURL,
                  }
                : require("../../assets/userPlaceHolder.png")
            }
            style={{
              width: 120,
              height: 120,
              position: "absolute",
              borderRadius: 10000,
              overflow: "hidden",
            }}
          />
        </View>

        <View>
          <Text
            style={[
              { textAlign: "center" },
              StyleUtils.color_txt_dark,
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_20,
            ]}
          >
            Você concluiu sua aula
          </Text>

          <Text
            style={[
              { textAlign: "center", margin: "12%", marginTop: "2%" },
              StyleUtils.color_txt_light,
              StyleUtils.font_size_16,
            ]}
          >
            Veja os horários disponíveis do seu tutor e agende sua próxima aula
          </Text>
        </View>

        <View>
          <TouchableOpacity
            style={[
              { margin: "9%" },
              StyleUtils.color_bg_primary,
              StyleUtils.shape_btn_default_rounded,
            ]}
            onPress={() => {
              // props.history.push("/classroom/rating");
              props.history.push("/classroom/airbnbrating");
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 16 },
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
              ]}
            >
              Próximo
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default connect(stateToProps, dispatchToProps)(ClassroomEnd);
