import React, { Component } from "react";
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { connect } from "react-redux";
import { analytics } from "../Analytics";
import RatingStars from "../Rating/RatingStars";
import * as Server from "../Server";
import StyleUtils, { COLOR_TXT_LIGHT } from "../Utils/StyleUtils";

type Props = {
  currentUser;
  selectedUser;
  history;
  tutorInfo;
};

type State = {
  comment: string;
  rating;
  numberOfStars: number;
};

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    selectedUser: state.selRed.selectedUser,
    tutorInfo: state.userRed.tutorInfo,
  };
};

const dispatchToProps = (dispatch) => ({});

class ClassroomRating extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      numberOfStars: 0,
      rating: null,
      comment: "",
    };
  }

  componentDidMount() {
    analytics.sendTutorRatingOpened();
  }

  setRating = (rating) => {
    console.log("passou");
    this.setState({ rating: rating });
  };

  render() {
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          Keyboard.dismiss();
        }}
      >
        <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          style={[StyleUtils.screen_default, { justifyContent: "center" }]}
        >
          <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
            <Text
              style={[
                { textAlign: "center", margin: "5%" },
                StyleUtils.color_txt_dark,
                StyleUtils.font_btn_bold,
                StyleUtils.font_size_20,
              ]}
            >
              Como foi sua aula ?
            </Text>
            <View
              style={{
                width: 105,
                height: 105,
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={
                  !!this.props.selectedUser &&
                  !!this.props.selectedUser.imageURL
                    ? {
                        uri: this.props.selectedUser.imageURL,
                      }
                    : require("../../assets/userPlaceHolder.png")
                }
                style={{
                  width: 100,
                  height: 100,
                  position: "absolute",
                  borderRadius: 16,
                  overflow: "hidden",
                }}
              />
            </View>

            <RatingStars setRating={this.setRating} numberOfStars={5} />

            <View
              style={[
                {
                  margin: "10%",
                  padding: 1,
                  alignItems: "center",
                },
              ]}
            >
              <Text
                style={[
                  {
                    padding: 1,
                    margin: "5%",
                  },
                  StyleUtils.font_size_16,
                  StyleUtils.font_btn_regular,
                  StyleUtils.color_txt_light,
                  { textAlign: "center" },
                ]}
              >
                Se preferir, deixe um comentário:
              </Text>

              <View
                style={[
                  StyleUtils.border_color_btn_muted,
                  {
                    flexDirection: "row",
                    width: "100%",
                    borderRadius: 10,
                    height: 136,
                    padding: 1,
                    alignItems: "center",
                  },
                ]}
              >
                <TextInput
                  style={[
                    StyleUtils.color_txt_normal,
                    {
                      borderRadius: 10,
                      padding: 12,
                      width: "100%",
                      height: "100%",
                      textAlignVertical: "top",
                    },
                  ]}
                  multiline={true}
                  onChangeText={(text) => this.setState({ comment: text })}
                  placeholderTextColor={COLOR_TXT_LIGHT}
                  maxLength={255}
                />
              </View>
            </View>

            <TouchableOpacity
              style={[
                { marginTop: 0, margin: "10%", borderRadius: 6 },
                StyleUtils.color_bg_primary,
              ]}
              onPress={() => {
                if (this.props.selectedUser) {
                  if (this.state.rating) {
                    Server.postRating(
                      this.props.selectedUser.id,
                      this.props.currentUser.id,
                      this.state.rating,
                      this.state.comment
                    )
                      .then(() => this.props.history.push("/"))
                      .catch();
                  } else {
                    this.props.history.push("/");
                  }
                }
              }}
            >
              <Text
                style={[
                  { textAlign: "center", margin: 16 },
                  StyleUtils.font_btn_bold,
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_16,
                ]}
              >
                Finalizar
              </Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(ClassroomRating);
