import React, { Component } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE } from "../../Utils/StyleUtils";
import { LinearGradient } from "../../Utils/LinearGradient";
import SVGImage from "../../Utils/SVGImage";
import ReadyCircles from "../../../assets/readyCircles.svg";
import UserGray from "../../../assets/userGray.svg";

type Props = {
  onContinue;
  tutor;
};

export default class SpcTutorFound extends Component<Props, any> {
  abbreviatedTutorName() {
    let abbreviatedName = "Professor";
    const names = this.props.tutor.name.split(" ");

    if (names.length == 0) {
      return abbreviatedName;
    }

    abbreviatedName = names[0];
    if (names.length > 1) {
      abbreviatedName += " " + names[names.length - 1][0] + ".";
    }
    return abbreviatedName;
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <LinearGradient
          colors={["#0179FF", "#18BAFF"]}
          style={{ flexDirection: "column", flex: 1 }}
        >
          <View
            style={{
              height: "62%",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <LinearGradient
              start={{ x: 1.0, y: 0.0 }}
              end={{ x: 0.0, y: 1.0 }}
              colors={["#0055B5", "rgba(1, 121, 255, 0)"]}
              locations={[0.0453, 0.5993]}
              style={{
                position: "absolute",
                top: -10,
                left: -10,
                right: -10,
                bottom: 0,
                height: "100%",
                borderRadius: 10000,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  position: "absolute",
                  top: "23%",
                  bottom: "60%",
                  left: "15%",
                  right: "15%",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: 10,
                }}
              />
              <View
                style={{
                  position: "absolute",
                  top: "20%",
                  bottom: "60%",
                  left: "20%",
                  right: "20%",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: 10,
                }}
              />
              <View style={{ flex: 1 }} />
              <View
                style={{
                  flexDirection: "row",
                  borderRadius: 10,
                  backgroundColor: COLOR_TRUE_WHITE,
                  width: "80%",
                  aspectRatio: 1.88,
                  overflow: "hidden",
                }}
              >
                <View style={{ flex: 0.45, height: "100%" }}>
                  {!!this.props.tutor.imageURL && (
                    <Image
                      source={{
                        uri: this.props.tutor.imageURL,
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                  {!this.props.tutor.imageURL && (
                    <SVGImage source={<UserGray />} />
                  )}
                </View>
                <View
                  style={{
                    flex: 0.55,
                    height: "100%",
                  }}
                >
                  <Text
                    style={[
                      StyleUtils.font_cera_bold,
                      StyleUtils.font_size_16,
                      StyleUtils.color_txt_dark,
                      { marginTop: 30, marginLeft: 12 },
                    ]}
                  >
                    Oi, tudo bem?
                  </Text>
                  <Text
                    style={[
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_14,
                      StyleUtils.color_txt_normal,
                      {
                        marginTop: 10,
                        marginLeft: 12,
                        marginRight: 12,
                      },
                    ]}
                  >
                    Venha estudar Matemática, Inglês e Física! Metodologia única
                    baseada na sua necessidade.
                  </Text>
                </View>
              </View>

              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_18,
                  { flex: 1, paddingTop: 20, maxWidth: "80%" },
                ]}
              >
                {this.abbreviatedTutorName()}
              </Text>
            </LinearGradient>
          </View>
          <View
            style={{ position: "absolute", left: 32, right: 32, bottom: 50 }}
          >
            <Text
              style={[
                StyleUtils.font_size_22,
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                {
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                },
              ]}
            >
              Professor encontrado
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_white,
                {
                  alignSelf: "center",
                  textAlign: "center",
                  lineHeight: 18,
                  marginBottom: 40,
                },
              ]}
            >
              Agora você está conectado com seu professor e já pode agendar suas
              aulas
            </Text>
            <TouchableOpacity
              style={[
                StyleUtils.color_bg_white,
                {
                  alignSelf: "center",
                  borderRadius: 6,
                  width: "100%",
                },
              ]}
              onPress={() => {
                this.props.onContinue();
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_18,
                  {
                    padding: 16,
                    paddingTop: 16,
                    paddingBottom: 12,
                    alignSelf: "center",
                  },
                ]}
              >
                Visualizar Perfil
              </Text>
            </TouchableOpacity>
          </View>
        </LinearGradient>
      </View>
    );
  }
}
