import React from "react";
import { Component } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import DotWhite from "../../assets/dotWhite.svg";
import DotBlue from "../../assets/dotBlue.svg";

type Props = {
  date: Date;
  refMonth: Date;
  selected: boolean;
  enabled: boolean;
  hasEvent: boolean;
  select: (date: Date) => void;
};

class CalendarDay extends Component<Props, any> {
  isSameMonth = () => {
    return (
      this.props.date.getMonth() == this.props.refMonth.getMonth() &&
      this.props.date.getFullYear() == this.props.refMonth.getFullYear()
    );
  };

  dateStyle = () => {
    if (!this.isSameMonth()) return [StyleUtils.color_txt_muted];
    if (this.props.selected) return [StyleUtils.color_txt_white];
    if (this.props.enabled) return [StyleUtils.color_txt_primary];
    return [StyleUtils.color_txt_normal];
  };

  dateHolderStyle = () => {
    if (!this.isSameMonth()) return [];
    if (this.props.selected)
      return [
        StyleUtils.color_bg_primary,
        StyleUtils.shape_btn_default_rounded,
      ];
    if (this.props.enabled)
      return [
        StyleUtils.color_bg_primarylight,
        StyleUtils.shape_btn_default_rounded,
      ];
    return [];
  };

  render() {
    return (
      <TouchableOpacity
        style={[
          {
            width: "14.2%",
            height: 34,
            alignItems: "center",
            paddingTop: 5,
            paddingBottom: 5,
            marginTop: 5,
            marginBottom: 5,
          },
          ...this.dateHolderStyle(),
        ]}
        disabled={!this.isSameMonth()}
        onPress={() => {
          this.props.select(this.props.date);
        }}
        key={this.props.date.toString()}
      >
        <View style={[{ flex: 1 }]}>
          <Text style={this.dateStyle()}>{this.props.date.getDate()}</Text>
        </View>
        <View style={{ height: 4, width: 4 }}>
          {this.props.hasEvent && <Dot selected={this.props.selected} />}
        </View>
      </TouchableOpacity>
    );
  }
}

function Dot(props: { selected: boolean }) {
  return <SVGImage source={props.selected ? <DotWhite /> : <DotBlue />} />;
}

export default CalendarDay;
