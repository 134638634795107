import React, { useState } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";
import { ViewStyle } from "react-web-gifted-chat";
import Backbutton from "../../assets/backbutton.svg";
import BackbuttonWhite from "../../assets/backbuttonWhite.svg";
import IconHelp from "../../assets/iconHelp.svg";
import StyleUtils, { COLOR_BG_LIGHT } from "../Utils/StyleUtils";
import { NavigationButton } from "./NavigationButton";
import SVGImage from "../Utils/SVGImage";
import CalendarIconWhite from "../../assets/calendarIconWhite.svg";
import BuyCreditPopup from "../Popups/BuyCreditPopup";

type Props = {
  style?: ViewStyle;
  mobile?: boolean;
  title?: string;
  onGoBackPress?: () => void;
  children?: any;
  options?: { colorStyle?: "dark" | "white" };
  history?;
  skip?;
  onPressCalendar?: () => void;
  help?;
  callHelp?;
};

const TitleWithNavigation = (props: Props) => {
  const { options } = props;
  const colorStyleWhite = options && options.colorStyle == "white";
  const [modalVisible, setModalVisible] = useState(false);
  const BackIcon = colorStyleWhite ? Backbutton : BackbuttonWhite;
  return (
    <View
      style={[
        StyleUtils.color_bg_other_dark,
        colorStyleWhite && StyleUtils.color_bg_white,
        {
          justifyContent: "center",
          width: "100%",
          borderColor: COLOR_BG_LIGHT,
          borderBottomWidth: 1,
        },
        !props.mobile && { height: 84, alignItems: "center" },
      ]}
    >
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
          },

          !props.mobile && StyleUtils.desktop_content_width,
          props.mobile && { marginHorizontal: 16 },
          props.style,
        ]}
      >
        {props.onGoBackPress && (
          <View style={{ flex: 1 }}>
            <NavigationButton
              colorStyle={colorStyleWhite ? "dark" : "white"}
              size={24}
              onPress={props.onGoBackPress}
            />
          </View>
        )}
        <View style={{ alignItems: "center" }}>
          <Text
            style={[
              StyleUtils.font_btn_bold,
              StyleUtils.color_txt_white,
              colorStyleWhite && StyleUtils.color_txt_dark,
              StyleUtils.font_size_16,
              {
                textAlign: "center",
              },
              props.mobile && { marginVertical: 16 },
            ]}
          >
            {props.title}
          </Text>
          {!props.mobile && props.children}
        </View>
        {props.onGoBackPress && <View style={{ flex: 1 }} />}
        {props.skip && (
          <View style={{ position: "absolute", right: 10 }}>
            <TouchableOpacity
              onPress={() => props.history.push("/classroom/rating")}
            >
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_16,
                  StyleUtils.font_cera_regular,
                  { textDecorationLine: "underline" },
                ]}
              >
                Pular
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {props.onPressCalendar && (
          <View style={{ position: "absolute", right: 10 }}>
            <TouchableOpacity onPress={props.onPressCalendar}>
              <SVGImage source={<CalendarIconWhite />} />
            </TouchableOpacity>
          </View>
        )}
        {props.help && (
          <View>
            <TouchableOpacity
              style={styles.btnHelp}
              onPress={() => setModalVisible(true)}
            >
              <SVGImage source={<IconHelp />} />
            </TouchableOpacity>
          </View>
        )}
      </View>
      {props.help && (
        <BuyCreditPopup
          show={modalVisible}
          close={() => setModalVisible(false)}
          header={"Tem alguma dúvida? Fale conosco pelo nosso Whatsapp!"}
          buttonText={"Falar agora"}
        />
      )}
      {props.mobile && props.children}
    </View>
  );
};

export default TitleWithNavigation;

const styles = StyleSheet.create({
  btnHelp: {
    width: 15,
    marginRight: 15,
    marginLeft: -25,
  },
});
