import React from "react";
import StyleUtils from "../Utils/StyleUtils";
import { Text, View } from "react-native";
import SVGImage from "../Utils/SVGImage";
import Exercise from "../../assets/exercise.svg";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  number?: number;
  style?: ViewStyle;
};

const QuestionNumber = (props: Props) => {
  return (
    <View
      style={[
        {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        },
        props.style,
      ]}
    >
      <View style={{ width: 40, height: 40 }}>
        <SVGImage source={<Exercise />} />
      </View>
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.font_size_18,
          StyleUtils.color_txt_dark,
        ]}
      >
        {`Questão ${props.number}`}
      </Text>
    </View>
  );
};
export default QuestionNumber;
