import webLayout from "../Utils/PlatformUtils";

const initialState = {
  mobile: !webLayout,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MOBILE_STATE_CHANGED":
      return {
        ...state,
        mobile: !!action.mobile,
      };
    default:
      return state;
  }
};
export default layoutReducer;
