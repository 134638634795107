import React, { Component } from "react";
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  NativeModules,
  View,
  AppState,
} from "react-native";
import { connect } from "react-redux";
import { setOnboardingData } from "../../Actions";
import StyleUtils from "../../Utils/StyleUtils";
import StarVideoRating from "./StarVideoRating";
import RatingComment from "./RatingComment";
import TutorComment from "./TutorComment";
import { analytics } from "../../Analytics";
import StarTutorRating from "./StarTutorRating";
import * as Server from "../../Server";
import RemoteConfigBridge from "../../RemoteConfig/RemoteConfigBridge";

type Props = {
  location;
  history;
  setOnboardingData;
  subjects;
  subjectLevels;
  photo;
  message;
  currentUser;
  selectedUser;
};

type State = {
  componentStack;
  translateX;
  isTransitioning;
  appState;
  tutorRating;
  tutorComment;
  videoRating;
  videoComment;
  tutorPublicComment;
  tutorOptions;
  videoOptions;
  backHandler;
};

const { width } = Dimensions.get("window");
const { remoteConfig } = NativeModules;

function mapStateToProps(state) {
  return {
    subjects: state.infoRed.subjects,
    subjectLevels: state.infoRed.subjectLevels,
    photo: state.searchFilterRed.photo,
    message: state.searchFilterRed.comment,
    currentUser: state.userRed.user,
    selectedUser: state.selRed.selectedUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOnboardingData: (data) => dispatch(setOnboardingData(data)),
  };
}

class RatingFlow extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);
    this.state = {
      componentStack: [
        <StarTutorRating
          onContinue={(rating) => this.continueTutorRating(rating)}
          headerText={"O que você achou\ndo professor?"}
          onSkip={() => this.continueVideoRating(undefined)}
        />,
      ],
      translateX: new Animated.Value(0),
      isTransitioning: false,
      appState: AppState.currentState,
      tutorRating: 0,
      tutorComment: "",
      videoRating: 0,
      videoComment: "",
      tutorPublicComment: "",
      tutorOptions: [],
      videoOptions: [],
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
    };
  }

  componentDidMount() {
    AppState.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    AppState.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
    }
    this.setState({ appState: nextAppState });
  };

  backPress = () => {
    if (this.state.componentStack.length > 1) {
      this.safelyPopComponent();
      return true;
    }
    return false;
  };

  executeWithTransition(method, back?: boolean, useFade?: boolean) {
    this.setState({ isTransitioning: true });
    if (useFade) {
      Animated.timing(this.fadeAnim, {
        toValue: 0,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        method();
        Animated.timing(this.fadeAnim, {
          toValue: 1,
          duration: 400,
          easing: Easing.cubic,
          useNativeDriver: true,
        }).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    } else {
      Animated.sequence([
        Animated.timing(this.state.translateX, {
          toValue: back ? -1 * width : width,
          duration: 1,
          useNativeDriver: true,
          delay: 500,
        }),
      ]).start(() => {
        method();
        Animated.sequence([
          Animated.timing(this.state.translateX, {
            toValue: 0,
            duration: 400,
            useNativeDriver: true,
          }),
        ]).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    }
  }

  pushComponent(component, useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack.concat(component);
        this.setState({
          componentStack: tempComponents,
        });
      },
      false,
      useFade
    );
  }

  safelyPopComponent(useFade?: boolean) {
    if (this.state.isTransitioning) return;
    this.popComponent(useFade);
  }

  popComponent(useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack;
        tempComponents.pop();
        this.setState({ componentStack: tempComponents });
      },
      true,
      useFade
    );
  }

  continueTutorRating(rating) {
    this.setState({ tutorRating: rating });
    if (this.state.isTransitioning) return;
    if (rating === 5) {
      setTimeout(() => {
        this.pushComponent(
          <StarVideoRating
            onContinue={(rating) => this.continueVideoComment(rating)}
            headerText={"O que você achou da\nvideochamada?"}
            onBack={() => this.safelyPopComponent(true)}
            onSkip={() => this.continueTutorComment(undefined)}
          />,
          false
        );
      }, 100);
    } else {
      let options = [
        { id: 0, name: "Didática ruim" },
        { id: 1, name: "Falta de preparo" },
        { id: 2, name: "Falta de conhecimento" },
        { id: 3, name: "Mal-educado" },
        { id: 4, name: "Aula não me ajudou" },
        { id: 5, name: "Outros" },
      ];
      setTimeout(() => {
        this.pushComponent(
          <RatingComment
            onContinue={(rating) => this.continueVideoRating(rating)}
            commentDescription={
              "Dê mais detalhes para melhorarmos sua experiência. Essa avaliação não será compartilhada com o tutor."
            }
            options={options}
            onBack={() => this.safelyPopComponent(true)}
            stars={rating}
            onSkip={() => this.continueVideoRating(undefined)}
          />,
          true
        );
      }, 300);
    }
  }

  continueVideoRating(rating) {
    if (rating && rating.options !== undefined) {
      let options: string[] = [];
      for (const item of rating.options) {
        options.push(item.name);
      }
      this.setState({
        tutorRating: rating.rating,
        tutorComment: rating.comment,
        tutorOptions: options,
      });
    } else {
      this.setState({ tutorRating: rating });
    }
    setTimeout(() => {
      this.pushComponent(
        <StarVideoRating
          onContinue={(rating) => this.continueVideoComment(rating)}
          headerText={"O que você achou da\nvideochamada?"}
          onBack={() => this.safelyPopComponent(true)}
          onSkip={() => this.continueTutorComment(undefined)}
        />,
        false
      );
    }, 300);
  }

  continueVideoComment(rating) {
    this.setState({ videoRating: rating });
    if (rating === 5) {
      this.continueTutorComment(rating);
    } else {
      let options = [
        { id: 0, name: "Problemas no áudio" },
        { id: 1, name: "Problemas no vídeo" },
        { id: 2, name: "Problemas no lápis" },
        { id: 3, name: "Problemas no texto" },
        { id: 4, name: "Problemas em subir uma foto" },
        { id: 5, name: "Outros" },
      ];
      setTimeout(() => {
        this.pushComponent(
          <RatingComment
            onContinue={(comment) => this.continueTutorComment(comment)}
            options={options}
            onBack={() => this.safelyPopComponent(true)}
            stars={rating}
            onSkip={() => this.continueTutorComment(undefined)}
          />,
          false
        );
      }, 300);
    }
  }

  continueTutorComment(rating) {
    if (rating && rating.options !== undefined) {
      let options: string[] = [];
      for (const item of rating.options) {
        options.push(item.name);
      }
      this.setState({
        videoRating: rating.rating,
        videoComment: rating.comment,
        videoOptions: options,
      });
    } else {
      this.setState({ videoRating: rating });
    }
    setTimeout(() => {
      this.pushComponent(
        <TutorComment
          onContinue={(comment) => this.finishRating(comment)}
          onBack={() => {
            analytics.sendTutorRatingSkipped();
            this.safelyPopComponent(true);
          }}
        />,
        false
      );
    }, 300);
  }

  finishRating(comment) {
    this.setState({ tutorPublicComment: comment }, () => {
      Server.postAirbnbRating(
        this.props.selectedUser.id,
        this.props.currentUser.id,
        this.state.tutorRating,
        this.state.tutorPublicComment,
        this.state.videoRating,
        this.state.tutorComment,
        this.state.videoComment,
        this.state.tutorOptions,
        this.state.videoOptions
      )
        .then(() => {
          RemoteConfigBridge.remoteConfig("showReferral", (value) => {
            var valueBool = value == "1";
            if (valueBool) {
              console.log("hi");
              this.props.history.push("/classroom/endreferral");
            } else {
              console.log("hi2");
              this.props.history.push("/");
            }
          });
        })
        .catch();
    });
  }

  getCurrentComponent() {
    return (
      <Animated.View
        style={{
          flex: 1,
          opacity: this.fadeAnim,
          transform: [{ translateX: this.state.translateX }],
        }}
      >
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        {this.getCurrentComponent()}
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingFlow);
