import User from "./User";
import Exercise from "./Exercise";
import AssignmentState from "./AssignmentState";

interface Assignment {
  id?: string;
  tutor?: User;
  tutee?: User;
  exercise?: Exercise;
  tuteeAnswer?: string;
  tuteeAnswerImageURL?: string;
  tutorCorrectionComment?: string;
  answeredAt?: string;
  correctedAt?: string;
}

export function assignmentState(assignment?: Assignment): AssignmentState {
  if (!assignment || !assignment.answeredAt) {
    return AssignmentState.SUBMITTED;
  } else if (!assignment.correctedAt) {
    return AssignmentState.ANSWERED;
  } else {
    return AssignmentState.REVIEWED;
  }
}

export default Assignment;
