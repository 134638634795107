import React, { Component } from "react";
import OneWebView from "../Utils/OneWebView";

export default class Landing extends Component<any, any> {
  webView;

  injectJavaScript = (js) => {
    this.webView.injectJavaScript(js);
  };

  handleMessage(event) {
    // let msgData;
    // try {
    //     const nativeEvent = event.nativeEvent;
    //     if (nativeEvent) {
    //         msgData = JSON.parse(event.nativeEvent.data);
    //     } else {
    //         msgData = event;
    //         console.log("handling message from web");
    //     }
    //     this.props.processedLatex(msgData.latex);
    // } catch (err) {
    //     console.log("error", err);
    // }
  }

  render() {
    return (
      <OneWebView
        ref={(webView) => (this.webView = webView)}
        scrollEnabled={false}
        onMessage={(event) => this.handleMessage(event)}
        source={"./landing/index.html"}
        keyboardDisplayRequiresUserAction={false}
      />
    );
  }
}
