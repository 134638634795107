import React from "react";
import { Component } from "react";
import { GoogleLogin } from "react-google-login";
import { loginSuccessful } from "./../Actions";
import { connect } from "react-redux";
import { serverLogin } from "./../Server";
import { withRouter } from "./../Router";
import User from "../Models/User";
import TuteeInfo from "../Models/TuteeInfo";
import TutorInfo from "../Models/TutorInfo";

type Props = {
  loginSuccessful: (
    user: User,
    tuteeInfo?: TuteeInfo,
    tutorInfo?: TutorInfo
  ) => void;
  history;
};

const mapDispatchToProps = (dispatch) => ({
  loginSuccessful: (user: User, tuteeInfo?: TuteeInfo, tutorInfo?: TutorInfo) =>
    dispatch(loginSuccessful(user, tuteeInfo, tutorInfo)),
});

class GoogleSigninButton extends Component<Props, null> {
  // TODO send corret gender and birthday
  loginSuccessful = (response) => {
    serverLogin(
      response.profileObj.name,
      response.profileObj.email,
      response.profileObj.imageUrl,
      undefined,
      undefined,
      undefined
    ).then((loginResponse) => {
      this.props.loginSuccessful(
        loginResponse.user,
        loginResponse.tuteeInfo,
        loginResponse.tutorInfo
      );
      this.props.history.push("/");
    });
  };

  loginFailed = (response) => {
    console.log(response);
  };

  signIn = async () => {
    try {
    } catch (error) {}
  };

  render() {
    return (
      <GoogleLogin
        clientId="750744001530-056u6mojmlovv4m9hgedh0medljnqaa0.apps.googleusercontent.com"
        buttonText="Entrar com Google"
        onSuccess={this.loginSuccessful}
        onFailure={this.loginFailed}
        cookiePolicy={"single_host_origin"}
        disabled={false}
      />
    );
  }
}

export default withRouter(
  connect(null, mapDispatchToProps)(GoogleSigninButton)
);
