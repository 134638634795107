import React, { Component } from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import Exercises from "../Exercises";
import StyleUtils, { COLOR_MUTED } from "../../Utils/StyleUtils";
import { getHomework } from "../../Server";
import AssignHomework from "../../AssignHomework/AssignHomework";

type Props = {
  channelID;
  style;
  isTutor;
  onUpdate;
  selectedUser;
};

type State = {
  page: PageType;
  selectedHomework;
};

enum PageType {
  PageExercise,
  PageSubmit,
  PageLoading,
}

class CanvasExercisesHolder extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedHomework: null,
      page: PageType.PageExercise,
    };
  }

  handleRemoteAction = (data) => {
    switch (data.obj.action) {
      case "exercises:refresh":
        this.refreshExercises();
        break;
    }
  };

  refreshExercises = () => {
    this.setState({ page: PageType.PageLoading });
    getHomework(this.props.channelID).then((homework) => {
      this.setState({
        selectedHomework: homework,
        page: PageType.PageExercise,
      });
    });
  };

  componentDidMount() {
    this.refreshExercises();
  }

  addExercisesText = () => {
    return this.state.selectedHomework
      ? "Adicionar mais exercícios..."
      : "Adicionar exercícios...";
  };

  renderPage = () => {
    switch (this.state.page) {
      case PageType.PageExercise:
        return (
          <View style={{ flex: 1 }}>
            {!this.state.selectedHomework && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  backgroundColor: COLOR_MUTED,
                }}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.color_txt_primary,
                    StyleUtils.font_size_16,
                    {
                      textAlign: "center",
                    },
                  ]}
                >
                  Sem exercícios para mostrar
                </Text>
              </View>
            )}
            {this.state.selectedHomework && (
              <Exercises
                classMode={true}
                classHomework={this.state.selectedHomework}
                onUpdate={this.props.onUpdate}
                reviewMode={true}
              />
            )}
            {this.props.isTutor && (
              <View style={{ height: 79 }}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ page: PageType.PageSubmit });
                  }}
                  style={[
                    StyleUtils.color_bg_primary,
                    StyleUtils.shape_btn_small,
                    {
                      flex: 1,
                      flexGrow: 1,
                      margin: 16,
                      marginLeft: 24,
                      marginRight: 24,
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  ]}
                >
                  <Text
                    style={[
                      StyleUtils.color_txt_white,
                      StyleUtils.font_cera_bold,
                      StyleUtils.font_size_16,
                      {
                        textAlign: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    {this.addExercisesText()}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        );
      case PageType.PageLoading:
        return (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              backgroundColor: COLOR_MUTED,
            }}
          >
            <ActivityIndicator />
          </View>
        );
      case PageType.PageSubmit:
        return (
          <AssignHomework
            style={{ flex: 1 }}
            classInfo={{
              id: this.props.channelID,
              selectedUser: this.props.selectedUser,
              finishCallback: () => {
                this.refreshExercises();
                this.props.onUpdate();
              },
              existingHomework: this.state.selectedHomework,
            }}
          />
        );
    }
  };

  render() {
    return <View style={this.props.style}>{this.renderPage()}</View>;
  }
}

export default CanvasExercisesHolder;
