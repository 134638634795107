const initialState = {
  cardToRemove: "",
  cardLastDigits: "",
  confirmDelete: false,
  help: false,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CARD_TO_REMOVE":
      return {
        ...state,
        cardToRemove: action.card.id,
        cardLastDigits: action.card.lastDigits,
      };
    case "REMOVE_CANCEL":
      return {
        ...state,
        cardLastDigits: "",
        cardToRemove: "",
      };
    case "CONFIRM_DELETE":
      return {
        ...state,
        confirmDelete: true,
      };
    case "CLEAN":
      return {
        ...state,
        confirmDelete: false,
        cardToRemove: "",
        cardLastDigits: "",
      };
    case "CALL_HELP":
      return {
        ...state,
        help: true,
      };
    case "CLOSE_HELP":
      return {
        ...state,
        help: false,
      };
    default:
      return state;
  }
};

export default paymentReducer;
