import React from "react";
import { Component } from "react";
import {
  FlatList,
  TouchableOpacity,
  Text,
  View,
  TextInput,
  Dimensions,
  SafeAreaView,
} from "react-native";
import StyleUtils, {
  COLOR_BG_LIGHT,
  COLOR_TXT_LIGHT,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import MessengerUser from "./MessengerUser";
import SVGImage from "../Utils/SVGImage";
import SearchIcon from "../../assets/searchIcon.svg";
import Footer from "../Footer/Footer";
import { ViewStyle } from "react-web-gifted-chat";
import { getCounselor } from "../Server";
import { setCounselor } from "../Actions";
import { connect } from "react-redux";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

type Props = {
  dataSource;
  selectedUser;
  onUserSelected;
  style?: ViewStyle;
  headerText;
  headerStyle?: ViewStyle;
  history;
  dispatchSetCounselor;
};

type State = {
  nameFilter;
  filteredDataSource;
  loading;
  showCounselor: boolean;
};

function mapStateToProps(state) {
  return {
    selectedUser: state.userRed.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetCounselor: (counselor) => dispatch(setCounselor(counselor)),
    dispatch: dispatch,
  };
}

class MessengerUserList extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      nameFilter: "",
      filteredDataSource: props.dataSource,
      loading: true,
      showCounselor: false,
    };
  }

  componentDidMount() {
    RemoteConfigBridge.remoteConfig("talkspaceOnboarding", (value) => {
      if (value === "1") {
        !this.props.selectedUser.counselor &&
          getCounselor(this.props.selectedUser.id).then((counselor) => {
            this.props.dispatchSetCounselor(counselor);
          });
        this.setState({ showCounselor: true });
      }
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (
      prevState.nameFilter != this.state.nameFilter ||
      prevProps.dataSource != this.props.dataSource
    ) {
      this.filterDataSource(this.state.nameFilter);
    }
  }

  filterDataSource = (toMatch) => {
    if (toMatch.trim() == "") {
      this.setState({ filteredDataSource: this.props.dataSource });
    } else {
      const lowercaseMatch = toMatch.toLowerCase();
      this.setState({
        filteredDataSource: this.props.dataSource.filter(
          (user) => user.name.toLowerCase().indexOf(lowercaseMatch) !== -1
        ),
      });
    }
    this.setState({ loading: false });
  };

  renderNativeItem = (item) => {
    const separatorStyle =
      item === this.props.dataSource[this.props.dataSource.length - 1]
        ? {}
        : {
            position: "absolute",
            top: 60,
            height: 1,
            alignSelf: "center",
            backgroundColor: COLOR_BG_LIGHT,
            flex: 1,
            width: "88%",
          };
    const cellStyle =
      this.props.selectedUser && item.id === this.props.selectedUser.id
        ? {
            height: 56,
            justifyContent: "center",
            backgroundColor: COLOR_WHITE,
          }
        : { height: 50, justifyContent: "center" };

    return (
      <MessengerUser
        cellStyle={cellStyle}
        separatorStyle={separatorStyle}
        onPress={() => this.props.onUserSelected(item)}
        user={item}
        history={this.props.history}
        icon={item.icon}
      />
    );
  };

  render() {
    return (
      <View
        style={[
          this.props.style,
          {
            flex: 1,
          },
        ]}
      >
        {!!this.props.headerText && (
          <View style={[StyleUtils.color_bg_dark, this.props.headerStyle]}>
            <Text
              style={[
                StyleUtils.font_btn_bold,
                StyleUtils.font_size_18,
                StyleUtils.color_txt_white,
                {
                  marginHorizontal: 24,
                  marginVertical: 12,
                },
              ]}
            >
              {this.props.headerText}
            </Text>
          </View>
        )}
        <View
          style={[
            StyleUtils.border_color_btn_muted,
            {
              flexDirection: "row",
              borderRadius: 50,
              height: 40,
              paddingLeft: 10,
              alignItems: "center",
              marginHorizontal: 24,
              marginBottom: 6,
              marginTop: 16,
            },
          ]}
        >
          <SVGImage source={<SearchIcon />} />
          <TextInput
            style={[
              StyleUtils.font_size_16,
              StyleUtils.font_btn_regular,
              StyleUtils.color_txt_light,
              {
                margin: 4,
                paddingLeft: 6,
                flex: 1,
                height: 40,
              },
            ]}
            onChangeText={(text) => this.setState({ nameFilter: text })}
            placeholder={"Procurar..."}
            placeholderTextColor={COLOR_TXT_LIGHT}
          />
        </View>
        {!this.state.loading &&
          this.state.filteredDataSource.length === 0 &&
          !this.state.showCounselor && (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: Dimensions.get("window").height / 2 - 250,
              }}
            >
              <Text
                style={[
                  { textAlign: "center", paddingVertical: 14 },
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_20,
                  StyleUtils.color_txt_dark,
                ]}
              >
                Você ainda não tem{"\n"}um professor!
              </Text>
              <Text
                style={[
                  { textAlign: "center" },
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_16,
                  StyleUtils.color_txt_light,
                ]}
              >
                Comece agora a fazer aulas com{"\n"}professores incríveis!
              </Text>
              <TouchableOpacity
                style={[
                  StyleUtils.shape_btn_small,
                  StyleUtils.color_bg_primary,
                  {
                    marginTop: 32,
                    marginLeft: 28,
                    marginRight: 28,
                    width: "85%",
                  },
                ]}
                onPress={() => {
                  this.props.history.push({
                    pathname: "/landing/subjects",
                    state: {
                      showFooter: true,
                    },
                  });
                }}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_white,
                    StyleUtils.font_size_16,
                    { padding: 14, textAlign: "center" },
                  ]}
                >
                  Encontrar Professor
                </Text>
              </TouchableOpacity>
            </View>
          )}
        {this.props.selectedUser.counselor &&
          this.state.showCounselor &&
          this.renderNativeItem({
            ...this.props.selectedUser.counselor,
            isCounselor: true,
          })}
        <FlatList
          data={this.state.filteredDataSource.filter(
            (user) => user.id !== this.props.selectedUser.counselor?.id
          )}
          renderItem={({ item }) => this.renderNativeItem(item)}
          extraData={this.props.selectedUser}
        />
        <SafeAreaView>
          <Footer />
        </SafeAreaView>
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessengerUserList);
