import React from "react";
import { StyleProp, Text, TextStyle, TouchableOpacity } from "react-native";
import { ViewStyle } from "react-web-gifted-chat";
import StyleUtils from "../Utils/StyleUtils";

const ButtonElement = (props: {
  text?: string;
  styte?: ViewStyle;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  colorScheme?: "primary" | "sucess" | "white" | "danger_white";
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity
      style={[
        StyleUtils.shape_btn_default_rounded,
        StyleUtils.color_bg_primary,
        { margin: 8 },
        props.colorScheme === "sucess" && StyleUtils.color_btn_success,
        props.colorScheme === "white" &&
          !props.disabled &&
          StyleUtils.color_bg_white,
        props.colorScheme === "white" &&
          !props.disabled &&
          StyleUtils.border_color_btn_primary,
        props.disabled && StyleUtils.color_btn_muted,
        props.styte,
        props.colorScheme === "danger_white" && StyleUtils.color_bg_true_white,
      ]}
      disabled={props.disabled}
      onPress={props.onPress}
    >
      <Text
        style={[
          StyleUtils.color_txt_white,
          StyleUtils.font_btn_bold,
          props.colorScheme === "white" &&
            !props.disabled &&
            StyleUtils.color_txt_primary,
          props.colorScheme === "danger_white" && StyleUtils.color_txt_dark,
          {
            padding: 16,
            paddingHorizontal: 80,
            alignSelf: "center",
          },
          props.textStyle,
        ]}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

export default ButtonElement;
