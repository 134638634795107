import React, { Component } from "react";
import { connect } from "react-redux";
import { View } from "react-native";

type Props = {
  boardUrl;
  channelName;
  otherUserName;
  onMessage?: (data?) => void;
};

type State = {};

const stateToProps = (state) => ({});

const dispatchToProps = (dispatch) => ({});

class CanvasWebView extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("message", this.handleIframeTask);
  }

  handleIframeTask = (e) => {
    if (e.origin !== this.props.boardUrl) {
      return;
    }
    try {
      let data = JSON.parse(e.data);
      if (this.props.onMessage) {
        this.props.onMessage(data);
      }
    } catch (e) {}
  };

  sendAction = (action, info) => {
    const iframe: any = document.getElementById("iframe");
    iframe.contentWindow.postMessage(
      { action: action, info: info },
      this.props.boardUrl
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <iframe
          style={{ borderWidth: 0 }}
          onLoad={() => {
            const iframe: any = document.getElementById("iframe");
            iframe.contentWindow.postMessage(
              {
                action: "setupChannel",
                info: {
                  channelName: this.props.channelName,
                  otherUserName: this.props.otherUserName,
                },
              },
              this.props.boardUrl
            );
          }}
          id={"iframe"}
          src={this.props.boardUrl}
          height={"100%"}
          width={"100%"}
        />
      </View>
    );
  }
}

export default connect(stateToProps, dispatchToProps, null, {
  forwardRef: true,
})(CanvasWebView);
