import { dateFormatHHhmm } from "../Utils/Utils";
import { AnalyticsManager } from "./analytics";
import { FacebookAnalyticsManager } from "./facebookAnalytics";
import { Platform } from "react-native";

// For some reason, PTAAS does not work if you send the analytics event inside a promise.
// Events tagged with 'ok' are safe to use for PTAAS purposes.

function sendEvent(eventName: string, params?: any) {
  AnalyticsManager.sendEvent(eventName, params || null);
  if (Platform.OS === "ios" || Platform.OS === "android")
    FacebookAnalyticsManager.sendEvent(eventName, params || null);
}

function setUserID(userID: string) {
  AnalyticsManager.setUserID(userID);
}

class Analytics {
  setUserID(userID: string) {
    setUserID(userID);
  }
  sendScreenView(screenName: string) {
    sendEvent("ScreenView", { screenName });
  }
  sendSessionStarted() {
    //no
    sendEvent("SessionStarted");
  }
  sendOnboarding(variation: string = "") {
    //ok
    sendEvent("OnboardingContinued" + variation);
  }
  sendAuthPopupOpened() {
    //ok
    sendEvent("AuthPopupOpened");
  }
  sendAuthPopupClosed() {
    //ok
    sendEvent("AuthPopupClosed");
  }
  sendOnboardingLogin() {
    //ok
    sendEvent("OnboardingLogin");
  }
  sendOnboardingBeTeacher() {
    //ok
    sendEvent("OnboardingIsTeacher");
  }
  sendOnboardingHaveTutor() {
    //ok
    sendEvent("OnboardingHaveTutorChosen");
  }
  sendOnboardingHaveTutorSuccess() {
    //ok
    sendEvent("OnboardingHaveTutorSucceeded");
  }
  sendOnboardingSubject(subject: string) {
    //ok
    sendEvent("OnboardingSubjectChosen", { subject });
  }
  sendOnboardingLevel(level: string) {
    //ok
    sendEvent("OnboardingLevelChosen", { level });
  }
  sendOnboardingTimeChoice(timeChoice: string) {
    sendEvent("OnboardingTimeChosen", { timeChoice });
  }
  sendOnboardingCompleted() {
    sendEvent("OnboardingCompleted");
  }
  sendLogin(provider: string) {
    //no
    sendEvent("Login", { provider });
  }
  sendLoginFailed(provider: string) {
    //no
    sendEvent("LoginFailed", { provider });
  }
  sendSignUp(provider: string) {
    //no
    sendEvent("Signup", { provider });
  }
  sendSignUpFailed(provider: string) {
    //no
    sendEvent("SignupFailed", { provider });
  }
  sendOpenHome(empty: string, tutorName: string) {
    //ok
    sendEvent("HomeOpened", { empty, tutorName });
  }
  sendSearchIconSelected() {
    //ok
    sendEvent("SearchIconSelected");
  }
  sendBookingIconSelected() {
    //ok
    sendEvent("BookingIconSelected");
  }
  sendHomeIconSelected() {
    //ok
    sendEvent("HomeIconSelected");
  }
  sendChatMessageFailed(from, to, error) {
    sendEvent("ChatMessageFailed", { to, from, error });
  }
  sendChatIconSelected() {
    //ok
    sendEvent("ChatIconSelected");
  }
  sendLessonIconSelected() {
    //ok
    sendEvent("LessonIconSelected");
  }
  sendScheduleOpen() {
    //ok
    sendEvent("ScheduleOpened");
  }
  sendScheduleTimeChosen(date: Date) {
    //ok
    sendEvent("ScheduleTimeChosen", {
      time: dateFormatHHhmm(date),
      dayOfWeek: date.getDay(),
    });
  }
  sendScheduleConfirmed() {
    //ok
    sendEvent("ScheduleConfirmed");
  }
  sendSearchResultsShow(subject: string, level: string) {
    //no
    sendEvent("SearchResultsShow", { subject, level });
  }
  sendSearchNoResultsShow(subject: string, level: string) {
    //no
    sendEvent("SearchNoResultsShow", { subject, level });
  }
  sendSearchBarRedo() {
    //yes
    sendEvent("SearchBarRedo");
  }
  sendTutorMatch(tutorName: string) {
    //no
    sendEvent("TutorMatch", { tutorName });
  }
  sendTutorMatchSendMessage() {
    //ok
    sendEvent("TutorMatchSendMessage");
  }
  sendTutorFavorite(tutorName: string) {
    //ok
    sendEvent("TutorFavorite", { tutorName });
  }
  sendClassEnter() {
    //ok
    sendEvent("ClassEntered");
  }
  sendTutorRatingOpened() {
    //ok
    sendEvent("TutorRatingOpened");
  }
  sendTutorRatingSkipped() {
    //ok
    sendEvent("TutorRatingSkipped");
  }
  sendTutorRatingSubmitted() {
    //ok
    sendEvent("TutorRatingSubmitted");
  }
  lessonSubmitted() {
    //ok
    sendEvent("LessonSubmitted");
  }
  sendLessonOpened() {
    //ok
    sendEvent("LessonOpened");
  }
  sendLessonReviewed() {
    //no
    sendEvent("LessonReviewed");
  }
  sendExerciseChosen() {
    //no
    sendEvent("ExerciseChosen");
  }
  sendExerciseImagePosted() {
    //no
    sendEvent("ExerciseImagePosted");
  }
  sendTutorProfileShow(tutor: string) {
    //ok
    sendEvent("TutorProfileShow", { tutor });
  }
  sendTutorProfileCardSendMessage() {
    //ok
    sendEvent("TutorProfileCardSendMessage");
  }
  sendTutorProfileSendMessage() {
    //ok
    sendEvent("TutorProfileSendMessage");
  }
  sendTutorProfileViewComments() {
    //ok
    sendEvent("TutorProfileViewComments");
  }
  sendTutorProfileScheduleClass() {
    //ok
    sendEvent("TutorProfileScheduleClass");
  }
  sendSignUpFormSubmitted() {
    //ok
    sendEvent("SignUpFormSubmitted");
  }
  sendTutorPaymentShow() {
    //ok
    sendEvent("TutorPaymentShow");
  }
  sendTutorPaymentStarted() {
    //ok
    sendEvent("TutorPaymentStarted");
  }
  sendCouponPopupOpened() {
    //ok
    sendEvent("CouponPopupOpened");
  }
  sendCouponFailed() {
    //ok
    sendEvent("CouponFailed");
  }
  sendCouponSuccess(coupon: string) {
    //ok
    sendEvent("CouponSuccess", { coupon });
  }
  sendClassScheduledShow() {
    //ok
    sendEvent("ClassScheduledShow");
  }
  sendClassScheduled() {
    //ok
    sendEvent("ClassScheduled");
  }
  sendClassRescheduled() {
    //ok
    sendEvent("ClassRescheduled");
  }
  sendStartClassSelected() {
    //ok
    sendEvent("StartClassSelected");
  }
  sendClassFinished() {
    //ok
    sendEvent("ClassFinished");
  }
  sendClassStartedStudent() {
    //ok
    sendEvent("ClassStarted_Student");
  }
  sendPushNotificationPressed(pushId, appWasClosed) {
    sendEvent("PushNotificationPressed", { pushId, appWasClosed });
  }
  sendExerciseOnboardingBack() {
    //ok
    sendEvent("Back_Exercise_Upload_ExerciseOnboarding");
  }
  sendExerciseOnboardingLookTutor() {
    //ok
    sendEvent("Search_Tutor_ExerciseOnboarding");
  }
  sendExerciseOnboarding() {
    //ok
    sendEvent("Send_ExerciseOnboarding");
  }
  sendExerciseOnboardingUploadImage() {
    //no
    sendEvent("Upload_Image_ExerciseOnboarding");
  }
  sendExerciseOnboardingSendExercise() {
    //ok
    sendEvent("Send_Exercise_ExerciseOnboarding");
  }
  sendLoginExerciseOnboarding(provider: string) {
    //no
    sendEvent("Login_ExerciseOnboarding", { provider });
  }
  sendSignUpExerciseOnboarding(provider: string) {
    //no
    sendEvent("Signup_ExerciseOnboarding", { provider });
  }
  sendExerciseOnboardingChatback() {
    //ok
    sendEvent("Back_Chat_ExerciseOnboarding");
  }
  sendOpenHomeExerciseOnboarding() {
    //ok
    sendEvent("Home_ExerciseOnboarding");
  }
  sendExerciseOnboardingTutorMatchList() {
    //ok
    sendEvent("Tutor_Match_List_ExerciseOnboarding");
  }
  sendTutorProfileCardShare() {
    sendEvent("TutorProfileShare");
  }
  sendTalkToTutorWhatsapp() {
    sendEvent("TalkToTutor_WhatsappOnboarding");
  }
  sendLookForTutorWhatsapp() {
    sendEvent("LookForTutor_WhatsappOnboarding");
  }
  sendTutorProfileWhatsapp() {
    sendEvent("TutorProfileShow_WhatsappOnboarding");
  }
  sendQuickClassBack() {
    //ok
    sendEvent("QuickClassBack");
  }
  sendSubOnboardingArea(area: string) {
    sendEvent("SubOnboardingAreaChosen", { area });
  }
  sendSubOnboardingSubject(subject: string) {
    sendEvent("SubOnboardingSubjectChosen", { subject });
  }
  sendSubOnboardingSpecificAreas(areas: string) {
    sendEvent("SubOnboardingSpecificAreasChosen", { areas });
  }
  sendSubOnboardingLevel(level: string) {
    sendEvent("SubOnboardingLevelChosen", { level });
  }
  sendOpenReferralPopup(location: string) {
    sendEvent("OpenReferralPopup", { location });
  }
  sendReferralHome() {
    sendEvent("ReferralHome");
  }
  sendGetFreeClassReferral() {
    sendEvent("GetFreeClassReferral");
  }
  sendCancelReferral() {
    sendEvent("CancelReferral");
  }
  sendReferralUsed() {
    sendEvent("ReferralUsed");
  }
  sendPremiumHome() {
    sendEvent("PremiumHome");
  }
  sendPremiumButton() {
    sendEvent("PremiumButton");
  }
  sendSignUpPremium() {
    sendEvent("SignUpPremium");
  }
  sendBuyCredits() {
    sendEvent("BuyCredits");
  }
  sendSupportWhatsapp() {
    sendEvent("SupportWhatsapp");
  }
  sendRecurringClass() {
    sendEvent("RecurringClassClicked");
  }
  sendClosePremiumPaywall() {
    sendEvent("ClosePremiumPaywall");
  }
  sendShowPremiumPaywall() {
    sendEvent("ShowPremiumPaywall");
  }
  sendShowSubjectTestList() {
    sendEvent("ShowSubjectTestList");
  }
  sendSubjectTestHome() {
    sendEvent("SubjectTestHome");
  }
  sendSubjectTestExercises() {
    sendEvent("SubjectTestExercises");
  }
  sendStartSubjectTest() {
    sendEvent("StartSubjectTest");
  }
  sendCancelStartSubjectTest() {
    sendEvent("CancelStartSubjectTest");
  }
  sendSubmitSubjectTest() {
    sendEvent("SubmitSubjectTest");
  }
  sendSubjectTestResultButton() {
    sendEvent("SubjectTestResultButton");
  }
  sendSendSubjectTestTutor() {
    sendEvent("SendSubjectTestTutor");
  }
  sendEndSubjectTest() {
    sendEvent("EndSubjectTest");
  }
  sendSubjectTestTimeFinished() {
    sendEvent("SubjectTestTimeFinished");
  }
  sendOpenedFreeCreditScreen() {
    sendEvent("OpenedFreeCreditScreen");
  }
  sendCopiedFreeCoupon() {
    sendEvent("CopiedFreeCoupon");
  }
  sendStartVocationalTest() {
    sendEvent("StartVocationalTest");
  }
  sendCancelStartVocationalTest() {
    sendEvent("CancelStartVocationalTest");
  }
  sendGoals(goals: string) {
    sendEvent("GoalsChosen", { goals });
  }
  sendYearOfCollegeEntry(year: string) {
    sendEvent("YearOfCollegeEntry", { year });
  }
  sendMentorInterest(interest: string) {
    sendEvent("MentorInterest", { interest });
  }
  sendStartPartOneVT() {
    sendEvent("StartPartOneVT");
  }
  sendStartPartTwoVT() {
    sendEvent("StartPartTwoVT");
  }
  sendStartPartThreeVT() {
    sendEvent("StartPartThreeVT");
  }
  sendNextResultsVT() {
    sendEvent("NextResultsVT");
  }
  sendDetailedResultVT() {
    sendEvent("DetailedResultVT");
  }
  sendRetakeVT() {
    sendEvent("RetakeVT");
  }
  sendShareVT() {
    sendEvent("ShareVT");
  }
  sendScheduleMentorship() {
    sendEvent("ScheduleMentorship");
  }
  sendFinalizeVT() {
    sendEvent("FinalizeVT");
  }
  sendScheduleFirstSessionFree() {
    sendEvent("ScheduleFirstSessionFree");
  }
  sendScheduleFullSession() {
    sendEvent("ScheduleFullSession");
  }
  sendExperimentFree() {
    sendEvent("ExperimentFree");
  }
  sendParentsOnboardingContinue() {
    //ok
    sendEvent("ParentsOnboardingContinue");
  }
  sendReviewsOnboardingContinue() {
    //ok
    sendEvent("ReviewsOnboardingContinue");
  }
  sendReviewsOnboardingSubject(subject: string) {
    //ok
    sendEvent("ReviewsOnboardingContinue", { subject });
  }
  sendReviewsOnboardingSkip() {
    sendEvent("ReviewsOnboardingSkip");
  }
  sendReviewsOnboardingChooseLater() {
    sendEvent("ReviewsOnboardingChooseLater");
  }
  sendReviewsOnboardingAge(age: string) {
    sendEvent("ReviewsOnboardingAge", { age });
  }
  sendScheduleFirstClassFree() {
    sendEvent("ScheduleFirstClassFree");
  }
  sendShowScheduleFirstClassFree() {
    sendEvent("ShowScheduleFirstClassFree");
  }
  sendFirstClassFreeBack() {
    sendEvent("FirstClassFreeBack");
  }
  sendShowCalendar() {
    sendEvent("ShowCalendar");
  }
  sendTalkspaceHomeRendered() {
    sendEvent("TalkspaceHomeRendered");
  }
  sendTalkspaceNewMessageOpened() {
    sendEvent("TalkspaceNewMessageOpened");
  }
  sendReviewsOnboardingScrolled() {
    sendEvent("ReviewsOnboardingScrolled");
  }
  sendParentsOnboardingScrolled() {
    sendEvent("ParentsOnboardingScrolled");
  }
  sendParentsOnboardingNecessity() {
    sendEvent("ParentsOnboardingNecessity");
  }
  sendParentsOnboardingNecessitySelected(necessity: string) {
    sendEvent("ParentsOnboardingNecessitySelected", { necessity });
  }
  sendParentsOnboardingPersonalInformations() {
    sendEvent("ParentsOnboardingPersonalInformations");
  }
  sendParentsOnboardingPersonalInformationsFilled(name: string, phone: string) {
    sendEvent("ParentsOnboardingPersonalInformationsFilled", { name, phone });
  }
  sendParentsOnboardingStudentName(name: string) {
    sendEvent("ParentsOnboardingStudentName", { name });
  }
  sendParentsOnboardingStudentAge(age: number) {
    sendEvent("ParentsOnboardingStudentAge", { age });
  }
  sendParentsOnboardingIntro() {
    sendEvent("ParentsOnboardingIntro");
  }
  sendParentsOnboardingCareerIntro() {
    sendEvent("ParentsOnboardingCareerIntro");
  }
  sendParentsOnboardingClassesIntro() {
    sendEvent("ParentsOnboardingClassesIntro");
  }
  sendParentsOnboardingIntroBeforeTutorMatch() {
    sendEvent("ParentsOnboardingIntroBeforeTutorMatch");
  }
  sendParentsOnboardingMessageSent(from, to, message) {
    sendEvent("ParentsOnboardingMessageSent", { from, to, message });
  }
  sendParentsOnboardingTutorMatch() {
    sendEvent("ParentsOnboardingTutorMatch");
  }
  sendPaymentPopupShow() {
    sendEvent("PaymentPopupShow");
  }
  sendOrderPayment(price: string) {
    sendEvent("OrderPayment", { price });
  }
  sendCreditCardAdd() {
    sendEvent("CreditCardAdd");
  }
  sendCreditCardRemove() {
    sendEvent("CreditCardRemoved");
  }
  sendCreditCardInvalid() {
    sendEvent("CreditCardInvalid");
  }
  sendOrderPaymentBack() {
    sendEvent("OrderPaymentBack");
  }
  sendOrderPaymentSuccess(price: string) {
    sendEvent("OrderPaymentSuccess", { price });
  }
  sendOrderPaymentFailed(price: string) {
    sendEvent("OrderPaymentFailed", { price });
  }
  sendIAPPurchaseSuccess() {
    sendEvent("IAPPurchaseSuccess");
  }
  sendIAPPurchaseFailed() {
    sendEvent("IAPPurchaseFailed");
  }
  sendShowRequestWhatsappPopup() {
    sendEvent("ShowRequestWhatsappPopup");
  }
  sendShowConfirmTokenPopup() {
    sendEvent("ShowConfirmTokenPopup");
  }
  sendCancelWhatsappPopup() {
    sendEvent("CancelWhatsappPopup");
  }
  sendValidateNumber() {
    sendEvent("ValidateNumber");
  }
  sendChangeNumber() {
    sendEvent("ChangeNumber");
  }
  sendConfirmTokenSuccess() {
    sendEvent("ConfirmTokenSuccess");
  }
  sendConfirmTokenFailure() {
    sendEvent("ConfirmTokenFailure");
  }
  sendResendToken() {
    sendEvent("ResendToken");
  }
  sendTalkNowParents() {
    sendEvent("TalkNowParents");
  }
  sendScheduleSessionParents() {
    sendEvent("ScheduleSessionParents");
  }
  sendWaitingContact(userid: string) {
    sendEvent("WaitingContact", { userid });
  }
  sendTFOnboarding() {
    sendEvent("TFOnboarding");
  }
  sendTFStudyPlan() {
    sendEvent("TFStudyPlan");
  }
  sendTFStartVocationalTest() {
    sendEvent("TFStartVocationalTest");
  }
  sendTFStudyPlanName() {
    sendEvent("TFStudyPlanName");
  }
  sendTFContinueGoals() {
    sendEvent("TFContinueGoals");
  }
  sendTFStudyTime() {
    sendEvent("TFStudyTime");
  }
  sendTFAvailableTime() {
    sendEvent("TFAvailableTime");
  }
  sendTFSchoolingType() {
    sendEvent("TFSchoolingType");
  }
  sendTFAge() {
    sendEvent("TFAge");
  }
  sendTFCreatePlan() {
    sendEvent("TFCreatePlan");
  }
  sendTFStartStudyPlan() {
    sendEvent("TFStartStudyPlan");
  }
  sendShowSubscriptionPopup() {
    sendEvent("ShowSubscriptionPopup");
  }
  sendSubscriptionPurchaseSuccess() {
    sendEvent("SubscriptionPurchaseSuccess");
  }
  sendSubscriptionPurchaseFailed() {
    sendEvent("SubscriptionPurchaseFailed");
  }
  sendSurveyResponse(question: string, answer: string) {
    sendEvent("SurveyResponse", { question, answer });
  }
  sendEndSurvey() {
    sendEvent("EndSurvey");
  }
  sendShowSurveyPopup() {
    sendEvent("ShowSurveyPopup");
  }
  sendCancelSurveyPopup() {
    sendEvent("CancelSurveyPopup");
  }
  sendContinueSurvey() {
    sendEvent("ContinueSurvey");
  }
  sendStopSurvey() {
    sendEvent("StopSurvey");
  }
  sendDynamicLinkInit(url: string) {
    sendEvent("DynamicLinkInit", { url });
  }
  sendDynamicLinkOpenUrl(url: string) {
    sendEvent("DynamicLinkOpenUrl", { url });
  }
  sendDynamicLinkFirebase(url: string) {
    sendEvent("DynamicLinkFirebase", { url });
  }
  sendDynamicLinkFirebaseInit(url: string) {
    sendEvent("DynamicLinkFirebaseInit", { url });
  }
  sendDynamicLinkFirebaseResolve(url: string) {
    sendEvent("DynamicLinkFirebaseResolve", { url });
  }
  sendDynamicLinkFirebaseResolveLinking(url: string) {
    sendEvent("DynamicLinkFirebaseResolveLinking", { url });
  }
  sendDynamicLinkFirebaseInitLinking(url: string) {
    sendEvent("DynamicLinkFirebaseInitLinking", { url });
  }
  sendStartStudyPlan(index) {
    sendEvent("StartStudyPlan", { index });
  }
  sendStudyPlanQuestion(question: string, answer, index) {
    sendEvent("StudyPlanQuestion", { question, answer, index });
  }
  sendShowWaitResult(index) {
    sendEvent("ShowWaitResult", { index });
  }
  sendSeeRecommendedTutors(index) {
    sendEvent("SeeRecommendedTutors", { index });
  }
  sendStudyPlanScheduleClass(index) {
    sendEvent("StudyPlanScheduleClass", { index });
  }
  sendStudyPlanIntermediate(headerText: string, index) {
    sendEvent("StudyPlanIntermediate", { headerText, index });
  }
  sendStudyPlanBack(index) {
    sendEvent("StudyPlanBack", { index });
  }
}

export const analytics = new Analytics();
