import User from "./User";
import Exercise from "./Exercise";
import TestQuestionState from "./TestQuestionState";

interface TestQuestion {
  id?: string;
  tutor?: User;
  tutee?: User;
  exercise?: Exercise;
  tuteeAnswer?: string;
  tutorCorrectionComment?: string;
  answeredAt?: string;
  correctedAt?: string;
}

export function testQuestionState(
  assignment?: TestQuestion
): TestQuestionState {
  if (!assignment || !assignment.answeredAt) {
    return TestQuestionState.SUBMITTED;
  } else if (!assignment.correctedAt) {
    return TestQuestionState.ANSWERED;
  } else {
    return TestQuestionState.REVIEWED;
  }
}

export default TestQuestion;
