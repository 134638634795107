import React from "react";
import { View, Text, StyleSheet, ActivityIndicator } from "react-native";
import * as Animatable from "react-native-animatable";
import StyleUtils from "../Utils/StyleUtils";
import BackgroundSuccess from "../../assets/backgroundShapesGreen.svg";
import BackgroundError from "../../assets/backgroundShapesRed.svg";
import BackgroundPaymentSuccess from "../../assets/backgroundPaymentSuccess.svg";
import BackgroundPaymentError from "../../assets/backgroundPaymentError.svg";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  paymentComplete?;
  processPayment?;
  paymentSuccess?;
  history;
  credits;
  changeProcess?;
  tryAgain?;
};

export default function ProcessPayment(props: Props) {
  const backToPayment = () => {
    props.changeProcess(false);
    props.tryAgain(false);
  };

  if (props.paymentComplete) {
    return (
      <>
        <View style={[styles.dimmer, styles.dimmerComplete]}>
          {props.paymentSuccess ? (
            <View>
              <View style={styles.backgroundShapes}>
                <Animatable.View
                  animation="pulse"
                  iterationCount={"infinite"}
                  iterationDelay={600}
                  easing="ease-out"
                  duration={600}
                >
                  <BackgroundSuccess />
                </Animatable.View>
              </View>
              <View style={styles.container}>
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_size_22,
                    StyleUtils.font_cera_bold,
                    styles.title,
                  ]}
                >
                  Créditos adicionados com sucesso
                </Text>
                <View style={styles.backgroundPaymentSuccess}>
                  <BackgroundPaymentSuccess />
                  <Text
                    style={[
                      StyleUtils.font_size_22,
                      StyleUtils.font_cera_bold,
                      styles.credits,
                    ]}
                  >
                    {props.credits}
                  </Text>
                </View>
                <View>
                  <Text
                    style={[
                      StyleUtils.color_txt_grey_light,
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_16,
                      styles.paymentMsg,
                    ]}
                  >
                    Seus créditos já estão disponíveis em sua carteira e você já
                    pode agendar sua aula.
                  </Text>
                </View>
                <ButtonElement
                  text="Ir para Agendamento"
                  colorScheme="sucess"
                  onPress={() => {
                    props.history.goBack();
                  }}
                />
              </View>
            </View>
          ) : (
            <View>
              <View style={styles.backgroundShapes}>
                <BackgroundError />
              </View>
              <View style={styles.container}>
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_size_22,
                    StyleUtils.font_cera_bold,
                    styles.title,
                  ]}
                >
                  Não foi possível processa sua compra
                </Text>
                <View style={styles.backgroundPaymentSuccess}>
                  <Animatable.View animation="fadeInUp" duration={500}>
                    <BackgroundPaymentError />
                  </Animatable.View>
                </View>
                <View>
                  <Text
                    style={[
                      StyleUtils.color_txt_grey_light,
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_16,
                      styles.paymentMsg,
                    ]}
                  >
                    Não foi possível realizar o pagamento utilizando o Cartão de
                    Crédito selecionado.
                  </Text>
                </View>
                <ButtonElement
                  styte={styles.tryAgain}
                  text="Tentar Novamente"
                  colorScheme="danger_white"
                  onPress={() => {
                    backToPayment();
                  }}
                />
              </View>
            </View>
          )}
        </View>
      </>
    );
  } else {
    return (
      <>
        <View style={styles.dimmer}>
          <View>
            <ActivityIndicator size="large" color="rgba(160, 162, 173, 1)" />
            <Text style={[styles.processPayment]}>Realizando pagamento...</Text>
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  dimmer: {
    backgroundColor: "rgba(255,255,255,0.85)",
    position: "absolute",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    color: "#444",
    // display:'none'
  },
  dimmerComplete: {
    backgroundColor: "#fff",
  },
  processPayment: {
    fontSize: 14,
    color: "rgba(160, 162, 173, 1)",
    backgroundColor: "#fff",
    textShadowColor: "rgba(255, 255, 255, 0.95)",
    textShadowOffset: { width: -1, height: 1 },
    textShadowRadius: 65,
    height: 90,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 25,
  },
  backgroundShapes: {
    position: "absolute",
    top: "15%",
  },
  backgroundPaymentSuccess: {
    // position:'absolute',
    justifyContent: "center",
    alignItems: "center",
    // bottom:20
    marginTop: "15%",
  },
  iconCheckSuccess: {
    marginLeft: 110,
    marginTop: 90,
  },
  paymentMsg: {
    marginTop: 60,
    width: 250,
    textAlign: "center",
  },
  title: {
    width: 240,
    textAlign: "center",
  },
  credits: {
    position: "absolute",
    top: 33,
  },
  tryAgain: {
    borderWidth: 2,
    borderColor: "#444",
  },
});
