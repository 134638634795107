import React, { Component } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import TutorNotFound from "../../assets/tutorNotFound.svg";
import {
  CircleBackgroundContainer,
  getCircleBackgroundContainerCenter,
} from "../Elements/Containers";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import SearchLevelSubjectBar from "./SearchLevelSubjectBar";

type Props = {
  children?;
  filter?;
  onFilterChanged: (newFilter) => void;
};

type State = {
  searchBarPressed;
};
export default class TutorMatchNoMatch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      searchBarPressed: false,
    };
  }
  resetSearchBar() {
    this.setState({ searchBarPressed: false });
    //    console.log("hi");
  }

  render() {
    return (
      <CircleBackgroundContainer style={{ alignItems: "center" }}>
        <View style={{ position: "absolute", width: "100%" }}>
          {this.state.searchBarPressed && (
            <SearchLevelSubjectBar
              filter={this.props.filter}
              onFilterChanged={this.props.onFilterChanged}
              onSearchBarPressed={true}
              onModalExit={this.resetSearchBar.bind(this)}
            />
          )}
          {!this.state.searchBarPressed && (
            <SearchLevelSubjectBar
              filter={this.props.filter}
              onFilterChanged={this.props.onFilterChanged}
              onSearchBarPressed={false}
              onModalExit={this.resetSearchBar.bind(this)}
            />
          )}
        </View>
        <SVGImage
          source={<TutorNotFound height={132} width={196} />}
          style={{
            marginTop: getCircleBackgroundContainerCenter() - 61,
            height: 132,
            width: 196,
          }}
        />
        <Text style={[styles.textLarge, { marginTop: 10 }]}>
          {"Ops, não encontramos\nnenhum professor"}
        </Text>
        <Text
          style={[
            styles.text,
            {
              marginTop: 16,
            },
          ]}
        >
          {"Tente modificar sua busca\ne tente novamente"}
        </Text>
        <TouchableOpacity
          onPress={() => {
            this.setState({ searchBarPressed: true });
          }}
        >
          <Text
            style={[
              styles.text_underline,
              {
                marginTop: 40,
              },
            ]}
          >
            Alterar busca
          </Text>
        </TouchableOpacity>
      </CircleBackgroundContainer>
    );
  }
}

const styles = StyleSheet.create({
  textLarge: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    textAlign: "center",
  },
  text: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary_light),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
  text_underline: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textDecorationLine: "underline",
    textAlign: "center",
  },
});
