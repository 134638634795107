import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import StyleUtils, { COLOR_PRIMARY } from "../Utils/StyleUtils";
import Modal from "react-native-modal";

type Props = {
  show;
  close;
  buttonText;
  headerText;
  subHeaderText;
};

export default function ParentsTalkNowPopup({
  show,
  close,
  buttonText,
  headerText,
  subHeaderText,
}: Props) {
  return (
    <Modal animationIn="fadeIn" isVisible={show} onBackdropPress={close}>
      <View style={styles.modalView}>
        <Text style={styles.modalTextHeader}>{headerText}</Text>
        <Text style={styles.modalText}>{subHeaderText}</Text>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            {
              height: 48,
              width: 263,
              justifyContent: "center",
              marginTop: 24,
              backgroundColor: COLOR_PRIMARY,
              flexDirection: "row",
              alignItems: "center",
            },
          ]}
          onPress={() => {
            close();
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              {
                textAlign: "center",
                paddingTop: 2,
              },
            ]}
          >
            {buttonText}
          </Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 30,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTextHeader: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    textAlign: "center",
  },
  modalText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "500",
  },
});
