import React, { useEffect, useState, useRef } from "react";
import { View, Text, Animated, Dimensions, StyleSheet } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import LinearGradient from "react-native-linear-gradient";
import MultipleChoicePopup from "../Popups/MultipleChoicePopup";

type Props = {
  onContinue;
  index;
};

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void | null>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (typeof savedCallback.current !== "undefined") {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function StudyPlanProgress(props: Props) {
  const [showQuestion, setShowQuestion] = useState(false);
  const [index, setIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );
  const [finishedQuestions, setFinishedQuestions] = useState(false);
  let { width, height } = Dimensions.get("window");
  height = Math.floor((667 * width) / 750);
  let animation = useRef(new Animated.Value(0));
  const [progress, setProgress] = useState(0);
  const [level, setLevel] = useState("");
  const [schoolYear, setSchoolYear] = useState("");
  const [childAge, setChildAge] = useState("");
  const [childPersonality, setChildPersonality] = useState("");
  const [levelIndex, setLevelIndex] = useState(0);
  const [schoolYearIndex, setSchoolYearIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (finishedQuestions) {
      setTimeout(() => {
        props.onContinue(level, schoolYear, childAge, childPersonality);
      }, 1500);
    }
  }, [finishedQuestions]);

  useInterval(() => {
    if (progress < 100) {
      setProgress(progress + 1);
    }
  }, 35);

  const questions = [
    {
      question: "Qual o nível de Ensino?",
      areas: [
        {
          id: 0,
          name: "Ens. Fundamental",
        },
        {
          id: 1,
          name: "Ens. Médio",
        },
      ],
    },
    {
      question: "E qual a série?",
      areas: [
        {
          id: 0,
          name: "1º ano",
        },
        {
          id: 1,
          name: "2º ano",
        },
        {
          id: 2,
          name: "3º ano",
        },
        {
          id: 3,
          name: "4º ano",
        },
        {
          id: 4,
          name: "5º ano",
        },
        {
          id: 5,
          name: "6º ano",
        },
        {
          id: 6,
          name: "7º ano",
        },
        {
          id: 7,
          name: "8º ano",
        },
        {
          id: 8,
          name: "9º ano",
        },
      ],
    },
    {
      question: "Qual a idade do estudante?",
      areas: [
        {
          id: 0,
          name: "11 anos ou menos",
        },
        {
          id: 1,
          name: "12 anos",
        },
        {
          id: 2,
          name: "13 anos",
        },
        {
          id: 3,
          name: "14 anos",
        },
        {
          id: 4,
          name: "mais de 15 anos",
        },
      ],
    },
    {
      question: "Você acha o estudante mais:",
      areas: [
        {
          id: 0,
          name: "Tímido",
        },
        {
          id: 1,
          name: "Extrovertido",
        },
      ],
    },
  ];

  useEffect(() => {
    if (progress === 80) {
      setShowQuestion(true);
    }
    Animated.timing(animation.current, {
      toValue: progress,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [progress]);

  const widthBar = animation.current.interpolate({
    inputRange: [0, 100],
    outputRange: ["0%", "80%"],
    extrapolate: "clamp",
  });

  const widthBarFinish = animation.current.interpolate({
    inputRange: [0, 100],
    outputRange: ["80%", "100%"],
    extrapolate: "clamp",
  });

  useEffect(() => {
    Dimensions.addEventListener("change", _handleAppStateChange);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  return (
    <View style={[StyleUtils.screen_default]}>
      <LinearGradient
        colors={["#011F42", "#016EE8"]}
        style={[
          {
            paddingVertical: 100,
          },
        ]}
      />
      <View
        style={{
          width: windowWidth,
          height: '100vh',
          borderRadius: windowWidth / 2,
          backgroundColor: showQuestion ? "#c4c4c4" : COLOR_TRUE_WHITE,
          transform: [{ scaleX: 2 }],
          marginTop: 80,
          position: "absolute",
        }}
      />
      <View
        style={{
          alignItems: "center",
          marginHorizontal: 20,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            { textAlign: "center", fontSize: 28 },
          ]}
        >
          Analisando suas respostas e desenvolvendo{"\n"}o plano de estudos
          ideal
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_normal,
            { textAlign: "center", marginVertical: 21 },
          ]}
        >
          Você pode remarcar a aula sem custo nenhum, com até 24 horas de
          antecedência.
        </Text>
        <View style={styles.container}>
          <View style={styles.progressBar}>
            <Animated.View
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: "#50D7A7",
                width: finishedQuestions ? widthBarFinish : widthBar,
                borderRadius: 50,
              }}
            />
          </View>
        </View>
      </View>

      {showQuestion && (
        <View
          style={{
            zIndex: 1,
            position: "absolute",
            alignSelf: "center",
            top: windowWidth < 500 ? 60 : 200,
          }}
        >
          <MultipleChoicePopup
            show={showQuestion}
            close={(res) => {
              switch (index) {
                case 0:
                  setLevelIndex(res.id);
                  setLevel(res.name);
                  break;
                case 1:
                  setSchoolYearIndex(res.id);
                  setSchoolYear(res.name);
                  break;
                case 2:
                  setChildAge(res.name);
                  break;
                case 3:
                  setChildPersonality(res.name);
                  break;
              }
              setShowQuestion(false);
              if (index + 1 < questions.length) {
                setIndex(index + 1);
                setTimeout(() => {
                  setShowQuestion(true);
                }, 300);
              } else {
                setFinishedQuestions(true);
              }
            }}
            question={questions[index].question}
            areas={questions[index].areas}
            level={levelIndex}
            schoolYear={schoolYearIndex}
            width={windowWidth < 500 ? 339 : 784}
            index={props.index}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    width: "80%",
    backgroundColor: "#ecf0f1",
    borderRadius: 50,
    margin: 24,
  },
  progressBar: {
    flexDirection: "row",
    height: 20,
    width: "100%",
    borderRadius: 50,
  },
});
