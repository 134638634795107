import React, { Component } from "react";
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SafeAreaInsetsContext } from "react-native-safe-area-context";
import { connect } from "react-redux";
import { ClassroomTimer } from "../Classroom/ClassroomComponents";
import StyleUtils, { COLOR_DANGER } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import CameraOffIcon from "./../../assets/cameraoff_icon.svg";
import ChatButton from "./../../assets/chatbutton.svg";
import FlipCamera from "./../../assets/flipCamera.svg";
import Calendar from "./../../assets/calendarClass.svg";
import LoadingIndicator from "./../../assets/loadingIndicator.svg";
import WhiteboardButton from "./../../assets/whiteboardbutton.svg";
import Player from "./Player";
import VideoButtons from "./VideoButtons";
import VideoConnection from "./VideoConnection";
import { selectTutor } from "./../Actions";
import { withRouter } from "../Router";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

export enum VideoMode {
  minimized = "minimized",
  mobile = "mobile",
}

type Props = {
  style;
  openChat;
  openWhiteboard;
  selectedUser;
  videoConnection: VideoConnection;
  mobile;
  exit;
  tutorInfo;
  videoMode: VideoMode;
  videoHidden;
  history;
  userID;
  selectTutor;
  openBooking;
  newChatMessage: boolean;
  setNewMessageToFalse;
  whiteboardChanged: boolean;
  setWhiteboardChangedToFalse;
};

type State = {
  myVideoSize;
  audioMuted: boolean;
  videoMuted: boolean;
  reloadKey: string;
  showCalendar: boolean;
  remoteVideoMuted: boolean;
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
  };
}
const mapDispatchToProps = (dispatch) => ({
  selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
});

class VideoHolder extends Component<Props, State> {
  public static defaultProps = {
    videoMode: VideoMode.mobile,
  };

  constructor(props) {
    super(props);
    this.state = {
      myVideoSize: {
        width: 0,
        height: 0,
      },
      audioMuted: props.videoConnection.audioMuted,
      videoMuted: props.videoConnection.videoMuted,
      reloadKey: "key1",
      showCalendar: false,
      remoteVideoMuted: props.videoConnection.remoteVideoMuted,
    };
    RemoteConfigBridge.remoteConfig("showCalendarInClass", (value) => {
      var valueBool = value == "1";
      this.setState({ showCalendar: valueBool });
    });
  }

  componentDidMount() {
    this.linkVideoConnection();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    console.log("remotestream");
    console.log(this.props.videoConnection.remoteStream);
    if (this.props.videoConnection != prevProps.videoConnection) {
      this.linkVideoConnection();
    }
    if (
      this.props.videoConnection.audioMuted != this.state.audioMuted ||
      this.props.videoConnection.videoMuted != this.state.videoMuted ||
      this.props.videoConnection.remoteVideoMuted != this.state.remoteVideoMuted
    ) {
      this.setState({
        audioMuted: this.props.videoConnection.audioMuted,
        videoMuted: this.props.videoConnection.videoMuted,
        remoteVideoMuted: this.props.videoConnection.remoteVideoMuted,
      });
      console.log("current remoteVideoMutedState");
      console.log(this.state.remoteVideoMuted);
    }
  }

  componentWillUnmount() {
    this.props.videoConnection.stateUpdate = null;
  }

  linkVideoConnection = () => {
    if (!this.props.videoConnection) return;
    this.props.videoConnection.stateUpdate = () => {
      let key = this.state.reloadKey === "key1" ? "key2" : "key1";
      this.setState({ reloadKey: key });
      console.log("linkVideoConnection");
    };
  };

  myVideoSize = () => {
    return this.props.mobile
      ? { height: 96, width: 96 }
      : { height: 92, width: 92 };
  };

  renderVideoMain(): React.ReactNode {
    const SCREEN_WIDTH = Dimensions.get("window").width;
    const SCREEN_HEIGHT = Dimensions.get("window").height;
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          <View
            key={"main" + this.state.reloadKey}
            style={[...this.props.style, StyleUtils.color_bg_txtdark]}
          >
            {/*Other Video*/}
            <View
              style={[
                this.props.mobile
                  ? {
                      backgroundColor: "#242838",
                      width: SCREEN_WIDTH,
                      height: SCREEN_WIDTH,
                      position: "absolute",
                      top: (SCREEN_HEIGHT - SCREEN_WIDTH) / 2,
                    }
                  : {
                      flexDirection: "column",
                      width: 336,
                      height: 336,
                      bottom: 0,
                      position: "absolute",
                      justifyContent: "center",
                      backgroundColor: "#242838",
                    },
                ,
              ]}
            >
              <View
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {this.props.selectedUser.imageURL != null && (
                  <Image
                    source={{
                      uri: this.props.selectedUser.imageURL,
                    }}
                    style={{
                      width: 124,
                      height: 124,
                      borderRadius: 6,
                      overflow: "hidden",
                      marginBottom: 16,
                    }}
                  />
                )}
                {!this.props.videoConnection.remoteStream && (
                  <>
                    <SVGImage source={<LoadingIndicator />} />
                    <Text
                      style={[
                        StyleUtils.color_txt_white,
                        StyleUtils.font_size_16,
                        StyleUtils.font_cera_regular,
                        { alignSelf: "center", marginTop: 16 },
                      ]}
                    >
                      Aguardando {this.props.selectedUser.name}
                    </Text>
                  </>
                )}
              </View>
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  bottom: 0,
                  flex: 1,
                  flexGrow: 1,
                }}
                onLayout={(event) => {
                  this.setState({
                    myVideoSize: event.nativeEvent.layout,
                  });
                }}
              >
                <Player
                  clientInitialized={this.props.videoConnection.client}
                  style={[
                    {
                      width: this.state.myVideoSize.width,
                      height: this.state.myVideoSize.height,
                      bottom: 0,
                    },
                  ]}
                  stream={
                    this.props.videoConnection.remoteStream
                      ? this.props.videoConnection.remoteStream
                      : null
                  }
                  isLocalStream={false}
                  channelName={this.props.videoConnection.channel}
                />
                {Platform.OS === "web" && this.state.remoteVideoMuted && (
                  <View
                    style={[
                      {
                        position: "absolute",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        flex: 0,
                        backgroundColor: "#000",
                        width: "100%",
                        height: "100%",
                        bottom: 0,
                      },
                    ]}
                  >
                    <SVGImage source={<CameraOffIcon />} />
                    <Text
                      style={[
                        StyleUtils.color_txt_white,
                        StyleUtils.font_size_16,
                        StyleUtils.font_cera_regular,
                        { alignSelf: "center", marginTop: 16 },
                      ]}
                    >
                      {this.props.selectedUser.name} está online
                    </Text>
                  </View>
                )}
              </View>
            </View>
            {/*My Video*/}
            <View
              style={[
                StyleUtils.shape_btn_small,
                this.props.mobile
                  ? {
                      backgroundColor: "#252939",
                      top: insets ? insets.top : 16,
                      position: "absolute",
                      alignSelf: "center",
                    }
                  : style.myvideoHolder_web,
                this.myVideoSize(),
              ]}
            >
              <Player
                clientInitialized={this.props.videoConnection.client}
                style={[this.myVideoSize()]}
                stream={
                  this.props.videoConnection.localStream
                    ? this.props.videoConnection.localStream
                    : null
                }
                videoMuted={this.state.videoMuted}
                isLocalStream={true}
                channelName={this.props.videoConnection.channel}
              />
              {this.state.videoMuted && (
                <View
                  style={[
                    {
                      position: "absolute",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                    this.myVideoSize(),
                  ]}
                >
                  <SVGImage source={<CameraOffIcon />} />
                </View>
              )}
            </View>

            {/*Buttons*/}
            <SafeAreaInsetsContext.Consumer>
              {(insets) => (
                <View
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    alignItems: this.props.mobile ? "center" : "flex-start",
                    justifyContent: this.props.mobile ? "center" : "flex-start",
                    marginBottom: insets ? insets.bottom : 0,
                  }}
                >
                  <VideoButtons
                    style={
                      this.props.mobile
                        ? style.videobuttons_mobile
                        : style.videobuttons_web
                    }
                    mobile={this.props.mobile}
                    exit={this.props.exit}
                    audioMuted={this.props.videoConnection.audioMuted}
                    videoMuted={this.props.videoConnection.videoMuted}
                    muteAudio={() => {
                      this.props.videoConnection.toggleAudio();
                      this.setState({
                        audioMuted: this.props.videoConnection.audioMuted,
                      });
                    }}
                    muteVideo={() => {
                      this.props.videoConnection.toggleVideo();
                      this.setState({
                        videoMuted: this.props.videoConnection.videoMuted,
                      });
                    }}
                  />
                  {this.props.mobile && (
                    <View
                      style={{
                        alignSelf: "flex-end",
                        flexDirection: "row",
                        marginBottom: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          width: 56,
                          height: 56,
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                        onPress={() => {
                          this.props.openWhiteboard();
                          this.props.setWhiteboardChangedToFalse();
                        }}
                      >
                        <View style={{ position: "relative" }}>
                          <SVGImage
                            source={<WhiteboardButton />}
                            style={{ width: 56, height: 56 }}
                          />
                          {this.props.whiteboardChanged && (
                            <View
                              style={[
                                {
                                  left: 38,
                                  position: "absolute",
                                  height: 16,
                                  backgroundColor: COLOR_DANGER,
                                  borderRadius: 8,
                                  width: 16,
                                },
                              ]}
                            />
                          )}
                        </View>
                      </TouchableOpacity>
                      {this.props.openChat && (
                        <TouchableOpacity
                          style={{
                            width: 56,
                            height: 56,
                            marginLeft: 5,
                            marginRight: 5,
                          }}
                          onPress={() => {
                            this.props.openChat();
                            this.props.setNewMessageToFalse();
                          }}
                        >
                          <View style={{ position: "relative" }}>
                            <SVGImage
                              source={<ChatButton />}
                              style={{ width: 56, height: 56 }}
                            />
                            {this.props.newChatMessage && (
                              <View
                                style={[
                                  {
                                    left: 38,
                                    position: "absolute",
                                    height: 16,
                                    backgroundColor: COLOR_DANGER,
                                    borderRadius: 8,
                                    width: 16,
                                  },
                                ]}
                              />
                            )}
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                  )}
                </View>
              )}
            </SafeAreaInsetsContext.Consumer>

            {Platform.OS !== "web" && (
              <>
                {!!this.state.showCalendar && !this.props.tutorInfo && (
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      top: insets ? insets.top + 8 : 16,
                      left: 16,
                    }}
                    onPress={this.props.openBooking}
                  >
                    <SVGImage source={<Calendar />} />
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  style={{
                    position: "absolute",
                    top: insets ? insets.top + 8 : 16,
                    right: 16,
                  }}
                  onPress={() => {
                    this.props.videoConnection.switchCamera();
                  }}
                >
                  <SVGImage source={<FlipCamera />} />
                </TouchableOpacity>
              </>
            )}
            <ClassroomTimer
              style={[
                StyleUtils.color_bg_true_white,
                StyleUtils.border_color_btn_muted,
                {
                  position: "absolute",
                  top: this.props.mobile
                    ? insets
                      ? !!this.state.showCalendar && !this.props.tutorInfo
                        ? insets.top + 72
                        : insets.top + 8
                      : 16
                    : 120,
                  left: 16,
                  borderRadius: 30,
                  width: 92,
                },
              ]}
              textStyle={[
                StyleUtils.font_btn_regular,
                StyleUtils.font_size_14,
                { textAlign: "center", marginVertical: 2 },
              ]}
              showOnlyAfterEndingThreshold={!this.props.tutorInfo}
              timeUnit="minutes"
            />
          </View>
        )}
      </SafeAreaInsetsContext.Consumer>
    );
  }

  renderVideoMinimized(): React.ReactNode {
    return (
      this.props.mobile &&
      this.props.videoConnection && (
        <SafeAreaInsetsContext.Consumer>
          {(insets) => (
            <View
              style={[
                this.props.videoHidden ? StyleUtils.hidden : null,
                {
                  position: "absolute",
                  alignSelf: "center",
                  top: insets
                    ? insets.top + (Platform.OS == "ios" ? 0 : 10)
                    : 10,
                  flexDirection: "row",
                },
              ]}
              key={"minimized" + this.state.reloadKey}
            >
              <View
                style={[
                  StyleUtils.shape_btn_default,
                  {
                    width: 80,
                    height: 80,
                    margin: 0,
                    marginLeft: 8,
                    marginRight: 8,
                    overflow: "hidden",
                  },
                ]}
              >
                <Player
                  clientInitialized={this.props.videoConnection.client}
                  style={[{ width: 80, height: 80 }]}
                  stream={
                    this.props.videoConnection.localStream
                      ? this.props.videoConnection.localStream
                      : null
                  }
                  videoMuted={this.state.videoMuted}
                  isLocalStream={true}
                  channelName={this.props.videoConnection.channel}
                />
              </View>
              <View
                style={[
                  StyleUtils.shape_btn_default,
                  {
                    width: 80,
                    height: 80,
                    margin: 0,
                    marginLeft: 8,
                    marginRight: 8,
                    overflow: "hidden",
                  },
                ]}
              >
                <Player
                  clientInitialized={this.props.videoConnection.client}
                  style={[{ width: 80, height: 80 }]}
                  stream={
                    this.props.videoConnection.remoteStream
                      ? this.props.videoConnection.remoteStream
                      : null
                  }
                  isLocalStream={false}
                  channelName={this.props.videoConnection.channel}
                />
              </View>
            </View>
          )}
        </SafeAreaInsetsContext.Consumer>
      )
    );
  }

  render() {
    console.log(this.props.videoMode);
    switch (this.props.videoMode) {
      case VideoMode.minimized:
        return this.renderVideoMinimized();
      case VideoMode.mobile:
        return this.renderVideoMain();
    }
  }
}

const style = StyleSheet.create({
  videobuttons_mobile: {
    flexDirection: "row",
    marginBottom: 20,
    alignSelf: "flex-end",
  },
  videobuttons_web: {
    flexDirection: "row",
    marginLeft: "50%",
    marginTop: 36,
    width: "50%",
  },
  myvideoHolder_web: {
    flex: 1,
    left: 16,
    top: 16,
    position: "absolute",
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoHolder)
);
