import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import BackgroundShapesGreen from "../../../assets/greenShapes.svg";
import { analytics } from "../../Analytics";
import { UserImage } from "../../Elements/UserImages";
import StyleUtils, {
  COLOR_TXT_DARK,
  COLOR_SUCCESS,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import * as Utils from "../../Utils/Utils";
import Check from "../../../assets/checkGreen.svg";

type Props = {
  tutorImage;
  lessonDate;
  lessonEndDate;
  tutorName;
  history;
  classroomEnd;
  numRecurringClass;
  tutorID;
};

type State = {
  message;
  dates;
};

const styles = StyleSheet.create({
  textBoldBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
  },
  textRegular: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
});

export default class ParentsSessionScheduled extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      dates: null,
    };
  }

  componentDidMount() {
    let message =
      "Olá " +
      this.props.tutorName.split(" ")[0] +
      ", agendei uma sessão com você no dia " +
      `${Utils.dateFormatLongDateNoYear(
        new Date(this.props.lessonDate)
      )}`.split(", ")[1] +
      " às " +
      `${Utils.dateFormatHHhmm(new Date(this.props.lessonDate))}` +
      ". Até mais!";
    let dates = Utils.dateFormatLongDateNoYear(new Date(this.props.lessonDate));
    analytics.sendClassScheduledShow();
    this.setState({ message: message, dates: dates });
  }

  render() {
    return (
      <View
        style={[
          StyleUtils.screen_default,
          {
            flex: 6,
            flexDirection: "column",
            justifyContent: "space-between",
          },
        ]}
      >
        <SVGImage
          source={<BackgroundShapesGreen />}
          style={{
            marginTop: 14,
            zIndex: 0,
            position: "absolute",
            width: "100%",
            height: 332,
            alignItems: "center",
          }}
        />
        <View style={{ zIndex: 1 }}></View>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <UserImage
            style={{ width: 148, height: 174, borderRadius: 15 }}
            imageURL={this.props.tutorImage}
          />
          <View style={{ flexDirection: "row" }}>
            <Text
              style={[
                StyleUtils.font_size_14,
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_success,
                {
                  backgroundColor: "#DBF4E4",
                  borderRadius: Platform.OS === "ios" ? 10 : 50,
                  overflow: "hidden",
                  paddingLeft: 10,
                  paddingRight: 30,
                  marginTop: 12,
                },
              ]}
            >
              Sessão Agendada
            </Text>
            <SVGImage
              source={<Check />}
              style={{
                position: "absolute",
                top: Platform.OS === "ios" ? 12 : 14,
                right: 8,
                zIndex: 0,
              }}
            />
          </View>

          <Text style={[styles.textBoldBlack, { marginTop: 24 }]}>
            {this.props.tutorName}
          </Text>

          <Text style={[styles.textRegular, { color: COLOR_TXT_DARK }]}>
            {this.state.dates}
            {"\n"}Horário:{" "}
            {Utils.dateFormatHHhmm(new Date(this.props.lessonDate))}-
            {Utils.dateFormatHHhmm(new Date(this.props.lessonEndDate))}
          </Text>
          <Text
            style={[
              styles.textRegular,
              StyleUtils.color_txt_light,
              {
                textAlign: "center",
                marginTop: 16,
              },
            ]}
          >
            Você será notificado alguns minutos {"\n"} antes do início da
            conversa
          </Text>
        </View>
        <View style={{ justifyContent: "flex-end" }}>
          <TouchableOpacity
            style={[
              {
                margin: 24,
                backgroundColor: COLOR_SUCCESS,
              },
              StyleUtils.shape_btn_small,
            ]}
            onPress={() => {
              this.props.history.push({
                pathname: "/messages/" + this.props.tutorID,
                state: {
                  schedule: true,
                  messages: this.state.message,
                },
              });
            }}
          >
            <Text
              style={[
                { textAlign: "center", paddingVertical: 14 },
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                StyleUtils.color_txt_white,
              ]}
            >
              Finalizar
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
