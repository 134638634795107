import React from "react";
import { Component } from "react";
import { TouchableOpacity, View } from "react-native";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import {
  renderPencilColorButtonText,
  renderPencilStrokePicker,
} from "./CanvasPencilTools";
import SVGImage from "../Utils/SVGImage";
import IcoAlignCenter from "../../assets/ico_align_center.svg";
import IcoAlignLeft from "../../assets/ico_align_left.svg";
import IcoAlignRight from "../../assets/ico_align_right.svg";
import IcoBold from "../../assets/ico_bold.svg";
import IcoItalic from "../../assets/ico_italic.svg";
import IcoFontDecrease from "../../assets/iconFontDecrease.svg";
import IcoFontIncrease from "../../assets/iconFontIncrease.svg";

import IcoAlignCenterSelected from "../../assets/ico_align_center_selected.svg";
import IcoAlignLeftSelected from "../../assets/ico_align_left_selected.svg";
import IcoAlignRightSelected from "../../assets/ico_align_right_selected.svg";
import IcoBoldSelected from "../../assets/ico_bold_selected.svg";
import IcoItalicSelected from "../../assets/ico_italic_selected.svg";

type Props = { sendToCanvas; sendRemote; mobile; zoom? };

type State = {
  submenuOpen;
  selectedColor;
  textID;
  textAlign;
  fontWeight;
  fontStyle;
  textControlsPosition;
  canvasSize;
  canvasMargin;
  canvasFixedSize;
};

export default class CanvasTextTools extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: "",
      selectedColor: "",
      textControlsPosition: null,
      canvasSize: null,
      canvasMargin: null,
      canvasFixedSize: null,
      textID: null,
      textAlign: null,
      fontWeight: null,
      fontStyle: null,
    };
  }

  render() {
    return (
      <View
        style={{
          position: "absolute",
          flex: 1,
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        }}
      >
        {this.state.textControlsPosition && this.renderTextTools()}
        {this.state.textControlsPosition &&
          this.state.submenuOpen.includes("pencil-color") &&
          renderPencilStrokePicker(
            this.state.textControlsPosition.left *
              (this.state.canvasSize.width / this.state.canvasFixedSize.width) +
              this.state.canvasMargin.width +
              (this.props.mobile ? 0 : 70),
            -this.state.textControlsPosition.top *
              (this.state.canvasSize.height /
                this.state.canvasFixedSize.height) -
              this.state.canvasMargin.height +
              50,
            (color) => {
              this.props.sendToCanvas("text:color", {
                color: color,
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: color,
                textID: this.state.textID,
              });
            },
            (state) => this.setState(state)
          )}
      </View>
    );
  }

  renderTextTools() {
    return (
      <View
        style={[
          StyleUtils.shape_btn_small,
          StyleUtils.border_color_btn_muted,
          StyleUtils.color_bg_white,
          {
            shadowOpacity: 0.05,
            shadowRadius: 4,
            shadowOffset: { width: 0, height: 4 },
            flexDirection: "row",
            position: "absolute",
            left:
              this.state.textControlsPosition.left *
                (this.state.canvasSize.width /
                  this.state.canvasFixedSize.width) +
              this.state.canvasMargin.width +
              (this.props.mobile ? 0 : 70),
            top:
              this.state.textControlsPosition.top *
                (this.state.canvasSize.height /
                  this.state.canvasFixedSize.height) +
              this.state.canvasMargin.height -
              45,
          },
        ]}
      >
        {renderPencilColorButtonText(
          this.state.submenuOpen,
          (state) => this.setState(state),
          this.state.selectedColor
        )}
        <View style={{ width: 1, backgroundColor: COLOR_MUTED }} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({ textAlign: "left" });
              this.props.sendToCanvas("text:color", {
                color: "textAlignLeft",
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: "textAlignLeft",
                textID: this.state.textID,
              });
            }}
          >
            <SVGImage
              source={
                this.state.textAlign === "left" ? (
                  <IcoAlignLeftSelected />
                ) : (
                  <IcoAlignLeft />
                )
              }
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.setState({ textAlign: "center" });
              this.props.sendToCanvas("text:color", {
                color: "textAlignCenter",
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: "textAlignCenter",
                textID: this.state.textID,
              });
            }}
          >
            <SVGImage
              source={
                this.state.textAlign === "center" ? (
                  <IcoAlignCenterSelected />
                ) : (
                  <IcoAlignCenter />
                )
              }
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.setState({ textAlign: "right" });
              this.props.sendToCanvas("text:color", {
                color: "textAlignRight",
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: "textAlignRight",
                textID: this.state.textID,
              });
            }}
          >
            <SVGImage
              source={
                this.state.textAlign === "right" ? (
                  <IcoAlignRightSelected />
                ) : (
                  <IcoAlignRight />
                )
              }
            />
          </TouchableOpacity>
        </View>
        <View style={{ width: 1, backgroundColor: COLOR_MUTED }} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              const newState =
                this.state.fontWeight === "bold" ? "fontWeightNormal" : "bold";
              this.setState({ fontWeight: newState });
              this.props.sendToCanvas("text:color", {
                color: newState,
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: newState,
                textID: this.state.textID,
              });
            }}
          >
            <SVGImage
              source={
                this.state.fontWeight === "bold" ? (
                  <IcoBoldSelected />
                ) : (
                  <IcoBold />
                )
              }
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              const newState =
                this.state.fontStyle === "italic"
                  ? "fontStyleNormal"
                  : "italic";
              this.setState({ fontStyle: newState });
              this.props.sendToCanvas("text:color", {
                color: newState,
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: newState,
                textID: this.state.textID,
              });
            }}
          >
            <SVGImage
              source={
                this.state.fontStyle === "italic" ? (
                  <IcoItalicSelected />
                ) : (
                  <IcoItalic />
                )
              }
            />
          </TouchableOpacity>
        </View>
        <View style={{ width: 1, backgroundColor: COLOR_MUTED }} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.props.sendToCanvas("text:color", {
                color: "font:decreased",
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: "font:decreased",
                textID: this.state.textID,
              });
            }}
            style={{ marginRight: 8 }}
          >
            <SVGImage source={<IcoFontDecrease />} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.sendToCanvas("text:color", {
                color: "font:increased",
                textID: this.state.textID,
              });
              this.props.sendRemote({
                action: "text:color",
                color: "font:increased",
                textID: this.state.textID,
              });
            }}
          >
            <SVGImage source={<IcoFontIncrease />} />
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  handleCanvasAction = (data) => {
    switch (data.info.action) {
      case "canvas:layout":
        this.setState({
          canvasSize: data.info.canvasSize,
          canvasMargin: data.info.canvasMargin,
          canvasFixedSize: data.info.canvasFixedSize,
        });
        break;
      case "object:added":
        if (data.info.target.type !== "textbox") return;
      case "editing:entered":
        console.log(data.info.target.top);
        if (this.props.zoom) {
          data.info.target.top = data.info.target.top * 1.5 - 20;
        }
        this.setState({
          textID: data.info.target.id,
          textControlsPosition: {
            top: data.info.target.top,
            left: data.info.target.left,
          },
          selectedColor: data.info.target.fill,
          textAlign:
            data.info.target.textAlign !== undefined
              ? data.info.target.textAlign
              : "left",
          fontWeight: data.info.target.fontWeight,
          fontStyle: data.info.target.fontStyle,
        });
        break;
      case "editing:exited":
        this.setState({
          submenuOpen: "",
          textID: null,
          textControlsPosition: null,
          selectedColor: null,
          textAlign: null,
          fontWeight: null,
          fontStyle: null,
        });
        break;
    }
  };
}
