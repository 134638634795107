import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  ScrollView,
  SafeAreaView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  areas;
  onContinue;
  onBack;
  headerText;
  getInitialValue: () => string;
};

type State = {
  selectedSingle: string | null;
};

export default class ChooseInterest extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialValue = props.getInitialValue();
    this.state = {
      selectedSingle: initialValue
        ? props.areas.filter((area) => area.name === initialValue)[0]
        : null,
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text
          style={[
            styles.cellText,
            isSelectedArea && { color: COLOR_WHITE, textAlign: "center" },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{
            flex: 1,
            flexDirection: "column",
            backgroundColor: COLOR_TRUE_WHITE,
          }}
        >
          <TouchableOpacity
            style={[
              {
                alignSelf: "flex-start",
                width: "15%",
                aspectRatio: 1,
                alignItems: "center",
                justifyContent: "center",
                marginHorizontal: 16,
                marginTop: 16,
              },
            ]}
            onPress={() => this.props.onBack()}
          >
            <SVGImage source={<BackArrow />} />
          </TouchableOpacity>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              paddingHorizontal: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  width: "100%",
                  marginBottom: 20,
                },
              ]}
            >
              {this.props.headerText}
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.renderLevelCell(this.props.areas[0])}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.renderLevelCell(this.props.areas[1])}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {this.renderLevelCell(this.props.areas[2])}
            </View>
          </View>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Próximo"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (this.state.selectedSingle) {
                this.props.onContinue(this.state.selectedSingle);
              }
            }}
            disabled={!this.state.selectedSingle}
          />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
  cellBase: {
    height: 88,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 20,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY,
  },
});
