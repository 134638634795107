const initialState = {
  selectedTutor: null,
  selectedEvent: null,
  selectedUser: null,
  bookedEvent: null,

  scheduleDraftDays: ["1", "2", "3", "4", "5"],
  scheduleDraftHours: {
    start: "09:00",
    end: "17:00",
  },

  selectedImagePopup: null,
  selectedHomework: null,

  searchedTutors: [],
  useSearchedTutors: false,
  selectedSubjectTest: null,
};
const selectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SELECTED_TUTOR":
      return {
        ...state,
        selectedTutor: action.selectedTutor,
      };
    case "SUPER_TUTOR":
      return {
        ...state,
        superTutor: action.superTutor,
      };
    case "LESSON_INFO":
      return {
        ...state,
        lessonInfo: action.lessonInfo,
      };
    case "PRICE_INFO":
      return {
        ...state,
        priceInfos: action.priceInfos,
      };
    case "SELECTED_EVENT":
      return {
        ...state,
        selectedEvent: action.selectedEvent,
      };
    case "SELECTED_IMAGE_POPUP":
      return {
        ...state,
        selectedImagePopup: action.selectedImagePopup,
      };
    case "SELECTED_USER":
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    case "BOOKED_EVENT":
      return {
        ...state,
        bookedEvent: action.bookedEvent,
      };
    case "SCHEDULE_DRAFT_DAYS_CHOSEN":
      return {
        ...state,
        scheduleDraftDays: action.scheduleDraftDays,
      };
    case "SCHEDULE_DRAFT_HOURS_CHOSEN":
      return {
        ...state,
        scheduleDraftHours: action.scheduleDraftHours,
      };
    case "SELECTED_HOMEWORK":
      return {
        ...state,
        selectedHomework: action.selectedHomework,
      };
    case "TUTOR_SEARCH": {
      const { searchedTutors, useSearchedTutors } = action;
      return { ...state, searchedTutors, useSearchedTutors };
    }
    case "TUTOR_SEARCH_BACK": {
      const { useSearchedTutors } = action;
      return { ...state, useSearchedTutors };
    }
    case "LOGOUT":
      return initialState;
    case "SELECTED_SUBJECT_TEST":
      return {
        ...state,
        selectedSubjectTest: action.selectedSubjectTest,
      };
    default:
      return state;
  }
};

export default selectionReducer;
