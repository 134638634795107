import React from "react";
import { View, Text } from "react-native";
import StyleUtils from "../Utils/StyleUtils";

type Props = {
  style?: any;
};

const AssignHomeworkHeader = (props: Props) => {
  return (
    <View
      style={[
        StyleUtils.color_bg_dark,
        {
          height: 60,
          flexWrap: "wrap",
          flexDirection: "row",
          alignContent: "center",
          padding: 24,
        },
        props.style,
      ]}
    >
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_white,
          StyleUtils.font_size_18,
          props.style,
        ]}
      >
        {"Selecione exercícios "}
      </Text>
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_white,
          StyleUtils.font_size_18,
          props.style,
        ]}
      >
        e envie aos seus alunos
      </Text>
    </View>
  );
};

export default AssignHomeworkHeader;
