import React from "react";
import { Component } from "react";
import {
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  View,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import Calendar from "./Calendar";
import AvailableHours from "./AvailableHours";
import SVGImage from "../Utils/SVGImage";
import Pencil from "../../assets/pencil.svg";
import { SCREEN_SIZE_500 } from "../Utils/SizeUtils";
import { connect } from "react-redux";

type Props = {
  history;
  tutorInfo;
  userID;
};

type State = {
  selected: Date;
  full_layout: boolean;
};

function stateToProps(state) {
  return {
    tutorInfo: state.userRed.tutorInfo,
    userID: state.userRed.user.id,
  };
}

class Schedule extends Component<Props, State> {
  constructor(props) {
    super(props);
    const refDate = new Date();
    refDate.setHours(0, 0, 0, 0);
    this.state = {
      selected: refDate,
      full_layout: false,
    };
  }

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let full = layout.width < SCREEN_SIZE_500;
    if (full != this.state.full_layout) {
      this.setState({
        full_layout: full,
      });
    }
  };

  render() {
    return (
      <View onLayout={this.onLayout} style={[StyleUtils.screen_default]}>
        {!this.state.full_layout && (
          <View style={{ marginLeft: "10%", marginRight: "10%" }}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 2 }}>
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_size_28,
                    StyleUtils.font_btn_bold,
                    { marginTop: 33 },
                  ]}
                >
                  Meus horários
                </Text>
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.color_txt_light,
                    { marginTop: 12, marginBottom: 39 },
                  ]}
                >
                  Consulte e gerencie seus horários de atendimento com seus
                  alunos
                </Text>
              </View>
              <View style={{ justifyContent: "center" }}>
                <TouchableOpacity
                  onPress={() => {
                    this.props.history.push("/scheduleday");
                  }}
                >
                  <View
                    style={[
                      StyleUtils.border_color_btn_primary,
                      StyleUtils.shape_btn_default_rounded,
                      { flexDirection: "row" },
                    ]}
                  >
                    <SVGImage
                      style={{ margin: 8, marginLeft: 12 }}
                      source={<Pencil />}
                    />
                    <Text
                      style={[
                        StyleUtils.color_txt_primary,
                        StyleUtils.font_btn_bold,
                        StyleUtils.font_size_14,
                        { margin: 8, marginLeft: 0, marginRight: 12 },
                      ]}
                    >
                      Editar horários
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={[StyleUtils.border_color_btn_muted, { marginBottom: 64 }]}
            />
          </View>
        )}

        {this.state.full_layout && (
          <View style={[StyleUtils.color_bg_dark, { flexDirection: "row" }]}>
            <Text
              style={[
                ,
                StyleUtils.font_size_18,
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_light,
                { padding: 14, paddingLeft: 24, flex: 1 },
              ]}
            >
              Meus horários
            </Text>
            <TouchableOpacity
              style={{ justifyContent: "center" }}
              onPress={() => {
                this.props.history.push("/scheduleday");
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_14,
                  { marginRight: 16 },
                ]}
              >
                Editar
              </Text>
            </TouchableOpacity>
          </View>
        )}

        <View style={this.state.full_layout ? style.full : style.half}>
          <Calendar
            style={[
              this.state.full_layout
                ? style.calendar_full
                : style.calendar_half,
            ]}
            selectedDate={this.state.selected}
            changeDate={(date) => {
              this.setState({
                selected: date,
              });
            }}
          />
          <AvailableHours
            style={[
              this.state.full_layout ? style.hours_full : style.hours_half,
              { marginTop: 20 },
            ]}
            selectedDate={this.state.selected}
            selectedTutor={this.props.tutorInfo}
            selectedUser={this.props.userID}
            selectTime={(time) => {}}
          />
        </View>
      </View>
    );
  }
}

const style = StyleSheet.create({
  half: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-around",
    flexGrow: 0,
    alignItems: "center",
    alignSelf: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  full: {},
  hours_half: {
    flex: 1,
    minWidth: 200,
    maxWidth: 375,
    margin: 8,
  },
  hours_full: {
    flex: 1,
    minWidth: 200,
    maxWidth: 375,
    margin: 24,
  },
  calendar_half: {
    flex: 1,
    minWidth: 200,
    maxWidth: 375,
    marginRight: "5%",
    borderRadius: 10,
  },
  calendar_full: {},
});

export default connect(stateToProps, null)(Schedule);
