import React, { useEffect, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import BackButton from "../../assets/backButtonBorder.svg";
import Rating from "../Models/Rating";
import { getRatings } from "../Server";
import StyleUtils, { COLOR_MUTED, COLOR_WHITE } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import CommentCard from "./CommentCard";

type Props = {
  history;
  selectedTutor;
  userID?;
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
    selectedTutor: state.selRed.selectedTutor,
  };
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

function TutorCommentsList(props: Props) {
  const [ratings, setRatings] = useState<Rating[]>([]);
  const tutor = props.selectedTutor;

  useEffect(() => {
    getRatings(props.userID, {
      tutorId: tutor.id,
      hasComment: false,
      limit: 10,
      offset: 0,
      olderThan: undefined,
    })
      .then((r) => {
        console.log(r.ratings);
        let arr: Rating[] = [];
        for (const item in r.ratings) {
          if (r.ratings[item].rating !== null) {
            arr.push(r.ratings[item]);
          }
        }
        setRatings(arr);
      })
      .catch(console.log);
  }, []);

  return (
    <View style={StyleUtils.screen_default}>
      <View
        style={[
          {
            backgroundColor: COLOR_WHITE,
            justifyContent: "center",
            width: "100%",
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: 16,
          }}
        >
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                props.history.goBack();
              }}
            >
              <SVGImage source={<BackButton />} />
            </TouchableOpacity>
          </View>

          <View
            style={{
              alignContent: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
                { textAlign: "center" },
              ]}
            >
              Comentários
            </Text>
          </View>
          <View style={{ flex: 1 }}></View>
        </View>
      </View>
      <View
        style={{
          borderBottomColor: COLOR_MUTED,
          borderBottomWidth: 1,
          width: "100%",
          zIndex: 10,
          shadowColor: "#000000",
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowRadius: 3,
          shadowOpacity: 0.3,
          marginBottom: 25,
        }}
      />
      <FlatList
        style={{
          flex: 1,
          width: "100%",
        }}
        data={ratings}
        renderItem={(item) => <CommentCard rating={item.item} />}
        keyExtractor={(item, index) => item.id || index.toString()}
      />
    </View>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorCommentsList);
