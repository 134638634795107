import Homework, {
  HomeworkState,
  homeworkState,
  homeworkStats,
} from "../Models/Homework";

const initialState = {
  homeworks: null as Homework[] | null,
  pendingAnswer: 0,
  pendingCorrection: 0,
  pendingComplete: 0,
  notification: false,
};

const homeworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HOMEWORKS":
      let homeworks: Homework[] = action.homeworks || [];
      const otherStates = homeworks.reduce(
        (acc, homework) => {
          const hmState = homeworkState(homework);
          const stats = homeworkStats(homework);
          if (hmState == HomeworkState.SUBMITTED) {
          }
          if (hmState < HomeworkState.COMPLETED) {
            acc.pendingComplete += stats.reviewed;
            acc.pendingAnswer += stats.pending;
            acc.pendingAnswer += stats.reviewed;
            if (
              hmState == HomeworkState.SUBMITTED ||
              hmState == HomeworkState.CORRECTED
            ) {
              acc.notification = acc.notification || !action.isTutor;
            } else if (hmState == HomeworkState.ANSWERED) {
              acc.notification = acc.notification || !!action.isTutor;
              acc.pendingCorrection += stats.pendingCorrection;
            }
          }
          return acc;
        },
        {
          pendingAnswer: 0,
          pendingCorrection: 0,
          pendingComplete: 0,
          notification: false,
        }
      );
      return {
        ...state,
        homeworks: homeworks,
        ...otherStates,
      };
    case "CLEAR_HOMEWORK_NOTIFICATION":
      return {
        ...state,
        notification: false,
      };
    default:
      return state;
  }
};
export default homeworkReducer;
