import React from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import StyleUtils, { COLOR_PRIMARY } from "../../Utils/StyleUtils";
import LottieHolder from "../../Lottie/LottieHolder";

type Props = {
  history;
};

const EmptyCard = (props: Props) => {
  return (
    <View style={[styles.mobileCard]}>
      <LottieHolder
        source={require("../../../assets/lottie/animacao-search/data.json")}
        imageAssetsFolder={"lottie/animacao-search/images/"}
        autoPlay
        loop
        style={{
          width: "60%",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      />
      <Text
        style={[
          StyleUtils.color_txt_dark,
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_18,
          {
            textAlign: "center",
            marginVertical: 20,
          },
        ]}
      >
        Você ainda não tem professor
      </Text>
      <View
        style={{
          flexDirection: "row",
          marginBottom: 16,
          height: 48,
        }}
      >
        <TouchableOpacity
          onPress={() =>
            props.history.push({
              pathname: "/landing/subjects",
              state: {
                showFooter: true,
              },
            })
          }
          style={[
            {
              borderRadius: 6,
              justifyContent: "center",
              alignSelf: "stretch",
              flex: 1,
              marginHorizontal: 24,
              backgroundColor: COLOR_PRIMARY,
            },
          ]}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              {
                paddingTop: Platform.OS === "ios" ? 4 : 0,
                textAlign: "center",
              },
            ]}
          >
            Encontrar Professor
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mobileCard: {
    ...StyleSheet.flatten(StyleUtils.shape_card_default),
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
    height: 350,
    justifyContent: "flex-end",
  },
  border_white: {
    ...StyleSheet.flatten(StyleUtils.border_color_txt_white),
    borderWidth: 0,
  },
  border_primary: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_primary),
    borderWidth: 0,
  },
  border_success: {
    ...StyleSheet.flatten(StyleUtils.border_color_btn_success),
    borderWidth: 0,
  },
  button_white: {
    ...StyleSheet.flatten(StyleUtils.color_bg_white),
  },
  button_primary: {
    ...StyleSheet.flatten(StyleUtils.color_btn_primary),
  },
  button_success: {
    ...StyleSheet.flatten(StyleUtils.color_btn_success),
  },
  button_text_white: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  button_text_primary: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
  },
  button_text_success: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_success),
  },
});

export default EmptyCard;
