import React from "react";
import { Component } from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import { connect } from "react-redux";
import StyleUtils from "../Utils/StyleUtils";
import Calendar from "../Schedule/Calendar";
import DayEvents from "./DayEvents";
import * as Utils from "../Utils/Utils";
import { EventsFilter, getEvents } from "../Server";
import { Platform } from "react-native";

const EVENT_INTERVAL = 60 * 60; // 30 minutes

type Props = {
  history;
  user?;
  tutorInfo?;
};

type State = {
  selectedDate: Date;
  full_layout: boolean;
  month: Date;
  daysWithEvents: Set<number>;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
  };
}

class Events extends Component<Props, State> {
  constructor(props) {
    super(props);
    const now = Utils.nextDay(new Date(), 0);
    this.state = {
      selectedDate: now,
      full_layout: Platform.OS !== "web",
      month: Utils.nextMonth(now, 0),
      daysWithEvents: new Set<number>(),
    };
  }

  componentDidMount() {
    this.fetchMonthlyEvents(this.state.month);
  }

  render() {
    return (
      <View style={[StyleUtils.screen_default]}>
        {!this.state.full_layout && (
          <View style={{ marginLeft: "10%", marginRight: "10%" }}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 2 }}>
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_size_28,
                    StyleUtils.font_btn_bold,
                    { marginTop: 33 },
                  ]}
                >
                  Minhas aulas
                </Text>
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.color_txt_light,
                    { marginTop: 12, marginBottom: 39 },
                  ]}
                >
                  {this.props.tutorInfo
                    ? "Visualize as aulas agendadas pelos seus alunos."
                    : "Visualize as aulas agendadas com seus tutores."}
                </Text>
              </View>
            </View>
            <View
              style={[StyleUtils.border_color_btn_muted, { marginBottom: 64 }]}
            />
          </View>
        )}

        {this.state.full_layout && (
          <Text
            style={[
              StyleUtils.color_bg_dark,
              StyleUtils.font_size_18,
              StyleUtils.font_btn_bold,
              StyleUtils.color_txt_light,
              { padding: 14, paddingLeft: 24 },
            ]}
          >
            Minhas aulas
          </Text>
        )}

        <View style={this.state.full_layout ? style.full : style.half}>
          <CalendarContainer mobile={this.state.full_layout}>
            <Calendar
              style={[
                this.state.full_layout
                  ? style.calendar_full
                  : style.calendar_half,
              ]}
              selectedDate={this.state.selectedDate}
              changeDate={(date) => {
                this.setState({
                  selectedDate: date,
                });
              }}
              month={this.state.month}
              onMonthChange={(month) => this.fetchMonthlyEvents(month)}
              dayOptions={(date) => {
                const hasEvent = this.state.daysWithEvents.has(date.getTime());
                if (this.props.tutorInfo) {
                  let availableDay = false;
                  if (
                    this.props.tutorInfo.schedule &&
                    this.props.tutorInfo.schedule.length >= 14
                  ) {
                    const startHour =
                      Math.floor(
                        this.props.tutorInfo.schedule[2 * date.getDay()] / 60
                      ) * 60;
                    const endHours =
                      Math.floor(
                        this.props.tutorInfo.schedule[2 * date.getDay() + 1] /
                          60
                      ) * 60;
                    for (
                      let hour = startHour;
                      hour < endHours;
                      hour += EVENT_INTERVAL
                    ) {
                      if (date.getDay() == 0 || date.getDay() == 6) {
                        // TODO remove saturday and sunday
                        continue;
                      }
                      availableDay = true;
                      break;
                    }
                  }
                  return {
                    highlighted: hasEvent || availableDay,
                    checked: hasEvent,
                  };
                } else {
                  return { highlighted: hasEvent, checked: hasEvent };
                }
              }}
            />
            <DayEvents
              style={[
                this.state.full_layout ? style.events_full : style.events_half,
                { alignSelf: "flex-start" },
              ]}
              selectedDate={this.state.selectedDate}
            />
          </CalendarContainer>
        </View>
      </View>
    );
  }

  fetchMonthlyEvents(month: Date) {
    if (this.props.user) {
      const currentMonth = Utils.nextMonth(month, 0);
      const nextMonth = Utils.nextMonth(month, 1);

      var eventFilter: EventsFilter = {
        tutorId: undefined,
        tuteeId: undefined,
        periodStart: currentMonth.toISOString(),
        periodEnd: nextMonth.toISOString(),
        limit: undefined,
      };
      if (this.props.user) {
        if (this.props.tutorInfo) {
          eventFilter.tutorId = this.props.user.id;
        } else {
          eventFilter.tuteeId = this.props.user.id;
        }
      }

      this.setState({ daysWithEvents: new Set<number>() });
      getEvents(eventFilter)
        .then((result: any) => {
          let daysWithEvents = new Set<number>();
          result.forEach((event: any) => {
            if (event.startDate) {
              const startOfDay = Utils.nextDay(new Date(event.startDate), 0);
              daysWithEvents.add(startOfDay.getTime());
            }
          });
          this.setState({ daysWithEvents, month: month });
        })
        .catch((err) => console.log(err));
    }
  }
}

function CalendarContainer(props: { mobile?: boolean; children?: any }) {
  return props.mobile ? (
    <ScrollView>{props.children}</ScrollView>
  ) : (
    <>{props.children}</>
  );
}

const style = StyleSheet.create({
  half: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-around",
    flexGrow: 0,
    alignItems: "center",
    alignSelf: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  full: {},
  events_half: {
    flex: 1,
    minWidth: 200,
    width: 375,
    margin: 8,
    flexGrow: 1,
  },
  events_full: {
    flex: 1,
    minWidth: 200,
    maxWidth: 375,
    margin: 24,
  },
  calendar_half: {
    flex: 1,
    minWidth: 200,
    maxWidth: 375,
    marginRight: "5%",
    borderRadius: 10,
    minHeight: 300,
  },
  calendar_full: {},
});

export default connect(mapStateToProps, null)(Events);
