import React from "react";
import { Component } from "react";
import { View, Text } from "react-native";
import SVGImage from "../Utils/SVGImage";
import StyleUtils from "../Utils/StyleUtils";
import ChatButtonBlack from "../../assets/chatButtonBlack.svg";
import ChatRoom from "./ChatRoom";

type Props = {
  style;
  mobile;
  chatConnection;
  closeChat;
};
export default class ChatHolder extends Component<Props, any> {
  render() {
    return (
      <View style={[StyleUtils.color_bg_white, { flex: 1 }]}>
        <View
          style={[
            { height: 48, flexDirection: "row", zIndex: 1 },
            StyleUtils.color_bg_overlay,
          ]}
        >
          {!this.props.mobile && (
            <>
              <SVGImage
                source={<ChatButtonBlack />}
                style={{ width: 30, height: 30, margin: 10 }}
              />
              <Text
                style={[
                  StyleUtils.font_size_16,
                  StyleUtils.color_txt_normal,
                  { alignSelf: "center" },
                ]}
              >
                Chat
              </Text>
            </>
          )}
        </View>
        <ChatRoom
          showBackButton={this.props.mobile}
          onBack={this.props.closeChat}
          chatConnection={this.props.chatConnection}
          initialMessages={this.props.chatConnection.getInitialMessages()}
        />
      </View>
    );
  }
}
