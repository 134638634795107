import React from "react";
import { Component } from "react";
import { Text, TouchableOpacity, View, Linking } from "react-native";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import Logo from "../../assets/logo.svg";
import SVGImage from "../Utils/SVGImage";
import { withRouter } from "../Router";
import { connect } from "react-redux";
import { logout, selectTutor } from "../Actions";
import { SCREEN_SIZE_900 } from "../Utils/SizeUtils";
import HamburgerIcon from "./../../assets/hamburgericon.svg";
import UserIconBlue from "./../../assets/userIconBlue.svg";
import UserWhite from "../../assets/userWhite.svg";

type Props = {
  user;
  tutorInfo;
  tuteeInfo;
  history;
  location;
  logout;
  selectTutor;
};

type State = {
  full_layout: boolean;
  menu_enabled: boolean;
};

function dispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
  };
}

function stateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    tuteeInfo: state.userRed.tuteeInfo,
  };
}

const headerHeight = 68;
class StudyPlanHeader extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      full_layout: false,
      menu_enabled: false,
    };
  }

  noAuthHeaderElements = [];

  tutorHeaderElements = [
    {
      name: "Minhas aulas",
      path: "/events",
      onClick: null,
    },
    {
      name: "Minhas lições",
      path: "/exercises",
      onClick: null,
    },
    {
      name: "Meus horários",
      path: "/schedule",
      onClick: null,
    },
    {
      name: "Exercícios",
      path: "/exerciseDirectories",
      onClick: () => {},
    },
  ];

  tuteeHeaderElements = [
    {
      name: "Agendar aula",
      path: "/tutormatchlist",
      onClick: () => {},
    },
    {
      name: "Minhas aulas",
      path: "/events",
      onClick: null,
    },
    {
      name: "Meus exercícios",
      path: "/exercises",
      onClick: null,
    },
  ];

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let full = layout.width < SCREEN_SIZE_900;
    if (full != this.state.full_layout) {
      this.setState({
        full_layout: full,
        menu_enabled: false,
      });
    }
  };

  headerElements = () => {
    if (!this.props.user) return this.noAuthHeaderElements;
    if (!this.props.tutorInfo) return this.tuteeHeaderElements;
    return this.tutorHeaderElements;
  };

  headerElementsFullLayout = () => {
    return (
      <View
        style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start" }}
      >
        {this.headerElements().map((element, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (element.onClick) {
                  element.onClick();
                }
                this.props.history.push(element.path);
              }}
              style={{ justifyContent: "center" }}
            >
              <Text
                style={[
                  { alignSelf: "center", margin: 8, padding: 8 },
                  StyleUtils.font_size_14,
                  this.props.location.pathname === element.path
                    ? StyleUtils.color_txt_primary
                    : StyleUtils.color_txt_dark,
                ]}
              >
                {element.name}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  headerElementsMenuLayout = () => {
    return (
      <View
        style={[
          StyleUtils.color_bg_white,
          StyleUtils.shadow_soft_bottom,
          { position: "absolute", width: "100%", marginTop: headerHeight },
        ]}
      >
        {this.props.user && (
          <Text
            style={[
              StyleUtils.color_bg_light,
              StyleUtils.font_size_16,
              { padding: 16 },
            ]}
          >
            Conectado como <b>{this.props.user.name}</b>
          </Text>
        )}
        {this.headerElements().map((element, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (element.onClick) {
                  element.onClick();
                }
                this.setState({ menu_enabled: false });
                this.props.history.push(element.path);
              }}
              style={{ justifyContent: "center" }}
            >
              <Text
                style={[
                  { margin: 8, padding: 8 },
                  StyleUtils.font_size_16,
                  this.props.location.pathname === element.path
                    ? StyleUtils.color_txt_primary
                    : StyleUtils.color_txt_dark,
                ]}
              >
                {element.name}
              </Text>
            </TouchableOpacity>
          );
        })}
        {this.props.user && (
          <TouchableOpacity
            onPress={() => {
              this.setState({ menu_enabled: false });
              this.props.logout();
              this.props.history.push("/landing");
            }}
            style={{ justifyContent: "center" }}
          >
            <Text
              style={[
                { margin: 8, padding: 8 },
                StyleUtils.font_size_16,
                StyleUtils.color_txt_dark,
              ]}
            >
              Logout
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  render() {
    return (
      <>
        <View
          style={[
            StyleUtils.shadow_soft_bottom,
            {
              zIndex: 1,
              flexDirection: "row",
              height: headerHeight,
              backgroundColor: COLOR_TRUE_WHITE,
            },
          ]}
          onLayout={this.onLayout}
        >
          {
            //Logo

            <View
              style={[
                {
                  alignSelf: "center",
                  padding: 8,
                  width: 100,
                  marginLeft: 100,
                },
              ]}
            >
              <SVGImage source={<Logo />} />
            </View>
          }

          {
            // Header Elements
            !this.state.full_layout && this.headerElementsFullLayout()
          }

          {
            // Header Elements Floating Menu
            this.state.full_layout &&
              this.state.menu_enabled &&
              this.headerElementsMenuLayout()
          }
        </View>
        <View
          style={{ borderBottomColor: COLOR_TRUE_WHITE, borderBottomWidth: 1 }}
        />
      </>
    );
  }
}

export default withRouter(
  connect(stateToProps, dispatchToProps)(StudyPlanHeader)
);
