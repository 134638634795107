import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import MomDaughter1 from "../../assets/momDaughter1.svg";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
};

export default function StartStudyPlan(props: Props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );

  const mobile = windowWidth < 500;

  useEffect(() => {
    Dimensions.addEventListener("change", _handleAppStateChange);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  return (
    <View style={[StyleUtils.screen_default]}>
      <LinearGradient
        colors={["#011F42", "#016EE8"]}
        style={[
          {
            paddingVertical: 250,
          },
        ]}
      />
      <View
        style={{
          width: windowWidth,
          height: windowWidth,
          borderRadius: windowWidth / 2,
          backgroundColor: COLOR_TRUE_WHITE,
          transform: [{ scaleX: 2 }],
          marginTop: 300,
          position: "absolute",
        }}
      />
      <SVGImage
        source={<MomDaughter1 />}
        style={{
          alignSelf: "center",
          zIndex: 1,
          width: mobile ? 210 : 317,
          height: mobile ? 205 : 327,
          position: "absolute",
          top: mobile ? 140 : 50,
        }}
      />
      <View
        style={{
          alignItems: "center",
          marginHorizontal: 20,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            {
              textAlign: "center",
              fontSize: mobile ? 20 : 38,
              marginTop: -100,
            },
          ]}
        >
          A ajuda que seu filho(a) precisa{"\n"}para atingir todos os objetivos.
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_normal,
            { textAlign: "center", marginTop: 21 },
          ]}
        >
          Melhorar as notas na escola, decidir uma carreira, passar no
          vestibular,{"\n"}
          você escolhe e nós fazemos acontecer.{"\n"}
        </Text>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            StyleUtils.color_bg_primary,
            { marginTop: 28, width: 264 },
          ]}
          onPress={() => {
            analytics.sendStartStudyPlan(0);
            props.onContinue();
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_white,
              StyleUtils.font_size_16,
              { padding: 14, textAlign: "center" },
            ]}
          >
            Criar meu plano
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
