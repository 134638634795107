import React, { Component } from "react";
import { Dimensions, Image, Text, TouchableOpacity, View } from "react-native";
import SignUpPopUp from "../../SignUp/SignUpPopUp";
import { LinearGradient } from "../../Utils/LinearGradient";
import StyleUtils from "../../Utils/StyleUtils";
import { analytics } from "../../Analytics";

type Props = {
  onContinue;
  onContinueProf;
  onLogin;
};

type State = {
  continueButtonText;
  currentPage: number;
  isShowPopUpLogin;
};

export default class ExerciseBenefits extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      continueButtonText: "Enviar Exercício",
      currentPage: 0,
      isShowPopUpLogin: false,
    };
  }

  pages = [
    {
      title: "Precisa de ajuda\ncom algum exercício?",
      subtitle:
        "Envie seu exercício e receba ajuda de\nprofessores em pouco tempo",
      image: require("../../../assets/onboarding4.png"),
    },
  ];

  viewPager;

  render() {
    const windowHeight = Dimensions.get("window").height;
    return (
      <LinearGradient
        colors={["#0179FF", "#18BAFF"]}
        style={{ flexDirection: "column", flex: 1 }}
      >
        <View
          style={[
            {
              height: "62%",
              alignItems: "center",
              marginTop: windowHeight < 700 ? 80 : 150,
            },
          ]}
        >
          <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={["#0055B5", "rgba(1, 121, 255, 0)"]}
            locations={[0.0453, 0.5993]}
            style={{
              position: "absolute",
              top: -30,
              left: -30,
              right: -30,
              bottom: 0,
              height: "100%",
              borderRadius: 10000,
            }}
          ></LinearGradient>
          <View
            ref={(viewPager) => (this.viewPager = viewPager)}
            style={{ flex: 1, marginTop: "3%", width: "100%" }}
          >
            {this.pages.map((item, index) => {
              return (
                <View key={index}>
                  <Image
                    source={item.image}
                    resizeMode="contain"
                    style={{
                      height: "60%",
                      maxHeight: "60%",
                      alignSelf: "center",
                      width: "100%",
                      marginTop: windowHeight < 850 ? 30 : 50,
                    }}
                  />
                  <Text
                    style={[
                      StyleUtils.font_size_22,
                      StyleUtils.font_cera_bold,
                      StyleUtils.color_txt_white,
                      {
                        alignSelf: "center",
                        textAlign: "center",
                        marginTop: 20,
                      },
                    ]}
                  >
                    {item.title}
                  </Text>
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                      StyleUtils.color_txt_white,
                      {
                        alignSelf: "center",
                        textAlign: "center",
                        lineHeight: 20,
                        width: 300,
                        marginTop: 15,
                      },
                    ]}
                  >
                    {item.subtitle}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
        <TouchableOpacity
          style={[
            StyleUtils.color_bg_white,
            {
              alignSelf: "center",
              borderRadius: 6,
              overflow: "hidden",
              position: "absolute",
              bottom: 100,
              width: "80%",
            },
          ]}
          onPress={() => {
            this.props.onContinue();
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_dark,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              {
                padding: 16,
                paddingBottom: 12,
                paddingLeft: 80,
                paddingRight: 80,
                alignSelf: "center",
              },
            ]}
          >
            {this.state.continueButtonText}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            position: "absolute",
            bottom: 50,
            alignSelf: "center",
          }}
          onPress={() => {
            analytics.sendExerciseOnboardingLookTutor();
            this.props.onContinueProf();
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_16,
              {
                alignSelf: "center",
                fontWeight: "500",
                textDecorationLine: "underline",
              },
            ]}
          >
            Procurar Professores
          </Text>
        </TouchableOpacity>
        <SignUpPopUp
          show={this.state.isShowPopUpLogin}
          close={() => {
            this.setState({ isShowPopUpLogin: false });
          }}
          nextStep={this.props.onContinue}
          isLogin={true}
        />
      </LinearGradient>
    );
  }
}
