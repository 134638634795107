import { Platform, StyleSheet, ViewStyle } from "react-native";

export const COLOR_PRIMARY = "#0179FF"; // old "#49AED9"
export const COLOR_PRIMARY_LIGHT = "#DCECFF"; // old "#E3F3FC";
export const COLOR_BG_LIGHT = "#EBEDF8";
export const COLOR_BG_DARK = "#2D304B";
export const COLOR_TXT_NORMAL = "#626577";
export const COLOR_TXT_LIGHT = "#A0A2AD";
export const COLOR_TXT_GREY_LIGHT = "#9DADC8";
export const COLOR_TXT_DARK = "#3B3F50";
export const COLOR_WHITE = "#FCFDFF";
export const COLOR_WHITE_WITH_TRANSPARENCY = "#FFFFFFE6";
export const COLOR_TRUE_WHITE = "#FFFFFF";
export const COLOR_DANGER = "#FF2F5C"; // old "#EC6355"
export const COLOR_DANGER_LIGHT = "#FFF1F5";
export const COLOR_SUCCESS = "#13CE74"; // old #56D179;
export const COLOR_MUTED = "#E1E8F3";
export const COLOR_LIGHT_ICON = "#BAC6DA";
export const COLOR_LIGHT_NOTIFICATION_UNSEEN = "#EEF4FF";

const screenStyle = (function (): ViewStyle {
  switch (Platform.OS) {
    case "android":
    case "ios":
      return {
        flex: 1,
      };
    default:
      return {
        flexGrow: 1,
        minHeight: 550,
      };
  }
})();

const baseStyle = StyleSheet.create({
  // Colors
  // Background Colors
  color_bg_txtdark: {
    backgroundColor: COLOR_TXT_DARK,
  },
  color_bg_dark: {
    backgroundColor: COLOR_BG_DARK,
  },
  color_bg_dark_bold: {
    backgroundColor: "#262942",
  },
  color_bg_other_dark: {
    backgroundColor: "#363A4B",
  },
  color_bg_text_dark: {
    backgroundColor: COLOR_TXT_DARK,
  },
  color_bg_light: {
    backgroundColor: COLOR_BG_LIGHT,
  },
  color_bg_light_icon: {
    backgroundColor: COLOR_LIGHT_ICON,
  },
  color_bg_overlay: {
    backgroundColor: "#F7F9FD",
  },
  color_bg_white: {
    backgroundColor: COLOR_WHITE,
  },
  color_bg_true_white: {
    backgroundColor: COLOR_TRUE_WHITE,
  },
  color_bg_white_transparency: {
    backgroundColor: COLOR_WHITE_WITH_TRANSPARENCY,
  },
  color_bg_whiteboard: {
    backgroundColor: "#EDF1F6",
  },
  hidden: {
    display: "none",
  },
  color_bg_primary: {
    backgroundColor: COLOR_PRIMARY,
  },
  color_bg_primarylight: {
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  color_bg_successlight: {
    backgroundColor: "#DBF4E4",
  },
  color_bg_success: {
    backgroundColor: COLOR_SUCCESS,
  },
  // Text Colors
  color_txt_dark: {
    color: COLOR_TXT_DARK,
  },
  color_txt_normal: {
    color: COLOR_TXT_NORMAL,
  },
  color_txt_light: {
    color: COLOR_TXT_LIGHT,
  },
  color_txt_white: {
    color: "#FFFFFF",
  },
  color_txt_primary: {
    color: COLOR_PRIMARY,
  },
  color_txt_primary_light: {
    color: COLOR_PRIMARY_LIGHT,
  },
  color_txt_info: {
    color: "#086CC1",
  },
  color_txt_success: {
    color: COLOR_SUCCESS,
  },
  color_txt_warning: {
    color: "#F3C44C",
  },
  color_txt_danger: {
    color: COLOR_DANGER,
  },
  color_txt_muted: {
    color: "#E1E8F3",
  },
  color_txt_light_icon: {
    color: COLOR_LIGHT_ICON,
  },
  color_txt_grey_light: {
    color: COLOR_TXT_GREY_LIGHT,
  },
  // Button BG Colors
  color_btn_primary: {
    backgroundColor: COLOR_PRIMARY,
  },
  color_btn_secondary: {
    backgroundColor: "#013362",
  },
  color_btn_info: {
    backgroundColor: "#086CC1",
  },
  color_btn_success: {
    backgroundColor: COLOR_SUCCESS,
  },
  color_btn_warning: {
    backgroundColor: "#F3C44C",
  },
  color_btn_danger: {
    backgroundColor: COLOR_DANGER,
  },
  color_btn_muted: {
    backgroundColor: "#E1E8F3",
  },
  // Button Outline Colors
  border_color_btn_primary: {
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  border_color_btn_secondary: {
    borderWidth: 1,
    borderColor: "#013362",
  },
  border_color_btn_info: {
    borderWidth: 1,
    borderColor: "#086CC1",
  },
  border_color_btn_success: {
    borderWidth: 1,
    borderColor: COLOR_SUCCESS,
  },
  border_color_btn_warning: {
    borderWidth: 1,
    borderColor: "#F3C44C",
  },
  border_color_btn_danger: {
    borderWidth: 1,
    borderColor: COLOR_DANGER,
  },
  border_color_btn_muted: {
    borderWidth: 1,
    borderColor: "#E1E8F3",
  },
  border_color_txt_normal: {
    borderWidth: 1,
    borderColor: "#626577",
  },
  border_color_txt_white: {
    borderWidth: 1,
    borderColor: "white",
  },
  border_color_btn_dark: {
    borderWidth: 1,
    borderColor: COLOR_TXT_DARK,
  },
  border_color_light: {
    borderWidth: 1,
    borderColor: COLOR_BG_LIGHT,
  },

  // Shapes
  // Button Shapes
  shape_btn_default_rounded: {
    borderRadius: 80,
    overflow: "hidden",
  },
  shape_btn_small: {
    borderRadius: 6,
    overflow: "hidden",
  },
  shape_btn_default: {
    borderRadius: 12,
    overflow: "hidden",
  },
  shape_card_default: {
    borderRadius: 10,
    overflow: "hidden",
  },

  // Outline
  // ButtonOutline
  outline_btn: {
    borderWidth: 1,
  },

  // Font

  // Button Font
  font_btn_regular: {
    fontFamily: "CeraPro-Regular",
    fontWeight: "normal",
  },
  font_btn_bold: {
    fontFamily: "CeraPro-Bold",
    fontWeight: "bold",
  },
  font_cera_bold: {
    fontFamily: "CeraPro-Bold",
    fontWeight: "bold",
  },
  font_cera_medium: {
    fontFamily: "CeraPro-Medium",
    fontWeight: "normal",
  },
  font_cera_regular: {
    fontFamily: "CeraPro-Regular",
    fontWeight: "normal",
  },

  // Font Size
  font_size_12: {
    fontSize: 12,
  },
  font_size_14: {
    fontSize: 14,
  },
  font_size_16: {
    fontSize: 16,
  },
  font_size_18: {
    fontSize: 18,
  },
  font_size_20: {
    fontSize: 20,
  },
  font_size_22: {
    fontSize: 22,
  },
  font_size_24: {
    fontSize: 24,
  },
  font_size_26: {
    fontSize: 26,
  },
  font_size_28: {
    fontSize: 28,
  },

  //Shadow
  shadow_soft_bottom: {
    shadowRadius: 33,
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: -1 },
  },
  card_shadow: {
    shadowOffset: { height: 15, width: 0 },
    shadowRadius: 20,
    shadowColor: "#959EC8",
    shadowOpacity: 0.15,
  },

  desktop_content_width: {
    width: "90%",
  },

  //Base screen
  screen_default: {
    ...screenStyle,
  },
});

const onboStyle = StyleSheet.create({
  headerText: {
    textAlign: "left",
    marginTop: 30,
    width: "80%",
    ...StyleSheet.flatten([baseStyle.font_cera_bold, baseStyle.font_size_22]),
  },
  subheaderText: {
    textAlign: "left",
    marginTop: 30,
    width: "80%",
    ...StyleSheet.flatten([
      baseStyle.font_cera_regular,
      baseStyle.font_size_22,
    ]),
  },
  cellText: {
    flex: 1,
    textAlign: "left",
    paddingTop: 4,
    ...StyleSheet.flatten([
      baseStyle.color_txt_dark,
      baseStyle.font_cera_regular,
      baseStyle.font_size_16,
    ]),
  },
  cellTextMuted: {
    flex: 1,
    textAlign: "left",
    paddingTop: 4,
    ...StyleSheet.flatten([
      baseStyle.color_txt_grey_light,
      baseStyle.font_cera_regular,
      baseStyle.font_size_16,
    ]),
  },

  cellBase: {
    height: 40,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginTop: 4,
    marginBottom: 4,
  },
  cellUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    borderColor: COLOR_PRIMARY,
    borderWidth: 2,
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },

  checkmarkBase: {
    height: "50%",
    marginLeft: 11,
    marginRight: 11,
    aspectRatio: 1,
    borderRadius: 1000,
    alignItems: "center",
    justifyContent: "center",
  },
  checkmarkUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  checkmarkSelected: {
    backgroundColor: COLOR_PRIMARY,
  },

  continueButtonBase: {
    position: "absolute",
    bottom: 32,
    left: 32,
    right: 32,
    alignSelf: "center",
    borderRadius: 6,
  },

  continueButtonBaseNoAbsolute: {
    width: "100%",
    margin: 32,
    alignSelf: "center",
    borderRadius: 6,
  },

  continueButtonInactive: {
    backgroundColor: COLOR_MUTED,
  },

  continueButtonActive: {
    backgroundColor: COLOR_PRIMARY,
  },

  continueButtonActiveBlue: {
    backgroundColor: COLOR_PRIMARY,
  },

  textInput: {
    ...StyleSheet.flatten([
      baseStyle.border_color_btn_muted,
      baseStyle.font_size_16,
      baseStyle.font_cera_regular,
      baseStyle.color_txt_dark,
    ]),

    borderRadius: 10,
    height: 40,
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 8,
  },

  textInputFocused: {
    borderColor: COLOR_TXT_DARK,
    borderWidth: 2,
  },

  textInputError: {
    borderColor: COLOR_DANGER,
    borderWidth: 2,
    backgroundColor: COLOR_DANGER_LIGHT,
  },
});

export default baseStyle;
export const SpcStyle = onboStyle;
