import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import React from "react";
import SVGImage from "../Utils/SVGImage";
import ArrowDownThin from "../../assets/arrowdownthin.svg";
import PencilStroke1_selected from "../../assets/toolPencilStroke1_selected.svg";
import PencilStroke1 from "../../assets/toolPencilStroke1.svg";
import PencilStroke2_selected from "../../assets/toolPencilStroke2_selected.svg";
import PencilStroke2 from "../../assets/toolPencilStroke2.svg";
import PencilStroke3_selected from "../../assets/toolPencilStroke3_selected.svg";
import PencilStroke3 from "../../assets/toolPencilStroke3.svg";

const pencilColors = [
  "#0179FF",
  "#E73534",
  "#02BBA9",
  "#FFB017",
  "#8B52B9",
  "#3B3F50",
];

export const renderPencilColorButton = (
  submenuOpen,
  changeSubmenu,
  selectedColor
) => {
  return (
    <TouchableOpacity
      style={[
        submenuOpen.includes("pencil-color")
          ? StyleUtils.color_bg_overlay
          : StyleUtils.color_bg_white,
        {
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 16,
          paddingRight: 16,
        },
      ]}
      onPress={() => {
        if (submenuOpen.includes("pencil-color")) changeSubmenu("pencil");
        else changeSubmenu("pencil-color");
      }}
    >
      <View
        style={[
          StyleUtils.shape_btn_default_rounded,
          {
            width: 16,
            height: 16,
            margin: 12,
            marginLeft: 0,
            marginRight: 8,
            backgroundColor: selectedColor,
          },
        ]}
      />
      <Text
        style={[
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_14,
          StyleUtils.font_btn_bold,
          { marginRight: 8 },
        ]}
      >
        Cor
      </Text>
      <SVGImage source={<ArrowDownThin />} />
    </TouchableOpacity>
  );
};

export const renderPencilColorButtonText = (
  submenuOpen,
  setState,
  selectedColor
) => {
  return (
    <TouchableOpacity
      style={[
        submenuOpen.includes("pencil-color")
          ? StyleUtils.color_bg_overlay
          : StyleUtils.color_bg_white,
        {
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 16,
          paddingRight: 16,
        },
      ]}
      onPress={() => {
        if (submenuOpen.includes("pencil-color"))
          setState({
            submenuOpen: "pencil",
          });
        else
          setState({
            submenuOpen: "pencil-color",
          });
      }}
    >
      <View
        style={[
          StyleUtils.shape_btn_default_rounded,
          {
            width: 16,
            height: 16,
            margin: 12,
            marginLeft: 0,
            marginRight: 8,
            backgroundColor: selectedColor,
          },
        ]}
      />
      <Text
        style={[
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_14,
          StyleUtils.font_btn_bold,
          { marginRight: 8 },
        ]}
      >
        Cor
      </Text>
      <SVGImage source={<ArrowDownThin />} />
    </TouchableOpacity>
  );
};

export const renderPencilStrokeWidth = (
  submenuOpen,
  changeSubmenu,
  selectedStroke
) => {
  return (
    <TouchableOpacity
      style={[
        submenuOpen.includes("pencil-stroke")
          ? StyleUtils.color_bg_overlay
          : StyleUtils.color_bg_white,
        {
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 8,
        },
      ]}
      onPress={() => {
        if (submenuOpen.includes("pencil-stroke")) changeSubmenu("pencil");
        else changeSubmenu("pencil-stroke");
      }}
    >
      <SVGImage
        style={{ marginRight: 8 }}
        source={strokeAsset(selectedStroke, false)}
      />
      <SVGImage source={<ArrowDownThin />} />
    </TouchableOpacity>
  );
};

export const renderPencilColorPicker = (
  mobile,
  setState,
  onChangeStroke,
  selectedStroke
) => {
  return (
    <View
      style={[
        {
          position: "absolute",
          width: 64,
          height: 103,
          marginTop: 50,
          left: mobile ? 100 : 160,
          bottom: mobile ? 45 : -153,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: 8,
          paddingTop: 12,
          paddingBottom: 12,
        },
        StyleUtils.border_color_btn_muted,
        StyleUtils.shape_btn_small,
        StyleUtils.color_bg_white,
      ]}
    >
      <TouchableOpacity
        onPress={() => {
          setState({ selectedStroke: 1 });
          onChangeStroke(1);
        }}
      >
        <SVGImage source={strokeAsset(1, selectedStroke == 1)} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setState({ selectedStroke: 2 });
          onChangeStroke(2);
        }}
      >
        <SVGImage source={strokeAsset(2, selectedStroke == 2)} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          setState({ selectedStroke: 3 });
          onChangeStroke(3);
        }}
      >
        <SVGImage source={strokeAsset(3, selectedStroke == 3)} />
      </TouchableOpacity>
    </View>
  );
};

const strokeAsset = (width, selected) => {
  switch (width) {
    case 1:
      return selected ? <PencilStroke1_selected /> : <PencilStroke1 />;
    case 2:
      return selected ? <PencilStroke2_selected /> : <PencilStroke2 />;
    case 3:
      return selected ? <PencilStroke3_selected /> : <PencilStroke3 />;
  }
};

export const renderPencilStrokePicker = (x, y, onChangeColor, setState) => {
  return (
    <View
      style={[
        {
          position: "absolute",
          width: 97,
          height: 72,
          marginTop: 50,
          left: x,
          bottom: y,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: 8,
          paddingTop: 12,
          paddingBottom: 12,
        },
        StyleUtils.border_color_btn_muted,
        StyleUtils.shape_btn_small,
        StyleUtils.color_bg_white,
      ]}
    >
      {pencilColors.map((color) => {
        return (
          <TouchableOpacity
            key={color}
            style={[
              StyleUtils.shape_btn_default_rounded,
              {
                width: 16,
                height: 16,
                margin: 4,
                marginBottom: 0,
                marginTop: 0,
                backgroundColor: color,
              },
            ]}
            onPress={() => {
              onChangeColor(color);
              setState({ selectedColor: color });
            }}
          />
        );
      })}
    </View>
  );
};
