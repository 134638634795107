import React, { Component } from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import StyleUtils, { COLOR_MUTED, SpcStyle } from "../Utils/StyleUtils";
import BackArrow from "../../assets/backbutton.svg";
import ArrowDown from "../../assets/arrowDropDown.svg";
import SVGImage from "../Utils/SVGImage";
import ButtonNextBlue from "./ButtonNextBlue";
import SignUpUser from "../Models/SignUpUser";
import GenderPopUp, { genderItem } from "./GenderPopUp";
import baseStyle from "../Utils/StyleUtils";
import { createISODatefromBrazilianFormat } from "../Utils/Utils";
import DateInput from "./DateInput";

type Props = {
  //history;
  onBack;
  onNext;
  userData: SignUpUser;
};

type State = {
  userData: SignUpUser;
  nameFocused;
  emailFocused;
  birthDateFocused;
  genderFocused;
  selectedGender;
  isGenderPopUpOpen;
  birthdayTextInput;
  isDeletingCharacters;
};

class SignUpForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
      selectedGender: null,
      nameFocused: false,
      emailFocused: false,
      birthDateFocused: false,
      genderFocused: false,
      isGenderPopUpOpen: false,
      birthdayTextInput: "",
      isDeletingCharacters: false,
    };
  }

  parseDateInput(text) {
    let trimmedInput = text.trim();
    const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[1-9])|((1)[0-2]))(\/)\d{4}$/;

    this.setState({ birthdayTextInput: trimmedInput });

    if (dateRegex.test(trimmedInput)) {
      const dateStringIso = createISODatefromBrazilianFormat(trimmedInput);
      this.setState({
        userData: { ...this.state.userData, birthday: dateStringIso },
      });
    }
  }

  isInputValid() {
    return (
      this.state.userData.birthday &&
      this.state.userData.name &&
      this.state.userData.gender
    );
  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          style={{ flex: 1, flexDirection: "column" }}
        >
          <TouchableOpacity
            style={[
              {
                alignSelf: "flex-start",
                aspectRatio: 1,
                alignItems: "center",
                justifyContent: "center",
                margin: 16,
                marginTop: 48,
                marginLeft: 24,
              },
            ]}
            onPress={() => {
              // this.history.goBack();
              this.props.onBack();
            }}
          >
            <SVGImage source={<BackArrow />} />
          </TouchableOpacity>
          <View
            style={{
              width: "100%",
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text style={SpcStyle.subheaderText}>
              Por favor,{"\n"}
              <Text style={SpcStyle.headerText}>complete seu cadastro</Text>
            </Text>
            <View>
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_14,
                  StyleUtils.color_txt_normal,
                  {
                    alignSelf: "flex-start",
                    marginTop: 24,
                  },
                ]}
              >
                Nome completo
              </Text>

              <TextInput
                style={[
                  SpcStyle.textInput,
                  this.state.nameFocused && SpcStyle.textInputFocused,
                ]}
                placeholder="Nome completo"
                placeholderTextColor={COLOR_MUTED}
                onFocus={() => {
                  this.setState({ nameFocused: true });
                }}
                onBlur={() => {
                  this.setState({ nameFocused: false });
                }}
                onChangeText={(text) =>
                  this.setState({
                    userData: { ...this.state.userData, name: text },
                  })
                }
                value={this.state.userData.name || ""}
              />
            </View>

            <View>
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_14,
                  StyleUtils.color_txt_normal,
                  {
                    alignSelf: "flex-start",
                    marginTop: 24,
                  },
                ]}
              >
                Email
              </Text>

              <TextInput
                style={[
                  { backgroundColor: "#F7F9FD" },
                  SpcStyle.textInput,
                  this.state.emailFocused && SpcStyle.textInputFocused,
                ]}
                keyboardType={"email-address"}
                autoCapitalize="none"
                placeholder="email@dominio.com"
                placeholderTextColor={COLOR_MUTED}
                // onFocus={() => {
                //   this.setState({ emailFocused: true });
                // }}
                // onBlur={() => {
                //   this.setState({ emailFocused: false });
                // }}
                editable={false}
                value={this.state.userData.email || ""}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <View style={{ flex: 1, marginRight: 40 }}>
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.font_size_14,
                    StyleUtils.color_txt_normal,
                    {
                      alignSelf: "flex-start",
                      marginTop: 24,
                    },
                  ]}
                >
                  Sexo
                </Text>

                <TouchableOpacity
                  style={[
                    {
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      borderRadius: 10,
                      height: 40,
                      marginTop: 8,
                    },
                    baseStyle.border_color_btn_muted,
                  ]}
                  onPress={() => {
                    this.setState({ isGenderPopUpOpen: true });
                  }}
                >
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                      StyleUtils.color_txt_dark,
                      {
                        justifyContent: "center",
                        padding: 8,
                        paddingTop: 10,
                        flex: 1,
                      },
                    ]}
                  >
                    {this.state.selectedGender
                      ? this.state.selectedGender.label
                      : "Selecione"}
                  </Text>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "flex-end",
                    }}
                  >
                    <View
                      style={{
                        height: "100%",
                        borderLeftWidth: 1,
                        borderLeftColor: COLOR_MUTED,
                      }}
                    ></View>
                    <SVGImage
                      style={{ padding: 12, paddingRight: 8, paddingLeft: 8 }}
                      source={<ArrowDown />}
                    ></SVGImage>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    StyleUtils.font_cera_regular,
                    StyleUtils.font_size_14,
                    StyleUtils.color_txt_normal,
                    {
                      alignSelf: "flex-start",
                      marginTop: 24,
                    },
                  ]}
                >
                  Data de Nascimento
                </Text>
                <DateInput
                  date={this.state.birthdayTextInput}
                  isFocused={this.state.birthDateFocused}
                  onFocus={() => {
                    this.setState({ birthDateFocused: true });
                  }}
                  onBlur={() => {
                    this.setState({ birthDateFocused: false });
                  }}
                  onDateChange={(date) => {
                    this.parseDateInput(date);
                  }}
                />
              </View>
            </View>
          </View>
          <ButtonNextBlue
            isValidInfo={this.isInputValid()}
            onClick={() => this.props.onNext(this.state.userData)}
            text={"Avançar"}
          />

          <GenderPopUp
            show={this.state.isGenderPopUpOpen}
            close={() => {
              this.setState({ isGenderPopUpOpen: false });
            }}
            onSelectGender={(gender: genderItem) => {
              this.setState({
                selectedGender: gender,
                userData: { ...this.state.userData, gender: gender.value },
                isGenderPopUpOpen: false,
              });
            }}
          />
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    );
  }
}

export default SignUpForm;
