import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import RatingStars from "../../Rating/RatingStars";
import StyleUtils from "../../Utils/StyleUtils";
import BackButton from "../../../assets/backButtonBorder.svg";
import SVGImage from "../../Utils/SVGImage";
import RemoteConfigBridge from "../../RemoteConfig/RemoteConfigBridge";

type Props = {
  onContinue;
  headerText;
  onSkip;
  onBack?;
};

type State = {
  rating;
  numberOfStars: number;
  showSkip;
};

export default class StarVideoRating extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      numberOfStars: 0,
      rating: null,
      showSkip: false,
    };
    RemoteConfigBridge.remoteConfig("showSkipReview", (value) => {
      var valueBool = value == "1";
      this.setState({ showSkip: valueBool });
    });
  }

  setRating = (rating) => {
    this.props.onContinue(rating);
    this.setState({ rating: null, numberOfStars: 0 });
  };

  render() {
    return (
      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 8,
            paddingRight: 24,
            paddingTop: 16,
          }}
        >
          {this.props.onBack && (
            <TouchableOpacity
              style={{ marginLeft: 8, marginTop: 16 }}
              onPress={() => {
                this.props.onBack();
              }}
            >
              <SVGImage source={<BackButton />} />
            </TouchableOpacity>
          )}
          {this.state.showSkip && (
            <TouchableOpacity onPress={() => this.props.onSkip()}>
              <Text
                style={[
                  StyleUtils.color_txt_primary,
                  StyleUtils.font_size_16,
                  StyleUtils.font_cera_regular,
                  { textDecorationLine: "underline" },
                ]}
              >
                Pular
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            width: "100%",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 200,
          }}
        >
          <Text
            style={[
              { textAlign: "center", margin: "5%" },
              StyleUtils.color_txt_dark,
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_20,
            ]}
          >
            {this.props.headerText}
          </Text>
          <RatingStars setRating={this.setRating} numberOfStars={5} />
        </View>
      </View>
    );
  }
}
