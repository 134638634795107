import React, { Component, useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { ViewStyle } from "react-web-gifted-chat";
import CorrectIcon from "../../assets/correctIcon.svg";
import SendIcon from "../../assets/sendIcon.svg";
import TrashIcon from "../../assets/trashIcon.svg";
import WrongIcon from "../../assets/wrongIcon.svg";
import { analytics } from "../Analytics";
import QuestionNumber from "../Elements/QuestionNumber";
import CustomLightBox from "../ImageUploader/CustomLightBox";
import CustomLightBoxLarge from "../ImageUploader/CustomLightBoxLarge";
import ImageUploader from "../ImageUploader/ImageUploader";
import Assignment from "../Models/Assignment";
import Homework, { HomeworkState, homeworkState } from "../Models/Homework";
import User from "../Models/User";
import { deleteAssignmentImage, getFileURL, putAssignment } from "../Server";
import StyleUtils, {
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_SUCCESS,
  COLOR_TXT_LIGHT,
  COLOR_TXT_NORMAL,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import * as Utils from "../Utils/Utils";
import {
  answerState,
  exerciseViewMode,
  ExerciseViewMode,
  itemState,
} from "./Models/AssignmentUtils";
import LinearGradient from "react-native-linear-gradient";

type Props = {
  homework: Homework;
  assignment: Assignment;
  assignmentIndex;
  tutorInfo;
  selectImagePopup;
  updateAssignment;
  reviewMode?: boolean;
};

enum AnswerState {
  Unanswered,
  Answered,
  Wrong,
  Correct,
}

export default class ExerciseCard extends Component<Props, any> {
  onImageUploadSuccessful(file) {
    console.log("upload successful");
    const filename = file.name ? file.name : file.fileName;
    const fileURL = getFileURL(filename);
    putAssignment(this.props.assignment.id, {
      answerImageURL: fileURL,
    })
      .then((assignment) => {
        this.props.updateAssignment(assignment);
      })
      .then(() => {
        analytics.sendExerciseImagePosted();
      });
  }

  deleteAssignmentImage = () => {
    deleteAssignmentImage(this.props.assignment.id).then(() => {
      this.props.assignment.tuteeAnswerImageURL = undefined;
      this.props.updateAssignment(this.props.assignment);
    });
  };

  render() {
    const hmState = homeworkState(this.props.homework);
    const editable = hmState < HomeworkState.ANSWERED;
    const tutorEditable =
      hmState >= HomeworkState.ANSWERED && hmState < HomeworkState.CORRECTED;
    const isTutor = !!this.props.tutorInfo;
    const containsImageUrl =
      this.props.assignment.exercise &&
      !!this.props.assignment.exercise.imageUrl;
    const writtenQuestion =
      this.props.assignment.exercise &&
      this.props.assignment.exercise.possibleAnswers &&
      this.props.assignment.exercise.possibleAnswers[0] === "";
    const hasAnswered =
      this.props.assignment.tuteeAnswer &&
      this.props.assignment.tuteeAnswer.length !== undefined;

    return (
      <LinearGradient
        colors={["#ECF0F9", "#F7F9FD"]}
        angle={180}
        style={[
          StyleUtils.color_bg_light,
          StyleUtils.shape_btn_default,
          {
            width: "100%",
            maxWidth: 548,
            alignSelf: "center",
            paddingVertical: 40,
            paddingHorizontal: 24,
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            marginBottom: 16,
            alignItems: "center",
          }}
        >
          <QuestionNumber
            number={this.props.assignmentIndex + 1}
            style={{ marginRight: 4 }}
          />
          {exerciseViewMode(this.props.assignment, this.props.reviewMode) !=
            ExerciseViewMode.exerciseView &&
            itemState(this.props.assignment, this.props.reviewMode) ===
              AnswerState.Correct &&
            !writtenQuestion && <SVGImage source={<CorrectIcon />} />}
          {exerciseViewMode(this.props.assignment, this.props.reviewMode) !=
            ExerciseViewMode.exerciseView &&
            itemState(this.props.assignment, this.props.reviewMode) ===
              AnswerState.Wrong &&
            !writtenQuestion && <SVGImage source={<WrongIcon />} />}
        </View>
        <Text
          style={[
            StyleUtils.font_size_16,
            StyleUtils.color_txt_normal,
            { marginBottom: containsImageUrl ? 0 : 24 },
          ]}
        >
          {this.props.assignment.exercise &&
            this.props.assignment.exercise.description}
        </Text>
        {containsImageUrl && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            {this.props.assignment.exercise && (
              <CustomLightBoxLarge
                style={{
                  width: 300,
                  height: 200,
                  resizeMode: "contain",
                }}
                activeProps={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "contain",
                }}
                source={this.props.assignment.exercise.imageUrl}
              />
            )}
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          {this.props.assignment.exercise &&
            this.props.assignment.exercise.possibleAnswers &&
            this.props.assignment.exercise.possibleAnswers.map(
              (answer, answerIndex) => {
                if (answer !== "") {
                  const cAnswerState = answerState(
                    this.props.assignment,
                    answer,
                    this.props.reviewMode
                  );
                  return (
                    <ExerciseAlternative
                      key={answerIndex}
                      answerState={cAnswerState}
                      answerIndex={answerIndex}
                      answer={answer}
                      onPress={() => {
                        putAssignment(this.props.assignment.id, {
                          userAnswer: answer,
                        }).then((assignment) =>
                          this.props.updateAssignment(assignment)
                        );
                      }}
                      disabled={!editable || isTutor}
                    />
                  );
                }
              }
            )}
          {writtenQuestion && (
            <Comment
              style={{
                marginBottom: 16,
                marginTop: 16,
                flex: 1,
              }}
              user={this.props.homework.tutee}
              text={this.props.assignment.tuteeAnswer}
              date={
                !!this.props.assignment.answeredAt
                  ? new Date(this.props.assignment.answeredAt)
                  : undefined
              }
              onPost={(text) =>
                putAssignment(this.props.assignment.id, {
                  userAnswer: text,
                }).then((assignment) => {
                  this.props.updateAssignment(assignment);
                })
              }
              placeholder={"Escreva sua resposta..."}
            />
          )}
        </View>

        {!this.props.assignment.tuteeAnswerImageURL && !isTutor && editable && (
          <ImageUploader
            descriptionText={"Coloque a foto de sua resolução aqui"}
            style={[
              StyleUtils.font_size_16,
              StyleUtils.color_txt_normal,
              { marginBottom: containsImageUrl ? 0 : 24 },
            ]}
            isVisible={true}
            onImageUploaded={this.onImageUploadSuccessful.bind(this)}
            isMobile={Platform.OS !== "web"}
          />
        )}
        {!!this.props.assignment.tuteeAnswerImageURL && (
          <View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 16,
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_btn_bold,
                  StyleUtils.font_size_16,
                  { flex: 1 },
                ]}
              >
                Resolução anexada
              </Text>
              {!isTutor && editable && (
                <TouchableOpacity
                  style={{
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={(e) => this.deleteAssignmentImage()}
                >
                  <SVGImage source={<TrashIcon />} />
                </TouchableOpacity>
              )}
            </View>
            {Platform.OS === "web" ? (
              <Image
                style={{ alignSelf: "center", width: 500, height: 500 }}
                source={{ uri: this.props.assignment.tuteeAnswerImageURL }}
              />
            ) : (
              <CustomLightBox
                style={{
                  flex: 1,
                  alignSelf: "center",
                  margin: 8,
                  borderRadius: 8,
                  overflow: "hidden",
                }}
                source={this.props.assignment.tuteeAnswerImageURL}
              />
            )}
          </View>
        )}
        {((isTutor && tutorEditable) ||
          !!this.props.assignment.tutorCorrectionComment) && (
          <Comment
            style={{
              marginBottom: 16,
              marginTop: 16,
            }}
            user={this.props.homework.tutor}
            text={this.props.assignment.tutorCorrectionComment}
            date={
              !!this.props.assignment.correctedAt
                ? new Date(this.props.assignment.correctedAt)
                : undefined
            }
            onPost={(text) =>
              putAssignment(this.props.assignment.id, {
                tutorCorrectionComment: text,
              }).then((assignment) => {
                if (!this.props.assignment.tuteeAnswer) {
                  analytics.sendExerciseChosen();
                }
                this.props.updateAssignment(assignment);
              })
            }
          />
        )}
      </LinearGradient>
    );
  }
}

const ExerciseAlternative = (props: {
  answerState: AnswerState;
  answerIndex: number;
  answer: string;
  onPress?: () => void;
  disabled?: boolean;
}) => {
  const { answerState, answer, answerIndex } = props;

  return (
    <TouchableOpacity
      style={[
        StyleUtils.shape_btn_default,
        StyleUtils.shadow_soft_bottom,
        cardStyleForAnswerState(answerState),
        {
          minHeight: 85,
          minWidth: "48%",
          maxWidth: "100%",
          margin: "1%",
          padding: 8,
          paddingTop: 36,
          paddingBottom: 36,
          flexGrow: 1,
          alignItems: "stretch",
          elevation: 2,
        },
      ]}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      <View
        style={[
          StyleUtils.border_color_btn_muted,
          StyleUtils.shape_btn_default_rounded,

          {
            position: "absolute",
            top: 6,
            left: 6,
            height: 24,
            width: 24,
            overflow: "hidden",
            justifyContent: "center",
          },
        ]}
      >
        <Text
          style={[
            textStyleForAnswerState(answerState),
            {
              textAlign: "center",
              textAlignVertical: "center",
              flexWrap: "wrap",
            },
          ]}
        >
          {String.fromCharCode(97 + answerIndex)}
        </Text>
      </View>
      {(answerState === AnswerState.Correct ||
        answerState === AnswerState.Wrong) && (
        <View
          style={[
            {
              position: "absolute",
              top: 6,
              right: 6,
              height: 24,
              width: 24,
              overflow: "hidden",
            },
          ]}
        >
          {answerState === AnswerState.Correct && (
            <SVGImage source={<CorrectIcon />} />
          )}
          {answerState === AnswerState.Wrong && (
            <SVGImage source={<WrongIcon />} />
          )}
        </View>
      )}
      <Text
        style={[
          textStyleForAnswerState(answerState),
          StyleUtils.font_size_16,
          { textAlign: "center" },
        ]}
      >
        {answer}
      </Text>
    </TouchableOpacity>
  );
};

const cardStyleForAnswerState = (answerState: AnswerState) => {
  switch (answerState) {
    case AnswerState.Answered:
      return style.card_answered;
    case AnswerState.Wrong:
      return style.card_wrong;
    case AnswerState.Correct:
      return style.card_correct;
    case AnswerState.Unanswered:
    default:
      return style.card_unanswered;
  }
};

const textStyleForAnswerState = (answerState: AnswerState) => {
  switch (answerState) {
    case AnswerState.Answered:
    case AnswerState.Wrong:
    case AnswerState.Correct:
      return style.text_answered;
    case AnswerState.Unanswered:
    default:
      return style.text_unanswered;
  }
};

const Comment = (props: {
  user?: User;
  text?: string;
  date?: Date;
  onPost?: (text: string) => void;
  disabled?: boolean;
  style?: ViewStyle;
  placeholder?;
}) => {
  const [commentDraft, setCommentDraft] = useState("");
  return (
    <View
      style={[
        StyleUtils.color_bg_white,
        StyleUtils.shape_btn_default,
        props.style,
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          paddingLeft: 8,
          paddingRight: 16,
        }}
      >
        <Image
          style={[
            { height: 40, width: 40, margin: 8 },
            StyleUtils.shape_btn_default_rounded,
          ]}
          source={
            props.user && props.user.imageURL
              ? {
                  uri: props.user.imageURL,
                }
              : require("../../assets/userPlaceHolder.png")
          }
        />
        <Text
          style={[
            StyleUtils.color_txt_dark,
            StyleUtils.font_btn_bold,
            { flex: 1, alignSelf: "center" },
          ]}
        >
          {!!props.user && props.user.name}
        </Text>
        <Text style={[StyleUtils.color_txt_normal, { alignSelf: "center" }]}>
          {formatCommentDate(props.date)}
        </Text>
      </View>
      <View style={[StyleUtils.color_btn_muted, { height: 1 }]} />
      {!!props.text && (
        <Text style={[StyleUtils.color_txt_normal, { padding: 16 }]}>
          {props.text}
        </Text>
      )}
      {!props.text && (
        <>
          <TextInput
            style={[
              StyleUtils.color_txt_normal,
              { padding: 12, margin: 3, marginTop: 2 },
            ]}
            multiline={true}
            defaultValue={props.text}
            onChangeText={(text) => {
              setCommentDraft(text);
            }}
            placeholder={
              props.placeholder ? props.placeholder : "Escreva um comentário..."
            }
            placeholderTextColor={COLOR_TXT_LIGHT}
          />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: 16,
            }}
          >
            <TouchableOpacity
              style={[
                StyleUtils.color_btn_muted,
                {
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                },
                !!commentDraft && StyleUtils.color_bg_primary,
              ]}
              disabled={!commentDraft}
              onPress={() => {
                if (
                  commentDraft &&
                  props.text !== commentDraft &&
                  props.onPost
                ) {
                  props.onPost(commentDraft);
                }
              }}
            >
              <SVGImage
                style={{ position: "absolute", top: 11, left: 11 }}
                source={<SendIcon />}
              />
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
};
function formatCommentDate(date?: Date) {
  if (!date) {
    return "";
  }
  const today = Utils.nextDay(new Date(), 0);
  const tomorrow = Utils.nextDay(today);
  if (date >= today && date < tomorrow) {
    return date.getHours() + "h";
  }
  return date.toLocaleDateString("pt-br");
}

const style = StyleSheet.create({
  card_unanswered: {
    backgroundColor: COLOR_WHITE,
  },
  card_answered: {
    backgroundColor: COLOR_PRIMARY,
  },
  card_wrong: {
    backgroundColor: COLOR_DANGER,
  },
  card_correct: {
    backgroundColor: COLOR_SUCCESS,
  },
  text_unanswered: {
    color: COLOR_TXT_NORMAL,
  },
  text_answered: {
    color: COLOR_WHITE,
  },
  dot_unanswered: {
    backgroundColor: "#494D77",
  },
});
