import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";
import StyleUtils, { COLOR_PRIMARY, COLOR_TXT_DARK } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import WhatsappIcon from "../../assets/ico-whatsapp.svg";
import BackgroundShapesBlue from "../../assets/backgroundBluePopup.svg";
import CloseIcon from "../../assets/ico_close.svg";
import { analytics } from "../Analytics";
import Modal from "react-native-modal";
import { connect } from "react-redux";
import CouponPopup from "./CouponPopup";

type Props = {
  show;
  close;
  header?;
  subHeader?;
  buttonText?;
  whatsappLink?;
  user;
  refetch?;
  onContinue?;
  onPay?;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

const BuyCreditPopup = ({
  show,
  close,
  header,
  subHeader,
  buttonText,
  whatsappLink,
  refetch,
  onContinue,
  onPay,
}: Props) => {
  const [showCouponModal, setShowCouponModal] = useState(false);
  return (
    <Modal animationIn="fadeIn" isVisible={show} onBackdropPress={close}>
      <View style={styles.modalView}>
        <TouchableOpacity
          onPress={close}
          style={{
            marginTop: 10,
            zIndex: 1,
            position: "absolute",
            right: 15,
          }}
        >
          <SVGImage source={<CloseIcon />} />
        </TouchableOpacity>
        <SVGImage
          source={<BackgroundShapesBlue />}
          style={{
            marginTop: 14,
            zIndex: 0,
            position: "absolute",
            width: "100%",
            alignItems: "center",
          }}
        />
        {header && <Text style={styles.modalTextHeader}>{header}</Text>}
        {!header && (
          <>
            <Text style={styles.modalTextHeader}>
              Adquira mais créditos e continue aprendendo!
            </Text>
          </>
        )}
        {subHeader && <Text style={styles.modalText}>{subHeader}</Text>}
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            {
              height: 48,
              width: 263,
              justifyContent: "center",
              marginTop: 24,
              backgroundColor: COLOR_PRIMARY,
              flexDirection: "row",
              alignItems: "center",
            },
          ]}
          onPress={() => {
            if (whatsappLink) {
              analytics.sendSignUpPremium();
              Linking.openURL(whatsappLink);
            } else if (onContinue) {
              analytics.sendReviewsOnboardingChooseLater();
              onContinue();
            } else {
              if (buttonText) {
                analytics.sendSupportWhatsapp();
                Linking.openURL(
                  "https://api.whatsapp.com/send?phone=5511968487979&text=Ol%C3%A1!%20Preciso%20de%20suporte%20no%20app%20do%20Tutore%2C%20voc%C3%AA%20conseguiria%20me%20ajudar%3F"
                );
              } else {
                analytics.sendBuyCredits();
                onPay();
              }
            }
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              {
                textAlign: "center",
                paddingTop: 2,
              },
            ]}
          >
            {buttonText ? buttonText : "Comprar Créditos"}
          </Text>
          {!onContinue && !onPay && (
            <SVGImage
              source={<WhatsappIcon />}
              style={{ right: 30, position: "absolute" }}
            />
          )}
        </TouchableOpacity>
        {!onContinue && (
          <TouchableOpacity
            onPress={() => {
              setShowCouponModal(true);
            }}
            style={{
              marginTop: 30,
            }}
          >
            <Text
              style={[
                styles.modalText,

                { textDecorationLine: "underline", color: COLOR_PRIMARY },
              ]}
            >
              Adicionar Cupom
            </Text>
          </TouchableOpacity>
        )}
        {onContinue && (
          <TouchableOpacity
            onPress={() => {
              close();
            }}
            style={{
              marginTop: 30,
            }}
          >
            <Text
              style={[
                styles.modalText,

                { textDecorationLine: "underline", color: COLOR_TXT_DARK },
              ]}
            >
              Cancelar
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <CouponPopup
        show={showCouponModal}
        close={() => {
          setShowCouponModal(false);
        }}
        closeAll={() => {
          close();
        }}
        refetch={refetch}
      />
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 30,
    paddingTop: 50,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTextHeader: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    marginTop: 25,
    textAlign: "center",
  },
  modalText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "500",
  },
});

export default connect(mapStateToProps, null)(BuyCreditPopup);
