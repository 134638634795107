import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Tutor } from "../Models/TutorInfo";
import SuperTutorCard from "../TutorCards/SuperTutorCard";
import { LinearGradient } from "../Utils/LinearGradient";
import StyleUtils from "../Utils/StyleUtils";
import SearchLevelSubjectBar from "./SearchLevelSubjectBar";

type Props = {
  mobile?: boolean;
  onSelectTutor?: (tutor: Tutor) => void;
  onFilterChanged: (newFilter) => void;
  tutorList: Tutor[];
  filter;
  onChatPress?: () => void;
  onLikePress?: (favorite: boolean) => void;
  onShowMore?: boolean;
};

function HeaderMatchList(props: Props) {
  return (
    <View style={styles.container}>
      <HeaderBackGround />
      <View
        style={{
          alignSelf: "stretch",
          alignItems: "center",
        }}
      >
        <View style={{ position: "absolute", zIndex: 10, width: "100%" }}>
          <SearchLevelSubjectBar
            filter={props.filter}
            onFilterChanged={props.onFilterChanged}
          />
        </View>
        <View
          style={{
            marginTop: 72,
            marginHorizontal: 24,
            flex: 1,
            alignItems: "center",
          }}
        >
          <Text style={[styles.textBoldWhite, { marginVertical: 16 }]}>
            Professor Recomendado
          </Text>
          {props.tutorList.length > 0 && (
            <SuperTutorCard
              tutor={props.tutorList[0]}
              onSelectTutor={props.onSelectTutor}
              onChatPress={props.onChatPress}
              onLikePress={props.onLikePress}
            />
          )}
          {props.onShowMore && (
            <Text style={[styles.textBlack, { marginTop: 40 }]}>
              Outros professores para você
            </Text>
          )}
        </View>
      </View>
    </View>
  );
}

export default HeaderMatchList;

export function HeaderBackGround() {
  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        flex: 1,
        flexDirection: "column",
        height: 520,
        width: "100%",
      }}
    >
      <LinearGradient
        colors={["#0179FF", "#18BAFF"]}
        style={{
          flexDirection: "column",
          flex: 1,
          height: "60%",
        }}
      >
        <View
          style={{
            height: "62%",
            width: "100%",
            marginTop: "10%",
          }}
        >
          <LinearGradient
            start={{ x: 1.0, y: 0.0 }}
            end={{ x: 0.0, y: 1.0 }}
            colors={["#0055B5", "rgba(1, 121, 255, 0)"]}
            locations={[0.0453, 0.5993]}
            style={{
              position: "absolute",
              top: -10,
              left: -10,
              right: -10,
              bottom: 0,
              height: "100%",
              borderRadius: 10000,
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </View>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.flatten(StyleUtils.screen_default),
  },
  textBoldWhite: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    textAlign: "center",
  },
  textWhite: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
  textBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    textAlign: "center",
  },
});
