import React, { useEffect, useMemo, useState } from "react";
import { StyleProp, Text, TextStyle, View } from "react-native";
import { classroomTimer } from "./ClassroomTimer";

export function ClassroomTimer(props: {
  style?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  showOnlyAfterEndingThreshold?: boolean;
  timeUnit?: "seconds" | "minutes";
}) {
  const [remainingTime, setRemainingTime] = useState<number | undefined>(
    classroomTimer.getRemainingSeconds()
  );

  const remainingTimeString = useMemo(() => {
    if (remainingTime === undefined) {
      return undefined;
    }
    if (
      props.showOnlyAfterEndingThreshold &&
      remainingTime > classroomTimer.countdownThreshold()
    ) {
      return undefined;
    }
    return props.timeUnit == "minutes"
      ? toMinutesString(remainingTime)
      : toSecondsString(remainingTime);
  }, [remainingTime]);

  useEffect(() => {
    const unsubscriber = classroomTimer.on("timeUpdate", setRemainingTime);
    return () => unsubscriber.unsubscribe();
  }, []);

  return (
    <>
      {!!remainingTimeString && (
        <View style={props.style}>
          <Text style={props.textStyle}>{remainingTimeString}</Text>
        </View>
      )}
    </>
  );
}

function toSecondsString(remainingSeconds: number) {
  const minutes = ("0" + Math.floor(remainingSeconds / 60)).slice(-2);
  const seconds = ("0" + Math.floor(remainingSeconds % 60)).slice(-2);
  return minutes + ":" + seconds;
}

function toMinutesString(seconds: number) {
  const remainingMinutes = Math.ceil(seconds / 60);
  return remainingMinutes + " min";
}
