import React from "react";
import Card from "./Card";
import CalendarBadge from "../../../assets/calendarBadge.svg";
import * as Utils from "../../Utils/Utils";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  mobile?: boolean;
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  eventsOfToday: number;
  onPress?: () => void;
  style?: ViewStyle;
};

const CalendarCard = (props: Props) => {
  const { eventsOfToday, ...other } = props;
  return (
    <Card
      {...other}
      badge={<CalendarBadge />}
      text={
        eventsOfToday > 0
          ? `${Utils.formatPositiveInteger(eventsOfToday, 2)} Aulas Hoje`
          : "Sem Aulas"
      }
      buttonText="Ver agenda"
    />
  );
};

export default CalendarCard;
