import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_PRIMARY,
  SpcStyle,
} from "../../Utils/StyleUtils";
import { connect } from "react-redux";
import SVGImage from "../../Utils/SVGImage";
import BackButton from "../../../assets/backButtonBorder.svg";
import ImageUploader from "../../ImageUploader/ImageUploader";
import { getFileURL } from "../../Server";
import SignUpPopUp from "../../SignUp/SignUpPopUp";
import { analytics } from "../../Analytics";
import { setExerciseData } from "../../Actions";
import { withRouter } from "../../Router";

type Props = {
  onBack;
  history;
  isLowFriction;
  headerText;
  subHeaderText;
  onContinue;
  setExerciseData;
  dispatch;
};

type State = {
  imageUploaderVisible;
  photo;
  textInput;
  showLogin;
  message;
};

function mapDispatchToProps(dispatch) {
  return {
    setExerciseData: (data) => dispatch(setExerciseData(data)),
  };
}

const tutoreId = "4c8eed66-0c37-49ed-a5d8-b575487c0fa1";

export class ExerciseUpload extends Component<Props, State> {
  private myScrollView: any;
  constructor(props) {
    super(props);
    this.state = {
      imageUploaderVisible: true,
      photo: "",
      textInput: false,
      showLogin: false,
      message: [],
    };
  }
  onImageUploadSuccessful(file) {
    this.setState({ imageUploaderVisible: false });
    const filename = file.name ? file.name : file.fileName;
    const fileURL = getFileURL(filename);
    this.setState({ photo: fileURL });
  }
  header() {
    return (
      <View
        style={[
          {
            flexDirection: "row",
            marginTop: 24,
            marginHorizontal: 14,
            justifyContent: "space-between",
            backgroundColor: "white",
            height: 57,
          },
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            analytics.sendExerciseOnboardingBack();
            this.props.onBack();
          }}
        >
          <SVGImage source={<BackButton />} />
        </TouchableOpacity>
      </View>
    );
  }
  onLoginSuccessPopUp = () => {
    this.props.history.push({
      pathname: "/messages/" + tutoreId,
      state: {
        shouldGoBack: false,
        messages: this.state.message,
        image: this.state.photo,
        exerciseOnboarding: true,
      },
    });
  };
  onSignUpSuccessPopUp = () => {
    this.props.history.push({
      pathname: "/messages/" + tutoreId,
      state: {
        shouldGoBack: false,
        messages: this.state.message,
        image: this.state.photo,
        exerciseOnboarding: true,
      },
    });
  };

  render() {
    return (
      <>
        <View
          style={[
            {
              flex: 1,
              backgroundColor: "white",
            },
          ]}
        >
          {this.header()}
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
            style={{ flex: 1, backgroundColor: "white" }}
          >
            <ScrollView
              ref={(component) => {
                this.myScrollView = component;
              }}
            >
              <View
                style={{
                  justifyContent: "flex-start",
                  marginLeft: 32,
                  marginTop: 36,
                }}
              >
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_cera_bold,
                    StyleUtils.font_size_22,
                    {
                      marginRight: 12,
                    },
                  ]}
                >
                  {this.props.headerText}
                </Text>
                <Text
                  style={[
                    StyleUtils.color_txt_light,
                    StyleUtils.font_cera_regular,
                    StyleUtils.font_size_16,
                    {
                      marginRight: 12,
                      marginTop: 8,
                      marginBottom: this.state.imageUploaderVisible ? 26 : 0,
                    },
                  ]}
                >
                  {this.props.subHeaderText}
                </Text>
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: this.state.photo ? 30 : 100,
                }}
              >
                <ImageUploader
                  descriptionText={
                    "Selecione uma foto para\nenviar aos nossos Tutores "
                  }
                  style={[
                    {
                      position: "absolute",
                      minWidth: "90%",
                      minHeight: 148,
                    },
                  ]}
                  isVisible={this.state.imageUploaderVisible}
                  onImageUploaded={this.onImageUploadSuccessful.bind(this)}
                  isMobile={true}
                />
                {!!this.state.photo && (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={{ uri: this.state.photo }}
                      style={{
                        width: 300,
                        height: 300,
                        borderRadius: 6,
                      }}
                    />
                  </View>
                )}
              </View>
              {!!this.state.photo && this.props.isLowFriction && (
                <>
                  <Text
                    style={[
                      StyleUtils.color_txt_dark,
                      StyleUtils.font_cera_bold,
                      StyleUtils.font_size_16,
                      {
                        marginTop: 40,
                        textAlign: "left",
                        marginLeft: 32,
                      },
                    ]}
                  >
                    Adicione um comentário
                  </Text>
                  <Text
                    style={[
                      StyleUtils.color_txt_dark,
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_14,
                      {
                        marginTop: 2,
                        textAlign: "left",
                        marginLeft: 32,
                      },
                    ]}
                  >
                    Isso vai nos ajudar a entender a sua dúvida
                  </Text>
                </>
              )}
              {!this.props.isLowFriction && (
                <Text
                  style={[
                    StyleUtils.color_txt_light,
                    StyleUtils.font_cera_regular,
                    StyleUtils.font_size_16,
                    {
                      marginTop: this.state.photo ? 20 : 100,
                      textAlign: "center",
                    },
                  ]}
                >
                  Adicione um comentário para nos ajudar {"\n"} a compreender
                  melhor sua dúvida
                </Text>
              )}
              <TextInput
                placeholder={"Deixe seu comentário..."}
                placeholderTextColor={COLOR_MUTED}
                multiline={true}
                onFocus={() =>
                  this.myScrollView.scrollTo({
                    x: 0,
                    y: 750,
                    animated: true,
                  })
                }
                onChangeText={(text) => {
                  if (text && text.trim()) {
                    this.setState({ textInput: true, message: text });
                  } else {
                    this.setState({ textInput: false });
                  }
                }}
                style={[
                  SpcStyle.cellUnselected,
                  StyleUtils.font_cera_regular,
                  {
                    height: 136,
                    borderRadius: 10,
                    marginHorizontal: 32,
                    marginTop:
                      (!!this.state.photo && this.props.isLowFriction) ||
                      !this.props.isLowFriction
                        ? 12
                        : 120,
                    marginBottom: 24,
                    color: "black",
                    textAlign: "auto",
                    paddingTop: 10,
                    paddingHorizontal: 10,
                    textAlignVertical: "top",
                    borderWidth: 1,
                    borderColor: this.state.textInput ? "#000000" : "#E1E8F3",
                    opacity:
                      (!!this.state.photo && this.props.isLowFriction) ||
                      !this.props.isLowFriction
                        ? 1
                        : 0,
                  },
                ]}
              />
            </ScrollView>
          </KeyboardAvoidingView>
          <View
            style={[
              {
                backgroundColor: "white",
                width: "100%",
                height: 96,
                borderColor: COLOR_MUTED,
                borderWidth: 1,
                shadowColor: COLOR_MUTED,
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowRadius: 9,
                shadowOpacity: 1,
              },
            ]}
          >
            <TouchableOpacity
              style={[
                StyleUtils.shape_btn_small,
                {
                  height: 48,
                  justifyContent: "center",
                  marginHorizontal: 32,
                  marginTop: 24,
                  backgroundColor:
                    this.state.textInput || !this.state.imageUploaderVisible
                      ? COLOR_PRIMARY
                      : COLOR_MUTED,
                },
              ]}
              onPress={() => {
                analytics.sendExerciseOnboardingSendExercise();
                if (this.props.isLowFriction) {
                  const photoData = {
                    photo: this.state.photo,
                    comment: this.state.message,
                  };
                  this.props.setExerciseData(photoData);
                  this.props.onContinue();
                } else {
                  setTimeout(() => {
                    this.setState({ showLogin: true });
                  }, 300);
                }
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_16,
                  {
                    textAlign: "center",
                  },
                ]}
              >
                Enviar
              </Text>
            </TouchableOpacity>
          </View>
          <SignUpPopUp
            show={this.state.showLogin}
            isLogin={false}
            onLoginSuccess={this.onLoginSuccessPopUp}
            onSignUpSuccess={this.onSignUpSuccessPopUp}
            close={() => {
              this.setState({ showLogin: false });
            }}
            history={this.props.history}
            isExerciseOnboarding={true}
          />
        </View>
      </>
    );
  }
}

const style = StyleSheet.create({
  cell: {
    height: 50,
    alignItems: "center",
    flexDirection: "row",
  },
  cellSeparator: {
    position: "absolute",
    bottom: 0,
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: COLOR_MUTED,
  },
});

export default withRouter(connect(null, mapDispatchToProps)(ExerciseUpload));
