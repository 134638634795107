import Homework from "../Models/Homework";
import SignUpUser from "../Models/SignUpUser";
import SubjectTest from "../Models/SubjectTest";
import TuteeInfo from "../Models/TuteeInfo";
import TutorInfo, { Tutor } from "../Models/TutorInfo";
import User from "../Models/User";

export const loginSuccessful = (
  user: User,
  tuteeInfo?: TuteeInfo,
  tutorInfo?: TutorInfo
) => ({
  type: "LOGIN_SUCCESSFUL",
  user: {
    id: user.id,
    name: user.name,
    email: user.email,
    imageURL: user.imageURL,
    gender: user.gender,
    birthday: user.birthday,
  },
  tuteeInfo: tuteeInfo,
  tutorInfo: tutorInfo,
});

export const setNoLoginUser = (userWithoutLogin) => ({
  type: "NO_LOGIN_USER",
  userWithoutLogin: userWithoutLogin,
});

export const setSignUpUserInfo = (user: SignUpUser) => ({
  type: "SET_USER_INFO",
  user: {
    name: user.name,
    email: user.email,
    imageURL: user.imageURL,
    gender: user.gender,
    birthday: user.birthday,
    appleId: user.appleId,
  },
});

export const isSuperTutor = (superTutor) => ({
  type: "SUPER_TUTOR",
  superTutor: superTutor,
});

export const bookingInfo = (lessonInfo) => ({
  type: "LESSON_INFO",
  lessonInfo: lessonInfo,
});

export const selectTutor = (selectedTutor) => ({
  type: "SELECTED_TUTOR",
  selectedTutor: selectedTutor,
});

export const priceInfo = (priceInfos) => ({
  type: "PRICE_INFO",
  priceInfos: priceInfos,
});
export const selectUser = (user) => ({
  type: "SELECTED_USER",
  selectedUser: user,
});

export const selectEvent = (event) => ({
  type: "SELECTED_EVENT",
  selectedEvent: event,
});

export const bookEvent = (event) => ({
  type: "BOOKED_EVENT",
  bookedEvent: event,
});

export const scheduleDraftDaysChosen = (days) => ({
  type: "SCHEDULE_DRAFT_DAYS_CHOSEN",
  scheduleDraftDays: days,
});

export const scheduleDraftHoursChosen = (startHour, endHour) => ({
  type: "SCHEDULE_DRAFT_HOURS_CHOSEN",
  scheduleDraftHours: {
    start: startHour,
    end: endHour,
  },
});

export const setWhiteboardRoom = (whiteboardRoom) => ({
  type: "SET_WHITEBOARD_ROOM",
  whiteboardRoom: whiteboardRoom,
});

export const selectImagePopup = (imageURL) => ({
  type: "SELECTED_IMAGE_POPUP",
  selectedImagePopup: imageURL,
});

export const selectHomework = (homework) => ({
  type: "SELECTED_HOMEWORK",
  selectedHomework: homework,
});

export const selectRating = (rating) => ({
  type: "SELECTED_RATING",
  selectedRating: rating,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const setTuteeInfo = (tuteeInfo: TuteeInfo) => ({
  type: "SET_TUTEE_INFO",
  tuteeInfo: tuteeInfo,
});

export const setTutorInfo = (tutorInfo: TutorInfo) => ({
  type: "SET_TUTOR_INFO",
  tutorInfo: tutorInfo,
});

export const setMobileLayout = (mobile: boolean) => ({
  type: "MOBILE_STATE_CHANGED",
  mobile: mobile,
});

export const setHomeworks = (homeworks: Homework[], isTutor?: boolean) => ({
  type: "HOMEWORKS",
  homeworks,
  isTutor,
});

export const clearHomeworkNotification = () => ({
  type: "CLEAR_HOMEWORK_NOTIFICATION",
});

export const setOnboardingData = (onboardingData) => ({
  type: "SET_ONBOARDING_DATA",
  tutor: onboardingData.tutor,
  level: onboardingData.level,
  subject: onboardingData.subject,
  timing: onboardingData.timing,
});

export const setSearchFilter = (filterData) => ({
  type: "SET_SEARCH_FILTER",
  level: filterData.level,
  subject: filterData.subject,
});

export const setTutorSearch = (tutors: Tutor[]) => ({
  type: "TUTOR_SEARCH",
  searchedTutors: tutors,
  useSearchedTutors: false,
});

export const setTutorSearchBack = () => ({
  type: "TUTOR_SEARCH_BACK",
  useSearchedTutors: true,
});

export const setExerciseData = (exerciseData) => ({
  type: "SET_EXERCISE_DATA",
  photo: exerciseData.photo,
  comment: exerciseData.comment,
});

export const setViewedHome = () => ({
  type: "SET_VIEWED_HOME",
});

export const setViewedSurvey = () => ({
  type: "SET_VIEWED_SURVEY",
});

export const selectSubjectTest = (subjectTest) => ({
  type: "SELECTED_SUBJECT_TEST",
  selectedSubjectTest: subjectTest,
});

export const setSubjectTests = (
  subjectTests: SubjectTest[],
  isTutor?: boolean
) => ({
  type: "SUBJECT_TESTS",
  subjectTests,
  isTutor,
});

export const setViewedFreeCreditPopUp = (value) => ({
  type: "SET_VIEWED_FREE_CREDIT_POP_UP",
  value,
});

export const setCounselor = (counselor) => ({
  type: "SET_COUNSELOR",
  counselor,
});

export const setTalkspaceOnboarding = (value) => ({
  type: "SET_TALKSPACE_ONBOARDING",
  value,
});

export const setSeenTalkspaceFirstMessages = () => ({
  type: "SET_SEEN_TALKSPACE_FIRST_MESSAGES",
});

export const setCounselorNewMessages = (value) => ({
  type: "SET_NEW_COUNSELOR_MESSAGES",
  value,
});

export const cardToRemove = (cardToRemove) => ({
  type: "SET_CARD_TO_REMOVE",
  payload: cardToRemove,
});

export const setFromDynamicLink = (fromDynamicLink) => ({
  type: "SET_FROM_DYNAMIC_LINK",
  fromDynamicLink: fromDynamicLink,
});
