import React from "react";
import { Component } from "react";
import SVGImage from "../Utils/SVGImage";
import CloseCall from "./../../assets/closecall.svg";
import MicrophoneOff from "./../../assets/microphoneoff.svg";
import MicrophoneOn from "./../../assets/microphoneOn.svg";
import CameraOff from "./../../assets/cameraoff.svg";
import CameraOn from "./../../assets/cameraOn.svg";
import { TouchableOpacity, View } from "react-native";

type Props = {
  style;
  mobile;
  videoMuted?: boolean;
  audioMuted?: boolean;
  exit;
  muteAudio;
  muteVideo;
};
export default class VideoButtons extends Component<Props, any> {
  render() {
    const buttonSize = this.props.mobile ? 56 : 40;
    const AudioButton = this.props.audioMuted ? MicrophoneOff : MicrophoneOn;
    const CameraButton = this.props.videoMuted ? CameraOff : CameraOn;

    return (
      <View style={this.props.style}>
        <TouchableOpacity onPress={this.props.exit}>
          <SVGImage
            source={<CloseCall />}
            style={{
              width: buttonSize,
              height: buttonSize,
              marginLeft: 5,
              marginRight: 5,
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={this.props.muteAudio}>
          <SVGImage
            source={<AudioButton />}
            style={{
              width: buttonSize,
              height: buttonSize,
              marginLeft: 5,
              marginRight: 5,
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={this.props.muteVideo}>
          <SVGImage
            source={<CameraButton />}
            style={{
              width: buttonSize,
              height: buttonSize,
              marginLeft: 5,
              marginRight: 5,
            }}
          />
        </TouchableOpacity>
      </View>
    );
  }
}
