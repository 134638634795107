import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import StyleUtils, { COLOR_WHITE, COLOR_MUTED } from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import TutoreFutureLogo from "../../../assets/tutoreFutureLogo.svg";

type Props = {
  onContinue;
  onCancel;
  headerText;
  subHeaderText;
  image;
  buttonText;
};

export default function TFStart(props: Props) {
  return (
    <LinearGradient
      colors={["#272B3E", "#005EC8"]}
      style={[
        StyleUtils.screen_default,
        {
          paddingVertical: 40,
        },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          marginLeft: 24,
          justifyContent: "center",
        }}
      >
        <SVGImage
          source={<TutoreFutureLogo />}
          style={{ alignSelf: "center" }}
        />
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <SVGImage
          source={<props.image />}
          style={{
            justifyContent: "center",
            alignSelf: "center",
          }}
        ></SVGImage>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_22,
            StyleUtils.color_txt_white,
            { textAlign: "center" },
          ]}
        >
          {props.headerText}
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_primary_light,
            { textAlign: "center" },
          ]}
        >
          {props.subHeaderText}
        </Text>
        <TouchableOpacity
          style={{
            borderRadius: 6,
            width: "85%",
            paddingVertical: 12,
            backgroundColor: COLOR_WHITE,
          }}
          onPress={() => props.onContinue()}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_dark,
              { textAlign: "center" },
            ]}
          >
            {props.buttonText}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={{}} onPress={() => props.onCancel()}>
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_btn_regular,
              StyleUtils.font_size_16,
              {
                textDecorationLine: "underline",
                marginTop: 20,
              },
            ]}
          >
            Cancelar
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  lineMuted: {
    flex: 1,
    borderTopWidth: 0,
    borderWidth: 1,
    borderColor: COLOR_MUTED,
    height: 0,
    marginVertical: 12,
    marginHorizontal: 32,
  },
});
