import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import SVGImage from "../Utils/SVGImage";
import ToolPencil from "../../assets/toolpencil.svg";
import ToolPencilSelected from "../../assets/toolpencilSelected.svg";
import ToolText from "../../assets/tooltext.svg";
import ToolTextSelected from "../../assets/toolTextSelected.svg";
import ToolMathInput from "../../assets/toolMathInput.svg";
import ToolMathInputSelected from "../../assets/toolMathInputSelected.svg";
import ToolHand from "../../assets/toolHand.svg";
import ToolHandSelected from "../../assets/toolHandSelected.svg";
import ToolImage from "../../assets/toolImage.svg";
import ToolImageSelected from "../../assets/toolImageSelected.svg";
import StyleUtils, { COLOR_DANGER } from "../Utils/StyleUtils";
import { UploadStatusType } from "../Utils/FileUtils";
import ImageUploader from "../ImageUploader/ImageUploader";
import ToolShapes from "../../assets/toolShapes.svg";
import ToolShapesSelected from "../../assets/toolShapesSelected.svg";
import ShapeTriangle2 from "../../assets/shapeTriangle2.svg";
import ShapeCircle from "../../assets/shapeCircle.svg";
import ShapeLine from "../../assets/shapeLine.svg";
import ShapeSquare from "../../assets/shapeSquare.svg";
import ShapeTriangle from "../../assets/shapeTriangle.svg";
import ShapeArrow from "../../assets/shapeArrow.svg";
import {
  renderPencilColorButton,
  renderPencilColorPicker,
  renderPencilStrokePicker,
  renderPencilStrokeWidth,
} from "./CanvasPencilTools";
import { SafeAreaInsetsContext } from "react-native-safe-area-context";
import { addStyles, EditableMathField } from "./MathInput/EditableMathField";
import ToolExercise from "../../assets/icoToolExercise.svg";
import ToolExerciseSelected from "../../assets/icoToolExerciseSelected.svg";
import ToolZoomIn from "../../assets/zoom-in.svg";
import ToolZoomOut from "../../assets/zoom-out.svg";
import Camera from "../../assets/screenshot.svg";

type Props = {
  isTutor;
  mobile: boolean;
  onChangeColor;
  onChangeStroke;
  onAddShape;
  onToolSelected;
  onImageUploaded;
  onSetFormula;
  style;
  onSetZoom;
  onSetScreenshot;
  changeTool;
  changeSubmenu;
  submenu;
  selectedTool;
  newExercises?: boolean;
  setNewExercisesToFalse?;
};

type State = {
  imageUploaderVisible;
  uploadStatus;
  selectedColor: string;
  selectedStroke: number;
  latex;
  zoom;
};

export default class CanvasTools extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      imageUploaderVisible: false,
      uploadStatus: UploadStatusType.ready,
      selectedColor: "#0179FF",
      selectedStroke: 2,
      latex: "",
      zoom: false,
    };
    if (!this.props.mobile) addStyles();
  }

  toolZoomToggle = (zoom) => {
    this.setState({ zoom });
    this.props.onSetZoom(zoom);
  };

  toolButtonPressed = (toolName) => {
    if (this.state.uploadStatus != UploadStatusType.uploading) {
      this.setState({ uploadStatus: UploadStatusType.ready });
    }

    let imageUploaderVisible = false;
    if (toolName == "uploader") {
      imageUploaderVisible = !this.state.imageUploaderVisible;
      toolName = "selector";
    }
    this.setState({
      imageUploaderVisible: imageUploaderVisible,
    });
    this.props.changeSubmenu(toolName);
    this.props.changeTool(toolName);
    this.props.onToolSelected(toolName);
  };

  onImageUploadSuccessful(file) {
    this.setState({ imageUploaderVisible: false });
    this.toolButtonPressed("hand");
    this.props.onImageUploaded(file);
  }

  render() {
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          <View
            style={[
              this.props.style,
              {
                flexDirection: this.props.mobile ? "column" : "row",
                overflow: "visible",
              },
            ]}
          >
            {this.props.mobile && (
              <ImageUploader
                descriptionText={"Enviar imagem"}
                style={[
                  {
                    position: "absolute",
                    minWidth: 300,
                    minHeight: 160,
                    bottom: "100%",
                    alignSelf: "center",
                  },
                ]}
                isVisible={this.state.imageUploaderVisible}
                onImageUploaded={this.onImageUploadSuccessful.bind(this)}
                isMobile={this.props.mobile}
              />
            )}
            <View
              style={[
                {
                  flexDirection: this.props.mobile ? "row" : "column",
                  overflow: "visible",
                  marginBottom: insets ? insets.bottom : 0,
                },
              ]}
            >
              {this.renderHandTool()}
              {this.renderPencilTool()}
              {this.renderTextTool()}
              {this.props.isTutor &&
                !this.props.mobile &&
                this.renderMathInputWeb()}
              {this.props.isTutor && this.renderShapeTool()}
              {/*{this.renderEraserTool()}*/}
              {this.renderImageUploaderTool()}
              {this.renderExerciseTool()}
              {this.renderZoomTool()}
              {this.renderScreenshot()}
            </View>

            {!this.props.mobile && (
              <ImageUploader
                descriptionText={"Arraste a imagem aqui"}
                style={[
                  {
                    marginLeft: 12,
                    top: 85,
                    minWidth: 230,
                  },
                ]}
                isVisible={this.state.imageUploaderVisible}
                onImageUploaded={this.onImageUploadSuccessful.bind(this)}
                isMobile={this.props.mobile}
              />
            )}
          </View>
        )}
      </SafeAreaInsetsContext.Consumer>
    );
  }

  renderHandTool(): React.ReactNode {
    return (
      <TouchableOpacity
        style={{
          padding: this.props.mobile ? 0 : 12,
          paddingTop: this.props.mobile ? 0 : 20,
        }}
        onPress={() => {
          this.toolButtonPressed("hand");
        }}
      >
        <SVGImage
          source={
            this.props.selectedTool === "hand" ? (
              <ToolHandSelected />
            ) : (
              <ToolHand />
            )
          }
        />
      </TouchableOpacity>
    );
  }

  renderPencilTool(): React.ReactNode {
    return (
      <View>
        <TouchableOpacity
          style={{
            padding: this.props.mobile ? 0 : 12,
            paddingTop: this.props.mobile ? 0 : 4,
            overflow: "visible",
          }}
          onPress={() => {
            this.toolButtonPressed("pencil");
            if (this.props.submenu.includes("pencil"))
              this.props.changeSubmenu("");
            else this.props.changeSubmenu("pencil");
          }}
        >
          <SVGImage
            source={
              this.props.selectedTool === "pencil" ? (
                <ToolPencilSelected />
              ) : (
                <ToolPencil />
              )
            }
          />
        </TouchableOpacity>
        {this.props.isTutor &&
          this.props.submenu.includes("pencil") &&
          this.renderPencilSubMenu()}
      </View>
    );
  }

  renderPencilSubMenu(): React.ReactNode {
    return (
      <View
        style={{
          position: "absolute",
        }}
      >
        <View
          style={[
            {
              position: "absolute",
              marginTop: 5,
              zIndex: 2,
              left: this.props.mobile ? 0 : 64,
              bottom: this.props.mobile ? 0 : -45,
              height: 40,
              overflow: "visible",
              flexDirection: "row",
            },
            StyleUtils.color_bg_white,
            StyleUtils.border_color_btn_muted,
            StyleUtils.shape_btn_small,
          ]}
        >
          {renderPencilColorButton(
            this.props.submenu,
            this.props.changeSubmenu,
            this.state.selectedColor
          )}
          <View style={[StyleUtils.color_btn_muted, { width: 1 }]} />
          {renderPencilStrokeWidth(
            this.props.submenu,
            this.props.changeSubmenu,

            this.state.selectedStroke
          )}
        </View>
        {this.props.submenu.includes("pencil-stroke") &&
          renderPencilColorPicker(
            this.props.mobile,
            (state) => this.setState(state),
            this.props.onChangeStroke,
            this.state.selectedStroke
          )}
        {this.props.submenu.includes("pencil-color") &&
          renderPencilStrokePicker(
            this.props.mobile ? 0 : 64,
            this.props.mobile ? 45 : -122,
            this.props.onChangeColor,
            (state) => this.setState(state)
          )}
      </View>
    );
  }

  renderTextTool(): React.ReactNode {
    return (
      <TouchableOpacity
        style={{
          padding: this.props.mobile ? 0 : 12,
          paddingTop: this.props.mobile ? 0 : 4,
          paddingBottom: 0,
        }}
        onPress={() => {
          this.toolButtonPressed("text");
        }}
      >
        <SVGImage
          source={
            this.props.selectedTool === "text" ? (
              <ToolTextSelected />
            ) : (
              <ToolText />
            )
          }
        />
      </TouchableOpacity>
    );
  }

  renderMathInputWeb(): React.ReactNode {
    return (
      <View>
        <TouchableOpacity
          style={{
            padding: this.props.mobile ? 0 : 12,
            paddingTop: this.props.mobile ? 0 : 4,
            overflow: "visible",
          }}
          onPress={() => {
            this.toolButtonPressed("mathinput");
            if (this.props.submenu.includes("mathinput")) {
              this.toolButtonPressed("hand");
              this.props.changeSubmenu("");
            } else this.props.changeSubmenu("mathinput");
          }}
        >
          <SVGImage
            source={
              this.props.selectedTool === "mathinput" ? (
                <ToolMathInputSelected />
              ) : (
                <ToolMathInput />
              )
            }
          />
        </TouchableOpacity>
        {this.props.submenu.includes("mathinput") &&
          this.renderMatnInputWebSubMenu()}
      </View>
    );
  }

  renderMatnInputWebSubMenu(): React.ReactNode {
    return (
      <View
        style={{
          position: "absolute",
        }}
      >
        <View
          style={[
            {
              position: "absolute",
              marginTop: 5,
              zIndex: 2,
              left: this.props.mobile ? -100 : 64,
              top: 0,
              width: 208,
              overflow: "visible",
              flexDirection: "column",
              flexWrap: "wrap",
              padding: 16,
              justifyContent: "space-between",
              alignItems: "center",
            },
            StyleUtils.color_bg_white,
            StyleUtils.border_color_btn_muted,
            StyleUtils.shape_btn_small,
          ]}
        >
          <Text
            style={[
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_18,
              { alignSelf: "flex-start", marginBottom: 8 },
            ]}
          >
            Funções
          </Text>
          <View
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.border_color_btn_dark,
              { width: 176, borderWidth: 1 },
            ]}
          >
            <EditableMathField
              latex={this.state.latex} // latex value for the input field
              onChange={(mathField) => {
                // called everytime the input changes
                this.setState({ latex: mathField.latex() });
              }}
            />
          </View>
          <TouchableOpacity
            style={[
              StyleUtils.color_bg_primary,
              StyleUtils.shape_btn_small,
              { marginTop: 8, padding: 14, width: 176, alignItems: "center" },
            ]}
            onPress={() => {
              console.log(this.state.latex);
              this.props.onSetFormula(this.state.latex);
              this.setState({ latex: "" });
              this.props.changeSubmenu("");
            }}
          >
            <Text
              style={[StyleUtils.color_txt_white, StyleUtils.font_btn_bold]}
            >
              Adicionar
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  renderShapeTool(): React.ReactNode {
    return (
      <View>
        <TouchableOpacity
          style={{
            padding: this.props.mobile ? 0 : 12,
            paddingTop: this.props.mobile ? 0 : 4,
            overflow: "visible",
          }}
          onPress={() => {
            this.toolButtonPressed("shapes");
            if (this.props.submenu.includes("shapes")) {
              this.toolButtonPressed("hand");
              this.props.changeSubmenu("");
            } else this.props.changeSubmenu("shapes");
          }}
        >
          <SVGImage
            source={
              this.props.selectedTool === "shapes" ? (
                <ToolShapesSelected />
              ) : (
                <ToolShapes />
              )
            }
          />
        </TouchableOpacity>
        {this.props.submenu.includes("shapes") && this.renderShapeSubMenu()}
      </View>
    );
  }

  shapes = ["square", "circle", "triangle", "triangle2", "arrow", "line"];
  implementedShapes = ["square", "circle", "triangle"];
  renderShapeSubMenu(): React.ReactNode {
    return (
      <View
        style={{
          position: "absolute",
        }}
      >
        <View
          style={[
            {
              position: "absolute",
              marginTop: 5,
              zIndex: 2,
              left: this.props.mobile ? -100 : 64,
              bottom: this.props.mobile ? 0 : -300,
              height: 304,
              width: 208,
              overflow: "visible",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: 16,
              justifyContent: "space-between",
              alignItems: "center",
            },
            StyleUtils.color_bg_white,
            StyleUtils.border_color_btn_muted,
            StyleUtils.shape_btn_small,
          ]}
        >
          {this.shapes.map((shape) => {
            return (
              <TouchableOpacity
                style={[
                  this.implementedShapes.includes(shape)
                    ? StyleUtils.color_bg_white
                    : StyleUtils.color_btn_muted,
                  StyleUtils.border_color_btn_muted,
                  StyleUtils.shape_btn_small,
                  {
                    width: 80,
                    height: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
                onPress={(event) => {
                  this.props.onAddShape(shape);
                  this.toolButtonPressed("hand");
                }}
                disabled={!this.implementedShapes.includes(shape)}
              >
                <SVGImage source={this.svgForShape(shape)} />
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    );
  }

  svgForShape = (shape) => {
    switch (shape) {
      case "triangle2":
        return <ShapeTriangle2 />;
      case "circle":
        return <ShapeCircle />;
      case "line":
        return <ShapeLine />;
      case "square":
        return <ShapeSquare />;
      case "triangle":
        return <ShapeTriangle />;
      case "arrow":
        return <ShapeArrow />;
    }
  };

  renderImageUploaderTool(): React.ReactNode {
    return (
      <>
        <TouchableOpacity
          style={{
            padding: this.props.mobile ? 0 : 12,
            paddingTop: this.props.mobile ? 0 : 4,
            paddingBottom: 0,
          }}
          onPress={() => {
            this.toolButtonPressed("uploader");
          }}
        >
          <SVGImage
            source={
              this.state.imageUploaderVisible ? (
                <ToolImageSelected />
              ) : (
                <ToolImage />
              )
            }
          />
        </TouchableOpacity>
      </>
    );
  }

  renderExerciseTool(): React.ReactNode {
    return (
      <View>
        <TouchableOpacity
          style={{
            position: "relative",
            padding: this.props.mobile ? 0 : 12,
            paddingTop: this.props.mobile ? 0 : 4,
            paddingBottom: 0,
          }}
          onPress={() => {
            this.props.setNewExercisesToFalse &&
              this.props.setNewExercisesToFalse();
            if (this.props.selectedTool !== "exercise")
              this.toolButtonPressed("exercise");
            else this.toolButtonPressed("hand");
          }}
        >
          <SVGImage
            source={
              this.props.selectedTool === "exercise" ? (
                <ToolExerciseSelected />
              ) : (
                <ToolExercise />
              )
            }
          />
          {this.props.newExercises && (
            <View
              style={[
                {
                  top: 1,
                  left: 33,
                  position: "absolute",
                  height: 12,
                  backgroundColor: COLOR_DANGER,
                  borderRadius: 6,
                  width: 12,
                },
              ]}
            />
          )}
        </TouchableOpacity>
      </View>
    );
  }

  renderZoomTool(): React.ReactNode {
    return (
      <View>
        <TouchableOpacity
          style={{
            padding: this.props.mobile ? 0 : 12,
            paddingTop: this.props.mobile ? 0 : 4,
            paddingBottom: 0,
          }}
          onPress={() => {
            this.toolZoomToggle(!this.state.zoom);
          }}
        >
          <SVGImage
            source={this.state.zoom ? <ToolZoomOut /> : <ToolZoomIn />}
          />
        </TouchableOpacity>
      </View>
    );
  }

  renderScreenshot(): React.ReactNode {
    return (
      <TouchableOpacity
        style={{
          padding: this.props.mobile ? 0 : 12,
          paddingTop: this.props.mobile ? 0 : 4,
          paddingBottom: 0,
        }}
        onPress={() => this.props.onSetScreenshot()}
      >
        <SVGImage source={<Camera />} />
      </TouchableOpacity>
    );
  }
}
