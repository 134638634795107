import firebase from "firebase/app";
import "firebase/analytics";
import { NativeModules } from "react-native";
import webLayout from "../Utils/PlatformUtils";

const firebaseConfig = {
  apiKey: "AIzaSyCwRfrAU5SnOXPUwfDIAn9N-wCBfCcHGa4",
  authDomain: "tutore-react.firebaseapp.com",
  databaseURL: "https://tutore-react-default-rtdb.firebaseio.com",
  projectId: "tutore-react",
  storageBucket: "tutore-react.appspot.com",
  messagingSenderId: "750744001530",
  appId: "1:750744001530:web:361bb9e8cc7d4262612243",
  measurementId: "G-WH4G8D0WG9",
};

class Analytics {
  constructor() {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  sendEvent(eventName: string, params: any) {
    firebase.analytics().logEvent(eventName, params);
  }

  setUserID(userID: string) {
    if (!webLayout) {
      NativeModules.AnalyticsManager.setUserID(userID);
    } else {
      firebase.analytics().setUserId(userID);
    }
  }
}

const AnalyticsManager = new Analytics();

export { AnalyticsManager };
