import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import ChatConnection from "../Chat/ChatConnection";
import { useCanvasConnection } from "./CanvasConnection";
import StyleUtils from "../Utils/StyleUtils";
import Canvas2 from "./Canvas2";

import { useToast } from "react-native-toast-notifications";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToast();
    return <Component {...props} toast={toastFuncs} />;
  };
}

type Props = {
  currentUser;
  history;
  mobile?: boolean;
  toast;
};

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    mobile: state.layoutRed.mobile,
  };
};

function useChatConnection(currentUser, selectedUser, channelId, toast) {
  const chatConnection = useMemo(
    () => new ChatConnection(currentUser, selectedUser, channelId, toast),
    [currentUser, selectedUser, channelId]
  );
  useEffect(() => {
    return () => {
      chatConnection.classUnmount();
    };
  }, [chatConnection]);
  return chatConnection;
}

function CanvasTest(props: Props) {
  const canvasConnetion = useCanvasConnection(
    "canvas_test",
    props.currentUser,
    props.currentUser
  );
  const chatConnection = useChatConnection(
    props.currentUser,
    props.currentUser,
    "canvas_test",
    props.toast
  );

  return (
    <View
      style={[
        StyleUtils.screen_default,
        StyleUtils.color_bg_whiteboard,
        { flex: 1 },
      ]}
    >
      <Canvas2
        canvasConnection={canvasConnetion}
        style={[StyleUtils.screen_default, { flex: 1 }]}
        userID={props.currentUser.id}
        isTutor={true}
        mobile={props.mobile}
        closeWhiteboard={() => {}}
        hideVideo={(hide) => {}}
        selectedUser={props.currentUser}
        channelID={"canvas_test"}
        openWhiteboard={() => {}}
        setWhiteboardChangedToTrue={() => {}}
      />
    </View>
  );
}
export default withToast(connect(stateToProps, null)(CanvasTest));
