import { useState, useEffect } from "react";

const useEsc = (onDownPress) => {
  const downHandler = ({ key }) => {
    if (key === "Escape") {
      onDownPress();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });
};

export default useEsc;
