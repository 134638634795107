import React from "react";
import Card from "./Card";
import ExerciseBadge from "../../../assets/exerciseBadge.svg";

type Props = {
  mobile?: boolean;
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  numberToReviewExercises: number;
  onPress?: () => void;
};

const ExercisesTutorCard = (props: Props) => {
  const { numberToReviewExercises, ...other } = props;
  let exercises = numberToReviewExercises;
  if (exercises > 99) {
    exercises = 99;
  }
  return (
    <Card
      {...other}
      badge={<ExerciseBadge />}
      text="Novas Respostas"
      buttonText="Revisar"
      notification={exercises > 0 ? "" + exercises : undefined}
    ></Card>
  );
};

export default ExercisesTutorCard;
