import User from "../Models/User";
import { createSockeckIO } from "../Server";

const socket = createSockeckIO();

class SocketNotifications {
  user?: User;

  constructor() {}

  open(user: User) {
    this.user = user;

    this.onConnect = this.onConnect.bind(this);
    socket.on("connect", this.onConnect);
    this.onDisconnect = this.onDisconnect.bind(this);
    socket.on("disconnect", this.onDisconnect);

    console.log(`open`);
    socket.open();
  }

  close() {
    console.log(`close`);
    socket.removeListener("connect", this.onConnect);
    socket.removeListener("disconnect", this.onDisconnect);
    socket.close();
  }

  emit(eventName, obj?: any) {
    if (this.user) {
      socket.emit(eventName, { from: this.user.id, ...(obj || {}) });
    }
  }

  on(eventName: string, listener: any) {
    socket.on(eventName, listener);
  }

  removeListener(eventName: string, listener: any) {
    socket.removeListener(eventName, listener);
  }

  private onConnect() {
    console.log(`connect`);
    this.emit("userConnected");
  }
  private onDisconnect() {
    console.log(`disconnect`);
  }
}

export const socketNotifications = new SocketNotifications();
