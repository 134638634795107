import Config from "react-native-config";

function getEnv(env: string, defaults) {
  return process.env[env] || Config[env] || defaults;
}

export default {
  ENV: getEnv("NODE_ENV", "development"),
  API_URL: getEnv("REACT_APP_API_URL", "http://localhost:8080"),
  WEBSITE_URL: getEnv("REACT_APP_WEBSITE_URL", "http://localhost:8080"),
  WHITEBOARD_URL: getEnv("REACT_APP_WHITEBOARD_URL", "http://localhost:9000"),
  UX_CAM_KEY: getEnv("REACT_APP_UX_CAM_KEY", "somekey"),
  API_SIGNATURE: getEnv("REACT_APP_API_SIGNATURE", "somesignature"),
  FIREBASE_ANALYTICS_ENABLED: getEnv(
    "REACT_APP_FIREBASE_ANALYTICS_ENABLED",
    false
  ),
  GETNET_URL: getEnv("GETNET_URL", "https://api-sandbox.getnet.com.br/"),
  GETNET_SELLER_ID: getEnv(
    "GETNET_SELLER_ID",
    "adb99ab2-9277-458d-b345-21debaa0ac8e"
  ),
};
