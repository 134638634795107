import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { analytics } from "../Analytics";
import ButtonElement from "../Elements/ButtonElement";
import Exercise from "../Models/Exercise";
import User from "../Models/User";
import * as Server from "../Server";
import { SCREEN_SIZE_600 } from "../Utils/SizeUtils";
import StyleUtils from "../Utils/StyleUtils";
import AssignHomeworkConfirmation from "./AssignHomeworkConfirmation";
import AssignHomeworkFinalize from "./AssignHomeworkFinalize";
import AssignHomeworkHeader from "./AssignHomeworkHeader";
import AssignHomeworkHeaderBack from "./AssignHomeworkHeaderBack";
import AssignHomeworkList from "./AssignHomeworkList";
import AssignHomeworkUsers from "./AssignHomeworkUsers";

type Props = {
  history;
  user: User;
  classInfo?;
  style?;
  handleBackButton?;
};

type State = {
  mobile?: boolean;
  exercises: Exercise[];
  selectedExercises: Record<string, any>;
  page: "exerciseSelection" | "userSelection" | "finalize";
  loading?: boolean;
  selectedUser?: User;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

class AssignHomework extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      exercises: [],
      selectedExercises: props.selectedExercises || {},
      page: props.page || "exerciseSelection",
      loading: true,
    };
  }

  componentDidMount() {
    Server.getExercises(this.props.user.id)
      .then((response) => {
        if (response && response.exercises) {
          this.setState({ exercises: response.exercises });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const CurrentPage = this.pages[this.state.page];
    return (
      <View
        style={
          this.props.style ? this.props.style : [StyleUtils.screen_default]
        }
        onLayout={this.onLayout}
      >
        <CurrentPage />
      </View>
    );
  }

  pages = {
    exerciseSelection: () => {
      return (
        <View style={{ height: "100%", width: "100%" }}>
          <AssignHomeworkHeader />
          <AssignHomeworkList
            list={this.state.exercises}
            style={{ flex: 1 }}
            hasExercise={(exercise) =>
              (exercise.id &&
                this.state.selectedExercises.hasOwnProperty(exercise.id)) ||
              false
            }
            onExercisePress={(exercise) => {
              if (exercise.id) {
                let s: Record<string, any> = {
                  ...this.state.selectedExercises,
                };
                if (s.hasOwnProperty(exercise.id)) {
                  delete s[exercise.id];
                } else {
                  s[exercise.id] = exercise;
                }
                this.setState({ selectedExercises: s });
              }
              console.log(this.state.selectedExercises);
            }}
          />
          <AssignHomeworkButton
            numberOfExercises={
              Object.entries(this.state.selectedExercises).length
            }
            onPress={() => {
              if (this.props.classInfo && this.props.classInfo.selectedUser) {
                this.setState({
                  selectedUser: this.props.classInfo.selectedUser,
                });
                this.setState({ page: "userSelection" });
              } else {
                this.setState({ selectedUser: undefined });
                this.setState({ page: "userSelection" });
              }
            }}
          />
        </View>
      );
    },
    userSelection: () => {
      return (
        <View style={{ height: "100%", width: "100%" }}>
          <AssignHomeworkHeaderBack
            mobile={this.state.mobile}
            style={!this.state.mobile && { width: "90%" }}
            onPress={() => {
              if (this.props.handleBackButton) {
                this.props.handleBackButton();
              } else {
                this.setState({
                  selectedUser: undefined,
                });

                this.setState({
                  page: "exerciseSelection",
                });
              }
            }}
          />
          <AssignHomeworkUsers
            mobile={this.state.mobile}
            onUserSelected={(user) => this.setState({ selectedUser: user })}
            selectedUser={this.state.selectedUser}
          >
            {this.state.selectedUser && (
              <AssignHomeworkConfirmation
                user={this.state.selectedUser}
                onPress={(exerciseTitle) => {
                  if (this.state.selectedUser) {
                    if (
                      this.props.classInfo &&
                      this.props.classInfo.existingHomework
                    ) {
                      Server.putHomeworkExercises(
                        this.props.user.id,
                        this.state.selectedUser.id,
                        this.props.classInfo && this.props.classInfo.id
                          ? this.props.classInfo.id
                          : null,
                        Object.values(this.state.selectedExercises)
                      )
                        .then(() => this.setState({ page: "finalize" }))
                        .catch();
                    } else {
                      analytics.lessonSubmitted();
                      Server.postHomework(
                        this.props.classInfo && this.props.classInfo.id
                          ? this.props.classInfo.id
                          : null,
                        this.props.user.id,
                        this.state.selectedUser.id,
                        exerciseTitle,
                        Object.values(this.state.selectedExercises)
                      )
                        .then(() => this.setState({ page: "finalize" }))
                        .catch(() => console.log("ERROR EM ENVIAR"));
                    }
                  }
                }}
              />
            )}
          </AssignHomeworkUsers>
        </View>
      );
    },
    finalize: () => {
      return (
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <AssignHomeworkFinalize
            onPress={() => {
              if (this.props.classInfo && this.props.classInfo.finishCallback) {
                this.props.classInfo.finishCallback();
              } else this.props.history.push("/");
            }}
          />
        </View>
      );
    },
  };

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let isMobileLayout = layout.width < SCREEN_SIZE_600;
    if (isMobileLayout != this.state.mobile) {
      this.setState({
        mobile: isMobileLayout,
      });
    }
  };
}

const AssignHomeworkButton = (props: {
  onPress?: () => void;
  numberOfExercises: number;
}) => {
  return (
    <ButtonElement
      text={
        props.numberOfExercises > 1
          ? `Enviar (${props.numberOfExercises}) Exercícios`
          : "Enviar Exercício"
      }
      styte={{
        marginHorizontal: 32,
        marginVertical: 24,
        alignSelf: "stretch",
      }}
      disabled={props.numberOfExercises <= 0}
      onPress={props.onPress}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignHomework);
