import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "../../assets/closeIconSmall.svg";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import TutoreFutureLogo from "../../assets/tutoreFutureLogo.svg";
import CheckWhite from "../../assets/checkIcon.svg";
import BuyCreditPopup from "../Popups/BuyCreditPopup";
import { analytics } from "../Analytics";
import { useEffect } from "react";

type Props = {
  history;
};

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

function TutoreFuturePaywall(props: Props) {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    analytics.sendShowPremiumPaywall();
  }, []);

  const divider = () => (
    <View
      style={{
        borderColor: "#FFFFFF",
        borderWidth: 1,
        width: "100%",
        opacity: 0.25,
        marginVertical: 12,
      }}
    />
  );

  return (
    <LinearGradient
      colors={["#12222E", "#004D85"]}
      style={[
        StyleUtils.color_bg_light,
        StyleUtils.shape_btn_default,
        {
          flex: 1,
          marginTop: Platform.OS === "ios" ? -50 : -10,
          marginBottom: -10,
        },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          margin: 16,
          marginTop: Platform.OS === "ios" ? 60 : 40,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            analytics.sendClosePremiumPaywall();
            props.history.push("/home");
          }}
        >
          <SVGImage source={<BackButton />} />
        </TouchableOpacity>
        <SVGImage
          source={<TutoreFutureLogo />}
          style={{ position: "absolute", left: 110, top: 4 }}
        />
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-evenly",
          marginTop: 32,
          marginHorizontal: 24,
        }}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_18,
            {
              textAlign: "center",
              marginTop: -24,
            },
          ]}
        >
          Seja um estudante
        </Text>
        <View
          style={{
            alignSelf: "center",
            width: 243,
            borderWidth: 1,
            borderRadius: 40,
            paddingVertical: 8,
            borderColor: "#D8BE8B",
            marginBottom: 24,
            marginTop: 8,
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_28,
              {
                marginBottom: -4,
                textAlign: "center",
              },
            ]}
          >
            P R E M I U M
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginLeft: 16 }}>
          <SVGImage
            source={<CheckWhite />}
            style={{
              marginTop: Platform.OS === "ios" ? -4 : 0,
              marginRight: 4,
            }}
          />
          <Text style={styles.textWhite}>
            <B>Orientação Vocacional com</B>
            {"\n"}um Mentor exclusivo; 2 sessões{"\n"}por mês inclusas
          </Text>
        </View>
        {divider()}
        <View style={{ flexDirection: "row", marginLeft: 16 }}>
          <SVGImage
            source={<CheckWhite />}
            style={{
              marginTop: Platform.OS === "ios" ? -4 : 0,
              marginRight: 4,
            }}
          />
          <Text style={styles.textWhite}>
            <B>Planejamento Personalizado</B>
            {"\n"}para garantir que você vai{"\n"}alcançar seus sonhos
          </Text>
        </View>
        {divider()}
        <View style={{ flexDirection: "row", marginLeft: 16 }}>
          <SVGImage
            source={<CheckWhite />}
            style={{
              marginTop: Platform.OS === "ios" ? -4 : 0,
              marginRight: 4,
            }}
          />
          <Text style={styles.textWhite}>
            <B>10% de desconto em todo o app</B>
            {"\n"}para suas aulas e sessões com{"\n"}especialistas
          </Text>
        </View>
        <Text style={styles.textPrice}>
          Apenas R$49,90/mês{"\n"}Cancele quando quiser
        </Text>
        <LinearGradient
          colors={["#D3B987", "#EBE1CF"]}
          style={{
            borderRadius: 6,
            marginBottom: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              analytics.sendPremiumButton();
              setModalVisible(true);
            }}
          >
            <Text
              style={[
                {
                  textAlign: "center",
                  paddingVertical: 14,
                  color: "#000000",
                },
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_20,
              ]}
            >
              Quero ser Premium
            </Text>
          </TouchableOpacity>
        </LinearGradient>
      </View>
      <BuyCreditPopup
        close={() => setModalVisible(false)}
        show={modalVisible}
        header={"Vai ser incrível ter você aqui no Tutore Future."}
        subHeader={
          "No momento, a assinatura é\nrealizada através do Whatsapp.\nClique no botão agora!"
        }
        buttonText={"Assinar Agora"}
        whatsappLink={
          "https://api.whatsapp.com/send?phone=5511968487979&text=Ol%C3%A1!%20Gostaria%20de%20me%20tornar%20um%20assinante%20do%20Tutore%20Future!"
        }
      />
    </LinearGradient>
  );
}

export default connect(null, null)(TutoreFuturePaywall);

const styles = StyleSheet.create({
  textWhite: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  textPrice: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    marginTop: 32,
    textAlign: "center",
    marginBottom: 16,
  },
});
