import React, { useState } from "react";
import {
  Platform,
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { connect } from "react-redux";
import DotWhite from "../../assets/dotWhite.svg";
import StarYellow from "../../assets/starYellow.svg";
import { ChatIcon, LikeIcon } from "../Elements/IconElements";
import { UserImageBackGround } from "../Elements/UserImages";
import { Tutor } from "../Models/TutorInfo";
import StyleUtils, {
  COLOR_SUCCESS,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import { formatCurrency, userShortName } from "../Utils/Utils";
import { DiscountBadge } from "../Payment/DiscountBadge";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import GradientBackground from "../../assets/gradientBackground.svg";
import FirstSessionFreeBanner from "../../assets/firstSessionFreeBanner.svg";
import FirstSessionFreeBannerBig from "../../assets/greenRibbon.svg";
import { useEffect } from "react";
import { getUserCredit, getEvents, EventsFilter } from "../Server";

type Props = {
  tutor: Tutor;
  style?: StyleProp<ViewStyle>;
  onSelectTutor?: (tutor: Tutor) => void;
  onChatPress?: () => void;
  onLikePress?: (favorite: boolean) => void;
  userID?: string;
  fromDynamicLink;
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
    fromDynamicLink: state.userRed.fromDynamicLink,
  };
}

function SuperTutorCard(props: Props) {
  const { tutor } = props;
  const online = false;
  const favorite = !!(
    props.userID &&
    props.tutor.tutorInfo &&
    props.tutor.tutorInfo.tutees &&
    props.tutor.tutorInfo.tutees[props.userID] &&
    props.tutor.tutorInfo.tutees[props.userID].favorite
  );
  const [priceShowABTest, setPriceShowABTest] = useState(0);
  const [firstClassFree, setFirstClassFree] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pricePackageShow, setPricePackageShow] = useState(false);

  useEffect(() => {
    RemoteConfigBridge.remoteConfig("pricePackageShow", (value) => {
      var valueBool = value == "1";
      setPricePackageShow(valueBool);
    });

    RemoteConfigBridge.remoteConfig("priceShow", (value) => {
      setPriceShowABTest(parseInt(value));
    });

    RemoteConfigBridge.remoteConfig("firstClassFree", (value) => {
      var valueBool = value == "1";
      if (valueBool && !props.fromDynamicLink) {
        if (props.userID) {
          getUserCredit(props.userID).then((res) => {
            if (res.amount === 0) {
              var eventFilter: EventsFilter = {
                tutorId: undefined,
                tuteeId: props.userID,
                periodStart: undefined,
                periodEnd: undefined,
                limit: 1,
              };
              getEvents(eventFilter).then((result: any) => {
                if (result && result.length === 0) {
                  setFirstClassFree(true);
                }
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          setFirstClassFree(true);
        }
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <TouchableOpacity
      onPress={() => props.onSelectTutor && props.onSelectTutor(tutor)}
      delayPressIn={100}
      style={[
        {
          borderRadius: 14,
          overflow: "hidden",
          width: 279,
        },
        props.style,
      ]}
    >
      <View
        style={{
          height: 324,
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <UserImageBackGround
          imageURL={tutor.imageURL}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        ></UserImageBackGround>
        <SVGImage
          source={<GradientBackground />}
          style={{
            zIndex: 0,
            position: "absolute",
            marginTop: 308,
          }}
        />
        <ChatIcon
          onPress={props.onChatPress}
          height={24}
          width={24}
          style={[
            StyleUtils.color_bg_true_white,
            {
              height: 32,
              width: 32,
              borderRadius: 16,
              overflow: "hidden",
              position: "absolute",
              top: 12,
              left: 195,
            },
          ]}
        />
        <LikeIcon
          onPress={() => props.onLikePress && props.onLikePress(!favorite)}
          selected={favorite}
          height={24}
          width={24}
          style={[
            StyleUtils.color_bg_true_white,
            {
              height: 32,
              width: 32,
              borderRadius: 16,
              overflow: "hidden",
              position: "absolute",
              top: 12,
              left: 235,
            },
          ]}
        />
        <View style={{ padding: 16, paddingTop: 24, alignSelf: "stretch" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_size_22,
                StyleUtils.font_btn_bold,
              ]}
            >
              {userShortName(tutor.name)}
            </Text>
            {online && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginLeft: 8,
                }}
              >
                <View
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 5,
                    overflow: "hidden",
                    backgroundColor: COLOR_SUCCESS,
                    marginRight: 4,
                  }}
                />
                <Text
                  style={[
                    StyleUtils.color_txt_white,
                    StyleUtils.font_size_12,
                    StyleUtils.font_btn_regular,
                    { textAlignVertical: "bottom", fontWeight: "500" },
                  ]}
                >
                  Online
                </Text>
              </View>
            )}
          </View>

          {tutor.rating && tutor.rating.count <= 0 && (
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_size_14,
                StyleUtils.font_btn_regular,
              ]}
            >
              {tutor.rating && tutor.rating.eventsCompleted > 0
                ? `${tutor.rating.eventsCompleted} Aulas`
                : ""}
            </Text>
          )}
          {tutor.rating && tutor.rating.count > 0 && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <SVGImage
                style={{
                  width: 14,
                  height: 12,
                  alignSelf: "flex-start",
                }}
                source={<StarYellow width={14} height={12} />}
              />
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_14,
                  StyleUtils.font_btn_regular,
                ]}
              >
                {` ${tutor.rating.average.toFixed(1)} `}
              </Text>
              <SVGImage
                source={<DotWhite />}
                style={{
                  paddingRight: 4,
                  paddingBottom: 4,
                }}
              />
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_14,
                  StyleUtils.font_btn_regular,
                ]}
              >
                {`${tutor.rating.count} avaliações `}
              </Text>
              <SVGImage
                source={<DotWhite />}
                style={{
                  paddingRight: 4,
                  paddingBottom: 4,
                }}
              />
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_14,
                  StyleUtils.font_btn_regular,
                ]}
              >
                {tutor.rating && tutor.rating.eventsCompleted > 0
                  ? `${tutor.rating.eventsCompleted} Aulas`
                  : ""}
              </Text>
            </View>
          )}
          {(priceShowABTest === 0 || pricePackageShow) && (
            <View
              style={[
                StyleUtils.color_bg_white_transparency,
                {
                  borderRadius: 6,
                  opacity: 0.8,
                  paddingHorizontal: 8,
                  paddingVertical: 2,
                  marginTop: 8,
                  alignSelf: "flex-start",
                },
                Platform.OS == "ios" && { paddingTop: 4 },
              ]}
            >
              <Text
                style={[
                  StyleUtils.font_size_16,
                  StyleUtils.font_btn_regular,
                  StyleUtils.color_txt_dark,
                  { textAlignVertical: "center" },
                ]}
              >
                {tutor.tutorInfo &&
                tutor.tutorInfo.hourPrice &&
                tutor.tutorInfo.hourPrice > 0
                  ? `${formatCurrency(
                      tutor.tutorInfo.currencyCode,
                      tutor.tutorInfo.hourPrice,
                      0
                    )}/aula`
                  : "Aula Grátis"}
              </Text>
            </View>
          )}
          {priceShowABTest === 1 && !pricePackageShow && (
            <DiscountBadge style={[]} discount={50} />
          )}
          {priceShowABTest === 3 && !pricePackageShow && (
            <View
              style={[
                {
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                },
              ]}
            >
              <View
                style={[
                  StyleUtils.color_bg_white_transparency,
                  {
                    borderRadius: 6,
                    opacity: 0.8,
                    paddingHorizontal: 8,
                    paddingVertical: 1,

                    alignSelf: "flex-start",
                    display: "flex",
                    flexDirection: "row",
                  },
                  Platform.OS == "ios" && { paddingTop: 4 },
                ]}
              >
                <Text
                  style={[
                    StyleUtils.font_size_14,
                    StyleUtils.font_btn_regular,
                    StyleUtils.color_txt_dark,
                    {
                      textAlignVertical: "center",
                      textDecorationLine: props.tutor.tutorInfo
                        ?.firstSessionFreeDuration
                        ? "line-through"
                        : "none",
                      textDecorationStyle: "solid",
                    },
                  ]}
                >
                  {tutor.tutorInfo &&
                    tutor.tutorInfo.creditPrice &&
                    tutor.tutorInfo.creditPrice}
                  {tutor.tutorInfo &&
                  tutor.tutorInfo.creditPrice &&
                  tutor.tutorInfo.creditPrice === 1
                    ? " crédito/aula"
                    : " créditos/aula"}
                </Text>
              </View>
            </View>
          )}
          {props.tutor.tutorInfo?.firstSessionFreeDuration &&
            !firstClassFree &&
            !loading && (
              <View
                style={{
                  position: "absolute",
                  justifyContent: "center",
                  right: 10,
                  top: 75,
                }}
              >
                <SVGImage source={<FirstSessionFreeBanner />}></SVGImage>
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.font_size_12,
                    {
                      color: COLOR_TRUE_WHITE,
                      left: 20,
                      position: "absolute",
                      top: Platform.OS === "ios" ? 5 : 0,
                    },
                  ]}
                >
                  {`${tutor.tutorInfo!.firstSessionFreeDuration}min Grátis`}
                </Text>
              </View>
            )}
          {firstClassFree && (
            <View
              style={{
                position: "absolute",
                justifyContent: "center",
                right: 16,
                bottom: 16,
              }}
            >
              <SVGImage source={<FirstSessionFreeBannerBig />}></SVGImage>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_12,
                  {
                    color: COLOR_TRUE_WHITE,
                    left: 8,
                    position: "absolute",
                    paddingTop: Platform.OS === "ios" ? 2 : 0,
                  },
                ]}
              >
                1a Aula Grátis
              </Text>
            </View>
          )}
        </View>
      </View>

      <View
        style={[
          StyleUtils.color_bg_text_dark,
          { padding: 16, minHeight: 116, opacity: 1 },
        ]}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_size_16,
            StyleUtils.font_btn_bold,
          ]}
        >
          {tutor.tutorInfo && tutor.tutorInfo.tagLine
            ? tutor.tutorInfo.tagLine
            : "Vamos estudar?"}
        </Text>
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_size_14,
            StyleUtils.font_btn_regular,
            { marginTop: 6 },
          ]}
        >
          {tutor.tutorInfo && tutor.tutorInfo.shortDescription}
        </Text>
      </View>
    </TouchableOpacity>
  );
}
export default connect(mapStateToProps, null)(SuperTutorCard);
