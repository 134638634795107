import React, { Component } from "react";
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import AgeTemplate from "./AgeTemplate";
import BinaryTemplate from "./BinaryTemplate";
import ManyMultipleChoiceTemplate from "./ManyMultipleChoiceTemplate";
import OneLineTemplate from "./OneLineTemplate";
import OneMultipleChoiceTemplate from "./OneMultipleChoiceTemplate";
import OneRowTemplate from "./OneRowTemplate";
import TextboxTemplate from "./TextboxTemplate";
import ZeroToFiveTemplate from "./ZeroToFiveTemplate";
import ManyRowTemplate from "./ManyRowTemplate";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import StyleUtils, { COLOR_DANGER } from "../Utils/StyleUtils";
import ButtonElement from "../Elements/ButtonElement";
import { updateUser } from "../Server";
import { analytics } from "../Analytics";
import { setViewedSurvey } from "../Actions";

type Props = {
  location;
  history;
  user;
  dispatch;
};

type State = {
  componentStack;
  backHandler;
  translateX;
  isTransitioning;
  surveyQuestions;
  showPopup;
  index;
};

const { width } = Dimensions.get("window");

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}
const dispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

class TFOnboarding extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);
    this.state = {
      componentStack: [],
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      translateX: new Animated.Value(0),
      isTransitioning: false,
      surveyQuestions: [],
      showPopup: false,
      index: 0,
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isGuardian
    ) {
      RemoteConfigBridge.remoteConfig("parentSurveyQuestions", (value) => {
        const obj = JSON.parse(value);
        this.setState({ surveyQuestions: obj }, () => {
          this.continueNext(0);
        });
      });
    } else {
      RemoteConfigBridge.remoteConfig("studentSurveyQuestions", (value) => {
        const obj = JSON.parse(value);
        this.setState({ surveyQuestions: obj }, () => {
          this.continueNext(0);
        });
      });
    }
  }

  componentWillUnmount() {
    this.state.backHandler.remove();
  }

  backPress = () => {
    if (this.state.componentStack.length > 1) {
      this.safelyPopComponent();
      return true;
    }
    return false;
  };

  executeWithTransition(method, back?: boolean, useFade?: boolean) {
    this.setState({ isTransitioning: true });
    if (useFade) {
      Animated.timing(this.fadeAnim, {
        toValue: 0,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        method();
        Animated.timing(this.fadeAnim, {
          toValue: 1,
          duration: 400,
          easing: Easing.cubic,
          useNativeDriver: true,
        }).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    } else {
      Animated.sequence([
        Animated.timing(this.state.translateX, {
          toValue: back ? -1 * width : width,
          duration: 1,
          useNativeDriver: true,
          delay: 400,
        }),
      ]).start(() => {
        method();
        Animated.sequence([
          Animated.timing(this.state.translateX, {
            toValue: 0,
            duration: 250,
            useNativeDriver: true,
          }),
        ]).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    }
  }

  pushComponent(component, useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack.concat(component);
        this.setState({
          componentStack: tempComponents,
        });
      },
      false,
      useFade
    );
  }

  safelyPopComponent(useFade?: boolean) {
    if (this.state.isTransitioning) return;
    if (this.state.index === 0) {
      this.setState({ showPopup: true });
    } else {
      this.setState({ index: this.state.index - 1 });
      this.popComponent(useFade);
    }
  }

  popComponent(useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack;
        tempComponents.pop();
        this.setState({ componentStack: tempComponents });
      },
      true,
      useFade
    );
  }

  getCurrentComponent() {
    return (
      <Animated.View
        style={{
          flex: 1,
          opacity: this.fadeAnim,
          transform: [{ translateX: this.state.translateX }],
        }}
      >
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  continueNext = (index) => {
    if (this.state.isTransitioning) return;
    const templateID = this.state.surveyQuestions[index].templateID;
    let progress =
      Math.round((index / this.state.surveyQuestions.length) * 100).toString() +
      "%";
    this.setState({ index: index });
    switch (templateID) {
      case 0:
        this.pushComponent(
          <AgeTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
          />
        );
        break;
      case 1:
        this.pushComponent(
          <ZeroToFiveTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            question={this.state.surveyQuestions[index].question}
            progress={progress}
          />
        );
        break;
      case 2:
        this.pushComponent(
          <TextboxTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
          />
        );
        break;
      case 3:
        this.pushComponent(
          <OneLineTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
            placeholder={this.state.surveyQuestions[index].placeholder}
          />
        );
        break;
      case 4:
        this.pushComponent(
          <BinaryTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
            areas={this.state.surveyQuestions[index].areas}
          />
        );
        break;
      case 5:
        this.pushComponent(
          <OneMultipleChoiceTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
            areas={this.state.surveyQuestions[index].areas}
          />
        );
        break;
      case 6:
        this.pushComponent(
          <ManyMultipleChoiceTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
            areas={this.state.surveyQuestions[index].areas}
          />
        );
        break;
      case 7:
        this.pushComponent(
          <OneRowTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
            areas={this.state.surveyQuestions[index].areas}
          />
        );
        break;
      case 8:
        this.pushComponent(
          <ManyRowTemplate
            onContinue={() => {
              if (index === this.state.surveyQuestions.length - 1) {
                this.finishForm();
              } else {
                this.continueNext(index + 1);
              }
            }}
            onBack={() => this.safelyPopComponent()}
            progress={progress}
            question={this.state.surveyQuestions[index].question}
            areas={this.state.surveyQuestions[index].areas}
          />
        );
        break;
    }
  };

  finishForm() {
    if (this.state.isTransitioning) return;
    if (this.props.user && this.props.user.id) {
      updateUser(this.props.user.id, undefined, undefined, true);
    }
    this.props.dispatch(setViewedSurvey());
    this.props.history.push({
      pathname: "/endform",
      state: {
        onboarding:
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.onboarding,
        isGuardian:
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.isGuardian,
      },
    });
  }

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        {this.getCurrentComponent()}
        <StopFormPopup
          show={this.state.showPopup}
          close={() => {
            this.setState({
              showPopup: false,
            });
          }}
          onContinue={() => {
            analytics.sendContinueSurvey();
            this.setState({
              showPopup: false,
            });
          }}
          onStop={() => {
            analytics.sendStopSurvey();
            this.props.history.goBack();
          }}
        />
      </View>
    );
  }
}

export default connect(mapStateToProps, dispatchToProps)(TFOnboarding);

const style = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});

const StopFormPopup = ({ show, close, onContinue, onStop }) => {
  return (
    <AnimatedPopUp show={show} close={close} bgColor={COLOR_DANGER}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_white,
        ]}
      >
        Deseja sair da pesquisa?{"\n"}Você irá perder uma aula grátis
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_white,
        ]}
      >
        Continue respondendo nossa{"\n"}pesquisa para ganhar uma aula grátis
      </Text>
      <ButtonElement
        colorScheme="danger_white"
        text="Continuar Respondendo"
        onPress={onContinue}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
      <TouchableOpacity
        style={[{ width: 252, marginTop: 24 }]}
        onPress={onStop}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            { textAlign: "center", textDecorationLine: "underline" },
          ]}
        >
          Encerrar Pesquisa
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};
