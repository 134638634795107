import React, { useState } from "react";
import {
  TouchableOpacity,
  Text,
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import StyleUtils from "../Utils/StyleUtils";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import ButtonElement from "../Elements/ButtonElement";
import { userCardRemove } from "../Server";

type Props = {
  lastDigits?: string;
  visible?: boolean;
};

const CreditCardDeletePopup = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const card = useSelector((state) => state.paymentRed);
  const dispatch = useDispatch();

  const cancelRemove = () => {
    dispatch({ type: "REMOVE_CANCEL" });
  };

  const confirmRemove = () => {
    setLoading(true);
    userCardRemove(card.cardToRemove)
      .then(() => {
        setTimeout(() => {
          dispatch({ type: "CONFIRM_DELETE" });
          dispatch({ type: "CLEAN" });
          setLoading(false);
        }, 600);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AnimatedPopUp show={props.visible}>
      <View style={styles.container}>
        <Text
          style={[
            StyleUtils.font_size_18,
            StyleUtils.color_txt_dark,
            StyleUtils.font_cera_bold,
            styles.cardTitle,
          ]}
        >
          Remover o cartão {"\n"} com final {props.lastDigits}
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.color_txt_grey_light,
            styles.cardComments,
          ]}
        >
          Se preferir, você pode adicionar esse cartão novamente no futuro.
        </Text>
        <TouchableOpacity style={styles.deleteConfirm} onPress={confirmRemove}>
          {!isLoading ? (
            <Text
              style={[
                StyleUtils.font_cera_regular,
                StyleUtils.font_size_16,
                StyleUtils.color_txt_white,
              ]}
            >
              Remover
            </Text>
          ) : (
            <ActivityIndicator color="#fff" />
          )}
        </TouchableOpacity>
        <ButtonElement
          styte={[{ margin: 0, marginTop: 0, alignSelf: "stretch" }]}
          textStyle={[{ textDecorationLine: "underline" }]}
          colorScheme={"danger_white"}
          text={"voltar"}
          onPress={cancelRemove}
        />
      </View>
    </AnimatedPopUp>
  );
};

export default CreditCardDeletePopup;

const styles = StyleSheet.create({
  container: {
    width: "80%",
  },
  cardTitle: {
    textAlign: "center",
  },
  cardComments: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 30,
  },
  deleteConfirm: {
    backgroundColor: "rgba(255, 47, 92, 1)",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 8,
  },
  btnBack: {
    textDecorationLine: "underline",
  },
});
