import React, { Component } from "react";
import {
  ActivityIndicator,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { connect } from "react-redux";
import { selectEvent, selectUser, setNoLoginUser } from "../Actions";
import StyleUtils from "../Utils/StyleUtils";
import { tuteeLobbyConnection } from "./QuickClassConnection";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

type Props = {
  selectUser;
  selectEvent;
  setNoLoginUser;
  history;
  selectedTutor;
};

type State = {
  message;
};
function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectUser: (selectedUser) => dispatch(selectUser(selectedUser)),
    selectEvent: (selectedEvent) => dispatch(selectEvent(selectedEvent)),
    setNoLoginUser: (user) => dispatch(setNoLoginUser(user)),
  };
}

class QuickClassLobby extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      message: "Estamos conectando você com um orientador",
    };
  }

  componentDidMount() {
    RemoteConfigBridge.remoteConfig("parentsWaitTime", (value) => {
      setTimeout(() => {
        this.props.history.push({
          pathname: "/TutorProfile",
          state: { parentsOnboarding: true, noContact: true },
        });
      }, parseInt(value) * 1000);
    });
    tuteeLobbyConnection.open(this.props.selectedTutor.tutorInfo.id);
    tuteeLobbyConnection.on("joinQuickClass", this.joinQuickClass);

    //change text
    setTimeout(() => {
      this.setState({
        message: "Verificando disponibilidade do orientador...",
      });
    }, 3000);
    setTimeout(() => {
      this.setState({ message: "Entrando em contato com o orientador..." });
    }, 8000);
    setTimeout(() => {
      this.setState({ message: "Aguardando confirmação do orientador..." });
    }, 15000);
    setTimeout(() => {
      this.setState({
        message: "Desculpe pela demora, por favor aguarde mais um pouco...",
      });
    }, 25000);
  }

  componentWillUnmount() {
    tuteeLobbyConnection.removeListener("joinQuickClass", this.joinQuickClass);
    tuteeLobbyConnection.close();
  }

  private joinQuickClass = (info) => {
    this.props.selectUser(info.userInfo);
    this.props.selectEvent(info.selectedEvent);
    this.props.setNoLoginUser({ id: info.clientId });
    this.props.history.push("/classroom");
  };

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        <View
          style={[
            StyleUtils.color_bg_text_dark,
            {
              position: "absolute",
              bottom: 0,
              top: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
            },
          ]}
        >
          <ActivityIndicator size={"large"} color={"white"} />
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_20,
              {
                marginLeft: 36,
                marginRight: 36,
                marginTop: 16,
                textAlign: "center",
              },
            ]}
          >
            {this.state.message}
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_normal,
              { marginTop: 16, textAlign: "center", marginHorizontal: 36 },
            ]}
          >
            Caso ninguém possa te atender agora, iremos entrar em contato via
            Whatsapp
          </Text>
        </View>
        <TouchableOpacity
          onPress={() =>
            this.props.history.push({
              pathname: "/TutorProfile",
              state: { parentsOnboarding: true, noContact: true },
            })
          }
          style={{
            position: "absolute",
            bottom: 80,
            alignSelf: "center",
          }}
        >
          <Text
            style={[
              styles.modalText,
              {
                textDecorationLine: "underline",
                alignItems: "center",
                textAlign: "center",
              },
            ]}
          >
            Cancelar
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  modalText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    textAlign: "center",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickClassLobby);
