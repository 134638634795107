import React from "react";
import { Component } from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { getEvents, EventsFilter } from "../Server";
import { selectEvent, selectUser } from "../Actions";
import { connect } from "react-redux";
import { dateToHoursString } from "../Utils/Utils";
import { withRouter } from "../Router";

type Props = {
  selectedDate: Date;
  style;
  user;
  history;
  selectEvent;
  selectUser;
  tutorInfo;
};

type State = {
  events;
  loading;
};

function dispatchToProps(dispatch) {
  return {
    selectEvent: (event) => dispatch(selectEvent(event)),
    selectUser: (user) => dispatch(selectUser(user)),
  };
}

function stateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
  };
}

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

class DayEvents extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchDailyEvents();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevProps.selectedDate !== this.props.selectedDate)
      this.fetchDailyEvents();
  }

  fetchDailyEvents = () => {
    if (this.props.user) {
      this.setState({
        loading: true,
      });
      const tomorrow = new Date(this.props.selectedDate);
      tomorrow.setDate(this.props.selectedDate.getDate() + 1);
      tomorrow.setHours(0);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);

      var eventFilter: EventsFilter = {
        tutorId: undefined,
        tuteeId: undefined,
        periodStart: this.props.selectedDate.toISOString(),
        periodEnd: tomorrow.toISOString(),
        limit: undefined,
      };
      if (this.props.user) {
        if (this.props.tutorInfo) {
          eventFilter.tutorId = this.props.user.id;
        } else {
          eventFilter.tuteeId = this.props.user.id;
        }
      }
      getEvents(eventFilter)
        .then((result) => {
          this.setState({
            events: result,
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  getOtherUser = (user1, user2) => {
    if (this.props.user) {
      if (user1.id === this.props.user.id) return user2;
      return user1;
    }
  };

  render() {
    return (
      <View style={[...this.props.style, { alignSelf: "stretch" }]}>
        <Text
          style={[
            StyleUtils.font_btn_bold,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_normal,
            { marginBottom: 16, height: 18 },
          ]}
        >
          {this.props.selectedDate.toLocaleDateString("pt-br", options)}
        </Text>
        <View>
          {this.state.loading && <ActivityIndicator />}
          {!this.state.loading &&
            this.state.events.map((event, index) => {
              const startDate = new Date(event.startDate);
              let subject = "Aula agendada";
              if (event.subject && event.subjectLevel) {
                if (event.subject === "Orientação/Teste Vocacional") {
                  subject = "Aula de " + event.subject;
                } else {
                  subject =
                    "Aula de " + event.subject + " - " + event.subjectLevel;
                }
              }

              return (
                <TouchableOpacity
                  style={[
                    {
                      flexDirection: "row",
                      overflow: "hidden",
                      marginBottom: 8,
                      height: 60,
                    },
                    StyleUtils.shape_btn_default,
                  ]}
                  key={index}
                  onPress={() => {
                    this.props.selectEvent(event);
                    this.props.selectUser(
                      this.getOtherUser(event.tutee, event.tutor)
                    );
                    this.props.history.push("/eventinfo");
                  }}
                >
                  <View
                    style={[
                      StyleUtils.color_bg_primary,
                      { justifyContent: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        { padding: 8 },
                        StyleUtils.color_bg_primary,
                        StyleUtils.color_txt_white,
                        StyleUtils.font_size_14,
                      ]}
                    >
                      {dateToHoursString(new Date(event.startDate))}
                    </Text>
                  </View>
                  <View
                    style={[
                      { padding: 8, flexGrow: 1 },
                      StyleUtils.color_bg_primarylight,
                    ]}
                  >
                    <Text
                      style={[
                        StyleUtils.color_txt_primary,
                        StyleUtils.font_size_14,
                      ]}
                    >
                      {subject}
                    </Text>
                    <Text
                      style={[
                        StyleUtils.color_txt_normal,
                        StyleUtils.font_size_14,
                      ]}
                    >
                      {this.getOtherUser(event.tutee, event.tutor).name}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })}
          {!this.state.loading && this.state.events.length == 0 && (
            <View
              style={[
                StyleUtils.color_btn_muted,
                StyleUtils.shape_btn_default,
                { padding: 16, height: 46, justifyContent: "center" },
              ]}
            >
              <Text
                style={[StyleUtils.color_txt_normal, { textAlign: "center" }]}
              >
                Sem aulas agendadas
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(DayEvents));
