import { addMinutes } from "date-fns";
const MINUTES_IN_A_HOUR = 60;
const BRASILIA_OFFSET = -3;

export const dateToHoursString = (date: Date) => {
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return hours + ":" + minutes;
};

// Converts date to Brasilia_Time. In the future, if we end up with users from multiple countries, a better solution should be implemented
export function convertToBrasiliaTime(date: Date) {
  const currentTimezone = date.getTimezoneOffset() / MINUTES_IN_A_HOUR;
  const addedTimezoneOffsetHours = BRASILIA_OFFSET + currentTimezone;
  const minutesToAdd = addedTimezoneOffsetHours * MINUTES_IN_A_HOUR;
  const theTimezoneTime = addMinutes(date, minutesToAdd);
  return theTimezoneTime;
}

export function nextDay(date?: Date, numDays = 1): Date {
  let nextDay = date ? new Date(date) : new Date();
  nextDay.setHours(0, 0, 0, 0);
  nextDay.setDate(nextDay.getDate() + numDays);
  return nextDay;
}

export function nextMonth(date?: Date, numMonths = 1): Date {
  let nextMonth = date ? new Date(date) : new Date();
  nextMonth.setHours(0, 0, 0, 0);
  nextMonth.setDate(1);
  nextMonth.setMonth(nextMonth.getMonth() + numMonths);
  return nextMonth;
}

export function nextMonthFromDay(date: Date): Date {
  let nextMonth = date;
  nextMonth.setHours(0, 0, 0, 0);
  nextMonth.setDate(nextMonth.getDate());
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  return nextMonth;
}

export function addDays(date: Date, numDays: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

export function dateFormatHHhmm(date: Date): string {
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return hours + ":" + minutes;
}

export function dateFormatLong(date: Date): string {
  return capitalizeFirstLetter(
    date.toLocaleDateString("pt-br", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    ["de"]
  );
}

export function dateFormatMonthYear(date: Date): string {
  return capitalizeFirstLetter(
    date.toLocaleDateString("pt-br", {
      year: "numeric",
      month: "long",
    }),
    ["de"]
  );
}

export function dateFormatShortMonthYear(date: Date): string {
  return capitalizeFirstLetter(
    date.toLocaleDateString("pt-br", {
      year: "numeric",
      month: "short",
    }),
    [""]
  );
}

export function dateFormatDayMonthYear(date: Date): string {
  return capitalizeFirstLetter(
    date.toLocaleDateString("pt-br", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
    [""]
  );
}

export function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

export function dateFormatLongDateNoYear(date: Date): string {
  return capitalizeFirstLetter(
    date.toLocaleDateString("pt-br", {
      weekday: "long",
      month: "long",
      day: "numeric",
    }),
    ["de"]
  );
}

function capitalizeFirstLetter(str: string, exclude: string[] = []) {
  let strs = str.split(" ");
  return strs
    .map((s) => {
      if (exclude.includes(s)) {
        return s;
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
    })
    .join(" ");
}

export function dateDiffText(date1: Date, date2: Date): string {
  const diffMinutes = dateDiffMinutes(date1, date2);
  if (diffMinutes == 0) {
    return "Agora";
  }

  const diffHours = dateDiffHours(date1, date2);
  if (diffHours == 0) {
    return `Há ${diffMinutes} minuto` + (diffMinutes > 1 ? "s" : "");
  }

  const diffDays = dateDiffDays(date1, date2);
  if (diffDays == 0) {
    return `Há ${diffHours} hora` + (diffHours > 1 ? "s" : "");
  }

  const diffWeeks = Math.floor(diffDays / 7);
  if (diffWeeks == 0) {
    return `Há ${diffDays} dia` + (diffDays > 1 ? "s" : "");
  }

  const diffYears = Math.floor(diffDays / 365);
  if (diffYears == 0) {
    return `Há ${diffWeeks} semana` + (diffWeeks > 1 ? "s" : "");
  }

  return `Há ${diffYears} ano` + (diffYears > 1 ? "s" : "");
}

export function dateDiffMinutes(date1: Date, date2: Date): number {
  const diffMs = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(diffMs / 60000); // minutes
}

export function dateDiffHours(date1: Date, date2: Date): number {
  const diffMs = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(diffMs / 3600000); // hours
}

export function dateDiffDays(date1: Date, date2: Date): number {
  const diffMs = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(diffMs / 86400000); // days
}

export function formatPositiveInteger(num?: number, digits = 1): string {
  if (num && num < 0) {
    return "" + num;
  }
  let s = num + "";
  const needed = digits - s.length;
  if (needed > 0) {
    s = new Array(needed + 1).join("0") + s;
  }
  return s;
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function userShortName(name?: string) {
  if (!name) {
    return name;
  }
  const names = name.split(" ");
  if (names.length <= 1) {
    return names[0];
  }
  return `${names[0]} ${names[names.length - 1].slice(0, 1)}.`;
}

export function userFirstName(name?: string) {
  if (!name) {
    return "";
  }
  return name.split(" ")[0];
}

export function userLastName(name?: string) {
  if (!name) {
    return "";
  }
  const names = name.split(" ");
  return names[names.length - 1];
}

export function getGooglePhotoWithBetterResolution(
  imageUrl: string,
  resolution: string
) {
  const betterImage = imageUrl.split("=s120")[0];
  return betterImage.concat("=s").concat(resolution);
}

export function createISODatefromBrazilianFormat(dateString) {
  const timeArray = dateString.split("/");
  const datetime = new Date(
    timeArray[2],
    Number(timeArray[1]) - 1,
    timeArray[0]
  );
  return datetime.toISOString();
}

export function formatCurrency(
  currencyCode?: string,
  value?: number,
  digits = 2
) {
  if (!currencyCode || value == undefined) {
    return undefined;
  }
  let s = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: currencyCode,
  }).format(value);

  return digits == 0 ? s.slice(0, -3) : s;
}
