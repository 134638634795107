import React from "react";
import StyleUtils from "../Utils/StyleUtils";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";

type Props = {
  mobile;
  history;
};

const ExercisesEmptyList = ({ mobile, history }) => {
  return mobile ? (
    <View style={!mobile && StyleUtils.desktop_content_width}>
      <Text
        style={[
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_16,
          StyleUtils.font_cera_medium,
          {
            textAlign: "left",
            marginLeft: 24,
            marginVertical: 24,
            textAlignVertical: "bottom",
          },
        ]}
      >
        Você não tem lições pendentes.
      </Text>
      <Text
        style={[
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_16,
          StyleUtils.font_cera_medium,
          { textAlign: "left", marginLeft: 24, textAlignVertical: "bottom" },
        ]}
      >
        {"Marque uma aula para que seu professor\nte envie uma nova lição!"}
      </Text>
      <TouchableOpacity
        style={[
          StyleUtils.shape_btn_small,
          StyleUtils.color_bg_primary,
          { margin: 32 },
        ]}
        onPress={() => {
          history.push({
            pathname: "/landing/subjects",
            state: {
              showFooter: true,
            },
          });
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_white,
            StyleUtils.font_size_16,
            { padding: 14, textAlign: "center" },
          ]}
        >
          Encontrar Professor
        </Text>
      </TouchableOpacity>
    </View>
  ) : (
    <View style={StyleUtils.desktop_content_width}>
      <Text
        style={[
          StyleUtils.font_size_16,
          StyleUtils.color_txt_light,
          { marginLeft: "10%", marginRight: "10%" },
        ]}
      >
        Você não enviou nenhuma lição a seus alunos.
      </Text>
    </View>
  );
};

const style = StyleSheet.create({
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});

export default ExercisesEmptyList;
