import React, { useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
  StyleSheet,
} from "react-native";
import StyleUtils, { COLOR_WHITE, COLOR_PRIMARY } from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import BackArrow from "../../../assets/backbutton.svg";
import Grafismo from "../../../assets/grafismo.svg";
import StarYellow from "../../../assets/starYellow.svg";
import { UserImage } from "../../Elements/UserImages";
import Placeholder from "../../../assets/dotWhite.svg";
import Timer from "../../Elements/Timer";
import { userShortName } from "../../Utils/Utils";

type Props = {
  onContinue;
  onBack;
  history;
  tutor: {
    name: string;
    imageURL: string;
    rating: {
      average: number;
      count: number;
    };
  };
};

export default function TFTutorMatch(props: Props) {
  const [time, setTime] = useState((15 * 60).toString());
  return (
    <LinearGradient
      colors={["#272B3E", "#005EC8"]}
      style={[StyleUtils.screen_default]}
    >
      <TouchableOpacity
        style={{
          marginLeft: 24,
          marginTop: Platform.OS === "ios" ? 80 : 40,
          zIndex: 1,
        }}
        onPress={() => {
          props.onBack();
        }}
      >
        <SVGImage source={<BackArrow />} />
      </TouchableOpacity>
      <View
        style={{
          flexDirection: "row",
          marginTop: -60,
          justifyContent: "center",
        }}
      >
        <SVGImage source={<Grafismo />} style={{ alignSelf: "center" }} />
      </View>
      <View
        style={{
          borderRadius: 310,
          backgroundColor: COLOR_WHITE,
          left: -124,
          width: 244 + Dimensions.get("window").width,
          height: 750,
          zIndex: 0,
          position: "absolute",
          top: 210,
        }}
      />
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <UserImage
          style={{
            width: 106,
            height: 125,
            borderRadius: 14,
            marginTop: Platform.OS === "ios" ? -140 : -100,
          }}
          imageURL={props.tutor.imageURL}
        />
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_20,
            StyleUtils.color_txt_dark,
            { textAlign: "center" },
          ]}
        >
          {userShortName(props.tutor.name)}
        </Text>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <SVGImage source={<StarYellow />} style={{ left: -5, top: 8 }} />
          <Text
            style={[
              StyleUtils.color_txt_light,
              StyleUtils.font_size_16,
              StyleUtils.font_cera_regular,

              {
                textAlign: "center",
                lineHeight: 25,
              },
            ]}
          >
            {props.tutor.rating.average.toFixed(1)} • {props.tutor.rating.count}{" "}
            Avaliações
          </Text>
        </View>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_22,
            StyleUtils.color_txt_dark,
            { textAlign: "center" },
          ]}
        >
          Converse com um mentor para finalizar seu plano
        </Text>
        <Text
          style={[
            StyleUtils.color_txt_normal,
            StyleUtils.font_size_16,
            StyleUtils.font_cera_regular,

            {
              textAlign: "center",
              lineHeight: 25,
            },
          ]}
        >
          Ele irá te acompanhar até a sua conquista
        </Text>
        <LinearGradient
          colors={["#DCECFF", "#FFFFFF"]}
          style={{
            width: "85%",
            height: 89,
            borderRadius: 6,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_normal,
              { textAlign: "center", marginHorizontal: 32 },
            ]}
          >
            Essa oferta grátis irá expirar em
          </Text>
          <Timer
            initialTime={60 * 15}
            onChange={(time) => {
              setTime((60 * 15 - time).toString());
            }}
            ClockIcon={Placeholder}
            textStyle={styles.clockTextStyle}
            showMinutes={true}
          />
        </LinearGradient>

        <TouchableOpacity
          style={{
            borderRadius: 6,
            backgroundColor: COLOR_PRIMARY,
            width: "85%",
            paddingVertical: 12,
            marginVertical: 16,
          }}
          onPress={() => props.onContinue()}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            Agendar mentoria grátis
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            props.history.push("/");
          }}
          style={{
            marginBottom: 20,
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_dark,
              { textDecorationLine: "underline" },
            ]}
          >
            Deixar para depois
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  clockTextStyle: {
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_normal),
    textAlign: "center",
  },
});
