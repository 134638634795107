import React, { useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import StyleUtils, {
  COLOR_BG_LIGHT,
  COLOR_TRUE_WHITE,
  COLOR_TXT_LIGHT,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import AddIcon from "../../../assets/addIcon.svg";
import Archive from "../../../assets/archive.svg";
import Exercise from "../../../assets/icoToolExercise.svg";
import BackButtonDark from "../../../assets/backButtonDark.svg";
import NewDirectoryPopUp from "./NewDirectoryPopUp";
import { postExerciseDirectory } from "../../Server";

type Props = {
  directories;
  currentDirectory;
  currentDirectorySize;
  onDirectoryPress;
  userId;
  history;
  handleDirectoryBackButtonPress;
  refetchData;
  selectedExercises;
};

const ChildDirectoriesColumn = ({
  selectedExercises,
  directories,
  currentDirectory,
  currentDirectorySize,
  onDirectoryPress,
  userId,
  history,
  handleDirectoryBackButtonPress,
  refetchData,
}: Props) => {
  const handleDirectoryPress = (index) => {
    const dir = directories[index];
    onDirectoryPress(dir.id, dir.name);
  };

  const [openNewDirectoryPopUp, setOpenNewDirectoryPopUp] = useState(false);

  const handleNewFolderSubmit = (name) => {
    return postExerciseDirectory(userId, name, currentDirectory.id);
  };

  return (
    <>
      {openNewDirectoryPopUp && (
        <NewDirectoryPopUp
          closePopUp={() => setOpenNewDirectoryPopUp(false)}
          refetchData={refetchData}
          handleSubmit={handleNewFolderSubmit}
          history={history}
          style={{
            position: "absolute",
            top: -20,
            left: 300,
            zIndex: 200,
            opacity: 1,
          }}
          buttonText={"Criar nova pasta"}
          handleBackButtonpress={() => {
            setOpenNewDirectoryPopUp(false);
          }}
          currentDirectory={currentDirectory}
          userId={userId}
        />
      )}
      <View
        style={[
          StyleUtils.shape_card_default,
          StyleUtils.border_color_light,
          { width: 280, height: 650, position: "relative" },
        ]}
      >
        <View
          style={[
            {
              borderBottomWidth: 1,
              borderColor: COLOR_BG_LIGHT,
              height: 65,
              width: "100%",
              paddingVertical: 21,
              paddingHorizontal: 16,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
        >
          <Text
            style={[StyleUtils.font_cera_bold, StyleUtils.font_size_16, {}]}
          >
            Pastas
          </Text>
          <TouchableOpacity
            onPress={() => {
              setOpenNewDirectoryPopUp((currentState) => !currentState);
            }}
          >
            <SVGImage style={{ width: 32, height: 32 }} source={<AddIcon />} />
          </TouchableOpacity>
        </View>

        <CurrentDirectoryHeader
          currentDirectory={currentDirectory}
          currentDirectorySize={currentDirectorySize}
          handleDirectoryBackButtonPress={handleDirectoryBackButtonPress}
        />

        <ScrollView>
          {directories.map((dir, index) => (
            <DirectoryRow
              dir={dir}
              index={index}
              handleDirectoryPress={handleDirectoryPress}
              selectedExercises={selectedExercises}
            />
          ))}
        </ScrollView>
      </View>
    </>
  );
};

const DirectoryRow = ({
  selectedExercises,
  handleDirectoryPress,
  dir,
  index,
}) => {
  const selectedExerciseNumber = selectedExercises.filter(
    (ex) => ex.directoryId === dir.id
  ).length;
  const showSelectedNumber = selectedExerciseNumber !== 0;
  return (
    <TouchableOpacity
      onPress={() => handleDirectoryPress(index)}
      style={{
        height: 70,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 32,
        paddingRight: 16,
        borderColor: COLOR_BG_LIGHT,
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <SVGImage style={{ width: 40, height: 40 }} source={<Archive />} />
        <Text
          style={[
            StyleUtils.font_size_16,
            StyleUtils.font_cera_bold,
            { marginLeft: 8 },
          ]}
        >
          {dir.name}
        </Text>
      </View>
      {showSelectedNumber && (
        <View
          style={[StyleUtils.color_bg_primary, { width: 25, borderRadius: 30 }]}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              { color: COLOR_TRUE_WHITE, alignSelf: "center" },
            ]}
          >
            {selectedExerciseNumber}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

const CurrentDirectoryHeader = ({
  currentDirectory,
  currentDirectorySize,
  handleDirectoryBackButtonPress,
}) => {
  return (
    <View
      style={{
        height: 70,
        backgroundColor: "#F7F9FD",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        borderColor: COLOR_BG_LIGHT,
        borderBottomWidth: 1,
        paddingLeft: currentDirectory.id ? 4 : 16,
      }}
    >
      {currentDirectory.id && (
        <TouchableOpacity
          onPress={() => handleDirectoryBackButtonPress(currentDirectory)}
        >
          <SVGImage
            source={<BackButtonDark />}
            style={{ width: 30, height: 30 }}
          />
        </TouchableOpacity>
      )}
      <SVGImage
        style={{ width: 40, height: 40 }}
        source={currentDirectory.id ? <Archive /> : <Exercise />}
      />

      <View style={{ marginLeft: 8 }}>
        <Text style={[StyleUtils.font_size_16, StyleUtils.font_cera_bold]}>
          {currentDirectory.id ? currentDirectory.name : "Todos os exercícios"}
        </Text>
        <Text style={[StyleUtils.font_size_14, { color: COLOR_TXT_LIGHT }]}>
          {`${currentDirectorySize} exercício(s)`}
        </Text>
      </View>
    </View>
  );
};
export default ChildDirectoriesColumn;
