import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils, {
  COLOR_BG_LIGHT,
  COLOR_PRIMARY_LIGHT,
  COLOR_WHITE,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import IconDelete from "../../../assets/iconDelete.svg";
import Archive from "../../../assets/archive.svg";
import Pen from "../../../assets/pen.svg";
import MoreDark from "../../../assets/moreDark.svg";
import MorePrimary from "../../../assets/morePrimary.svg";
import MoreOptionsPopUp from "./MoreOptionsPopUp";
import { deleteExercise, updateExercise } from "../../Server";
import { InlineInputEdit } from "react-inline-input-edit";
import Exercise from "../../../assets/exercise.svg";

type Props = {
  file;
  index;
  user;
  history;
  currentDirectory;
  handleExerciseClick;
  isSelected;
  refetchData;
};

const FileRow = ({
  file,
  index,
  user,
  history,
  currentDirectory,
  handleExerciseClick,
  isSelected,
  refetchData,
}: Props) => {
  const [openMoreOptionsPopUp, setOpenMoreOptionsPopUp] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);

  const handleMorePress = () => {
    setOpenMoreOptionsPopUp(true);
  };
  const handleDelete = () => {
    deleteExercise(user.id, file.id).then(() => {
      refetchData(currentDirectory.id);
    });
  };

  const handleExerciseChangeName = (text) => {
    updateExercise(user.id, file.id, text, file.description)
      .then(() => {
        setIsEditingName(false);
        setOpenMoreOptionsPopUp(false);
        refetchData(currentDirectory.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePenPress = () => {
    setIsEditingName(true);
    setOpenMoreOptionsPopUp(false);
  };

  const handleMoveToDirectory = (newParentId) => {
    return updateExercise(
      user.id,
      file.id,
      file.name,
      file.description,
      newParentId
    );
  };

  const zIndex = index * -1;
  const options = [
    { img: Pen, text: "Renomear", handlePress: handlePenPress },
    {
      img: Archive,
      text: "Mover para pasta",
      handlePress: handleMoveToDirectory,
    },
    { img: IconDelete, text: "Excluir", handlePress: handleDelete },
  ];
  const deletePopUpText = {
    header: "Você realmente deseja deletar esse(s) exercício(s)?",
    body: "Você não poderá\nrecuperá-los nunca mais",
  };
  return (
    <View
      style={{
        borderColor: COLOR_BG_LIGHT,
        borderBottomWidth: 1,
        borderTopWidth: index == 0 ? 1 : 0,
        marginHorizontal: isSelected ? 0 : 32,
        paddingHorizontal: isSelected ? 32 : 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: isSelected ? COLOR_PRIMARY_LIGHT : COLOR_WHITE,
        zIndex: zIndex,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: 400,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              handleExerciseClick(file);
            }}
          >
            <SVGImage style={{ paddingRight: 8 }} source={<Exercise />} />
          </TouchableOpacity>

          <Text
            style={[
              StyleUtils.font_size_16,
              {
                minHeight: 22,
              },
            ]}
          >
            {isEditingName ? (
              <InlineInputEdit
                text={file.name}
                inputFontSize={16}
                labelFontSize={16}
                isEditing={true}
                onFocusOut={handleExerciseChangeName}
                inputWidth={300}
              />
            ) : (
              <TouchableOpacity
                onPress={() => {
                  handleExerciseClick(file);
                }}
              >
                <Text
                  style={{
                    minWidth: 300,
                    alignSelf: "center",
                    lineHeight: 50,
                    minHeight: 40,
                  }}
                >
                  {file.name || "(sem nome)"}
                </Text>
              </TouchableOpacity>
            )}
          </Text>
        </View>
      </View>
      <View style={{ position: "relative" }}>
        <TouchableOpacity onPress={handleMorePress}>
          <SVGImage
            style={{ width: 32, height: 32 }}
            source={openMoreOptionsPopUp ? <MorePrimary /> : <MoreDark />}
          />
        </TouchableOpacity>
        {openMoreOptionsPopUp && (
          <MoreOptionsPopUp
            refetchData={refetchData}
            headerText={file.name}
            user={user}
            options={options}
            style={{ zIndex: 999, right: 40, top: 0 }}
            closePopUp={() => {
              setOpenMoreOptionsPopUp(false);
            }}
            file={file}
            directory={currentDirectory}
            history={history}
            deletePopUpText={deletePopUpText}
          />
        )}
      </View>
    </View>
  );
};

export default FileRow;
