import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  areas;
  onContinue;
  onBack;
  headerText;
  getInitialGoals: () => string[];
};

type State = {
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
  otherGoals;
};

export default class ChooseGoals extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialGoals = props.getInitialGoals();
    let otherGoals = "";
    for (const goal of initialGoals) {
      if (!props.areas.filter((area) => area.name === goal).length) {
        otherGoals = goal;
        break;
      }
    }
    this.state = {
      selectedZero: initialGoals.filter((goal) => goal === props.areas[0].name)
        .length
        ? props.areas[0]
        : null,
      selectedOne: initialGoals.filter((goal) => goal === props.areas[1].name)
        .length
        ? props.areas[1]
        : null,
      selectedTwo: initialGoals.filter((goal) => goal === props.areas[2].name)
        .length
        ? props.areas[2]
        : null,
      selectedThree: initialGoals.filter((goal) => goal === props.areas[3].name)
        .length
        ? props.areas[3]
        : null,
      selectedFour: initialGoals.filter((goal) => goal === props.areas[4].name)
        .length
        ? props.areas[4]
        : null,
      selectedFive: otherGoals.length ? props.areas[5] : null,
      otherGoals: otherGoals,
    };
  }
  private myScrollView: any;

  toggleSelectedLevel(item) {
    switch (parseInt(item.id)) {
      case 0:
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea;
    switch (parseInt(item.id)) {
      case 0:
        isSelectedArea = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedArea = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedArea = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedArea = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedArea = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedArea = this.state.selectedFive === item;
        break;
    }

    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: item.id % 2 ? 0 : 4,
            marginLeft: item.id % 2 ? 4 : 0,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text
          style={[
            styles.cellText,
            isSelectedArea && { color: COLOR_WHITE, textAlign: "center" },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  otherGoalsText = () => {
    if (!this.state.selectedFive) return true;
    if (this.state.otherGoals.length === 0) return true;
  };

  render() {
    return (
      <>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={[StyleUtils.screen_default]}
          keyboardVerticalOffset={80}
        >
          <ScrollView
            style={{
              flex: 1,
              flexDirection: "column",
              backgroundColor: COLOR_TRUE_WHITE,
            }}
            ref={(component) => {
              this.myScrollView = component;
            }}
          >
            <TouchableOpacity
              style={[
                {
                  alignSelf: "flex-start",
                  width: "15%",
                  aspectRatio: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  marginHorizontal: 16,
                  marginTop: 16,
                },
              ]}
              onPress={() => this.props.onBack()}
            >
              <SVGImage source={<BackArrow />} />
            </TouchableOpacity>
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                paddingHorizontal: 32,
                minHeight: 407,
                overflow: "hidden",
              }}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_22,
                  {
                    textAlign: "left",
                    width: "100%",
                    marginBottom: 20,
                  },
                ]}
              >
                {this.props.headerText}
              </Text>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(0, 2)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(2, 4)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(4)
                  .map((area) => this.renderLevelCell(area))}
              </View>

              {this.state.selectedFive && (
                <>
                  <Text
                    style={[
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_14,
                      StyleUtils.color_txt_normal,
                      {
                        textAlign: "left",
                        marginTop: 20,
                      },
                    ]}
                  >
                    Por favor, descreva:
                  </Text>
                  <TextInput
                    value={this.state.otherGoals}
                    onChangeText={(text) => {
                      this.setState({ otherGoals: text });
                    }}
                    style={[
                      StyleUtils.font_cera_regular,
                      {
                        borderRadius: 6,
                        color: "black",
                        textAlign: "auto",
                        padding: 8,
                        borderWidth: 1,
                        borderColor: "#000000",
                        marginTop: 8,
                      },
                    ]}
                    onFocus={() =>
                      this.myScrollView.scrollTo({
                        x: 0,
                        y: 600,
                      })
                    }
                  />
                </>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Próximo"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (
                this.state.selectedZero ||
                this.state.selectedOne ||
                this.state.selectedTwo ||
                this.state.selectedThree ||
                this.state.selectedFour ||
                (this.state.selectedFive && this.state.otherGoals !== 0)
              ) {
                let arr: any[] = [];
                if (this.state.selectedZero !== null) {
                  arr.push(this.state.selectedZero);
                }
                if (this.state.selectedOne !== null) {
                  arr.push(this.state.selectedOne);
                }
                if (this.state.selectedTwo !== null) {
                  arr.push(this.state.selectedTwo);
                }
                if (this.state.selectedThree !== null) {
                  arr.push(this.state.selectedThree);
                }
                if (this.state.selectedFour !== null) {
                  arr.push(this.state.selectedFour);
                }
                if (this.state.selectedFive !== null) {
                  let otherGoal = { id: "5", name: this.state.otherGoals };
                  arr.push(otherGoal);
                }
                this.props.onContinue(arr);
              }
            }}
            disabled={
              !this.state.selectedZero &&
              !this.state.selectedOne &&
              !this.state.selectedTwo &&
              !this.state.selectedThree &&
              !this.state.selectedFour &&
              this.otherGoalsText()
            }
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
  cellBase: {
    height: 88,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 25,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
