import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import LinearGradient from "react-native-linear-gradient";

type Props = {
  onContinue;
  areas;
  question;
  width;
  height;
};

type State = {
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
  selectedSix: string | null;
  selectedGoals: string | null;
  otherGoals;
  windowWidth;
};

export default class StudyPlanManyMultipleChoice extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedZero: null,
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      selectedFive: null,
      selectedSix: null,
      selectedGoals: null,
      otherGoals: "",
      windowWidth: Dimensions.get("window").width,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    Dimensions.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    this.setState({ windowWidth: nextAppState.window.width });
  };

  toggleSelectedLevel(item) {
    switch (parseInt(item.id)) {
      case 0:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
      case 6:
        if (item.name === "Outros" || item.name === "Outras") {
          if (this.state.selectedGoals) {
            this.setState({ selectedGoals: null });
          } else {
            this.setState({ selectedGoals: item });
          }
        }
        if (this.state.selectedSix === item) {
          this.setState({ selectedSix: null });
        } else {
          this.setState({ selectedSix: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedLevel;
    switch (parseInt(item.id)) {
      case 0:
        isSelectedLevel = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedLevel = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedLevel = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedLevel = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedLevel = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedLevel = this.state.selectedFive === item;
        break;
      case 6:
        isSelectedLevel = this.state.selectedSix === item;
        break;
    }
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          isSelectedLevel ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: 12,
            height: this.props.height,
            width: this.props.width,
            borderRadius: 6,
            alignItems: "center",
            flexDirection: "row",
            marginVertical: 8,
            justifyContent: "center",
            flexWrap: "wrap",
            alignContent: "center",
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text style={[styles.cellText]}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <View style={[StyleUtils.screen_default]}>
          <LinearGradient
            colors={["#011F42", "#016EE8"]}
            style={[
              {
                paddingVertical: 100,
              },
            ]}
          />
          <View
            style={{
              width: this.state.windowWidth,
              height: this.state.windowWidth,
              borderRadius: this.state.windowWidth / 2,
              backgroundColor: COLOR_TRUE_WHITE,
              transform: [{ scaleX: 2 }],
              marginTop: 80,
              position: "absolute",
            }}
          />
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              {
                alignSelf: "center",
                textAlign: "center",
                fontSize: this.state.windowWidth < 500 ? 20 : 32,
                zIndex: 1,
                marginTop: -60,
                marginBottom: 20,
                marginHorizontal: 40,
              },
            ]}
          >
            {this.props.question}
          </Text>
          {this.props.areas.length < 5 || this.state.windowWidth < 500 ? (
            <>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(0, 2)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(2, 4)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(4, 6)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              {this.props.areas.length > 6 && (
                <View style={styles.containerCenter}>
                  {this.props.areas
                    .slice(6)
                    .map((area) => this.renderLevelCell(area))}
                </View>
              )}
            </>
          ) : (
            <>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(0, 3)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(3, 6)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              {this.props.areas.length > 6 && (
                <View style={styles.containerCenter}>
                  {this.props.areas
                    .slice(6)
                    .map((area) => this.renderLevelCell(area))}
                </View>
              )}
            </>
          )}
        </View>
        {(this.state.selectedZero ||
          this.state.selectedOne ||
          this.state.selectedTwo ||
          this.state.selectedThree ||
          this.state.selectedFour ||
          this.state.selectedFive ||
          this.state.selectedSix) && (
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.color_bg_white,
              {
                width: 264,
                borderWidth: 1,
                borderColor: COLOR_PRIMARY,
                alignSelf: "center",
                marginBottom: 100,
              },
            ]}
            onPress={() => {
              const arr: string[] = [];
              if (this.state.selectedZero !== null) {
                arr.push(this.state.selectedZero);
              }
              if (this.state.selectedOne !== null) {
                arr.push(this.state.selectedOne);
              }
              if (this.state.selectedTwo !== null) {
                arr.push(this.state.selectedTwo);
              }
              if (this.state.selectedThree !== null) {
                arr.push(this.state.selectedThree);
              }
              if (this.state.selectedFour !== null) {
                arr.push(this.state.selectedFour);
              }
              if (this.state.selectedFive !== null) {
                arr.push(this.state.selectedFive);
              }
              if (this.state.selectedSix !== null) {
                arr.push(this.state.selectedSix);
              }
              this.props.onContinue(arr);
              setTimeout(() => {
                this.setState({
                  selectedZero: null,
                  selectedOne: null,
                  selectedTwo: null,
                  selectedThree: null,
                  selectedFour: null,
                  selectedFive: null,
                  selectedSix: null,
                  selectedGoals: null,
                  otherGoals: "",
                });
              }, 400);
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_primary,
                StyleUtils.font_size_16,
                { padding: 14, textAlign: "center" },
              ]}
            >
              Continuar
            </Text>
          </TouchableOpacity>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 15,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
