import React, { Component } from "react";
import { View, Image, Text } from "react-native";
import { connect } from "react-redux";
import { withRouter } from "../Router";
import StyleUtils, {
  COLOR_SUCCESS,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import UserGray from "../../assets/userGray.svg";
import CheckWhite from "../../assets/checkWhite.svg";
import { SCREEN_SIZE_500 } from "../Utils/SizeUtils";

type Props = {
  bookedEvent?;
  history;
};

type State = {
  mobile;
};

const stateToProps = (state) => {
  return {
    bookedEvent: state.selRed.bookedEvent,
  };
};

class BookingFinished extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false,
    };
  }

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let mobile = layout.width < SCREEN_SIZE_500;
    if (mobile != this.state.mobile) {
      this.setState({
        mobile: mobile,
      });
    }
  };

  dateString = () => {
    let date = new Date(this.props.bookedEvent.startDate);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  renderTutorImage(): React.ReactNode {
    return (
      <View>
        <View
          style={{
            height: 100,
            width: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!!this.props.bookedEvent.tutor.imageURL && (
            <Image
              source={{
                uri: this.props.bookedEvent.tutor.imageURL,
              }}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 1000,
                overflow: "hidden",
              }}
            />
          )}
          {!this.props.bookedEvent.tutor.imageURL && (
            <SVGImage source={<UserGray />} />
          )}
        </View>
        <View
          style={{
            position: "absolute",
            right: -6,
            bottom: 0,
            width: 32,
            height: 32,
            borderColor: COLOR_TRUE_WHITE,
            borderRadius: 1000,
            borderWidth: 1,
            backgroundColor: COLOR_SUCCESS,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <SVGImage style={{ alignSelf: "center" }} source={<CheckWhite />} />
        </View>
      </View>
    );
  }

  render() {
    return (
      <View style={[StyleUtils.screen_default, { alignItems: "center" }]}>
        <View
          style={{
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: 26,
            flex: 1,
          }}
        >
          <View>
            <Text
              style={[
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_success,
                StyleUtils.font_size_20,
                { textAlign: "center" },
              ]}
            >
              Sua aula foi agendada!
            </Text>
            <Text
              style={[
                StyleUtils.font_btn_regular,
                StyleUtils.color_txt_light,
                StyleUtils.font_size_16,
                { textAlign: "center", paddingTop: 12 },
              ]}
            >
              Você será notificado alguns minutos antes do início da aula.
            </Text>
          </View>
          <View
            style={{
              alignItems: "center",
              justifyContent: "space-evenly",
              flex: 0.5,
            }}
          >
            {this.renderTutorImage()}
            <Text
              style={[
                StyleUtils.color_txt_dark,
                StyleUtils.font_btn_bold,
                StyleUtils.font_size_16,
                { textAlign: "center" },
              ]}
            >
              {this.props.bookedEvent.tutor.name}
            </Text>
            <View
              style={[
                StyleUtils.color_bg_primarylight,
                StyleUtils.shape_btn_default_rounded,
                {
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 15,
                  paddingRight: 15,
                },
              ]}
            >
              <Text
                style={[StyleUtils.color_txt_primary, { textAlign: "center" }]}
              >
                {this.dateString()}
              </Text>
            </View>
          </View>
        </View>
        <ButtonElement
          text="Voltar à Home"
          onPress={() => this.props.history.push("/")}
          styte={[
            { margin: 32 },
            this.state.mobile && { alignSelf: "stretch" },
          ]}
        />
      </View>
    );
  }
}

export default withRouter(connect(stateToProps, null)(BookingFinished));
