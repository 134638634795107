import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  StyleSheet,
} from "react-native";
import StyleUtils, { COLOR_SUCCESS } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import { connect } from "react-redux";
import { selectSubjectTest, setOnboardingData } from "../Actions";
import GreenCircle from "../../assets/greenCircle.svg";
import VideoComponent from "../Elements/VideoElement";
import { getSubjectTest } from "../Server";
import { analytics } from "../Analytics";
import { Subject } from "../Models/Subjects";

type Props = {
  history;
  location;
  testDuration;
  selectSubjectTest;
  setOnboardingData;
  talkspaceOnboarding;
  user;
};

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    talkspaceOnboarding: state.userRed.talkspaceOnboarding,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectSubjectTest: (subjectTest) =>
      dispatch(selectSubjectTest(subjectTest)),
    setOnboardingData: (data) => dispatch(setOnboardingData(data)),

    dispatch: dispatch,
  };
}

function SubjectTestResult(props: Props) {
  const [headerText, setHeaderText] = useState("");
  const [subHeaderText, setSubHeaderText] = useState("");
  const [emoji, setEmoji] = useState("");
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [numQuestions, setNumQuestions] = useState(0);
  const [subject, setSubject] = useState<Subject>({ id: "", name: "" });
  const [subjectTestID, setSubjectTestID] = useState("");

  useEffect(() => {
    getSubjectTest(props.location.state.subjectTestID).then((subjectTest) => {
      setSubjectTestID(subjectTest.id);
      setSubject(subjectTest.subject);
      setNumQuestions(subjectTest.testQuestions.length);
      setScore(subjectTest.score);
      if (score <= 4) {
        setHeaderText("Quase lá");
        setSubHeaderText(
          "Que tal agendar uma aula para te\najudar com todas as suas dúvidas?"
        );
        setEmoji("😐");
      } else if (score <= 6) {
        setHeaderText("Muito bem!");
        setSubHeaderText(
          "Que tal agendar uma aula para\nconsolidar melhor essas matérias?"
        );
        setEmoji("😀");
      } else if (score <= 8) {
        setHeaderText("Demais!");
        setSubHeaderText(
          "Que tal tirar essas últimas dúvidas\npara gabaritar " +
            subjectTest.subject.name +
            "?"
        );
        setEmoji("😃");
      } else if (score <= 10) {
        setHeaderText("Incrível!");
        setSubHeaderText(
          "Parece que você está super\npreparado para o vestibular!"
        );
        setEmoji("😁");
      }
    });
  }, [score]);

  useEffect(() => {
    if (Platform.OS === "android") {
      const handle = setTimeout(() => setLoading(false), 800);
      return () => clearTimeout(handle);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: COLOR_SUCCESS,
        paddingTop: 30,
      }}
    >
      <Text
        style={[
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_28,
          StyleUtils.color_txt_white,
          { textAlign: "center", marginVertical: 50, zIndex: 1 },
        ]}
      >
        {headerText}
      </Text>
      <Text
        style={[
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_18,
          StyleUtils.color_txt_white,
          { textAlign: "center", zIndex: 1, marginTop: 20 },
        ]}
      >
        {subHeaderText}
      </Text>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <SVGImage source={<GreenCircle />} style={{ zIndex: 1, top: 95 }} />
        <Text
          style={{
            position: "absolute",
            fontSize: Platform.OS === "android" ? 43 : 52,
            top: 98,
            zIndex: 2,
          }}
        >
          {emoji}
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.color_txt_dark,
            {
              fontSize: 80,
              position: "absolute",
              top: Platform.OS === "ios" ? 180 : 160,
              zIndex: 1,
            },
          ]}
        >
          {score}
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_16,
            {
              position: "absolute",
              top: 260,
              zIndex: 1,
              opacity: 0.5,
            },
          ]}
        >
          de 10.0
        </Text>
        {loading && <View style={styles.backgroundVideoGreen} />}
        <VideoComponent
          source={require("../../assets/subject_test_result.mp4")}
          style={styles.backgroundVideo}
          muted={true}
          repeat={true}
          resizeMode={"cover"}
          rate={1.0}
          ignoreSilentSwitch={"obey"}
        />
      </View>

      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            borderWidth: 1,
            borderColor: "rgba(255, 255, 255, .5)",
            borderRadius: 8,
            paddingVertical: 12,
            paddingHorizontal: 24,
            marginRight: 33,
            zIndex: 3,
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_18,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            Duração
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_18,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            {Math.round(props.location.state.testDuration / 60)} min
          </Text>
        </View>

        <View
          style={{
            borderWidth: 1,
            borderColor: "rgba(255, 255, 255, .5)",
            borderRadius: 8,
            paddingVertical: 12,
            paddingHorizontal: 24,
            zIndex: 3,
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_18,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            Acerto
          </Text>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_18,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            {Math.round((score / 10) * numQuestions)} de {numQuestions}
          </Text>
        </View>
      </View>

      <TouchableOpacity
        style={[
          StyleUtils.shape_btn_small,
          StyleUtils.color_bg_white,
          { marginTop: 80, width: 327, marginBottom: 70 },
        ]}
        onPress={() => {
          analytics.sendSendSubjectTestTutor();
          props.setOnboardingData({
            subject: subject,
            level: {
              id: "3de1f15d-3725-4254-919d-ac43c21e3496",
              name: "Ensino Médio",
              index: 3,
            },
          });
          if (props.talkspaceOnboarding) {
            props.history.push({
              pathname: "/messages/" + props.user.counselor.id,
              state: {
                messages: `Acabei de fazer um simulado de ${subject.name} e gostaria de agendar uma conversa com um professor para me ajudar!`,
              },
            });
          } else {
            props.history.push({
              pathname: "/landing/tutors",
              state: {
                showFooter: true,
                subjectTestID: subjectTestID,
              },
            });
          }
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_16,
            { padding: 14, textAlign: "center" },
          ]}
        >
          Revisar com um professor
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          analytics.sendSubjectTestResultButton();
          getSubjectTest(props.location.state.subjectTestID).then(
            (subjectTest) => {
              props.selectSubjectTest(subjectTest);
              props.history.push("/subjecttests/exercise");
            }
          );
        }}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_btn_regular,
            StyleUtils.font_size_16,
            {
              textDecorationLine: "underline",
              marginBottom: 100,
              marginTop: 20,
            },
          ]}
        >
          Ver correção
        </Text>
      </TouchableOpacity>
    </View>
  );
}

export default connect(stateToProps, dispatchToProps)(SubjectTestResult);

const styles = StyleSheet.create({
  backgroundVideo: {
    marginTop: -30,
    width: 375,
    height: 375,
    marginBottom: 24,
  },
  backgroundVideoGreen: {
    width: 375,
    height: 375,
    position: "absolute",
    top: 60,
    backgroundColor: COLOR_SUCCESS,
    zIndex: 2,
  },
});
