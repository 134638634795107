import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils, { SpcStyle } from "../Utils/StyleUtils";
import React from "react";

const ButtonNextBlue = (props) => {
  return (
    <View
      style={{
        flex: 1,
        width: "100%",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "flex-end",
        paddingLeft: 32,
        paddingRight: 32,
      }}
    >
      <TouchableOpacity
        disabled={!props.isValidInfo}
        style={[
          SpcStyle.continueButtonBaseNoAbsolute,
          props.isValidInfo
            ? SpcStyle.continueButtonActiveBlue
            : SpcStyle.continueButtonInactive,
        ]}
        onPress={() => props.onClick()}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_16,
            {
              padding: 16,
              paddingTop: 16,
              paddingBottom: 12,
              paddingLeft: 40,
              paddingRight: 40,
              alignSelf: "center",
            },
          ]}
        >
          {props.text}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default ButtonNextBlue;
