import React from "react";
import { TouchableOpacity, ViewStyle } from "react-native";
import Backbutton from "../../assets/backbutton.svg";
import BackbuttonWhite from "../../assets/backbuttonWhite.svg";
import Forwardbutton from "../../assets/forwardButton.svg";
import ForwardbuttonWhite from "../../assets/forwardButtonWhite.svg";
import SVGImage from "../Utils/SVGImage";

const buttons = {
  backWhite: BackbuttonWhite,
  backDark: Backbutton,
  forwardWhite: ForwardbuttonWhite,
  forwardDark: Forwardbutton,
};

export function NavigationButton(props: {
  type?: "forward" | "backward";
  size?: number;
  colorStyle?: "white" | "dark";
  style?: ViewStyle;
  onPress?: () => void;
}) {
  const iconSize = props.size || 24;
  const size = (iconSize * 3) / 2;
  const white = (props.colorStyle || "white") === "white";
  const backward = (props.type || "backward") === "backward";
  let Icon = backward ? buttons.backDark : buttons.forwardDark;
  if (white) {
    Icon = backward ? buttons.backWhite : buttons.forwardWhite;
  }

  return (
    <TouchableOpacity
      style={[
        {
          height: size,
          width: size,
          borderRadius: size / 2,
          justifyContent: "center",
          alignItems: "center",
        },
        props.style,
      ]}
      onPress={props.onPress}
    >
      <SVGImage
        source={<Icon height={iconSize} width={iconSize} />}
        style={{
          height: iconSize,
          width: iconSize,
        }}
      />
    </TouchableOpacity>
  );
}
