import AgoraRTC from "./AgoraEnhancer";

export default class VideoConnection {
  constructor(channelID) {
    this.channel = channelID;
    this.audioMuted = false;
    this.videoMuted = false;
    this.remoteVideoMuted = false;
  }

  channel;
  client;
  private privateRemoteStream;
  remoteStream;
  localStream;
  stateUpdate;
  audioMuted;
  videoMuted;
  remoteVideoMuted;

  classMount = (audioMuted, videoMuted) => {
    this.audioMuted = audioMuted;
    this.videoMuted = videoMuted;
    this.connectVideo();
  };

  classUnmount = () => {
    if (this.localStream) {
      this.localStream.close();
    }
    if (this.client) {
      this.client.gatewayClient.removeEventListener(
        "stream-published",
        this.addLocal
      );
      this.client.gatewayClient.removeEventListener("stream-added", this.doSub);
      this.client.gatewayClient.removeEventListener(
        "stream-subscribed",
        this.addRemote
      );
      this.client.gatewayClient.removeEventListener(
        "peer-leave",
        this.removeRemote
      );
      this.client.gatewayClient.removeEventListener(
        "stream-removed",
        this.removeRemote
      );
      this.client.leave();
    }
  };

  connectVideo = () => {
    this.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
    this.client
      .init("8e6218b6e6984290a74036fe3abd0160")
      .then(() => {
        this.client.on("stream-published", this.addLocal);
        this.client.on("stream-added", this.doSub);
        this.client.on("stream-subscribed", this.addRemote);
        this.client.on("peer-leave", this.removeRemote);
        this.client.on("stream-removed", this.removeRemote);
        return this.client.join("", this.channel, null);
      })
      .then(() => {
        this.initLocalStream();
        this.verifyRemoteVideoStats();
      })
      .catch((err) => {
        console.log("[AGORA] Error: " + err);
      });
  };

  verifyRemoteVideoStats = () => {
    setInterval(() => {
      let remoteVideoMutedInit = this.remoteVideoMuted;
      this.client
        .getRemoteVideoStats((video) => {})
        .then((video) => {
          for (var uid in video) {
            if (parseInt(video[uid].MuteState) === 1) {
              remoteVideoMutedInit = true;
            } else {
              remoteVideoMutedInit = false;
            }

            if (remoteVideoMutedInit !== this.remoteVideoMuted) {
              this.remoteVideoMuted = remoteVideoMutedInit;
              this.stateUpdate();
            }
          }
        })
        .catch(() => {
          console.log("VerifyRemoteVideoStats has failed!");
        });
    }, 1000);
  };

  initLocalStream = () => {
    const localStream = AgoraRTC.createStream({
      streamID: 12345,
      video: true,
      audio: true,
      screen: false,
    });
    localStream
      .init()
      .then(() => {
        console.log("[AGORA] Created local stream");
        if (this.videoMuted) localStream.muteVideo();
        if (this.audioMuted) localStream.muteAudio();
        this.client.publish(localStream);
      })
      .then(() => {
        console.log("[AGORA] Local stream published");
        this.localStream = localStream;
        console.log(localStream);
        if (this.stateUpdate) this.stateUpdate();
      })
      .catch((err) => {
        console.log("[AGORA] Error: " + err);
      });
  };

  toggleVideo = () => {
    if (!this.videoMuted) {
      this.localStream.muteVideo();
    } else {
      this.localStream.unmuteVideo();
    }
    this.videoMuted = !this.videoMuted;
  };

  toggleAudio = () => {
    if (!this.audioMuted) {
      this.localStream.muteAudio();
    } else {
      this.localStream.unmuteAudio();
    }
    this.audioMuted = !this.audioMuted;
  };

  addRemote = (evt: any) => {
    const { stream } = evt;
    console.log("addRemote");
    console.log(evt);
    this.privateRemoteStream = stream;
    if (this.localStream) {
      this.remoteStream = this.privateRemoteStream;
      if (this.stateUpdate) this.stateUpdate();
    }
  };

  // remove stream
  removeSub = (evt: any) => {
    const { stream } = evt;
    console.log("removeSub");
    this.remoteVideoMuted = false;
    console.log(`remote ${this.remoteVideoMuted}`);
    if (stream === this.remoteStream) {
      this.remoteStream = null;
    }
    if (this.stateUpdate) this.stateUpdate();
  };

  // remove stream
  removeRemote = (evt: any) => {
    const { stream } = evt;
    if (stream === this.remoteStream) {
      this.remoteStream = null;
      if (this.stateUpdate) this.stateUpdate();
    }
  };

  // subscribe when added
  doSub = (evt: any) => {
    console.log("doSub");
    console.log(evt);
    this.client.subscribe(evt.stream);
    if (this.stateUpdate) this.stateUpdate();
  };

  // add when published
  addLocal = (evt: any) => {
    console.log("addLocal");
    const { stream } = evt;
    this.localStream = stream;
    if (this.privateRemoteStream) {
      this.remoteStream = this.privateRemoteStream;
    }
    if (this.stateUpdate) this.stateUpdate();
  };

  switchCamera = () => {};
}
