import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  areas;
  onContinue;
  onBack;
  progress;
  question;
};

type State = {
  selectedSingle: string | null;
};

export default class BinaryTemplate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSingle: null,
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;

    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: item.id % 2 ? 0 : 8,
            marginLeft: item.id % 2 ? 8 : 0,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text style={[styles.cellText]}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <View style={[{ justifyContent: "center", width: "100%" }]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 24,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  this.props.onBack();
                }}
              >
                <SVGImage source={<BackArrow />} />
              </TouchableOpacity>
            </View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
              ]}
            >
              Responda e ganhe uma aula grátis!
            </Text>
            <View style={{ flex: 1 }}></View>
          </View>
        </View>
        <View
          style={{
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
          }}
        />
        <View style={{ height: 4 }}>
          <View
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: COLOR_PRIMARY,
              width: this.props.progress,
            }}
          />
        </View>
        <View
          style={{
            paddingHorizontal: 32,
            marginTop: 24,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_22,
              {
                textAlign: "left",
                width: "100%",
                marginBottom: 20,
              },
            ]}
          >
            {this.props.question}
          </Text>
          <View style={styles.containerCenter}>
            {this.props.areas
              .slice(0, 2)
              .map((area) => this.renderLevelCell(area))}
          </View>
        </View>
        <View style={{ bottom: 0 }}>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (this.state.selectedSingle) {
                analytics.sendSurveyResponse(
                  this.props.question,
                  this.state.selectedSingle
                );
                this.props.onContinue();
                this.setState({
                  selectedSingle: null,
                });
              }
            }}
            disabled={!this.state.selectedSingle}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  cellBase: {
    height: 88,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 25,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
