import React, { useEffect, useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import TuteeInfo from "../Models/TuteeInfo";
import {
  getDeviceToken,
  useNotification,
  useReloadKey,
} from "../Notifications/NotificationsManager";
import {
  EventsFilter,
  getEvents,
  sendAnalyticsEvent,
  getStoredNotificationCount,
  updateDeviceToken,
} from "../Server";
import StyleUtils, {
  COLOR_BG_LIGHT,
  COLOR_DANGER,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import TuteeHome from "./TuteeHome";
import TutorHome from "./TutorHome";
import SVGImage from "../Utils/SVGImage";
import Logo from "../../assets/logoWhiteNew.svg";
import LogoColor from "../../assets/logoColor.svg";
import NotificationIcon from "../../assets/notificationBell.svg";
import NotificationBlack from "../../assets/notificationBlack.svg";
import { logout, setTalkspaceOnboarding } from "../Actions";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import Settings from "../../assets/hamburger.svg";
import SettingsBlack from "../../assets/hamburgerBlack.svg";
import { analytics } from "../Analytics";

type Props = {
  user;
  tutorInfo;
  mobile;
  tuteeInfo?: TuteeInfo;
  history;
  logout;
  location;
  dispatchSetTalkspaceOnboading;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    tuteeInfo: state.userRed.tuteeInfo,
    mobile: state.layoutRed.mobile,
  };
}

function dispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    dispatchSetTalkspaceOnboading: (value) =>
      dispatch(setTalkspaceOnboarding(value)),
  };
}

export function Home(props: Props) {
  const [hasLesson, setHasLesson] = useState(false);
  const [unseenNotificationCount, setUnseenNotificationCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [referralOpen, setReferralOpen] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  const [updateToken, setUpdateToken] = useState(false);
  const [isTalkspaceFlux, setIsTalkspaceFlux] = useState(false);
  const [loading, setLoading] = useState(true);

  const hasTutor = !!props.tuteeInfo && !!props.tuteeInfo.preferredTutor;

  const beforeComponentMounts = async () => {
    try {
      if (props.user) {
        await RemoteConfigBridge.remoteConfig(
          "talkspaceOnboarding",
          (value) => {
            if (value === "1" && !hasTutor) {
              props.dispatchSetTalkspaceOnboading(true);
              setIsTalkspaceFlux(true);
            }
          }
        );

        if (hasTutor) {
          props.dispatchSetTalkspaceOnboading(false);
          setIsTalkspaceFlux(false);
        }

        await sendAnalyticsEvent("LoadedHome", {
          name: props.user.name,
          email: props.user.email,
        });

        analytics.setUserID(props.user.id);

        const now = new Date();
        now.setMinutes(now.getMinutes() - 29);
        var eventFilter: EventsFilter = {
          tutorId: undefined,
          tuteeId: props.user.id,
          periodStart: now.toISOString(),
          periodEnd: undefined,
          limit: 1,
        };
        const result: any = await getEvents(eventFilter);
        if (result && result.length > 0 && result[0]) {
          setHasLesson(true);
        }

        const res = await getStoredNotificationCount(props.user.id);
        await setUnseenNotificationCount(res.count);

        if (!updateToken) {
          setUpdateToken(true);
          const token = await getDeviceToken();
          if (token !== "" && token !== null) {
            await updateDeviceToken(props.user.id, token).then(() => {
              console.log("token updated");
            });
          }
        }

        await RemoteConfigBridge.remoteConfig("showReferralHome", (value) => {
          var valueBool = value == "1";
          setShowReferral(valueBool);
        });
      } else {
        await sendAnalyticsEvent("LoadedHome", {
          name: "Not logged",
          email: "Not logged",
        });
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    beforeComponentMounts();
  }, []);

  const notification = useNotification(["HOMEWORK"]);
  const key = useReloadKey(notification);

  const emptyHome =
    (!props.tutorInfo && !hasTutor && !hasLesson) || !props.user;

  const renderNotificationBadge = (count) => {
    return (
      <View
        style={{
          width: 20,
          height: 20,
          borderRadius: 1000,
          backgroundColor: COLOR_DANGER,
          position: "absolute",
          top: emptyHome ? -10 : -3,
          right: emptyHome ? -10 : 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_14,
            {
              height: "100%",
              width: "100%",
              textAlign: "center",
              textAlignVertical: "center",
              paddingTop: Platform.OS === "ios" ? 2 : 0,
            },
          ]}
        >
          {count}
        </Text>
      </View>
    );
  };

  return (
    <>
      <View
        key={key}
        style={[
          StyleUtils.screen_default,
          {
            backgroundColor: props.tutorInfo ? COLOR_BG_LIGHT : COLOR_WHITE,
          },
        ]}
      >
        <View style={{ zIndex: 1 }}>
          {(props.location.pathname === "/" ||
            props.location.pathname === "/home") && (
            <View
              style={[
                {
                  height: 50,
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: emptyHome ? "#FFFFFF" : "#0179FF",
                },
              ]}
            >
              <TouchableOpacity
                onPress={() => {
                  props.history.push("/settings");
                }}
              >
                <SVGImage
                  source={emptyHome ? <SettingsBlack /> : <Settings />}
                  style={{ position: "relative", left: 16 }}
                />
              </TouchableOpacity>
              <SVGImage
                source={emptyHome ? <LogoColor /> : <Logo />}
                style={{ position: "absolute", left: 60 }}
              />

              <TouchableOpacity
                onPress={() => {
                  props.history.push("/notifications");
                }}
                style={{ position: "absolute", right: 24 }}
              >
                <SVGImage
                  source={
                    emptyHome ? <NotificationBlack /> : <NotificationIcon />
                  }
                />
                {unseenNotificationCount > 0 &&
                  renderNotificationBadge(unseenNotificationCount)}
              </TouchableOpacity>
            </View>
          )}
        </View>
        {props.tutorInfo && <TutorHome mobile={props.mobile} />}
        {!props.tutorInfo && hasTutor && <TuteeHome mobile={props.mobile} />}
        {!props.tutorInfo && !hasTutor && hasLesson && (
          <TuteeHome mobile={props.mobile} />
        )}
      </View>
    </>
  );
}

export default connect(stateToProps, dispatchToProps)(Home);
