import React, { useRef, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_LIGHT,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import IconDelete from "../../../assets/iconDelete.svg";
import Archive from "../../../assets/archive.svg";
import Pen from "../../../assets/pen.svg";
import ButtonElement from "../../Elements/ButtonElement";
import Exercise from "../../../assets/icoToolExercise.svg";
import MoreDark from "../../../assets/moreDark.svg";
import FileRow from "./FileRow";
import MoreOptionsPopUp from "./MoreOptionsPopUp";
import { deleteExerciseDirectory, updateExerciseDirectory } from "../../Server";
import { InlineInputEdit } from "react-inline-input-edit";
import ErrorPopUp from "./ErrorPopUp";

type Props = {
  files;
  currentDirectory;
  currentDirectorySize;
  history;
  user;
  style;
  handleExerciseClick;
  selectedExercises;
  refetchData;
};

const FileListColumn = ({
  files,
  currentDirectory,
  currentDirectorySize,
  history,
  user,
  style,
  handleExerciseClick,
  selectedExercises,
  refetchData,
}: Props) => {
  const CurrentDirectoryHeader = () => {
    const [showMoreOptionPopUp, setShowMoreOptionPopUp] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);

    let error = useRef("");

    const handleDelete = () => {
      deleteExerciseDirectory(user.id, currentDirectory.id)
        .then(() => {
          setShowMoreOptionPopUp(false);
          history.replace("/exerciseDirectories");
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handlePenPress = () => {
      setIsEditingName(true);
      setShowMoreOptionPopUp(false);
    };

    const handleFolderChangeName = (text) => {
      if (text !== currentDirectory.name) {
        updateExerciseDirectory(user.id, currentDirectory.id, text)
          .then(() => {
            setIsEditingName(false);
            refetchData(currentDirectory.id);
          })
          .catch((err) => {
            error.current = err.message;
            setShowErrorPopUp(true);
          });
      }
    };

    const handleMoveToDirectory = (newParentId) => {
      return updateExerciseDirectory(
        user.id,
        currentDirectory.id,
        currentDirectory.name,
        newParentId
      );
    };

    const options = [
      { img: Pen, text: "Renomear", handlePress: handlePenPress },
      {
        img: Archive,
        text: "Mover para pasta",
        handlePress: handleMoveToDirectory,
      },
      { img: IconDelete, text: "Excluir", handlePress: handleDelete },
    ];

    const deletePopUpText = {
      header: "Você realmente deseja deletar essa pasta?",
      body:
        "Todas as pastas e exercícios dentro\ndessa pasta serão excluídos também",
    };

    return (
      <>
        <View
          style={{
            height: 70,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <SVGImage
            style={{ width: 50, height: 50 }}
            source={currentDirectory.id ? <Archive /> : <Exercise />}
          />
          <View style={{ marginLeft: 8, maxWidth: 300, overflow: "hidden" }}>
            <Text style={[StyleUtils.font_size_20, StyleUtils.font_cera_bold]}>
              {currentDirectory.id ? (
                isEditingName ? (
                  <InlineInputEdit
                    text={currentDirectory.name}
                    inputFontSize={20}
                    inputFontWeight={"bold"}
                    labelFontSize={20}
                    labelFontWeight={"bold"}
                    isEditing={true}
                    onFocusOut={handleFolderChangeName}
                  />
                ) : (
                  currentDirectory.name
                )
              ) : (
                "Todos os exercícios"
              )}
            </Text>
            <Text style={[StyleUtils.font_size_14, { color: COLOR_TXT_LIGHT }]}>
              {`${currentDirectorySize} exercício(s) criado(s)`}
            </Text>
          </View>

          {currentDirectory.id && (
            <View
              style={{
                position: "relative",
                alignSelf: "flex-start",
                marginTop: 10,
                marginLeft: 8,
                zIndex: 1,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setShowMoreOptionPopUp((state) => !state);
                }}
              >
                <SVGImage
                  style={{
                    width: 32,
                    height: 32,
                  }}
                  source={<MoreDark />}
                />
              </TouchableOpacity>
              {showMoreOptionPopUp && (
                <MoreOptionsPopUp
                  refetchData={refetchData}
                  user={user}
                  headerText={currentDirectory.name}
                  options={options}
                  style={{ zIndex: 999, left: 50, top: -15 }}
                  closePopUp={() => setShowMoreOptionPopUp(false)}
                  directory={currentDirectory}
                  history={history}
                  deletePopUpText={deletePopUpText}
                />
              )}
            </View>
          )}
        </View>
        <ErrorPopUp
          show={showErrorPopUp}
          header={"Não foi possível renomear a pasta"}
          body={error.current}
          onReturn={() => {
            setShowErrorPopUp(false);
            refetchData(currentDirectory.id);
          }}
        />
      </>
    );
  };

  return (
    <View
      style={[
        StyleUtils.shape_card_default,
        StyleUtils.border_color_light,
        { height: 650, width: 850, marginLeft: 11 },
        style,
      ]}
    >
      <View
        style={[
          StyleUtils.shadow_soft_bottom,
          {
            position: "relative",
            zIndex: 1,
            height: 96,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 24,
            paddingRight: 32,
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
          },
        ]}
      >
        <CurrentDirectoryHeader />
        <ButtonElement
          colorScheme="primary"
          text="Criar Exercício"
          textStyle={(StyleUtils.font_size_16, { paddingHorizontal: 24 })}
          styte={[StyleUtils.shape_btn_default, { height: 48, zIndex: -1 }]}
          onPress={() =>
            history.push({
              pathname: "/addExercise",
              state: { currentDirectory },
            })
          }
        />
      </View>
      <ScrollView
        style={[
          {
            marginTop: 16,
          },
        ]}
      >
        {files.map((file, index) => (
          <FileRow
            refetchData={refetchData}
            handleExerciseClick={handleExerciseClick}
            file={file}
            index={index}
            user={user}
            history={history}
            currentDirectory={currentDirectory}
            isSelected={
              selectedExercises.filter((ex) => ex.id === file.id).length
            }
          />
        ))}
      </ScrollView>
    </View>
  );
};

export default FileListColumn;
