import React from "react";
import Card from "./Card";
import User from "../../Models/User";
import * as Utils from "../../Utils/Utils";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  mobile?: boolean;
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  user: User;
  date: Date;
  onPress?: () => void;
  style?: ViewStyle;
  subject?;
  onPressReschedule?: () => void;
  onPressTutor?: () => void;
};

const EventCard = (props: Props) => {
  const { user, date, ...other } = props;
  return (
    <Card
      {...other}
      imageLabel={user.name}
      imageSource={
        props.user.imageURL
          ? {
              uri: props.user.imageURL,
            }
          : require("../../../assets/userPlaceHolder.png")
      }
      text={dateTime(date)}
      buttonText="Enviar Mensagem"
      subjects={props.subject}
    />
  );
};

function dateTime(date: Date): string {
  return `${Utils.dateFormatLongDateNoYear(date)}, às ${Utils.dateFormatHHhmm(
    date
  )}`;
}

function cardDate(date: Date, now: Date): string {
  if (date < Utils.nextDay(now, 1)) {
    return "Hoje";
  } else if (date < Utils.nextDay(now, 2)) {
    return "Amanhã";
  } else {
    return date.toLocaleDateString("pt-br", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
}

export default EventCard;
