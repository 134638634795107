import React from "react";
import { Component } from "react";
import { View, Text } from "react-native";
import GoogleSigninButton from "../Google/GoogleSigninButton";
import { connect } from "react-redux";
import StyleUtils from "../Utils/StyleUtils";
import FBButton from "../Facebook/FBButton";
import User from "../Models/User";
import TuteeInfo from "../Models/TuteeInfo";
import TutorInfo from "../Models/TutorInfo";
import { loginSuccessful, setTuteeInfo } from "../Actions";

type Props = {
  userID;
  loginSuccessful;
  history;
  location;
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccessful: (
      user: User,
      tuteeInfo?: TuteeInfo,
      tutorInfo?: TutorInfo
    ) => dispatch(loginSuccessful(user, tuteeInfo, tutorInfo)),
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    userID: state.userID,
  };
}

type State = {};

class Login extends Component<Props, State> {
  onLoginSuccessful(user: User, tuteeInfo?: TuteeInfo, tutorInfo?: TutorInfo) {
    this.props.loginSuccessful(user, tuteeInfo, tutorInfo);
    console.log();
    if (this.props.location.state && this.props.location.state.onSuccessPath) {
      this.props.history.replace(this.props.location.state.onSuccessPath);
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <View
        style={[
          StyleUtils.screen_default,
          { justifyContent: "center", alignItems: "center" },
        ]}
      >
        <View style={{ width: 300 }}>
          <Text
            style={[
              StyleUtils.font_btn_bold,
              StyleUtils.font_size_20,
              StyleUtils.color_txt_dark,
              { textAlign: "center", marginBottom: 8 },
            ]}
          >
            Faça seu login
          </Text>
          <Text
            style={[
              StyleUtils.font_size_16,
              StyleUtils.color_txt_light,
              { textAlign: "center", marginBottom: 70 },
            ]}
          >
            Escolha uma conta para entrar
          </Text>
          <GoogleSigninButton
            loginSuccessful={this.onLoginSuccessful.bind(this)}
          />
        </View>
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
