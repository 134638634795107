import React, { Component } from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { LinearGradient } from "../Utils/LinearGradient";
import SVGImage from "../Utils/SVGImage";
import PlaceHolderBoy from "../../assets/placeHolderBoy.svg";
import PlaceHolderGirl from "../../assets/placeHolderGirl.svg";
import { userShortName } from "../Utils/Utils";
import SignUpUser from "../Models/SignUpUser";

type Props = {
  userData: SignUpUser;
  onNext;
};

type State = {
  userData: SignUpUser;
};

class SignUpFinish extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
    };
  }

  getProfileImage() {
    if (this.props.userData && this.state.userData.imageURL) {
      return (
        <Image
          style={{ height: 176, width: 152, borderRadius: 14 }}
          source={{ uri: this.state.userData.imageURL }}
        />
      );
    }

    if (this.props.userData && this.props.userData.gender === "masculino") {
      return <SVGImage source={<PlaceHolderBoy />} />;
    } else {
      return <SVGImage source={<PlaceHolderGirl />} />;
    }
  }

  render() {
    return (
      <View style={[{ flex: 1, height: "100%" }, StyleUtils.screen_default]}>
        <View
          style={{
            flexDirection: "column",
            flex: 1,
            backgroundColor: "#13CE74",
          }}
        >
          <View
            style={{
              height: "62%",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <LinearGradient
              start={{ x: 1.0, y: 0.0 }}
              end={{ x: 0.0, y: 1.0 }}
              colors={["#028747", "rgba(26, 220, 126, 0)"]}
              locations={[0.0453, 0.5993]}
              style={{
                zIndex: 1,
                position: "absolute",
                top: -10,
                left: -5,
                right: -5,
                bottom: 0,
                height: "100%",
                borderRadius: 10000,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  position: "absolute",
                  top: "24%",
                  bottom: "50%",
                  left: "31%",
                  right: "31%",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: 10,
                }}
              />
              <View
                style={{
                  position: "absolute",
                  top: "20%",
                  bottom: "50%",
                  left: "33%",
                  right: "33%",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  borderRadius: 10,
                }}
              />
              <View style={{ flex: 1 }} />
              {this.getProfileImage()}

              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_18,
                  { flex: 1, paddingTop: 20, maxWidth: "80%" },
                ]}
              >
                {this.props.userData &&
                  this.props.userData.name &&
                  userShortName(this.props.userData.name)}
              </Text>
            </LinearGradient>
          </View>
          <View
            style={{ position: "absolute", left: 32, right: 32, bottom: 25 }}
          >
            <Text
              style={[
                StyleUtils.font_size_22,
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,

                {
                  elevation: 3,
                  zIndex: 2,
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 16,
                  lineHeight: 20,
                },
              ]}
            >
              Sua conta foi {"\n"} criada com sucesso
            </Text>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_regular,
                StyleUtils.color_txt_white,
                {
                  alignSelf: "center",
                  textAlign: "center",
                  lineHeight: 18,
                  marginBottom: 40,
                },
              ]}
            >
              Você está prestes a estudar com os {"\n"} melhores professores
              online
            </Text>
            <TouchableOpacity
              style={[
                StyleUtils.color_bg_white,
                {
                  alignSelf: "center",
                  borderRadius: 6,
                  width: "100%",
                },
              ]}
              onPress={() => {
                this.props.onNext();
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_18,
                  {
                    padding: 16,
                    paddingTop: 16,
                    paddingBottom: 12,
                    alignSelf: "center",
                  },
                ]}
              >
                Finalizar
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

export default SignUpFinish;
