import React, { Component } from "react";
import StreamPlayer from "agora-stream-player";
import { View } from "react-native";

type Props = {
  style;
  stream;
  clientInitialized;
  videoMuted?: boolean;
  isLocalStream: boolean;
};

export default class Player extends Component<Props, any> {
  render() {
    return (
      <View>
        {this.props.stream && (
          <StreamPlayer
            style={this.props.style[0]}
            stream={this.props.stream}
            fit="cover"
            autoChange={false}
            networkDetect={true}
            placeholder={<View style={this.props.style[0]} />}
          />
        )}
      </View>
    );
  }
}
