import React, { useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from "react-redux";
import { isSuperTutor, selectTutor, setTutorSearch } from "../Actions";
import { analytics } from "../Analytics";
import { Tutor } from "../Models/TutorInfo";
import {
  getTutors2,
  getTutorsByArea,
  sendTutorSubjectTestNotification,
  setTutor,
} from "../Server";
import SignUpPopUp from "../SignUp/SignUpPopUp";
import TutorCard from "../TutorCards/TutorCard";
import StyleUtils from "../Utils/StyleUtils";
import HeaderMatchList from "./HeaderMatchList";
import TutorMatchNoMatch from "./TutorMatchNoMatch";
import TutorMatchSearching from "./TutorMatchSearching";
import BottomFooter from "../Footer/Footer";
import { EventsFilter, getEvents, updateSubjectTest } from "../Server";
import FreeCreditScreen from "../Home/FreeCreditScreen";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import { setViewedFreeCreditPopUp } from "../Actions";
import TutorMatchLoadingState from "./TutorMatchLoadingState";

const defaultFilter = {
  level: {
    name: "Ensino Fundamental",
  },
  subject: {
    name: "Matemática",
  },
};

type Props = {
  dispatch;
  history;
  location;
  userID;
  selectedSubject;
  selectedLevel;
  searchedTutors: Tutor[];
  useSearchedTutors?: boolean;
  viewedFreeCreditPopUp: boolean;
  setViewedFreeCreditPopUp;
};

const dispatchToProps = (dispatch) => {
  return {
    setViewedFreeCreditPopUp: (value) =>
      dispatch(setViewedFreeCreditPopUp(value)),
    dispatch: dispatch,
  };
};

function mapStateToProps(state) {
  return {
    userID: state.userRed.user && state.userRed.user.id,
    selectedSubject: state.searchFilterRed.subject,
    selectedLevel: state.searchFilterRed.level,
    searchedTutors: state.selRed.searchedTutors,
    useSearchedTutors: state.selRed.useSearchedTutors,
    viewedFreeCreditPopUp: state.userRed.viewedFreeCreditPopUp,
  };
}

type SelectAction = {
  type: "sendMessage" | "booking" | "favorite";
  tutor?: Tutor;
  favorite?: boolean;
};

function TutorMatchList(props: Props) {
  const [filter, setFilter] = useState({
    subject: props.selectedSubject || defaultFilter.subject,
    level: props.selectedLevel || defaultFilter.level,
  });
  const [tutors, setTutors] = useState<Tutor[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchCount, setSearchCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [played, setPlayed] = useState(props.useSearchedTutors);
  const [showMore, setShowMore] = useState(false);
  const [action, setAction] = useState<SelectAction | undefined>();
  const [showFreeCreditPopUp, setShowFreeCreditPopUp] = useState(false);
  const [showLottie, setShowLottie] = useState(false);
  const [
    showVocationalOptionsOnboarding,
    setShowVocationalOptionsOnboarding,
  ] = useState(false);
  useEffect(() => {
    RemoteConfigBridge.remoteConfig("freeCreditOnSearch", (value) => {
      if (value === "1" && !props.viewedFreeCreditPopUp && !props.userID) {
        setShowFreeCreditPopUp(true);
      }
    });
    RemoteConfigBridge.remoteConfig("showLottieOnTutorSearch", (value) => {
      if (value === "1") {
        setShowLottie(true);
      }
    });
  }, []);

  const onSendMessage = (action: SelectAction) => {
    if (action && action.tutor) {
      props.dispatch(setTutorSearch(tutors));
      props.history.push({
        pathname: `/messages/${action.tutor.id}`,
        state: { shouldGoBack: true, useTutorSearchBack: true },
      });
    }
    setAction(undefined);
  };

  const onBooking = (action: SelectAction) => {
    if (action.tutor) {
      props.dispatch(selectTutor(action.tutor));
      props.dispatch(setTutorSearch(tutors));
      props.history.push({
        pathname: "/booking",
        state: { useTutorSearchBack: true, source: "tutor_match" },
      });
    }
  };

  const onLikeTutor = (action: SelectAction) => {
    if (action.tutor) {
      const selectedTutor = action.tutor;
      setTutor(props.userID, action.tutor.id, { favorite: action.favorite })
        .then(() => {
          setTutors(
            tutors.map((tutor) => {
              if (tutor.id === selectedTutor.id) {
                let tutorInfo = selectedTutor.tutorInfo || {};
                const tutees = tutorInfo.tutees ? { ...tutorInfo.tutees } : {};
                tutees[props.userID] = { favorite: action.favorite };
                tutorInfo = { ...tutorInfo, tutees };
                return { ...tutor, tutorInfo };
              }
              return tutor;
            })
          );
        })
        .catch(console.log);
    }
  };

  const executeAction = (action?: SelectAction) => {
    if (action) {
      switch (action.type) {
        case "booking":
          onBooking(action);
          return true;
        case "favorite":
          onLikeTutor(action);
          return true;
        case "sendMessage":
          onSendMessage(action);
          return true;
      }
    }
    return false;
  };

  const onLoginSuccessPopUp = () => {
    setAction(undefined);
    if (!executeAction(action)) {
      props.history.push("/");
    }
  };

  const onSignUpSuccessPopUp = () => {
    setAction(undefined);
    executeAction(action);
  };

  const closePopUp = () => {
    setAction(undefined);
  };
  const onAction = (action: SelectAction) => {
    if (props.userID) {
      executeAction(action);
    } else {
      setTimeout(() => {
        setAction(action);
      }, 300);
    }
  };

  useEffect(() => {
    if (props.useSearchedTutors) {
      setTutors(props.searchedTutors);
      props.dispatch(setTutorSearch([]));
      setLoading(false);
      return;
    }

    if (searchCount > 0) {
      analytics.sendSearchBarRedo();
    }
    setSearchCount(searchCount + 1);

    if (filter.subject.name === "Orientação/Teste Vocacional") {
      getTutorsByArea(
        filter.subject.area || "Mentoria de Carreira",
        props.userID
      )
        .then((tutors: any) => {
          if (tutors.length > 0) {
            analytics.sendSearchResultsShow(
              filter.subject.name,
              filter.level.name
            );
            analytics.sendTutorMatch(tutors[0].name);
          } else {
            analytics.sendSearchNoResultsShow(
              filter.subject.name,
              filter.level.name
            );
          }
          setTutors(tutors);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getTutors2(props.userID, {
        include: ["rating", "superTutor", "favorite"],
        limit: 11,
        subject: filter.subject.name,
        level: filter.level.name,
      })
        .then((res) => filterTutors(res.tutors, filter))
        .then((tutors) => {
          if (tutors.length > 0) {
            analytics.sendSearchResultsShow(
              filter.subject.name,
              filter.level.name
            );
            analytics.sendTutorMatch(tutors[0].name);
          } else {
            analytics.sendSearchNoResultsShow(
              filter.subject.name,
              filter.level.name
            );
          }
          setTutors(tutors);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter.subject.name, filter.level.name]);

  const getMoreTutors = () => {
    getTutors2(props.userID, {
      include: ["rating", "favorite"],
      subject: filter.subject.name,
      level: filter.level.name,
      limit: 10,
      offset: tutors.length,
    })
      .then((res) => filterTutors(res.tutors, filter))
      .then((moreTutors) => {
        if (moreTutors.length == 0) {
          setHasMore(false);
        }
        let newTutors = [...tutors, ...moreTutors];
        //delete duplicates
        newTutors = newTutors.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
        );
        setTutors(newTutors);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        //setLoading(false);
      });
  };

  const onSelectTutor = (tutor: Tutor, superTutor: boolean) => {
    props.dispatch(isSuperTutor(superTutor));
    props.dispatch(selectTutor(tutor));
    props.dispatch(setTutorSearch(tutors));
    let subject = props.selectedSubject || defaultFilter.subject;
    let subjectLevel = props.selectedLevel || defaultFilter.level;
    const now = new Date();

    var eventFilter: EventsFilter = {
      tutorId: tutor.id,
      tuteeId: props.userID,
      periodStart: now.toISOString(),
      periodEnd: undefined,
      limit: 1,
    };

    getEvents(eventFilter).then((result: any) => {
      const ev = (result && result.length > 0 && result[0]) || null;
      if (
        ev &&
        props.location &&
        props.location.state &&
        props.location.state.subjectTestID
      ) {
        updateSubjectTest(
          props.location.state.subjectTestID,
          "SEND_TO_TUTOR",
          tutor.id
        );
        sendTutorSubjectTestNotification(tutor.id, props.userID);
        let message =
          "Olá " +
          tutor.name.split(" ")[0] +
          ", acabei de fazer um simulado e queria a sua ajuda para revisar!";
        props.history.push({
          pathname: "/messages/" + tutor.id,
          state: {
            shouldGoBack: false,
            messages: message,
            subjectTest: true,
          },
        });
      } else {
        props.history.push({
          pathname: "/TutorProfile",
          state: {
            useTutorSearchBack: true,
            subject: subject,
            subjectLevel: subjectLevel,
            subjectTestID:
              props.location &&
              props.location.state &&
              props.location.state.subjectTestID,
          },
        });
      }
    });
  };

  const filterTutors = (tutors: Tutor[], filter) => {
    return tutors;
    // return tutors.filter((tutor) => {
    //   const matchLevel =
    //     tutor.tutorInfo &&
    //     tutor.tutorInfo.subjectLevels &&
    //     tutor.tutorInfo.subjectLevels.some(
    //       (subjectLevel) => subjectLevel.name === filter.level.name
    //     );
    //   const matchSubject =
    //     tutor.tutorInfo &&
    //     tutor.tutorInfo.subjects &&
    //     tutor.tutorInfo.subjects.some(
    //       (subject) => subject.name === filter.subject.name
    //     );
    //   return matchLevel && matchSubject;
    // });
  };

  if (
    (loading || !played) &&
    showLottie &&
    props.location.pathname == "/landing/tutors"
  ) {
    return (
      <TutorMatchSearching
        searching={true}
        onVideoEnded={() => setPlayed(true)}
        loadingDescription={"Procurando seu Professor"}
      />
    );
  }

  if (loading) {
    return (
      <TutorMatchLoadingState
        filter={filter}
        onFilterChanged={(newFilter) => setFilter(newFilter)}
      />
    );
  }

  if (!loading && tutors.length == 0) {
    return (
      <TutorMatchNoMatch
        filter={filter}
        onFilterChanged={(newFilter) => setFilter(newFilter)}
      />
    );
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <FlatList
          ListHeaderComponent={() => (
            <HeaderMatchList
              tutorList={tutors.slice(0, 1)}
              onSelectTutor={(tutor) => onSelectTutor(tutor, true)}
              filter={filter}
              onFilterChanged={(newFilter) => setFilter(newFilter)}
              onChatPress={() => {
                analytics.sendTutorMatchSendMessage();
                onAction({ type: "sendMessage", tutor: tutors[0] });
              }}
              onLikePress={(favorite) => {
                if (favorite) {
                  analytics.sendTutorFavorite(tutors[0].name);
                }
                onAction({ type: "favorite", tutor: tutors[0], favorite });
              }}
              onShowMore={showMore}
            />
          )}
          columnWrapperStyle={{
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
          windowSize={1}
          initialNumToRender={1}
          data={tutors.slice(1)}
          renderItem={
            showMore
              ? ({ item }) => (
                  <TutorCard
                    tutor={item}
                    onSelectTutor={(tutor) => onSelectTutor(tutor, true)}
                    onChatPress={() => {
                      analytics.sendTutorMatchSendMessage();
                      onAction({ type: "sendMessage", tutor: item });
                    }}
                    onLikePress={(favorite) => {
                      if (favorite) {
                        analytics.sendTutorFavorite(item.name);
                      }
                      onAction({ type: "favorite", tutor: item, favorite });
                    }}
                  />
                )
              : null
          }
          keyExtractor={(item) => item.id}
          ListFooterComponent={
            <Footer
              hasMore={hasMore}
              onPress={() => {
                setShowMore(true);
                getMoreTutors();
              }}
            />
          }
          numColumns={2}
        />
        <SignUpPopUp
          show={!!action}
          onLoginSuccess={onLoginSuccessPopUp}
          onSignUpSuccess={onSignUpSuccessPopUp}
          isLogin={false}
          close={closePopUp}
          history={props.history}
        />
      </SafeAreaView>
      {props.location &&
        props.location.state &&
        props.location.state.showFooter && (
          <SafeAreaView>
            <BottomFooter />
          </SafeAreaView>
        )}
      <AnimatedPopUp show={showFreeCreditPopUp}>
        <FreeCreditScreen
          buttonText="Entendi"
          onConfirm={() => {
            props.setViewedFreeCreditPopUp(true);
            setShowFreeCreditPopUp(false);
          }}
          buttonColorScheme={"sucess"}
        />
      </AnimatedPopUp>
    </>
  );
}

function Footer(props: { hasMore?: boolean; onPress?: () => void }) {
  return (
    <View style={{ alignItems: "center" }}>
      {props.hasMore && (
        <TouchableOpacity
          onPress={props.onPress}
          style={{
            borderRadius: 6,
            borderColor: "#3B3F50",
            borderWidth: 2,
            width: 160,
            height: 48,
            alignContent: "center",
            justifyContent: "center",
            margin: 56,
          }}
        >
          <Text
            style={[
              StyleUtils.font_size_16,
              StyleUtils.font_cera_bold,
              { textAlign: "center" },
            ]}
          >
            Mostrar Mais
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
}

export default connect(mapStateToProps, dispatchToProps)(TutorMatchList);

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.flatten(StyleUtils.screen_default),
  },
  textBoldWhite: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    textAlign: "center",
  },
  textWhite: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
});
