import React, { Component } from "react";
import {
  FlatList,
  StyleSheet,
  View,
  ScrollView,
  Text,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import {
  selectImagePopup,
  selectSubjectTest,
  setOnboardingData,
} from "../Actions";
import ButtonElement from "../Elements/ButtonElement";
import TitleWithNavigation from "../Elements/TitleWithNavigation";
import SubjectTest, {
  subjectTestState,
  SubjectTestState,
  subjectTestStats,
} from "../Models/SubjectTest";
import { Route } from "../Router";
import { updateSubjectTest, updateTestQuestion } from "../Server";
import StyleUtils, {
  COLOR_BG_DARK,
  COLOR_BG_LIGHT,
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_SUCCESS,
  COLOR_TXT_NORMAL,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import CorrectPopup from "../Exercises/CorrectPopup";
import SubjectTestExerciseCard from "./SubjectTestExerciseCard";
import { itemState } from "../Exercises/Models/AssignmentUtils";
import Timer from "../Elements/Timer";
import Clock from "../../assets/clockGrey.svg";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import SubjectTestResult from "./SubjectTestResult";
import { analytics } from "../Analytics";

type Props = {
  selectImagePopup;
  reviewMode?: boolean;
  tutorInfo;
  user;
  history: any;
  match: any;
  mobile?: boolean;
  dispatch;
  selectedSubjectTest;
  onUpdate?;
  setOnboardingData;
  talkspaceOnboarding;
};

type State = {
  subjectTest: SubjectTest;
  currentVisibleItem;
  popup?: boolean;
  answerPopup?: boolean;
  correctPopup?: boolean;
  timeExpiredPopup: boolean;
  testDuration: number;
};

export enum AnswerState {
  Unanswered,
  Answered,
  Wrong,
  Correct,
}

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    selectedSubjectTest: state.selRed.selectedSubjectTest,
    talkspaceOnboarding: state.userRed.talkspaceOnboarding,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectImagePopup: (imageURL) => dispatch(selectImagePopup(imageURL)),
    selectSubjectTest: (subjectTest) =>
      dispatch(selectSubjectTest(subjectTest)),
    setOnboardingData: (data) => dispatch(setOnboardingData(data)),
    dispatch: dispatch,
  };
}

class SubjectTestExercises extends Component<Props, State> {
  exerciseListRef;
  constructor(props) {
    super(props);
    this.state = {
      currentVisibleItem: 0,
      subjectTest: props.selectedSubjectTest,
      popup: true,
      timeExpiredPopup: false,
      testDuration: 0,
    };
    this.props.selectImagePopup(null);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.testDuration !== this.state.testDuration) {
      return false;
    }
    return true;
  }

  questionDotStyle = (answerState: AnswerState, current: boolean) => {
    switch (answerState) {
      case AnswerState.Unanswered:
        return current ? style.card_unanswered : style.dot_unanswered;
      case AnswerState.Answered:
        return style.card_answered;
      case AnswerState.Wrong:
        return style.card_wrong;
      case AnswerState.Correct:
        return style.card_correct;
    }
  };

  questionDots = () => {
    const width = this.state.subjectTest.testQuestions!.length * 38;

    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View
          style={[
            {
              backgroundColor: COLOR_BG_LIGHT,
              height: 1,
              width: width,
              marginTop: 8,
              position: "absolute",
              alignSelf: "center",
              overflow: "visible",
            },
          ]}
        />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            overflow: "visible",
            width: width,
          }}
        >
          {this.state.subjectTest.testQuestions &&
            this.state.subjectTest.testQuestions.map((assignment, index) => {
              const answerState = itemState(
                assignment,
                subjectTestState(this.state.subjectTest) >
                  SubjectTestState.SUBMITTED
              );
              return (
                <View
                  key={index}
                  style={[
                    {
                      width: 16,
                      height: 16,
                      borderWidth:
                        this.state.currentVisibleItem === index ? 1 : 0,
                      borderRadius: 8,
                      justifyContent: "center",
                      borderColor: COLOR_BG_DARK,
                    },
                  ]}
                >
                  <View
                    style={[
                      this.questionDotStyle(
                        answerState,
                        this.state.currentVisibleItem === index
                      ),
                      {
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        alignSelf: "center",
                      },
                    ]}
                  />
                </View>
              );
            })}
        </View>
      </ScrollView>
    );
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    if (viewableItems && viewableItems.length > 0) {
      this.setState({
        currentVisibleItem: viewableItems[0].index,
      });
    }
  };

  subjectTest() {
    const stats = subjectTestStats(this.state.subjectTest);
    const hmState = subjectTestState(this.state.subjectTest);
    const isTutor = !!this.props.tutorInfo;
    if (hmState == SubjectTestState.CORRECTED && !isTutor) {
      this.complete();
    }
    const renderNativeItem = (assignment, index) => {
      return (
        <>
          <SubjectTestExerciseCard
            subjectTest={this.state.subjectTest}
            assignment={assignment}
            assignmentIndex={index}
            tutorInfo={this.props.tutorInfo}
            selectImagePopup={this.props.selectImagePopup}
            reviewMode={hmState >= SubjectTestState.ANSWERED}
            updateAssignment={(curAssignment) => {
              const subjectTest = { ...this.state.subjectTest };
              const assignments = this.state.subjectTest.testQuestions || [];
              assignments[index] = curAssignment;
              subjectTest.testQuestions = assignments;
              this.setState({
                subjectTest: subjectTest,
              });
              if (this.props.onUpdate) this.props.onUpdate();
            }}
          />
          {index === this.state.subjectTest.testQuestions!.length - 1 &&
            !isTutor &&
            !this.props.reviewMode &&
            hmState == SubjectTestState.SUBMITTED &&
            stats.pending != 0 && (
              <ButtonElement
                colorScheme="primary"
                text="Finalizar"
                onPress={() => this.submit()}
                disabled
                styte={[
                  StyleUtils.shape_btn_default,
                  { margin: 28, maxWidth: 564 },
                ]}
              />
            )}
        </>
      );
    };

    return (
      <View style={{ flex: 1 }}>
        <TitleWithNavigation
          title={
            "Simulado " +
            (this.state.subjectTest &&
            this.state.subjectTest.subject &&
            this.state.subjectTest.subject.name
              ? this.state.subjectTest.subject.name
              : "")
          }
          onGoBackPress={() => this.props.history.push("/subjecttests")}
          mobile={this.props.mobile}
          options={{ colorStyle: "white" }}
        >
          {!isTutor &&
            !this.props.reviewMode &&
            hmState < SubjectTestState.ANSWERED && (
              <Timer
                initialTime={
                  parseInt(this.props.selectedSubjectTest.totalMinutes) * 60
                }
                ClockIcon={Clock}
                style={style.clock}
                onEnded={() =>
                  this.setState({
                    timeExpiredPopup: true,
                  })
                }
                onChange={(time) => {
                  this.setState({
                    testDuration:
                      this.props.selectedSubjectTest.totalMinutes * 60 - time,
                  });
                }}
              />
            )}

          {this.state.subjectTest.testQuestions!.length > 1 && (
            <View
              style={{
                marginVertical: 16,
                height: 16,
                alignSelf: "center",
              }}
            >
              {this.questionDots()}
            </View>
          )}
        </TitleWithNavigation>
        <FlatList
          ref={(ref) => (this.exerciseListRef = ref)}
          onViewableItemsChanged={this.onViewableItemsChanged}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 50,
          }}
          extraData={this.state}
          data={this.state.subjectTest.testQuestions}
          renderItem={({ item, index }) => renderNativeItem(item, index)}
          style={{
            paddingBottom: 24,
            flex: 1,
          }}
        />
        {!isTutor &&
          !this.props.reviewMode &&
          hmState == SubjectTestState.SUBMITTED &&
          stats.pending == 0 && (
            <ButtonElement
              colorScheme="primary"
              text="Finalizar"
              onPress={() => this.submit()}
              styte={[
                StyleUtils.shape_btn_default,
                { margin: 28, maxWidth: 564 },
              ]}
            />
          )}
        {!isTutor &&
          !this.props.reviewMode &&
          hmState > SubjectTestState.SUBMITTED &&
          stats.pending == 0 && (
            <>
              <ButtonElement
                colorScheme="primary"
                text="Revisar com um professor"
                onPress={() => {
                  analytics.sendSendSubjectTestTutor();
                  this.props.setOnboardingData({
                    subject: this.state.subjectTest.subject,
                    level: {
                      id: "3de1f15d-3725-4254-919d-ac43c21e3496",
                      name: "Ensino Médio",
                      index: 3,
                    },
                  });
                  if (this.props.talkspaceOnboarding) {
                    this.props.history.push({
                      pathname: "/messages/" + this.props.user.counselor.id,
                      state: {
                        messages: `Acabei de fazer um simulado de ${this.state.subjectTest.subject.name} e gostaria de agendar uma conversa com um professor para me ajudar!`,
                      },
                    });
                  } else {
                    this.props.history.push({
                      pathname: "/landing/tutors",
                      state: {
                        showFooter: true,
                        subjectTestID: this.state.subjectTest.id,
                      },
                    });
                  }
                }}
                styte={[
                  StyleUtils.shape_card_default,
                  { marginHorizontal: 16, marginTop: 28 },
                ]}
              />
              <TouchableOpacity
                onPress={() => {
                  analytics.sendEndSubjectTest();
                  this.props.history.push("/subjecttests");
                }}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Text
                  style={[
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_btn_regular,
                    StyleUtils.font_size_16,
                    {
                      textDecorationLine: "underline",
                      marginBottom: 30,
                      marginTop: 14,
                    },
                  ]}
                >
                  Finalizar
                </Text>
              </TouchableOpacity>
            </>
          )}

        {isTutor && hmState == SubjectTestState.ANSWERED && (
          <ButtonElement
            colorScheme="sucess"
            text="Finalizar Correção"
            onPress={() => this.setState({ correctPopup: true })}
            styte={[!this.props.mobile && { alignSelf: "center" }]}
          />
        )}

        <CorrectPopup
          visible={this.state.correctPopup}
          onExit={() => this.setState({ correctPopup: false })}
          onConfirm={() => this.correct()}
        />
      </View>
    );
  }

  render() {
    return (
      <View style={[StyleUtils.screen_default]}>
        <Route
          path={`${this.props.match.path}/result`}
          render={(props) => (
            <SubjectTestResult
              testDuration={this.state.testDuration}
              {...props}
            />
          )}
        />
        <Route
          exact
          path={this.props.match.path}
          render={() => {
            return this.subjectTest();
          }}
        />
        <TimeExpired
          show={this.state.timeExpiredPopup}
          onReturn={() => {
            analytics.sendSubjectTestTimeFinished();
            this.submit();
          }}
        />
      </View>
    );
  }
  submit() {
    analytics.sendSubmitSubjectTest();
    let id = this.state.subjectTest.id || "";
    if (this.state.subjectTest.id) {
      updateSubjectTest(id, "ANSWER")
        .then((subjectTest) => {
          selectSubjectTest(subjectTest);
          this.props.history.push({
            pathname: "/subjecttests/result",
            state: {
              subjectTestID: id,
              testDuration:
                this.state.testDuration === 0
                  ? this.state.subjectTest.totalMinutes
                  : this.state.testDuration,
            },
          });
        })
        .catch((reason) => console.log(reason));
    }
  }
  correct() {
    if (this.state.subjectTest.id) {
      if (this.state.subjectTest.testQuestions) {
        for (let i = 0; i < this.state.subjectTest.testQuestions.length; ++i) {
          if (!this.state.subjectTest.testQuestions[i].tutorCorrectionComment) {
            let id = this.state.subjectTest.testQuestions[i].id || "";
            updateTestQuestion(id, {
              tutorCorrectionComment: " ",
            }).then((assignment) => {
              console.log("corrected subject test");
            });
          }
        }
      }
      updateSubjectTest(this.state.subjectTest.id, "CORRECT")
        .then(this.props.history.push("/subjecttests"))
        .catch((reason) => console.log(reason));
    }
  }
  complete() {
    if (this.state.subjectTest.id) {
      updateSubjectTest(this.state.subjectTest.id, "COMPLETE")
        .then((subjectTest) => this.setState({ subjectTest: subjectTest }))
        .catch((reason) => console.log(reason));
    }
  }
}

export default connect(stateToProps, dispatchToProps)(SubjectTestExercises);

const style = StyleSheet.create({
  card_unanswered: {
    backgroundColor: COLOR_BG_DARK,
  },
  card_answered: {
    backgroundColor: COLOR_PRIMARY,
  },
  card_wrong: {
    backgroundColor: COLOR_DANGER,
  },
  card_correct: {
    backgroundColor: COLOR_SUCCESS,
  },
  text_unanswered: {
    color: COLOR_TXT_NORMAL,
  },
  text_answered: {
    color: COLOR_PRIMARY,
  },
  dot_unanswered: {
    backgroundColor: COLOR_BG_LIGHT,
  },
  clock: {
    position: "absolute",
    right: 20,
    top: 14,
  },
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    paddingVertical: 14,
  },
});

const TimeExpired = ({ show, onReturn }) => {
  return (
    <AnimatedPopUp show={show} bgColor={COLOR_WHITE}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_dark,
        ]}
      >
        Acabou o tempo!
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_light,
        ]}
      >
        Sem desanimar! É pra isso que{"\n"}estamos estudando!
      </Text>
      <ButtonElement
        colorScheme="primary"
        text="Ver resultado"
        onPress={onReturn}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
    </AnimatedPopUp>
  );
};
