export const defaultCareers = [
  { id: 0, name: "Administração e Contabilidade" },
  { id: 1, name: "Arquitetura e Eng. Civil" },
  { id: 2, name: "Direito" },
  { id: 3, name: "Economia e Setor Público" },
  { id: 4, name: "Computação e áreas relacionadas" },
  { id: 5, name: "Engenharias, exceto Civil e Computação" },
  { id: 6, name: "Esportes" },
  { id: 7, name: "Gastronomia" },
  { id: 8, name: "História, Geografia, e ciências humanas" },
  { id: 9, name: "Hotelaria e Turismo" },
  { id: 10, name: "Matemática e Estatística" },
  { id: 11, name: "Medicina e áreas da saúde" },
  { id: 12, name: "Moda, Design e Artes no Geral" },
  { id: 13, name: "Pedagogia e Licenciatura" },
  { id: 14, name: "Publicidade, Marketing e Comunicação" },
  { id: 15, name: "Química, Biologia, e outras ciências" },
];

export const defaultActivities = [
  { id: 0, name: "Acompanhar Economia e Política", question: 0 },
  { id: 1, name: "Acompanhar Tendências da Moda", question: 0 },
  { id: 2, name: "Analisar a História", question: 1 },
  { id: 3, name: "Analisar Estruturas Físicas e Construções", question: 1 },
  { id: 4, name: "Cálculos Financeiros", question: 4 },
  { id: 5, name: "Comunicação com várias pessoas", question: 6 },
  { id: 6, name: "Criar e Manufaturar Coisas", question: 2 },
  { id: 7, name: "Criar Programas de Computador", question: 8 },
  { id: 8, name: "Criar Projetos", question: 2 },
  { id: 9, name: "Cuidar de Pessoas e Animais", question: 5 },
  { id: 10, name: "Debater e Discutir", question: 6 },
  { id: 11, name: "Decisão sob Pressão", question: 8 },
  { id: 12, name: "Desenhar", question: 2 },
  { id: 13, name: "Eficiência de Processos", question: 1 },
  { id: 14, name: "Ensinar e Explicar", question: 6 },
  { id: 15, name: "Entender Funcionamento Máquinas", question: 1 },
  { id: 16, name: "Estudar a Sociedade", question: 3 },
  { id: 17, name: "Estudar Novas Línguas", question: 3 },
  { id: 18, name: "Falar em Público", question: 6 },
  { id: 19, name: "Fazer Pesquisa", question: 3 },
  { id: 20, name: "Gerenciar Pessoas", question: 5 },
  { id: 21, name: "Praticar Atividades Físicas", question: 7 },
  { id: 22, name: "Resolução de Problemas", question: 8 },
  { id: 23, name: "Trabalhar com Números", question: 4 },
];

export const defaultInterests = [
  { id: 0, name: "Arte e Beleza no Geral" },
  { id: 1, name: "Biologia" },
  { id: 2, name: "Botânica" },
  { id: 3, name: "Comida" },
  { id: 4, name: "Corpo Humano/Animal" },
  { id: 5, name: "Desenho" },
  { id: 6, name: "Economia e Política" },
  { id: 7, name: "Educação" },
  { id: 8, name: "Esportes" },
  { id: 9, name: "Estatística" },
  { id: 10, name: "Estruturas e Construções" },
  { id: 11, name: "Finanças" },
  { id: 12, name: "Física" },
  { id: 13, name: "Geografia" },
  { id: 14, name: "Gestão" },
  { id: 15, name: "História" },
  { id: 16, name: "Línguas Estrangeiras" },
  { id: 17, name: "Matemática" },
  { id: 18, name: "Mundo Corporativo" },
  { id: 19, name: "Mundo Escolar/Acadêmico" },
  { id: 20, name: "Programação/Linguagens de Programação" },
  { id: 21, name: "Química" },
  { id: 22, name: "Resolução Problemas" },
  { id: 23, name: "Sociologia" },
  { id: 24, name: "Tecnologia" },
  { id: 25, name: "Vestuário e Moda" },
];

export const defaultAbilities = [
  { id: 0, name: "Análise e Interpretação de Fatos" },
  { id: 1, name: "Argumentação" },
  { id: 2, name: "Bom Condicionamento Físico" },
  { id: 3, name: "Cálculo" },
  { id: 4, name: "Comunicação" },
  { id: 5, name: "Criatividade" },
  { id: 6, name: "Desenho" },
  { id: 7, name: "Destreza e Agilidade" },
  { id: 8, name: "Empatia com Pessoas" },
  { id: 9, name: "Habilidades com Atividades Manuais" },
  { id: 10, name: "Leitura e Interpretação de Texto" },
  { id: 11, name: "Pensamento Lógico e Estruturado" },
  { id: 12, name: "Persuasão e Negociação" },
  { id: 13, name: "Planejamento e Organização" },
  { id: 14, name: "Redação e Escrita" },
  { id: 15, name: "Resolução de Problemas" },
  { id: 16, name: "Trabalho em Equipe" },
];

export const defaultCareerActivities = [
  [20, 8, 4, 5, 18, 13, 0, 22, 23, 11],
  [8, 20, 19, 12, 1, 3, 22, 23, 5, 13, 6],
  [18, 19, 22, 5, 20, 0, 10, 16, 14],
  [19, 0, 4, 23, 8, 10, 2, 13, 5, 11, 22, 16],
  [8, 13, 19, 22, 23, 7, 15, 17],
  [4, 23, 8, 20, 22, 13, 15, 5, 6],
  [19, 21, 20, 11, 14],
  [20, 19, 1, 13, 11, 6, 16, 9],
  [19, 10, 2, 0, 17, 16, 14],
  [16, 1, 5, 13, 20, 21, 9, 17, 14],
  [19, 4, 23, 22, 7, 17],
  [22, 11, 13, 19, 5, 20, 15, 9, 14],
  [5, 1, 12, 19, 8, 2, 16, 6],
  [20, 5, 9, 19, 22, 11, 10, 14],
  [19, 23, 1, 5, 12],
  [19, 23, 15, 13, 22, 6],
];

export const defaultCareerInterests = [
  [11, 14, 17, 18],
  [5, 10, 12, 17],
  [6, 15, 16, 22, 23],
  [6, 9, 11, 13, 15, 17, 19, 23],
  [9, 17, 20, 22, 24],
  [9, 11, 12, 17, 18, 21, 22, 24],
  [4, 8, 14],
  [0, 3, 4, 21],
  [6, 13, 15, 16, 19, 23],
  [0, 2, 7, 14, 15, 16],
  [9, 17, 19, 20, 22, 24],
  [1, 2, 4, 21, 22],
  [0, 5, 15, 25],
  [7, 14, 19, 22],
  [0, 5, 9, 18, 24],
  [1, 2, 4, 9, 12, 19, 21],
];

export const defaultCareerAbilities = [
  [3, 4, 13, 16],
  [3, 6, 9, 13],
  [0, 1, 4, 10, 11, 12, 14],
  [0, 3, 10, 13],
  [3, 11, 15],
  [3, 11, 13, 15, 16],
  [2, 7, 13],
  [5, 7, 9, 13, 16],
  [0, 10, 14],
  [2, 4, 8],
  [0, 3, 11, 15],
  [2, 7, 8, 13, 16],
  [5, 6, 9],
  [4, 8, 9, 12, 13],
  [3, 4, 5],
  [0, 3, 10],
];
