import React, { Component } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StyleSheet,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import { analytics } from "../../Analytics";
import RatingStars from "../../Rating/RatingStars";
import StyleUtils, {
  SpcStyle,
  COLOR_PRIMARY,
  COLOR_TXT_DARK,
} from "../../Utils/StyleUtils";
import BackButton from "../../../assets/backButtonBorder.svg";
import SVGImage from "../../Utils/SVGImage";
import ButtonElement from "../../Elements/ButtonElement";
import RemoteConfigBridge from "../../RemoteConfig/RemoteConfigBridge";

type Props = {
  onContinue;
  commentDescription;
  options;
  onBack;
  stars;
  onSkip;
};

type State = {
  comment: string;
  rating;
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
  arr1;
  arr2;
  arr3;
  showSkip;
};

const stateToProps = (state) => {
  return {
    currentUser: state.userRed.user,
    selectedUser: state.selRed.selectedUser,
    tutorInfo: state.userRed.tutorInfo,
  };
};

const dispatchToProps = (dispatch) => ({});

class RatingComment extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      comment: "",
      selectedZero: null,
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      selectedFive: null,
      arr1: [],
      arr2: [],
      arr3: [],
      showSkip: false,
    };
    RemoteConfigBridge.remoteConfig("showSkipReview", (value) => {
      var valueBool = value == "1";
      this.setState({ showSkip: valueBool });
    });
  }
  private myScrollView: any;

  componentDidMount() {
    analytics.sendTutorRatingOpened();
    this.setState({
      arr1: this.props.options.slice(0, 2),
      arr2: this.props.options.slice(2, 4),
      arr3: this.props.options.slice(4, 6),
    });
  }

  setRating = (rating) => {
    this.setState({ rating: rating });
    if (rating === 5) {
      this.props.onContinue(rating);
    }
  };

  toggleSelectedLevel(item) {
    switch (parseInt(item.id)) {
      case 0:
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea;
    switch (parseInt(item.id)) {
      case 0:
        isSelectedArea = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedArea = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedArea = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedArea = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedArea = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedArea = this.state.selectedFive === item;
        break;
    }
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? SpcStyle.cellSelected : SpcStyle.cellUnselected,
          {
            marginRight: item.id % 2 ? 12 : 4,
            marginLeft: item.id % 2 ? 4 : 12,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text
          style={[
            StyleUtils.color_txt_dark,
            StyleUtils.font_btn_regular,
            StyleUtils.font_size_14,
            styles.optionsText,
            isSelectedArea && { color: COLOR_PRIMARY },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 8,
            paddingRight: 24,
            paddingTop: 16,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              analytics.sendTutorRatingSkipped();
              this.props.onBack();
            }}
          >
            <SVGImage source={<BackButton />} />
          </TouchableOpacity>

          {this.state.showSkip && (
            <View>
              <TouchableOpacity
                onPress={() => {
                  analytics.sendTutorRatingSkipped();
                  this.props.onSkip();
                }}
              >
                <Text
                  style={[
                    StyleUtils.color_txt_primary,
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_regular,
                    { textDecorationLine: "underline" },
                  ]}
                >
                  Pular
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={[StyleUtils.screen_default]}
          keyboardVerticalOffset={100}
        >
          <ScrollView
            style={{ flex: 1 }}
            ref={(component) => {
              this.myScrollView = component;
            }}
          >
            <View>
              <RatingStars
                setRating={this.setRating}
                numberOfStars={5}
                startStars={this.props.stars}
              />
              <Text
                style={[
                  { textAlign: "center", margin: "10%", marginTop: 32 },
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_btn_bold,
                  StyleUtils.font_size_16,
                ]}
              >
                Quais motivos te levaram a dar uma nota menor que 5 estrelas?
              </Text>
              <View style={{ flexDirection: "row" }}>
                {this.state.arr1.map((area) => this.renderLevelCell(area))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {this.state.arr2.map((area) => this.renderLevelCell(area))}
              </View>
              <View style={{ flexDirection: "row" }}>
                {this.state.arr3.map((area) => this.renderLevelCell(area))}
              </View>
              <Text
                style={[
                  {
                    marginBottom: 8,
                  },
                  StyleUtils.font_size_12,
                  StyleUtils.font_btn_regular,
                  StyleUtils.color_txt_dark,
                  { textAlign: "center", margin: "10%", alignItems: "center" },
                ]}
              >
                {this.props.commentDescription}
              </Text>
              <TextInput
                style={[
                  StyleUtils.color_txt_dark,
                  {
                    borderRadius: 10,
                    padding: 12,
                    width: 311,
                    height: 136,
                    textAlignVertical: "top",
                    borderColor: COLOR_TXT_DARK,
                    borderWidth: 1,
                    margin: "10%",
                    alignSelf: "center",
                  },
                ]}
                multiline={true}
                onChangeText={(text) => this.setState({ comment: text })}
                maxLength={255}
                onFocus={() =>
                  this.myScrollView.scrollTo({
                    x: 0,
                    y: 300,
                    animated: true,
                  })
                }
              />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
        <ButtonElement
          text="Próximo"
          colorScheme="primary"
          styte={{
            margin: "7%",
            borderRadius: 6,
            width: 311,
            alignSelf: "center",
          }}
          textStyle={StyleUtils.font_size_16}
          onPress={() => {
            if (
              this.state.selectedZero ||
              this.state.selectedOne ||
              this.state.selectedTwo ||
              this.state.selectedThree ||
              this.state.selectedFour ||
              this.state.selectedFive
            ) {
              let arr: string[] = [];
              if (this.state.selectedZero !== null) {
                arr.push(this.state.selectedZero);
              }
              if (this.state.selectedOne !== null) {
                arr.push(this.state.selectedOne);
              }
              if (this.state.selectedTwo !== null) {
                arr.push(this.state.selectedTwo);
              }
              if (this.state.selectedThree !== null) {
                arr.push(this.state.selectedThree);
              }
              if (this.state.selectedFour !== null) {
                arr.push(this.state.selectedFour);
              }
              if (this.state.selectedFive !== null) {
                arr.push(this.state.selectedFive);
              }
              let result = {
                options: arr,
                rating:
                  this.state.rating !== null
                    ? this.state.rating
                    : this.props.stars,
                comment: this.state.comment,
              };
              this.props.onContinue(result);
            }
          }}
          disabled={
            !this.state.selectedZero &&
            !this.state.selectedOne &&
            !this.state.selectedTwo &&
            !this.state.selectedThree &&
            !this.state.selectedFour &&
            !this.state.selectedFive
          }
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  optionsText: {
    textAlign: "center",
  },
  cellBase: {
    height: 48,
    width: "45%",
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 4,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
});

export default connect(stateToProps, dispatchToProps)(RatingComment);
