import React from "react";
import { Component } from "react";
import { Text, View } from "react-native";
import Rating from "../Models/Rating";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import StarMuted from "../../assets/starMuted.svg";
import StarYellow from "../../assets/starYellow.svg";
import { UserImage } from "../Elements/UserImages";

type Props = {
  rating: Rating;
  mobile?: boolean;
};

export default class CommentCard extends Component<Props, any> {
  render() {
    return (
      <>
        <View style={{ flexDirection: "row" }}>
          <UserImage
            style={{ marginLeft: 26, marginRight: 16, width: 50, height: 50 }}
            imageURL={
              this.props.rating &&
              this.props.rating.tutee &&
              this.props.rating.tutee.imageURL
            }
          />
          <View>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.color_txt_dark,
                StyleUtils.font_cera_bold,
                {
                  paddingTop: 4,
                },
              ]}
            >
              {!!this.props.rating &&
                !!this.props.rating.tutee &&
                this.props.rating.tutee.name}
            </Text>
            <View style={{ flexDirection: "row" }}>
              {[...Array(5)].map((v, index) => {
                return (
                  <SVGImage
                    key={index}
                    source={
                      (this.props.rating.rating || 0) >= index + 0.5 ? (
                        <StarYellow />
                      ) : (
                        <StarMuted />
                      )
                    }
                    style={{
                      paddingTop: 4,
                      paddingRight: 2,
                    }}
                  />
                );
              })}
            </View>
          </View>
        </View>

        <Text
          style={[
            StyleUtils.font_size_16,
            StyleUtils.color_txt_dark,
            StyleUtils.font_cera_regular,
            {
              marginLeft: 26,
              marginRight: 22,
              marginTop: 16,
              marginBottom: 32,
            },
          ]}
        >
          {this.props.rating.comment}
        </Text>
        <View
          style={[
            {
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
              marginLeft: 26,
              marginRight: 22,
              marginBottom: 32,
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
            },
          ]}
        />
      </>
    );
  }
}
