import React, { useState, useEffect } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  ScrollView,
  Linking,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "../../assets/backButtonBorder.svg";
import { bookingInfo, priceInfo, setTuteeInfo } from "../Actions";
import { UserImage } from "../Elements/UserImages";
import { Tutor } from "../Models/TutorInfo";
import {
  bookTutor,
  chooseTutor,
  getUserPersonalInfo,
  updateUserPersonalInfo,
} from "../Server";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_DARK,
  COLOR_WHITE,
  COLOR_SUCCESS,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import * as Utils from "../Utils/Utils";
import { userShortName } from "../Utils/Utils";
import TuteeInfo from "../Models/TuteeInfo";
import { CLASS_MINUTES } from "../Booking/Booking";
import { analytics } from "../Analytics";
import RequestWhatsappPopup from "../Popups/RequestWhatsappPopup";
import ConfirmTokenPopup from "../Popups/ConfirmTokenPopup";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";

type Props = {
  history;
  lessonInfo: { selectedDate; selectedTutor: Tutor; endDate };
  selectedTutor: Tutor;
  dispatch;
  userID: string;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  mobile;
  location;
  onBack?;
  onBookingFinished?;
  subject?;
  subjectLevel?;
  bookingInfo;
};

function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
    userID: state.userRed.user.id,
    lessonInfo: state.selRed.lessonInfo,
    mobile: state.layoutRed.mobile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    priceInfo: (event) => dispatch(priceInfo(event)),
    bookingInfo: (event) => dispatch(bookingInfo(event)),
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch: dispatch,
  };
}
const divider = (short: boolean, flex?: number) => (
  <View
    style={{
      borderBottomColor: COLOR_MUTED,
      borderBottomWidth: 1,
      flex: flex === 0 ? 0 : 1,
      justifyContent: "center",
      marginHorizontal: short ? 24 : 0,
    }}
  />
);

const FreeSessionConfirmationPage = (props: Props) => {
  const [requestWhatsapp, setRequestWhatsapp] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);
  const [tokenPopup, setTokenPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifiedPhone, setVerifiedPhone] = useState(false);

  useEffect(() => {
    RemoteConfigBridge.remoteConfig("requestWhatsapp", (value) => {
      var valueBool = value == "1";
      setRequestWhatsapp(valueBool);
    });
    getUserPersonalInfo(props.userID).then((data) => {
      if (data) {
        if (data.guardianPhoneVerified) {
          setVerifiedPhone(true);
        }
      }
    });
  }, []);

  let myScrollView: any;

  const handleCompleteSessionPress = () => {
    analytics.sendScheduleFullSession();
    let endDate = new Date(props.lessonInfo.selectedDate);
    const event = {
      ...props.lessonInfo,
      endDate: endDate.setMinutes(endDate.getMinutes() + CLASS_MINUTES),
    };
    props.bookingInfo(event);

    props.history.push({
      pathname: "/ConfirmationPage",
      state: {},
    });
  };

  const handleConfirmFreeSession = () => {
    analytics.sendClassScheduled();
    analytics.sendScheduleFirstSessionFree();
    const date = new Date(props.lessonInfo.selectedDate);
    const endDate = new Date(props.lessonInfo.endDate);
    let selectedDate = Utils.convertToBrasiliaTime(
      props.lessonInfo.selectedDate
    );

    const subject = "";
    const subjectLevel = "";
    bookTutor(
      props.userID,
      props.selectedTutor.id,
      date,
      endDate,
      subject,
      subjectLevel,
      "free_session"
    )
      .then(() => {
        return chooseTutor(props.userID, props.selectedTutor.email);
      })
      .then((tuteeInfo) => {
        props.setTuteeInfo(tuteeInfo);
        props.history.push({
          pathname: "/messages/" + props.selectedTutor.id,
          state: {
            messages: `Olá ${
              props.selectedTutor.name
            }, agendei uma sessão introdutória de ${
              props.selectedTutor.tutorInfo?.firstSessionFreeDuration
            } minutos para conversarmos, na ${Utils.dateFormatLong(
              selectedDate
            )} às ${Utils.dateFormatHHhmm(
              selectedDate
            )}, horário de Brasília. Até mais!`,
            schedule: true,
          },
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fullClassEndDate = new Date(props.lessonInfo.selectedDate).setMinutes(
    props.lessonInfo.selectedDate.getMinutes() + CLASS_MINUTES
  );

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS == "ios" ? "padding" : "height"}
        style={{
          flex: 10,
          backgroundColor:
            Platform.OS === "ios" && (tokenPopup || whatsappPopup)
              ? "rgba(0, 0, 0, 0.5)"
              : COLOR_WHITE,
        }}
      >
        <ScrollView
          ref={(component) => {
            myScrollView = component;
          }}
        >
          <View
            style={{
              width: "100%",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                margin: 16,
              }}
            >
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  props.history.replace("/booking", {
                    source: "free_session",
                  });
                }}
              >
                <SVGImage source={<BackButton />} />
              </TouchableOpacity>
            </View>
            {divider(false)}
          </View>
          <View>
            <TouchableOpacity activeOpacity={1.0}>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_22,
                  {
                    marginLeft: 26,
                    marginTop: 25,
                    marginBottom: 32,
                    color: COLOR_TXT_DARK,
                  },
                ]}
              >
                Confirme sua sessão grátis!
              </Text>
            </TouchableOpacity>
            <View style={{ flexDirection: "row" }}>
              <UserImage
                style={{
                  marginLeft: 26,
                  marginRight: 16,
                  marginBottom: 33,
                  width: 80,
                  height: 92,
                }}
                imageURL={props.selectedTutor.imageURL}
              />
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    styles.textBoldBlack,
                    {
                      paddingTop: 4,
                      marginBottom: 8,
                    },
                  ]}
                >
                  {userShortName(props.selectedTutor.name)}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_regular,
                    {
                      color: COLOR_TXT_DARK,
                    },
                  ]}
                >
                  {`${Utils.dateFormatLongDateNoYear(
                    new Date(props.lessonInfo.selectedDate)
                  )}`}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_regular,
                    {
                      textDecorationStyle: "solid",
                      textDecorationLine: "line-through",
                      color: COLOR_TXT_DARK,
                    },
                  ]}
                >
                  {`Horário: ${Utils.dateFormatHHhmm(
                    new Date(props.lessonInfo.selectedDate)
                  )}-${Utils.dateFormatHHhmm(new Date(fullClassEndDate))}`}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_bold,
                    {
                      color: COLOR_SUCCESS,
                    },
                  ]}
                >
                  {`Horário: ${Utils.dateFormatHHhmm(
                    new Date(props.lessonInfo.selectedDate)
                  )}-${Utils.dateFormatHHhmm(
                    new Date(props.lessonInfo.endDate)
                  )}`}
                </Text>
              </View>
            </View>
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginHorizontal: 24,
                  marginBottom: 16,
                },
              ]}
            >
              <Text
                style={[StyleUtils.font_size_16, StyleUtils.font_cera_bold]}
              >
                Total
              </Text>
              <Text
                style={[StyleUtils.font_size_16, StyleUtils.font_cera_bold]}
              >
                Grátis
              </Text>
            </View>
          </View>
          <View>{divider(true)}</View>
          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.font_cera_regular,
              {
                color: COLOR_TXT_DARK,
                marginHorizontal: 24,
                marginTop: 16,
              },
            ]}
          >
            Ao clicar no botão abaixo, você concorda com os{" "}
            <Text
              style={{ textDecorationLine: "underline" }}
              onPress={() =>
                Linking.openURL("https://politicas.learning-machine.org/")
              }
            >
              termos de uso
            </Text>
            do produto.
          </Text>
        </ScrollView>
      </KeyboardAvoidingView>
      <View>
        {divider(false)}
        <TouchableOpacity
          style={[
            {
              margin: 24,
              backgroundColor: COLOR_SUCCESS,
            },
            StyleUtils.shape_btn_small,
          ]}
          onPress={() => {
            if (requestWhatsapp && !verifiedPhone) setWhatsappPopup(true);
            else handleConfirmFreeSession();
          }}
        >
          <Text
            style={[
              { textAlign: "center", paddingVertical: 14 },
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_white,
            ]}
          >
            {`Agendar ${
              props.selectedTutor.tutorInfo!.firstSessionFreeDuration
            } minutos grátis`}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={handleCompleteSessionPress}
          style={{ alignSelf: "center", marginBottom: 27 }}
        >
          <Text
            style={[
              StyleUtils.font_size_16,
              StyleUtils.font_cera_medium,
              StyleUtils.color_txt_dark,
              { textDecorationLine: "underline" },
            ]}
          >
            Agendar sessão completa
          </Text>
        </TouchableOpacity>
      </View>
      {Platform.OS === "ios" ? (
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === "ios" ? "padding" : undefined}
          keyboardVerticalOffset={Platform.OS === "ios" ? 20 : 0}
        >
          <RequestWhatsappPopup
            close={() => setWhatsappPopup(false)}
            show={whatsappPopup}
            onSuccess={(phoneNumber) => {
              setPhoneNumber(phoneNumber);
              setWhatsappPopup(false);
              setTokenPopup(true);
            }}
          />

          <ConfirmTokenPopup
            close={() => setTokenPopup(false)}
            show={tokenPopup}
            onSuccess={() => {
              updateUserPersonalInfo(
                props.userID,
                undefined,
                undefined,
                undefined,
                true
              );
              handleConfirmFreeSession();
            }}
            changeNumber={() => {
              setTokenPopup(false);
              setWhatsappPopup(true);
            }}
            phoneNumber={phoneNumber}
            userID={props.userID}
          />
        </KeyboardAvoidingView>
      ) : (
        <>
          <RequestWhatsappPopup
            close={() => setWhatsappPopup(false)}
            show={whatsappPopup}
            onSuccess={(phoneNumber) => {
              setPhoneNumber(phoneNumber);
              setWhatsappPopup(false);
              setTokenPopup(true);
            }}
          />

          <ConfirmTokenPopup
            close={() => setTokenPopup(false)}
            show={tokenPopup}
            onSuccess={() => {
              updateUserPersonalInfo(
                props.userID,
                undefined,
                undefined,
                undefined,
                true
              );
              handleConfirmFreeSession();
            }}
            changeNumber={() => {
              setTokenPopup(false);
              setWhatsappPopup(true);
            }}
            phoneNumber={phoneNumber}
            userID={props.userID}
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  textBoldBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FreeSessionConfirmationPage);
