import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Platform,
  StyleSheet,
  ScrollView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  SpcStyle,
  COLOR_MUTED,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  areas;
  onContinue;
  onBack;
  question;
  progress;
};

type State = {
  selectedSingle: string | null;
};

export default class OneRowTemplate extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedSingle: null,
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
    }
  }

  renderLevelCell = (item) => {
    const isSelectedLevel = this.state.selectedSingle === item;
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          isSelectedLevel ? SpcStyle.cellSelected : SpcStyle.cellUnselected,
          {
            height: 45,
            borderRadius: 6,
            alignItems: "center",
            flexDirection: "row",
            marginTop: 4,
            marginBottom: 4,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <View
          style={[
            SpcStyle.checkmarkBase,
            isSelectedLevel
              ? styles.checkmarkSelected
              : SpcStyle.checkmarkUnselected,
          ]}
        >
          {isSelectedLevel && (
            <View
              style={{
                height: "70%",
                aspectRatio: 1,
                borderRadius: 1000,
                backgroundColor: COLOR_PRIMARY,
              }}
            />
          )}
        </View>
        <Text
          style={[
            SpcStyle.cellText,
            isSelectedLevel && { color: COLOR_PRIMARY },
            {
              paddingTop: Platform.OS === "ios" ? 4 : 1,
            },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <ScrollView style={{ flex: 1 }}>
          <View style={[{ justifyContent: "center", width: "100%" }]}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                margin: 24,
              }}
            >
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  style={{ flexDirection: "row" }}
                  onPress={() => {
                    this.props.onBack();
                  }}
                >
                  <SVGImage source={<BackArrow />} />
                </TouchableOpacity>
              </View>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_16,
                ]}
              >
                Responda e ganhe uma aula grátis!
              </Text>
              <View style={{ flex: 1 }}></View>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
            }}
          />
          <View style={{ height: 4 }}>
            <View
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: COLOR_PRIMARY,
                width: this.props.progress,
              }}
            />
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 16,
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  marginTop: 15,
                  width: "100%",
                },
              ]}
            >
              {this.props.question}
            </Text>
            <View style={{ marginTop: 20 }}>
              {this.props.areas.map((level) => this.renderLevelCell(level))}
            </View>
          </View>
        </ScrollView>
        <View style={{ bottom: 0 }}>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (this.state.selectedSingle) {
                analytics.sendSurveyResponse(
                  this.props.question,
                  this.state.selectedSingle
                );
                this.props.onContinue(this.state.selectedSingle);
                this.setState({
                  selectedSingle: null,
                });
              }
            }}
            disabled={!this.state.selectedSingle}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  checkmarkSelected: {
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
    borderWidth: 1,
  },
});
