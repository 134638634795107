import React, { Component } from "react";
import { FlatList, StyleSheet, View, ScrollView } from "react-native";
import { connect } from "react-redux";
import { clearHomeworkNotification, selectImagePopup } from "../Actions";
import { analytics } from "../Analytics";
import ButtonElement from "../Elements/ButtonElement";
import TitleWithNavigation from "../Elements/TitleWithNavigation";
import Homework, {
  homeworkState,
  HomeworkState,
  homeworkStats,
} from "../Models/Homework";
import { Route } from "../Router";
import * as Server from "../Server";
import StyleUtils, {
  COLOR_BG_DARK,
  COLOR_BG_LIGHT,
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_SUCCESS,
  COLOR_TXT_NORMAL,
} from "../Utils/StyleUtils";
import AnswerPopup from "./AnswerPopup";
import CorrectPopup from "./CorrectPopup";
import ExerciseCard from "./ExerciseCard";
import ExercisesAnswered from "./ExercisesAnswered";
import ExercisesCorrected from "./ExercisesCorrected";
import { itemState } from "./Models/AssignmentUtils";

type Props = {
  selectImagePopup;
  reviewMode?: boolean;
  classMode?: boolean;
  tutorInfo;
  userID;
  history: any;
  match: any;
  mobile?: boolean;
  dispatch;
  selectedHomework;
  classHomework?;
  onUpdate?;
};

type State = {
  homework: Homework;
  currentVisibleItem;
  popup?: boolean;
  answerPopup?: boolean;
  correctPopup?: boolean;
};

export enum AnswerState {
  Unanswered,
  Answered,
  Wrong,
  Correct,
}

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    userID: state.userRed.user.id,
    tutorInfo: state.userRed.tutorInfo,
    selectedHomework: state.selRed.selectedHomework,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectImagePopup: (imageURL) => dispatch(selectImagePopup(imageURL)),
    dispatch: dispatch,
  };
}

class Exercises extends Component<Props, State> {
  exerciseListRef;
  constructor(props) {
    super(props);

    this.state = {
      currentVisibleItem: 0,
      homework: props.classHomework
        ? props.classHomework
        : props.selectedHomework,
      popup: true,
    };

    this.props.selectImagePopup(null);
  }

  componentDidMount() {
    this.props.dispatch(clearHomeworkNotification());
    if (
      !this.props.tutorInfo &&
      homeworkState(this.state.homework) == HomeworkState.SUBMITTED
    ) {
      analytics.sendLessonOpened();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      prevProps.classHomework != this.props.classHomework &&
      this.state.homework !== this.props.classHomework
    ) {
      console.log("exercises update");
      this.setState({
        homework: this.props.classHomework,
      });
    }
  }

  questionDotStyle = (answerState: AnswerState, current: boolean) => {
    switch (answerState) {
      case AnswerState.Unanswered:
        return current ? style.card_unanswered : style.dot_unanswered;
      case AnswerState.Answered:
        return style.card_answered;
      case AnswerState.Wrong:
        return style.card_wrong;
      case AnswerState.Correct:
        return style.card_correct;
    }
  };

  questionDots = () => {
    const width = this.state.homework.assignments!.length * 38;

    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View
          style={[
            {
              backgroundColor: COLOR_BG_LIGHT,
              height: 1,
              width: width,
              marginTop: 8,
              position: "absolute",
              alignSelf: "center",
              overflow: "visible",
            },
          ]}
        />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            overflow: "visible",
            width: width,
          }}
        >
          {this.state.homework.assignments &&
            this.state.homework.assignments.map((assignment, index) => {
              const answerState = itemState(assignment, this.props.reviewMode);
              return (
                <View
                  key={index}
                  style={[
                    {
                      width: 16,
                      height: 16,
                      borderWidth:
                        this.state.currentVisibleItem === index ? 1 : 0,
                      borderRadius: 8,
                      justifyContent: "center",
                      borderColor: COLOR_BG_DARK,
                    },
                  ]}
                >
                  <View
                    style={[
                      this.questionDotStyle(
                        answerState,
                        this.state.currentVisibleItem === index
                      ),
                      {
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        alignSelf: "center",
                      },
                    ]}
                  />
                </View>
              );
            })}
        </View>
      </ScrollView>
    );
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    if (viewableItems && viewableItems.length > 0) {
      this.setState({
        currentVisibleItem: viewableItems[0].index,
      });
    }
  };

  homework() {
    const stats = homeworkStats(this.state.homework);
    const hmState = homeworkState(this.state.homework);
    const isTutor = !!this.props.tutorInfo;
    if (hmState == HomeworkState.CORRECTED && !isTutor) {
      this.complete();
    }

    const renderNativeItem = (assignment, index) => {
      return (
        <>
          <ExerciseCard
            homework={this.state.homework}
            assignment={assignment}
            assignmentIndex={index}
            tutorInfo={this.props.tutorInfo}
            selectImagePopup={this.props.selectImagePopup}
            reviewMode={this.props.reviewMode}
            updateAssignment={(curAssignment) => {
              const homework = { ...this.state.homework };
              const assignments = this.state.homework.assignments || [];
              assignments[index] = curAssignment;
              homework.assignments = assignments;
              this.setState({
                homework: homework,
              });
              if (this.props.onUpdate) this.props.onUpdate();
            }}
          />
          {index === this.state.homework.assignments!.length - 1 &&
            !isTutor &&
            !this.props.reviewMode &&
            hmState == HomeworkState.SUBMITTED &&
            stats.pending != 0 && (
              <ButtonElement
                colorScheme="primary"
                text="Finalizar"
                onPress={() => this.setState({ answerPopup: true })}
                disabled
                styte={[
                  StyleUtils.shape_btn_default,
                  { margin: 28, maxWidth: 564 },
                ]}
              />
            )}
        </>
      );
    };

    return (
      <View style={{ flex: 1 }}>
        <TitleWithNavigation
          title={
            this.props.classMode
              ? "Exercicios da Aula"
              : this.state.homework.name
          }
          onGoBackPress={
            this.props.classMode ? undefined : () => this.props.history.goBack()
          }
          mobile={this.props.mobile}
          options={{ colorStyle: "white" }}
        >
          {this.state.homework.assignments!.length > 1 && (
            <View
              style={{
                marginVertical: 16,
                height: 16,
                alignSelf: "center",
              }}
            >
              {this.questionDots()}
            </View>
          )}
        </TitleWithNavigation>
        <FlatList
          ref={(ref) => (this.exerciseListRef = ref)}
          onViewableItemsChanged={this.onViewableItemsChanged}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 50,
          }}
          extraData={this.state}
          data={this.state.homework.assignments}
          renderItem={({ item, index }) => renderNativeItem(item, index)}
          style={{
            paddingBottom: 24,
            flex: 1,
          }}
        />
        {!isTutor &&
          !this.props.reviewMode &&
          hmState == HomeworkState.SUBMITTED &&
          stats.pending == 0 && (
            <ButtonElement
              colorScheme="primary"
              text="Finalizar"
              onPress={() => this.setState({ answerPopup: true })}
              styte={[
                StyleUtils.shape_btn_default,
                { margin: 28, maxWidth: 564 },
              ]}
            />
          )}
        <AnswerPopup
          visible={this.state.answerPopup}
          onExit={() => this.setState({ answerPopup: false })}
          onConfirm={() => this.submit()}
        />

        {isTutor && hmState == HomeworkState.ANSWERED && (
          <ButtonElement
            colorScheme="sucess"
            text="Finalizar Correção"
            onPress={() => this.setState({ correctPopup: true })}
            styte={[!this.props.mobile && { alignSelf: "center" }]}
          />
        )}

        <CorrectPopup
          visible={this.state.correctPopup}
          onExit={() => this.setState({ correctPopup: false })}
          onConfirm={() => this.correct()}
        />
      </View>
    );
  }

  render() {
    if (this.props.classMode) return this.homework();
    return (
      <View style={[StyleUtils.screen_default]}>
        <Route
          path={`${this.props.match.path}/answered`}
          render={(props) => (
            <ExercisesAnswered
              {...props}
              homework={this.state.homework}
              mobile={this.props.mobile}
            />
          )}
        />
        <Route
          path={`${this.props.match.path}/corrected`}
          render={(props) => (
            <ExercisesCorrected
              {...props}
              homework={this.state.homework}
              mobile={this.props.mobile}
            />
          )}
        />
        <Route
          exact
          path={this.props.match.path}
          render={() => {
            return this.homework();
          }}
        />
      </View>
    );
  }
  submit() {
    if (this.state.homework.id) {
      Server.putHomework(this.props.userID, this.state.homework.id, "ANSWER")
        .then(() => {
          if (!this.props.classMode)
            this.props.history.push("/exercises/exercise/answered");
          else {
            this.setState({ answerPopup: false });
            this.props.onUpdate();
          }
        })
        .catch((reason) => console.log(reason));
    }
  }
  correct() {
    if (this.state.homework.id) {
      if (this.state.homework.assignments) {
        for (let i = 0; i < this.state.homework.assignments.length; ++i) {
          if (!this.state.homework.assignments[i].tutorCorrectionComment) {
            Server.putAssignment(this.state.homework.assignments[i].id, {
              tutorCorrectionComment: " ",
            }).then((assignment) => {
              console.log("corrected assignment");
            });
          }
        }
      }
      Server.putHomework(this.props.userID, this.state.homework.id, "CORRECT")
        .then(this.props.history.push("/exercises/exercise/corrected"))
        .catch((reason) => console.log(reason));
    }
  }
  complete() {
    if (this.state.homework.id) {
      Server.putHomework(this.props.userID, this.state.homework.id, "COMPLETE")
        .then((homework) => this.setState({ homework: homework }))
        .then(() => analytics.sendLessonReviewed())
        .catch((reason) => console.log(reason));
    }
  }
}

export default connect(stateToProps, dispatchToProps)(Exercises);

const style = StyleSheet.create({
  card_unanswered: {
    backgroundColor: COLOR_BG_DARK,
  },
  card_answered: {
    backgroundColor: COLOR_PRIMARY,
  },
  card_wrong: {
    backgroundColor: COLOR_DANGER,
  },
  card_correct: {
    backgroundColor: COLOR_SUCCESS,
  },
  text_unanswered: {
    color: COLOR_TXT_NORMAL,
  },
  text_answered: {
    color: COLOR_PRIMARY,
  },
  dot_unanswered: {
    backgroundColor: COLOR_BG_LIGHT,
  },
});
