import React from "react";
import { Text, TouchableOpacity, View, Clipboard } from "react-native";
import SVGImage from "../Utils/SVGImage";
import Celebration from "../../assets/celebration.svg";
import FreeCreditCoupon from "../../assets/freeCreditCoupon.svg";
import Timer from "../Elements/Timer";
import ClockBlack from "../../assets/clockBlack.svg";
import ButtonElement from "../Elements/ButtonElement";
import CopyIcon from "../../assets/copyIcon.svg";
import StyleUtils, {
  COLOR_TXT_LIGHT,
  COLOR_TXT_NORMAL,
} from "../Utils/StyleUtils";
import { analytics } from "../Analytics";

type Props = {
  buttonText: string;
  onConfirm: () => void;
  buttonTextStyle?;
  buttonStyle?;
  buttonColorScheme:
    | "white"
    | "primary"
    | "sucess"
    | "danger_white"
    | undefined;
};

const FreeCreditScreen = ({
  buttonText,
  buttonTextStyle,
  buttonStyle,
  onConfirm,
  buttonColorScheme,
}: Props) => {
  const couponCode = "bemvindo";

  return (
    <View
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View
        style={{
          paddingTop: 100,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <SVGImage
          style={{ position: "absolute", left: -80, overflow: "hidden" }}
          source={<Celebration />}
        />
        <View
          style={[
            {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_28,
              {
                textAlign: "center",
              },
            ]}
          >
            {"Você ganhou\numa aula grátis!"}
          </Text>
        </View>
      </View>
      <Text
        style={[
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,

          { color: COLOR_TXT_LIGHT, textAlign: "center", marginTop: 8 },
        ]}
      >
        Use o cupom nos próximos 15 minutos,
      </Text>
      <Text
        style={[
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_16,

          {
            color: COLOR_TXT_NORMAL,
            textAlign: "center",
            marginBottom: 26,
          },
        ]}
      >
        {"e ganhe 100% de desconto\nna primeira aula."}
      </Text>
      <View style={{ position: "relative" }}>
        <SVGImage source={<FreeCreditCoupon />} />
        <TouchableOpacity
          style={{
            position: "absolute",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            top: 40,
          }}
          onPress={() => {
            analytics.sendCopiedFreeCoupon();
            Clipboard.setString(couponCode);
            alert("Cupom copiado com sucesso!");
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_22,
              { left: 8 },
            ]}
          >
            {couponCode}
          </Text>
          <SVGImage
            source={<CopyIcon />}
            style={{
              left: 18,
              top: 2,
            }}
          />
        </TouchableOpacity>
      </View>

      <Timer
        style={[
          StyleUtils.shape_card_default,
          {
            marginTop: 20,
            paddingRight: 16,
            paddingLeft: 8,
            borderColor: COLOR_TXT_LIGHT,
            borderWidth: 1,
          },
        ]}
        showRestantes
        initialTime={15 * 60}
        ClockIcon={ClockBlack}
      />
      <ButtonElement
        colorScheme={buttonColorScheme}
        text={buttonText}
        styte={[
          StyleUtils.shape_btn_default,
          { marginTop: 20, marginBottom: 20 },
          buttonStyle,
        ]}
        textStyle={[
          { marginHorizontal: 25 },
          buttonTextStyle,
          StyleUtils.font_size_18,
        ]}
        onPress={onConfirm}
      />
    </View>
  );
};

export default FreeCreditScreen;
