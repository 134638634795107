import { StyleSheet } from "react-native";
import {
  COLOR_DANGER,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_TRUE_WHITE,
  COLOR_TXT_NORMAL,
} from "../Utils/StyleUtils";

export default StyleSheet.create({
  /* BOX */
  uploadMainBox: {
    flex: 1,
    paddingBottom: 10,
  },
  uploadBoxWhiteBoard: {
    flex: 1,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderRadius: 10,
    padding: 10,
  },
  dropBoxWhiteBoard: {
    flex: 1,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: COLOR_PRIMARY,
    borderRadius: 9,
    margin: 0,
    textAlign: "center",
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  dropBoxWhiteBoardOnboarding: {
    flex: 1,
    shadowColor: "rgba(0, 0, 0, 0.14)",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    borderRadius: 8,
    margin: 0,
    textAlign: "center",
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  txtNormal: {
    fontFamily: "Helvetica",
    fontWeight: "normal",
    fontSize: 14,
    color: COLOR_TXT_NORMAL,
  },
  txtBold: {
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: 14,
    color: COLOR_TXT_NORMAL,
  },
  primaryButton: {
    minHeight: 40,
    minWidth: "48%",
    margin: "1%",
    justifyContent: "center",
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 100,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderWidth: 0,
  },

  /* ALERT */
  uploadError: {
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: COLOR_DANGER,
    borderRadius: 10,
    paddingTop: 1,
    paddingRight: 12,
    paddingBottom: 1,
    paddingLeft: 12,
  },

  uploadErrorText: {
    fontFamily: "Helvetica",
    fontSize: 14,
    color: COLOR_TRUE_WHITE,
    textAlign: "left",
  },

  /* LOADING */
  uploadProgress: {
    height: "78%",
    paddingTop: "20%",
  },
});
