import TestQuestion, { testQuestionState } from "./TestQuestion";
import TestQuestionState from "./TestQuestionState";
import { Subject } from "./Subjects";
import User from "./User";

interface SubjectTest {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  name?: string;
  tutor?: User;
  tutorID?: string;
  tutee?: User;
  tuteeID?: string;
  testQuestions?: TestQuestion[];
  answeredAt?: string;
  correctedAt?: string;
  completedAt?: string;
  score?: number;
  subject: Subject;
  subjectID: string;
  totalMinutes: string;
  firstTimeOpen: boolean;
}

export enum SubjectTestState {
  SUBMITTED,
  ANSWERED,
  CORRECTED,
  COMPLETED,
}

export interface SubjectTestStats {
  total: number;
  answered: number;
  reviewed: number;
  pending: number;
  pendingCorrection: number;
}

export function subjectTestState(subjectTest?: SubjectTest): SubjectTestState {
  if (!!subjectTest) {
    if (!!subjectTest.completedAt) {
      return SubjectTestState.COMPLETED;
    } else if (!!subjectTest.correctedAt) {
      return SubjectTestState.CORRECTED;
    } else if (!!subjectTest.answeredAt) {
      return SubjectTestState.ANSWERED;
    }
  }
  return SubjectTestState.SUBMITTED;
}

export function subjectTestStats(subjectTest?: SubjectTest): SubjectTestStats {
  let assignments: TestQuestion[] =
    (subjectTest && subjectTest.testQuestions) || [];
  let total = assignments.length;
  let answered = assignments.filter(
    (a) => testQuestionState(a) >= TestQuestionState.ANSWERED
  ).length;
  let reviewed = assignments.filter(
    (a) => testQuestionState(a) >= TestQuestionState.REVIEWED
  ).length;
  let pending = total - answered;
  let pendingCorrection = answered - reviewed;
  return {
    total,
    answered,
    reviewed,
    pending,
    pendingCorrection,
  };
}

export default SubjectTest;
