import React, { Component } from "react";
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  NativeModules,
  View,
  Linking,
  AppState,
} from "react-native";
import { connect } from "react-redux";
import { setOnboardingData } from "../../Actions";
import { analytics } from "../../Analytics";
import StyleUtils from "../../Utils/StyleUtils";
import ExerciseBenefits from "../ExerciseUpload/ExerciseBenefits";
import ExerciseUpload from "../ExerciseUpload/ExerciseUpload";
import SpcBenefits from "./SpcBenefits";
import SpcBeTeacher from "./SpcBeTeacher";
import SpcChooseTutor from "./SpcChooseTutor";
import SpcLevel from "./SpcLevel";
import SpcSubject from "./SpcSubject";
import SpcTutorFound from "./SpcTutorFound";
import VideoOnboarding from "../WhatsappOnboarding/VideoOnboarding";
import RemoteConfigBridge from "../../RemoteConfig/RemoteConfigBridge";
import TutorMatchExercise from "../../TutorList/TutorMatchExercise";
import SubInterest from "../SubjectsOnboarding/SubInterest";

type Props = {
  location;
  history;
  setOnboardingData;
  subjects;
  subjectLevels;
  photo;
  message;
};

type State = {
  componentStack;
  desiredSubject;
  desiredLevel;
  desiredTiming;
  desiredTutor;
  backHandler;
  translateX;
  isTransitioning;
  onboardingType;
  desiredArea;
  appState;
  showAlert;
};

const { width } = Dimensions.get("window");
const { remoteConfig } = NativeModules;

function mapStateToProps(state) {
  return {
    subjects: state.infoRed.subjects,
    subjectLevels: state.infoRed.subjectLevels,
    photo: state.searchFilterRed.photo,
    message: state.searchFilterRed.comment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOnboardingData: (data) => dispatch(setOnboardingData(data)),
  };
}

class SpcOnboarding extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);
    this.state = {
      componentStack:
        props.location.pathname === "/landing/subjects"
          ? [
              <SpcSubject
                subjects={this.props.subjects}
                onContinue={(info) => this.continueSubject(info)}
                onBack={() => this.popComponent(true)}
                onHasTeacher={() => this.continueTutor()}
                removeBackButton={true}
                headerText={"O que você quer aprender?"}
                isSubOnboarding={false}
              />,
            ]
          : [
              <SpcBenefits
                onContinue={() => this.continueBenefits()}
                onLogin={() => this.loginPressed()}
                onBeTeacher={() => this.beTeacherPressed()}
                history={this.props.history}
              />,
            ],
      desiredSubject: null,
      desiredLevel: null,
      desiredTiming: null,
      desiredTutor: null,
      desiredArea: null,
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      translateX: new Animated.Value(0),
      isTransitioning: false,
      onboardingType: 0,
      appState: AppState.currentState,
      showAlert: true,
    };

    if (props.location.pathname !== "/landing/subjects") {
      RemoteConfigBridge.remoteConfig("onboardingType", (value) => {
        this.setState({ onboardingType: parseInt(value) });
        switch (parseInt(value)) {
          case 1:
            this.setState({
              componentStack: [
                <ExerciseBenefits
                  onContinueProf={() => this.continueBenefits()}
                  onContinue={() => this.continueBenefits2(false)}
                  onLogin={() => this.loginPressed()}
                />,
              ],
            });
            break;
          case 2:
            this.setState({
              componentStack: [
                <VideoOnboarding
                  onContinue={() => {
                    Linking.openURL("https://wa.link/s458dn");
                  }}
                  onContinueProf={() => this.continueBenefits()}
                  onLogin={() => {}}
                  continueButtonText={"Fale com um orientador agora"}
                  continueProfButtonText={"Procure um professor"}
                  headerText={"Tire suas dúvidas\ncom professores reais"}
                  isWhatsappOnboarding={true}
                  isLowFrictionOnboarding={false}
                  history={this.props.history}
                />,
              ],
            });
            break;
          case 3:
            this.setState({
              componentStack: [
                <VideoOnboarding
                  onContinue={() => {
                    this.props.history.push("/quickclass/start");
                  }}
                  onContinueProf={() => this.continueBenefits()}
                  onLogin={() => {}}
                  continueButtonText={"Faça um teste grátis agora"}
                  continueProfButtonText={"Procure um professor"}
                  headerText={"Tire suas dúvidas\ncom professores reais"}
                  isWhatsappOnboarding={false}
                  isLowFrictionOnboarding={false}
                  history={this.props.history}
                />,
              ],
            });
            break;
          case 4:
            this.setState({
              componentStack: [
                <VideoOnboarding
                  onContinue={() => this.continueBenefits2(true)}
                  onContinueProf={() => {}}
                  onLogin={() => {}}
                  continueButtonText={"Começar"}
                  continueProfButtonText={""}
                  headerText={
                    "Seus problemas resolvidos\npelos melhores Tutores.\nAo vivo e na hora!"
                  }
                  isWhatsappOnboarding={false}
                  isLowFrictionOnboarding={true}
                  history={this.props.history}
                />,
              ],
            });
            break;
          case 5:
            this.setState({
              componentStack: [
                <VideoOnboarding
                  onContinue={() => this.continueSubBenefits()}
                  onContinueProf={() => {}}
                  onLogin={() => {}}
                  continueButtonText={"Começar"}
                  continueProfButtonText={""}
                  headerText={
                    "Seus problemas resolvidos\npelos melhores Tutores.\nAo vivo e na hora!"
                  }
                  isWhatsappOnboarding={false}
                  isLowFrictionOnboarding={true}
                  history={this.props.history}
                />,
              ],
            });
            break;
          default:
            this.setState({
              componentStack: [
                <SpcBenefits
                  onContinue={() => this.continueBenefits()}
                  onLogin={() => this.loginPressed()}
                  onBeTeacher={() => this.beTeacherPressed()}
                  history={this.props.history}
                />,
              ],
            });
        }
      });
    }
  }

  componentDidMount() {
    AppState.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    this.state.backHandler.remove();
    AppState.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
    }
    this.setState({ appState: nextAppState });
  };

  backPress = () => {
    if (this.state.componentStack.length > 1) {
      this.safelyPopComponent();
      return true;
    }
    return false;
  };

  executeWithTransition(method, back?: boolean, useFade?: boolean) {
    this.setState({ isTransitioning: true });
    if (useFade) {
      Animated.timing(this.fadeAnim, {
        toValue: 0,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        method();
        Animated.timing(this.fadeAnim, {
          toValue: 1,
          duration: 400,
          easing: Easing.cubic,
          useNativeDriver: true,
        }).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    } else {
      Animated.sequence([
        Animated.timing(this.state.translateX, {
          toValue: back ? -1 * width : width,
          duration: 1,
          useNativeDriver: true,
          delay: 500,
        }),
      ]).start(() => {
        method();
        Animated.sequence([
          Animated.timing(this.state.translateX, {
            toValue: 0,
            duration: 400,
            useNativeDriver: true,
          }),
        ]).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    }
  }

  pushComponent(component, useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack.concat(component);
        this.setState({
          componentStack: tempComponents,
        });
      },
      false,
      useFade
    );
  }

  safelyPopComponent(useFade?: boolean) {
    if (this.state.isTransitioning) return;
    this.popComponent(useFade);
  }

  popComponent(useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack;
        tempComponents.pop();
        this.setState({ componentStack: tempComponents });
      },
      true,
      useFade
    );
  }

  beTeacherPressed() {
    if (this.state.isTransitioning) return;

    analytics.sendOnboardingBeTeacher();
    this.pushComponent(
      <SpcBeTeacher onBack={() => this.safelyPopComponent(true)} />,
      true
    );
  }

  continueBenefits() {
    if (this.state.isTransitioning) return;
    this.pushComponent(
      <SpcSubject
        subjects={this.props.subjects}
        onContinue={(info) => this.continueSubject(info)}
        onBack={() => this.safelyPopComponent(true)}
        onHasTeacher={() => this.continueTutor()}
        headerText={"O que você quer aprender?"}
        isSubOnboarding={false}
      />,
      true
    );
  }

  continueBenefits2(isLowFriction) {
    if (this.state.isTransitioning) return;
    let headerText = isLowFriction
      ? "Mande uma foto do seu\nproblema!"
      : "Por favor,";
    let subHeaderText = isLowFriction
      ? "O que você precisa aprender?"
      : "envie a foto do exercício";
    analytics.sendExerciseOnboarding();
    this.pushComponent(
      <ExerciseUpload
        onBack={() => this.safelyPopComponent(true)}
        history={this.props.history}
        isLowFriction={isLowFriction}
        headerText={headerText}
        subHeaderText={subHeaderText}
        onContinue={() => this.continueBenefits()}
      />,
      true
    );
  }

  continueSubBenefits() {
    if (this.state.isTransitioning) return;
    const defaultAreas = [
      { id: "Línguas", name: "Línguas" },
      { id: "Matérias da Escola", name: "Matérias da Escola" },
      {
        id: "88055d6c-7ea9-4bc8-912c-fbdb27948106",
        name: "Orientação Vocacional",
      },
      { id: "cd91b5b7-1adb-488f-af87-a834c33c6dc7", name: "Redação" },
    ];
    this.pushComponent(
      <SpcSubject
        subjects={defaultAreas}
        onContinue={(info) => this.continueSubSubject(info)}
        onBack={() => this.safelyPopComponent(true)}
        onHasTeacher={() => this.continueTutor()}
        headerText={"Como podemos te ajudar?"}
        isSubOnboarding={true}
      />,
      true
    );
  }

  continueSubject(selectedSubject) {
    if (this.state.isTransitioning) return;
    this.setState({ desiredSubject: selectedSubject });
    analytics.sendOnboardingSubject(selectedSubject.name);
    this.pushComponent(
      <SpcLevel
        levels={this.props.subjectLevels}
        onContinue={(info) => this.continueLevel(info)}
        onBack={() => this.safelyPopComponent()}
        headerText={"Qual o nível das aulas\nque você busca?"}
      />
    );
  }

  continueSubSubject(selectedArea) {
    analytics.sendSubOnboardingArea(selectedArea.name);
    if (this.state.isTransitioning) return;
    this.setState({ desiredSubject: selectedArea });
    this.setState({ desiredArea: selectedArea });
    if (selectedArea.id === "Línguas") {
      const defaultLanguages = [
        { id: "a94effa1-748a-4feb-9db7-31acac555744", name: "Espanhol" },
        { id: "46392a73-efe7-4785-9238-e080ace66d2c", name: "Francês" },
        { id: "77336d25-8e11-4494-8c04-250b4ea45819", name: "Inglês" },
      ];
      this.pushComponent(
        <SpcSubject
          subjects={defaultLanguages}
          onContinue={(info) => this.continueSubLevel(info)}
          onBack={() => this.safelyPopComponent(true)}
          onHasTeacher={() => this.continueTutor()}
          headerText={"Qual língua?"}
          isSubOnboarding={true}
        />
      );
    } else if (selectedArea.id === "Matérias da Escola") {
      const defaultSubjects = [
        { id: "9d9064ed-ae90-4081-aeaf-5c1f6279a2f1", name: "Física" },
        { id: "3fce2130-453e-42b2-bd4c-b28c0da1f5f0", name: "Geografia" },
        { id: "34441773-71f0-4598-bd1e-b0a5a229cd73", name: "História" },
        { id: "4d81017f-737d-4549-965c-4efdac7bbe9d", name: "Matemática" },
        { id: "06e72385-4796-45f5-a007-e1f6202b3579", name: "Português" },
        { id: "901f25ec-287c-4764-adf4-20f1e1f0f94a", name: "Química" },
      ];
      this.pushComponent(
        <SpcSubject
          subjects={defaultSubjects}
          onContinue={(info) => this.continueSubLevel(info)}
          onBack={() => this.safelyPopComponent(true)}
          onHasTeacher={() => this.continueTutor()}
          headerText={"Qual a matéria?"}
          isSubOnboarding={true}
        />
      );
    } else if (selectedArea.name === "Orientação Vocacional") {
      const interestedAreas = [
        { id: "0", name: "Auto-conhecimento" },
        { id: "1", name: "Intercâmbio e Faculdades no Exterior" },
        { id: "2", name: "Motivação e Propósito" },
        { id: "3", name: "O que prestar no vestibular" },
        { id: "4", name: "Planejamento de Vida" },
        { id: "5", name: "Profissões e trabalhos" },
      ];
      this.pushComponent(
        <SubInterest
          areas={interestedAreas}
          onContinue={(info) => this.continueSubLevel(info)}
          onBack={() => this.safelyPopComponent()}
        />
      );
    } else if (selectedArea.name === "Redação") {
      const interestedAreas = [
        { id: "0", name: "Escrita de textos" },
        { id: "1", name: "Gramática" },
        { id: "2", name: "Interpretação de Imagens" },
        { id: "3", name: "Interpretação de Textos" },
        { id: "4", name: "Redação do ENEM" },
        { id: "5", name: "Temas de Redações e Atualidades" },
      ];
      this.pushComponent(
        <SubInterest
          areas={interestedAreas}
          onContinue={(info) => this.continueSubLevel(info)}
          onBack={() => this.safelyPopComponent()}
        />
      );
    }
  }

  continueSubLevel(selectedSubject) {
    if (this.state.isTransitioning) return;

    if (this.state.desiredArea.name === "Línguas") {
      const defaultLanguageLevels = [
        { id: 0, name: "Básico" },
        { id: 1, name: "Intermediário" },
        { id: 2, name: "Avançado" },
      ];
      this.setState({ desiredSubject: selectedSubject });
      analytics.sendSubOnboardingSubject(selectedSubject.name);
      this.pushComponent(
        <SpcLevel
          levels={defaultLanguageLevels}
          onContinue={(info) => this.continueLevel(info)}
          onBack={() => this.safelyPopComponent()}
          headerText={"Qual o nível de dificuldade?"}
        />
      );
    } else if (this.state.desiredArea.name === "Orientação Vocacional") {
      let arr: string[] = [];
      for (const item of selectedSubject) {
        arr.push(item.name);
      }
      analytics.sendSubOnboardingSpecificAreas(arr.toString());
      const subjectLevels = [
        {
          id: "5dcea5fc-cb0f-4578-97ed-76b1dedb1604",
          name: "Ensino Fundamental",
          index: 0,
        },
        {
          id: "3de1f15d-3725-4254-919d-ac43c21e3496",
          name: "Ensino Médio",
          index: 1,
        },

        {
          id: "a44aa4f9-a997-4a6e-841a-7062490263bb",
          name: "Pré-Vestibular",
          index: 2,
        },
        {
          id: "12a13e1c-f2b7-48ca-bc63-7625ac6bcd6a",
          name: "Ensino Superior",
          index: 3,
        },
        {
          id: "Não estou estudando",
          name: "Não estou estudando",
          index: 4,
        },
      ];
      this.pushComponent(
        <SpcLevel
          levels={subjectLevels}
          onContinue={(info) => this.continueLevel(info)}
          onBack={() => this.safelyPopComponent()}
          headerText={"Qual seu nível escolar?"}
        />
      );
    } else {
      if (this.state.desiredArea.name === "Redação") {
        let arr: string[] = [];
        for (const item of selectedSubject) {
          arr.push(item.name);
        }
        analytics.sendSubOnboardingSpecificAreas(arr.toString());
        this.setState({ desiredSubject: selectedSubject });
      } else {
        this.setState({ desiredSubject: selectedSubject });
        analytics.sendSubOnboardingSubject(selectedSubject.name);
      }
      this.pushComponent(
        <SpcLevel
          levels={this.props.subjectLevels}
          onContinue={(info) => this.continueLevel(info)}
          onBack={() => this.safelyPopComponent()}
          headerText={"Qual o nível de dificuldade?"}
        />
      );
    }
  }

  continueLevel(selectedLevel) {
    if (this.state.isTransitioning) return;
    if (selectedLevel.id === 0) {
      selectedLevel = {
        id: "5dcea5fc-cb0f-4578-97ed-76b1dedb1604",
        name: "Ensino Fundamental",
        index: 0,
      };
    } else if (selectedLevel.id === 1) {
      selectedLevel = {
        id: "3de1f15d-3725-4254-919d-ac43c21e3496",
        name: "Ensino Médio",
        index: 1,
      };
    } else if (selectedLevel.id === 2) {
      selectedLevel = {
        id: "a44aa4f9-a997-4a6e-841a-7062490263bb",
        name: "Pré-Vestibular",
        index: 2,
      };
    } else if (selectedLevel.id === 3) {
      selectedLevel = {
        id: "12a13e1c-f2b7-48ca-bc63-7625ac6bcd6a",
        name: "Ensino Superior",
        index: 3,
      };
    }

    this.setState({ desiredLevel: selectedLevel }, () => {
      if (this.state.onboardingType === 4) {
        this.onContinueTutorMatch();
      } else {
        this.finishOnboarding();
      }
    });
    if (this.state.onboardingType !== 5) {
      analytics.sendOnboardingLevel(selectedLevel.name);
    } else {
      analytics.sendSubOnboardingLevel(selectedLevel.name);
    }
  }

  continueTutor() {
    if (this.state.isTransitioning) return;

    analytics.sendOnboardingHaveTutor();
    this.pushComponent(
      <SpcChooseTutor
        onContinue={(tutor) => this.continueProfile(tutor)}
        onBack={() => this.safelyPopComponent()}
      />
    );
  }

  continueProfile(tutor) {
    if (this.state.isTransitioning) return;

    this.setState({ desiredTutor: tutor });
    analytics.sendOnboardingHaveTutorSuccess();
    this.pushComponent(
      <SpcTutorFound onContinue={() => this.finishOnboarding()} tutor={tutor} />
    );
  }

  finishOnboarding() {
    let subject = this.state.desiredSubject;
    let level = this.state.desiredLevel;
    if (this.state.onboardingType === 5) {
      if (
        this.state.desiredArea.name === "Orientação Vocacional" ||
        this.state.desiredArea.name === "Redação"
      ) {
        subject = this.state.desiredArea;
        if (
          this.state.desiredArea.name === "Orientação Vocacional" &&
          this.state.desiredLevel.index === 3
        ) {
          level = {
            id: "12a13e1c-f2b7-48ca-bc63-7625ac6bcd6a",
            name: "Ensino Superior",
            index: 3,
          };
        }
      }
    }
    this.props.setOnboardingData({
      subject: subject,
      level: level,
      timing: this.state.desiredTiming,
      tutor: this.state.desiredTutor,
    });
    this.props.history.push("/landing/tutors");
  }

  loginPressed() {
    this.props.history.push("/login");
  }

  onContinueTutorMatch() {
    if (this.state.isTransitioning) return;
    this.pushComponent(
      <TutorMatchExercise
        history={this.props.history}
        photo={this.props.photo}
        comment={this.props.message}
        subject={this.state.desiredSubject}
        subjectLevel={this.state.desiredLevel}
      />
    );
  }

  getCurrentComponent() {
    return (
      <Animated.View
        style={{
          flex: 1,
          opacity: this.fadeAnim,
          transform: [{ translateX: this.state.translateX }],
        }}
      >
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        {this.getCurrentComponent()}
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpcOnboarding);
// export default remoteConfig;
