import React, { Component } from "react";
import OneWebView from "../../Utils/OneWebView";

type Props = {
  handleMessage;
};

export default class MathInputStaticServer extends Component<Props, any> {
  webView;

  injectJavaScript = (js) => {
    this.webView.injectJavaScript(js);
  };

  render() {
    return (
      <OneWebView
        ref={(webView) => (this.webView = webView)}
        scrollEnabled={false}
        onMessage={(event) => this.props.handleMessage(event)}
        source={"./mathinput/index.html"}
        keyboardDisplayRequiresUserAction={false}
      />
    );
  }
}
