import React from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import StyleUtils, { COLOR_PRIMARY } from "../Utils/StyleUtils";
import SubjectTestBackground from "../../assets/subjectTestBackground.svg";
import SVGImage from "../Utils/SVGImage";
import { connect } from "react-redux";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../Analytics";

type Props = {
  dispatch;
  tutorInfo;
  user;
  history;
  selectedSubjectTest;
  mobile?: boolean;
};

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    selectedSubjectTest: state.selRed.selectedSubjectTest,
  };
}

function dispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
  };
}

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

function SubjectTestStart(props: Props) {
  return (
    <LinearGradient
      colors={["#272B3E", "#005EC8"]}
      style={[
        StyleUtils.screen_default,
        {
          paddingVertical: 40,
        },
      ]}
    >
      <SVGImage
        source={<SubjectTestBackground />}
        style={{ alignItems: "center", justifyContent: "center", zIndex: 1 }}
      />
      <View
        style={{
          borderRadius: 270,
          backgroundColor: COLOR_PRIMARY,
          left: -124,
          width: 244 + Dimensions.get("window").width,
          height: 750,
          zIndex: 0,
          position: "absolute",
          top: 201,
        }}
      />
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          paddingTop: 10,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_28,
            StyleUtils.color_txt_white,
            { textAlign: "center" },
          ]}
        >
          Pronto para testar{"\n"} seu conhecimento?
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_white,
            { textAlign: "center" },
          ]}
        >
          São <B>{props.selectedSubjectTest.testQuestions.length} questões</B>
          {"\n"}e <B>{props.selectedSubjectTest.totalMinutes} minutos</B> para
          responder!
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_white,
            { textAlign: "center" },
          ]}
        >
          Prepare papel e caneta e quando{"\n"}quiser, é só começar!
        </Text>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            StyleUtils.color_bg_white,
            { marginTop: 8, width: 327 },
          ]}
          onPress={() => {
            analytics.sendStartSubjectTest();
            props.history.push("/subjecttests/exercise");
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_16,
              { padding: 14, textAlign: "center" },
            ]}
          >
            Começar teste
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{}}
          onPress={() => {
            analytics.sendCancelStartSubjectTest();
            props.history.goBack();
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_btn_regular,
              StyleUtils.font_size_16,
              {
                textDecorationLine: "underline",
                marginTop: 20,
              },
            ]}
          >
            Cancelar
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
}

export default connect(stateToProps, dispatchToProps)(SubjectTestStart);
