import React from "react";
import { View, Text, ScrollView } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import Exercise from "../Models/Exercise";
import CardElement from "../Elements/CardElement";
import CustomLightBoxLarge from "../ImageUploader/CustomLightBoxLarge";

type Props = {
  style?: any;
  exercise: Exercise;
  selected?: boolean;
  onPress?: () => void;
};

const AssignHomeworkQuestionCard = (props: Props) => {
  const textStyle = [
    StyleUtils.font_btn_regular,
    StyleUtils.color_txt_normal,
    StyleUtils.font_size_14,
  ];

  return (
    <CardElement
      styte={[
        { height: props.exercise.imageUrl === "" ? 240 : 400, borderWidth: 1 },
      ]}
      textStyle={StyleUtils.font_btn_regular}
      buttonText="Selecionar"
      options={{ type: "selection" }}
      selected={props.selected}
      onPress={props.onPress}
    >
      <CardHeader />
      <ScrollView
        style={[
          StyleUtils.color_bg_overlay,
          {
            flex: 1,
            padding: 12,
            backgroundColor: "#DDE0F2",
          },
        ]}
      >
        <Text style={textStyle}>{`${props.exercise.description}\n\n`}</Text>
        {props.exercise.imageUrl !== "" && (
          <CustomLightBoxLarge
            style={{
              width: 600,
              height: 400,
              resizeMode: "contain",
            }}
            activeProps={{
              width: "100%",
              height: "100%",
              resizeMode: "contain",
            }}
            source={props.exercise.imageUrl}
          />
        )}
        {props.exercise.possibleAnswers &&
          props.exercise.possibleAnswers
            .filter((possibleAnswer) => !!possibleAnswer)
            .map((possibleAnswer, index) => (
              <Text key={index} style={textStyle}>{`${String.fromCharCode(
                97 + index
              )}: ${possibleAnswer}`}</Text>
            ))}
      </ScrollView>
    </CardElement>
  );
};

const CardHeader = (props: {
  dificultyGrade?: number;
  contextGrade?: number;
}) => {
  const Grade = (props: { text: string; grade?: number }) => {
    return (
      <View style={{ flexDirection: "row", marginRight: 32 }}>
        <Text
          style={[
            StyleUtils.font_btn_regular,
            StyleUtils.color_txt_light,
            StyleUtils.font_size_14,
          ]}
        >
          {props.text}
        </Text>
        <Text>{props.grade}</Text>
      </View>
    );
  };
  return (
    <View
      style={[StyleUtils.color_bg_white, { flexDirection: "row", margin: 12 }]}
    >
      <Grade text="Dificuldade" grade={props.dificultyGrade} />
      <Grade text="Contextualização" grade={props.dificultyGrade} />
    </View>
  );
};

export default AssignHomeworkQuestionCard;
