import React from "react";
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native";
import ChatIconSvg from "../../assets/chatIcon.svg";
import CloseIconSvg from "../../assets/closeIcon.svg";
import FavoriteIconDark from "../../assets/favoriteIconDark.svg";
import FavoriteIconSelected from "../../assets/favoriteIconSelected.svg";
import FavoriteIconWhite from "../../assets/favoriteIconWhite.svg";
import ShareIconSvg from "../../assets/shareIcon.svg";
import SVGImage from "../Utils/SVGImage";

export function LikeIcon(props: {
  selected?: boolean;
  onPress?: () => void;
  height;
  width;
  style?: StyleProp<ViewStyle>;
  color?: "white" | "dark";
}) {
  return (
    <Icon
      {...props}
      icon={
        props.color == "white" ? (
          <FavoriteIconWhite height={props.height} width={props.width} />
        ) : (
          <FavoriteIconDark height={props.height} width={props.width} />
        )
      }
      selectedIcon={
        <FavoriteIconSelected height={props.height} width={props.width} />
      }
    />
  );
}

export function ChatIcon(props: {
  onPress?: () => void;
  height;
  width;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <Icon
      {...props}
      icon={<ChatIconSvg height={props.height} width={props.width} />}
    />
  );
}

export function CloseIcon(props: {
  onPress?: () => void;
  height;
  width;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <Icon
      {...props}
      icon={<CloseIconSvg height={props.height} width={props.width} />}
    />
  );
}

export function ShareIcon(props: {
  onPress?: () => void;
  height;
  width;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <Icon
      {...props}
      icon={<ShareIconSvg height={props.height} width={props.width} />}
    />
  );
}

function Icon(props: {
  selected?: boolean;
  onPress?: () => void;
  icon;
  selectedIcon?;
  height;
  width;
  style?: StyleProp<ViewStyle>;
}) {
  const icon =
    props.selected && props.selectedIcon ? props.selectedIcon : props.icon;

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[{ justifyContent: "center", alignItems: "center" }, props.style]}
    >
      <View style={{ opacity: 1 }}>
        <SVGImage
          source={icon}
          style={{ height: props.height, width: props.width }}
        />
      </View>
    </TouchableOpacity>
  );
}
