import React from "react";
import { Component } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import Logo from "../../assets/logo.svg";
import SVGImage from "../Utils/SVGImage";
import { withRouter } from "../Router";
import { connect } from "react-redux";
import UserIcon from "./../../assets/usericon.svg";
import { logout, selectTutor } from "../Actions";
import { SCREEN_SIZE_900 } from "../Utils/SizeUtils";
import HamburgerIcon from "./../../assets/hamburgericon.svg";
import ChatIcon from "./../../assets/chatButtonGrey.svg";
import NotificationIcon from "./../../assets/notificationIcon.svg";
import UserIconBlue from "./../../assets/userIconBlue.svg";

type Props = {
  user;
  tutorInfo;
  tuteeInfo;
  history;
  location;
  logout;
  selectTutor;
};

type State = {
  full_layout: boolean;
  menu_enabled: boolean;
};

function dispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
  };
}

function stateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    tuteeInfo: state.userRed.tuteeInfo,
  };
}

const headerHeight = 68;
class Header extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      full_layout: false,
      menu_enabled: false,
    };
  }

  noAuthHeaderElements = [];

  tutorHeaderElements = [
    {
      name: "Minhas aulas",
      path: "/events",
      onClick: null,
    },
    {
      name: "Minhas lições",
      path: "/exercises",
      onClick: null,
    },
    {
      name: "Meus horários",
      path: "/schedule",
      onClick: null,
    },
    {
      name: "Exercícios",
      path: "/exerciseDirectories",
      onClick: () => {},
    },
  ];

  tuteeHeaderElements = [
    // {
    //   name: "Agendar aula",
    //   path: "/booking",
    //   onClick: () => {
    //     if (this.props.tuteeInfo.preferredTutor) {
    //       this.props.selectTutor(this.props.tuteeInfo.preferredTutor);
    //     }
    //   },
    // },
    {
      name: "Agendar aula",
      path: "/tutormatchlist",
      onClick: () => {},
    },
    {
      name: "Minhas aulas",
      path: "/events",
      onClick: null,
    },
    {
      name: "Meus exercícios",
      path: "/exercises",
      onClick: null,
    },
  ];

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let full = layout.width < SCREEN_SIZE_900;
    if (full != this.state.full_layout) {
      this.setState({
        full_layout: full,
        menu_enabled: false,
      });
    }
  };

  headerElements = () => {
    if (!this.props.user) return this.noAuthHeaderElements;
    if (!this.props.tutorInfo) return this.tuteeHeaderElements;
    return this.tutorHeaderElements;
  };

  headerElementsFullLayout = () => {
    return (
      <View
        style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start" }}
      >
        {this.headerElements().map((element, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (element.onClick) {
                  element.onClick();
                }
                this.props.history.push(element.path);
              }}
              style={{ justifyContent: "center" }}
            >
              <Text
                style={[
                  { alignSelf: "center", margin: 8, padding: 8 },
                  StyleUtils.font_size_14,
                  this.props.location.pathname === element.path
                    ? StyleUtils.color_txt_primary
                    : StyleUtils.color_txt_dark,
                ]}
              >
                {element.name}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  headerElementsMenuLayout = () => {
    return (
      <View
        style={[
          StyleUtils.color_bg_white,
          StyleUtils.shadow_soft_bottom,
          { position: "absolute", width: "100%", marginTop: headerHeight },
        ]}
      >
        {this.props.user && (
          <Text
            style={[
              StyleUtils.color_bg_light,
              StyleUtils.font_size_16,
              { padding: 16 },
            ]}
          >
            Conectado como <b>{this.props.user.name}</b>
          </Text>
        )}
        {this.headerElements().map((element, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (element.onClick) {
                  element.onClick();
                }
                this.setState({ menu_enabled: false });
                this.props.history.push(element.path);
              }}
              style={{ justifyContent: "center" }}
            >
              <Text
                style={[
                  { margin: 8, padding: 8 },
                  StyleUtils.font_size_16,
                  this.props.location.pathname === element.path
                    ? StyleUtils.color_txt_primary
                    : StyleUtils.color_txt_dark,
                ]}
              >
                {element.name}
              </Text>
            </TouchableOpacity>
          );
        })}
        {this.props.user && (
          <TouchableOpacity
            onPress={() => {
              this.setState({ menu_enabled: false });
              this.props.logout();
              this.props.history.push("/landing");
            }}
            style={{ justifyContent: "center" }}
          >
            <Text
              style={[
                { margin: 8, padding: 8 },
                StyleUtils.font_size_16,
                StyleUtils.color_txt_dark,
              ]}
            >
              Logout
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  headerUserinfo = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-end",
          marginRight: "2%",
        }}
      >
        {this.props.user && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <SVGImage source={<UserIcon />} />
            <Text
              style={[
                StyleUtils.font_btn_bold,
                StyleUtils.font_size_14,
                StyleUtils.color_txt_dark,
                { marginLeft: 13 },
              ]}
            >
              {this.props.user.name}
            </Text>
            <View
              style={[
                StyleUtils.color_btn_muted,
                { width: 1, height: "80%", margin: 14, marginRight: 20 },
              ]}
            />
            <TouchableOpacity
              onPress={() => {
                this.props.history.push("/messages");
              }}
            >
              <SVGImage source={<ChatIcon />} />
            </TouchableOpacity>
            <View
              style={[
                StyleUtils.color_btn_muted,
                {
                  width: 1,
                  height: "80%",
                  margin: 14,
                  marginLeft: 20,
                  marginRight: 20,
                },
              ]}
            />
            <TouchableOpacity
              onPress={() => {
                this.props.history.push("/notifications");
              }}
            >
              <SVGImage source={<NotificationIcon />} />
            </TouchableOpacity>
            <View
              style={[
                StyleUtils.color_btn_muted,
                { width: 1, height: "80%", margin: 14, marginLeft: 20 },
              ]}
            />

            <TouchableOpacity
              onPress={() => {
                this.props.logout();
                this.props.history.push("/landing");
              }}
              style={{ justifyContent: "center" }}
            >
              <Text
                style={[
                  StyleUtils.font_size_14,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_btn_regular,
                ]}
              >
                Sair
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {!this.props.user && (
          <>
            <TouchableOpacity
              style={{ alignSelf: "center", margin: 8, padding: 8 }}
              onPress={() => {
                this.props.history.push("/login");
              }}
            >
              <Text
                style={[StyleUtils.font_size_14, StyleUtils.color_txt_dark]}
              >
                Entrar
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                StyleUtils.border_color_btn_primary,
                StyleUtils.shape_btn_default_rounded,
                { alignSelf: "center", margin: 8, padding: 8 },
              ]}
              onPress={() => {
                this.props.history.push("/login");
              }}
            >
              <Text
                style={[StyleUtils.font_size_14, StyleUtils.color_txt_primary]}
              >
                Cadastre-se
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    );
  };

  render() {
    return (
      <View
        style={[
          StyleUtils.color_bg_white,
          StyleUtils.shadow_soft_bottom,
          {
            zIndex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
            height: headerHeight,
          },
        ]}
        onLayout={this.onLayout}
      >
        {
          // Header Elements Floating Menu Button
          this.state.full_layout && (
            <TouchableOpacity
              style={{
                justifyContent: "center",
                marginLeft: 24,
                marginRight: 24,
              }}
              onPress={() => {
                this.setState({ menu_enabled: !this.state.menu_enabled });
              }}
            >
              <SVGImage source={<HamburgerIcon />} />
            </TouchableOpacity>
          )
        }

        {
          //Logo

          <TouchableOpacity
            onPress={() => {
              this.props.history.push("/");
            }}
            style={[{ flex: 0.5, alignSelf: "center", padding: 8 }]}
          >
            <SVGImage source={<Logo />} />
          </TouchableOpacity>
        }

        {
          // Header Elements
          !this.state.full_layout && this.headerElementsFullLayout()
        }

        {
          // Header User Info Full
          !this.state.full_layout && this.headerUserinfo()
        }

        {
          // Chat Button
          this.state.full_layout && this.props.user && (
            <TouchableOpacity
              style={{
                justifyContent: "center",
                marginLeft: 24,
                marginRight: 24,
              }}
              onPress={() => {
                this.props.history.push("/messages");
              }}
            >
              <SVGImage source={<ChatIcon />} />
            </TouchableOpacity>
          )
        }

        {
          // Login Button
          this.state.full_layout && !this.props.user && (
            <TouchableOpacity
              style={{
                justifyContent: "center",
                marginLeft: 24,
                marginRight: 24,
              }}
              onPress={() => {
                this.props.history.push("/login");
              }}
            >
              <SVGImage source={<UserIconBlue />} />
            </TouchableOpacity>
          )
        }

        {
          // Header Elements Floating Menu
          this.state.full_layout &&
            this.state.menu_enabled &&
            this.headerElementsMenuLayout()
        }
      </View>
    );
  }
}

export default withRouter(connect(stateToProps, dispatchToProps)(Header));
