import React, { Component } from "react";
import { View, FlatList } from "react-native";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import { AssignmentsFilter, getHomeworks } from "../Server";
import { connect } from "react-redux";
import { selectHomework } from "../Actions";
import HomeworkCard from "./HomeworkCard";
import { homeworkState, HomeworkState } from "../Models/Homework";
import TitleWithNavigation from "../Elements/TitleWithNavigation";

type Props = {
  tutorInfo;
  userID;
  history;
  selectHomework;
  mobile?: boolean;
};

type State = {
  homeworks;
};

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    userID: state.userRed.user.id,
    tutorInfo: state.userRed.tutorInfo,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectHomework: (homework) => dispatch(selectHomework(homework)),
  };
}

class ExercisesListArchived extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      homeworks: [],
    };
  }

  componentDidMount() {
    var assignmentsFilter: AssignmentsFilter = {
      tutorId: undefined,
      tuteeId: undefined,
      limit: undefined,
    };

    if (this.props.tutorInfo) {
      assignmentsFilter.tutorId = this.props.userID;
    } else {
      assignmentsFilter.tuteeId = this.props.userID;
    }
    getHomeworks(assignmentsFilter)
      .then((homeworks) => {
        return homeworks.filter(
          (homework) => homeworkState(homework) >= HomeworkState.COMPLETED
        );
      })
      .then((result) => {
        console.log(result);
        this.setState({
          homeworks: result,
        });
      })
      .finally(() => {});
  }

  render() {
    return (
      <View
        style={[
          StyleUtils.screen_default,
          StyleUtils.color_bg_white,
          { alignItems: "center" },
        ]}
      >
        <TitleWithNavigation
          title="Arquivadas"
          onGoBackPress={() => this.props.history.goBack()}
          mobile={this.props.mobile}
          options={{ colorStyle: "white" }}
          style={
            this.props.mobile && {
              borderBottomWidth: 1,
              borderColor: COLOR_MUTED,
            }
          }
        />
        <FlatList
          style={{
            flex: 1,
            width: "100%",
            maxWidth: 564,
            marginVertical: 8,
          }}
          data={this.state.homeworks}
          renderItem={(item) => (
            <HomeworkCard
              homework={item.item}
              isTutor={!!this.props.tutorInfo}
              mobile={this.props.mobile}
              onPress={() => {
                this.props.selectHomework(item.item);
                if (this.props.tutorInfo) {
                  this.props.history.push("/exercises/review");
                } else {
                  this.props.history.push("/exercises/exercise");
                }
              }}
            />
          )}
        />
      </View>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(ExercisesListArchived);
