import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import LinearGradient from "react-native-linear-gradient";

type Props = {
  onContinue;
  areas;
  question;
  width;
  height;
};

type State = {
  selectedSingle: string | null;
  windowWidth;
};

export default class StudyPlanOneMultipleChoice extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSingle: null,
      windowWidth: Dimensions.get("window").width,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    Dimensions.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    this.setState({ windowWidth: nextAppState.window.width });
  };

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;

    return (
      <TouchableOpacity
        key={item.id}
        style={[
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: 12,
            height: this.props.height,
            width: this.props.width,
            borderRadius: 6,
            alignItems: "center",
            flexDirection: "row",
            marginVertical: 8,
            justifyContent: "center",
            flexWrap: "wrap",
            alignContent: "center",
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text style={[styles.cellText]}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <View style={[StyleUtils.screen_default]}>
          <LinearGradient
            colors={["#011F42", "#016EE8"]}
            style={[
              {
                paddingVertical: 100,
              },
            ]}
          />
          <View
            style={{
              width: this.state.windowWidth,
              height: this.state.windowWidth,
              borderRadius: this.state.windowWidth / 2,
              backgroundColor: COLOR_TRUE_WHITE,
              transform: [{ scaleX: 2 }],
              marginTop: 80,
              position: "absolute",
            }}
          />
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              {
                alignSelf: "center",
                textAlign: "center",
                fontSize: this.state.windowWidth < 500 ? 20 : 32,
                zIndex: 1,
                marginTop: -60,
                marginBottom: 20,
                marginHorizontal: 60,
              },
            ]}
          >
            {this.props.question}
          </Text>
          <View style={styles.containerCenter}>
            {this.props.areas
              .slice(0, 2)
              .map((area) => this.renderLevelCell(area))}
          </View>
          <View style={styles.containerCenter}>
            {this.props.areas
              .slice(2, 4)
              .map((area) => this.renderLevelCell(area))}
          </View>
          <View style={styles.containerCenter}>
            {this.props.areas
              .slice(4)
              .map((area) => this.renderLevelCell(area))}
          </View>
        </View>
        {this.state.selectedSingle && (
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              StyleUtils.color_bg_white,
              {
                width: 264,
                borderWidth: 1,
                borderColor: COLOR_PRIMARY,
                alignSelf: "center",
                marginBottom: 300,
              },
            ]}
            onPress={() => {
              this.props.onContinue(this.state.selectedSingle);
              setTimeout(() => {
                this.setState({ selectedSingle: null });
              }, 400);
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_primary,
                StyleUtils.font_size_16,
                { padding: 14, textAlign: "center" },
              ]}
            >
              Continuar
            </Text>
          </TouchableOpacity>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 25,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
