enum AnswerState {
  Unanswered,
  Answered,
  Wrong,
  Correct,
}

export enum ExerciseViewMode {
  exerciseView,
  reviewView,
}

export const exerciseViewMode = (assignment, reviewMode?: boolean) => {
  if (assignment.correctedAt || reviewMode) return ExerciseViewMode.reviewView;
  return ExerciseViewMode.exerciseView;
};

export const itemState = (assignment, reviewMode?: boolean) => {
  if (
    exerciseViewMode(assignment, reviewMode) != ExerciseViewMode.exerciseView
  ) {
    if (!assignment.tuteeAnswer) return AnswerState.Unanswered;
    if (assignment.tuteeAnswer === assignment.exercise.correctAnswer)
      return AnswerState.Correct;
    return AnswerState.Wrong;
  } else {
    if (!assignment.tuteeAnswer) return AnswerState.Unanswered;
    return AnswerState.Answered;
  }
};

export const answerState = (assignment, answer, reviewMode?: boolean) => {
  if (
    exerciseViewMode(assignment, reviewMode) != ExerciseViewMode.exerciseView &&
    assignment.tuteeAnswer
  ) {
    if (
      assignment.tuteeAnswer !== assignment.exercise.correctAnswer &&
      answer === assignment.tuteeAnswer
    )
      return AnswerState.Wrong;
    else if (
      assignment.tuteeAnswer === assignment.exercise.correctAnswer &&
      answer === assignment.tuteeAnswer
    )
      return AnswerState.Correct;
    return AnswerState.Unanswered;
  } else {
    if (!assignment.tuteeAnswer || assignment.tuteeAnswer !== answer)
      return AnswerState.Unanswered;
    return AnswerState.Answered;
  }
};
