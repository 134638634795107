import React from "react";
import Card from "./Card";
import User from "../../Models/User";

type Props = {
  mobile?: boolean;
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  user: User;
  onPress?: () => void;
  onPress2?: () => void;
  subjects?;
  onPressTutor?: () => void;
};

const BookEventCard = (props: Props) => {
  const { user, ...other } = props;
  return (
    <Card
      {...other}
      imageLabel={user.name}
      imageSource={
        props.user.imageURL
          ? {
              uri: props.user.imageURL,
            }
          : require("../../../assets/userPlaceHolder.png")
      }
      description="Disponível Hoje"
      buttonText="Agendar Aula"
      subjects={props.subjects}
    />
  );
};

export default BookEventCard;
