import React, { useState } from "react";
import {
  TextInput,
  Text,
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import BackButton from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_WHITE,
  COLOR_PRIMARY,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  onBack;
  progress;
  question;
  placeholder;
};

export default function OneLineTemplate(props: Props) {
  const [text, setText] = useState("");

  return (
    <>
      <View style={StyleUtils.screen_default}>
        <View
          style={[
            {
              backgroundColor: COLOR_WHITE,
              justifyContent: "center",
              width: "100%",
            },
          ]}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 24,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  props.onBack();
                }}
              >
                <SVGImage source={<BackButton />} />
              </TouchableOpacity>
            </View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
                { textAlign: "center" },
              ]}
            >
              Responda e ganhe uma aula grátis!
            </Text>
            <View style={{ flex: 1 }}></View>
          </View>
        </View>
        <View
          style={{
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
            width: "100%",
            zIndex: 10,
          }}
        />
        <View style={{ height: 4 }}>
          <View
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: COLOR_PRIMARY,
              width: props.progress,
            }}
          />
        </View>
        <TouchableWithoutFeedback
          onPress={() => {
            Keyboard.dismiss();
          }}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
          >
            <View style={{ justifyContent: "center", height: "90%" }}>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_20,
                  { marginLeft: 24, marginRight: 60 },
                ]}
              >
                {props.question}
              </Text>
              <TextInput
                value={text}
                onChangeText={(text) => setText(text)}
                style={[
                  StyleUtils.font_cera_regular,
                  {
                    borderRadius: 6,
                    color: "black",
                    // textAlign: "auto",
                    marginTop: 16,
                    marginLeft: 24,
                    fontSize: 32,
                  },
                ]}
                placeholder={props.placeholder}
                placeholderTextColor={COLOR_MUTED}
              />
            </View>
          </KeyboardAvoidingView>
        </TouchableWithoutFeedback>
      </View>
      <View style={{ bottom: 0 }}>
        <ButtonElement
          colorScheme="primary"
          text="Avançar"
          onPress={() => {
            analytics.sendSurveyResponse(props.question, text);
            props.onContinue();
          }}
          disabled={!text}
          styte={[
            StyleUtils.shape_btn_default,
            { margin: 24, borderRadius: 6 },
          ]}
          textStyle={{ fontSize: 16 }}
        />
      </View>
    </>
  );
}
