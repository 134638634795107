// Not available for web
export async function getDeviceToken() {
  return null;
}

export function onBackgroundMessage(listener: (remoteMessage) => void) {
  return function unsubscribe() {
    return;
  };
}

export function onMessage(listener: (remoteMessage) => void) {
  return function unsubscribe() {
    return;
  };
}

export function useNotification(serviceTypes: string[]) {
  return false;
}

export function setup(history: any) {
  return;
}

export function useReloadKey(notification: boolean) {
  return "key1";
}
