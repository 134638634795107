import BoyPencil from "../../assets/boyPencil.svg";
import StudyPlanGraph from "../../assets/studyPlanGraph.svg";
import GirlBook from "../../assets/girlBook.svg";
import GirlComputer from "../../assets/girlComputer.svg";

export const questions = [
  {
    templateID: 2,
    question: "Qual o principal objetivo do estudante?",
    areas: [
      {
        id: 0,
        name: "Passar no vestibular",
      },
      {
        id: 1,
        name: "Passar no colégio técnico / vestibulinho",
      },
      {
        id: 2,
        name: "Melhorar as notas na escola",
      },
      {
        id: 3,
        name: "Escolher uma carreira ou faculdade",
      },
    ],
    width: 227,
    height: 132,
    questionID: 3,
  },
  {
    templateID: 3,
    question:
      "Quais são as principais dificuldades que\n o estudante enfrenta atualmente?",
    areas: [
      {
        id: 0,
        name: "Queda nas notas das provas",
      },
      {
        id: 1,
        name: "Atraso nas matérias da escola",
      },
      {
        id: 2,
        name: "Alcançar notas excepcionais",
      },
      {
        id: 3,
        name: "Notas insuficientes para objetivos de longo prazo",
      },
    ],
    width: 227,
    height: 132,
    questionID: 4,
  },
  {
    templateID: 4,
    question:
      "Agora vamos aprofundar nas matérias que\n o estudante está com dificuldade.",
    subHeaderText:
      "Todas as nossas aulas acontecem através de uma plataforma própria, garantindo\nsegurança e um espaço ideal para aulas individuais e resolução de exercícios",
    buttonText: "Vamos lá!",
    svg: BoyPencil,
  },
  {
    templateID: 3,
    question: "Quais são as matérias que\n o estudante está com dificuldade?",
    areas: [
      {
        id: 0,
        name: "Matemática",
      },
      {
        id: 1,
        name: "Português",
      },
      {
        id: 2,
        name: "Redação",
      },
      {
        id: 3,
        name: "Física",
      },
      {
        id: 4,
        name: "Inglês",
      },
      {
        id: 5,
        name: "Outras",
      },
    ],
    width: 203,
    height: 67,
    questionID: 5,
  },
  {
    templateID: 2,
    question: "E qual a média da escola para passar nas provas?",
    areas: [
      {
        id: 0,
        name: "4",
      },
      {
        id: 1,
        name: "5",
      },
      {
        id: 2,
        name: "6",
      },
      {
        id: 3,
        name: "7",
      },
      {
        id: 4,
        name: "8",
      },
      {
        id: 5,
        name: "Não sei",
      },
    ],
    width: 203,
    height: 67,
    questionID: 6,
  },
  {
    templateID: 4,
    question: "Você sabia?",
    subHeaderText:
      "86% dos nossos estudantes com nota @ em\n#, alcançaram uma nota 2 pontos acima\nda média em menos de 1 mês",
    buttonText: "Também quero!",
    svg: StudyPlanGraph,
  },
  {
    templateID: 2,
    question:
      "Faz quanto tempo que o estudante\nestá com dificuldades na escola?",
    areas: [
      {
        id: 0,
        name: "Algumas semanas",
      },
      {
        id: 1,
        name: "Alguns meses",
      },
      {
        id: 2,
        name: "Por volta de um ano",
      },
      {
        id: 3,
        name: "Desde o início da pandemia",
      },
      {
        id: 4,
        name: "Alguns anos",
      },
    ],
    width: 203,
    height: 67,
    questionID: 7,
  },
  {
    templateID: 2,
    question: "O estudante estuda em que tipo de escola?",
    areas: [
      {
        id: 0,
        name: "Escola Pública",
      },
      {
        id: 1,
        name: "Escola Privada",
      },
    ],
    width: 227,
    height: 132,
    questionID: 8,
  },
  {
    templateID: 6,
    question:
      "Consolidando todas as informações no\n Plano de Estudos personalizado...",
  },
  {
    templateID: 4,
    question: "Agora, vamos falar sobre\na rotina do estudante!",
    subHeaderText:
      "No Tutore você tem flexibilidade de dias e horários para as aulas.\nQualquer dia e horário, você que escolhe!",
    buttonText: "Continuar",
    svg: GirlBook,
  },
  {
    templateID: 2,
    question: "Qual o período que o estudante\ntem aulas na escola?",
    areas: [
      {
        id: 0,
        name: "Manhã",
      },
      {
        id: 1,
        name: "Tarde",
      },
      {
        id: 2,
        name: "Noite",
      },
      {
        id: 3,
        name: "Semi-integral",
      },
      {
        id: 4,
        name: "Integral",
      },
    ],
    width: 203,
    height: 67,
    questionID: 9,
  },
  {
    templateID: 2,
    question:
      "Quantas horas por semana o estudante teria\n disponível para realizar aulas particulares?",
    areas: [
      {
        id: 0,
        name: "Menos de 1 hora",
      },
      {
        id: 1,
        name: "Entre 1 e 2 horas",
      },
      {
        id: 2,
        name: "Entre 2 e 4 horas",
      },
      {
        id: 3,
        name: "Entre 4 e 6 horas",
      },
      {
        id: 4,
        name: "Entre 6 e 8 horas",
      },
      {
        id: 5,
        name: "Mais de 8 horas",
      },
    ],
    width: 203,
    height: 67,
    questionID: 10,
  },
  {
    templateID: 3,
    question:
      "Quais os melhores períodos para o estudante fazer\n aulas particulares com nossos professores?",
    areas: [
      {
        id: 0,
        name: "Manhã",
      },
      {
        id: 1,
        name: "Durante o almoço",
      },
      {
        id: 2,
        name: "Logo após o almoço",
      },
      {
        id: 3,
        name: "Tarde",
      },
      {
        id: 4,
        name: "Final da tarde",
      },
      {
        id: 5,
        name: "Noite",
      },
    ],
    width: 203,
    height: 67,
    questionID: 11,
  },
  {
    templateID: 4,
    question:
      "Com o plano e hábitos certos, todo aluno consegue aprender\n# estudando algumas horas por semana!",
    subHeaderText:
      "Todos os nossos pagamentos são realizados pelo PagSeguro,\ngarantindo segurança e tranquilidade para você.",
    buttonText: "Ótimo!",
    svg: GirlComputer,
  },

  {
    templateID: 5,
  },
  {
    templateID: 7,
  },
  {
    templateID: 8,
  },
  {
    templateID: 9,
  },
];

export const mathTopics = [
  {
    id: 0,
    name: "Adição e Subtração",
  },
  {
    id: 1,
    name: "Multiplicação e Divisão",
  },
  {
    id: 2,
    name: "Equações e sistemas",
  },
  {
    id: 3,
    name: "Frações e Decimais",
  },
  {
    id: 4,
    name: "Geometria",
  },
  {
    id: 5,
    name: "Outros",
  },
  {
    id: 6,
    name: "Não sei",
  },
];

export const portTopics = [
  {
    id: 0,
    name: "Gramática",
  },
  {
    id: 1,
    name: "Interpretação de texto",
  },
  {
    id: 2,
    name: "Leitura",
  },
  {
    id: 3,
    name: "Escrita de textos",
  },
  {
    id: 4,
    name: "Vocabulário",
  },
  {
    id: 5,
    name: "Outros",
  },
  {
    id: 6,
    name: "Não sei",
  },
];

export const essayTopics = [
  {
    id: 0,
    name: "Gramática",
  },
  {
    id: 1,
    name: "Interpretação de texto",
  },
  {
    id: 2,
    name: "Temas de redação",
  },
  {
    id: 3,
    name: "Escrita de textos",
  },
  {
    id: 4,
    name: "Redação do Enem",
  },
  {
    id: 5,
    name: "Outros",
  },
  {
    id: 6,
    name: "Não sei",
  },
];

export const physicsTopics = [
  {
    id: 0,
    name: "Leis de Newton",
  },
  {
    id: 1,
    name: "Unidades de Medida",
  },
  {
    id: 2,
    name: "Dinâmica",
  },
  {
    id: 3,
    name: "Cinemática",
  },
  {
    id: 4,
    name: "Elétrica",
  },
  {
    id: 5,
    name: "Outros",
  },
  {
    id: 6,
    name: "Não sei",
  },
];

export const englishTopics = [
  {
    id: 0,
    name: "Conversação",
  },
  {
    id: 1,
    name: "Escrita",
  },
  {
    id: 2,
    name: "Leitura",
  },
  {
    id: 3,
    name: "Vocabulário",
  },
  {
    id: 4,
    name: "Gramática",
  },
  {
    id: 5,
    name: "Outros",
  },
  {
    id: 6,
    name: "Não sei",
  },
];
