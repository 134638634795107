import React, { useState } from "react";
import { Platform, Text, View } from "react-native";
import SVGImage from "../Utils/SVGImage";
import StyleUtils from "../Utils/StyleUtils";
import { useEffect } from "react";

type Props = {
  initialTime: number; // in seconds
  style?;
  textStyle?;
  showRestantes?;
  ClockIcon;
  onEnded?;
  onChange?;
  showMinutes?;
};
const Timer = ({
  initialTime,
  style,
  showRestantes = false,
  ClockIcon,
  textStyle,
  onEnded,
  onChange,
  showMinutes,
}: Props) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    let counter;
    counter = setInterval(() => {
      setTime((oldTime) => {
        if (oldTime === 0) {
          onEnded();
          clearInterval(counter);
          return 0;
        }
        return oldTime - 1;
      });
    }, 1000);
    return () => {
      clearInterval(counter);
    };
  }, []);

  useEffect(() => {
    onChange && onChange(time);
  }, [time]);

  const displayTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + seconds).slice(-2);
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const displayTimeWithMinutes = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + seconds).slice(-2);
    return `${formattedMinutes} minutos e ${formattedSeconds} segundos`;
  };

  return (
    <View
      style={[
        { display: "flex", flexDirection: "row", alignItems: "center" },
        style,
      ]}
    >
      <SVGImage
        source={<ClockIcon />}
        style={{ top: Platform.OS === "ios" ? -2 : 0 }}
      ></SVGImage>
      <Text
        style={[
          StyleUtils.font_cera_medium,
          StyleUtils.font_size_16,
          textStyle,
        ]}
      >
        {showMinutes
          ? displayTimeWithMinutes()
          : displayTime() + (showRestantes ? " restantes" : "")}
      </Text>
    </View>
  );
};

export default Timer;
