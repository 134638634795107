import React, { useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import * as Animatable from "react-native-animatable";
import { connect } from "react-redux";
import TitleWithNavigation from "../Elements/TitleWithNavigation";
import PaymentSeals from "./PaymentSeals";
import {
  cardTokenization,
  cardVaultAdd,
  oAuthGetNet,
  userCardAdd,
} from "../Server";
import CreditCardErrorPopup from "./CreditCardErrorPopup";
import { analytics } from "../Analytics";

type Props = {
  user;
  history;
  location;
  show;
  close;
  help;
  callHelp?;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function getCardType(number) {
  var re = new RegExp("^4");
  if (number.match(re) != null) return "visa";

  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number
    )
  )
    return "mastercard";

  re = new RegExp("^3[47]");
  if (number.match(re) != null) return "amex";

  re = new RegExp(
    "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  );
  if (number.match(re) != null) return "discover";

  re = new RegExp("^36");
  if (number.match(re) != null) return "diners";

  re = new RegExp("^35(2[89]|[3-8][0-9])");
  if (number.match(re) != null) return "jcb";

  return "";
}

export function CreditCardAdd(props: Props) {
  const [show, setShow] = useState(false);
  const [help, setHelp] = useState(false);
  const [close, setClose] = useState(false);
  const [cardError, setCardError] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardValidateMM, setCardValidateMM] = useState("");
  const [cardValidateYY, setCardValidateYY] = useState("");
  const [cardCCV, setCardCCV] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [isValidCardNumber, setValidCardNumber] = useState(true);
  const [isValidCardHolderName, setValidCardHolderName] = useState(true);
  const [isValidCardValidate, setValidCardValidate] = useState(true);
  const [isValidCardCCV, setValidCardCCV] = useState(true);
  const [isCompleteForm, setCompleteForm] = useState(true);

  const sendForm = async () => {
    if (cardNumber.trim().length >= 14) {
      console.log("valid card");
      setValidCardNumber(true);
    } else {
      setValidCardNumber(false);
    }

    cardHolderName.trim().length >= 6
      ? setValidCardHolderName(true)
      : setValidCardHolderName(false);

    cardValidateMM.trim().length === 2 && cardValidateYY.trim().length === 2
      ? setValidCardValidate(true)
      : setValidCardValidate(false);

    cardCCV.trim().length === 3
      ? setValidCardCCV(true)
      : setValidCardCCV(false);

    // get card token from pagseguro
    if (
      cardNumber &&
      cardHolderName &&
      cardCCV &&
      cardValidateYY &&
      cardValidateMM
    ) {
      setLoading(true);
      const cardBrand = getCardType(cardNumber);
      const oAuthToken = await oAuthGetNet()
        .then((res) => {
          return res.access_token;
        })
        .catch((err) => {
          return false;
        });

      if (!oAuthToken) {
        // add auth error analytics
        // analytics.sendCreditCardInvalid();
        setLoading(false);
        setShow(true);
        setCardError("Entre em contato com o suporte.");
        setTimeout(() => {
          setShow(false);
          setCardError("");
          setClose(true);
        }, 4000);
        props.history.goBack();
      }

      const cardToken = await cardTokenization(
        cardNumber.replace(/ /g, ""),
        oAuthToken
      )
        .then((res) => {
          return res.number_token;
        })
        .catch((err) => {
          console.log(`error card token -> ${err}`);
        });

      if (cardToken) {
        const cardVault = await cardVaultAdd(
          cardToken,
          cardHolderName,
          cardValidateMM,
          cardValidateYY,
          props.user.id,
          oAuthToken
        )
          .then((res) => {
            console.log("vault complete");
            return res;
          })
          .catch((err) => {
            console.log(`error -> cardVault`);
            console.log(err);
          });

        if (cardVault.card_id) {
          let userCard = {
            cardToken: cardVault.number_token,
            cardId: cardVault.card_id,
            cardHolderName: cardHolderName,
            cardLastDigits: cardNumber.slice(-4),
            cardBrand: cardBrand,
            userId: props.user.id,
          };
          userCardAdd(userCard).then((res) => {
            console.log(`card saved ${cardVault.card_id}`);
            if (!res.error) {
              analytics.sendCreditCardAdd();
              props.history.goBack();
            }
          });
        }
      }
    } else {
      setCompleteForm(false);
    }
  };

  useEffect(() => {
    setCardNumber(cardNumber.replace(/(\d{4}(?!\s))/g, "$1 ").trimEnd());
    if (cardNumber.trim().length > 14) setValidCardNumber(true);
    if (cardHolderName.trim().length > 4) setValidCardHolderName(true);
    if (
      cardValidateYY.trim().length === 2 &&
      cardValidateMM.trim().length === 2
    )
      setValidCardValidate(true);
    if (cardCCV.trim().length === 3) setValidCardCCV(true);
  });

  return (
    <>
      <TitleWithNavigation
        title={"Adicionar Cartão"}
        onGoBackPress={props.history.goBack}
        options={{ colorStyle: "white" }}
        help={true}
        callHelp={(help) => setHelp(help)}
      />
      <SafeAreaView>
        <View style={styles.container}>
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
            keyboardVerticalOffset={Platform.OS == "ios" ? 0 : 200}
            enabled={Platform.OS === "ios"}
          >
            <ScrollView>
              <Animatable.View animation="fadeInLeft" duration={500}>
                <View style={styles.formContainer}>
                  <View style={styles.row}>
                    <Text
                      style={[
                        StyleUtils.font_size_16,
                        StyleUtils.color_txt_dark,
                        styles.label,
                      ]}
                    >
                      Número do cartão
                    </Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={setCardNumber}
                      value={cardNumber}
                      keyboardType="numeric"
                      autoCompleteType="cc-number"
                      maxLength={23}
                    />
                    {isValidCardNumber ? null : (
                      <Animatable.View animation="fadeInLeft" duration={500}>
                        <View style={styles.error}>
                          <Text style={styles.errorMsg}>
                            Número do cartão inválido.
                          </Text>
                        </View>
                      </Animatable.View>
                    )}
                  </View>
                  <View style={styles.row}>
                    <Text
                      style={[
                        StyleUtils.font_size_16,
                        StyleUtils.color_txt_dark,
                        styles.label,
                      ]}
                    >
                      Nome do titular
                    </Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={setCardHolderName}
                      value={cardHolderName}
                      autoCompleteType="name"
                      autoCapitalize="words"
                    />
                    {isValidCardHolderName ? null : (
                      <Animatable.View animation="fadeInLeft" duration={500}>
                        <View style={styles.error}>
                          <Text style={styles.errorMsg}>
                            Digite o Nome do Titular.
                          </Text>
                        </View>
                      </Animatable.View>
                    )}
                  </View>
                  <View style={styles.fields}>
                    <View style={styles.row}>
                      <Text
                        style={[
                          StyleUtils.font_size_16,
                          StyleUtils.color_txt_dark,
                          styles.label,
                        ]}
                      >
                        Validade do Cartão
                      </Text>
                      <View style={styles.inlineFields}>
                        <TextInput
                          style={[styles.input, styles.inputSmall]}
                          onChangeText={setCardValidateMM}
                          value={cardValidateMM}
                          keyboardType={"numeric"}
                          placeholder="MM"
                          autoCompleteType="cc-exp-month"
                          maxLength={2}
                        />
                        <TextInput
                          style={[styles.input, styles.inputSmall]}
                          onChangeText={setCardValidateYY}
                          value={cardValidateYY}
                          keyboardType={"numeric"}
                          placeholder="AA"
                          autoCompleteType="cc-exp-year"
                          maxLength={2}
                        />
                      </View>
                      {isValidCardValidate ? null : (
                        <Animatable.View animation="fadeInLeft" duration={500}>
                          <View style={styles.error}>
                            <Text style={styles.errorMsg}>
                              Validade inválida.
                            </Text>
                          </View>
                        </Animatable.View>
                      )}
                    </View>
                    <View style={styles.row}>
                      <Text
                        style={[
                          StyleUtils.font_size_16,
                          StyleUtils.color_txt_dark,
                          styles.label,
                        ]}
                      >
                        CCV
                      </Text>
                      <TextInput
                        style={styles.input}
                        onChangeText={setCardCCV}
                        value={cardCCV}
                        keyboardType={"numeric"}
                        placeholder="***"
                        autoCompleteType="cc-csc"
                      />
                      {isValidCardCCV ? null : (
                        <Animatable.View animation="fadeInLeft" duration={500}>
                          <View style={styles.error}>
                            <Text style={styles.errorMsg}>
                              Código inválido.
                            </Text>
                          </View>
                        </Animatable.View>
                      )}
                    </View>
                  </View>
                </View>
              </Animatable.View>
            </ScrollView>
          </KeyboardAvoidingView>
          <View>
            <PaymentSeals />
            <View style={styles.containerAdd}>
              <TouchableOpacity
                style={[StyleUtils.color_bg_primary, styles.btnAddCard]}
                onPress={() => {
                  sendForm();
                }}
              >
                {isLoading ? (
                  <ActivityIndicator color="#fff" />
                ) : (
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_bold,
                      StyleUtils.color_txt_white,
                    ]}
                  >
                    Adicionar Cartão
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </SafeAreaView>
      <CreditCardErrorPopup visible={show} msg={cardError} />
    </>
  );
}

export default connect(stateToProps)(CreditCardAdd);

const styles = StyleSheet.create({
  container: {
    height: "95%",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  formContainer: {
    marginTop: 5,
    margin: 20,
  },
  fields: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  inlineFields: {
    flexDirection: "row",
  },
  row: {
    flexDirection: "column",
  },
  input: {
    borderColor: "#eee",
    borderWidth: 1,
    borderRadius: 8,
    paddingLeft: 20,
    paddingRight: 10,
    minWidth: 150,
    minHeight: 45,
    color: "#000",
  },
  inputSmall: {
    minWidth: 70,
    marginRight: 10,
  },
  label: {
    marginTop: 15,
    marginBottom: 10,
  },
  error: {
    backgroundColor: "rgba(255, 47, 92, 1)",
    color: "#fff",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 8,
    borderRadius: 5,
    fontSize: 14,
  },
  errorMsg: {
    color: "#fff",
  },
  containerAdd: {
    // marginTop:40,
    // position:"absolute",
    // bottom:20,
    // width:'100%'
    // height:"25%"
  },
  btnAddCard: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 10,
    marginTop: 15,
    marginBottom: 15,
  },
  btnAddCardText: {},
});
