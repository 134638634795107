import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_PRIMARY,
  SpcStyle,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import Checkmark from "../../assets/checkWhite.svg";

import AnimatedPopUp from "./AnimatedPopUp";

export type genderItem = {
  value: string;
  label: string;
};

const defaultGender = { value: "", gender: "" };

const GenderPopUp = ({ show, close, onSelectGender }) => {
  const [selectedGender, setGender] = useState(defaultGender);

  const toggleSelectedGender = (item) => {
    if (selectedGender.value !== item.value) {
      setGender(item);
    }
    onSelectGender(item);
  };

  const renderLevelCell = (item: genderItem, isTheLast) => {
    const isSelectedGender = selectedGender.value === item.value;
    return (
      <TouchableOpacity
        style={[
          {
            height: 50,
            padding: 8,
            alignItems: "center",
            flexDirection: "row",
            borderColor: COLOR_MUTED,
            borderWidth: 1,
            borderBottomWidth: isTheLast ? 1 : 0,
            borderRightWidth: 0,
            borderLeftWidth: 0,
          },
        ]}
        onPress={(e) => toggleSelectedGender(item)}
      >
        <View
          style={[
            SpcStyle.checkmarkBase,
            isSelectedGender
              ? SpcStyle.checkmarkSelected
              : SpcStyle.checkmarkUnselected,
          ]}
        >
          {isSelectedGender && <SVGImage source={<Checkmark />} />}
        </View>
        <Text
          style={[
            SpcStyle.cellText,
            isSelectedGender && { color: COLOR_PRIMARY },
          ]}
        >
          {item.label}
        </Text>
      </TouchableOpacity>
    );
  };

  const genders = [
    { label: "Masculino", value: "masculino" },
    { label: "Feminino", value: "feminino" },
  ];

  return (
    <AnimatedPopUp show={show} close={close}>
      <Text
        style={[
          { margin: 32 },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_dark,
        ]}
      >
        Selecione uma opção
      </Text>
      <View style={{ margin: 32, marginTop: 0, width: "100%" }}>
        {genders.map((level, index) =>
          renderLevelCell(level, index == genders.length - 1)
        )}
      </View>
    </AnimatedPopUp>
  );
};

export default GenderPopUp;
