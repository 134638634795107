import React, { Component } from "react";
import {
  TextInput,
  View,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  BackHandler,
} from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";

import IcoAlignCenter from "../../assets/ico_align_center.svg";
import IcoAlignLeft from "../../assets/ico_align_left.svg";
import IcoAlignRight from "../../assets/ico_align_right.svg";
import IcoBold from "../../assets/ico_bold.svg";
import IcoItalic from "../../assets/ico_italic.svg";
import IcoSend from "../../assets/iconSend.svg";
import Ico123 from "../../assets/ico123.svg";
import IcoABC from "../../assets/icoABC.svg";
import CloseButton from "../../assets/closeButton.svg";

import IcoAlignCenterSelected from "../../assets/ico_align_center_selected.svg";
import IcoAlignLeftSelected from "../../assets/ico_align_left_selected.svg";
import IcoAlignRightSelected from "../../assets/ico_align_right_selected.svg";
import IcoBoldSelected from "../../assets/ico_bold_selected.svg";
import IcoItalicSelected from "../../assets/ico_italic_selected.svg";
import Ico123Selected from "../../assets/ico123_selected.svg";
import IcoABCSelected from "../../assets/icoABC_selected.svg";
import MathInput from "./MathInput/MathInput";
import { SafeAreaInsetsContext } from "react-native-safe-area-context";
import { SafeAreaFrameContext } from "react-native-safe-area-context";

type Props = {
  onSetText;
  onSetFormula;
};

type State = {
  opened;
  keyboard;
  textAlign;
  fontWeight;
  fontStyle;
  text;
  textID;
  textColor;
  backHandler;
};

export default class CanvasAuxiliarTextView extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      keyboard: "abc",
      textAlign: "left",
      fontWeight: "normal",
      fontStyle: "normal",
      text: null,
      textID: null,
      textColor: "#000",
      backHandler: null,
    };
  }

  open = () => {
    this.setState({
      opened: true,
      keyboard: "abc",
      textAlign: "left",
      fontWeight: "normal",
      fontStyle: "normal",
      text: null,
      textID: null,
      textColor: "#000",
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
    });
  };

  backPress = () => {
    if (this.state.opened) {
      this.close();
      return true;
    }
    return false;
  };

  close = () => {
    if (this.state.backHandler) {
      this.state.backHandler.remove();
    }
    this.setState({ opened: false });
  };

  render() {
    return this.state.opened ? (
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "#FFFFFF",
          justifyContent: "center",
        }}
      >
        {this.renderAuxView()}
      </View>
    ) : (
      <View />
    );
  }

  mathInput;

  renderAuxView() {
    return (
      <SafeAreaFrameContext.Consumer>
        {(frame) => (
          <SafeAreaInsetsContext.Consumer>
            {(insets) => (
              <KeyboardAvoidingView
                behavior={Platform.OS == "ios" ? "padding" : "height"}
                style={[
                  {
                    position: "absolute",
                    top: frame ? frame.y : 0,
                    bottom: insets ? insets.bottom : 0,
                    left: 0,
                    right: 0,
                    justifyContent: "center",
                  },
                ]}
                keyboardVerticalOffset={frame ? frame.y : 0}
              >
                {console.log(frame)}
                {console.log(insets)}
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  {this.state.keyboard === "123" && (
                    <MathInput
                      ref={(mathInput) => {
                        this.mathInput = mathInput;
                      }}
                      processedLatex={(formula) => {
                        this.props.onSetFormula({
                          formula: formula,
                        });
                      }}
                    />
                  )}
                  {this.state.keyboard === "abc" && (
                    <TextInput
                      placeholder={"Digite alguma coisa..."}
                      placeholderTextColor={"#BAC6DA"}
                      style={[
                        {
                          flexGrow: 1,
                          position: "absolute",
                          width: "80%",
                          textAlign: this.state.textAlign,
                          fontStyle: this.state.fontStyle,
                          fontWeight: this.state.fontWeight,
                          color: this.state.textColor,
                          marginLeft: "10%",
                        },
                        StyleUtils.color_txt_dark,
                        StyleUtils.font_size_20,
                      ]}
                      value={this.state.text}
                      autoFocus={true}
                      multiline={true}
                      onChangeText={(text) => {
                        this.setState({ text: text });
                      }}
                    />
                  )}
                </View>
                <TouchableOpacity
                  style={{ position: "absolute", top: 40, left: 16 }}
                  onPress={() => this.props.onSetText(null)}
                >
                  <SVGImage source={<CloseButton />} />
                </TouchableOpacity>
                <View
                  style={[
                    StyleUtils.color_bg_white,
                    StyleUtils.border_color_btn_muted,
                    {
                      height: 48,
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    },
                  ]}
                >
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({ keyboard: "abc" });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.keyboard === "abc" ? (
                          <IcoABCSelected />
                        ) : (
                          <IcoABC />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({ keyboard: "123" });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.keyboard === "123" ? (
                          <Ico123Selected />
                        ) : (
                          <Ico123 />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <View style={[StyleUtils.color_btn_muted, { width: 1 }]} />
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({ textAlign: "left" });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.textAlign === "left" ? (
                          <IcoAlignLeftSelected />
                        ) : (
                          <IcoAlignLeft />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({ textAlign: "center" });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.textAlign === "center" ? (
                          <IcoAlignCenterSelected />
                        ) : (
                          <IcoAlignCenter />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({ textAlign: "right" });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.textAlign === "right" ? (
                          <IcoAlignRightSelected />
                        ) : (
                          <IcoAlignRight />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <View style={[StyleUtils.color_btn_muted, { width: 1 }]} />
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({
                        fontWeight:
                          this.state.fontWeight === "bold" ? "normal" : "bold",
                      });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.fontWeight === "bold" ? (
                          <IcoBoldSelected />
                        ) : (
                          <IcoBold />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      this.setState({
                        fontStyle:
                          this.state.fontStyle === "italic"
                            ? "normal"
                            : "italic",
                      });
                    }}
                  >
                    <SVGImage
                      source={
                        this.state.fontStyle === "italic" ? (
                          <IcoItalicSelected />
                        ) : (
                          <IcoItalic />
                        )
                      }
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      if (this.state.keyboard === "abc") {
                        this.props.onSetText({
                          textID: this.state.textID,
                          text: this.state.text,
                          fontStyle: this.state.fontStyle,
                          fontWeight: this.state.fontWeight,
                          textAlign: this.state.textAlign,
                        });
                      } else {
                        this.mathInput.requestLatex();
                      }
                    }}
                  >
                    <SVGImage source={<IcoSend />} />
                  </TouchableOpacity>
                </View>
              </KeyboardAvoidingView>
            )}
          </SafeAreaInsetsContext.Consumer>
        )}
      </SafeAreaFrameContext.Consumer>
    );
  }

  handleCanvasAction = (data) => {
    console.log(data);
    switch (data.info.action) {
      case "editing:entered":
        this.setState({
          opened: true,
          keyboard: "abc",
          textID: data.info.target.id,
          text: data.info.target.text,
          textColor: data.info.target.fill,
          textAlign:
            data.info.target.textAlign !== undefined
              ? data.info.target.textAlign
              : "left",
          fontWeight: data.info.target.fontWeight,
          fontStyle: data.info.target.fontStyle,
        });
        break;
      case "editing:exited":
        break;
    }
  };
}
