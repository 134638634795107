import React from "react";
import Card from "./Card";
import CameraIconSuccess from "../../../assets/cameraIconSuccess.svg";
import User from "../../Models/User";
import * as Utils from "../../Utils/Utils";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  mobile?: boolean;
  size?: "large" | "regular" | "small";
  user: User;
  date: Date;
  onPress?: () => void;
  style?: ViewStyle;
  subject?;
};

const OngoingEventCard = (props: Props) => {
  const { user, date, ...other } = props;
  return (
    <Card
      {...other}
      cardStyle="success"
      imageLabel={user.name}
      imageSource={
        props.user.imageURL
          ? {
              uri: props.user.imageURL,
            }
          : require("../../../assets/userPlaceHolder.png")
      }
      imageIcon={<CameraIconSuccess />}
      text={`Agora, às ${Utils.dateFormatHHhmm(date)}`}
      buttonText="Entrar na Aula"
      subjects={props.subject}
    />
  );
};

export default OngoingEventCard;
