import React, { Component } from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
} from "react-native";
import ForwardArrow from "../../../assets/forwardArrowSlim.svg";
import StyleUtils, { COLOR_MUTED, SpcStyle } from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import IconBiology from "../../../assets/iconBiology.svg";
import IconBook from "../../../assets/iconBook.svg";
import IconChemistry from "../../../assets/iconChemistry.svg";
import IconEnglish from "../../../assets/iconEnglish.svg";
import IconFrench from "../../../assets/iconFrench.svg";
import IconGeography from "../../../assets/iconGeography.svg";
import IconHistory from "../../../assets/iconHistory.svg";
import IconMath from "../../../assets/iconMath.svg";
import IconPhysics from "../../../assets/iconPhysics.svg";
import IconSpanish from "../../../assets/iconSpanish.svg";
import IconLanguage from "../../../assets/iconLanguage.svg";
import IconSchool from "../../../assets/iconSchool.svg";
import IconVocation from "../../../assets/iconVocation.svg";
import IconEssay from "../../../assets/iconEssay.svg";
import BackArrow from "../../../assets/backbutton.svg";
import Footer from "../../Footer/Footer";
import { connect } from "react-redux";
import { withRouter } from "./../../Router";

type Subject = {
  name: string;
  key: string;
};

type Props = {
  subjects;
  onContinue;
  onBack;
  onHasTeacher;
  removeBackButton?;
  headerText;
  isSubOnboarding;
  location?;
};

type State = {
  selectedSubject: Subject | null;
};

const disableHaveTutor = true;

class SpcBenefits extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: null,
    };
  }

  renderNativeItem = (item) => {
    return (
      <>
        <TouchableOpacity
          style={style.cell}
          onPress={() => {
            let showFooter = false;
            if (
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.showFooter
            ) {
              showFooter = true;
            }
            item.continue
              ? item.continue(item, showFooter)
              : this.props.onContinue(item, showFooter);
          }}
        >
          {this.svgImageForSubjectId(item.id)}
          <Text style={[SpcStyle.cellText]}>{item.name}</Text>
          <SVGImage
            style={{
              marginRight: 12,
            }}
            source={<ForwardArrow />}
          />
        </TouchableOpacity>
        <View
          style={
            item !== this.props.subjects[this.props.subjects.length]
              ? style.cellSeparator
              : {}
          }
        />
      </>
    );
  };

  svgImageForSubjectId(subjectId) {
    const iconStyle = { paddingRight: 10 };
    switch (subjectId) {
      // Biology
      case "89e94319-4d91-440f-960a-da43e8e97413":
        return <SVGImage source={<IconBiology />} style={iconStyle} />;
      // Spanish
      case "a94effa1-748a-4feb-9db7-31acac555744":
        return <SVGImage source={<IconSpanish />} style={iconStyle} />;
      // Physics
      case "9d9064ed-ae90-4081-aeaf-5c1f6279a2f1":
        return <SVGImage source={<IconPhysics />} style={iconStyle} />;
      // French
      case "46392a73-efe7-4785-9238-e080ace66d2c":
        return <SVGImage source={<IconFrench />} style={iconStyle} />;
      // English
      case "77336d25-8e11-4494-8c04-250b4ea45819":
        return <SVGImage source={<IconEnglish />} style={iconStyle} />;
      // Geography
      case "3fce2130-453e-42b2-bd4c-b28c0da1f5f0":
        return <SVGImage source={<IconGeography />} style={iconStyle} />;
      // Portuguese
      case "06e72385-4796-45f5-a007-e1f6202b3579":
        return <SVGImage source={<IconBook />} style={iconStyle} />;
      // Math
      case "4d81017f-737d-4549-965c-4efdac7bbe9d":
        return <SVGImage source={<IconMath />} style={iconStyle} />;
      // Chemistry
      case "901f25ec-287c-4764-adf4-20f1e1f0f94a":
        return <SVGImage source={<IconChemistry />} style={iconStyle} />;
      // History
      case "34441773-71f0-4598-bd1e-b0a5a229cd73":
        return <SVGImage source={<IconHistory />} style={iconStyle} />;
      case "88055d6c-7ea9-4bc8-912c-fbdb27948106":
        return <SVGImage source={<IconVocation />} style={iconStyle} />;
      case "cd91b5b7-1adb-488f-af87-a834c33c6dc7":
        return <SVGImage source={<IconEssay />} style={iconStyle} />;
      case "Línguas":
        return <SVGImage source={<IconLanguage />} style={iconStyle} />;
      case "Matérias da Escola":
        return <SVGImage source={<IconSchool />} style={iconStyle} />;
      default:
        return <SVGImage source={<IconBook />} style={iconStyle} />;
    }
  }

  render() {
    return (
      <>
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_default_rounded,
              StyleUtils.border_color_txt_white,
              {
                alignSelf: "flex-start",
                margin: 16,
                marginTop: 70,
              },
              !!this.props.removeBackButton ? { opacity: 0 } : {},
            ]}
            disabled={!!this.props.removeBackButton}
            onPress={() => {
              this.props.onBack();
            }}
          >
            {this.props.headerText === "Qual língua?" ||
            this.props.headerText === "Qual a matéria?" ? (
              <SVGImage source={<BackArrow />} />
            ) : (
              <Text
                style={[
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_14,
                ]}
              >
                Cancelar
              </Text>
            )}
          </TouchableOpacity>
          <View
            style={{
              height: "60%",
              width: "100%",
              justifyContent: "center",
              paddingLeft:
                this.props.headerText === "Qual língua?" ||
                this.props.headerText === "Qual a matéria?"
                  ? 32
                  : 16,
              paddingRight:
                this.props.headerText === "Qual língua?" ||
                this.props.headerText === "Qual a matéria?"
                  ? 32
                  : 16,
              marginTop: 16,
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  marginTop: 30,
                  width: "100%",
                },
              ]}
            >
              {this.props.headerText}
            </Text>
            <View style={{ marginTop: 20 }}>
              <FlatList
                data={this.props.subjects}
                renderItem={({ item }) => this.renderNativeItem(item)}
              />
            </View>
          </View>
          {!disableHaveTutor && (
            <TouchableOpacity
              onPress={() => {
                this.props.onHasTeacher();
              }}
            >
              <Text
                style={[
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_16,
                  {
                    padding: 16,
                    paddingTop: 16,
                    paddingBottom: 12,
                    paddingLeft: 40,
                    paddingRight: 40,
                    textDecorationLine: "underline",
                    alignSelf: "center",
                  },
                ]}
              >
                Já tenho um professor
              </Text>
            </TouchableOpacity>
          )}
        </View>
        {this.props.location &&
          this.props.location.state &&
          this.props.location.state.showFooter && (
            <SafeAreaView>
              <Footer />
            </SafeAreaView>
          )}
      </>
    );
  }
}

export default withRouter(connect(null, null)(SpcBenefits));

const style = StyleSheet.create({
  cell: {
    height: 50,
    alignItems: "center",
    flexDirection: "row",
  },
  cellSeparator: {
    position: "absolute",
    bottom: 0,
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: COLOR_MUTED,
  },
});
