import React, { Component } from "react";
import {
  Animated,
  BackHandler,
  Dimensions,
  Easing,
  View,
  StyleSheet,
  Linking,
} from "react-native";
import { connect } from "react-redux";
import StudyPlanManyMultipleChoice from "./StudyPlanManyMultipleChoice";
import StudyPlanParts from "./StudyPlanParts";
import StudyPlanOneMultipleChoice from "./StudyPlanOneMultipleChoice";
import StyleUtils from "../Utils/StyleUtils";
import StartStudyPlan from "./StartStudyPlan";
import StudyPlanHeader from "./StudyPlanHeader";
import StudyPlanProgress from "./StudyPlanProgress";
import StudyPlanPause from "./StudyPlanPause";
import StudyPlanWaitResult from "./StudyPlanWaitResult";
import StudyPlanResult from "./StudyPlanResult";
import StudyPlanTutors from "./StudyPlanTutors";
import {
  englishTopics,
  essayTopics,
  mathTopics,
  physicsTopics,
  portTopics,
  questions,
} from "./StudyPlanQuestions";
import { analytics } from "../Analytics";
import { getTutors2, getTutor } from "../Server";
import CryptoJS from "crypto-js";
import stringify from "fast-json-stable-stringify";
import Footer from "../Footer/Footer";
import BackButtonMobile from "./BackButtonMobile";
import BackButtonDesktop from "./BackButtonDesktop";

type Props = {
  location;
  history;
  user;
  dispatch;
};

type State = {
  componentStack;
  backHandler;
  translateX;
  isTransitioning;
  surveyQuestions;
  mainObjective;
  difficulties;
  index;
  result;
  subjects;
  examScores;
  targetSubjects;
  specificSubjects;
  passingAverage;
  timeSinceStruggle;
  schoolType;
  schoolPeriod;
  timeAvailable;
  bestClassTimes;
  nextExamPeriod;
  schoolLevel;
  schoolYear;
  childAge;
  childPersonality;
  recommendedTutors;
  numClasses;
  windowWidth;
};

const { width } = Dimensions.get("window");

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}
const dispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

class StudyPlan extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);
    this.state = {
      componentStack: [],
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      translateX: new Animated.Value(0),
      isTransitioning: false,
      surveyQuestions: [],
      mainObjective: "",
      index: -1,
      result: [],
      subjects: [],
      difficulties: [],
      targetSubjects: [],
      examScores: [],
      specificSubjects: [],
      passingAverage: 6,
      timeSinceStruggle: "",
      schoolType: "",
      schoolPeriod: "",
      timeAvailable: "",
      bestClassTimes: [],
      nextExamPeriod: [],
      schoolLevel: "",
      schoolYear: "",
      childAge: "",
      childPersonality: "",
      recommendedTutors: [],
      numClasses: 0,
      windowWidth: width,
    };
  }

  componentDidMount() {
    this.setState({ surveyQuestions: questions }, () => {
      this.startPlan();
    });
    Dimensions.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    this.state.backHandler.remove();
    Dimensions.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    this.setState({ windowWidth: nextAppState.window.width });
  };

  backPress = () => {
    if (this.state.componentStack.length > 1) {
      this.safelyPopComponent();
      return true;
    }
    return false;
  };

  executeWithTransition(method, back?: boolean, useFade?: boolean) {
    this.setState({ isTransitioning: true });
    if (useFade) {
      Animated.timing(this.fadeAnim, {
        toValue: 0,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        method();
        Animated.timing(this.fadeAnim, {
          toValue: 1,
          duration: 800,
          easing: Easing.cubic,
          useNativeDriver: true,
        }).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    } else {
      Animated.sequence([
        Animated.timing(this.state.translateX, {
          toValue: back ? -1 * width : width,
          duration: 1,
          useNativeDriver: true,
          delay: 400,
        }),
      ]).start(() => {
        method();
        Animated.sequence([
          Animated.timing(this.state.translateX, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ]).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    }
  }

  pushComponent(component, useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack.concat(component);
        this.setState({
          componentStack: tempComponents,
        });
      },
      false,
      useFade
    );
  }

  safelyPopComponent(useFade?: boolean) {
    if (this.state.isTransitioning) return;
    this.setState({ index: this.state.index - 1 });
    this.popComponent(useFade);
  }

  popComponent(useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack;
        tempComponents.pop();
        this.setState({ componentStack: tempComponents });
      },
      true,
      useFade
    );
  }

  getCurrentComponent() {
    const showBackButton = this.state.index > -1 && this.state.surveyQuestions[this.state.index].templateID != 5
    return (
      <Animated.View
        style={{
          flex: 1,
          opacity: this.fadeAnim,
          transform: [{ translateX: this.state.translateX }],
        }}
      >
        {this.state.windowWidth > 950
        ? showBackButton && (
            <BackButtonDesktop onBack={() => this.onBack()} />
          )
        : showBackButton && (
            <BackButtonMobile onBack={() => this.onBack()} />
        )}
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  generateHash(obj) {
    const string = stringify(obj);
    const secret = "jTAEvg";
    return CryptoJS.DES.encrypt(string, secret).toString();
  }

  continueNext = (index) => {
    if (this.state.isTransitioning) return;
    const templateID = this.state.surveyQuestions[index].templateID;
    let progress =
      Math.round((index / this.state.surveyQuestions.length) * 100).toString() +
      "%";
    setTimeout(() => this.setState({ index: index }), 500);
    let question = "";
    let average = "5";
    let subHeaderText = "";
    if (this.state.surveyQuestions[index].question) {
      question = this.state.surveyQuestions[index].question;

      //subject
      if (question.includes("#")) {
        if (this.state.subjects[0]) {
          question = question.replace("#", this.state.subjects[0]);
        } else {
          question = question.replace("#", "Matemática");
        }
      }
      //time
      if (question.includes("*")) {
        let time = "8 horas";
        switch (this.state.timeAvailable.id) {
          case 0: {
            time = "30 minutos";
            break;
          }
          case 1: {
            time = "2 horas";
            break;
          }
          case 2: {
            time = "3 horas";
            break;
          }
          case 3: {
            time = "5 horas";
            break;
          }
          case 4: {
            time = "7 horas";
            break;
          }
        }
        question = question.replace("*", time);
      }
    }
    if (this.state.surveyQuestions[index].subHeaderText) {
      subHeaderText = this.state.surveyQuestions[index].subHeaderText;
      if (subHeaderText.includes("#")) {
        if (this.state.subjects[0]) {
          subHeaderText = subHeaderText.replace("#", this.state.subjects[0]);
        } else {
          subHeaderText = subHeaderText.replace("#", "Matemática");
        }
      }
      if (subHeaderText.includes("@")) {
        if (this.state.examScores[0]) {
          switch (this.state.examScores[0].id) {
            case 0: {
              average = "1";
              break;
            }
            case 1: {
              average = "3";
              break;
            }
            case 2: {
              average = "5";
              break;
            }
            case 3: {
              average = "7";
              break;
            }
            case 4: {
              average = "9";
              break;
            }
          }
        }

        subHeaderText = subHeaderText.replace("@", average);
      }
    }
    let width = 203;
    let height = 67;
    if (this.state.surveyQuestions[index].width) {
      if (this.state.windowWidth < 500) {
        if (this.state.surveyQuestions[index].width === 203) {
          width = 152;
          height = 65;
        } else {
          width = 150;
          height = 132;
        }
      } else {
        width = this.state.surveyQuestions[index].width;
        height = this.state.surveyQuestions[index].height;
      }
    }
    switch (templateID) {
      case 2:
        this.pushComponent(
          <StudyPlanOneMultipleChoice
            onContinue={(res) => {
              analytics.sendStudyPlanQuestion(question, res.name, index);
              switch (this.state.surveyQuestions[index].questionID) {
                case 3:
                  this.setState({ mainObjective: res.name });
                  break;
                case 6:
                  let passingAverage = 6;
                  if (res.id !== 5) {
                    passingAverage = parseInt(res.name);
                  }
                  let goal = passingAverage + 2;
                  let classPerWeek = 0;
                  for (let i = 0; i < this.state.subjects.length; i++) {
                    let totalClasses = 0;
                    let examScore = 3;
                    switch (this.state.examScores[i].id) {
                      case 0:
                        examScore = 0;
                        break;
                      case 2:
                        examScore = 4;
                        break;
                      case 3:
                        examScore = 6;
                        break;
                      case 4:
                        examScore = 8;
                        break;
                    }
                    while (examScore < goal) {
                      if (examScore < 2) {
                        totalClasses = totalClasses + 4;
                        examScore = examScore + 1;
                      } else if (examScore < 4) {
                        totalClasses = totalClasses + 3;
                        examScore = examScore + 1;
                      } else if (examScore < 6) {
                        totalClasses = totalClasses + 2;
                        examScore = examScore + 1;
                      } else if (examScore < 8) {
                        totalClasses = totalClasses + 3;
                        examScore = examScore + 1;
                      } else if (examScore < 10) {
                        totalClasses = totalClasses + 4;
                        examScore = examScore + 1;
                      }
                    }
                    //in weeks
                    let nextExam = 5;
                    switch (this.state.nextExamPeriod[i].id) {
                      case 0:
                        nextExam = 1;
                        break;
                      case 1:
                        nextExam = 2;
                        break;
                      case 2:
                        nextExam = 3;
                        break;
                      case 4:
                        nextExam = 8;
                        break;
                    }
                    let classPerWeekPerSubject = Math.ceil(
                      totalClasses / nextExam
                    );
                    classPerWeek = classPerWeek + classPerWeekPerSubject;
                  }
                  let finalNumClasses = classPerWeek * 4;
                  if (finalNumClasses > 8) {
                    finalNumClasses = 8;
                  } else if (finalNumClasses < 1) {
                    finalNumClasses = 1;
                  }
                  this.setState({
                    passingAverage: res.name,
                    numClasses: finalNumClasses,
                  });
                  break;
                case 7:
                  this.setState({ timeSinceStruggle: res.name });
                  break;
                case 8:
                  this.setState({ schoolType: res.name });
                  break;
                case 9:
                  this.setState({ schoolPeriod: res.name });
                  break;
                case 10:
                  this.setState({ timeAvailable: res });
                  break;
                case 12:
                  let arr = this.state.examScores.concat([res]);
                  this.setState({ examScores: arr });
                  break;
                case 14:
                  let nextExamPeriod = this.state.nextExamPeriod.concat([res]);
                  this.setState({ nextExamPeriod: nextExamPeriod });
                  break;
              }

              this.continueNext(index + 1);
            }}
            question={question}
            areas={this.state.surveyQuestions[index].areas}
            width={width}
            height={height}
          />
        );
        break;
      case 3:
        this.pushComponent(
          <StudyPlanManyMultipleChoice
            onContinue={(res) => {
              analytics.sendStudyPlanQuestion(
                question,
                JSON.stringify(res),
                index
              );
              switch (this.state.surveyQuestions[index].questionID) {
                case 4:
                  this.setState({ difficulties: res });
                  break;
                case 5:
                  this.setState({ targetSubjects: res });
                  break;
                case 11:
                  this.setState({ bestClassTimes: res });
                  break;
                case 13:
                  let tempArr: any[] = [];
                  for (let i = 0; i < res.length; ++i) {
                    tempArr.push(res[i].name);
                  }
                  let specificSubjects =
                    this.state.specificSubjects.concat(tempArr);
                  this.setState({ specificSubjects: specificSubjects });
                  break;
              }
              if (index === 3) {
                let arr: any[] = [];
                let firstPart = this.state.surveyQuestions.slice(0, index + 1);
                let secondPart = this.state.surveyQuestions.slice(index + 1);
                let subjectAreas: any[] = [];
                for (let i = 0; i < res.length; i++) {
                  if (res[i].id !== 5) {
                    switch (res[i].id) {
                      case 0: {
                        subjectAreas = mathTopics;
                        break;
                      }
                      case 1: {
                        subjectAreas = portTopics;
                        break;
                      }
                      case 2: {
                        subjectAreas = essayTopics;
                        break;
                      }
                      case 3: {
                        subjectAreas = physicsTopics;
                        break;
                      }
                      case 4: {
                        subjectAreas = englishTopics;
                        break;
                      }
                    }
                    arr.push(res[i].name);
                    firstPart.push({
                      templateID: 2,
                      question:
                        "Qual foi a nota do estudante" +
                        " na última prova\nde " +
                        res[i].name +
                        " que ele teve dificuldade?",
                      areas: [
                        {
                          id: 0,
                          name: "Entre 0-2",
                        },
                        {
                          id: 1,
                          name: "Entre 2-4",
                        },
                        {
                          id: 2,
                          name: "Entre 4-6",
                        },
                        {
                          id: 3,
                          name: "Entre 6-8",
                        },
                        {
                          id: 4,
                          name: "Entre 8-10",
                        },
                        {
                          id: 5,
                          name: "Não sei",
                        },
                      ],
                      width: 203,
                      height: 67,
                      questionID: 12,
                    });
                    firstPart.push({
                      templateID: 3,
                      question:
                        "Você sabe quais tópicos que o estudante está" +
                        "\ncom dificuldade em " +
                        res[i].name +
                        "?",
                      areas: subjectAreas,
                      width: 203,
                      height: 67,
                      questionID: 13,
                    });
                    firstPart.push({
                      templateID: 2,
                      question:
                        "Quando são as próximas provas\nde " +
                        res[i].name +
                        "?",
                      areas: [
                        {
                          id: 0,
                          name: "Essa semana",
                        },
                        {
                          id: 1,
                          name: "Semana que vem",
                        },
                        {
                          id: 2,
                          name: "Neste mês",
                        },
                        {
                          id: 3,
                          name: "Mês que vem",
                        },
                        {
                          id: 4,
                          name: "Daqui a dois meses",
                        },
                        {
                          id: 5,
                          name: "Ainda não sei",
                        },
                      ],
                      width: 203,
                      height: 67,
                      questionID: 14,
                    });
                  }
                }
                this.setState({ subjects: arr });
                this.setState(
                  { surveyQuestions: firstPart.concat(secondPart) },
                  () => {
                    this.continueNext(index + 1);
                  }
                );
              } else {
                this.continueNext(index + 1);
              }
            }}
            question={question}
            areas={this.state.surveyQuestions[index].areas}
            width={width}
            height={height}
          />
        );
        break;
      case 4:
        this.pushComponent(
          <StudyPlanParts
            onContinue={() => {
              this.continueNext(index + 1);
            }}
            headerText={question}
            subHeaderText={subHeaderText}
            buttonText={this.state.surveyQuestions[index].buttonText}
            svg={this.state.surveyQuestions[index].svg}
            index={index}
          />
        );
        break;
      case 5:
        this.pushComponent(
          <StudyPlanProgress
            onContinue={(level, schoolYear, childAge, childPersonality) => {
              this.setState(
                {
                  schoolLevel: level,
                  schoolYear: schoolYear,
                  childAge: childAge,
                  childPersonality: childPersonality,
                },
                () => {
                  this.getRecommendedTutors()
                    .then(() => {
                      this.continueNext(index + 1);
                    })
                    .catch((e) => {
                      console.log("error get recommended tutor");
                    });
                }
              );
            }}
            index={index}
          />
        );
        break;
      case 6:
        this.pushComponent(
          <StudyPlanPause
            onContinue={() => {
              this.continueNext(index + 1);
            }}
            headerText={question}
          />
        );
        break;
      case 7:
        this.pushComponent(
          <StudyPlanWaitResult
            onContinue={() => {
              this.continueNext(index + 1);
            }}
            index={index}
          />
        );
        break;
      case 8:
        let subjects = this.state.subjects.toString();
        subjects = subjects.replace(/,/g, ", ");
        if (this.state.subjects.length > 1) {
          let lastIndex = subjects.lastIndexOf(",");
          subjects =
            subjects.substring(0, lastIndex) +
            " e" +
            subjects.substring(lastIndex + 1);
        }
        let filteredTopics = [...new Set(this.state.specificSubjects)];
        filteredTopics = filteredTopics.filter(
          (e) => e !== "Outros" && e !== "Não sei"
        );
        let topicsArr = filteredTopics.toString();
        let topicsStr = topicsArr.toString();
        topicsStr = topicsStr.replace(/,/g, ", ");
        if (this.state.specificSubjects.length > 1) {
          let lastIndex = topicsStr.lastIndexOf(",");
          topicsStr =
            topicsStr.substring(0, lastIndex) +
            " e" +
            topicsStr.substring(lastIndex + 1);
        }
        this.pushComponent(
          <StudyPlanResult
            onContinue={() => {
              this.continueNext(index + 1);
            }}
            age={this.state.childAge}
            schoolLevel={this.state.schoolLevel}
            schoolYear={this.state.schoolYear}
            subjects={subjects}
            topics={topicsStr}
            hours={this.state.timeAvailable.name}
            index={index}
          />
        );
        break;
      case 9:
        let tutors: any[] = [];
        for (let i = 0; i < this.state.recommendedTutors.length; i++) {
          let obj = {};
          if (this.state.subjects[i]) {
            obj = {
              tutorId: this.state.recommendedTutors[i].tutors[0].id,
              subjectId: this.state.subjects[i],
            };
          } else {
            obj = {
              tutorId: this.state.recommendedTutors[i].tutors[0].id,
              subjectId: "Matemática",
            };
          }
          tutors.push(obj);
        }
        let targetSubjects: any[] = [];
        for (let i = 0; i < this.state.targetSubjects.length; i++) {
          targetSubjects.push(this.state.targetSubjects[i].name);
        }
        let difficulties: any[] = [];
        for (let i = 0; i < this.state.difficulties.length; i++) {
          difficulties.push(this.state.difficulties[i].name);
        }
        let nextExamPeriod: any[] = [];
        for (let i = 0; i < this.state.nextExamPeriod.length; i++) {
          nextExamPeriod.push(this.state.nextExamPeriod[i].name);
        }
        let examScores: any[] = [];
        for (let i = 0; i < this.state.examScores.length; i++) {
          examScores.push(this.state.examScores[i].name);
        }
        let bestClassTimes: any[] = [];
        for (let i = 0; i < this.state.bestClassTimes.length; i++) {
          bestClassTimes.push(this.state.bestClassTimes[i].name);
        }
        this.pushComponent(
          <StudyPlanTutors
            onContinue={() => {
              const objCookie = {
                mainObjective: this.state.mainObjective,
                difficulties: difficulties,
                targetSubjects: targetSubjects,
                examScores: examScores,
                specificSubjects: this.state.specificSubjects,
                passingAverage: this.state.passingAverage,
                timeSinceStruggle: this.state.timeSinceStruggle,
                schoolType: this.state.schoolType,
                schoolPeriod: this.state.schoolPeriod,
                timeAvailable: this.state.timeAvailable.name,
                bestClassTimes: bestClassTimes,
                nextExamPeriod: nextExamPeriod,
                schoolLevel: this.state.schoolLevel,
                schoolYear: this.state.schoolYear,
                childAge: this.state.childAge,
                childPersonality: this.state.childPersonality,
                recommendedTutors: tutors,
                recommendedClasses: this.state.numClasses,
              };
              let finalCookie = this.generateHash(objCookie);
              document.cookie =
                "TUTORE_FUTURE_STUDY_PLAN=" +
                finalCookie +
                ";path=/;domain=learning-machine.org";
              Linking.canOpenURL("https://tutoring.learning-machine.org/login")
                .then(() => {
                  Linking.openURL(
                    "https://tutoring.learning-machine.org/login"
                  );
                })
                .catch();
            }}
            subjects={this.state.subjects}
            selectedTutors={this.state.recommendedTutors}
            numClasses={this.state.numClasses}
            index={index}
          />
        );
        break;
    }
  };

  startPlan() {
    if (this.state.isTransitioning) return;
    this.pushComponent(
      <StartStudyPlan onContinue={() => this.continueNext(0)} />,
      true
    );
  }

  async getRecommendedTutors() {
    let subjectLevel = "Ensino Fundamental";
    if (this.state.schoolLevel === "Ens. Médio") {
      subjectLevel = "Ensino Médio";
    }
    for (let i = 0; i < this.state.subjects.length; i++) {
      await getTutors2("70be658a-db03-4ffe-88c5-ff90cb6b5679", {
        include: ["rating"],
        limit: 1,
        subject: this.state.subjects[i],
        level: subjectLevel,
      })
        .then((tutor) => {
          this.state.recommendedTutors.push(tutor);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (this.state.subjects.length === 0) {
      await getTutor(
        "70be658a-db03-4ffe-88c5-ff90cb6b5679",
        "4a60c1ae-532c-4dd1-8352-d11577e2f0e2"
      ).then((tutor) => {
        let obj = {
          tutors: [tutor],
        };
        this.state.recommendedTutors.push(obj);
      });
    }
  }

  onBack() {
    analytics.sendStudyPlanBack(this.state.index);
    if (this.state.index == 0) {
      this.setState({ index: -1 });
      return this.startPlan();
    }
    this.continueNext(this.state.index - 1);
  }

  render() {
    return (
      <View style={[StyleUtils.screen_default, { overflow: "hidden" }]}>
        {this.state.windowWidth > 500 && <StudyPlanHeader />}
        {this.getCurrentComponent()}
        {this.state.windowWidth > 500 && <Footer />}
      </View>
    );
  }
}

export default connect(mapStateToProps, dispatchToProps)(StudyPlan);

const style = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});
