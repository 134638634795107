import React, { useRef, useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import ButtonElement from "../../Elements/ButtonElement";
import AnimatedPopUp from "../../SignUp/AnimatedPopUp";
import StyleUtils, {
  COLOR_DANGER,
  COLOR_MUTED,
  COLOR_PRIMARY_LIGHT,
  COLOR_TXT_LIGHT,
  COLOR_WHITE,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import Next from "../../../assets/next.svg";
import BackButton from "../../../assets/backbutton.svg";
import Archive from "../../../assets/archive.svg";
import { useEffect } from "react";
import { getExerciseDirectories, postExerciseDirectory } from "../../Server";
import ErrorPopUp from "./ErrorPopUp";
import NewDirectoryPopUp from "./NewDirectoryPopUp";
import useOnClickOutside from "../../hooks/useClickOnOutside";

type Props = {
  headerText: string;
  options;
  style?;
  closePopUp;
  user;
  file?;
  directory;
  history;
  deletePopUpText;
  refetchData;
};

const MoreOptionsPopUp = ({
  headerText,
  options,
  style,
  closePopUp,
  user,
  file,
  directory,
  history,
  deletePopUpText,
  refetchData,
}: Props) => {
  const [
    openDeleteConfirmationPopUp,
    setOpenDeleteConfirmationPopUp,
  ] = useState(false);
  const [showMoreDirectories, setShowMoreDirectories] = useState(false);

  const handleMoveDirectoryPress = () => {
    setShowMoreDirectories(true);
  };

  const ref: any = useRef();
  useOnClickOutside(ref, () => {
    closePopUp();
  });

  const MainOptions = () => {
    return (
      <>
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: COLOR_MUTED,
            paddingVertical: 16,
            paddingHorizontal: 19,
          }}
        >
          <Text style={[StyleUtils.font_size_16, StyleUtils.font_cera_bold]}>
            {headerText}
          </Text>
        </View>
        <View style={{ paddingHorizontal: 16, paddingTop: 16 }}>
          <OptionRow
            ImgSrc={options[0].img}
            text={options[0].text}
            handlePress={options[0].handlePress}
            disabled={options[0].disabled}
          />
          <OptionRow
            ImgSrc={options[1].img}
            text={options[1].text}
            handlePress={handleMoveDirectoryPress}
            showDirectories
            disabled={options[1].disabled}
          />
          <OptionRow
            ImgSrc={options[2].img}
            text={options[2].text}
            handlePress={() => {
              setOpenDeleteConfirmationPopUp(true);
            }}
            disabled={options[2].disabled}
          />
          {openDeleteConfirmationPopUp && (
            <ConfirmDeleteActionPopup
              close={() => {}}
              show={openDeleteConfirmationPopUp}
              onConfirm={options[2].handlePress}
              onReturn={() => {
                setOpenDeleteConfirmationPopUp(false);
                closePopUp();
              }}
              deletePopUpText={deletePopUpText}
            />
          )}
        </View>
      </>
    );
  };

  const MoreDirectories = () => {
    const directories: any[] = [];
    const initialStateStack: any[] = [];
    const stateStack = useRef(initialStateStack);
    const error = useRef("");
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);
    const [state, setState] = useState({
      directories,
      currentDirectory: { id: undefined, name: undefined },
    });
    const [openNewFolderPopUp, setOpenNewFolderPopUp] = useState(false);

    const [rowSelected, setRowSelected] = useState(-1);

    useEffect(() => {
      getExerciseDirectories(user.id, state.currentDirectory.id).then(
        ({ response }) => {
          setState({
            ...state,
            directories: response.directories,
          });
          stateStack.current.push({
            currentId: state.currentDirectory,
            directories: response.directories,
          });
        }
      );
    }, []);

    const handleBackButtonPress = () => {
      stateStack.current.pop();
      setOpenNewFolderPopUp(false);
      if (stateStack.current.length == 0) {
        setShowMoreDirectories(false);
      } else {
        setState(stateStack.current[stateStack.current.length - 1]);
      }
    };

    const handleNextButtonPress = (directory) => {
      getExerciseDirectories(user.id, directory.id).then(({ response }) => {
        setState({
          currentDirectory: directory,
          directories: response.directories,
        });
        stateStack.current.push({
          currentId: state.currentDirectory,
          directories: response.directories,
        });
      });
    };

    const handleMoveDirectorySubmit = async () => {
      const newParentDir = state.directories[rowSelected];

      try {
        const data = await options[1].handlePress(newParentDir.id);
        closePopUp();
        if (data && data.exercise) {
          refetchData(directory.id);
        } else {
          history.replace("/exerciseDirectories");
        }
      } catch (err) {
        error.current = err.message;
        setShowErrorPopUp(true);
      }
    };

    const handleCreateNewFolder = () => {
      setOpenNewFolderPopUp(true);
      stateStack.current.push({
        currentDirectory: state.currentDirectory,
        directories: state.directories,
      });
    };
    const handleMoveToNewFolderSubmit = (name) => {
      return postExerciseDirectory(
        user.id,
        name,
        state.currentDirectory.id
      ).then((res: any) => {
        return options[1].handlePress(res.directory.id);
      });
    };

    return openNewFolderPopUp ? (
      <NewDirectoryPopUp
        closePopUp={() => closePopUp()}
        refetchData={refetchData}
        style={{ border: 0 }}
        currentDirectory={state.currentDirectory}
        history={history}
        userId={user.id}
        handleBackButtonpress={handleBackButtonPress}
        buttonText={"Mover para pasta nova"}
        handleSubmit={handleMoveToNewFolderSubmit}
      />
    ) : (
      <>
        <View>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: COLOR_MUTED,
              paddingVertical: 16,
              paddingHorizontal: 16,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TouchableOpacity onPress={handleBackButtonPress}>
              <SVGImage
                style={{ width: 24, height: 24 }}
                source={<BackButton />}
              />
            </TouchableOpacity>
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_bold,
                { marginLeft: 8 },
              ]}
            >
              Mover para pasta
            </Text>
          </View>
          <ScrollView
            style={{
              paddingTop: 8,
              minHeight: 150,
              maxHeight: 300,
            }}
          >
            {state.directories.map(
              (dir, index) =>
                dir.id !== directory.id && (
                  <OpenDirectoriesRow
                    ImgSrc={Archive}
                    directory={dir}
                    handleTextPress={() => {
                      setRowSelected((oldIndex) =>
                        oldIndex === index ? -1 : index
                      );
                    }}
                    handleNextPress={() => handleNextButtonPress(dir)}
                    isSelected={index === rowSelected}
                    user={user}
                  />
                )
            )}
          </ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 20,
              paddingRight: 16,
              paddingBottom: 16,
              marginTop: 8,
            }}
          >
            <TouchableOpacity onPress={handleCreateNewFolder}>
              <Text
                style={[StyleUtils.font_size_14, StyleUtils.font_cera_regular]}
              >
                + Nova Pasta
              </Text>
            </TouchableOpacity>
            <ButtonElement
              colorScheme="primary"
              onPress={handleMoveDirectorySubmit}
              text="Mover"
              textStyle={{ paddingHorizontal: 30 }}
              styte={[StyleUtils.shape_btn_default, { margin: 0 }]}
              disabled={rowSelected === -1}
            />
          </View>
        </View>
        <ErrorPopUp
          show={showErrorPopUp}
          header={"Não foi possível mover a pasta"}
          body={error.current}
          onReturn={() => {
            refetchData(directory.id);
          }}
        />
      </>
    );
  };

  return (
    <div ref={ref}>
      <View
        testID="more-options-popup"
        style={[
          StyleUtils.color_bg_white,
          {
            position: "absolute",
            borderRadius: 10,
            minHeight: 190,
            width: 247,
            borderWidth: 1,
            borderColor: COLOR_MUTED,
          },
          style,
        ]}
      >
        {showMoreDirectories ? <MoreDirectories /> : <MainOptions />}
      </View>
    </div>
  );
};

type OptionRowProps = {
  ImgSrc;
  text;
  handlePress;
  style?;
  showDirectories?;
  disabled?;
};

const OptionRow = ({
  ImgSrc,
  text,
  handlePress,
  style,
  showDirectories,
  disabled = false,
}: OptionRowProps) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={handlePress}
      style={[
        {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 16,
        },
        style,
      ]}
    >
      <View
        style={[
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minHeight: 24,
          },
        ]}
      >
        <SVGImage style={{ width: 24, height: 24 }} source={<ImgSrc />} />
        <Text
          style={[
            StyleUtils.font_size_16,
            { color: disabled && COLOR_TXT_LIGHT, marginLeft: 8 },
          ]}
        >
          {text}
        </Text>
      </View>

      {showDirectories && (
        <SVGImage style={{ width: 24, height: 24 }} source={<Next />} />
      )}
    </TouchableOpacity>
  );
};

type OpenDirectoriesRowProps = {
  ImgSrc;
  directory;
  handleTextPress;
  handleNextPress?;
  style?;
  isSelected;
  user;
};

const OpenDirectoriesRow = ({
  ImgSrc,
  directory,
  handleTextPress,
  handleNextPress,
  style,
  isSelected,
  user,
}: OpenDirectoriesRowProps) => {
  const [hasChildDirectories, setHasChildDirectories] = useState(false);
  useEffect(() => {
    getExerciseDirectories(user.id, directory.id).then(({ response }) => {
      if (response.directories.length) {
        setHasChildDirectories(true);
      } else {
        setHasChildDirectories(false);
      }
    });
  });

  return (
    <View
      style={[
        {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: isSelected ? COLOR_PRIMARY_LIGHT : COLOR_WHITE,
          paddingVertical: 10,
          paddingHorizontal: 15,
        },
        style,
      ]}
    >
      <TouchableOpacity
        onPress={handleTextPress}
        style={[
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minHeight: 24,
            justifyContent: "flex-start",
            width: "75%",
          },
        ]}
      >
        <SVGImage style={{ width: 24, height: 24 }} source={<ImgSrc />} />
        <Text style={[StyleUtils.font_size_16, { marginLeft: 8 }]}>
          {directory.name}
        </Text>
      </TouchableOpacity>

      {hasChildDirectories && (
        <TouchableOpacity onPress={handleNextPress}>
          <SVGImage style={{ width: 24, height: 24 }} source={<Next />} />
        </TouchableOpacity>
      )}
    </View>
  );
};

const ConfirmDeleteActionPopup = ({
  show,
  close,
  onConfirm,
  onReturn,
  deletePopUpText,
}) => {
  return (
    <AnimatedPopUp show={show} close={close} bgColor={COLOR_DANGER}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_white,
        ]}
      >
        {deletePopUpText.header}
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_white,
        ]}
      >
        {deletePopUpText.body}
      </Text>
      <ButtonElement
        colorScheme="danger_white"
        text="Cancelar"
        onPress={onReturn}
        textStyle={[StyleUtils.font_cera_bold, StyleUtils.font_size_16, {}]}
        styte={[
          StyleUtils.shape_btn_default,
          { marginTop: 32, paddingHorizontal: 64 },
        ]}
      />
      <TouchableOpacity style={[{ marginTop: 16 }]} onPress={onConfirm}>
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            { textAlign: "center", textDecorationLine: "underline" },
          ]}
        >
          Deletar mesmo assim
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};

export default MoreOptionsPopUp;
