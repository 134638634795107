import AsyncStorage from "@react-native-community/async-storage";
import { persistCombineReducers, persistStore } from "redux-persist";
import layoutReducer from "./LayoutReducer";
import selectionReducer from "./SelectionReducer";
import userReducer from "./UserReducer";
import { createStore } from "redux";
import homeworkReducer from "./HomeworkReducer";
import searchFilterReducer from "./SearchFilterReducer";
import signupReducer from "./SignUpReducer";
import infoReducer from "./InfoReducer";
import subjectTestReducer from "./SubjectTestReducer";
import paymentReducer from "./PaymentReducer";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: ["userRed", "selRed"],
};

const pReducer = persistCombineReducers(persistConfig, {
  selRed: selectionReducer,
  userRed: userReducer,
  layoutRed: layoutReducer,
  homeworkRed: homeworkReducer,
  searchFilterRed: searchFilterReducer,
  signupRed: signupReducer,
  infoRed: infoReducer,
  subjectTestRed: subjectTestReducer,
  paymentRed: paymentReducer,
});

const store = createStore(pReducer);
export const persistor = persistStore(store);

export default store;
