import React, { Component } from "react";
import { TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import IconDelete from "../../assets/iconDelete.svg";

type Props = { sendToCanvas; mobile; zoom? };

type State = {
  canvasSize;
  canvasMargin;
  canvasFixedSize;

  selectionControlsPosition;
  selectionID;
  lastAction;
};

let startTime, endTime;

export default class CanvasSelectionTools extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      canvasSize: null,
      canvasMargin: null,
      canvasFixedSize: null,
      selectionControlsPosition: null,
      selectionID: null,
      lastAction: null,
    };
  }

  render() {
    return (
      <View
        style={{
          position: "absolute",
          flex: 1,
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        }}
      >
        {this.state.selectionControlsPosition && this.renderSelectionTools()}
      </View>
    );
  }

  renderSelectionTools() {
    let leftVal =
      this.state.selectionControlsPosition.left *
        (this.state.canvasSize.width / this.state.canvasFixedSize.width) +
      this.state.canvasMargin.width;
    let topVal =
      this.state.selectionControlsPosition.top *
        (this.state.canvasSize.height / this.state.canvasFixedSize.height) +
      this.state.canvasMargin.height;
    if (this.props.zoom) {
      topVal =
        this.state.selectionControlsPosition.top *
          (this.state.canvasSize.height / this.state.canvasFixedSize.height) +
        this.state.canvasMargin.height;
    }
    return (
      <View
        style={[
          StyleUtils.shape_btn_small,
          StyleUtils.border_color_btn_muted,
          StyleUtils.color_bg_white,
          {
            shadowOpacity: 0.05,
            shadowRadius: 4,
            shadowOffset: { width: 0, height: 4 },
            flexDirection: "row",
            position: "absolute",
            left: this.props.mobile ? leftVal : leftVal + 50,
            top: this.props.mobile ? topVal : topVal - 30,
          },
        ]}
      >
        <TouchableOpacity
          onPress={() =>
            this.props.sendToCanvas("removeCurrentSelection", null)
          }
        >
          <SVGImage source={<IconDelete />} />
        </TouchableOpacity>
      </View>
    );
  }

  handleCanvasAction = (data) => {
    switch (data.info.action) {
      case "canvas:layout":
        if (this.props.zoom) {
          data.info.canvasSize.height = data.info.canvasSize.height / 1.5;
          data.info.canvasMargin.height =
            data.info.canvasMargin.height + 197.25;
        }
        this.setState({
          canvasSize: data.info.canvasSize,
          canvasMargin: data.info.canvasMargin,
          canvasFixedSize: data.info.canvasFixedSize,
        });
        break;
      case "selection:created":
        this.start();
        if (this.props.zoom) {
          data.info.target.top = data.info.target.top * 1.5 - 20;
        }
        this.setState({
          selectionID: data.info.target.id,
          selectionControlsPosition: {
            top: data.info.target.top,
            left: data.info.target.left,
          },
        });
        break;
      case "selection:cleared":
        this.setState({
          selectionID: null,
          selectionControlsPosition: null,
        });
        break;
      case "mouse:down":
        this.end();
        var timeDiff = endTime - startTime; //in ms
        // strip the ms
        timeDiff /= 1000;
        // get seconds
        var seconds = Math.round(timeDiff);
        if (seconds > 0) {
          this.setState({
            selectionID: null,
            selectionControlsPosition: null,
          });
        }
        break;
      case "cursor:moved":
        if (this.props.mobile) {
          if (
            this.state.lastAction === "cursor:moved" ||
            this.state.lastAction === "mouse:down"
          ) {
            this.setState({
              selectionID: null,
              selectionControlsPosition: null,
            });
          }
        }
        break;
      case "object:modifying":
        this.setState({
          selectionID: null,
          selectionControlsPosition: null,
        });
        break;
    }
    this.setState({
      lastAction: data.info.action,
    });
  };

  start() {
    startTime = new Date();
  }

  end() {
    endTime = new Date();
  }
}
