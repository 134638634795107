import SubjectTest, {
  subjectTestStats,
  SubjectTestState,
  subjectTestState,
} from "../Models/SubjectTest";

const initialState = {
  subjectTests: null as SubjectTest[] | null,
  pendingAnswer: 0,
  pendingCorrection: 0,
  pendingComplete: 0,
  notification: false,
};

const subjectTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUBJECT_TESTS":
      let subjectTests: SubjectTest[] = action.subjectTests || [];
      const otherStates = subjectTests.reduce(
        (acc, subjectTest) => {
          const hmState = subjectTestState(subjectTest);
          const stats = subjectTestStats(subjectTest);
          if (hmState == SubjectTestState.SUBMITTED) {
          }
          if (hmState < SubjectTestState.COMPLETED) {
            acc.pendingComplete += stats.reviewed;
            acc.pendingAnswer += stats.pending;
            acc.pendingAnswer += stats.reviewed;
            if (
              hmState == SubjectTestState.SUBMITTED ||
              hmState == SubjectTestState.CORRECTED
            ) {
              acc.notification = acc.notification || !action.isTutor;
            } else if (hmState == SubjectTestState.ANSWERED) {
              acc.notification = acc.notification || !!action.isTutor;
              acc.pendingCorrection += stats.pendingCorrection;
            }
          }
          return acc;
        },
        {
          pendingAnswer: 0,
          pendingCorrection: 0,
          pendingComplete: 0,
          notification: false,
        }
      );
      return {
        ...state,
        subjectTests: subjectTests,
        ...otherStates,
      };
    default:
      return state;
  }
};
export default subjectTestReducer;
