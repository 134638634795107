import React, { Component } from "react";
import { Text, TouchableOpacity, NativeModules } from "react-native";
import StyleUtils, { COLOR_PRIMARY } from "../../Utils/StyleUtils";
import AnimatedPopUp from "../../SignUp/AnimatedPopUp";

type Props = {
  show;
  close;
};

type State = {};

export default class NotificationPopUp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AnimatedPopUp
        show={this.props.show}
        close={this.props.close}
        notification={true}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_20,
            StyleUtils.color_txt_dark,
            {
              textAlign: "center",
            },
          ]}
        >
          Ative suas notificações para{"\n"} receber sua resposta
        </Text>

        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            {
              height: 48,
              justifyContent: "center",
              marginHorizontal: 32,
              marginTop: 24,
              backgroundColor: COLOR_PRIMARY,
              width: "100%",
            },
          ]}
          onPress={() => {
            NativeModules.NotificationsBridge.requestNotifications();
            this.props.close();
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              {
                textAlign: "center",
                paddingTop: 4,
              },
            ]}
          >
            Permitir Notificações
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            this.props.close();
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_16,
              { textDecorationLine: "underline", marginTop: 24 },
            ]}
          >
            Agora não
          </Text>
        </TouchableOpacity>
      </AnimatedPopUp>
    );
  }
}
