import React from "react";
import { TouchableOpacity, View } from "react-native";
import User from "../Models/User";
import TuteeInfo from "../Models/TuteeInfo";
import TutorInfo from "../Models/TutorInfo";
import SignUpUser from "../Models/SignUpUser";
import { loginSuccessful, setSignUpUserInfo } from "./../Actions";
import {
  default as appleAuth,
  AppleAuthRequestOperation,
  AppleAuthRequestScope,
  AppleAuthCredentialState,
} from "./AppleAuthWrapper";
import { connect } from "react-redux";
import { serverLogin, validateAppleId } from "./../Server";
import { withRouter } from "./../Router";
import { uuidv4 } from "../Utils/Utils";
import { analytics } from "../Analytics";
import LinearGradient from "react-native-linear-gradient";
import { createShimmerPlaceholder } from "react-native-shimmer-placeholder";
import StyleUtils from "../Utils/StyleUtils";

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

const INVALID_REQUEST = -1;
const VALID_TOKEN_NO_USER = 0;
const VALID_TOKEN_EXISTING_USER = 1;
const HIDDEN_EMAIL = "hiddenby@apple.com";

type Props = {
  history;
  children?: any;
  setSignUpUserInfo: (user: SignUpUser) => void;
  loginSuccessful: (
    user: User,
    tuteeInfo?: TuteeInfo,
    tutorInfo?: TutorInfo
  ) => void;
  isLogin;
  onLoginFailure;
  onSignUpFailure;
  onLoginSuccess;
  onSignUpSuccess;
};

type State = {
  loading: boolean;
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccessful: (
      user: User,
      tuteeInfo?: TuteeInfo,
      tutorInfo?: TutorInfo
    ) => dispatch(loginSuccessful(user, tuteeInfo, tutorInfo)),
    setSignUpUserInfo: (user: User) => dispatch(setSignUpUserInfo(user)),
    dispatch: dispatch,
  };
}

class AppleSigninButton extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  login = (user: SignUpUser, onSuccessLogin) => {
    serverLogin(
      user.name,
      user.email,
      user.imageURL ? user.imageURL : "",
      user.gender,
      user.birthday,
      user.appleId
    ).then((loginResponse) => {
      this.props.loginSuccessful(
        loginResponse.user,
        loginResponse.tuteeInfo,
        loginResponse.tutorInfo
      );
      onSuccessLogin();
    });
  };

  signIn = async () => {
    let appleResponseHolder;

    appleAuth
      .performRequest({
        requestedOperation: AppleAuthRequestOperation.LOGIN,
        requestedScopes: [
          AppleAuthRequestScope.EMAIL,
          AppleAuthRequestScope.FULL_NAME,
        ],
      })
      .then((appleResponse) => {
        this.setState({ loading: true });
        appleResponseHolder = appleResponse;
        return appleAuth.getCredentialStateForUser(appleResponse.user);
      })
      .then((credentialState) => {
        if (credentialState !== AppleAuthCredentialState.AUTHORIZED) {
          throw Error("Unauthorized");
        }
      })
      .then(() => {
        return validateAppleId(
          appleResponseHolder.identityToken,
          appleResponseHolder.nonce
        );
      })
      .then(async (validation) => {
        switch (validation.code) {
          case VALID_TOKEN_NO_USER: {
            const firstName = appleResponseHolder.fullName.givenName
              ? appleResponseHolder.fullName.givenName
              : "";
            const middleName = appleResponseHolder.fullName.middleName
              ? appleResponseHolder.fullName.middleName
              : "";
            const familyName = appleResponseHolder.fullName.familyName
              ? appleResponseHolder.fullName.familyName
              : "";
            const fullName = (
              (firstName + " " + middleName).trim() +
              " " +
              familyName
            ).trim();
            const email = appleResponseHolder.email
              ? appleResponseHolder.email
              : uuidv4() + "@apple.com";

            this.login(
              {
                email: email,
                name: fullName,
                imageURL: "",
                appleId: appleResponseHolder.user,
              },
              () => {
                analytics.sendSignUp("apple");
                this.props.onSignUpSuccess();
              }
            );

            break;
          }

          case VALID_TOKEN_EXISTING_USER: {
            if (validation.user) {
              this.login(validation.user, () => {
                analytics.sendLogin("apple");
                this.props.onLoginSuccess();
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (this.props.isLogin) {
          analytics.sendLoginFailed("apple");
          this.props.onLoginFailure();
        } else {
          analytics.sendSignUpFailed("apple");
          this.props.onSignUpFailure();
        }

        this.setState({ loading: false });
      });
  };

  render() {
    return this.state.loading ? (
      <ShimmerPlaceHolder
        style={[StyleUtils.shape_card_default, { width: "95%", height: 40 }]}
      />
    ) : (
      <View style={{ alignItems: "center", marginBottom: 60 }}>
        <TouchableOpacity onPress={this.signIn}>
          {this.props.children}
        </TouchableOpacity>
      </View>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(AppleSigninButton));
