import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Image,
  Dimensions,
} from "react-native";
import BackgroundShapesBlue from "../../assets/backgroundShapesBlue.svg";
import CorrectIconBig from "../../assets/checkBlueSpiky.svg";
import BlueBarLong from "../../assets/blueBarLong.svg";
import BlueBarShort from "../../assets/blueBarShort.svg";
import DotGreen from "../../assets/dotGreen.svg";
import { UserImage } from "../Elements/UserImages";
import StyleUtils, {
  COLOR_TXT_DARK,
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import StarYellow from "../../assets/starYellow.svg";
import DotBlack from "../../assets/dotBlack.svg";
import SignUpPopUp from "../SignUp/SignUpPopUp";
import TutorMatchSearching from "./TutorMatchSearching";

const styles = StyleSheet.create({
  textBoldBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
  },
  textRegular: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
  },
  black_text_regular_14: {
    ...StyleSheet.flatten(StyleUtils.font_size_12),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
});

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

type Props = {
  history;
  photo;
  comment;
  subject;
  subjectLevel;
};
type State = {
  showLogin;
  played;
};

var dimen = Dimensions.get("window");
dimen.width = dimen.width < 375 ? 375 : dimen.width;

function isIphoneXorAbove() {
  return (
    Platform.OS === "ios" &&
    !Platform.isPad &&
    !Platform.isTVOS &&
    dimen.height > 800 &&
    dimen.height < 1000 &&
    dimen.width < 1000
  );
}

const allisonId = "4a60c1ae-532c-4dd1-8352-d11577e2f0e2";

export class TutorMatchExercise extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      played: false,
    };
  }

  onLoginSuccessPopUp = () => {
    this.props.history.push({
      pathname: "/messages/" + allisonId,
      state: {
        shouldGoBack: false,
        messages: this.props.comment,
        image: this.props.photo,
        exerciseOnboarding: true,
      },
    });
  };
  onSignUpSuccessPopUp = () => {
    this.props.history.push({
      pathname: "/messages/" + allisonId,
      state: {
        shouldGoBack: false,
        messages: this.props.comment,
        image: this.props.photo,
        exerciseOnboarding: true,
      },
    });
  };

  render() {
    if (!this.state.played) {
      return (
        <TutorMatchSearching
          searching={true}
          onVideoEnded={() =>
            this.setState({
              played: true,
            })
          }
          loadingDescription={"Encontrando seu Tutor..."}
          isLowFriction={true}
        />
      );
    }
    return (
      <View
        style={[
          StyleUtils.screen_default,
          {
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: COLOR_PRIMARY_LIGHT,
          },
        ]}
      >
        <SVGImage
          source={<BackgroundShapesBlue />}
          style={{
            marginTop: isIphoneXorAbove() ? 120 : 100,
            zIndex: 0,
            position: "absolute",
            width: "100%",
            height: 332,
            alignItems: "center",
          }}
        />
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <UserImage
            style={{
              width: 133,
              height: 142,
              borderRadius: 15,
              top: isIphoneXorAbove() ? 80 : 60,
              borderColor: "#FFC93D",
              borderWidth: 5,
            }}
            imageURL={"https://i.imgur.com/XoihF8U.jpg"}
          />
          <View style={{ position: "absolute", zIndex: 0 }}>
            <SVGImage
              source={<CorrectIconBig />}
              style={{
                marginLeft: 220,
                marginRight: 90,
                marginTop: isIphoneXorAbove() ? 300 : 260,
              }}
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            marginTop:
              dimen.height < 800
                ? dimen.height * -0.1564 + 130.1959
                : dimen.height * -0.1564 + 140.1959,
            borderRadius: 8,
            backgroundColor: "#FFFFFF",
            opacity: 0.8,
            width: 65,
            marginLeft: dimen.width * (7 / 15) - 40,
          }}
        >
          <SVGImage
            source={<DotGreen />}
            style={{
              marginTop: 7,
              marginLeft: 6,
            }}
          />
          <Text
            style={[
              styles.black_text_regular_14,
              {
                fontWeight: "bold",
                marginLeft: 4,
                marginTop: 7,
                lineHeight: 14,
              },
            ]}
          >
            Online
          </Text>
        </View>
        <View
          style={{
            marginTop: 30,
            flexDirection: "row",
            alignItems: "center",
            zIndex: 1,
            marginBottom: 8,
            justifyContent: "center",
          }}
        >
          <SVGImage
            source={<StarYellow />}
            style={[
              Platform.OS === "ios" && { paddingBottom: 8 },
              Platform.OS === "android" && { paddingTop: 2 },
            ]}
          />
          <Text
            style={[
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_22,
              StyleUtils.font_cera_bold,

              {
                paddingHorizontal: 4,
              },
            ]}
          >
            4.9
          </Text>
          <Text style={[styles.textRegular]}>(120) </Text>
          <SVGImage
            source={<DotBlack />}
            style={{
              paddingRight: 4,
              paddingBottom: 4,
            }}
          />
          <Text style={[styles.textRegular]}>1,250 soluções</Text>
        </View>
        <Text
          style={[
            StyleUtils.font_cera_medium,
            StyleUtils.font_size_22,
            {
              marginBottom: 8,
              color: COLOR_TXT_DARK,
              textAlign: "center",
            },
          ]}
        >
          <B>Allison</B> está esperando você{"\n"}para te ajudar!
        </Text>
        <View>
          <Text
            style={[
              StyleUtils.font_cera_medium,
              StyleUtils.font_size_12,
              {
                color: COLOR_TXT_DARK,
                marginLeft: dimen.width / 3 - 68,
                marginBottom: 3,
              },
            ]}
          >
            {this.props.subject.name} {this.props.subjectLevel.name}
          </Text>
          <SVGImage
            source={<BlueBarLong />}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 5,
            }}
          />
          <Text
            style={[
              StyleUtils.font_cera_medium,
              StyleUtils.font_size_12,
              {
                color: COLOR_TXT_DARK,
                marginLeft: dimen.width / 3 - 68,
                marginVertical: 3,
              },
            ]}
          >
            Didática
          </Text>
          <SVGImage
            source={<BlueBarShort />}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 15,
          }}
        >
          <Image
            source={{ uri: this.props.photo }}
            style={{
              width: 150,
              height: 150,
              borderRadius: 6,
            }}
          />
        </View>
        <View style={{ justifyContent: "flex-end" }}>
          <TouchableOpacity
            style={[
              {
                marginHorizontal: 24,
                marginBottom: 40,
                backgroundColor: COLOR_PRIMARY,
              },
              StyleUtils.shape_btn_small,
            ]}
            onPress={() => {
              setTimeout(() => {
                this.setState({ showLogin: true });
              }, 300);
            }}
          >
            <Text
              style={[
                { textAlign: "center", paddingVertical: 16 },
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                StyleUtils.color_txt_white,
              ]}
            >
              Resolver agora
            </Text>
          </TouchableOpacity>
        </View>
        <SignUpPopUp
          show={this.state.showLogin}
          isLogin={false}
          onLoginSuccess={this.onLoginSuccessPopUp}
          onSignUpSuccess={this.onSignUpSuccessPopUp}
          close={() => {
            this.setState({ showLogin: false });
          }}
          history={this.props.history}
          isExerciseOnboarding={true}
        />
      </View>
    );
  }
}

export default TutorMatchExercise;
