import React from "react";
import { Component } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import BackButton from "../../assets/backButtonCalendar.svg";
import ForwardButton from "../../assets/forwardButtonCalendar.svg";
import CalendarDay from "./CalendarDay";
import { connect } from "react-redux";
import * as Utils from "../Utils/Utils";

type Props = {
  scheduleDraftDays;
  changeDate;
  style;
  month?: Date;
  onMonthChange?: (month: Date) => void;
  dayOptions?: (day: Date) => { highlighted?: boolean; checked?: boolean };
};

type State = {
  month: Date;
  selectedDate: Date;
};

function dispatchToProps(dispatch) {
  return {};
}

function stateToProps(state: any) {
  return {
    scheduleDraftDays: state.selRed.scheduleDraftDays,
  };
}

class Calendar extends Component<Props, State> {
  constructor(props) {
    super(props);
    const refDate = new Date();
    refDate.setHours(0, 0, 0, 0);
    this.state = {
      month: Utils.nextMonth(props.month, 0),
      selectedDate: props.selectedDate,
    };
  }

  daysOfWeek = () => {
    const date = this.state.month;
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let days: any;
    days = [];
    let key = 0;
    for (let i = -firstDay.getDay() + 1; i <= lastDay.getDate(); i++) {
      const curDate = new Date(date.getFullYear(), date.getMonth(), i);
      let dayOptions = this.props.dayOptions && this.props.dayOptions(curDate);
      days.push(
        <CalendarDay
          key={key++}
          date={curDate}
          refMonth={this.state.month}
          selected={this.state.selectedDate.getTime() === curDate.getTime()}
          enabled={
            dayOptions
              ? dayOptions.highlighted
              : this.props.scheduleDraftDays.includes(String(curDate.getDay()))
          }
          hasEvent={!!dayOptions && !!dayOptions.checked}
          select={(date) => {
            this.setState({
              selectedDate: date,
            });
            this.props.changeDate(date);
          }}
        />
      );
    }
    return days;
  };

  backMonth = () => {
    const previousMonth = Utils.nextMonth(this.state.month, -1);
    if (this.props.onMonthChange) {
      this.props.onMonthChange(previousMonth);
    }
    this.setState({
      month: previousMonth,
    });
  };

  forwardMonth = () => {
    const nextMonth = Utils.nextMonth(this.state.month, 1);
    if (this.props.onMonthChange) {
      this.props.onMonthChange(nextMonth);
    }
    this.setState({
      month: nextMonth,
    });
  };

  render() {
    return (
      <View
        style={[
          StyleUtils.color_bg_dark_bold,
          { overflow: "hidden" },
          ...this.props.style,
        ]}
      >
        <View style={{ flexDirection: "row", margin: 16, marginBottom: 0 }}>
          <Text
            style={[
              StyleUtils.font_size_20,
              StyleUtils.color_txt_white,
              StyleUtils.font_btn_bold,
              { margin: 5, textTransform: "capitalize" },
            ]}
          >
            {this.state.month.toLocaleString("pt-br", { month: "long" })}
          </Text>
          <Text
            style={[
              StyleUtils.font_size_20,
              StyleUtils.color_txt_light,
              StyleUtils.font_btn_bold,
              { margin: 5 },
            ]}
          >
            {this.state.month.getFullYear()}
          </Text>
          <View style={{ flexGrow: 1 }} />
          <TouchableOpacity
            onPress={this.backMonth}
            style={{ marginRight: 10 }}
          >
            <SVGImage source={<BackButton />} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={this.forwardMonth}
            style={{ marginRight: 10 }}
          >
            <SVGImage source={<ForwardButton />} />
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            margin: 5,
          }}
        >
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Dom
          </Text>
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Seg
          </Text>
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Ter
          </Text>
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Qua
          </Text>
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Qui
          </Text>
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Sex
          </Text>
          <Text style={[StyleUtils.font_size_14, StyleUtils.color_txt_light]}>
            Sab
          </Text>
        </View>

        <View
          style={[
            {
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              padding: 5,
              paddingBottom: 10,
            },
            StyleUtils.color_bg_overlay,
          ]}
        >
          {this.daysOfWeek()}
        </View>
      </View>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(Calendar);
