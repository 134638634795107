import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  areas;
  onContinue;
  onBack;
  progress;
  question;
};

type State = {
  selectedSingle: string | null;
  selectedGoals: string | null;
  otherGoals;
};

export default class OneMultipleChoiceTemplate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSingle: null,
      selectedGoals: null,
      otherGoals: "",
    };
  }
  private myScrollView: any;

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedGoals: null });
      this.setState({ selectedSingle: null });
    } else {
      if (item.name === "Outros") this.setState({ selectedGoals: item });
      else this.setState({ selectedGoals: null });
      this.setState({ selectedSingle: item });
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;

    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: item.id % 2 ? 0 : 12,
            marginLeft: item.id % 2 ? 12 : 0,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text style={[styles.cellText]}>{item.name}</Text>
      </TouchableOpacity>
    );
  };

  otherGoalsText = () => {
    if (this.state.selectedGoals) {
      return this.state.otherGoals.length === 0;
    } else {
      return !this.state.selectedSingle;
    }
  };

  render() {
    return (
      <>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : undefined}
          style={[StyleUtils.screen_default]}
          keyboardVerticalOffset={80}
        >
          <ScrollView
            style={{
              flex: 1,
              flexDirection: "column",
              backgroundColor: COLOR_WHITE,
            }}
            ref={(component) => {
              this.myScrollView = component;
            }}
          >
            <View style={[{ justifyContent: "center", width: "100%" }]}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 24,
                }}
              >
                <View style={{ flex: 1 }}>
                  <TouchableOpacity
                    style={{ flexDirection: "row" }}
                    onPress={() => {
                      this.props.onBack();
                    }}
                  >
                    <SVGImage source={<BackArrow />} />
                  </TouchableOpacity>
                </View>
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_dark,
                    StyleUtils.font_size_16,
                  ]}
                >
                  Responda e ganhe uma aula grátis!
                </Text>
                <View style={{ flex: 1 }}></View>
              </View>
            </View>
            <View
              style={{
                borderBottomColor: COLOR_MUTED,
                borderBottomWidth: 1,
                width: "100%",
                zIndex: 10,
              }}
            />
            <View style={styles.container}>
              <View
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: COLOR_PRIMARY,
                  width: this.props.progress,
                }}
              />
            </View>
            <View
              style={{
                paddingHorizontal: 32,
                marginTop: 24,
              }}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_22,
                  {
                    textAlign: "left",
                    width: "100%",
                    marginBottom: 20,
                  },
                ]}
              >
                {this.props.question}
              </Text>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(0, 2)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(2, 4)
                  .map((area) => this.renderLevelCell(area))}
              </View>
              <View style={styles.containerCenter}>
                {this.props.areas
                  .slice(4)
                  .map((area) => this.renderLevelCell(area))}
              </View>

              {this.state.selectedGoals && (
                <>
                  <Text
                    style={[
                      StyleUtils.font_cera_regular,
                      StyleUtils.font_size_14,
                      StyleUtils.color_txt_normal,
                      {
                        textAlign: "left",
                        marginTop: 20,
                      },
                    ]}
                  >
                    Por favor, informe qual objetivo:
                  </Text>
                  <TextInput
                    value={this.state.otherGoals}
                    onChangeText={(text) => {
                      this.setState({ otherGoals: text });
                    }}
                    style={[
                      StyleUtils.font_cera_regular,
                      {
                        borderRadius: 6,
                        color: "black",
                        textAlign: "auto",
                        padding: 8,
                        borderWidth: 1,
                        borderColor: this.state.otherGoals
                          ? "#000000"
                          : COLOR_MUTED,
                        marginTop: 8,
                      },
                    ]}
                    onFocus={() =>
                      this.myScrollView.scrollTo({
                        x: 0,
                        y: 600,
                      })
                    }
                    placeholderTextColor={COLOR_MUTED}
                    placeholder={"Ex.: Estudar fora do país..."}
                  />
                </>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>

        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (this.state.selectedSingle) {
                if (this.state.selectedGoals !== null) {
                  let temp = {
                    id: 4,
                    name: this.state.otherGoals,
                  };
                  analytics.sendSurveyResponse(
                    this.props.question,
                    this.state.otherGoals
                  );
                  this.props.onContinue();
                } else {
                  analytics.sendSurveyResponse(
                    this.props.question,
                    this.state.selectedSingle
                  );
                  this.props.onContinue();
                }
                this.setState({
                  selectedSingle: null,
                  selectedGoals: null,
                  otherGoals: "",
                });
              }
            }}
            disabled={this.otherGoalsText()}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
  cellBase: {
    height: 88,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 25,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: 4,
  },
  progressBar: {
    height: 4,
    width: "100%",
  },
});
