import User from "../Models/User";

const initialState = {
  tutor: null as User | null,
  level: null,
  subject: null,
  timing: null,
  photo: null,
  comment: null,
};

const searchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ONBOARDING_DATA":
      return {
        ...state,
        tutor: action.tutor,
        level: action.level,
        subject: action.subject,
        timing: action.timing,
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
        level: action.level,
        subject: action.subject,
      };
    case "SET_EXERCISE_DATA":
      return {
        ...state,
        photo: action.photo,
        comment: action.comment,
      };
    default:
      return state;
  }
};
export default searchFilterReducer;
