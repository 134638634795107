import React, { Component } from "react";
import { Animated, Easing, View } from "react-native";
import { connect } from "react-redux";
import StyleUtils from "../Utils/StyleUtils";
import TuteeInfo from "../Models/TuteeInfo";
import TutorInfo from "../Models/TutorInfo";
import { loginSuccessful, setSignUpUserInfo } from "../Actions";
import SignUpForm from "./SignUpForm";
import SignUpPhotoUploader from "./SignUpPhotoUpload";
import SignUpFinish from "./SignUpFinish";
import SignUpUser from "../Models/SignUpUser";
import User from "../Models/User";
import { serverLogin } from "../Server";
import { analytics } from "../Analytics";

type Props = {
  history;
  setOnboardingData;
  user?;
  setSignUpUserInfo: (SignUpUser) => void;
  loginSuccessful: (
    user: User,
    tuteeInfo?: TuteeInfo,
    tutorInfo?: TutorInfo
  ) => void;
};

type State = {
  componentStack;
  userData: SignUpUser;
  nextButtonAction;
  isTransitioning;
};

function mapStateToProps(state) {
  return {
    user: state.signupRed.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginSuccessful: (
      user: User,
      tuteeInfo?: TuteeInfo,
      tutorInfo?: TutorInfo
    ) => dispatch(loginSuccessful(user, tuteeInfo, tutorInfo)),
    setSignUpUserInfo: (user: User) => dispatch(setSignUpUserInfo(user)),
    dispatch: dispatch,
  };
}

class SignUpHolder extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);

    this.state = {
      userData: this.props.user,
      nextButtonAction: this.continueFinishSignUp,
      componentStack: [
        <SignUpForm
          userData={this.props.user}
          onNext={(userData) => {
            this.setState({ userData: userData });
            this.continueFinishSignUp();
          }}
          onBack={() => {
            this.props.history.goBack();
          }}
        />,
      ],
      isTransitioning: false,
    };
  }

  executeWithTransition(method) {
    this.setState({ isTransitioning: true });
    Animated.timing(this.fadeAnim, {
      toValue: 0,
      duration: 400,
      easing: Easing.cubic,
      useNativeDriver: true,
    }).start(() => {
      method();
      Animated.timing(this.fadeAnim, {
        toValue: 1,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        this.setState({ isTransitioning: false });
      });
    });
  }

  pushComponent(component) {
    this.executeWithTransition(() => {
      const tempComponents = this.state.componentStack.concat(component);
      this.setState({ componentStack: tempComponents });
    });
  }

  safelyPopComponent = () => {
    if (this.state.isTransitioning) return;
    this.popComponent();
  };

  popComponent = () => {
    this.executeWithTransition(() => {
      const tempComponents = this.state.componentStack;
      tempComponents.pop();
      this.setState({ componentStack: tempComponents });
    });
  };

  continueUploadProfilePhoto = () => {
    if (this.state.isTransitioning) return;

    this.pushComponent(
      <SignUpPhotoUploader
        onBack={this.safelyPopComponent}
        onNext={() => this.continueFinishSignUp()}
        onSkipScreen={() => this.continueFinishSignUp()}
        userData={this.state.userData}
      />
    );
  };

  login = () => {
    let { name, email, gender, birthday, imageURL, appleId } =
      this.state.userData;

    gender = gender ? gender : "";
    birthday = birthday ? birthday : "";
    imageURL = imageURL ? imageURL : "";

    serverLogin(name, email, gender, birthday, imageURL, appleId).then(
      (loginResponse) => {
        this.props.loginSuccessful(
          loginResponse.user,
          loginResponse.tuteeInfo,
          loginResponse.tutorInfo
        );
        this.props.history.goBack();
      }
    );
  };

  continueFinishSignUp = () => {
    if (this.state.isTransitioning) return;

    analytics.sendSignUpFormSubmitted();
    this.pushComponent(
      <SignUpFinish userData={this.state.userData} onNext={this.login} />
    );
  };

  getCurrentComponent() {
    return (
      <Animated.View style={{ flex: 1, opacity: this.fadeAnim }}>
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        {this.getCurrentComponent()}
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpHolder);
