import React, { Component } from "react";
import {
  BackHandler,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { connect } from "react-redux";
import { setTutorSearchBack } from "../Actions";
import ChatConnection from "../Chat/ChatConnection";
import ChatRoom from "../Chat/ChatRoom";
import TuteeInfo from "../Models/TuteeInfo";
import User from "../Models/User";
import { getRelatedUsers, getUserFromId } from "../Server";
import { SCREEN_SIZE_600 } from "../Utils/SizeUtils";
import StyleUtils, { COLOR_BG_LIGHT } from "../Utils/StyleUtils";
import MessengerUserList from "./MessengerUserList";
import { getChannelName } from "../Utils/Chat";

import { useToast } from "react-native-toast-notifications";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs: any = useToast();
    return <Component {...props} toast={toastFuncs} />;
  };
}

// import { callNotification } from "../Notifications/WithNotificationHOC"

// interface IwithNotificationHOC {
//   notification: any
// }

type Props = {
  dispatch;
  history;
  user?;
  tutorInfo?;
  tuteeInfo?: TuteeInfo;
  match;
  location;
  talkspaceOnboarding: boolean;
  toast;
};

type State = {
  selectedUser: any;
  dataSource: any;
  chatConnection: any;
  compactLayout: boolean;
  backHandler;
  loading: boolean;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
    tuteeInfo: state.userRed.tuteeInfo,
    talkspaceOnboarding: state.userRed.talkspaceOnboarding,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
  };
}

class Messenger extends Component<Props, State> {
  constructor(props) {
    super(props);
    const refDate = new Date();
    refDate.setHours(0, 0, 0, 0);
    this.state = {
      selectedUser: undefined,
      compactLayout: true,
      dataSource: [],
      chatConnection: null,
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      loading: true,
    };
  }

  componentDidMount() {
    this.getUsers(false);
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.state.backHandler.remove();
    if (this.state.chatConnection) {
      this.state.chatConnection.classUnmount();
    }
  }

  getUsers(backPress) {
    this.fetchUserList()
      .then((users) => {
        this.setState(
          {
            dataSource: users,
          },
          () => {
            this.selectUserIfNeeded();
          }
        );
        if (backPress) {
          this.setState({ selectedUser: undefined });
        }
      })
      .catch((err) => {
        console.log(`Error: Could not fetch users - ${err}`);
      });
  }

  backPress = () => {
    if (this.state.selectedUser !== undefined) {
      if (this.props.location.state && this.props.location.state.shouldGoBack) {
        if (this.props.location.state.useTutorSearchBack) {
          this.props.dispatch(setTutorSearchBack());
        }
        this.props.history.goBack();
      } else if (
        this.props.location.state &&
        this.props.location.state.exerciseOnboarding
      ) {
        this.setState({ selectedUser: undefined });
      } else if (
        this.props.location.state &&
        this.props.location.state.reschedule
      ) {
        this.props.history.push("/events");
      } else if (
        this.props.location.state &&
        this.props.location.state.schedule
      ) {
        this.props.history.push("/");
      } else if (
        this.props.location.state &&
        this.props.location.state.subjectTest
      ) {
        this.props.history.push("/subjecttests");
      } else if (this.props.talkspaceOnboarding) {
        this.props.history.goBack();
      } else {
        this.props.history.push("/messages");
        this.getUsers(true);
      }
      return true;
    }
    return false;
  };

  fetchUserList = async () => {
    if (!this.props.user) {
      return Promise.reject("Not logged in");
    }

    return getRelatedUsers(this.props.user.id).then(async (res) => {
      let users = res.users;
      if (this.props.match.params.userId) {
        let duplicateUser: User | undefined = undefined;
        for (const user of users) {
          if (user.id === this.props.match.params.userId) {
            duplicateUser = user;
          }
        }

        if (!duplicateUser) {
          const selectedUser = await getUserFromId(
            this.props.match.params.userId
          );
          users.push(selectedUser.user);
        }
      }
      return users;
    });
  };

  selectUserIfNeeded = () => {
    if (this.props.match.params.userId) {
      for (const user of this.state.dataSource) {
        if (user.id === this.props.match.params.userId) {
          this.userSelected(user);
          break;
        }
      }
    }
  };

  userSelected = async (pressedUser) => {
    if (this.state.selectedUser !== pressedUser) {
      if (this.state.chatConnection) {
        await this.state.chatConnection.classUnmount();
      }

      const channel = getChannelName(this.props.user, pressedUser);
      const connection = new ChatConnection(
        this.props.user,
        pressedUser,
        channel,
        this.props.toast
      );
      connection.classMount();

      this.setState({
        selectedUser: pressedUser,
        chatConnection: connection,
      });
    }
  };

  onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let isMobileLayout = layout.width < SCREEN_SIZE_600;
    if (isMobileLayout != this.state.compactLayout) {
      this.setState({
        compactLayout: isMobileLayout,
      });
    }
  };

  render() {
    return (
      !this.state.loading && (
        <SafeAreaView
          style={[
            StyleUtils.screen_default,
            { height: 400 },
            !this.state.compactLayout && { padding: 20 },
          ]}
        >
          <View
            style={[
              {
                height: "100%",
              },
              !this.state.compactLayout && {
                flexDirection: "row",
                borderRadius: 14,
                borderWidth: 1,
                borderColor: COLOR_BG_LIGHT,
                overflow: "hidden",
              },
            ]}
            onLayout={this.onLayout}
          >
            {this.renderMobileLayout()}
            {this.renderDesktopLayout()}
          </View>
        </SafeAreaView>
      )
    );
  }

  renderDesktopLayout(): React.ReactNode {
    if (this.state.compactLayout) {
      return null;
    }
    return (
      <>
        <MessengerUserList
          style={{
            width: 300,
            borderColor: COLOR_BG_LIGHT,
            borderRightWidth: 1,
          }}
          dataSource={this.state.dataSource}
          onUserSelected={(item) => this.userSelected(item)}
          selectedUser={this.state.selectedUser}
          headerText={"Enviar para..."}
          headerStyle={{
            height: 60,
            justifyContent: "flex-end",
          }}
          history={this.props.history}
        ></MessengerUserList>
        {this.state.selectedUser !== undefined && (
          <ChatRoom
            chatConnection={this.state.chatConnection}
            headerInfo={{
              user: this.state.selectedUser,
            }}
            onBack={() => {
              this.backPress();
            }}
            initialMessages={this.state.chatConnection.getInitialMessages()}
            history={this.props.history}
          />
        )}
        {this.state.selectedUser === undefined && (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: COLOR_BG_LIGHT,
            }}
          >
            <Text
              style={[StyleUtils.font_btn_regular, StyleUtils.font_size_18]}
            >
              Selecione um usuário ao lado!
            </Text>
          </View>
        )}
      </>
    );
  }

  previousMessages() {
    if (this.props.location.state && this.props.location.state.messages) {
      return this.props.location.state.messages;
    }
    return undefined;
  }

  exerciseImage() {
    if (this.props.location.state && this.props.location.state.image) {
      return this.props.location.state.image;
    }
    return undefined;
  }

  rescheduleReason() {
    if (this.props.location.state && this.props.location.state.messages) {
      return this.props.location.state.messages;
    }
    return undefined;
  }

  renderMobileLayout(): React.ReactNode {
    if (!this.state.compactLayout) {
      return null;
    }
    return (
      <>
        {this.state.selectedUser === undefined && (
          <View
            style={{
              flexDirection: "row",
              marginLeft: 24,
              marginTop: 21,
              marginBottom: 20,
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_22,
                { marginTop: 10 },
              ]}
            >
              Meus Tutores
            </Text>
          </View>
        )}
        {this.state.selectedUser === undefined && (
          <MessengerUserList
            style={{
              width: "100%",
              borderColor: COLOR_BG_LIGHT,
            }}
            dataSource={this.state.dataSource}
            onUserSelected={(item) => this.userSelected(item)}
            headerText={""}
            headerStyle={{
              height: 60,
              justifyContent: "flex-end",
            }}
            history={this.props.history}
          />
        )}
        {this.state.selectedUser !== undefined && (
          <ChatRoom
            chatConnection={this.state.chatConnection}
            headerInfo={{
              user: this.state.selectedUser,
            }}
            onBack={() => {
              this.backPress();
            }}
            previousMessage={this.previousMessages()}
            exerciseImage={this.exerciseImage()}
            initialMessages={this.state.chatConnection.getInitialMessages()}
            history={this.props.history}
            rescheduleReason={this.rescheduleReason()}
          />
        )}
      </>
    );
  }
}

const style = StyleSheet.create({
  half: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "space-around",
    flexGrow: 0,
    alignItems: "center",
    alignSelf: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  full: {},
});

// @ts-ignore
export default withToast(
  connect(mapStateToProps, mapDispatchToProps)(Messenger)
);
