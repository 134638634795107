import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Alert,
} from "react-native";
import StyleUtils, { COLOR_PRIMARY } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import CloseIcon from "../../assets/ico_close.svg";
import { analytics } from "../Analytics";
import Modal from "react-native-modal";
import { redeemTicket } from "../Server";
import { connect } from "react-redux";

type Props = {
  show;
  close;
  user;
  refetch?;
  closeAll?;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

const CouponPoup = ({ show, close, user, refetch, closeAll }: Props) => {
  const [text, setText] = useState("");
  const handleClosePressOnSuccess = () => {
    close();
    closeAll && closeAll();
  };
  return (
    <Modal animationIn="fadeIn" isVisible={show} onBackdropPress={close}>
      <View style={styles.modalView}>
        <TouchableOpacity
          onPress={close}
          style={{
            marginTop: 10,
            zIndex: 1,
            position: "absolute",
            right: 15,
          }}
        >
          <SVGImage source={<CloseIcon />} />
        </TouchableOpacity>
        <Text style={styles.modalText}>
          Adicionar Cupom que {"\n"}você recebeu:
        </Text>
        <View style={{ flexDirection: "row" }}>
          <TextInput
            style={[StyleUtils.color_txt_dark, { height: 40, width: 150 }]}
            placeholder="Código de cupom"
            onChangeText={(text) => setText(text)}
            defaultValue={text}
            autoFocus={true}
            placeholderTextColor={"#BAC6DA"}
            autoCapitalize="characters"
          />
          <TouchableOpacity
            style={[
              StyleUtils.shape_btn_small,
              {
                height: 40,
                width: 70,
                justifyContent: "center",
                backgroundColor: COLOR_PRIMARY,
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 60,
              },
            ]}
            onPress={() => {
              if (user) {
                redeemTicket(text.toUpperCase(), user.id).then((res) => {
                  if (res["error"]) {
                    analytics.sendCouponFailed();
                    Alert.alert(
                      "Código Inválido",
                      "",
                      [
                        {
                          text: "OK",
                          onPress: close,
                        },
                      ],
                      { cancelable: false }
                    );
                  } else {
                    analytics.sendCouponSuccess(text.toUpperCase());
                    refetch && refetch();
                    Alert.alert(
                      "Créditos adicionados!",
                      "",
                      [
                        {
                          text: "OK",
                          onPress: handleClosePressOnSuccess,
                        },
                      ],
                      { cancelable: false }
                    );
                  }
                });
                setText("");
              }
            }}
          >
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                {
                  textAlign: "center",
                },
              ]}
            >
              Aplicar
            </Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={close}
          style={{
            marginTop: 25,
          }}
        >
          <Text
            style={[
              styles.modalText,
              {
                textDecorationLine: "underline",
                alignItems: "center",
                textAlign: "center",
              },
            ]}
          >
            Cancelar
          </Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default connect(mapStateToProps, null)(CouponPoup);

const styles = StyleSheet.create({
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 30,
    paddingTop: 50,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "500",
  },
});
