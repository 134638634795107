import User from "../Models/User";

type initialStateType = {
  user: User | any;
  userWithoutLogin: any;
  tuteeInfo: any;
  tutorInfo: any;
  viewedHome: any;
  viewedFreeCreditPopUp: any;
  talkspaceOnboarding: boolean;
  seenTalkspaceFirstMessages: boolean;
  unreadMessageCounselor: boolean;
  viewedSurvey: any;
  fromDynamicLink: any;
};

const initialState: initialStateType = {
  user: null,
  userWithoutLogin: null,
  tuteeInfo: null,
  tutorInfo: null,
  viewedHome: false,
  viewedFreeCreditPopUp: false,
  talkspaceOnboarding: false,
  seenTalkspaceFirstMessages: false,
  unreadMessageCounselor: false,
  viewedSurvey: false,
  fromDynamicLink: false,
};

const userReducer = (state = initialState, action) => {
  let tuteeInfo: any = null;
  switch (action.type) {
    case "NO_LOGIN_USER":
      return {
        ...state,
        userWithoutLogin: action.userWithoutLogin,
      };
    case "LOGIN_SUCCESSFUL":
      let tutorInfo: any = null;
      if (action.tuteeInfo) {
        tuteeInfo = {
          preferredTutor: action.tuteeInfo.preferredTutor,
        };
      }
      if (action.tutorInfo)
        tutorInfo = {
          schedule: action.tutorInfo.schedule,
        };
      return {
        ...state,
        user: action.user,
        tuteeInfo: tuteeInfo,
        tutorInfo: tutorInfo,
      };
    case "LOGOUT":
      return initialState;
    case "SET_TUTEE_INFO":
      if (action.tuteeInfo) {
        tuteeInfo = {
          preferredTutor: action.tuteeInfo.preferredTutor,
        };
      }
      return {
        ...state,
        tuteeInfo: tuteeInfo,
      };
    case "SET_TUTOR_INFO":
      if (action.tutorInfo && action.tutorInfo.schedule) {
        const { schedule } = action.tutorInfo;
        return {
          ...state,
          tutorInfo: { schedule },
        };
      }
      return state;
    case "SET_VIEWED_HOME":
      return {
        ...state,
        viewedHome: true,
      };
    case "SET_VIEWED_FREE_CREDIT_POP_UP":
      return {
        ...state,
        viewedFreeCreditPopUp: action.value,
      };
    case "SET_VIEWED_FREE_CREDIT_POP_UP":
      return {
        ...state,
        viewedFreeCreditPopUp: true,
      };
    case "SET_COUNSELOR":
      return {
        ...state,
        user: { ...state.user, counselor: action.counselor },
      };
    case "SET_TALKSPACE_ONBOARDING":
      return {
        ...state,
        talkspaceOnboarding: action.value,
      };
    case "SET_SEEN_TALKSPACE_FIRST_MESSAGES":
      return {
        ...state,
        seenTalkspaceFirstMessages: true,
      };
    case "SET_NEW_COUNSELOR_MESSAGES":
      return {
        ...state,
        unreadMessageCounselor: action.value,
      };
    case "SET_VIEWED_SURVEY":
      return {
        ...state,
        viewedSurvey: true,
      };
    case "SET_FROM_DYNAMIC_LINK":
      return {
        ...state,
        fromDynamicLink: true,
      };
    default:
      return state;
  }
};
export default userReducer;
