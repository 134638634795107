import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../../Utils/StyleUtils";
import ChildDirectoriesColumn from "./ChildDirectoriesColumn";
import FileListColumn from "./FileListColumn";
import {
  deleteExercise,
  getExerciseDirectories,
  updateExercise,
} from "../../Server";
import User from "../../Models/User";
import { useEffect } from "react";
import ExerciseDisplay from "./ExerciseDisplay";
import useControlHeld from "../../hooks/useControlHeld";
import MoreOptionsPopUp from "./MoreOptionsPopUp";
import IconDelete from "../../../assets/iconDelete.svg";
import Archive from "../../../assets/archive.svg";
import SendIcon from "../../../assets/sendIconGray.svg";
import AssignHomework from "../../AssignHomework/AssignHomework";
import useContextMenuClick from "../../hooks/useContextMenuClick";
import useEsc from "../../hooks/useEsc";
import ButtonElement from "../../Elements/ButtonElement";

type Props = { user: User | undefined; history };

type State = {
  currentDirectory: {
    id: any;
    name: any;
    parentId: any;
  };
  exercises: any[];
  directories: any[];
  selectedExercises: any[];
};

const ExerciseDirectories = ({ user, history }: Props) => {
  const initialState: State = {
    currentDirectory: { id: undefined, name: undefined, parentId: undefined },
    exercises: [],
    directories: [],
    selectedExercises: [],
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let directoryId = undefined;
    if (history.location.state) {
      directoryId = history.location.state.directoryId;
    }
    getExerciseDirectories(user!.id, directoryId).then(({ response }) => {
      setState((oldState) => ({
        ...oldState,
        currentDirectory: response.directory,
        exercises: response.exercises,
        directories: response.directories,
      }));
    });
  }, []);

  const handleDirectoryPress = (directoryId) => {
    getExerciseDirectories(user!.id, directoryId).then(({ response }) => {
      setState((oldState: State) => {
        return {
          selectedExercises: oldState.selectedExercises,
          exercises: response.exercises,
          directories: response.directories,
          currentDirectory: response.directory,
        };
      });
    });
  };

  const handleDirectoryBackButtonPress = (directory) => {
    getExerciseDirectories(user!.id, directory.parentId).then(
      ({ response }) => {
        setState((oldState: State) => {
          return {
            selectedExercises: oldState.selectedExercises,
            exercises: response.exercises,
            directories: response.directories,
            currentDirectory: response.directory,
          };
        });
      }
    );
  };

  const refetchData = (directoryId) => {
    getExerciseDirectories(user!.id, directoryId).then(({ response }) => {
      setState((oldState) => {
        return {
          selectedExercises: oldState.selectedExercises,
          exercises: response.exercises,
          directories: response.directories,
          currentDirectory: response.directory,
        };
      });
    });
  };

  const [exerciseDisplay, setExerciseDisplay] = useState<any>({
    show: false,
    exercise: undefined,
  });

  const controlHeld = useControlHeld();
  useContextMenuClick((e) => {
    setMoreOptionsPopUpState({
      show: true,
      position: {
        left: e.clientX,
        top: e.clientY,
      },
    });
  });
  useEsc(() => {
    setState((oldState) => {
      return {
        ...oldState,
        selectedExercises: [],
      };
    });
  });

  const handleExerciseClick = (exercise) => {
    setExerciseDisplay({ show: true, exercise: exercise });
    if (!controlHeld) {
      setState((oldState: State) => {
        return {
          ...oldState,
          selectedExercises: [exercise],
        };
      });
    } else {
      if (
        !state.selectedExercises.filter((ex) => ex.id === exercise.id).length
      ) {
        setState((oldState: State) => {
          return {
            ...oldState,
            selectedExercises: [...oldState.selectedExercises, exercise],
          };
        });
      } else {
        setState((oldState: State) => {
          return {
            ...oldState,
            selectedExercises: oldState.selectedExercises.filter(
              (ex) => ex.id !== exercise.id
            ),
          };
        });
      }
    }
  };

  const [moreOptionsPopUpState, setMoreOptionsPopUpState] = useState({
    show: false,
    position: { top: 0, left: 0 },
  });

  const [showUserSelection, setShowUserSelection] = useState(false);

  const handleSendExercisesPress = () => {
    setShowUserSelection(true);
  };

  const handleDeleteExercisesPress = () => {
    try {
      selectedExercises.forEach(async (exercise) => {
        await deleteExercise(user!.id, exercise.id);
      });
      setState((oldState: State) => {
        return {
          ...oldState,
          selectedExercises: [],
        };
      });
      refetchData(currentDirectory.id);
    } catch (err) {
      console.log(err);
      history.replace("/exerciseDirectories");
    }
  };

  const handleMoveExercisesToDirectory = (newParentId) => {
    try {
      selectedExercises.forEach(async (exercise) => {
        await updateExercise(
          user!.id,
          exercise.id,
          exercise.name,
          exercise.description,
          newParentId
        );
      });
      setState((oldState: State) => {
        return {
          ...oldState,
          selectedExercises: [],
        };
      });
      return { exercise: true };
    } catch (err) {
      console.log(err);
      history.replace("/exerciseDirectories");
    }
  };

  const { exercises, directories, currentDirectory, selectedExercises } = state;
  const currentDirectorySize = exercises.length;

  const propSelectedExercises = {};
  selectedExercises.forEach((ex) => (propSelectedExercises[ex.id] = ex));

  const disablePopUpOptions = !selectedExercises.length;
  const options = [
    {
      img: SendIcon,
      text: "Enviar exercício(s)",
      handlePress: handleSendExercisesPress,
      disabled: disablePopUpOptions,
    },
    {
      img: Archive,
      text: "Mover para pasta",
      handlePress: handleMoveExercisesToDirectory,
      disabled: disablePopUpOptions,
    },
    {
      img: IconDelete,
      text: "Excluir",
      handlePress: handleDeleteExercisesPress,
      disabled: disablePopUpOptions,
    },
  ];

  const deletePopUpText = {
    header: "Você realmente deseja deletar esse(s) exercício(s)?",
    body: "Você não poderá\nrecuperá-los nunca mais",
  };

  return showUserSelection ? (
    <AssignHomework
      page="userSelection"
      selectedExercises={propSelectedExercises}
      history={history}
      user={user}
      handleBackButton={() => {
        setShowUserSelection(false);
      }}
    />
  ) : (
    <>
      {moreOptionsPopUpState.show && (
        <MoreOptionsPopUp
          refetchData={refetchData}
          user={user}
          history={history}
          directory={currentDirectory}
          closePopUp={() => {
            setMoreOptionsPopUpState((oldState) => {
              return { ...oldState, show: false };
            });
          }}
          deletePopUpText={deletePopUpText}
          headerText={`${state.selectedExercises.length} exercício(s) selecionado(s)`}
          options={options}
          style={{
            width: 260,
            zIndex: 999,
            position: "absolute",
            top: moreOptionsPopUpState.position.top,
            left: moreOptionsPopUpState.position.left,
          }}
        />
      )}
      <View
        style={{
          marginHorizontal: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              history.replace("/exerciseDirectories");
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                { marginVertical: 40 },
              ]}
            >
              Meus Exercícios
            </Text>
          </TouchableOpacity>
          <ButtonElement
            colorScheme="primary"
            styte={[StyleUtils.shape_card_default, { marginLeft: 687 }]}
            text={`Enviar ${selectedExercises.length} exercício(s)`}
            disabled={!selectedExercises.length}
            onPress={handleSendExercisesPress}
          />
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ChildDirectoriesColumn
            refetchData={refetchData}
            handleDirectoryBackButtonPress={handleDirectoryBackButtonPress}
            userId={user!.id}
            history={history}
            directories={directories}
            selectedExercises={selectedExercises}
            currentDirectory={currentDirectory}
            currentDirectorySize={currentDirectorySize}
            onDirectoryPress={handleDirectoryPress}
          />
          <FileListColumn
            user={user}
            files={exercises}
            currentDirectory={currentDirectory}
            currentDirectorySize={currentDirectorySize}
            history={history}
            style={{ width: exerciseDisplay.show ? 550 : 850 }}
            handleExerciseClick={handleExerciseClick}
            selectedExercises={state.selectedExercises}
            refetchData={refetchData}
          />
          {exerciseDisplay.show && (
            <ExerciseDisplay exercise={exerciseDisplay.exercise} />
          )}
        </View>
      </View>
    </>
  );
};

export default ExerciseDirectories;
