import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Platform,
  StyleSheet,
  ScrollView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  SpcStyle,
  COLOR_MUTED,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import Check from "../../assets/checkSmallPrimary.svg";
import { analytics } from "../Analytics";

type Props = {
  areas;
  onContinue;
  onBack;
  question;
  progress;
};

type State = {
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
};

export default class ManyRowTemplate extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedZero: null,
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      selectedFive: null,
    };
  }

  toggleSelectedLevel(item) {
    switch (parseInt(item.id)) {
      case 0:
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedLevel;
    switch (parseInt(item.id)) {
      case 0:
        isSelectedLevel = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedLevel = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedLevel = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedLevel = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedLevel = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedLevel = this.state.selectedFive === item;
        break;
    }
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          isSelectedLevel ? SpcStyle.cellSelected : SpcStyle.cellUnselected,
          {
            height: 45,
            borderRadius: 6,
            alignItems: "center",
            flexDirection: "row",
            marginTop: 4,
            marginBottom: 4,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <View
          style={[
            styles.checkmarkBase,
            isSelectedLevel
              ? styles.checkmarkSelected
              : styles.checkmarkUnselected,
          ]}
        >
          {isSelectedLevel && <SVGImage source={<Check />} />}
        </View>
        <Text
          style={[
            SpcStyle.cellText,
            isSelectedLevel && { color: COLOR_PRIMARY },
            {
              paddingTop: Platform.OS === "ios" ? 4 : 1,
            },
          ]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <>
        <ScrollView style={{ flex: 1 }}>
          <View style={[{ justifyContent: "center", width: "100%" }]}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                margin: 24,
              }}
            >
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  style={{ flexDirection: "row" }}
                  onPress={() => {
                    this.props.onBack();
                  }}
                >
                  <SVGImage source={<BackArrow />} />
                </TouchableOpacity>
              </View>
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_16,
                ]}
              >
                Responda e ganhe uma aula grátis!
              </Text>
              <View style={{ flex: 1 }}></View>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLOR_MUTED,
              borderBottomWidth: 1,
            }}
          />
          <View style={{ height: 4 }}>
            <View
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: COLOR_PRIMARY,
                width: this.props.progress,
              }}
            />
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              paddingLeft: 32,
              paddingRight: 32,
              minHeight: 407,
              overflow: "hidden",
              marginTop: 20,
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  marginTop: 15,
                  width: "100%",
                },
              ]}
            >
              {this.props.question}
            </Text>
            <View style={{ marginTop: 20 }}>
              {this.props.areas.map((level) => this.renderLevelCell(level))}
            </View>
          </View>
        </ScrollView>
        <View style={{ bottom: 0 }}>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              if (
                this.state.selectedZero ||
                this.state.selectedOne ||
                this.state.selectedTwo ||
                this.state.selectedThree ||
                this.state.selectedFour ||
                this.state.selectedFive
              ) {
                let arr: string[] = [];
                if (this.state.selectedZero !== null) {
                  arr.push(this.state.selectedZero);
                }
                if (this.state.selectedOne !== null) {
                  arr.push(this.state.selectedOne);
                }
                if (this.state.selectedTwo !== null) {
                  arr.push(this.state.selectedTwo);
                }
                if (this.state.selectedThree !== null) {
                  arr.push(this.state.selectedThree);
                }
                if (this.state.selectedFour !== null) {
                  arr.push(this.state.selectedFour);
                }
                if (this.state.selectedFive !== null) {
                  arr.push(this.state.selectedFive);
                }
                analytics.sendSurveyResponse(
                  this.props.question,
                  arr.toString()
                );
                this.props.onContinue();
              }
            }}
            disabled={
              !this.state.selectedZero &&
              !this.state.selectedOne &&
              !this.state.selectedTwo &&
              !this.state.selectedThree &&
              !this.state.selectedFour &&
              !this.state.selectedFive
            }
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  checkmarkSelected: {
    backgroundColor: COLOR_WHITE,
    borderColor: COLOR_PRIMARY,
    borderWidth: 1,
  },
  checkmarkBase: {
    height: "50%",
    marginLeft: 11,
    marginRight: 11,
    aspectRatio: 1,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },

  checkmarkUnselected: {
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
});
