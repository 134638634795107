import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
  SafeAreaView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  onBack;
  question;
  progress;
};

type State = {
  selectedSingle: string | null;
  areas;
};

export default class ZeroToFiveTemplate extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedSingle: null,
      areas: [1, 2, 3, 4, 5, 6],
    };
  }

  toggleSelectedLevel(item) {
    if (this.state.selectedSingle === item) {
      this.setState({ selectedSingle: null });
    } else {
      this.setState({ selectedSingle: item });
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea = this.state.selectedSingle === item;
    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            alignSelf: "center",
          },
        ]}
        onPress={() => this.toggleSelectedLevel(item)}
      >
        <Text style={[styles.cellText]}>{item - 1}</Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: COLOR_TRUE_WHITE }}>
        <View style={[{ justifyContent: "center", width: "100%" }]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 24,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  this.props.onBack();
                }}
              >
                <SVGImage source={<BackArrow />} />
              </TouchableOpacity>
            </View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
              ]}
            >
              Responda e ganhe uma aula grátis!
            </Text>
            <View style={{ flex: 1 }}></View>
          </View>
        </View>
        <View
          style={{
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
            width: "100%",
            zIndex: 10,
          }}
        />
        <View style={styles.container}>
          <View
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: COLOR_PRIMARY,
              width: this.props.progress,
            }}
          />
        </View>
        <View
          style={{
            paddingHorizontal: 32,
            flex: 1,
          }}
        >
          <FlatList
            data={this.state.areas}
            renderItem={({ item }) => this.renderLevelCell(item)}
            style={{ marginBottom: 12 }}
            keyExtractor={(index) => index.toString()}
            showsVerticalScrollIndicator={false}
            ListHeaderComponent={
              <>
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.font_size_22,
                    {
                      marginVertical: 20,
                    },
                  ]}
                >
                  {this.props.question}
                </Text>
                <Text style={styles.grayText}>Discordo totalmente</Text>
              </>
            }
            ListFooterComponent={
              <>
                <Text style={[styles.grayText, { marginBottom: 20 }]}>
                  Concordo totalmente
                </Text>
                <ButtonElement
                  text="Avançar"
                  colorScheme="primary"
                  styte={{
                    borderRadius: 6,
                    marginVertical: 24,
                  }}
                  textStyle={StyleUtils.font_size_16}
                  onPress={() => {
                    if (this.state.selectedSingle) {
                      analytics.sendSurveyResponse(
                        this.props.question,
                        this.state.selectedSingle
                      );
                      this.props.onContinue();
                    }
                  }}
                  disabled={!this.state.selectedSingle}
                />
              </>
            }
          />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cellBase: {
    height: 53,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    backgroundColor: COLOR_PRIMARY_LIGHT,
    borderWidth: 1,
    borderColor: COLOR_PRIMARY,
  },
  grayText: {
    textAlign: "center",
    paddingHorizontal: 20,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_light),
  },
  container: {
    height: 4,
  },
});
