import {
  Bubble,
  Composer,
  GiftedChat,
  InputToolbar,
  Send,
  Time,
} from "react-web-gifted-chat";
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  COLOR_TXT_NORMAL,
  COLOR_TXT_LIGHT,
  COLOR_DANGER_LIGHT,
} from "../Utils/StyleUtils";
import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from "react-native";
import { standardizeFileName, UploadStatusType } from "../Utils/FileUtils";
import { cancelFileUpload, uploadNativeFile, getFileURL } from "../Server";
import { uuidv4 } from "../Utils/Utils";
import ImagePicker from "react-native-image-crop-picker";
import SVGImage from "../Utils/SVGImage";
import IconGallery from "../../assets/toolImageChat.svg";
import ImageUploader from "../ImageUploader/ImageUploader";
import IconSend from "../../assets/iconSend.svg";
import IconSendGray from "../../assets/iconSendGray.svg";

type Props = {
  messages;
  onSend;
  userID;
};

type State = {
  uploadStatus;
  showImageUpload;
  photo;
};

export default class GiftedChatHolder extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      uploadStatus: UploadStatusType.ready,
      showImageUpload: false,
      photo: "",
    };
  }

  renderTicks = (message) => {
    if (message.sent || message.received || message.pending) {
      return (
        <View
          style={{
            flexDirection: "row",
            marginRight: 10,
            marginBottom: 7,
          }}
        >
          {message.sent && (
            <Text
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: COLOR_TXT_NORMAL,
              }}
            >
              ✓
            </Text>
          )}
          {message.received && (
            <Text
              style={{
                fontSize: 14,
                fontWeight: "bold",
                color: COLOR_TXT_NORMAL,
              }}
            >
              ✓
            </Text>
          )}
          {message.pending && (
            <Text
              style={{
                fontSize: 10,
                color: COLOR_TXT_NORMAL,
              }}
            >
              🕓
            </Text>
          )}
        </View>
      );
    }
    return null;
  };

  renderBubble = (props) => {
    const isPending =
      props && props.currentMessage && props.currentMessage.pending;
    return (
      <Bubble
        {...props}
        renderTicks={this.renderTicks.bind(this)}
        onLongPress={() => {
          if (!isPending) return;
          this.props.onSend([props.currentMessage]);
        }}
        wrapperStyle={{
          left: {},
          right: {
            cursor: isPending ? "pointer" : "normal",
            backgroundColor: isPending
              ? COLOR_DANGER_LIGHT
              : COLOR_PRIMARY_LIGHT,
          },
        }}
        textProps={{
          style: {
            color: props.position === "left" ? COLOR_TXT_NORMAL : COLOR_PRIMARY,
          },
        }}
        textStyle={{
          left: {
            color: COLOR_TXT_NORMAL,
          },
          right: {
            color: COLOR_PRIMARY,
          },
        }}
      />
    );
  };

  renderComposer = (props) => (
    <Composer
      {...props}
      textInputStyle={{
        fontFamily: "helvetica",
        fontSize: 16,
        color: COLOR_TXT_NORMAL,
        paddingTop: 10,
      }}
    />
  );

  renderToolbar = (props) => (
    <InputToolbar {...props} renderComposer={this.renderComposer.bind(this)} />
  );

  renderReadyFragment(open): React.ReactNode {
    if (this.state.uploadStatus == UploadStatusType.uploading) {
      return null;
    }
    return (
      <>
        <TouchableOpacity
          style={[{ alignSelf: "center", height: 20, width: "80%" }]}
          onPress={open}
        >
          <Text style={[{ textAlign: "center" }]}>Escolha um arquivo</Text>
        </TouchableOpacity>
      </>
    );
  }

  renderLoadingFragment(): React.ReactNode {
    if (this.state.uploadStatus != UploadStatusType.uploading) {
      return null;
    }
    return (
      <>
        <ActivityIndicator />
        <Text>Carregando arquivo...</Text>
      </>
    );
  }

  renderActions(props) {
    return (
      <View style={{ flexDirection: "row" }}>
        <TouchableOpacity
          onPress={() => {
            this.setState({ showImageUpload: !this.state.showImageUpload });
          }}
        >
          <SVGImage source={<IconGallery />} style={{ marginLeft: -4 }} />
        </TouchableOpacity>
      </View>
    );
  }

  submitGalleryImage(photo) {
    photo.fileName = standardizeFileName(photo.filename);
    photo.type = photo.mime;
    photo.uri = photo.path;
    this.updateUploadStatus(UploadStatusType.uploading);
    this.uploadFileToServer(photo);
  }

  uploadFileToServer(file) {
    uploadNativeFile(
      file,
      (res) => {
        this.updateUploadStatus(UploadStatusType.ready);
        this.onImageUploadSuccessful(file);
      },
      (err) => {
        this.updateUploadStatus(UploadStatusType.networkError);
      },
      () => {
        this.updateUploadStatus(UploadStatusType.ready);
      }
    );
  }

  cancelServerFileUpload() {
    if (this.state.uploadStatus == UploadStatusType.uploading) {
      cancelFileUpload();
    }
  }

  updateUploadStatus(newStatus: UploadStatusType) {
    if (this.state.uploadStatus != newStatus) {
      this.setState({ uploadStatus: newStatus });
    }
  }

  onImageUploadSuccessful(file) {
    const filename = file.name ? file.name : file.fileName;
    const fileURL = getFileURL(filename);
    const imageMessage = [
      {
        id: uuidv4(),
        text: "",
        user: {
          id: this.props.userID,
        },
        createdAt: new Date(),
        image: fileURL,
      },
    ];
    this.props.onSend(imageMessage);
    this.setState({ showImageUpload: false });
  }

  renderSend(props) {
    return (
      <Send {...props} containerStyle={{ borderWidth: 0 }}>
        <View style={{ marginRight: 10, marginBottom: 9 }}>
          {props.text !== "" ? (
            <SVGImage source={<IconSend />} />
          ) : (
            <SVGImage source={<IconSendGray />} />
          )}
        </View>
      </Send>
    );
  }

  renderTime(props) {
    return (
      <Time
        {...props}
        timeTextStyle={{
          right: {
            color: COLOR_TXT_LIGHT,
          },
          left: {
            color: COLOR_TXT_LIGHT,
          },
        }}
      />
    );
  }

  render() {
    return (
      <>
        <GiftedChat
          messages={this.props.messages}
          onSend={this.props.onSend}
          renderAvatarOnTop={true}
          placeholder={"Digite sua mensagem..."}
          showUserAvatar={false}
          renderBubble={this.renderBubble.bind(this)}
          renderInputToolbar={this.renderToolbar.bind(this)}
          renderActions={(props) => this.renderActions(props)}
          renderAvatar={() => null}
          showAvatarForEveryMessage={true}
          user={{
            id: this.props.userID,
          }}
          alwaysShowSend={true}
          renderSend={this.renderSend}
          renderTime={this.renderTime}
          keyboardShouldPersistTaps={"never"}
          listViewProps={{
            contentContainerStyle: {
              paddingRight: 8,
            },
          }}
        />
        {!!this.state.showImageUpload && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 70,
              marginTop: 20,
            }}
          >
            <ImageUploader
              descriptionText={"Arraste a foto que deseja enviar"}
              style={[
                {
                  minWidth: "70%",
                },
              ]}
              isVisible={this.state.showImageUpload}
              onImageUploaded={this.onImageUploadSuccessful.bind(this)}
              isMobile={false}
            />
          </View>
        )}
      </>
    );
  }
}
