import React from "react";
import { Text } from "react-native";
import ButtonElement from "../../Elements/ButtonElement";
import AnimatedPopUp from "../../SignUp/AnimatedPopUp";
import StyleUtils, { COLOR_DANGER } from "../../Utils/StyleUtils";

const ErrorPopUp = ({ show, header, body, onReturn }) => {
  return (
    <AnimatedPopUp show={show} bgColor={COLOR_DANGER}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_white,
        ]}
      >
        {header}
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_white,
        ]}
      >
        {body}
      </Text>
      <ButtonElement
        colorScheme="danger_white"
        text="Ok"
        onPress={onReturn}
        textStyle={[StyleUtils.font_cera_bold, StyleUtils.font_size_16, {}]}
        styte={[
          StyleUtils.shape_btn_default,
          { marginTop: 32, paddingHorizontal: 64 },
        ]}
      />
    </AnimatedPopUp>
  );
};

export default ErrorPopUp;
