import React, { Component } from "react";
import {
  View,
  FlatList,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import StyleUtils, {
  COLOR_BG_LIGHT,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import { AssignmentsFilter, getHomeworks } from "../Server";
import { connect } from "react-redux";
import { selectHomework, setHomeworks } from "../Actions";
import Title from "../Elements/Title";
import HomeworkCard from "./HomeworkCard";
import Homework, { homeworkState, HomeworkState } from "../Models/Homework";
import ExercisesEmptyList from "./ExercisesEmptyList";
import SVGImage from "../Utils/SVGImage";
import Archive from "../../assets/archive.svg";
import SubjectTestButton from "../../assets/subjectTestButton.svg";
import ForwardWhiteButton from "../../assets/whiteNextButton.svg";
import { analytics } from "../Analytics";

type Props = {
  dispatch;
  tutorInfo;
  user;
  history;
  selectHomework;
  mobile?: boolean;
};

type State = {
  homeworks;
  archived: number;
  loading: boolean;
};

function stateToProps(state) {
  return {
    mobile: state.layoutRed.mobile,
    user: state.userRed.user,
    tutorInfo: state.userRed.tutorInfo,
  };
}

function dispatchToProps(dispatch) {
  return {
    selectHomework: (homework) => dispatch(selectHomework(homework)),
    dispatch: dispatch,
  };
}

class ExercisesList extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      homeworks: [],
      archived: 0,
      loading: true,
    };
  }

  componentDidMount() {
    analytics.sendSubjectTestExercises();
    var assignmentsFilter: AssignmentsFilter = {
      tutorId: undefined,
      tuteeId: undefined,
      limit: undefined,
    };
    if (this.props.user) {
      if (this.props.tutorInfo) {
        assignmentsFilter.tutorId = this.props.user.id;
      } else {
        assignmentsFilter.tuteeId = this.props.user.id;
      }
      getHomeworks(assignmentsFilter)
        .then((homeworks) => {
          const [archived, currentHomeworks] = homeworks.reduce(
            (acc, homework) => {
              if (homeworkState(homework) >= HomeworkState.COMPLETED) {
                acc[0]++;
              } else {
                acc[1].push(homework);
              }
              return acc;
            },
            [0, new Array<Homework>()]
          );
          this.setState({ archived });
          this.props.dispatch(setHomeworks(homeworks, !!this.props.tutorInfo));
          return currentHomeworks;
        })
        .then((result) => {
          console.log(result);
          this.setState({
            homeworks: result,
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <View
        style={[
          StyleUtils.screen_default,
          StyleUtils.color_bg_white,
          !this.props.mobile && {
            alignItems: "center",
          },
        ]}
      >
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              borderColor: COLOR_BG_LIGHT,
              borderBottomWidth: 1,
            },
            this.props.mobile && {
              justifyContent: "space-between",
            },
          ]}
        >
          <Title title="Meus exercícios" mobile={this.props.mobile} />
          {this.state.archived > 0 && (
            <TouchableOpacity
              onPress={() => this.props.history.push("/exercises/archived")}
            >
              <View style={[StyleUtils.shape_card_default]}>
                <SVGImage style={{ margin: 16 }} source={<Archive />} />
              </View>
            </TouchableOpacity>
          )}
        </View>
        <ScrollView>
          {!this.state.loading && this.props.user && (
            <TouchableOpacity
              style={[
                StyleUtils.shape_card_default,
                {
                  marginHorizontal: 24,
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: COLOR_PRIMARY,
                },
              ]}
              onPress={() => this.props.history.push("/subjecttests")}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_20,
                  {
                    position: "absolute",
                    color: COLOR_WHITE,
                    zIndex: 1,
                    marginLeft: 16,
                  },
                ]}
              >
                Simulados
              </Text>
              <SVGImage source={<SubjectTestButton />} />
              <SVGImage
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  right: 16,
                }}
                source={<ForwardWhiteButton />}
              />
            </TouchableOpacity>
          )}
          {this.state.homeworks.length ? (
            <FlatList
              style={{
                flex: 1,
                width: "100%",
                maxWidth: 564,
                marginVertical: 8,
              }}
              data={this.state.homeworks}
              renderItem={(item) => (
                <HomeworkCard
                  homework={item.item}
                  isTutor={!!this.props.tutorInfo}
                  mobile={this.props.mobile}
                  onPress={() => {
                    this.props.selectHomework(item.item);
                    if (this.props.tutorInfo) {
                      this.props.history.push("/exercises/review");
                    } else {
                      this.props.history.push("/exercises/exercise");
                    }
                  }}
                />
              )}
            />
          ) : (
            !this.state.loading && (
              <ExercisesEmptyList
                history={this.props.history}
                mobile={this.props.mobile}
              />
            )
          )}
        </ScrollView>
      </View>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(ExercisesList);
