export function standardizeFileName(filename) {
  return `${+new Date()}_${filename}`;
}

export function standardizeFile(file) {
  return new File([file], standardizeFileName(file.name), { type: file.type });
}

export enum UploadStatusType {
  ready,
  uploading,
  fileTypeError,
  fileSizeError,
  networkError,
}
