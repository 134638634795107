import React, { useEffect, useState } from "react";
import {
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput,
  KeyboardAvoidingView,
  Alert,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import BackButton from "../../assets/backButtonBorder.svg";
import TicketBlack from "../../assets/ticketBlack.svg";
import TicketGreen from "../../assets/ticketGreen.svg";
import TicketRed from "../../assets/ticketRed.svg";
import { bookingInfo, priceInfo, selectTutor, setTuteeInfo } from "../Actions";
import { analytics } from "../Analytics";
import { UserImage } from "../Elements/UserImages";
import { Tutor } from "../Models/TutorInfo";
import {
  bookTutor,
  getUserCredit,
  consumeTicket,
  rescheduleEvent,
  requestPurchaseIAP,
  chooseTutor,
  getUserPersonalInfo,
  updateUserPersonalInfo,
  getSubscription,
} from "../Server";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TXT_DARK,
  COLOR_WHITE,
  COLOR_SUCCESS,
  COLOR_PRIMARY,
  SpcStyle,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import * as Utils from "../Utils/Utils";
import { userShortName } from "../Utils/Utils";
import PaymentOutcomeLayout from "./PaymentOutcomeLayout";
import TuteeInfo from "../Models/TuteeInfo";
import { DiscountBadge } from "./DiscountBadge";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import BuyCreditPopup from "../Popups/BuyCreditPopup";
import Checkmark from "../../assets/checkWhite.svg";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import { fetchAvailableHours } from "../Booking/Booking";
import CouponPopup from "../Popups/CouponPopup";
import CreditOptionsPopup from "../Popups/CreditOptionsPopup";
import IAP from "react-native-iap";
import LinearGradient from "react-native-linear-gradient";
import { createShimmerPlaceholder } from "react-native-shimmer-placeholder";
import RequestWhatsappPopup from "../Popups/RequestWhatsappPopup";
import ConfirmTokenPopup from "../Popups/ConfirmTokenPopup";
import Loader from "../Elements/Loader";
import _ from "lodash";

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

type Props = {
  history;
  lessonInfo: { selectedDate; selectedTutor: Tutor; endDate };
  selectedTutor: Tutor;
  dispatch;
  userID: string;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  mobile;
  location;
  onBack?;
  onBookingFinished?;
  subject?;
  subjectLevel?;
  fromDynamicLink;
};

function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
    userID: state.userRed.user.id,
    lessonInfo: state.selRed.lessonInfo,
    mobile: state.layoutRed.mobile,
    fromDynamicLink: state.userRed.fromDynamicLink,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    priceInfo: (event) => dispatch(priceInfo(event)),
    bookingInfo: (event) => dispatch(bookingInfo(event)),
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch: dispatch,
  };
}

const subscriptionID =
  Platform.OS === "ios"
    ? "subs_1un_credito_week_7daysfree_40reais"
    : "subs_1un_credito_week_7daysfree_40reais_google";

const CLASS_MINUTES = 60;

interface iapProduct {
  appleId?: string;
  androidId?: string;
  label?: string;
  numCredits: number;
}

const styles = StyleSheet.create({
  textBoldBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
  },
  textBlack: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    color: COLOR_TXT_DARK,
    paddingTop: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTextHeader: {
    ...StyleSheet.flatten(StyleUtils.font_cera_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_22),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 10,
    marginTop: 10,
  },
  modalText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 10,
    fontWeight: "500",
  },
  checkmarkBase: {
    height: "50%",
    marginRight: 11,
    aspectRatio: 1,
    borderRadius: 1000,
    alignItems: "center",
    justifyContent: "center",
  },
  cellBase: {
    height: 40,
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginVertical: 4,
    flex: 1,
  },
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
  },
  popupText: {
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    color: COLOR_TXT_DARK,
  },
  mutedPopupText: {
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    color: COLOR_MUTED,
  },
  modalBackground: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "#00000040",
  },
  activityIndicatorWrapper: {
    backgroundColor: "#FFFFFF",
    height: 100,
    width: 100,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
});

const divider = (short: boolean, flex?: number) => (
  <View
    style={{
      borderBottomColor: COLOR_MUTED,
      borderBottomWidth: 1,
      flex: flex === 0 ? 0 : 1,
      justifyContent: "center",
      marginHorizontal: short ? 24 : 0,
    }}
  />
);

function ConfirmationPage(props: Props) {
  let myScrollView: any;
  const [purchaseSuccess, setPurchaseSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [totalCredits, setTotalCredits] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [priceShowABTest, setPriceShowABTest] = useState(0);
  const [ticketIDs, setTicketIDs] = useState<string[]>([]);
  const [creditPrice, setCreditPrice] = useState(0);
  const [addCouponVisible, setAddCouponVisible] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [reason, setReason] = useState("");
  const [eventID, setEventID] = useState("");
  const [recurringClass, setRecurringClass] = useState(false);
  const [numRecurringClass, setNumRecurringClass] = useState(1);
  const [showRecurringClassPopup, setShowRecurringClassPopup] = useState(false);
  const [isTwoAvailable, setTwoAvailable] = useState(false);
  const [isThreeAvailable, setThreeAvailable] = useState(false);
  const [isFourAvailable, setFourAvailable] = useState(false);
  const [reset, setReset] = useState(false);
  const totalCreditPrice = creditPrice * numRecurringClass;
  const [hourPrice, setHourPrice] = useState(0);
  const [creditOptionsVisible, setCreditOptionsVisible] = useState(false);
  const [products, setProducts] = useState<iapProduct[]>([]);
  const [paymentType, setPaymentType] = useState(0);
  const [productIds, setProductIds] = useState([]);
  const [creditOptions, setCreditOptions] = useState<object[]>([]);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingCredits, setLoadingCredits] = useState(false);
  const [requestWhatsapp, setRequestWhatsapp] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);
  const [tokenPopup, setTokenPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifiedPhone, setVerifiedPhone] = useState(false);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    analytics.sendTutorPaymentShow();
    if (
      props.location &&
      props.location.state &&
      props.location.state.reschedule
    ) {
      setReschedule(true);
      setEventID(props.location.state.eventID);
    }
    RemoteConfigBridge.remoteConfig("paymentType", (value) => {
      setPaymentType(parseInt(value));
    });
    RemoteConfigBridge.remoteConfig("requestWhatsapp", (value) => {
      var valueBool = value == "1";
      if (!props.fromDynamicLink) {
        setRequestWhatsapp(valueBool);
      }
    });
    getProducts();
    getUserPersonalInfo(props.userID).then((data) => {
      if (data) {
        if (data.guardianPhoneVerified) {
          setVerifiedPhone(true);
        }
      }
    });
  }, []);

  const getProducts = async () => {
    await RemoteConfigBridge.remoteConfig("subscriptionType", async (value) => {
      const platform = Platform.OS === "ios" ? "apple" : "google";
      switch (parseInt(value)) {
        case 1:
          await getSubscription(platform, props.userID)
            .then((message) => {
              if (!message.error) {
                setIsPremium(true);
                RemoteConfigBridge.remoteConfig(
                  "subscriptionBundles",
                  (value) => {
                    const obj = JSON.parse(value);
                    setProducts(obj);
                    let productIds: any = [];
                    let id = Platform.OS === "ios" ? "appleId" : "androidId";
                    for (let i = 0; i < obj.length; i++) {
                      productIds[i] = obj[i][id];
                    }
                    setProductIds(productIds);
                  }
                );
              } else {
                getSubscription(platform, props.userID)
                  .then((message) => {
                    console.log(message);
                    if (!message.error) {
                      setIsPremium(true);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    return [];
                  });
                RemoteConfigBridge.remoteConfig("iapBundles", (value) => {
                  const obj = JSON.parse(value);
                  setProducts(obj);
                  let productIds: any = [];
                  let id = Platform.OS === "ios" ? "appleId" : "androidId";
                  for (let i = 0; i < obj.length; i++) {
                    productIds[i] = obj[i][id];
                  }
                  setProductIds(productIds);
                });
              }
            })
            .catch((err) => {
              console.log(err);
              return [];
            });
          break;
        default:
          await RemoteConfigBridge.remoteConfig("iapBundles", (value) => {
            const obj = JSON.parse(value);
            setProducts(obj);
            let productIds: any = [];
            let id = Platform.OS === "ios" ? "appleId" : "androidId";
            for (let i = 0; i < obj.length; i++) {
              productIds[i] = obj[i][id];
            }
            setProductIds(productIds);
          });
      }
    });
  };

  useEffect(() => {
    if (
      props.selectedTutor &&
      props.selectedTutor.tutorInfo &&
      props.selectedTutor.tutorInfo.hourPrice
    ) {
      setHourPrice(props.selectedTutor.tutorInfo.hourPrice);
    }
    RemoteConfigBridge.remoteConfig("priceShow", (value) => {
      setPriceShowABTest(parseInt(value));
    });
  }, [hourPrice]);

  const fetchUserCredits = () => {
    let price = 0;
    if (
      props.selectedTutor &&
      props.selectedTutor.tutorInfo &&
      props.selectedTutor.tutorInfo.creditPrice
    ) {
      setCreditPrice(props.selectedTutor.tutorInfo.creditPrice);
      price = props.selectedTutor.tutorInfo.creditPrice;
    }
    price *= numRecurringClass;
    getUserCredit(props.userID).then((res) => {
      setTotalCredits(parseInt(res.amount));
      setLoadingCredits(false);
      let ticketsArray: string[] = [];
      if (parseInt(res.amount) >= price) {
        for (let i = 0; i < price; ++i) {
          ticketsArray.push(res.tickets[i].id);
        }
      }
      setTicketIDs(ticketsArray);
    });
  };

  useEffect(() => {
    fetchUserCredits();
  }, [addCouponVisible, numRecurringClass]);

  useEffect(() => {
    let nextMonth = new Date(props.lessonInfo.selectedDate);
    let oneWeekFromDate = new Date(props.lessonInfo.selectedDate);
    oneWeekFromDate.setDate(oneWeekFromDate.getDate() + 7);
    let twoWeeksFromDate = new Date(props.lessonInfo.selectedDate);
    twoWeeksFromDate.setDate(twoWeeksFromDate.getDate() + 14);
    let threeWeeksFromDate = new Date(props.lessonInfo.selectedDate);
    threeWeeksFromDate.setDate(threeWeeksFromDate.getDate() + 21);
    fetchAvailableHours(
      props.userID,
      props.selectedTutor.id,
      oneWeekFromDate,
      Utils.nextMonthFromDay(nextMonth)
    )
      .then((hours) => {
        let filtered = hours.filter((x) => x !== undefined);
        let flattened = filtered.flat();
        const matchOneWeek = flattened.find(
          (d) => d.getTime() === oneWeekFromDate.getTime()
        );
        const hasMatchOneWeek = !!matchOneWeek; // convert to boolean
        const matchTwoWeeks = flattened.find(
          (d) => d.getTime() === twoWeeksFromDate.getTime()
        );
        const hasMatchTwoWeeks = !!matchTwoWeeks; // convert to boolean
        const matchThreeWeeks = flattened.find(
          (d) => d.getTime() === threeWeeksFromDate.getTime()
        );
        const hasMatchThreeWeeks = !!matchThreeWeeks; // convert to boolean
        if (hasMatchOneWeek) {
          setTwoAvailable(true);
        }
        if (hasMatchOneWeek && hasMatchTwoWeeks) {
          setThreeAvailable(true);
        }
        if (hasMatchOneWeek && hasMatchTwoWeeks && hasMatchThreeWeeks) {
          setFourAvailable(true);
        }
      })
      .catch(console.log);
  }, []);

  function compare(a, b) {
    if (parseFloat(a.price) < parseFloat(b.price)) {
      return -1;
    }
    if (parseFloat(a.price) > parseFloat(b.price)) {
      return 1;
    }
    return 0;
  }

  const initilizeIAPConnection = async () => {
    await IAP.initConnection()
      .then(async (connection) => {
        IAP.getProducts(productIds).then((res) => {
          let sorted = res.sort(compare);
          let creditOptions: any = [];
          for (let i = 0; i < productIds.length; i++) {
            let discount =
              i === 0
                ? ""
                : Math.round(
                    ((parseFloat(sorted[0].price) * products[i].numCredits -
                      parseFloat(sorted[i].price)) /
                      (parseFloat(sorted[0].price) * products[i].numCredits)) *
                      100
                  ) + "% Desconto";
            if (isPremium) {
              discount =
                i === 0
                  ? "10% Desconto"
                  : i === 1
                  ? "36% Desconto"
                  : i === 2
                  ? "49% Desconto"
                  : "";
            }

            let obj = {
              id: productIds[i],
              label: products[i]["label"],
              price: sorted[i].localizedPrice,
              discount: discount,
            };
            creditOptions.push(obj);
          }
          setCreditOptions(creditOptions);
        });
      })
      .catch((err) => {
        console.warn(`IAP ERROR ${err.code}`, err.message);
      });
  };

  useEffect(() => {
    initilizeIAPConnection();
  }, [productIds]);

  useEffect(() => {
    const purchaseUpdateSubscription = IAP.purchaseUpdatedListener(
      async (purchase) => {
        if (purchase) {
          const receipt = purchase.transactionReceipt;
          if (receipt)
            try {
              setLoadingCredits(true);
              analytics.sendIAPPurchaseSuccess();
              if (Platform.OS === "ios" && purchase.transactionId) {
                let transactionId = purchase.transactionId;
                IAP.finishTransactionIOS(purchase.transactionId);
                IAP.finishTransaction(purchase)
                  .then(() => {
                    requestPurchaseIAP(
                      "apple",
                      receipt,
                      props.userID,
                      purchase.productId,
                      transactionId
                    )
                      .then((response) => {
                        fetchUserCredits();
                        setLoadingPayment(false);
                      })
                      .catch((err) => {
                        console.log(err);
                        setLoadingCredits(false);
                      });
                  })
                  .catch((err) => {
                    Alert.alert("Erro na compra");
                    console.log(err.code, err.message);
                    setLoadingPayment(false);
                    setLoadingCredits(false);
                    analytics.sendIAPPurchaseFailed();
                  });
              } else if (Platform.OS === "android" && purchase.purchaseToken) {
                IAP.acknowledgePurchaseAndroid(purchase.purchaseToken)
                  .then(() => {
                    IAP.finishTransaction(purchase, true)
                      .then(() => {
                        if (purchase.purchaseToken && purchase.transactionId) {
                          requestPurchaseIAP(
                            "google",
                            purchase.purchaseToken,
                            props.userID,
                            purchase.productId,
                            purchase.transactionId
                          )
                            .then((response) => {
                              fetchUserCredits();
                              if (response.error === true) {
                                Alert.alert(
                                  "Compra inválida. Entre em contato com o nosso suporte."
                                );
                                analytics.sendIAPPurchaseFailed();
                              }
                              setLoadingPayment(false);
                            })
                            .catch((err) => {
                              console.log("api purchases error ", err);
                              setLoadingPayment(false);
                              analytics.sendIAPPurchaseFailed();
                              setLoadingCredits(false);
                            });
                        }
                      })
                      .catch((err) => {
                        console.log(err.code, err.message);
                        setLoadingPayment(false);
                        setLoadingCredits(false);
                      });
                  })
                  .catch((err) => {
                    console.log(err.code, err.message);
                    setLoadingPayment(false);
                    setLoadingCredits(false);
                  });
              }
            } catch (ackErr) {
              console.warn("ackErr", ackErr);
              setLoadingPayment(false);
              setLoadingCredits(false);
              analytics.sendIAPPurchaseFailed();
            }
        }
      }
    );
    const purchaseErrorSubscription = IAP.purchaseErrorListener((error) => {
      console.log("purchaseErrorListener INAPP>>>>", error);
      setLoadingPayment(false);
    });
    return () => {
      if (purchaseUpdateSubscription) {
        purchaseUpdateSubscription.remove();
      }
      if (purchaseErrorSubscription) {
        purchaseErrorSubscription.remove();
      }
    };
  }, []);

  const schedule = () => {
    const date = new Date(props.lessonInfo.selectedDate);
    const endDate = new Date(props.lessonInfo.endDate);
    if (reschedule) {
      analytics.sendClassRescheduled();
      rescheduleEvent(
        props.userID,
        props.selectedTutor.id,
        eventID,
        date,
        endDate,
        reason
      )
        .then(() => {
          setPurchaseSuccess(true);
          return chooseTutor(props.userID, props.selectedTutor.email);
        })
        .then((tuteeInfo) => {
          props.setTuteeInfo(tuteeInfo);
        })
        .catch((err) => {
          //TODO reverter compra?
          setPurchaseSuccess(false);
        });
      //redirect to chat
      props.history.push({
        pathname: "/messages/" + props.selectedTutor.id,
        state: {
          shouldGoBack: false,
          messages:
            "Sua aula foi reagendada para " +
            `${Utils.dateFormatLongDateNoYear(
              new Date(props.lessonInfo.selectedDate)
            )}, ${Utils.dateFormatHHhmm(
              new Date(props.lessonInfo.selectedDate)
            )}-${Utils.dateFormatHHhmm(new Date(props.lessonInfo.endDate))}` +
            ". O motivo do reagendamento é: " +
            reason,
          reschedule: true,
        },
      });
    } else {
      analytics.sendClassScheduled();
      let subject = "";
      let subjectLevel = "";
      const ticket = {
        tickets: ticketIDs,
        userId: props.userID,
        tutorId: props.selectedTutor.id,
      };
      if (props.subject !== undefined) {
        subject = props.subject;
        subjectLevel = props.subjectLevel;
      } else if (
        props.location &&
        props.location.state &&
        props.location.state.subject
      ) {
        subject = props.location.state.subject;
        subjectLevel = props.location.state.subjectLevel;
      }
      let oneWeekFromDate = new Date(props.lessonInfo.selectedDate);
      oneWeekFromDate.setDate(oneWeekFromDate.getDate() + 7);
      let twoWeeksFromDate = new Date(props.lessonInfo.selectedDate);
      twoWeeksFromDate.setDate(twoWeeksFromDate.getDate() + 14);
      let threeWeeksFromDate = new Date(props.lessonInfo.selectedDate);
      threeWeeksFromDate.setDate(threeWeeksFromDate.getDate() + 21);

      const source = _.get(props, "location.state.source", null);
      bookTutor(
        props.userID,
        props.selectedTutor.id,
        date,
        endDate,
        subject,
        subjectLevel,
        source
      )
        .then(() => {
          if (numRecurringClass === 1) {
            setPurchaseSuccess(true);
            consumeTicket(ticket);
          }
          return chooseTutor(props.userID, props.selectedTutor.email);
        })
        .then((tuteeInfo) => {
          props.setTuteeInfo(tuteeInfo);
        });
      if (
        numRecurringClass === 2 ||
        numRecurringClass === 3 ||
        numRecurringClass === 4
      ) {
        let newDate = new Date(oneWeekFromDate);
        newDate.setMinutes(date.getMinutes() + CLASS_MINUTES);
        bookTutor(
          props.userID,
          props.selectedTutor.id,
          oneWeekFromDate,
          newDate,
          subject,
          subjectLevel,
          source
        ).then(() => {
          if (numRecurringClass === 2) {
            setPurchaseSuccess(true);
            consumeTicket(ticket);
          }
        });
      }
      if (numRecurringClass === 3 || numRecurringClass === 4) {
        let newDate = new Date(twoWeeksFromDate);
        newDate.setMinutes(date.getMinutes() + CLASS_MINUTES);
        bookTutor(
          props.userID,
          props.selectedTutor.id,
          twoWeeksFromDate,
          newDate,
          subject,
          subjectLevel,
          source
        ).then(() => {
          if (numRecurringClass === 3) {
            setPurchaseSuccess(true);
            consumeTicket(ticket);
          }
        });
      }
      if (numRecurringClass === 4) {
        let newDate = new Date(threeWeeksFromDate);
        newDate.setMinutes(date.getMinutes() + CLASS_MINUTES);
        bookTutor(
          props.userID,
          props.selectedTutor.id,
          threeWeeksFromDate,
          newDate,
          subject,
          subjectLevel,
          source
        )
          .then(() => {
            setPurchaseSuccess(true);
            consumeTicket(ticket);
          })
          .catch((err) => {
            //TODO reverter compra?
            setPurchaseSuccess(false);
          });
      }
    }
  };

  if (purchaseSuccess !== undefined) {
    let subjectTestID = "";
    let subject = "";
    let subjectLevel = "";
    if (props.subject !== undefined) {
      subject = props.subject;
      subjectLevel = props.subjectLevel;
    } else if (
      props.location &&
      props.location.state &&
      props.location.state.subject
    ) {
      subject = props.location.state.subject;
      subjectLevel = props.location.state.subjectLevel;
    }
    if (
      props.location &&
      props.location.state &&
      props.location.state.subjectTestID
    ) {
      subjectTestID = props.location.state.subjectTestID;
    }
    return (
      <PaymentOutcomeLayout
        tutorImage={props.selectedTutor.imageURL}
        lessonDate={props.lessonInfo.selectedDate}
        lessonEndDate={props.lessonInfo.endDate}
        tutorName={userShortName(props.selectedTutor.name)}
        history={props.history}
        classroomEnd={
          props.location &&
          props.location.state &&
          props.location.state.classroomEnd
        }
        onBookingFinished={props.onBookingFinished && props.onBookingFinished}
        tutorID={props.selectedTutor.id}
        numRecurringClass={numRecurringClass}
        subjectTestID={subjectTestID}
        subject={subject}
        subjectLevel={subjectLevel}
      />
    );
  }

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS == "ios" ? "padding" : "height"}
        style={{
          flex: 10,
          backgroundColor:
            Platform.OS === "ios" && (tokenPopup || whatsappPopup)
              ? "rgba(0, 0, 0, 0.5)"
              : COLOR_WHITE,
        }}
      >
        <ScrollView
          ref={(component) => {
            myScrollView = component;
          }}
        >
          <Loader loading={loadingPayment} />
          <View
            style={{
              width: "100%",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                margin: 16,
              }}
            >
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  if (props.location && props.location.state) {
                    props.history.goBack();
                  } else {
                    props.onBack();
                  }
                }}
              >
                <SVGImage source={<BackButton />} />
              </TouchableOpacity>
            </View>
            {divider(false)}
          </View>
          <View>
            <TouchableOpacity
              activeOpacity={1.0}
              // delayLongPress={5000}
              // onLongPress={() => {
              //   schedule();
              // }}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_22,
                  {
                    marginLeft: 26,
                    marginTop: 25,
                    marginBottom: 32,
                    color: COLOR_TXT_DARK,
                  },
                ]}
              >
                {reschedule
                  ? "Confirme seu reagendamento"
                  : "Confirme sua aula"}
              </Text>
            </TouchableOpacity>
            <View style={{ flexDirection: "row" }}>
              <UserImage
                style={{
                  marginLeft: 26,
                  marginRight: 16,
                  marginBottom: 33,
                  width: 80,
                  height: 92,
                }}
                imageURL={props.selectedTutor.imageURL}
              />
              <View style={{ flex: 1 }}>
                <Text
                  style={[
                    styles.textBoldBlack,
                    {
                      paddingTop: 4,
                      marginBottom: 8,
                    },
                  ]}
                >
                  {userShortName(props.selectedTutor.name)}
                </Text>
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_regular,
                    {
                      color: COLOR_TXT_DARK,
                    },
                  ]}
                >
                  {`${Utils.dateFormatLongDateNoYear(
                    new Date(props.lessonInfo.selectedDate)
                  )}\nHorário: ${Utils.dateFormatHHhmm(
                    new Date(props.lessonInfo.selectedDate)
                  )}-${Utils.dateFormatHHhmm(
                    new Date(props.lessonInfo.endDate)
                  )}`}
                </Text>
              </View>
            </View>
          </View>
          <View>{divider(true)}</View>
          {!reschedule && (
            <>
              <View>
                {priceShowABTest === 1 && divider(true)}
                {priceShowABTest === 1 && (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginHorizontal: 24,
                      marginVertical: 16,
                    }}
                  >
                    <Text
                      style={[
                        StyleUtils.font_cera_regular,
                        StyleUtils.font_size_16,
                      ]}
                    >
                      Subtotal
                    </Text>
                    <View style={{ alignItems: "flex-end" }}>
                      <Text
                        style={[
                          StyleUtils.font_cera_regular,
                          StyleUtils.font_size_16,
                          { textDecorationLine: "line-through" },
                        ]}
                      >
                        R$ 0,00
                      </Text>
                      <DiscountBadge style={[]} discount={50} light={true} />
                    </View>
                  </View>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginHorizontal: 24,
                    marginVertical: 16,
                  }}
                >
                  {priceShowABTest === 3 && (
                    <>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text style={styles.textBlack}>Saldo Atual</Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {priceShowABTest === 3 &&
                              totalCredits - totalCreditPrice < 0 && (
                                <SVGImage
                                  source={<TicketRed />}
                                  style={{ paddingRight: 4 }}
                                />
                              )}
                            {!(
                              priceShowABTest === 3 &&
                              totalCredits - totalCreditPrice < 0
                            ) && (
                              <SVGImage
                                source={<TicketGreen />}
                                style={{ paddingRight: 4 }}
                              />
                            )}
                            {loadingCredits && (
                              <ShimmerPlaceHolder
                                style={[
                                  StyleUtils.shape_card_default,
                                  {
                                    position: "absolute",
                                    width: "70%",
                                    height: 30,
                                    left: 38,
                                  },
                                ]}
                              />
                            )}

                            <Text
                              style={[
                                StyleUtils.font_cera_regular,
                                StyleUtils.font_size_16,
                                {
                                  color:
                                    priceShowABTest === 3 &&
                                    totalCredits - totalCreditPrice < 0
                                      ? "#FF2F5C"
                                      : "#34A853",
                                  paddingTop: 5,
                                  opacity: !loadingCredits ? 1 : 0,
                                },
                              ]}
                            >
                              {totalCredits}{" "}
                              {totalCredits === 1 ? "Crédito" : "Créditos"}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 16,
                          }}
                        >
                          <Text style={styles.textBoldBlack}>
                            {numRecurringClass === 1
                              ? "Valor da Sessão"
                              : "Valor das Sessões"}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <SVGImage
                              source={<TicketBlack />}
                              style={{ paddingRight: 4 }}
                            />
                            <Text style={styles.textBlack}>
                              {totalCreditPrice}{" "}
                              {totalCreditPrice === 1 ? "Crédito" : "Créditos"}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </>
                  )}
                  {priceShowABTest !== 3 && (
                    <>
                      <Text style={styles.textBoldBlack}>Total</Text>
                      <Text style={styles.textBoldBlack}>
                        R$ {hourPrice},00
                      </Text>
                    </>
                  )}
                </View>
                {divider(true)}
              </View>
              <View style={{ marginHorizontal: 24, marginTop: 16 }}>
                {!reschedule && (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      style={[styles.cellBase, { marginTop: -1 }]}
                      onPress={(e) => {
                        analytics.sendRecurringClass();
                        if (numRecurringClass !== 1) {
                          setReset(true);
                          setNumRecurringClass(1);
                          setRecurringClass(false);
                        } else {
                          setReset(false);
                          setRecurringClass(true);
                          setShowRecurringClassPopup(true);
                        }
                      }}
                    >
                      <View
                        style={[
                          styles.checkmarkBase,
                          recurringClass
                            ? SpcStyle.checkmarkSelected
                            : SpcStyle.checkmarkUnselected,
                        ]}
                      >
                        {recurringClass && <SVGImage source={<Checkmark />} />}
                      </View>
                      <Text
                        style={[
                          StyleUtils.font_size_14,
                          StyleUtils.font_cera_regular,
                          {
                            color: COLOR_TXT_DARK,
                            marginTop: Platform.OS === "ios" ? 43 : 35,
                            marginBottom: 16,
                            height: "100%",
                            width: 230,
                          },
                        ]}
                      >
                        Sessões Recorrentes Semanais?
                      </Text>
                    </TouchableOpacity>
                    {numRecurringClass !== 1 && (
                      <TouchableOpacity
                        style={[styles.cellBase]}
                        onPress={(e) => {
                          setRecurringClass(true);
                          setShowRecurringClassPopup(true);
                        }}
                      >
                        <View
                          style={{
                            borderColor: COLOR_MUTED,
                            borderWidth: 1,
                            marginLeft: 80,
                            borderRadius: 6,
                            height: 40,
                          }}
                        >
                          <Text
                            style={[
                              StyleUtils.font_size_16,
                              StyleUtils.font_cera_regular,
                              {
                                color: COLOR_TXT_DARK,
                                paddingVertical: Platform.OS === "ios" ? 10 : 7,
                                paddingHorizontal: 8,
                                fontWeight: "500",
                              },
                            ]}
                          >
                            {numRecurringClass} aulas
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    if (priceShowABTest === 3) {
                      analytics.sendCouponPopupOpened();
                      setAddCouponVisible(true);
                    }
                  }}
                >
                  <Text
                    style={[
                      StyleUtils.font_size_14,
                      StyleUtils.font_cera_regular,
                      {
                        color: "#0179FF",
                        marginBottom: 24,
                        marginTop: 10,
                        textDecorationLine: "underline",
                        fontWeight: "500",
                      },
                    ]}
                  >
                    Adicionar Cupom
                  </Text>
                </TouchableOpacity>
                <Text
                  style={[
                    StyleUtils.font_size_14,
                    StyleUtils.font_cera_regular,
                    {
                      color: COLOR_TXT_DARK,
                    },
                  ]}
                >
                  Ao clicar no botão abaixo, você concorda com os{" "}
                  <Text
                    style={{ textDecorationLine: "underline" }}
                    onPress={() =>
                      Linking.openURL("https://politicas.learning-machine.org/")
                    }
                  >
                    termos de uso
                  </Text>{" "}
                  do produto.
                </Text>
              </View>
            </>
          )}
          {!!reschedule && (
            <View>
              <Text
                style={[
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_14,
                  {
                    margin: 24,
                  },
                ]}
              >
                Qual é o motivo do reagendamento?
              </Text>
              <TextInput
                placeholder={"Deixe seu motivo..."}
                placeholderTextColor={COLOR_MUTED}
                multiline={true}
                onFocus={() =>
                  myScrollView.scrollTo({
                    x: 0,
                    y: 750,
                    animated: true,
                  })
                }
                onChangeText={(text) => {
                  if (text && text.trim()) {
                    setReason(text);
                  }
                }}
                style={[
                  SpcStyle.cellUnselected,
                  StyleUtils.font_cera_regular,
                  {
                    height: 136,
                    borderRadius: 10,
                    marginHorizontal: 24,
                    color: "black",
                    textAlign: "auto",
                    paddingTop: 10,
                    paddingHorizontal: 10,
                    textAlignVertical: "top",
                    borderWidth: 1,
                    borderColor: "#000000",
                  },
                ]}
              />
              <Text
                style={[
                  StyleUtils.font_size_14,
                  StyleUtils.font_cera_regular,
                  {
                    color: COLOR_TXT_DARK,
                    margin: 24,
                  },
                ]}
              >
                Ao clicar no botão abaixo, você concorda com os{" "}
                <Text style={{ textDecorationLine: "underline" }}>
                  termos de uso
                </Text>{" "}
                do produto.
              </Text>
            </View>
          )}
          <CouponPopup
            show={addCouponVisible}
            close={() => {
              setAddCouponVisible(false);
            }}
          />
          {!!props.mobile && (
            <>
              <BuyCreditPopup
                close={() => setModalVisible(false)}
                show={modalVisible}
                refetch={() => {
                  fetchUserCredits();
                }}
                onPay={() => {
                  setModalVisible(false);
                  if (paymentType === 0) {
                    Linking.openURL(
                      "https://api.whatsapp.com/send?phone=5511968487979&text=%20Ol%C3%A1!%20Gostaria%20de%20comprar%20mais%20cr%C3%A9ditos%20para%20fazer%20mais%20aulas%20no%20Tutore!"
                    );
                  } else {
                    setCreditOptionsVisible(true);
                  }
                }}
              />
            </>
          )}
        </ScrollView>
      </KeyboardAvoidingView>
      <View>
        {divider(false)}
        <TouchableOpacity
          style={[
            {
              margin: 24,
              backgroundColor:
                priceShowABTest === 3 && totalCredits - totalCreditPrice < 0
                  ? COLOR_PRIMARY
                  : reschedule && reason.length < 5
                  ? COLOR_MUTED
                  : COLOR_SUCCESS,
            },
            StyleUtils.shape_btn_small,
          ]}
          onPress={() => {
            if (priceShowABTest !== 3 || totalCredits - totalCreditPrice >= 0) {
              if (requestWhatsapp && !verifiedPhone) setWhatsappPopup(true);
              else if ((reschedule && reason.length > 4) || !reschedule) {
                schedule();
              }
            } else {
              if (paymentType === 0) {
                setModalVisible(true);
              } else {
                setCreditOptionsVisible(true);
              }
            }
          }}
        >
          <Text
            style={[
              { textAlign: "center", paddingVertical: 14 },
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_white,
            ]}
          >
            {reschedule
              ? "Confirmar reagendamento"
              : priceShowABTest !== 3
              ? "Confirmar Agendamento"
              : totalCredits - totalCreditPrice >= 0
              ? "Confirmar Agendamento"
              : "Comprar Créditos"}
          </Text>
        </TouchableOpacity>
        {props.selectedTutor.tutorInfo?.firstSessionFreeDuration && (
          <TouchableOpacity
            onPress={() => {
              analytics.sendExperimentFree();
              props.history.replace("/FreeSessionConfirmationPage");
            }}
            style={{ alignSelf: "center", marginBottom: 27 }}
          >
            <Text
              style={[
                StyleUtils.font_size_16,
                StyleUtils.font_cera_medium,
                StyleUtils.color_txt_dark,
                { textDecorationLine: "underline" },
              ]}
            >
              Experimente Grátis!
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <RecurringClassPopup
        show={showRecurringClassPopup}
        closeDelay={(numClasses) => {
          setTimeout(() => {
            if (numClasses === 1) {
              setRecurringClass(false);
            }
            setShowRecurringClassPopup(false);
            setNumRecurringClass(numClasses);
          }, 600);
        }}
        close={() => {
          if (numRecurringClass === 1) {
            setRecurringClass(false);
          }
          setShowRecurringClassPopup(false);
        }}
        twoAvailable={isTwoAvailable}
        threeAvailable={isThreeAvailable}
        fourAvailable={isFourAvailable}
        reset={reset}
      />
      {!!props.mobile && (
        <>
          <CreditOptionsPopup
            close={() => setCreditOptionsVisible(false)}
            show={creditOptionsVisible}
            onPay={(price) => {
              if (price) {
                setCreditOptionsVisible(false);
                analytics.sendOrderPayment(price);
                if (paymentType === 1) {
                  IAP.requestPurchase(price.id)
                    .then(() => {
                      setLoadingPayment(true);
                    })
                    .catch(() => {
                      setLoadingPayment(false);
                      Alert.alert("Cartão Inválido");
                      analytics.sendIAPPurchaseFailed();
                    });
                  setLoadingPayment(true);
                } else if (paymentType === 2) {
                  props.history.push({
                    pathname: "/payment",
                    state: { price: price },
                  });
                }
              }
            }}
            creditOptions={creditOptions}
            premium={isPremium}
          />
          {Platform.OS === "ios" && totalCredits - totalCreditPrice >= 0 ? (
            <KeyboardAvoidingView style={{ flex: 1 }} behavior={"padding"}>
              <RequestWhatsappPopup
                close={() => setWhatsappPopup(false)}
                show={whatsappPopup}
                onSuccess={(phoneNumber) => {
                  setPhoneNumber(phoneNumber);
                  setWhatsappPopup(false);
                  setTokenPopup(true);
                }}
              />

              <ConfirmTokenPopup
                close={() => setTokenPopup(false)}
                show={tokenPopup}
                onSuccess={() => {
                  updateUserPersonalInfo(
                    props.userID,
                    undefined,
                    undefined,
                    undefined,
                    true
                  );
                  schedule();
                }}
                changeNumber={() => {
                  setTokenPopup(false);
                  setWhatsappPopup(true);
                }}
                phoneNumber={phoneNumber}
                userID={props.userID}
              />
            </KeyboardAvoidingView>
          ) : (
            <>
              <RequestWhatsappPopup
                close={() => setWhatsappPopup(false)}
                show={whatsappPopup}
                onSuccess={(phoneNumber) => {
                  setPhoneNumber(phoneNumber);
                  setWhatsappPopup(false);
                  setTokenPopup(true);
                }}
              />

              <ConfirmTokenPopup
                close={() => setTokenPopup(false)}
                show={tokenPopup}
                onSuccess={() => {
                  updateUserPersonalInfo(
                    props.userID,
                    undefined,
                    undefined,
                    undefined,
                    true
                  );
                  schedule();
                }}
                changeNumber={() => {
                  setTokenPopup(false);
                  setWhatsappPopup(true);
                }}
                phoneNumber={phoneNumber}
                userID={props.userID}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

const RecurringClassPopup = ({
  show,
  closeDelay,
  close,
  twoAvailable,
  threeAvailable,
  fourAvailable,
  reset,
}) => {
  const [twoClasses, setTwoClasses] = useState(false);
  const [threeClasses, setThreeClasses] = useState(false);
  const [fourClasses, setFourClasses] = useState(false);

  useEffect(() => {
    if (reset) {
      setTwoClasses(false);
      setThreeClasses(false);
      setFourClasses(false);
    }
  }, [reset]);

  return (
    <AnimatedPopUp show={show} bgColor={COLOR_WHITE} close={close}>
      <View style={{ flex: 1 }}>
        <Text
          style={[
            {
              textAlign: "center",
            },
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_20,
            StyleUtils.color_txt_dark,
          ]}
        >
          Selecione quantas{"\n"}aulas deseja agendar
        </Text>
        <Text
          style={[
            {
              marginTop: 12,
              textAlign: "center",
              marginBottom: 24,
            },
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_dark,
          ]}
        >
          Todas as aulas serão reservadas no mesmo dia{"\n"}e horário e já
          bloquearão a agenda do seu tutor!
        </Text>
        <View style={{ flexDirection: "column" }}>
          {divider(false, 0)}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={[styles.cellBase]}
              onPress={() => {
                if (fourAvailable) {
                  if (!fourClasses) {
                    closeDelay(4);
                  } else {
                    closeDelay(1);
                  }
                  setThreeClasses(false);
                  setTwoClasses(false);
                  setFourClasses(!fourClasses);
                }
              }}
            >
              <View
                style={[
                  styles.checkmarkBase,
                  fourClasses
                    ? SpcStyle.checkmarkSelected
                    : SpcStyle.checkmarkUnselected,
                ]}
              >
                {fourClasses && <SVGImage source={<Checkmark />} />}
              </View>
              <Text
                style={fourAvailable ? styles.popupText : styles.mutedPopupText}
              >
                4 aulas
              </Text>
            </TouchableOpacity>
          </View>
          {divider(false, 0)}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={[styles.cellBase]}
              onPress={() => {
                if (threeAvailable) {
                  if (!threeClasses) {
                    closeDelay(3);
                  } else {
                    closeDelay(1);
                  }
                  setFourClasses(false);
                  setTwoClasses(false);
                  setThreeClasses(!threeClasses);
                }
              }}
            >
              <View
                style={[
                  styles.checkmarkBase,
                  threeClasses
                    ? SpcStyle.checkmarkSelected
                    : SpcStyle.checkmarkUnselected,
                ]}
              >
                {threeClasses && <SVGImage source={<Checkmark />} />}
              </View>
              <Text
                style={
                  threeAvailable ? styles.popupText : styles.mutedPopupText
                }
              >
                3 aulas
              </Text>
            </TouchableOpacity>
          </View>
          {divider(false, 0)}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={[styles.cellBase]}
              onPress={() => {
                if (twoAvailable) {
                  if (!twoClasses) {
                    closeDelay(2);
                  } else {
                    closeDelay(1);
                  }
                  setThreeClasses(false);
                  setFourClasses(false);
                  setTwoClasses(!twoClasses);
                }
              }}
            >
              <View
                style={[
                  styles.checkmarkBase,
                  twoClasses
                    ? SpcStyle.checkmarkSelected
                    : SpcStyle.checkmarkUnselected,
                ]}
              >
                {twoClasses && <SVGImage source={<Checkmark />} />}
              </View>
              <Text
                style={twoAvailable ? styles.popupText : styles.mutedPopupText}
              >
                2 aulas
              </Text>
            </TouchableOpacity>
          </View>
          {divider(false, 0)}
        </View>
      </View>
    </AnimatedPopUp>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
