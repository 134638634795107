export interface Subject {
  id: string;
  name: string;
}

export interface SubjectLevel {
  id: string;
  name: string;
  index?: number;
}

export const defaultSubjects = [
  {
    id: "88055d6c-7ea9-4bc8-912c-fbdb27948106",
    name: "Orientação/Teste Vocacional",
  },
  { id: "cd91b5b7-1adb-488f-af87-a834c33c6dc7", name: "Redação" },
  { id: "a94effa1-748a-4feb-9db7-31acac555744", name: "Espanhol" },
  { id: "9d9064ed-ae90-4081-aeaf-5c1f6279a2f1", name: "Física" },
  { id: "46392a73-efe7-4785-9238-e080ace66d2c", name: "Francês" },
  { id: "3fce2130-453e-42b2-bd4c-b28c0da1f5f0", name: "Geografia" },
  { id: "34441773-71f0-4598-bd1e-b0a5a229cd73", name: "História" },
  { id: "77336d25-8e11-4494-8c04-250b4ea45819", name: "Inglês" },
  { id: "4d81017f-737d-4549-965c-4efdac7bbe9d", name: "Matemática" },
  { id: "06e72385-4796-45f5-a007-e1f6202b3579", name: "Português" },
  { id: "901f25ec-287c-4764-adf4-20f1e1f0f94a", name: "Química" },
];

export const defaultSubjectLevels = [
  {
    id: "5dcea5fc-cb0f-4578-97ed-76b1dedb1604",
    name: "Ensino Fundamental",
    index: 0,
  },
  {
    id: "3de1f15d-3725-4254-919d-ac43c21e3496",
    name: "Ensino Médio",
    index: 1,
  },
  {
    id: "a44aa4f9-a997-4a6e-841a-7062490263bb",
    name: "Pré-Vestibular",
    index: 2,
  },
  {
    id: "12a13e1c-f2b7-48ca-bc63-7625ac6bcd6a",
    name: "Ensino Superior",
    index: 3,
  },
];
