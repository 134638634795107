import React, { Component } from "react";
import { connect } from "react-redux";
import Star from "./Star";
import { View, Text } from "react-native";
import StyleUtils from "../Utils/StyleUtils";

type Props = {
  numberOfStars: number;
  setRating;
  startStars?;
};

type State = {
  rating;
};

const stateToProps = (state) => ({});

const dispatchToProps = (dispatch) => ({});

class RatingStars extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      rating: this.props.startStars || 0,
    };
  }

  ratingText(rating) {
    switch (rating) {
      case 1:
        return "Péssimo";
      case 2:
        return "Ruim";
      case 3:
        return "Regular";
      case 4:
        return "Bom";
      case 5:
        return "Ótimo";
      default:
        return "";
    }
  }

  onClickStar = (ratingValue) => {
    this.setState({ rating: ratingValue });
    this.props.setRating(ratingValue);
  };

  render() {
    return (
      <View
        style={[
          {
            width: "100%",
            alignItems: "center",
          },
        ]}
      >
        <View style={{ flexDirection: "row", marginTop: 25, marginBottom: 15 }}>
          {[...Array(this.props.numberOfStars)].map((star, i) => {
            const ratingValue = i + 1;

            return (
              <Star
                rating={this.state.rating}
                value={ratingValue}
                onClick={(rating) => this.onClickStar(rating)}
              />
            );
          })}
        </View>
        <Text
          style={[
            { textAlign: "center" },
            StyleUtils.color_txt_dark,
            StyleUtils.font_btn_bold,
            StyleUtils.font_size_16,
          ]}
        >
          {this.ratingText(this.state.rating)}
        </Text>
      </View>
    );
  }
}

// const style = StyleSheet.create({
//     radioStar: {
//     },
// });

export default connect(stateToProps, dispatchToProps)(RatingStars);
