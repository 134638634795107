import React from "react";
import { connect } from "react-redux";
import { Image, Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import BackgroundShapesBlue from "../../assets/backgroundShapesBlue.svg";
import CheckBlue from "../../assets/checkBlue.svg";

type Props = {
  selectedUser;
  history;
};

const stateToProps = (state) => {
  return {
    selectedUser: state.selRed.selectedUser,
  };
};

const dispatchToProps = (dispatch) => ({});

const QuickClassEnd = (props: Props) => {
  return (
    <View style={[StyleUtils.screen_default, { justifyContent: "center" }]}>
      <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
        <View
          style={{
            alignSelf: "center",
            justifyContent: "center",
            marginBottom: 70,
          }}
        >
          <SVGImage source={<BackgroundShapesBlue />} />
          <Image
            source={
              !!props.selectedUser && !!props.selectedUser.imageURL
                ? {
                    uri: props.selectedUser.imageURL,
                  }
                : require("../../assets/userPlaceHolder.png")
            }
            style={{
              width: 140,
              height: 140,
              position: "absolute",
              borderRadius: 8,
              overflow: "hidden",
              backgroundColor: "lightgrey",
              alignSelf: "center",
              bottom: 0,
            }}
          />
          <View
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              bottom: -20,
              alignSelf: "center",
            }}
          >
            <SVGImage source={<CheckBlue />} />
          </View>
        </View>

        <View>
          <Text
            style={[
              { textAlign: "center" },
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_22,
              StyleUtils.font_cera_bold,
            ]}
          >
            Você concluiu seu teste grátis
          </Text>

          <Text
            style={[
              { textAlign: "center", margin: 30, marginTop: 16 },
              StyleUtils.color_txt_light,
              StyleUtils.font_size_16,
              StyleUtils.font_cera_regular,
            ]}
          >
            Encontre agora os melhores professores para você fazer suas próximas
            aulas quando quiser.
          </Text>
        </View>

        <View>
          <TouchableOpacity
            style={[
              { margin: 16 },
              StyleUtils.color_bg_primary,
              StyleUtils.shape_btn_small,
            ]}
            onPress={() => {
              props.history.push("/landing/subjects");
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 16 },
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
              ]}
            >
              Encontrar Professores
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default connect(stateToProps, dispatchToProps)(QuickClassEnd);
