import { useEffect } from "react";

const useContextMenuClick = (onClick) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };
  useEffect(() => {
    window.addEventListener("contextmenu", handleClick);
    return () => {
      window.removeEventListener("contextmenu", handleClick);
    };
  });
};

export default useContextMenuClick;
