import React, { Component } from "react";
import { Animated, BackHandler, Dimensions, Easing, View } from "react-native";
import { analytics } from "../../Analytics";
import StyleUtils from "../../Utils/StyleUtils";
import TFChoosePath from "./TFChoosePath";
import TFStart from "./TFStart";
import TutoreFuturePerson2 from "../../../assets/tutoreFuturePerson2.svg";
import TutoreFuturePerson3 from "../../../assets/tutoreFuturePerson3.svg";
import TFStudyPlanName from "./TFStudyPlanName";
import TFGoals from "./TFGoals";
import TFAge from "./TFAge";
import TFPlan from "./TFPlan";
import TFTutorMatch from "./TFTutorMatch";
import {
  createUserStudyPlan,
  getRandomCounselor,
  updateUserPersonalInfo,
} from "../../Server";
import { selectTutor } from "../../Actions";
import { connect } from "react-redux";
import * as Utils from "../../Utils/Utils";

type Props = {
  location;
  history;
  user;
  dispatch;
};

type State = {
  componentStack;
  backHandler;
  translateX;
  isTransitioning;
  goal;
  time;
  age;
  investment;
  school;
  studyPlanName;
  strInvestment;
  headerText;
  graphText;
  tutor;
};

const { width } = Dimensions.get("window");

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
  };
}
const dispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

class TFOnboarding extends Component<Props, State> {
  fadeAnim = new Animated.Value(1);

  constructor(props) {
    super(props);
    analytics.sendTFOnboarding();
    this.state = {
      componentStack: [
        <TFChoosePath
          history={props.history}
          onContinueVocationalTest={() => this.continueVocationalTest()}
          onContinueStudyPlan={() => this.continueStudyPlan()}
        />,
      ],
      backHandler: BackHandler.addEventListener(
        "hardwareBackPress",
        this.backPress
      ),
      translateX: new Animated.Value(0),
      isTransitioning: false,
      goal: "",
      time: "",
      age: "",
      investment: "",
      school: "",
      studyPlanName: "",
      strInvestment: "",
      headerText: "",
      graphText: "",
      tutor: null,
    };
  }

  componentDidMount() {
    updateUserPersonalInfo(
      this.props.user.id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      new Date()
    )
      .then(() => {})
      .catch(() => {});
    getRandomCounselor(this.props.user.id).then((tutor) => {
      this.setState({ tutor: tutor });
      this.props.dispatch(selectTutor(tutor));
    });
  }

  componentWillUnmount() {
    this.state.backHandler.remove();
  }

  backPress = () => {
    if (this.state.componentStack.length > 1) {
      this.safelyPopComponent();
      return true;
    }
    return false;
  };

  executeWithTransition(method, back?: boolean, useFade?: boolean) {
    this.setState({ isTransitioning: true });
    if (useFade) {
      Animated.timing(this.fadeAnim, {
        toValue: 0,
        duration: 400,
        easing: Easing.cubic,
        useNativeDriver: true,
      }).start(() => {
        method();
        Animated.timing(this.fadeAnim, {
          toValue: 1,
          duration: 400,
          easing: Easing.cubic,
          useNativeDriver: true,
        }).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    } else {
      Animated.sequence([
        Animated.timing(this.state.translateX, {
          toValue: back ? -1 * width : width,
          duration: 1,
          useNativeDriver: true,
          delay: 400,
        }),
      ]).start(() => {
        method();
        Animated.sequence([
          Animated.timing(this.state.translateX, {
            toValue: 0,
            duration: 250,
            useNativeDriver: true,
          }),
        ]).start(() => {
          this.setState({ isTransitioning: false });
        });
      });
    }
  }

  pushComponent(component, useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack.concat(component);
        this.setState({
          componentStack: tempComponents,
        });
      },
      false,
      useFade
    );
  }

  safelyPopComponent(useFade?: boolean) {
    if (this.state.isTransitioning) return;
    this.popComponent(useFade);
  }

  popComponent(useFade?: boolean) {
    this.executeWithTransition(
      () => {
        const tempComponents = this.state.componentStack;
        tempComponents.pop();
        this.setState({ componentStack: tempComponents });
      },
      true,
      useFade
    );
  }

  getCurrentComponent() {
    return (
      <Animated.View
        style={{
          flex: 1,
          opacity: this.fadeAnim,
          transform: [{ translateX: this.state.translateX }],
        }}
      >
        {this.state.componentStack[this.state.componentStack.length - 1]}
      </Animated.View>
    );
  }

  continueStudyPlan = () => {
    if (this.state.isTransitioning) return;
    analytics.sendTFStudyPlan();
    this.pushComponent(
      <TFStart
        headerText={"Crie seu plano de estudos\ne alcance seus objetivos"}
        subHeaderText={
          "Responda algumas perguntas e receba\num plano personalizado."
        }
        onContinue={() => this.continueStartStudyPlan()}
        onCancel={() => this.safelyPopComponent(false)}
        image={TutoreFuturePerson2}
        buttonText={"Começar"}
      />
    );
  };

  continueVocationalTest = () => {
    if (this.state.isTransitioning) return;
    analytics.sendTFStartVocationalTest();
    this.pushComponent(
      <TFStart
        headerText={"Descubra qual carreira\ncombina com você"}
        subHeaderText={
          "Receba sugestões de carreiras para te\najudar a refletir sobre seu futuro!"
        }
        onContinue={() => this.props.history.push("/vocationaltest")}
        onCancel={() => this.safelyPopComponent(false)}
        image={TutoreFuturePerson3}
        buttonText={"Fazer Teste Vocacional"}
      />
    );
  };

  continueStudyPlanName = (name) => {
    if (this.state.isTransitioning) return;
    this.setState({ studyPlanName: name });
    analytics.sendTFStudyPlanName();
    const defaultGoals = [
      { id: 0, name: "Passar de ano na escola" },
      { id: 1, name: "Passar no vestibular" },
      { id: 2, name: "Encontrar meu primeiro emprego" },
      { id: 3, name: "Fazer uma transição de carreira" },
      { id: 4, name: "Outros" },
    ];
    this.pushComponent(
      <TFGoals
        onContinue={(goal) => this.continueGoals(goal)}
        onBack={() => this.safelyPopComponent(false)}
        areas={defaultGoals}
        getInitialGoals={() => this.state.goal}
        progress={"17%"}
        headerText={"Qual o seu principal\nobjetivo hoje?"}
      />
    );
  };

  continueGoals = (goal) => {
    if (this.state.isTransitioning) return;
    this.setState({ goal: goal });
    analytics.sendTFContinueGoals();
    const defaultTime = [
      { id: 0, name: "Menos de 1 mês" },
      { id: 1, name: "Entre 1 e 3 meses" },
      { id: 2, name: "Entre 3 e 6 meses" },
      { id: 3, name: "Entre 6 e 12 meses" },
      { id: 4, name: "Mais de 1 ano" },
      { id: 5, name: "Ainda não sei" },
    ];
    this.pushComponent(
      <TFGoals
        onContinue={(time) => this.continueTime(time)}
        onBack={() => this.safelyPopComponent(false)}
        areas={defaultTime}
        getInitialGoals={() => goal}
        progress={"33%"}
        headerText={"Em quanto tempo você gostaria de atingir seu objetivo?"}
      />
    );
  };

  continueTime = (time) => {
    if (this.state.isTransitioning) return;
    this.setState({ time: time });
    analytics.sendTFStudyTime();
    let headerText = "";
    let graphText = "";
    let date = "";
    // date is menos de 1 mês
    if (time.id === 0) {
      date = Utils.dateFormatMonthYear(Utils.addMonths(new Date(), 1));
    }
    //date is entre 1 e 3 meses
    else if (time.id === 1) {
      date = Utils.dateFormatMonthYear(Utils.addMonths(new Date(), 2));
    }
    // date is entre 3 e 6 meses
    else if (time.id === 2) {
      date = Utils.dateFormatMonthYear(Utils.addMonths(new Date(), 4));
    }
    //date is entre 6 e 12 meses
    else if (time.id === 3) {
      date = Utils.dateFormatMonthYear(Utils.addMonths(new Date(), 9));
    }
    //date is mais de 1 ano
    else if (time.id === 4) {
      date = Utils.dateFormatMonthYear(Utils.addMonths(new Date(), 14));
    }

    // goal is Passar de ano na escola
    if (this.state.goal.id === 0) {
      if (time.id === 5) {
        headerText = "Nota 10 na escola daqui a pouco";
      } else {
        headerText = "Nota 10 na escola em " + date;
      }
      graphText = "Nota 10 na escola";
    }
    // goal is Passar no vestibular
    else if (this.state.goal.id === 1) {
      if (time.id === 5) {
        headerText = "Alcance sua aprovação daqui a pouco";
      } else {
        headerText = "Alcance sua aprovação em " + date;
      }
      graphText = "Aprovação no vestibular";
    }
    // goal is encontrar emprego
    else if (this.state.goal.id === 2) {
      if (time.id === 5) {
        headerText = "Seu primeiro emprego daqui a pouco";
      } else {
        headerText = "Seu primeiro emprego em " + date;
      }
      graphText = "Seu novo emprego";
    }
    // goal is transição de carreira
    else if (this.state.goal.id === 3) {
      if (time.id === 5) {
        headerText = "Sua carreira dos sonhos daqui a pouco";
      } else {
        headerText = "Sua carreira dos sonhos em " + date;
      }
      graphText = "Seu novo emprego";
    }
    // goal is outros
    else if (this.state.goal.id === 4) {
      if (time.id === 5) {
        headerText = "Alcance seu objetivo daqui a pouco";
      } else {
        headerText = "Alcance seu objetivo em " + date;
      }
      graphText = "Seu objetivo";
    }

    this.setState({ headerText: headerText, graphText: graphText });

    const defaultTime = [
      { id: 0, name: "Menos de 1h" },
      { id: 1, name: "Entre 1h e 3h" },
      { id: 2, name: "Entre 3h e 6h" },
      { id: 3, name: "Mais de 6h" },
      { id: 4, name: "Ainda não sei" },
    ];
    this.pushComponent(
      <TFGoals
        onContinue={(investment) => this.continueTimeAvailable(investment)}
        onBack={() => this.safelyPopComponent(false)}
        areas={defaultTime}
        getInitialGoals={() => this.state.goal}
        progress={"50%"}
        headerText={"Quantas horas por dia\nvocê pode investir?"}
      />
    );
  };

  continueTimeAvailable = (investment) => {
    if (this.state.isTransitioning) return;
    let strInvestment = {
      0: "Estudando menos de 1h por dia",
      1: "Estudando entre 1h e 3h por dia",
      2: "Estudando entre 3h e 6h por dia",
      3: "Estudando mais de 6h por dia",
    }[investment.id];

    this.setState({ investment: investment });
    this.setState({ strInvestment: strInvestment });
    const defaultSchool = [
      { id: 0, name: "Escola Pública" },
      { id: 1, name: "Escola Particular" },
      { id: 2, name: "Prefiro não dizer" },
      { id: 3, name: "Não estou estudando" },
    ];
    this.pushComponent(
      <TFGoals
        onContinue={(school) => this.continueSchooling(school)}
        onBack={() => this.safelyPopComponent(false)}
        areas={defaultSchool}
        getInitialGoals={() => this.state.goal}
        progress={"67%"}
        headerText={"Você estuda em qual tipo de\nescola?"}
      />
    );
  };

  continueSchooling = (school) => {
    if (this.state.isTransitioning) return;
    analytics.sendTFSchoolingType();
    this.setState({ school: school });
    this.pushComponent(
      <TFAge
        onContinue={(age) => this.continueAge(age)}
        onBack={() => this.safelyPopComponent(false)}
        progress={"83%"}
      />
    );
  };

  continueAge = (age) => {
    if (this.state.isTransitioning) return;
    analytics.sendTFAge();
    this.setState({ age: age });
    createUserStudyPlan(
      this.props.user.id,
      this.state.goal.name,
      this.state.time.name,
      this.state.investment.name,
      this.state.school.name,
      age,
      this.state.studyPlanName
    );
    this.pushComponent(
      <TFPlan
        onContinue={() => this.continuePlan()}
        investment={this.state.strInvestment}
        headerText={this.state.headerText}
        graphText={this.state.graphText}
        time={this.state.time}
      />
    );
  };

  continuePlan = () => {
    if (this.state.isTransitioning) return;
    analytics.sendTFCreatePlan();
    this.pushComponent(
      <TFTutorMatch
        onContinue={() =>
          this.props.history.push({
            pathname: "/booking",
            state: { tfOnboarding: true, source: "tutore_future" },
          })
        }
        onBack={() => this.safelyPopComponent(false)}
        history={this.props.history}
        tutor={this.state.tutor}
      />
    );
  };

  continueStartStudyPlan = () => {
    if (this.state.isTransitioning) return;
    analytics.sendTFStartStudyPlan();
    this.pushComponent(
      <TFStudyPlanName
        onBack={() => this.safelyPopComponent(false)}
        onContinue={(name) => this.continueStudyPlanName(name)}
      />
    );
  };

  render() {
    return (
      <View style={StyleUtils.screen_default}>
        {this.getCurrentComponent()}
      </View>
    );
  }
}

export default connect(mapStateToProps, dispatchToProps)(TFOnboarding);
