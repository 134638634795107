import React, { useState } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  Platform,
  StyleSheet,
  TextInput,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_TXT_LIGHT,
  COLOR_PRIMARY,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import BackButton from "../../../assets/backbutton.svg";
import ButtonElement from "../../Elements/ButtonElement";

type Props = {
  onContinue;
  onBack;
  progress;
};

export default function TFAge(props: Props) {
  const [age, setAge] = useState(0);

  const ageInputPopup = () => {
    return (
      <>
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text
            style={[
              StyleUtils.color_txt_dark,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_22,
              {
                textAlign: "center",
                marginBottom: 8,
              },
            ]}
          >
            Qual a sua idade?
          </Text>
          <TextInput
            maxLength={2}
            keyboardType={"numeric"}
            editable={true}
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_dark,
              {
                fontSize: 32,
                textAlign: "center",
              },
            ]}
            onChangeText={(text) => setAge(parseInt(text))}
          />
          <View
            style={{
              borderBottomColor: COLOR_TXT_LIGHT,
              borderBottomWidth: 1,
              width: 78,
              alignSelf: "center",
            }}
          />
        </View>
        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Próximo"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              props.onContinue(age);
            }}
            disabled={!age}
          />
        </View>
      </>
    );
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <View style={{ flex: 1 }}>
        <View style={[{ justifyContent: "center", width: "100%" }]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 16,
            }}
          >
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                style={{ flexDirection: "row" }}
                onPress={() => {
                  props.onBack();
                }}
              >
                <SVGImage source={<BackButton />} />
              </TouchableOpacity>
            </View>
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
              ]}
            >
              Meu plano de estudo
            </Text>
            <View style={{ flex: 1 }}></View>
          </View>
        </View>
        <View
          style={{
            borderBottomColor: COLOR_MUTED,
            borderBottomWidth: 1,
            width: "100%",
            zIndex: 10,
          }}
        />
        <View style={styles.container}>
          <View
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: COLOR_PRIMARY,
              width: props.progress,
            }}
          />
        </View>
        {ageInputPopup()}
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  cellBase: {
    height: 95,
    width: 154,
    borderRadius: 10,
    alignItems: "center",
    marginVertical: 4,
    justifyContent: "center",
    flexDirection: "column",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 20,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: 4,
  },
  progressBar: {
    height: 4,
    width: "100%",
  },
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
});
