import React from "react";
import { Text, TouchableOpacity, View, FlatList } from "react-native";
import StyleUtils, { COLOR_MUTED, COLOR_WHITE } from "../Utils/StyleUtils";
import { connect } from "react-redux";
import SVGImage from "../Utils/SVGImage";
import BackButton from "../../assets/backButtonBorder.svg";
import { useState } from "react";
import { useEffect } from "react";
import { getStoredNotifications } from "../Server";
import {
  NotificationType,
  TutoreNotification,
} from "../Models/TutoreNotification";
import NotificationCard from "./NotificationCard";
import User from "../Models/User";

type Props = {
  history;
  user: User;
};

function stateToProps(state) {
  return {
    user: state.userRed.user,
  };
}

function NotificationList(props: Props) {
  const [notifications, setNotifications] = useState<TutoreNotification[]>([]);

  useEffect(() => {
    if (props.user) {
      getStoredNotifications(props.user.id, 100, 0)
        .then((res) => setNotifications(res.notifications))
        .catch(console.log);
    }
  }, []);

  function notificationCardPressed(type) {
    switch (type) {
      case NotificationType.HOUR_BEFORE_CLASS:
      case NotificationType.TEN_MINUTES_BEFORE_CLASS:
      case NotificationType.MINUTE_BEFORE_CLASS: {
        props.history.push("/events");
        break;
      }

      case NotificationType.MESSAGE_SENT: {
        props.history.push("/messages");
        break;
      }

      case NotificationType.HOMEWORK_ASSIGNED:
      case NotificationType.HOMEWORK_INACTIVITY:
      case NotificationType.HOMEWORK_INACTIVITY_DEADLINE:
      case NotificationType.HOMEWORK_FINISHED:
      case NotificationType.HOMEWORK_CORRECTION_AVAILABLE: {
        props.history.push("/exercises");
        break;
      }

      default:
        break;
    }
  }

  return (
    <View style={StyleUtils.screen_default}>
      <View
        style={[
          {
            backgroundColor: COLOR_WHITE,
            justifyContent: "center",
            width: "100%",
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: 16,
          }}
        >
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ flexDirection: "row" }}
              onPress={() => {
                props.history.goBack();
              }}
            >
              <SVGImage source={<BackButton />} />
            </TouchableOpacity>
          </View>

          <View
            style={{
              alignContent: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
                { textAlign: "center" },
              ]}
            >
              Notificações
            </Text>
          </View>
          <View style={{ flex: 1 }}></View>
        </View>
      </View>
      <View
        style={{
          borderBottomColor: COLOR_MUTED,
          borderBottomWidth: 1,
          width: "100%",
          zIndex: 10,
          shadowColor: "#000000",
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowRadius: 3,
          shadowOpacity: 0.3,
        }}
      />
      {notifications.length == 0 && (
        <Text
          style={[
            StyleUtils.color_txt_light,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_14,
            { textAlign: "center", width: "100%", marginTop: 30 },
          ]}
        >
          Sem novidades até o momento.
        </Text>
      )}
      <FlatList
        style={{
          flex: 1,
          width: "100%",
        }}
        data={notifications}
        renderItem={(item) => (
          <NotificationCard
            notificationInfo={item.item}
            onPress={notificationCardPressed}
          />
        )}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
}

export default connect(stateToProps, null)(NotificationList);
