import React from "react";
import { ScrollView, Text, View, Image } from "react-native";
import StyleUtils, {
  COLOR_MUTED,
  COLOR_SUCCESS,
  COLOR_TXT_DARK,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import WhiteCircle from "../../../assets/circle_white.svg";
import GreenCircle from "../../../assets/green_circle.svg";
import Check from "../../../assets/check.svg";

type Props = {
  exercise;
};

const ExerciseDisplay = ({ exercise }: Props) => {
  return (
    <ScrollView
      style={[
        StyleUtils.shape_card_default,
        StyleUtils.border_color_btn_muted,
        StyleUtils.color_bg_white,
        {
          width: 450,
          height: 650,
          marginLeft: 11,
          display: "flex",
          paddingHorizontal: 50,
          paddingVertical: 25,
        },
      ]}
    >
      <Text
        style={[
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_18,
          { marginBottom: 27 },
        ]}
      >
        {exercise.name}
      </Text>
      <Text style={[StyleUtils.font_cera_regular, StyleUtils.font_size_16]}>
        {exercise.description}
      </Text>
      {exercise.imageUrl ? (
        <View>
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              { marginBottom: 18, marginTop: 33 },
            ]}
          >
            Image Enunciado
          </Text>
          <Image
            style={{ width: 300, height: 300, alignSelf: "center" }}
            source={{ uri: exercise.imageUrl }}
          />
        </View>
      ) : (
        <View />
      )}
      {exercise.correctAnswer !== "" && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 24,
            alignSelf: "center",
          }}
        >
          {exercise.possibleAnswers.map((answer, index) => (
            <PossibleAnswerBox
              letter={getLetter(index)}
              answer={answer}
              isCorrect={answer === exercise.correctAnswer}
            />
          ))}
        </View>
      )}
    </ScrollView>
  );
};
const PossibleAnswerBox = ({ letter, answer, isCorrect }) => {
  return (
    <View
      style={[
        StyleUtils.shape_card_default,

        {
          width: 150,
          minHeight: 85,
          marginBottom: 7,
          marginRight: 7,
          backgroundColor: isCorrect ? "#F2FFF7" : "#FFFFFF",
          borderWidth: 1,
          borderColor: isCorrect ? COLOR_SUCCESS : COLOR_MUTED,
        },
      ]}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 6,
          position: "relative",
        }}
      >
        <SVGImage
          style={{ width: 24, height: 24 }}
          source={isCorrect ? <GreenCircle /> : <WhiteCircle />}
        />
        <Text
          style={{
            position: "absolute",
            top: 9,
            left: 14,
            fontFamily: "Helvetica",
            fontWeight: "400",
            color: isCorrect ? COLOR_SUCCESS : COLOR_TXT_DARK,
          }}
        >
          {letter}
        </Text>
        {isCorrect && <SVGImage source={<Check />} />}
      </View>
      <View
        style={{
          marginHorizontal: 10,
          paddingBottom: 10,
          width: 130,
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            { alignSelf: "center" },
          ]}
        >
          {answer}
        </Text>
      </View>
    </View>
  );
};

const getLetter = (index) => {
  switch (index) {
    case 0:
      return "a";
    case 1:
      return "b";
    case 2:
      return "c";
    case 3:
      return "d";
    case 4:
      return "e";
    default:
      return "";
  }
};
export default ExerciseDisplay;
