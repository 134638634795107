import React, { useEffect, useState } from "react";
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Clipboard,
  BackHandler,
} from "react-native";
import { connect } from "react-redux";
import CommentIcon from "../../assets/commentIcon.svg";
import DotGreen from "../../assets/dotGreen.svg";
import DotWhite from "../../assets/dotWhite.svg";
import ForwardArrowNormal from "../../assets/forwardArrowNormal.svg";
import GradientBackground from "../../assets/gradientBackground.svg";
import StarMuted from "../../assets/starMuted.svg";
import StarYellow from "../../assets/starYellow.svg";
import {
  selectEvent,
  selectTutor,
  selectUser,
  setTutorSearch,
  setTutorSearchBack,
  setTuteeInfo,
} from "../Actions";
import { analytics } from "../Analytics";
import {
  ChatIcon,
  CloseIcon,
  LikeIcon,
  ShareIcon,
} from "../Elements/IconElements";
import { UserImageBackGround } from "../Elements/UserImages";
import Rating from "../Models/Rating";
import { Tutor } from "../Models/TutorInfo";
import {
  getRatings,
  getTutor,
  setTutor as serverSetTutor,
  getUserCredit,
  getEvents,
  EventsFilter,
  chooseTutor,
} from "../Server";
import SignUpPopUp from "../SignUp/SignUpPopUp";
import StyleUtils, { COLOR_TRUE_WHITE, COLOR_WHITE } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import { formatCurrency, userShortName } from "../Utils/Utils";
import CommentCard from "./CommentCard";
import { DiscountBadge } from "../Payment/DiscountBadge";
import RemoteConfigBridge from "../RemoteConfig/RemoteConfigBridge";
import FirstSessionFreeBanner from "../../assets/greenRibbonBig.svg";
import Clock from "../../assets/clockWhiteBig.svg";
import ParentsTalkNowPopup from "../Popups/ParentsTalkNowPopup";
import TuteeInfo from "../Models/TuteeInfo";

type Props = {
  dispatch;
  history;
  userID?;
  location;
  searchedTutors: Tutor[];
  selectedTutor;
  superTutor;
  selectTutor;
  talkspaceOnboarding: boolean;
  setTuteeInfo: (tuteeInfo: TuteeInfo) => void;
  fromDynamicLink;
};

function mapStateToProps(state) {
  return {
    superTutor: state.selRed.superTutor,
    selectedTutor: state.selRed.selectedTutor,
    searchedTutors: state.selRed.searchedTutors,
    useSearchedTutors: state.selRed.useSearchedTutors,
    userID: state.userRed.user && state.userRed.user.id,
    talkspaceOnboarding: state.userRed.talkspaceOnboarding,
    fromDynamicLink: state.userRed.fromDynamicLink,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectEvent: (event) => dispatch(selectEvent(event)),
    selectUser: (user) => dispatch(selectUser(user)),
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
    setTuteeInfo: (tuteeInfo: TuteeInfo) => dispatch(setTuteeInfo(tuteeInfo)),
    dispatch: dispatch,
  };
}

const B = (props) => (
  <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
);

function TutorProfile(props: Props) {
  const [numComments, setNumComments] = useState(0);
  const [ratings, setRatings] = useState<Rating[]>([]);
  const [online] = useState(false);
  const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
  const [isShowTimesClicked, setIsShowTimesClicked] = useState(false);
  const [isFavoriteCliked, setIsFavoriteCliked] = useState(false);
  const [priceShowABTest, setPriceShowABTest] = useState(0);
  const [firstClassFree, setFirstClassFree] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scheduleButton, setScheduleButton] = useState(false);
  const [pricePackageShow, setPricePackageShow] = useState(false);
  const [talkNowParents, setTalkNowParents] = useState(false);
  const [showParentsPopup, setShowParentsPopup] = useState(false);
  const [contactPopup, setContactPopup] = useState(false);
  const [parentsOnboarding, setParentsOnboarding] = useState(false);
  const [showNoContactPopup, setShowNoContactPopup] = useState(false);

  useEffect(() => {
    RemoteConfigBridge.remoteConfig("pricePackageShow", (value) => {
      var valueBool = value == "1";
      setPricePackageShow(valueBool);
    });
    RemoteConfigBridge.remoteConfig("scheduleClassButton", (value) => {
      var valueBool = value == "1";
      setScheduleButton(valueBool);
    });
    RemoteConfigBridge.remoteConfig("priceShow", (value) => {
      setPriceShowABTest(parseInt(value));
    });
    RemoteConfigBridge.remoteConfig("talkNowParents", (value) => {
      var valueBool = value == "1";
      if (
        props.location &&
        props.location.state &&
        props.location.state.parentsOnboarding
      ) {
        setParentsOnboarding(true);
        if (
          props.location &&
          props.location.state &&
          props.location.state.noContact
        ) {
          analytics.sendWaitingContact(props.userID);
          setShowNoContactPopup(true);
        }
        if (valueBool) {
          if (
            props.location &&
            props.location.state &&
            !props.location.state.noContact
          ) {
            setShowParentsPopup(true);
          }
          setTalkNowParents(true);
        }
      }
    });
    RemoteConfigBridge.remoteConfig("firstClassFree", (value) => {
      var valueBool = value == "1";
      if (valueBool && !props.fromDynamicLink) {
        if (props.userID) {
          getUserCredit(props.userID).then((res) => {
            if (res.amount === 0) {
              var eventFilter: EventsFilter = {
                tutorId: undefined,
                tuteeId: props.userID,
                periodStart: undefined,
                periodEnd: undefined,
                limit: 1,
              };
              getEvents(eventFilter).then((result: any) => {
                if (result && result.length === 0) {
                  setFirstClassFree(true);
                }
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          setFirstClassFree(true);
        }
      } else {
        setLoading(false);
      }
    });
    if (props.userID) {
      chooseTutor(props.userID, props.selectedTutor.email).then((tuteeInfo) => {
        props.setTuteeInfo(tuteeInfo);
      });
    }
  }, []);

  const [tutor, setTutor] = useState<Tutor>(props.selectedTutor);
  const hasRatings = tutor.rating && tutor.rating.count > 0;
  useEffect(() => analytics.sendTutorProfileShow(tutor.name), []);

  const price: number =
    tutor.tutorInfo &&
    tutor.tutorInfo.currencyCode &&
    tutor.tutorInfo.hourPrice != undefined
      ? tutor.tutorInfo.hourPrice
      : 0;

  const creditPrice: number =
    tutor.tutorInfo && tutor.tutorInfo.creditPrice != undefined
      ? tutor.tutorInfo.creditPrice
      : 0;

  const favorite = !!(
    props.userID &&
    tutor.tutorInfo &&
    tutor.tutorInfo.tutees &&
    tutor.tutorInfo.tutees[props.userID] &&
    tutor.tutorInfo.tutees[props.userID].favorite
  );

  useEffect(() => {
    getTutor(props.userID, tutor.id, { include: ["rating", "favorite"] })
      .then((t) => {
        dispatchTutorUpdate(t);
        setTutor(t);
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    getRatings(props.userID, {
      tutorId: tutor.id,
      hasComment: false,
      limit: 10,
      include: ["total"],
    }).then((r) => {
      let arr: Rating[] = [];
      for (const item in r.ratings) {
        if (r.ratings[item].rating !== null) {
          arr.push(r.ratings[item]);
        }
      }
      setRatings(arr);
      setNumComments(arr.length);
    });
  }, []);

  const handleClose = () => {
    if (props.talkspaceOnboarding) {
      props.history.push("/home");
    } else if (
      props.location &&
      props.location.state &&
      props.location.state.useTutorSearchBack
    ) {
      props.dispatch(setTutorSearchBack());
      props.history.goBack();
    } else if (parentsOnboarding) {
      props.history.push("/");
    } else {
      props.history.goBack();
    }
  };

  const handleBackPress = () => {
    handleClose();
    return true;
  };

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackPress);
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackPress);
    };
  }, []);

  const header = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          margin: 16,
          justifyContent: "space-between",
        }}
      >
        <CloseIcon
          onPress={handleClose}
          height={24}
          width={24}
          style={[
            StyleUtils.color_bg_true_white,
            {
              height: 32,
              width: 32,
              borderRadius: 16,
            },
          ]}
        />

        <View style={{ flexDirection: "row" }}>
          <ChatIcon
            onPress={() => {
              analytics.sendTutorProfileCardSendMessage();
              if (props.userID) {
                onSendMessage();
              } else {
                setTimeout(() => {
                  setIsSendMessageClicked(true);
                }, 300);
              }
            }}
            height={24}
            width={24}
            style={[
              StyleUtils.color_bg_true_white,
              {
                height: 32,
                width: 32,
                borderRadius: 16,
              },
            ]}
          />
          <ShareIcon
            onPress={() => {
              analytics.sendTutorProfileCardShare();
              writeToClipboard();
            }}
            height={50}
            width={50}
            style={[
              StyleUtils.color_bg_true_white,
              {
                height: 32,
                width: 32,
                borderRadius: 16,
                marginLeft: 8,
              },
            ]}
          />
          <LikeIcon
            onPress={() => {
              if (props.userID) {
                onLikeTutor();
              } else {
                setTimeout(() => {
                  setIsFavoriteCliked(true);
                }, 300);
              }
            }}
            selected={favorite}
            height={24}
            width={24}
            style={[
              StyleUtils.color_bg_true_white,
              {
                height: 32,
                width: 32,
                borderRadius: 16,
                overflow: "hidden",
                marginLeft: 8,
              },
            ]}
          />
        </View>
      </View>
    );
  };

  const writeToClipboard = async () => {
    let initialStr =
      "Olá, estude com " + tutor.name + " no Tutore. Basta clicar no link: \n";
    let iosLink = "iOS: tutore://tutorlink/" + tutor.id + "\n\n";
    let androidLink =
      "Android: https://tutore.learning-machine.org/tutorlink/" + tutor.id;
    await Clipboard.setString(initialStr + iosLink + androidLink);
    alert("Copied to Clipboard!");
  };

  const onSendMessage = () => {
    props.history.push({
      pathname: `/messages/${tutor.id}`,
      state: { shouldGoBack: true },
    });
    setIsSendMessageClicked(false);
  };

  const onSelectTutor = () => {
    props.selectTutor(tutor);
    if (
      props.location &&
      props.location.state &&
      props.location.state.subject
    ) {
      let subjectTestID = "";
      if (props.location.state.subjectTestID) {
        subjectTestID = props.location.state.subjectTestID;
      }
      props.history.push({
        pathname: "/booking",
        state: {
          subject: props.location.state.subject.name,
          subjectLevel: props.location.state.subjectLevel.name,
          subjectTestID: subjectTestID,
          source: "tutor_match",
        },
      });
    } else {
      props.history.push("/booking", {
        source: "tutor_match",
      });
    }
    setIsShowTimesClicked(false);
  };

  const onLikeTutor = () => {
    const newFavorite = !favorite;
    serverSetTutor(props.userID, tutor.id, { favorite: newFavorite })
      .then(() => {
        let tutorInfo = tutor.tutorInfo || {};
        const tutees = tutorInfo.tutees ? { ...tutorInfo.tutees } : {};
        tutees[props.userID] = { favorite: newFavorite };
        tutorInfo = { ...tutorInfo, tutees };
        const updatedTutor = { ...tutor, tutorInfo };
        dispatchTutorUpdate(updatedTutor);
        setTutor(updatedTutor);
      })
      .catch(console.log);

    setIsFavoriteCliked(false);
  };

  const dispatchTutorUpdate = (tutor: Tutor) => {
    props.dispatch(
      setTutorSearch(
        props.searchedTutors.map((t) => {
          if (t.id === tutor.id) {
            return tutor;
          }
          return t;
        })
      )
    );
  };

  const onLoginSuccessPopUp = () => {
    props.history.push("/");
  };

  const onSignUpSuccessPopUp = () => {
    if (isSendMessageClicked) {
      onSendMessage();
    } else if (isShowTimesClicked) {
      onSelectTutor();
    } else {
      onLikeTutor();
    }
  };

  const closePopUp = () => {
    if (isSendMessageClicked) {
      setIsSendMessageClicked(false);
    } else if (isShowTimesClicked) {
      setIsShowTimesClicked(false);
    } else {
      setIsFavoriteCliked(false);
    }
  };

  return (
    <View style={StyleUtils.screen_default}>
      <ScrollView style={{ flex: 1 }}>
        <View style={{ width: "100%", height: 400 }}>
          <View>
            {header()}
            {!!props.superTutor && (
              <View
                style={[
                  Platform.OS === "ios" && { paddingTop: 5, paddingBottom: 7 },
                  Platform.OS === "android" && { paddingVertical: 3 },
                  {
                    marginLeft: 24,
                    marginRight: 197,
                    overflow: "hidden",
                    marginTop:
                      !!props.selectedTutor.tutorInfo
                        .firstSessionFreeDuration || firstClassFree
                        ? 180
                        : 215,
                    alignSelf: "flex-start",
                  },
                ]}
              >
                <Text
                  style={[
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_white,
                    StyleUtils.font_size_14,
                    {
                      backgroundColor: "#FF7354",
                      borderRadius: 6,
                      height: 26,
                      paddingHorizontal: 8,
                      textAlignVertical: "center",
                      overflow: "hidden",
                      paddingTop: Platform.OS === "ios" ? 8 : 0,
                    },
                  ]}
                >
                  Super Tutore
                </Text>
              </View>
            )}

            <View
              style={{
                zIndex: 1,
                marginTop: props.superTutor ? 8 : 260,

                marginLeft: 24,
              }}
            >
              {!!props.selectedTutor.tutorInfo.firstSessionFreeDuration &&
                !firstClassFree &&
                !loading && (
                  <View
                    style={{
                      position: "relative",
                      marginBottom: 16,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: props.superTutor ? 0 : -40,
                    }}
                  >
                    <SVGImage source={<FirstSessionFreeBanner />} />
                    <View
                      style={{
                        position: "absolute",

                        flexDirection: "row",
                        alignItems: "center",
                        left: 4,
                      }}
                    >
                      <SVGImage source={<Clock />}></SVGImage>
                      <Text
                        style={[
                          StyleUtils.font_cera_bold,
                          StyleUtils.font_size_14,
                          {
                            color: COLOR_TRUE_WHITE,
                            textAlignVertical: "center",
                            paddingTop: Platform.OS === "ios" ? 4 : 0,
                          },
                        ]}
                      >
                        {`${props.selectedTutor.tutorInfo.firstSessionFreeDuration}min Grátis`}
                      </Text>
                    </View>
                  </View>
                )}
              {firstClassFree && (
                <View
                  style={{
                    position: "relative",
                    marginBottom: 16,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: props.superTutor ? 0 : -40,
                  }}
                >
                  <SVGImage source={<FirstSessionFreeBanner />} />
                  <View
                    style={{
                      position: "absolute",

                      flexDirection: "row",
                      alignItems: "center",
                      left: 8,
                    }}
                  >
                    <Text
                      style={[
                        StyleUtils.font_cera_bold,
                        Platform.OS === "ios"
                          ? StyleUtils.font_size_14
                          : StyleUtils.font_size_12,
                        {
                          color: COLOR_TRUE_WHITE,
                          textAlignVertical: "center",
                          paddingTop: Platform.OS === "ios" ? 3 : 0,
                        },
                      ]}
                    >
                      1a Aula Grátis
                    </Text>
                  </View>
                </View>
              )}
              {parentsOnboarding && (
                <View
                  style={{
                    position: "relative",
                    marginBottom: 16,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: props.superTutor ? 0 : -40,
                  }}
                >
                  <SVGImage source={<FirstSessionFreeBanner />} />
                  <View
                    style={{
                      position: "absolute",

                      flexDirection: "row",
                      alignItems: "center",
                      left: 8,
                    }}
                  >
                    <Text
                      style={[
                        StyleUtils.font_cera_bold,
                        Platform.OS === "ios"
                          ? StyleUtils.font_size_14
                          : StyleUtils.font_size_12,
                        {
                          color: COLOR_TRUE_WHITE,
                          textAlignVertical: "center",
                          paddingTop: Platform.OS === "ios" ? 3 : 0,
                        },
                      ]}
                    >
                      Conversa Grátis
                    </Text>
                  </View>
                </View>
              )}
              <Text
                style={[
                  StyleUtils.color_txt_white,
                  StyleUtils.font_size_28,
                  StyleUtils.font_cera_regular,
                  {
                    fontWeight: "bold",
                    lineHeight: 25,
                    paddingTop: 2,
                  },
                ]}
              >
                {userShortName(tutor.name)}
              </Text>
              {!!online && (
                <>
                  <SVGImage
                    source={<DotGreen />}
                    style={{
                      marginTop: 10,
                      marginLeft: 10,
                    }}
                  />
                  <Text
                    style={[
                      styles.white_text_regular_14,
                      {
                        fontWeight: "bold",
                        marginLeft: 4,
                        marginTop: 10,
                        lineHeight: 14,
                      },
                    ]}
                  >
                    Online
                  </Text>
                </>
              )}
            </View>
            <View
              style={{
                marginLeft: 24,
                flexDirection: "row",
                alignItems: "center",
                zIndex: 1,
                marginBottom: 16,
              }}
            >
              {hasRatings && (
                <>
                  <SVGImage
                    source={<StarYellow />}
                    style={[
                      Platform.OS === "ios" && { paddingBottom: 8 },
                      Platform.OS === "android" && { paddingTop: 2 },
                    ]}
                  />
                  <Text
                    style={[
                      StyleUtils.color_txt_white,
                      StyleUtils.font_size_20,
                      StyleUtils.font_cera_regular,

                      {
                        paddingHorizontal: 4,
                      },
                    ]}
                  >
                    {tutor.rating && tutor.rating.average.toFixed(1)}
                  </Text>
                  <SVGImage
                    source={<DotWhite />}
                    style={{
                      paddingRight: 4,
                      paddingBottom: 4,
                    }}
                  />
                  <Text style={[styles.white_text_regular_14]}>
                    {`${tutor.rating && tutor.rating.count} Avaliações`}
                  </Text>
                  <SVGImage
                    source={<DotWhite />}
                    style={{
                      paddingHorizontal: 4,
                      paddingBottom: 4,
                    }}
                  />
                </>
              )}

              {tutor.rating && (
                <Text style={[styles.white_text_regular_14]}>
                  {`${tutor.rating.eventsCompleted} `}
                  {tutor.rating.eventsCompleted === 1 ? "Aula" : "Aulas"}
                </Text>
              )}
            </View>
            <UserImageBackGround
              style={{
                width: "100%",
                height: 400,
                position: "absolute",
                zIndex: -1,
              }}
              imageURL={tutor.imageURL}
            />
            <SVGImage
              source={<GradientBackground />}
              style={{
                zIndex: 0,
                position: "absolute",
                marginTop: 308,
              }}
            />
          </View>
        </View>
        <View
          style={{
            backgroundColor: "#3B3F50",
          }}
        >
          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.color_txt_light,
              StyleUtils.font_cera_regular,

              {
                marginLeft: 24,
                marginTop: 24,
                marginBottom: 8,
              },
            ]}
          >
            Matérias
          </Text>
          <Text
            style={[
              styles.white_text_regular_16,

              {
                marginLeft: 24,
                marginBottom: 32,
              },
            ]}
          >
            {tutor.tutorInfo &&
              tutor.tutorInfo.subjects &&
              tutor.tutorInfo.subjects.map((level) => level.name).join(", ")}
          </Text>
          <View
            style={{
              borderBottomColor: "#656B7F",
              borderBottomWidth: 1,
              width: 327,
              marginLeft: 24,
              marginBottom: 16,
            }}
          />
          <Text
            style={[
              styles.white_text_regular_16,
              {
                marginLeft: 24,
              },
            ]}
          >
            {tutor.tutorInfo && tutor.tutorInfo.shortDescription}
          </Text>
          <View
            style={{
              borderBottomColor: "#656B7F",
              borderBottomWidth: 1,
              width: 327,
              marginLeft: 24,
              marginBottom: 16,
              marginTop: 16,
            }}
          />
          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.color_txt_light,
              StyleUtils.font_cera_regular,

              {
                marginLeft: 24,
                marginTop: 16,
                marginBottom: 8,
              },
            ]}
          >
            Especialidades
          </Text>
          <Text
            style={[
              styles.white_text_regular_16,

              {
                marginLeft: 24,
                marginBottom: 32,
              },
            ]}
          >
            {tutor.tutorInfo && tutor.tutorInfo.specialties}
            {/*ENEM, FUVEST, Vestibulares, GMAT, Business Conversation*/}
          </Text>

          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.color_txt_light,
              StyleUtils.font_cera_regular,

              {
                marginLeft: 24,
                marginTop: 16,
                marginBottom: 8,
              },
            ]}
          >
            Nível das aulas
          </Text>
          <Text
            style={[
              styles.white_text_regular_16,

              {
                marginLeft: 24,
                marginBottom: 40,
              },
            ]}
          >
            {tutor.tutorInfo &&
              tutor.tutorInfo.subjectLevels &&
              tutor.tutorInfo.subjectLevels
                .map((level) => level.name)
                .join(", ")}
          </Text>
          <TouchableOpacity
            style={[
              { marginLeft: 24, marginBottom: 40, marginRight: 24 },
              StyleUtils.border_color_txt_white,
              StyleUtils.shape_card_default,
            ]}
            onPress={() => {
              analytics.sendTutorProfileSendMessage();
              if (props.userID) {
                onSendMessage();
              } else {
                setTimeout(() => {
                  setIsSendMessageClicked(true);
                }, 300);
              }
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 14 },
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
              ]}
            >
              Enviar uma mensagem
            </Text>
          </TouchableOpacity>
        </View>
        <Text
          style={[
            {
              textAlign: "left",
              marginTop: 56,
              marginLeft: 24,
              marginBottom: 24,
            },
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_22,
          ]}
        >
          Sobre
        </Text>
        <Text
          style={[
            StyleUtils.font_size_16,
            StyleUtils.color_txt_dark,
            StyleUtils.font_cera_regular,

            {
              marginLeft: 24,
              marginRight: 24,
              marginBottom: 56,
            },
          ]}
        >
          {tutor.tutorInfo && tutor.tutorInfo.longDescription}
        </Text>
        {numComments === 0 && (
          <>
            <Text
              style={[
                { textAlign: "left", marginLeft: 24 },
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_22,
              ]}
            >
              Comentários (0)
            </Text>
            <View
              style={{
                flexDirection: "row",
                marginLeft: 24,
                marginTop: 32,
              }}
            >
              <SVGImage
                source={<CommentIcon />}
                style={{
                  padding: 1,
                }}
              />
              <Text
                style={[
                  {
                    marginLeft: 6,
                    marginRight: 58,
                    lineHeight: 22,
                    marginBottom: 52,
                  },
                  StyleUtils.color_txt_grey_light,
                  StyleUtils.font_size_16,
                  StyleUtils.font_cera_regular,
                ]}
              >
                Esse professor ainda não recebeu nenhum comentário.
              </Text>
            </View>
          </>
        )}
        {numComments > 0 && (
          <>
            <TouchableOpacity
              onPress={() => {
                analytics.sendTutorProfileViewComments();
                props.history.push("/TutorCommentsList");
              }}
            >
              <Text
                style={[
                  {
                    textAlign: "left",
                    marginLeft: 24,
                    marginBottom: 30,
                  },
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_dark,
                  StyleUtils.font_size_22,
                ]}
              >
                Comentários ({numComments})
              </Text>
            </TouchableOpacity>
            {numComments !== 0 &&
              ratings.slice(0, 2).map((rating) => {
                return <CommentCard key={rating.id} rating={rating} />;
              })}
            {numComments > 2 && (
              <>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      props.history.push("/TutorCommentsList");
                    }}
                  >
                    <Text
                      style={[
                        StyleUtils.font_size_18,
                        StyleUtils.color_txt_dark,
                        StyleUtils.font_cera_regular,

                        {
                          marginHorizontal: 24,
                          marginRight: 24,
                        },
                      ]}
                    >
                      Ver todos os {numComments} comentários
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      props.history.push("/TutorCommentsList");
                    }}
                  >
                    <SVGImage
                      source={<ForwardArrowNormal />}
                      style={{
                        marginRight: 24,
                      }}
                    />
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    borderBottomColor: "#E1E8F3",
                    borderBottomWidth: 1,
                    width: 327,
                    marginLeft: 24,
                    marginBottom: 32,
                    marginTop: 28,
                  }}
                />
              </>
            )}
          </>
        )}
      </ScrollView>
      {parentsOnboarding ? (
        <View
          style={{
            backgroundColor: "#363A4B",
            height: 96,
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              style={[
                {
                  marginRight: 16,
                  marginVertical: 24,
                  width: 161,
                  borderWidth: 1,
                  borderColor: COLOR_WHITE,
                  borderRadius: 6,
                  marginLeft: 24,
                },
              ]}
              onPress={() => {
                if (talkNowParents) {
                  analytics.sendScheduleSessionParents();
                  props.history.push({
                    pathname: "/booking",
                    state: {
                      parentsOnboarding: true,
                      source: "parents_onboarding",
                    },
                  });
                } else {
                  analytics.sendWaitingContact(props.userID);
                  setContactPopup(true);
                }
              }}
            >
              <Text
                style={[
                  {
                    textAlign: "center",
                    paddingVertical: Platform.OS == "ios" ? 14 : 12,
                  },
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_16,
                  StyleUtils.color_txt_white,
                ]}
              >
                {talkNowParents ? "Agendar Sessão" : "Aguardar Contato"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                {
                  marginRight: 24,
                  marginVertical: 24,
                  width: 161,
                  borderRadius: 6,
                },
                StyleUtils.color_bg_success,
              ]}
              onPress={() => {
                if (talkNowParents) {
                  analytics.sendTalkNowParents();
                  props.history.push("/quickclass/lobby");
                } else {
                  analytics.sendScheduleSessionParents();
                  props.history.push({
                    pathname: "/booking",
                    state: {
                      parentsOnboarding: true,
                      source: "parents_onboarding",
                    },
                  });
                }
              }}
            >
              <Text
                style={[
                  {
                    textAlign: "center",
                    paddingVertical: Platform.OS == "ios" ? 14 : 12,
                  },
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_16,
                  StyleUtils.color_txt_white,
                ]}
              >
                {talkNowParents ? "Conversar Agora" : "Agendar Sessão"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <View
          style={{
            backgroundColor: "#363A4B",
            height: 96,
            flexDirection: "row",
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                marginTop: hasRatings ? 29 : 38,
              }}
            >
              {price > 0 && priceShowABTest === 0 && !pricePackageShow && (
                <>
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_bold,
                      StyleUtils.color_txt_white,
                      {
                        marginLeft: 29,
                      },
                    ]}
                  >
                    {tutor.tutorInfo &&
                      formatCurrency(tutor.tutorInfo.currencyCode, price, 2)}
                  </Text>
                  <Text style={[styles.white_text_regular_16]}>/aula</Text>
                </>
              )}
              {price <= 0 && priceShowABTest === 0 && !pricePackageShow && (
                <Text
                  style={[
                    StyleUtils.font_size_16,
                    StyleUtils.font_cera_bold,
                    StyleUtils.color_txt_white,
                    {
                      marginLeft: 29,
                    },
                  ]}
                >
                  Aula grátis
                </Text>
              )}
              {priceShowABTest === 1 && !pricePackageShow && (
                <DiscountBadge style={[{ marginLeft: 29 }]} discount={50} />
              )}
              {priceShowABTest === 2 && !pricePackageShow && (
                <Text
                  style={[styles.white_text_regular_16, { marginLeft: 29 }]}
                >
                  Avaliação
                </Text>
              )}
              {priceShowABTest === 3 && !pricePackageShow && (
                <Text
                  style={[styles.white_text_regular_16, { marginLeft: 29 }]}
                >
                  {creditPrice}
                  {creditPrice === 1 ? " crédito/aula" : " créditos/aula"}
                </Text>
              )}
              {pricePackageShow && (
                <View>
                  <Text
                    style={[
                      StyleUtils.font_size_16,
                      StyleUtils.font_cera_regular,
                      StyleUtils.color_txt_white,
                      {
                        marginLeft: 29,
                        marginTop: -9,
                      },
                    ]}
                  >
                    <B>
                      {tutor.tutorInfo &&
                        formatCurrency(tutor.tutorInfo.currencyCode, price, 0)}
                    </B>
                    /aula
                  </Text>
                  <Text
                    style={[
                      StyleUtils.font_size_14,
                      StyleUtils.font_cera_medium,
                      StyleUtils.color_txt_light,
                      {
                        marginLeft: 29,
                      },
                    ]}
                  >
                    (Pacote de 4 aulas)
                  </Text>
                </View>
              )}
            </View>
            {hasRatings && (
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 29,
                  marginTop: 6,
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  {[...Array(5)].map((v, index) => {
                    return (
                      <SVGImage
                        key={index}
                        source={
                          ((tutor.rating && tutor.rating.average.toFixed(1)) ||
                            0) >=
                          index + 0.5 ? (
                            <StarYellow />
                          ) : (
                            <StarMuted />
                          )
                        }
                      />
                    );
                  })}
                </View>
                <Text
                  style={[
                    styles.white_text_regular_14,
                    {
                      paddingLeft: 8,
                      lineHeight: 16,
                    },
                  ]}
                >
                  {tutor.rating && tutor.rating.average.toFixed(1)}
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              style={[
                {
                  marginRight: 16,
                  marginVertical: 24,
                  width: 185,
                },
                StyleUtils.shape_card_default,
                scheduleButton
                  ? StyleUtils.color_bg_success
                  : StyleUtils.color_bg_white,
              ]}
              onPress={() => {
                analytics.sendTutorProfileScheduleClass();
                if (props.userID) {
                  onSelectTutor();
                } else {
                  setTimeout(() => {
                    setIsShowTimesClicked(true);
                  }, 300);
                }
              }}
            >
              <Text
                style={[
                  {
                    textAlign: "center",
                    paddingVertical: Platform.OS == "ios" ? 14 : 12,
                  },
                  StyleUtils.font_cera_bold,
                  StyleUtils.font_size_16,
                  scheduleButton
                    ? StyleUtils.color_txt_white
                    : StyleUtils.color_txt_dark,
                ]}
              >
                {scheduleButton ? "Agendar aula" : "Horários Disponíveis"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}

      <SignUpPopUp
        show={isSendMessageClicked || isShowTimesClicked || isFavoriteCliked}
        onLoginSuccess={onLoginSuccessPopUp}
        onSignUpSuccess={onSignUpSuccessPopUp}
        isLogin={false}
        close={closePopUp}
        history={props.history}
      />
      <ParentsTalkNowPopup
        show={showParentsPopup}
        close={() => setShowParentsPopup(false)}
        buttonText={"Entendi"}
        headerText={"Você pode agendar um horário ou conversar agora mesmo!"}
        subHeaderText={
          "Se preferir aguardar, nosso orientador entrará em contato em breve por Whatsapp."
        }
      />
      <ParentsTalkNowPopup
        show={contactPopup}
        close={() => props.history.push("/")}
        buttonText={"Ok!"}
        headerText={
          "Nosso orientador entrará em contato em breve via Whatsapp."
        }
        subHeaderText={
          "Enviaremos uma mensagem no seu Whatsapp assim que possível"
        }
      />
      <ParentsTalkNowPopup
        show={showNoContactPopup}
        close={() => setShowNoContactPopup(false)}
        buttonText={"Entendi"}
        headerText={"Infelizmente não temos ninguém disponível no momento"}
        subHeaderText={
          "Entraremos em contato assim que alguém estiver disponível"
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  white_text_regular_14: {
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
  white_text_regular_16: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorProfile);
