import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import Quickclass from "../../assets/quickclass.svg";
import BackArrow from "../../assets/backbutton.svg";
import { analytics } from "../Analytics";

type Props = {
  history;
};

const QuickClassStart = (props: Props) => {
  return (
    <View style={[StyleUtils.screen_default, { justifyContent: "center" }]}>
      <TouchableOpacity
        style={[
          {
            alignSelf: "flex-start",
            width: "15%",
            aspectRatio: 1,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "3%",
            left: "3%",
          },
        ]}
        onPress={() => {
          analytics.sendQuickClassBack();
          props.history.goBack();
        }}
      >
        <SVGImage source={<BackArrow />} />
      </TouchableOpacity>
      <View style={{ maxWidth: 375, width: "100%", alignSelf: "center" }}>
        <View
          style={{
            alignSelf: "center",
            justifyContent: "center",
            marginBottom: 70,
          }}
        >
          <SVGImage source={<Quickclass />} />
        </View>

        <View>
          <Text
            style={[
              {
                textAlign: "center",
                marginLeft: "5%",
                marginRight: "5%",
              },
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_22,
              StyleUtils.font_cera_bold,
            ]}
          >
            Você ganhou 5 minutos grátis com nosso orientador!
          </Text>

          <Text
            style={[
              { textAlign: "center", margin: 30, marginTop: 16 },
              StyleUtils.color_txt_light,
              StyleUtils.font_size_16,
              StyleUtils.font_cera_regular,
            ]}
          >
            Fale agora com nosso orientador de estudos para conhecer mais sobre
            nosso aplicativo e comece a aprender já.
          </Text>
        </View>

        <View>
          <TouchableOpacity
            style={[
              { margin: 16 },
              StyleUtils.color_bg_primary,
              StyleUtils.shape_btn_small,
            ]}
            onPress={() => {
              props.history.push("/quickclass/lobby");
            }}
          >
            <Text
              style={[
                { textAlign: "center", margin: 16 },
                StyleUtils.font_btn_bold,
                StyleUtils.color_txt_white,
                StyleUtils.font_size_16,
              ]}
            >
              Entrar na Aula
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default QuickClassStart;
