import { EventEmitter } from "events";
import { useEffect } from "react";
import { ClassEvent } from "../Models/ClassEvent";

const EXIT_TIMER = 120;
const TEST_CLASS_TIME = 0;

interface ClassroomTimerEvents {
  timeUpdate: (remainingSeconds: number) => void;
  timeEnding: () => void;
  classEnded: () => void;
}

class ClassroomTimer {
  classEvent: ClassEvent | null;
  startTime: number;
  endTime: number;
  intervalHandle?: any;
  countDown: boolean;
  eventEmitter: EventEmitter;
  ended?: boolean;
  endTimeGracePeriod: number;

  constructor() {
    this.classEvent = null;
    this.startTime = 0;
    this.endTime = 0;
    this.eventEmitter = new EventEmitter();
    this.countDown = false;
    this.endTimeGracePeriod = 0;
  }

  start(classEvent: ClassEvent) {
    this.classEvent = classEvent;
    this.startTime = new Date(classEvent.startDate).getTime();
    this.endTime = new Date(classEvent.endDate).getTime();
    this.intervalHandle = setInterval(this.timerFunction, 1000);
    this.countDown = false;
    this.ended = false;
    this.endTimeGracePeriod = new Date(this.endTime + 10 * 60 * 1000).getTime();

    if (TEST_CLASS_TIME > 0) {
      this.startTime = new Date().getTime();
      const end = new Date(this.startTime);
      end.setSeconds(end.getSeconds() + TEST_CLASS_TIME);
      this.endTime = end.getTime();
    }
  }

  end() {
    this.classEvent = null;
    clearInterval(this.intervalHandle);
    this.intervalHandle = undefined;
  }

  getRemainingSeconds(): number | undefined {
    if (!this.classEvent) {
      return undefined;
    }
    const now = new Date().getTime();
    let diff;
    if (now < this.startTime) {
      diff = this.endTime - this.startTime;
    } else if (now >= this.endTime) {
      diff = 0;
    } else {
      diff = this.endTime - now;
    }
    return Math.floor(diff / 1000);
  }

  on<U extends keyof ClassroomTimerEvents>(
    eventName: U,
    listener: ClassroomTimerEvents[U]
  ): Unsubscriber {
    this.eventEmitter.on(eventName, listener);
    return new Unsubscriber(this.eventEmitter, eventName, listener);
  }
  removeListener<U extends keyof ClassroomTimerEvents>(
    eventName: U,
    listener: ClassroomTimerEvents[U]
  ) {
    this.eventEmitter.removeListener(eventName, listener);
  }

  private timerFunction = () => {
    const remainingSeconds = this.getRemainingSeconds();
    if (remainingSeconds === undefined) {
      return;
    }
    this.eventEmitter.emit("timeUpdate", remainingSeconds);

    if (
      !this.countDown &&
      remainingSeconds > 0 &&
      remainingSeconds < this.countdownThreshold()
    ) {
      this.countDown = true;
      this.eventEmitter.emit("timeEnding");
    }

    if (new Date().getTime() >= this.endTimeGracePeriod && !this.ended) {
      this.ended = true;
      this.eventEmitter.emit("classEnded");
    }
  };

  countdownThreshold = () => {
    if (this.endTime - this.startTime < 10 * 60 * 1000) return 60;
    return 300;
  };

  useExitTimer(enabled?: boolean, onTimer?: () => void) {
    useEffect(() => {
      if (!enabled) {
        return;
      }
      const handle = setTimeout(() => {
        onTimer && onTimer();
      }, EXIT_TIMER * 1000);
      return () => clearTimeout(handle);
    }, [enabled]);
  }
}

class Unsubscriber {
  _context: EventEmitter;
  eventName: string;
  listener: any;
  constructor(context: EventEmitter, eventName: string, listener: any) {
    this._context = context;
    this.eventName = eventName;
    this.listener = listener;
  }
  unsubscribe() {
    this._context.removeListener(this.eventName, this.listener);
  }
}

export const classroomTimer = new ClassroomTimer();
