import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import Exercise from "../../assets/exercise.svg";
import SubjectTest from "../Models/SubjectTest";

type Props = {
  mobile?: boolean;
  subjectTest: SubjectTest;
  isTutor?: boolean;
  onPress?: () => void;
};

const SubjectTestCard = (props: Props) => {
  return (
    <SubjectTestContainer onPress={props.onPress}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.color_txt_normal,
              StyleUtils.font_size_14,
              { marginBottom: 3 },
            ]}
          >
            {props.isTutor
              ? props.subjectTest.tutee && props.subjectTest.tutee.name
              : "FUVEST"}
          </Text>
          <View
            style={{
              flexDirection: "row",
              minHeight: 20,
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.color_txt_dark,
                StyleUtils.font_size_16,
              ]}
            >
              {props.subjectTest.subject.name}
            </Text>
          </View>
        </View>

        {props.subjectTest.score && (
          <View
            style={{
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={[
                StyleUtils.font_btn_regular,
                StyleUtils.color_txt_normal,
                StyleUtils.font_size_14,
                { alignSelf: "flex-end" },
              ]}
            >
              Nota
            </Text>
            <View
              style={[
                StyleUtils.shape_btn_default,
                StyleUtils.color_bg_success,
                {
                  width: 42,
                  marginTop: 2,
                },
              ]}
            >
              <Text
                style={[
                  StyleUtils.font_cera_bold,
                  StyleUtils.color_txt_white,
                  {
                    alignSelf: "center",
                    top: 1,
                  },
                ]}
              >
                {props.subjectTest.score}
              </Text>
            </View>
          </View>
        )}
      </View>
    </SubjectTestContainer>
  );
};

const SubjectTestContainer = (props: {
  onPress?: () => void;
  children?: any;
}) => {
  return (
    <TouchableOpacity
      style={[
        StyleUtils.color_bg_white,
        {
          flexDirection: "column",
          marginHorizontal: 24,
          borderColor: COLOR_MUTED,
          borderBottomWidth: 1,
        },
      ]}
      onPress={props.onPress}
    >
      <View
        style={[
          {
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
          },
        ]}
      >
        <View style={{ alignSelf: "center" }}>
          <View
            style={[
              StyleUtils.color_bg_light,
              { height: 40, width: 40, borderRadius: 6 },
            ]}
          >
            <SVGImage source={<Exercise />} />
          </View>
        </View>
        <View
          style={[
            {
              flex: 1,
              padding: 23,
            },
          ]}
        >
          {props.children}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default SubjectTestCard;
