import React, { Component } from "react";
import {
  Platform,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import StyleUtils, { COLOR_MUTED } from "../Utils/StyleUtils";
import GoogleSigninButton from "../Google/GoogleSigninButton";
import { SignUpButtonStyle } from "./SignUpButtonStyle";
import GoogleIcon from "../../assets/iconGoogle.svg";
import FacebookIcon from "../../assets/iconFacebook.svg";
import AppleIcon from "../../assets/iconApple.svg";
import AnimatedPopUp from "./AnimatedPopUp";
import { connect } from "react-redux";
import { analytics } from "../Analytics";

import AppleSignInButton from "../Apple/AppleSignInButton";
import appleAuth from "../Apple/AppleAuthWrapper";

type Props = {
  show;
  close;
  isLogin;
  history;
  selectedSubject;
  selectedLevel;
  onLoginSuccess;
  onSignUpSuccess;
  isExerciseOnboarding?;
};

type State = {
  isLogin;
  hasFailureLogin;
  hasFailureSignUp;
  popUpOpened;
};

function mapStateToProps(state) {
  return {
    selectedSubject: state.searchFilterRed.subject,
    selectedLevel: state.searchFilterRed.level,
  };
}

class SignUpPopUp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: this.props.isLogin,
      hasFailureLogin: false,
      hasFailureSignUp: false,
      popUpOpened: false,
    };
  }

  componentDidUpdate() {
    if (this.props.show && !this.state.popUpOpened) {
      analytics.sendAuthPopupOpened();
      this.setState({ popUpOpened: true });
    } else if (this.state.popUpOpened && !this.props.show) {
      analytics.sendAuthPopupClosed();
      this.setState({ popUpOpened: false });
    }
  }

  hasPassedThroughExerciseOnboarding = () => {
    if (this.props.isExerciseOnboarding) {
      return true;
    } else return false;
  };

  onChangeToSignUp = () => {
    if (this.state.isLogin) {
      this.setState({ isLogin: false });
    } else {
      this.setState({ isLogin: true });
    }
  };

  render() {
    return (
      <AnimatedPopUp show={this.props.show} close={this.props.close}>
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_20,
            StyleUtils.color_txt_dark,
          ]}
        >
          Faça seu login
        </Text>

        {this.state.hasFailureLogin && this.state.isLogin ? (
          <Text style={styles.errorMessage}>
            Não existe nenhuma conta tutore {"\n"} com esse email de acesso.
          </Text>
        ) : null}

        {this.state.hasFailureSignUp && !this.state.isLogin ? (
          <Text style={styles.errorMessage}>
            Você já tem uma conta tutore {"\n"} com esse email de acesso.
          </Text>
        ) : null}

        <View style={{ marginTop: 32 }}></View>

        <GoogleSigninButton
          isLogin={this.state.isLogin}
          onSuccess={this.props.close}
          onLoginSuccess={this.props.onLoginSuccess}
          onSignUpSuccess={this.props.onSignUpSuccess}
          onSignUpFailure={() => {
            this.setState({ hasFailureSignUp: true });
          }}
          onLoginFailure={() => {
            this.setState({ hasFailureLogin: true });
          }}
          history={this.props.history}
          isExerciseOnboarding={this.hasPassedThroughExerciseOnboarding()}
        >
          <SignUpButtonStyle text={"Entrar com Google"} icon={<GoogleIcon />} />
        </GoogleSigninButton>

        {/*<SignUpButtonStyle*/}
        {/*  text={*/}
        {/*    this.state.isLogin*/}
        {/*      ? "Entrar com Facebook"*/}
        {/*      : "Continuar com Facebook"*/}
        {/*  }*/}
        {/*  icon={<FacebookIcon />}*/}
        {/*/>*/}

        {appleAuth.isSupported && (
          <AppleSignInButton
            isLogin={this.state.isLogin}
            onLoginSuccess={this.props.onLoginSuccess}
            onSignUpSuccess={this.props.onSignUpSuccess}
            onSignUpFailure={() => {
              this.setState({ hasFailureSignUp: true });
            }}
            onLoginFailure={() => {
              this.setState({ hasFailureLogin: true });
            }}
            history={this.props.history}
          >
            <SignUpButtonStyle
              text={"Iniciar sessão com a Apple"}
              icon={<AppleIcon />}
            />
          </AppleSignInButton>
        )}
      </AnimatedPopUp>
    );
  }
}

const styles = StyleSheet.create({
  lineMuted: {
    flex: 1,
    borderTopWidth: 0,
    borderWidth: 1,
    borderColor: COLOR_MUTED,
    height: 0,
    margin: 12,
  },

  errorMessage: {
    margin: 32,
    marginTop: 24,
    marginBottom: 0,
    textAlign: "center",
    ...StyleSheet.flatten([
      StyleUtils.color_txt_danger,
      StyleUtils.font_cera_regular,
      StyleUtils.font_size_16,
    ]),
  },
});

export default connect(mapStateToProps, null)(SignUpPopUp);
