import React from "react";
import { Component } from "react";
import { Image, TouchableOpacity, ActivityIndicator } from "react-native";
import Lightbox from "react-image-lightbox";
import StyleUtils from "../Utils/StyleUtils";

type Props = {
  activeProps;
  style;
  source;
};

type State = {
  isLoading;
  isOpen;
};

//TODO: Deal with image not found errors
export default class CustomLightBox extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoading: true,
    };
  }

  render() {
    return (
      <>
        <TouchableOpacity
          style={[StyleUtils.shape_btn_default, { marginTop: 8 }]}
          onPress={() => {
            this.setState({ isOpen: true });
          }}
        >
          {this.state.isLoading && <ActivityIndicator />}
          <Image
            style={{ height: 100, resizeMode: "cover" }}
            source={{ uri: this.props.source }}
            onLoadEnd={() => {
              this.setState({ isLoading: false });
            }}
          />
        </TouchableOpacity>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.props.source}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </>
    );
  }
}
