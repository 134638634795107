import React, { useEffect } from "react";
import {
  BackHandler,
  Linking,
  Platform,
  SafeAreaView,
  View,
  NativeModules,
} from "react-native";
import { connect } from "react-redux";
import {
  loginSuccessful,
  logout,
  selectTutor,
  setMobileLayout,
  setSignUpUserInfo,
  setFromDynamicLink,
} from "./Actions";
import AssignHomework from "./AssignHomework/AssignHomework";
import Booking from "./Booking/Booking";
import BookingFinished from "./Booking/BookingFinished";
import CanvasTest from "./Canvas/CanvasTest";
import CanvasWebView from "./Canvas/CanvasWebView";
import Classroom from "./Classroom/Classroom";
import ClassroomEnd from "./Classroom/ClassroomEnd";
import ClassroomEndTutor from "./Classroom/ClassroomEndTutor";
import ClassroomEndSchedule from "./Classroom/ClassroomEndSchedule";
import ClassroomRating from "./Classroom/ClassroomRating";
import ClassroomEndReferral from "./Classroom/ClassroomEndReferral";
import RatingFlow from "./Classroom/AirbnbRatingFlow/RatingFlow";
import EventInfo from "./EventInfo/EventInfo";
import Events from "./Events/Events";
import Exercises from "./Exercises/Exercises";
import ExercisesList from "./Exercises/ExercisesList";
import ExercisesListArchived from "./Exercises/ExercisesListArchived";
import ExercisesReviewMode from "./Exercises/ExercisesReviewMode";
import Footer from "./Footer/Footer";
import { GoogleSignin } from "./Google/GoogleSignin";
import Header from "./Header/Header";
import Home from "./Home/Home";
import Landing from "./Landing/Landing";
import Login from "./Login/Login";
import Messages from "./Messenger/Messenger";
import TuteeInfo from "./Models/TuteeInfo";
import TutorInfo from "./Models/TutorInfo";
import User from "./Models/User";
import NotificationList from "./Notifications/NotificationList";
import { socketNotifications } from "./Notifications/Notifications";
import { setup as setupNotificationsManager } from "./Notifications/NotificationsManager";
import ChooseTutor from "./Onboarding/ChooseTutor";
import SpcOnboarding from "./Onboarding/Student Profile Creation/SpcOnboarding";
import ConfirmationPage from "./Payment/ConfirmationPage";
import PurchaseManager from "./Payment/PurchaseManager";
import { Redirect, Route, Switch, withRouter } from "./Router";
import Schedule from "./Schedule/Schedule";
import ScheduleDayPicker from "./Schedule/ScheduleDayPicker";
import ScheduleHour from "./Schedule/ScheduleHour";
import SignUpHolder from "./SignUp/SignUpHolder";
import PostExercise from "./Temp/PostExercise";
import SuperTutorCard from "./TutorCards/SuperTutorCard";
import TutorCard from "./TutorCards/TutorCard";
import TutorMatchList from "./TutorList/TutorMatchList";
import TutorCommentsList from "./TutorProfile/TutorCommentsList";
import TutorProfile from "./TutorProfile/TutorProfile";
import webLayout from "./Utils/PlatformUtils";
import { SCREEN_SIZE_900 } from "./Utils/SizeUtils";
import appleAuth from "./Apple/AppleAuthWrapper";
import { getTutor, getUserFromId, serverLogin } from "./Server";
import DeepLinkTutorProfile from "./TutorProfile/DeepLinkTutorProfile";
import QuickClassLobby from "./QuickClass/QuickClassLobby";
import QuickClassEnd from "./QuickClass/QuickClassEnd";
import { getMainSubjectLevels, getMainSubjects } from "./Server";
import { defaultSubjectLevels, defaultSubjects } from "./Models/Subjects";
import { setSubjectLevels, setSubjects } from "./Redux/InfoReducer";
import QuickClassStart from "./QuickClass/QuickClassStart";
import Settings from "./Home/Settings";
import SubjectTestList from "./SubjectTests/SubjectTestList";
import SubjectTestStart from "./SubjectTests/SubjectTestStart";
import SubjectTestResult from "./SubjectTests/SubjectTestResult";
import ExerciseDirectoriesPage from "./Exercises/ExerciseDirectories/ExerciseDirectoriesPage";
import TutoreFuturePaywall from "./Home/TutoreFuturePaywall";
import SubjectTestExercises from "./SubjectTests/SubjectTestExercises";
import SubjectTestReviewMode from "./SubjectTests/SubjectTestReviewMode";
import FreeSessionConfirmationPage from "./Payment/FreeSessionConfirmationPage";
import VocationalTest from "./VocationalTest/VocationalTest";
import FirstClassFreeConfirmationPage from "./Payment/FirstClassFreeConfirmationPage";
import { analytics } from "./Analytics";
import OrderPayment from "./Payment/OrderPayment";
import CreditCardAdd from "./Payment/CreditCardAdd";
import CreditCardSettings from "./Payment/CreditCardSettings";
import TutoreFutureOnboarding from "./Onboarding/TutoreFutureOnboarding/TFOnboarding";
import TFConfirmationPage from "./Onboarding/TutoreFutureOnboarding/TFConfirmationPage";
import SubscriptionConfirmationPage from "./Payment/SubscriptionConfirmationPage";
import EndForm from "./Forms/EndForm";
import Forms from "./Forms/Forms";
import StudyPlan from "./StudyPlan/StudyPlan";

function dispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

type Props = {
  user?: User;
  userWithoutLogin;
  tuteeInfo?: TuteeInfo;
  tutorInfo?: TutorInfo;
  bookedEvent?;
  history;
  location;
  mobile?: boolean;
  setMobileLayout: (mobile: boolean) => void;
  logout;
  userID;
  selectTutor;
  loginSuccessful: (
    user: User,
    tuteeInfo?: TuteeInfo,
    tutorInfo?: TutorInfo
  ) => void;
  setFromDynamicLink;
};

function mapStateToProps(state) {
  return {
    user: state.userRed.user,
    userWithoutLogin: state.userRed.userWithoutLogin,
    tuteeInfo: state.userRed.tuteeInfo,
    tutorInfo: state.userRed.tutorInfo,
    bookedEvent: state.selRed.bookedEvent,
    mobile: state.layoutRed.mobile,
    userID: state.userRed.user && state.userRed.user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMobileLayout: (mobile: boolean) => dispatch(setMobileLayout(mobile)),
    selectTutor: (selectedTutor) => dispatch(selectTutor(selectedTutor)),
    setSubjects: (subjects) => dispatch(setSubjects(subjects)),
    logout: () => dispatch(logout()),
    loginSuccessful: (
      user: User,
      tuteeInfo?: TuteeInfo,
      tutorInfo?: TutorInfo
    ) => dispatch(loginSuccessful(user, tuteeInfo, tutorInfo)),
    setSignUpUserInfo: (user: User) => dispatch(setSignUpUserInfo(user)),
    setFromDynamicLink: (fromDynamicLink: boolean) =>
      dispatch(setFromDynamicLink(fromDynamicLink)),
    dispatch: dispatch,
  };
}

const headerBlacklist: string[] = [
  "/quickclass/lobby",
  "/quickclass/start",
  "/classroom",
  "/canvastest",
  "/landing",
  "/landing/subjects",
  "/SignUp",
  "/plano-de-estudo",
];
const footerBlacklist: string[] = [
  "/quickclass/lobby",
  "/quickclass/start",
  "/classroom",
  "/canvastest",
  "/landing",
  "/landing/subjects",
  "/SignUp",
  "/plano-de-estudo",
];
const headerBlacklistApp: string[] = [
  "/quickclass/lobby",
  "/quickclass/start",
  "/classroom",
  "/landing",
  "/landing/subjects",
  "/SignUp",
];
const footerShowListApp: string[] = [
  "/tutormatchlist",
  "/events",
  "/exercises",
  "/homeworkassignment",
  "/home",
  "/",
];

const PrivateRoute = ({ auth, requiredConditions = true, ...rest }) => {
  if (!auth) {
    return <Redirect to={{ pathname: "/landing" }} />;
  } else if (!requiredConditions) {
    return <Redirect to={{ pathname: "/" }} />;
  } else {
    return <Route {...rest} />;
  }
};

function useGoogleSignin() {
  useEffect(() => {
    GoogleSignin.configure({
      webClientId:
        "750744001530-056u6mojmlovv4m9hgedh0medljnqaa0.apps.googleusercontent.com",
    });
  }, []);
}

function useBackHandler(history) {
  const backPress = () => {
    if (history.index > 0) {
      history.goBack();
      return true;
    }
    return false;
  };
  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backPress
    );
    return () => backHandler.remove();
  }, []);
}

function setupNotifications(history = "/") {
  setupNotificationsManager(history);
}

function startSocket(user?: User) {
  const socketNotificationsInstance = socketNotifications;
  useEffect(() => {
    if (!user) {
      return;
    }
    socketNotificationsInstance.open(user);
    return () => socketNotificationsInstance.close();
  }, [socketNotificationsInstance, user]);
}

function useAppleSignin(logout) {
  useEffect(() => {
    return appleAuth.onCredentialRevoked(async () => {
      logout();
    });
  }, []);
}

function goToTutorProfile(props, tutorID) {
  getTutor(props.userID, tutorID, { include: ["rating", "favorite"] })
    .then((t) => props.selectTutor(t))
    .then(() => {
      props.history.push({
        pathname: `/tutorlink/${tutorID}`,
      });
    })
    .catch(console.log);
}

function goToVocationalTest(props) {
  props.history.push({
    pathname: "/vocationaltest",
  });
}

function getTutorIdFromUrl(url, tutee = false) {
  let deepLink = "";
  if (tutee) {
    deepLink = "/from_web/";
  } else {
    deepLink = "/tutorlink/";
  }

  if (url && url.includes(deepLink)) {
    let newUrl = url.split(deepLink)[1];
    return newUrl.substring(0, 36);
  } else {
    return undefined;
  }
}

async function getInitUrl(props) {
  await Linking.getInitialURL().then((url) => {
    if (url) analytics.sendDynamicLinkInit(url);
    if (Platform.OS === "android") {
      NativeModules.AnalyticsManager.getDynamicLink().then((url) => {
        if (url.includes("from_web")) {
          login(props, url);
        }
      });
    } else if (Platform.OS === "ios") {
      if (url && url.includes("from_web")) {
        login(props, url);
      }
    }

    const tutorID = getTutorIdFromUrl(url);
    if (tutorID) {
      goToTutorProfile(props, tutorID);
    } else if (url && url.includes("/vocationaltest")) {
      goToVocationalTest(props);
    }
  });
}

function fetchInitialInfo() {
  getMainSubjects(
    (subjects) => {
      setSubjects(subjects);
    },
    (error) => {
      setSubjects(defaultSubjects);
    }
  );

  getMainSubjectLevels(
    (levels) => {
      setSubjectLevels(levels);
    },
    (error) => {
      setSubjectLevels(defaultSubjectLevels);
    }
  );
}

function login(props, url) {
  const userId = getTutorIdFromUrl(url, true);
  getUserFromId(userId).then((result) => {
    serverLogin(
      result.user.name,
      result.user.email,
      result.user.imageURL ? result.user.imageURL : ""
    ).then((loginResponse) => {
      props.setFromDynamicLink(true);
      props.loginSuccessful(
        loginResponse.user,
        loginResponse.tuteeInfo,
        loginResponse.tutorInfo
      );
      props.history.push({
        pathname: "/home",
      });
    });
  });
}

function Routes(props: Props) {
  analytics.sendScreenView(props.history.location.pathname.substring(1));

  useEffect(() => {
    setupNotifications(props.history);

    getInitUrl(props);
    const handleOpenUrl = async (event) => {
      analytics.sendDynamicLinkOpenUrl(event.url);
      if (event.url.includes("from_web")) {
        login(props, event.url);
      }
      if (event.url.includes("tutorlink")) {
        const tutorID = getTutorIdFromUrl(event.url);
        goToTutorProfile(props, tutorID);
      } else if (event.url.includes("vocationaltest")) {
        goToVocationalTest(props);
      }
    };

    if (Platform.OS !== "android") {
      Linking.addEventListener("url", handleOpenUrl);
    }
    return () => Linking.removeEventListener("url", handleOpenUrl);
  }, []);

  useGoogleSignin();
  useAppleSignin(props.logout);
  useBackHandler(props.history);
  startSocket(props.user);

  const onLayout = (event) => {
    const { layout } = event.nativeEvent;
    let mobile = !webLayout || layout.width < SCREEN_SIZE_900;
    if (mobile != props.mobile) {
      props.setMobileLayout(mobile);
    }
  };

  return (
    <View style={{ flex: 1 }} onLayout={onLayout}>
      {((Platform.OS === "web" &&
        !headerBlacklist.includes(props.location.pathname)) ||
        (Platform.OS !== "web" &&
          !headerBlacklistApp.includes(props.location.pathname))) && (
        <SafeAreaView style={{ zIndex: 1 }}>
          <Header />
        </SafeAreaView>
      )}
      <Switch>
        <Route
          exact
          path="/landing"
          component={Platform.OS == "web" ? Landing : SpcOnboarding}
        />
        <Route exact path="/landing/subjects" component={SpcOnboarding} />
        <Route exact path="/landing/tutors" component={TutorMatchList} />
        <Route exact path="/SignUp" component={SignUpHolder} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/canvastest" component={CanvasTest} />
        <Route exact path="/quickclass/start" component={QuickClassStart} />
        <Route exact path="/quickclass/lobby" component={QuickClassLobby} />
        <Route exact path="/quickclass/end" component={QuickClassEnd} />
        <PrivateRoute exact path="/" auth={props.user} component={Home} />
        <Route exact path="/home" component={Home} />
        <PrivateRoute
          exact
          path="/chooseTutor"
          auth={props.user}
          component={ChooseTutor}
        />
        <PrivateRoute
          exact
          path="/supertutor"
          auth={props.user}
          component={SuperTutorCard}
        />
        <PrivateRoute
          exact
          path="/tutormatchlist"
          auth={props.user}
          component={TutorMatchList}
        />
        <PrivateRoute
          exact
          path="/tutorcard"
          auth={props.user}
          component={TutorCard}
        />
        <Route exact path="/events" component={Events} />
        <PrivateRoute
          exact
          path="/eventinfo"
          auth={props.user}
          component={EventInfo}
        />
        <PrivateRoute
          exact
          path="/booking"
          auth={props.user}
          component={Booking}
        />
        <PrivateRoute
          exact
          path="/booking/end"
          auth={props.user}
          requiredConditions={!!props.bookedEvent}
          component={BookingFinished}
        />
        <PrivateRoute
          exact
          path="/classroom"
          auth={props.user || props.userWithoutLogin}
          component={Classroom}
        />
        <PrivateRoute
          exact
          path="/classroom/end"
          auth={props.user}
          component={ClassroomEnd}
        />
        <PrivateRoute
          exact
          path="/classroom/endtutor"
          auth={props.user}
          component={ClassroomEndTutor}
        />
        <PrivateRoute
          exact
          path="/classroom/endschedule"
          auth={props.user}
          component={ClassroomEndSchedule}
        />
        <PrivateRoute
          exact
          path="/classroom/rating"
          auth={props.user}
          component={ClassroomRating}
        />
        <PrivateRoute
          exact
          path="/classroom/airbnbrating"
          auth={props.user}
          component={RatingFlow}
        />
        <PrivateRoute
          exact
          path="/canvas/webview"
          auth={props.user}
          component={CanvasWebView}
        />
        <PrivateRoute
          exact
          path="/schedule"
          auth={props.user}
          component={Schedule}
        />
        <PrivateRoute
          exact
          path="/scheduleday"
          auth={props.user}
          component={ScheduleDayPicker}
        />
        <PrivateRoute
          exact
          path="/schedulehour"
          auth={props.user}
          component={ScheduleHour}
        />
        <Route exact path="/exercises" component={ExercisesList} />
        <PrivateRoute
          exact
          path="/exercises/archived"
          auth={props.user}
          component={ExercisesListArchived}
        />
        <PrivateRoute
          path="/exercises/exercise"
          auth={props.user}
          component={Exercises}
        />
        <PrivateRoute
          exact
          path="/exercises/review"
          auth={props.user}
          component={ExercisesReviewMode}
        />
        <Route exact path="/messages" component={Messages} />
        <PrivateRoute
          exact
          path="/messages/:userId"
          auth={props.user}
          component={Messages}
        />
        <PrivateRoute
          exact
          path="/homeworkassignment"
          auth={props.user}
          component={AssignHomework}
        />
        <PrivateRoute
          exact
          path="/addExercise"
          auth={props.user}
          component={PostExercise}
        />
        <PrivateRoute
          exact
          path="/exerciseDirectories"
          auth={props.user}
          component={() =>
            ExerciseDirectoriesPage({
              user: props.user,
              history: props.history,
            })
          }
        />
        <Route
          exact
          path="/TutorProfile"
          auth={props.user}
          component={TutorProfile}
        />
        <Route
          exact
          path="/tutorlink/:tutorID"
          component={DeepLinkTutorProfile}
        />
        <Route
          exact
          path="/TutorCommentsList"
          auth={props.user}
          component={TutorCommentsList}
        />
        <PrivateRoute
          exact
          path="/ConfirmationPage"
          auth={props.user}
          component={ConfirmationPage}
        />
        <PrivateRoute
          exact
          path="/FreeSessionConfirmationPage"
          auth={props.user}
          component={FreeSessionConfirmationPage}
        />
        <PrivateRoute
          exact
          path="/FirstClassFreeConfirmationPage"
          auth={props.user}
          component={FirstClassFreeConfirmationPage}
        />
        <PrivateRoute
          exact
          path="/PurchaseManager"
          auth={props.user}
          component={PurchaseManager}
        />
        <Route exact path="/notifications" component={NotificationList} />
        <Route exact path="/settings" component={Settings} />
        <PrivateRoute
          exact
          path="/classroom/endreferral"
          auth={props.user}
          component={ClassroomEndReferral}
        />
        <PrivateRoute
          exact
          path="/subjecttests"
          auth={props.user}
          component={SubjectTestList}
        />
        <PrivateRoute
          exact
          path="/subjecttests/start"
          auth={props.user}
          component={SubjectTestStart}
        />
        <PrivateRoute
          exact
          path="/subjecttests/result"
          auth={props.user}
          component={SubjectTestResult}
        />
        <PrivateRoute
          path="/subjecttests/exercise"
          auth={props.user}
          component={SubjectTestExercises}
        />
        <PrivateRoute
          exact
          path="/subjecttests/review"
          auth={props.user}
          component={SubjectTestReviewMode}
        />
        <Route
          exact
          path="/tutorefuturepaywall"
          component={TutoreFuturePaywall}
        />
        <Route exact path="/vocationaltest" component={VocationalTest} />
        <PrivateRoute
          exact
          path="/payment"
          auth={props.user}
          component={OrderPayment}
        />
        <PrivateRoute
          exact
          path="/payment/card/add"
          auth={props.user}
          component={CreditCardAdd}
        />
        <PrivateRoute
          exact
          path="/user/cards/settings"
          auth={props.user}
          component={CreditCardSettings}
        />
        <PrivateRoute
          exact
          path="/payment"
          auth={props.user}
          component={OrderPayment}
        />
        <PrivateRoute
          exact
          path="/payment/card/add"
          auth={props.user}
          component={CreditCardAdd}
        />
        <PrivateRoute
          exact
          path="/user/cards/settings"
          auth={props.user}
          component={CreditCardSettings}
        />
        <PrivateRoute
          exact
          path="/tutorefutureonboarding"
          auth={props.user}
          component={TutoreFutureOnboarding}
        />
        <PrivateRoute
          exact
          path="/tutorefuture/confirmationpage"
          auth={props.user}
          component={TFConfirmationPage}
        />
        <PrivateRoute
          exact
          path="/SubscriptionConfirmationPage"
          auth={props.user}
          component={SubscriptionConfirmationPage}
        />
        <Route exact path="/plano-de-estudo" component={StudyPlan} />
        <Route exact path="/endform" auth={props.user} component={EndForm} />
        <Route exact path="/forms" auth={props.user} component={Forms} />
      </Switch>
      {((Platform.OS === "web" &&
        !footerBlacklist.includes(props.location.pathname)) ||
        (Platform.OS !== "web" &&
          footerShowListApp.includes(props.location.pathname))) && (
        <SafeAreaView>
          <Footer />
        </SafeAreaView>
      )}
    </View>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
