import React from "react";
import { View, Text } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { ViewStyle } from "react-web-gifted-chat";

type Props = {
  style?: ViewStyle;
  mobile?: boolean;
  title?: string;
};

const Title = (props: Props) => {
  const title =
    !props.mobile && props.title
      ? props.title.replace(/(?:\r\n|\r|\n)/g, " ")
      : props.title;
  return (
    <View
      style={[
        StyleUtils.color_bg_white,
        { justifyContent: "center", alignSelf: "stretch" },
        !props.mobile && {
          height: 84,
          marginLeft: "10%",
          width: "20%",
        },
      ]}
    >
      <View style={!props.mobile && StyleUtils.desktop_content_width}>
        <Text
          style={[
            StyleUtils.color_txt_dark,
            StyleUtils.font_size_22,
            StyleUtils.font_cera_bold,
            { textAlign: "left" },
            props.mobile && {
              margin: 24,
              textAlignVertical: "bottom",
              lineHeight: 22,
            },
          ]}
        >
          {title}
        </Text>
      </View>
    </View>
  );
};

export default Title;
