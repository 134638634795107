import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  Platform,
} from "react-native";
import StyleUtils, {
  COLOR_WHITE,
  COLOR_MUTED,
  COLOR_PRIMARY,
} from "../../Utils/StyleUtils";
import SVGImage from "../../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import Grafismo from "../../../assets/grafismoTutoreFuture.svg";
import TutoreFutureCheck from "../../../assets/tutoreFutureCheck.svg";
import Clock from "../../../assets/clockBlack.svg";
import TutoreFutureGraph from "../../../assets/tutoreFutureGraph.svg";
import * as Utils from "../../Utils/Utils";

type Props = {
  onContinue;
  headerText;
  investment;
  graphText;
  time;
};

export default function TFPlan(props: Props) {
  const [dates, setDates] = useState<string[]>([]);

  const formatDate = (date) => {
    let temp = date.split(" ");
    return temp[0] + " " + temp[2];
  };

  const formatLongDate = (date) => {
    let temp = date.split(" ");
    return temp[0] + " " + temp[2] + " " + temp[4];
  };

  useEffect(() => {
    const arr = [] as any;
    let date = "";
    if (props.time.id === 0) {
      date = Utils.dateFormatDayMonthYear(new Date());
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 7));
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 14));
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 21));
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 28));
      arr.push(formatLongDate(date));
    }
    //date is entre 1 e 3 meses
    else if (props.time.id === 1) {
      date = Utils.dateFormatDayMonthYear(new Date());
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 15));
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 30));
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 45));
      arr.push(formatLongDate(date));
      date = Utils.dateFormatDayMonthYear(Utils.addDays(new Date(), 60));
      arr.push(formatLongDate(date));
    }
    // date is entre 3 e 6 meses
    else if (props.time.id === 2) {
      date = Utils.dateFormatShortMonthYear(new Date());
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 1));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 2));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 3));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 4));
      arr.push(formatDate(date));
    }
    //date is entre 6 e 12 meses
    else if (props.time.id === 3) {
      date = Utils.dateFormatShortMonthYear(new Date());
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 2));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 4));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 6));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 9));
      arr.push(formatDate(date));
    }
    //date is mais de 1 ano
    else if (props.time.id === 4) {
      date = Utils.dateFormatShortMonthYear(new Date());
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 4));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 8));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 12));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 14));
      arr.push(formatDate(date));
    }
    // date is ainda não sei
    else if (props.time.id === 5) {
      date = Utils.dateFormatShortMonthYear(new Date());
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 1));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 2));
      arr.push(formatDate(date));
      date = Utils.dateFormatShortMonthYear(Utils.addMonths(new Date(), 3));
      arr.push(formatDate(date));
    }
    setDates(arr);
  }, []);

  return (
    <LinearGradient
      colors={["#272B3E", "#005EC8"]}
      style={[
        StyleUtils.screen_default,
        {
          paddingVertical: 40,
        },
      ]}
    >
      <View
        style={{
          flexDirection: "row",
          top: 10,
          position: "absolute",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <SVGImage source={<Grafismo />} />
      </View>
      <View
        style={{
          borderRadius: 310,
          backgroundColor: COLOR_WHITE,
          left: -124,
          width: 244 + Dimensions.get("window").width,
          height: 750,
          zIndex: 0,
          position: "absolute",
          top: 150,
        }}
      />
      <SVGImage
        source={<TutoreFutureCheck />}
        style={{
          justifyContent: "center",
          alignSelf: "center",
          top: 110,
          position: "absolute",
          zIndex: 0,
        }}
      />
      <View
        style={{
          flex: 0.7,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          top: "30%",
          marginHorizontal: 24,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_22,
            StyleUtils.color_txt_dark,
            { textAlign: "center", paddingHorizontal: 24 },
          ]}
        >
          {props.headerText}
        </Text>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            StyleUtils.color_txt_light,
            { textAlign: "center" },
          ]}
        >
          Com as suas informações, esse é o período{"\n"}estimado para alcançar
          seu objetivo
        </Text>
        <View style={{ flexDirection: "row" }}>
          {dates.map((element, index) => {
            return (
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_14,
                  StyleUtils.color_txt_primary,
                  {
                    marginTop: 10,
                    width: 51,
                    textAlign: "center",
                    marginHorizontal: 7,
                    left: props.time.id === 5 ? -3 : 30,
                  },
                ]}
              >
                {element}
              </Text>
            );
          })}
          <Text
            style={[
              StyleUtils.font_cera_regular,
              StyleUtils.font_size_14,
              StyleUtils.color_txt_normal,
              {
                position: "absolute",
                top: 95,
                left: props.time.id === 5 ? -50 : -15,
                width: 90,
              },
            ]}
          >
            {props.graphText}
          </Text>
        </View>

        <SVGImage
          source={<TutoreFutureGraph />}
          style={{
            justifyContent: "center",
            alignSelf: "center",
            marginTop: -50,
          }}
        />
        <View style={{ flexDirection: "row" }}>
          {props.investment !== "" && (
            <>
              <SVGImage
                source={<Clock />}
                style={{ top: Platform.OS === "ios" ? -8 : -4 }}
              />
              <Text
                style={[
                  StyleUtils.font_cera_regular,
                  StyleUtils.font_size_16,
                  StyleUtils.color_txt_dark,
                  { textAlign: "center" },
                ]}
              >
                {props.investment}
              </Text>
            </>
          )}
        </View>
        <TouchableOpacity
          style={{
            borderRadius: 6,
            backgroundColor: COLOR_PRIMARY,
            width: "95%",
            paddingVertical: 12,
          }}
          onPress={() => props.onContinue()}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            Criar meu plano
          </Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  lineMuted: {
    flex: 1,
    borderTopWidth: 0,
    borderWidth: 1,
    borderColor: COLOR_MUTED,
    height: 0,
    marginVertical: 12,
    marginHorizontal: 32,
  },
});
