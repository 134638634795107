import React from "react";
import { Component } from "react";
import { Picker, Text, TouchableOpacity, View } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { scheduleDraftHoursChosen } from "../Actions";
import { connect } from "react-redux";

type Props = {
  history;
  scheduleDraftHoursChosen;
  scheduleDraftHours;
};

type State = {
  selectedStart;
  selectedEnd;
};

function dispatchToProps(dispatch) {
  return {
    scheduleDraftHoursChosen: (startHour, endHour) =>
      dispatch(scheduleDraftHoursChosen(startHour, endHour)),
  };
}

function stateToProps(state) {
  return {
    scheduleDraftHours: state.scheduleDraftHours,
  };
}

const hours: string[] = [];

class ScheduleHour extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedStart: this.props.scheduleDraftHours.start,
      selectedEnd: this.props.scheduleDraftHours.end,
    };
    for (let i = 1; i <= 24; i++) {
      hours.push(String(i).padStart(2, "0") + ":00");
    }
  }

  render() {
    return (
      <View
        style={{
          flexGrow: 1,
          justifyContent: "center",
          alignSelf: "center",
          maxWidth: 375,
          margin: 20,
        }}
      >
        <Text
          style={[
            StyleUtils.font_size_20,
            StyleUtils.font_btn_bold,
            { textAlign: "center" },
          ]}
        >
          Qual seu período disponível?
        </Text>
        <Text
          style={[
            StyleUtils.color_txt_light,
            { textAlign: "center", marginTop: 10 },
          ]}
        >
          Informe a janela de horários que você pode lecionar aulas para os
          alunos.
        </Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Text
            style={[
              { margin: 8 },
              StyleUtils.color_txt_normal,
              StyleUtils.font_btn_bold,
            ]}
          >
            Das
          </Text>
          <Picker
            selectedValue={this.state.selectedStart}
            style={[
              { height: 50, padding: 10 },
              StyleUtils.color_txt_normal,
              StyleUtils.font_size_16,
              StyleUtils.color_bg_white,
              StyleUtils.border_color_btn_muted,
              StyleUtils.shape_btn_default,
            ]}
            onValueChange={(value) => {
              this.setState({
                selectedStart: value,
              });
            }}
          >
            {hours.map((hourString) => {
              return <Picker.Item label={hourString} value={hourString} />;
            })}
          </Picker>
          <Text
            style={[
              { margin: 8 },
              StyleUtils.color_txt_normal,
              StyleUtils.font_btn_bold,
            ]}
          >
            às
          </Text>
          <Picker
            selectedValue={this.state.selectedEnd}
            style={[
              { height: 50, padding: 10 },
              StyleUtils.color_txt_normal,
              StyleUtils.font_size_16,
              StyleUtils.color_bg_white,
              StyleUtils.border_color_btn_muted,
              StyleUtils.shape_btn_default,
            ]}
            onValueChange={(value) => {
              this.setState({
                selectedEnd: value,
              });
            }}
          >
            {hours.map((hourString) => {
              return <Picker.Item label={hourString} value={hourString} />;
            })}
          </Picker>
        </View>
        <TouchableOpacity
          onPress={() => {
            this.props.scheduleDraftHoursChosen(
              this.state.selectedStart,
              this.state.selectedEnd
            );
            this.props.history.push("/schedule");
          }}
          style={[
            StyleUtils.color_btn_primary,
            StyleUtils.shape_btn_default_rounded,
            { marginTop: "20%" },
          ]}
        >
          <Text
            style={[
              { margin: 20, textAlign: "center" },
              StyleUtils.color_txt_white,
              StyleUtils.font_size_16,
              StyleUtils.font_btn_bold,
            ]}
          >
            Continuar
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}

export default connect(stateToProps, dispatchToProps)(ScheduleHour);
