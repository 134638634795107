import React from "react";
import Card from "./Card";
import ExerciseCatalogBadge from "../../../assets/exerciseCatalogBadge.svg";

type Props = {
  mobile?: boolean;
  cardStyle: "regular" | "success" | "primary";
  size?: "large" | "regular" | "small";
  onPress?: () => void;
};

const ExercisesCatologCard = (props: Props) => {
  return (
    <Card
      {...props}
      badge={<ExerciseCatalogBadge />}
      text="Catálogo de
      Exercícios"
      buttonText="Submeter"
    ></Card>
  );
};

export default ExercisesCatologCard;
