import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  FlatList,
} from "react-native";
import { connect } from "react-redux";
import StyleUtils, {
  COLOR_TRUE_WHITE,
  COLOR_MUTED,
  COLOR_DANGER,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import VocationalTestBackground from "../../assets/vocationalTestBackground.svg";
import IconSchool from "../../assets/iconSchoolWhite.svg";
import ForwardArrow from "../../assets/backbuttonWhite.svg";
import ShareIcon from "../../assets/shareIcon.svg";
import ButtonElement from "../Elements/ButtonElement";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  history;
  headerText;
  subHeaderText;
  buttonText;
  underlineText;
  mainText?;
  onContinueDetailedResult;
  onBack?;
  onRetake?;
  results?;
};

function VTResults(props: Props) {
  const [showPopup, setShowPopup] = useState(false);

  const renderNativeItem = (item) => {
    return (
      <>
        <TouchableOpacity
          style={styles.cell}
          onPress={() => {
            props.onContinueDetailedResult(item);
          }}
        >
          <SVGImage source={<IconSchool />} style={{ paddingRight: 10 }} />
          <Text style={[styles.cellText]}>{item.name}</Text>
          <SVGImage
            style={{
              marginRight: 12,
              transform: [
                {
                  rotate: "-180deg",
                },
              ],
            }}
            source={<ForwardArrow />}
          />
        </TouchableOpacity>
        <View
          style={
            item !== props.results[props.results.length - 1]
              ? styles.cellSeparator
              : {}
          }
        />
      </>
    );
  };

  const onShare = async () => {
    analytics.sendShareVT();
  };

  return (
    <>
      <LinearGradient
        colors={["#272B3E", "#005EC8"]}
        style={[
          StyleUtils.color_bg_light,
          StyleUtils.shape_btn_default,
          {
            flex: 1,
            marginTop: Platform.OS === "ios" ? -50 : -10,
            marginBottom: -10,
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: Platform.OS === "ios" ? 50 : 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!!props.onBack && (
            <TouchableOpacity
              onPress={() => {
                props.onBack();
              }}
              style={{
                position: "absolute",
                left: 20,
                top: Platform.OS === "ios" ? 10 : 25,
                zIndex: 1,
              }}
            >
              <SVGImage source={<ForwardArrow />} />
            </TouchableOpacity>
          )}

          <SVGImage source={<VocationalTestBackground />} />
          {!props.mainText && (
            <TouchableOpacity
              onPress={() => onShare()}
              style={{
                position: "absolute",
                right: 10,
                top: Platform.OS === "ios" ? 0 : 25,
              }}
            >
              <SVGImage source={<ShareIcon />} />
            </TouchableOpacity>
          )}
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginHorizontal: 24,
            marginTop: -60,
          }}
        >
          <Text
            style={[
              StyleUtils.color_txt_white,
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_28,
              {
                textAlign: "center",
              },
            ]}
          >
            {props.headerText}
          </Text>
          <Text style={styles.textWhite}>{props.subHeaderText}</Text>
          {!props.mainText ? (
            <View style={{ marginTop: -20 }}>
              <FlatList
                data={props.results}
                renderItem={({ item }) => renderNativeItem(item)}
                keyExtractor={(index) => index.toString()}
              />
            </View>
          ) : (
            <Text style={styles.textWhite}>{props.mainText}</Text>
          )}
          <TouchableOpacity
            onPress={() => props.onContinue()}
            style={{
              backgroundColor: COLOR_TRUE_WHITE,
              borderRadius: 6,
              marginTop: props.mainText ? 100 : 0,
            }}
          >
            <Text
              style={[
                {
                  textAlign: "center",
                  paddingVertical: 14,
                },
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_16,
                StyleUtils.color_txt_dark,
              ]}
            >
              {props.buttonText}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (props.mainText) {
                analytics.sendFinalizeVT();
                props.history.push("/home");
              } else {
                setShowPopup(true);
              }
            }}
          >
            <Text style={[styles.underlineText]}>{props.underlineText}</Text>
          </TouchableOpacity>
        </View>
      </LinearGradient>
      <CloseClassPopup
        show={showPopup}
        close={() => {
          setShowPopup(false);
        }}
        onCancel={() => {
          setShowPopup(false);
        }}
        onRetake={() => {
          props.onRetake();
        }}
      />
    </>
  );
}

export default connect(null, null)(VTResults);

const styles = StyleSheet.create({
  textWhite: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    textAlign: "center",
    paddingHorizontal: 10,
    marginTop: -10,
  },
  textPrice: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    marginTop: 32,
    textAlign: "center",
    marginBottom: 16,
  },
  underlineText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
    marginBottom: 30,
    textAlign: "center",
    textDecorationLine: "underline",
    color: COLOR_TRUE_WHITE,
  },
  cell: {
    height: 70,
    alignItems: "center",
    flexDirection: "row",
  },
  cellSeparator: {
    position: "absolute",
    bottom: 0,
    height: 1,
    width: "100%",
    alignSelf: "center",
    backgroundColor: COLOR_MUTED,
    opacity: 0.2,
  },
  cellText: {
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_18),
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
    marginTop: Platform.OS === "ios" ? 10 : 0,
    flex: 1,
    paddingRight: 10,
  },
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});

const CloseClassPopup = ({ show, close, onCancel, onRetake }) => {
  return (
    <AnimatedPopUp show={show} close={close} bgColor={COLOR_DANGER}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_white,
        ]}
      >
        {"Tem certeza que você\ndeseja refazer o teste?"}
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_white,
        ]}
      >
        {"Ao refazer o teste, o resultado\natual será substituído pelo novo."}
      </Text>
      <ButtonElement
        colorScheme="danger_white"
        text="Cancelar"
        onPress={onCancel}
        textStyle={styles.popupButtonText}
        styte={[styles.popupButton, { marginTop: 32 }]}
      />
      <TouchableOpacity
        style={[{ width: 252, marginTop: 24 }]}
        onPress={onRetake}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            { textAlign: "center", textDecorationLine: "underline" },
          ]}
        >
          Refazer o teste
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};
