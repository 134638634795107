import React, { useEffect, useState } from "react";
import { View, Text, Dimensions, StyleSheet, Image } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import LinearGradient from "react-native-linear-gradient";

type Props = {
  onContinue;
  headerText;
};

export default function StudyPlanProgress(props: Props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    Dimensions.addEventListener("change", _handleAppStateChange);
    setTimeout(() => {
      props.onContinue();
    }, 3000);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  return (
    <View style={[StyleUtils.screen_default]}>
      <LinearGradient
        colors={["#011F42", "#016EE8"]}
        style={[
          {
            paddingVertical: 100,
          },
        ]}
      />
      <View
        style={{
          width: windowWidth,
          height: windowWidth,
          borderRadius: windowWidth / 2,
          backgroundColor: COLOR_TRUE_WHITE,
          transform: [{ scaleX: 2 }],
          marginTop: 80,
          position: "absolute",
        }}
      />
      <View
        style={{
          alignItems: "center",
        }}
      >
        <Image
          source={require("../../assets/loadingDots.gif")}
          style={{
            width: 250,
            height: 250,
            marginTop: -100,
          }}
        />
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            {
              textAlign: "center",
              fontSize: windowWidth < 500 ? 20 : 28,
              marginHorizontal: 20,
            },
          ]}
        >
          {props.headerText}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    width: "80%",
    backgroundColor: "#ecf0f1",
    borderRadius: 50,
    margin: 24,
  },
  progressBar: {
    flexDirection: "row",
    height: 20,
    width: "100%",
    borderRadius: 50,
  },
});
