import React, { Component } from "react";
import { Text, TouchableOpacity, View, StyleSheet } from "react-native";
import StyleUtils from "../Utils/StyleUtils";
import { getScheduleHours } from "../Server";

type Props = {
  selectedDate;
  selectedTime?;
  style;
  selectedTutor;
  selectTime?;
  selectedUser;
};

type State = {
  times: string[];
  schedule;
  userID;
  hours;
};

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

class AvailableHours extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      times: [],
      schedule: props.selectedTutor.schedule,
      userID: props.selectedUser,
      hours: [],
    };
  }

  componentDidMount() {
    getScheduleHours(this.state.userID)
      .then((res) => {
        this.setState({
          hours: res.weekWorkHours,
        });
        this.changedDate();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.props.selectedDate !== prevProps.selectedDate) this.changedDate();
  }

  changedDate = () => {
    const day = this.props.selectedDate.getDay();

    let times: string[] = [];
    if (this.state.hours[day].length) {
      this.state.hours[day].forEach((hour) => times.push(hour));
    }

    this.setState({
      times: times,
    });
    // const startHours = this.state.schedule[
    //   2 * this.props.selectedDate.getDay()
    // ];
    // const endHours = this.state.schedule[
    //   2 * this.props.selectedDate.getDay() + 1
    // ];
    // const startDate =
    //   Date.parse("1970-01-01T" + hourString(startHours) + "Z") / 1000;
    // const endDate =
    //   Date.parse("1970-01-01T" + hourString(endHours) + "Z") / 1000;
    // let times: number[] = [];
    // for (let i = startDate; i < endDate; i += 60 * 60) times.push(i);
    // this.setState({
    //   times: times,
    // });
  };

  render() {
    return (
      <View style={[...this.props.style]}>
        <Text
          style={[
            StyleUtils.font_btn_bold,
            StyleUtils.font_size_14,
            StyleUtils.color_txt_dark,
          ]}
        >
          {this.props.selectedDate.toLocaleDateString("pt-br", options)}
        </Text>
        <View style={styles.timeContainer}>
          {this.state.times.map((timeDate) => {
            return (
              <TimeOption
                key={timeDate}
                timeDate={timeDate}
                selected={this.props.selectedTime === timeDate}
                selectTime={this.props.selectTime}
              />
            );
          })}
        </View>
      </View>
    );
  }
}

function TimeOption(props: {
  timeDate: string;
  selected: boolean;
  selectTime: Function;
}) {
  const timeText: string = hourString(props.timeDate);
  // console.log(props.timeDate)
  return (
    <TouchableOpacity
      style={styles.timeTouchable}
      onPress={() => {
        if (props.selectTime) {
          props.selectTime(props.timeDate);
        }
      }}
    >
      <View
        style={[styles.timeOption, props.selected && styles.timeOptionSelected]}
      >
        <Text
          style={[styles.timeText, props.selected && styles.timeTextSelected]}
        >
          {timeText}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

function hourString(timeDate: string): string {
  // const hours = Math.floor(timeDate / (60 * 60));
  // const minutes = (timeDate % (60 * 60)) / 60;
  return (
    // String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0")
    `${timeDate[0]} - ${timeDate[1]}`
  );
}

const styles = StyleSheet.create({
  timeContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
  },
  timeTouchable: {
    width: "50%",
  },
  timeOption: {
    ...StyleSheet.flatten(StyleUtils.color_bg_primarylight),
    ...StyleSheet.flatten(StyleUtils.shape_btn_default_rounded),
    paddingTop: 6,
    paddingBottom: 6,
    margin: 2,
  },
  timeOptionSelected: {
    ...StyleSheet.flatten(StyleUtils.color_bg_primary),
  },
  timeText: {
    ...StyleSheet.flatten(StyleUtils.color_txt_primary),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    textAlign: "center",
    padding: 8,
  },
  timeTextSelected: {
    ...StyleSheet.flatten(StyleUtils.color_txt_white),
  },
});

export default AvailableHours;
