import React from "react";
import { View, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import Dropzone from "react-dropzone";
import DragDropStyle from "./DragDropStyle";

import SVGImage from "../Utils/SVGImage";
import ImageIcon from "../../assets/imageIcon.svg";
import StyleUtils from "../Utils/StyleUtils";

import { standardizeFile, UploadStatusType } from "../Utils/FileUtils";

type Props = {
  descriptionText;
  style;
  isMobile;
  uploadFile;
  cancelUpload;
  uploadStatus;
  updateUploadStatus;
};

type State = {
  isDragging;
  files;
};

export default class ImageUploaderUI extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
      files: [],
    };
  }

  onDragEnter(event) {
    this.setState({ isDragging: true });
  }

  onDragLeave(event) {
    this.setState({ isDragging: false });
  }

  onDrop(acceptedFiles, rejectedFiles, event) {
    this.setState({ isDragging: false });
    if (rejectedFiles.length > 0) {
      this.props.updateUploadStatus(UploadStatusType.fileTypeError);
    } else {
      try {
        const file = acceptedFiles[0];
        if (file.size > 4000000) {
          this.props.updateUploadStatus(UploadStatusType.fileSizeError);
        } else {
          this.submitFile(file);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  submitFile(file) {
    const newFile = standardizeFile(file);
    this.props.updateUploadStatus(UploadStatusType.uploading);
    this.props.uploadFile(newFile);
  }

  render() {
    return (
      <View style={[...this.props.style, DragDropStyle.uploadMainBox]}>
        {!this.props.isMobile && this.renderDropZone()}
        {this.renderErrorBox()}
        {this.props.isMobile && this.renderDropZone()}
      </View>
    );
  }

  renderDropZone(): React.ReactNode {
    return (
      <View style={DragDropStyle.uploadBoxWhiteBoard}>
        <View
          style={[
            DragDropStyle.dropBoxWhiteBoard,
            this.state.isDragging && { backgroundColor: "#E6F3FB" },
          ]}
        >
          <Dropzone
            disabled={this.props.uploadStatus == UploadStatusType.uploading}
            onDrop={this.onDrop.bind(this)}
            onDragEnter={this.onDragEnter.bind(this)}
            onDragLeave={this.onDragLeave.bind(this)}
            noClick={true}
            accept={"image/jpeg, image/png"}
          >
            {({ getRootProps, getInputProps, open, acceptedFiles }) => (
              <section className="container" style={{ flex: 1 }}>
                {/*
                        // @ts-ignore*/}
                <div
                  {...getRootProps()}
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    minHeight: 150,
                    height: "100%",
                  }}
                >
                  {/*
                          // @ts-ignore*/}
                  <input {...getInputProps()} />
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      height: "100%",
                      minHeight: 150,
                    }}
                  >
                    {this.renderReadyFragment(open)}
                    {this.renderLoadingFragment()}
                  </View>
                </div>
              </section>
            )}
          </Dropzone>
        </View>
      </View>
    );
  }

  renderReadyFragment(open): React.ReactNode {
    if (this.props.uploadStatus == UploadStatusType.uploading) {
      return null;
    }
    return (
      <>
        <SVGImage
          source={<ImageIcon />}
          style={{ width: 40, alignSelf: "center" }}
        />
        {!this.props.isMobile && (
          <>
            <Text style={[DragDropStyle.txtNormal]}>
              {this.props.descriptionText}
            </Text>
            <Text style={[DragDropStyle.txtBold]}>OU</Text>
          </>
        )}
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_default,
            DragDropStyle.primaryButton,
            { alignSelf: "center", height: 20, width: "80%" },
          ]}
          onPress={open}
        >
          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            Escolha um arquivo
          </Text>
        </TouchableOpacity>
      </>
    );
  }

  renderLoadingFragment(): React.ReactNode {
    if (this.props.uploadStatus != UploadStatusType.uploading) {
      return null;
    }
    return (
      <>
        <ActivityIndicator />
        <Text style={[DragDropStyle.txtNormal]}>Carregando arquivo...</Text>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_default,
            DragDropStyle.primaryButton,
            { alignSelf: "center", height: 20, width: "80%" },
          ]}
          onPress={this.props.cancelUpload}
        >
          <Text
            style={[
              StyleUtils.font_size_14,
              StyleUtils.color_txt_white,
              { textAlign: "center" },
            ]}
          >
            Cancelar
          </Text>
        </TouchableOpacity>
      </>
    );
  }

  renderErrorBox(): React.ReactNode {
    if (
      this.props.uploadStatus != UploadStatusType.fileSizeError &&
      this.props.uploadStatus != UploadStatusType.fileTypeError &&
      this.props.uploadStatus != UploadStatusType.networkError
    ) {
      return null;
    }

    return (
      <View
        style={[
          DragDropStyle.uploadError,
          {
            position: "absolute",
            width: "100%",
            height: "30%",
            justifyContent: "center",
          },
          this.props.isMobile && { bottom: "100%" },
          !this.props.isMobile && { top: "100%" },
        ]}
      >
        {this.renderErrorBoxText()}
      </View>
    );
  }

  renderErrorBoxText(): React.ReactNode {
    return (
      <Text style={DragDropStyle.uploadErrorText}>
        {this.props.uploadStatus == UploadStatusType.fileSizeError && (
          <>
            <Text>O tamanho máximo de um arquivo para upload deve ser de </Text>
            <Text style={StyleUtils.font_btn_bold}>2MB.</Text>
          </>
        )}
        {this.props.uploadStatus == UploadStatusType.fileTypeError && (
          <>
            <Text>O arquivo deve ser do tipo </Text>
            <Text style={StyleUtils.font_btn_bold}>.jpg</Text>
            <Text>, </Text>
            <Text style={StyleUtils.font_btn_bold}>.jpeg</Text>
            <Text> ou </Text>
            <Text style={StyleUtils.font_btn_bold}>.png</Text>
            <Text> .</Text>
          </>
        )}
        {this.props.uploadStatus == UploadStatusType.networkError && (
          <>
            <Text>
              Não foi possível carregar a imagem, tente novamente mais tarde.
            </Text>
          </>
        )}
      </Text>
    );
  }
}
