import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  ScrollView,
  SafeAreaView,
} from "react-native";
import BackArrow from "../../assets/backbutton.svg";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_MUTED,
  COLOR_TRUE_WHITE,
  COLOR_WHITE,
  COLOR_SUCCESS,
  COLOR_PRIMARY_LIGHT,
} from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  areas;
  onContinue;
  onBack;
  headerText;
  percentage;
};

type State = {
  selectedZero: string | null;
  selectedOne: string | null;
  selectedTwo: string | null;
  selectedThree: string | null;
  selectedFour: string | null;
  selectedFive: string | null;
};

export default class BasicQuestions extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedZero: null,
      selectedOne: null,
      selectedTwo: null,
      selectedThree: null,
      selectedFour: null,
      selectedFive: null,
    };
  }

  toggleSelectedLevel(item) {
    let id = parseInt(item.id);
    if (item.id >= 22) id -= 22;
    else if (item.id >= 18) id -= 18;
    else if (item.id >= 12) id -= 12;
    else if (item.id >= 6) id -= 6;
    switch (id) {
      case 0:
        if (this.state.selectedZero === item) {
          this.setState({ selectedZero: null });
        } else {
          this.setState({ selectedZero: item });
        }
        break;
      case 1:
        if (this.state.selectedOne === item) {
          this.setState({ selectedOne: null });
        } else {
          this.setState({ selectedOne: item });
        }
        break;
      case 2:
        if (this.state.selectedTwo === item) {
          this.setState({ selectedTwo: null });
        } else {
          this.setState({ selectedTwo: item });
        }
        break;
      case 3:
        if (this.state.selectedThree === item) {
          this.setState({ selectedThree: null });
        } else {
          this.setState({ selectedThree: item });
        }
        break;
      case 4:
        if (this.state.selectedFour === item) {
          this.setState({ selectedFour: null });
        } else {
          this.setState({ selectedFour: item });
        }
        break;
      case 5:
        if (this.state.selectedFive === item) {
          this.setState({ selectedFive: null });
        } else {
          this.setState({ selectedFive: item });
        }
        break;
    }
  }

  renderLevelCell = (item) => {
    let isSelectedArea;
    let id = parseInt(item.id);
    if (item.id >= 22) id -= 22;
    else if (item.id >= 18) id -= 18;
    else if (item.id >= 12) id -= 12;
    else if (item.id >= 6) id -= 6;
    switch (id) {
      case 0:
        isSelectedArea = this.state.selectedZero === item;
        break;
      case 1:
        isSelectedArea = this.state.selectedOne === item;
        break;
      case 2:
        isSelectedArea = this.state.selectedTwo === item;
        break;
      case 3:
        isSelectedArea = this.state.selectedThree === item;
        break;
      case 4:
        isSelectedArea = this.state.selectedFour === item;
        break;
      case 5:
        isSelectedArea = this.state.selectedFive === item;
        break;
    }

    return (
      <TouchableOpacity
        key={item.id}
        style={[
          styles.cellBase,
          isSelectedArea ? styles.cellSelected : styles.cellUnselected,
          {
            marginRight: item.id % 2 ? 0 : 4,
            marginLeft: item.id % 2 ? 4 : 0,
          },
        ]}
        onPress={(e) => this.toggleSelectedLevel(item)}
      >
        <Text
          style={[styles.cellText, isSelectedArea && { color: COLOR_WHITE }]}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          style={{
            flex: 1,
            flexDirection: "column",
            backgroundColor: COLOR_TRUE_WHITE,
          }}
        >
          <View
            style={{ flexDirection: "row", marginTop: 16, marginBottom: 32 }}
          >
            <TouchableOpacity
              style={[
                {
                  alignSelf: "flex-start",
                  alignItems: "center",
                  justifyContent: "center",
                  marginHorizontal: 24,
                },
              ]}
              onPress={() => {
                this.props.onBack();
                this.setState({
                  selectedZero: null,
                  selectedOne: null,
                  selectedTwo: null,
                  selectedThree: null,
                  selectedFour: null,
                  selectedFive: null,
                });
              }}
            >
              <SVGImage source={<BackArrow />} />
            </TouchableOpacity>
            <View style={styles.container}>
              <View style={styles.progressBar}>
                <View
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: COLOR_SUCCESS,
                    width: this.props.percentage,
                    borderRadius: 50,
                  }}
                />
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              paddingHorizontal: 32,
              minHeight: 407,
              overflow: "hidden",
            }}
          >
            <Text
              style={[
                StyleUtils.font_cera_bold,
                StyleUtils.font_size_22,
                {
                  textAlign: "left",
                  width: "100%",
                  marginBottom: 20,
                },
              ]}
            >
              {this.props.headerText}
            </Text>
            <View style={styles.containerCenter}>
              {this.props.areas
                .slice(0, 2)
                .map((area) => this.renderLevelCell(area))}
            </View>
            <View style={styles.containerCenter}>
              {this.props.areas
                .slice(2, 4)
                .map((area) => this.renderLevelCell(area))}
            </View>
            <View style={styles.containerCenter}>
              {this.props.areas.length !== 7
                ? this.props.areas
                    .slice(4)
                    .map((area) => this.renderLevelCell(area))
                : this.props.areas
                    .slice(4, 6)
                    .map((area) => this.renderLevelCell(area))}
            </View>
            {this.props.areas.length === 7 &&
              this.props.areas
                .slice(6)
                .map((area) => this.renderLevelCell(area))}
          </View>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <ButtonElement
            text="Avançar"
            colorScheme="primary"
            styte={{
              borderRadius: 6,
              marginHorizontal: 24,
              marginVertical: 24,
            }}
            textStyle={StyleUtils.font_size_16}
            onPress={() => {
              let arr: any[] = [];
              if (this.state.selectedZero !== null) {
                arr.push(1);
              } else {
                arr.push(0);
              }
              if (this.state.selectedOne !== null) {
                arr.push(1);
              } else {
                arr.push(0);
              }
              if (this.state.selectedTwo !== null) {
                arr.push(1);
              } else {
                arr.push(0);
              }
              if (this.state.selectedThree !== null) {
                arr.push(1);
              } else {
                arr.push(0);
              }
              if (this.state.selectedFour !== null) {
                arr.push(1);
              } else {
                arr.push(0);
              }
              if (this.state.selectedFive !== null) {
                arr.push(1);
              } else {
                arr.push(0);
              }

              this.props.onContinue(arr);
              this.setState({
                selectedZero: null,
                selectedOne: null,
                selectedTwo: null,
                selectedThree: null,
                selectedFour: null,
                selectedFive: null,
              });
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  buttonContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
  },
  cellBase: {
    height: 88,
    width: 159,
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 8,
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "center",
  },
  cellText: {
    flex: 1,
    textAlign: "center",
    paddingHorizontal: 20,
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.font_cera_regular),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  cellUnselected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: Platform.OS === "ios" ? 8 : 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: COLOR_TRUE_WHITE,
    borderColor: COLOR_MUTED,
    borderWidth: 1,
  },
  cellSelected: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY,
  },
  cellNeutral: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 8,
    backgroundColor: COLOR_PRIMARY_LIGHT,
  },
  container: {
    height: 12,
    width: "65%",
    backgroundColor: "#ecf0f1",
    borderRadius: 50,
    marginTop: 6,
  },
  progressBar: {
    height: 12,
    width: "100%",
    borderRadius: 50,
  },
  containerCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
