import { NativeEventEmitter, NativeModules, Platform } from "react-native";
import { connect } from "react-redux";

type PurchaseParams = {
  tier;
  location;
  lessonInfo;
  selectedTutor;
  onComplete: (success: boolean) => void;
};

function mapStateToProps(state) {
  return {
    selectedTutor: state.selRed.selectedTutor,
    priceInfos: state.selRed.priceInfos,
    lessonInfo: state.selRed.lessonInfo,
  };
}

function mapDispatchToProps() {
  return {};
}

export function PurchaseManager(params: PurchaseParams) {
  if (Platform.OS === "ios") {
    const paymentEmitter = new NativeEventEmitter(NativeModules.EventsEmitter);
    let paymentSuccess = paymentEmitter.addListener("Purchase", (event) => {
      params.onComplete(true);
    });
    NativeModules.StoreKitBridge.buyProduct(params.tier, params.location);
  } else if (Platform.OS === "android") {
    const onConsumeFinished = (event) => {
      console.log("onConsumeFinished", event);
      params.onComplete(true);
    };
    const eventEmitter = new NativeEventEmitter(NativeModules.EventsEmitter);
    eventEmitter.addListener("onConsumeFinished", onConsumeFinished);
    NativeModules.TutoreBilling.buyProduct(
      (err) => {
        console.log(err);
      },
      (msg) => {
        console.log(msg);
      }
    );
  }
  //paymentSuccess.remove();
}
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseManager);
