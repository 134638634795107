import React, { Component } from "react";
import { StyleSheet, Text, View, TouchableHighlight } from "react-native";
import StyleUtils, {
  COLOR_LIGHT_NOTIFICATION_UNSEEN,
  COLOR_MUTED,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../Utils/StyleUtils";
import { UserImage } from "../Elements/UserImages";
import {
  NotificationType,
  TutoreNotification,
} from "../Models/TutoreNotification";
import { dateDiffText, userShortName } from "../Utils/Utils";

type Props = {
  notificationInfo: TutoreNotification;
  onPress;
};

export default class NotificationCard extends Component<Props, any> {
  renderNotificationImage(): React.ReactNode {
    return (
      <View style={styles.imageHolder}>
        <UserImage
          style={styles.image}
          imageURL={
            this.props.notificationInfo.sender
              ? this.props.notificationInfo.sender.imageURL
              : undefined
          }
        />
        {!this.props.notificationInfo.seen && (
          <View style={styles.notificationBadge} />
        )}
      </View>
    );
  }

  renderNotificationText(): React.ReactNode {
    let nameString = "";
    if (
      this.props.notificationInfo.sender &&
      this.props.notificationInfo.sender.name
    ) {
      nameString += userShortName(this.props.notificationInfo.sender.name);
    }

    const today = new Date();
    const notificationDate = new Date(this.props.notificationInfo.createdAt);
    console.log(notificationDate);
    const dateText = dateDiffText(notificationDate, today);

    switch (this.props.notificationInfo.notificationType) {
      case NotificationType.HOUR_BEFORE_CLASS: {
        return (
          <View style={styles.textView}>
            <Text style={styles.textBold}>Sua aula começa em 1 hora!</Text>
            <Text style={styles.textRegular}>
              Que tal mandar uma mensagem antes da aula?
            </Text>
          </View>
        );
      }

      case NotificationType.TEN_MINUTES_BEFORE_CLASS: {
        return (
          <View style={styles.textView}>
            <Text style={styles.textBold}>Sua aula começa em 10 minutos!</Text>
            <Text style={styles.textRegular}>
              Que tal mandar uma mensagem antes da aula?
            </Text>
          </View>
        );
      }

      case NotificationType.MINUTE_BEFORE_CLASS: {
        return (
          <View style={styles.textView}>
            <Text style={styles.textBold}>Sua aula começa agora!</Text>
            <Text style={styles.textRegular}>
              Entre para iniciar sua aula com {nameString}
            </Text>
          </View>
        );
      }

      case NotificationType.MESSAGE_SENT: {
        return (
          <View style={styles.textView}>
            <Text style={styles.textRegular}>
              <Text style={styles.textBold}>{nameString + " "}</Text>
              enviou novas mensagens.
            </Text>
            <Text style={styles.textLight}>{dateText}</Text>
          </View>
        );
      }

      case NotificationType.HOMEWORK_ASSIGNED:
        return (
          <View style={styles.textView}>
            <Text style={styles.textRegular}>
              <Text style={styles.textBold}>{nameString + " "}</Text>
              enviou uma nova lição.
            </Text>
            <Text style={styles.textLight}>{dateText}</Text>
          </View>
        );

      case NotificationType.HOMEWORK_INACTIVITY:
        return (
          <View style={styles.textView}>
            <Text style={styles.textBold}>Você possui lições pendentes</Text>
            <Text style={styles.textRegular}>
              {nameString} está aguardando sua resolução dos exercícios.
            </Text>
          </View>
        );

      case NotificationType.HOMEWORK_INACTIVITY_DEADLINE:
        return (
          <View style={styles.textView}>
            <Text style={styles.textBold}>Você possui lições pendentes</Text>
            <Text style={styles.textRegular}>
              Responda antes de sua próxima aula com {nameString}
            </Text>
          </View>
        );

      case NotificationType.HOMEWORK_FINISHED:
        return (
          <View style={styles.textView}>
            <Text style={styles.textRegular}>
              <Text style={styles.textBold}>{nameString + " "}</Text>
              resolveu sua lição.
            </Text>
            <Text style={styles.textLight}>{dateText}</Text>
          </View>
        );

      case NotificationType.HOMEWORK_CORRECTION_AVAILABLE:
        return (
          <View style={styles.textView}>
            <Text style={styles.textRegular}>
              <Text style={styles.textBold}>{nameString + " "}</Text>
              corrigiu sua lição.
            </Text>
            <Text style={styles.textLight}>{dateText}</Text>
          </View>
        );

      default:
      case NotificationType.TUTEE_CHURN_7_DAYS:
      case NotificationType.TUTOR_CHURN_7_DAYS:
        return (
          <View style={styles.textView}>
            <Text style={styles.textRegular}>
              Bem-vindo de volta! Sentimos sua falta :)
            </Text>
          </View>
        );
    }
  }

  render() {
    return (
      <TouchableHighlight
        onPress={() =>
          this.props.onPress(this.props.notificationInfo.notificationType)
        }
      >
        <View
          style={[
            styles.cardBase,
            this.props.notificationInfo.seen && styles.cardSeen,
            !this.props.notificationInfo.seen && styles.cardUnseen,
          ]}
        >
          {this.renderNotificationImage()}
          <View style={{ flex: 1, marginRight: "5%" }}>
            {this.renderNotificationText()}
          </View>
          <View style={styles.cardSeparator} />
        </View>
      </TouchableHighlight>
    );
  }
}

const styles = StyleSheet.create({
  cardBase: {
    height: 108,
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
  },
  cardUnseen: {
    backgroundColor: COLOR_LIGHT_NOTIFICATION_UNSEEN,
  },
  cardSeen: {
    backgroundColor: COLOR_WHITE,
  },
  cardSeparator: {
    backgroundColor: COLOR_MUTED,
    height: 1,
    position: "absolute",
    bottom: 0,
    width: "90%",
    marginHorizontal: "5%",
  },
  imageHolder: {
    marginLeft: 26,
    marginRight: 26,
    width: 50,
    height: 50,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 6,
  },
  notificationBadge: {
    backgroundColor: COLOR_PRIMARY,
    height: 10,
    width: 10,
    borderRadius: 1000,
    position: "absolute",
    top: -4,
    left: -4,
  },
  textView: {},
  textBold: {
    ...StyleSheet.flatten(StyleUtils.font_btn_bold),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  textRegular: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    ...StyleSheet.flatten(StyleUtils.color_txt_dark),
  },
  textLight: {
    ...StyleSheet.flatten(StyleUtils.font_btn_regular),
    ...StyleSheet.flatten(StyleUtils.font_size_14),
    ...StyleSheet.flatten(StyleUtils.color_txt_light),
  },
});
