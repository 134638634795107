import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

type Props = {
  source;
  style?: StyleProp<ViewStyle>;
  autoPlay?: boolean;
  onPlaying?: () => void;
  onEnded?: () => void;
};

export default function VideoComponent(props: Props) {
  const autoPlay = props.autoPlay === false ? false : true;

  return (
    <View style={props.style}>
      <video
        src={props.source}
        style={{ height: "100%", width: "100%" }}
        autoPlay={autoPlay}
        onError={props.onEnded}
        onEnded={props.onEnded}
        onPlaying={props.onPlaying}
      />
    </View>
  );
}
