import React, { useState } from "react";
import { Text, View, Image, TextInput } from "react-native";
import StyleUtils, {
  COLOR_BG_DARK,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import User from "../Models/User";
import ButtonElement from "../Elements/ButtonElement";

type Props = {
  user: User;
  onPress?: (exerciseTitle) => void;
};

const AssignHomeworkConfirmation = (props: Props) => {
  const [exerciseTitle, setExerciseTitle] = useState("");

  return (
    <View style={[{ justifyContent: "center", alignItems: "center" }]}>
      <View
        style={{
          height: 148,
          width: 148,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={
            props.user.imageURL
              ? { uri: props.user.imageURL }
              : require("../../assets/userPlaceHolder.png")
          }
          style={{
            width: 104,
            height: 104,
            borderRadius: 52,
            overflow: "hidden",
            position: "absolute",
          }}
        />
      </View>
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_16,
        ]}
      >
        {props.user.name}
      </Text>
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_16,
          {
            marginTop: 32,
            marginBottom: 8,
          },
        ]}
      >
        Dê um título para sua lição!
      </Text>
      <TextInput
        style={[
          StyleUtils.border_color_btn_muted,
          StyleUtils.font_size_18,
          StyleUtils.font_btn_regular,
          StyleUtils.color_txt_normal,
          {
            borderRadius: 10,
            height: 48,
            padding: 8,
            borderWidth: 2,
            borderColor: COLOR_BG_DARK,
            backgroundColor: COLOR_TRUE_WHITE,
            marginBottom: 24,
          },
        ]}
        onChangeText={(text) => setExerciseTitle(text)}
        maxLength={18}
      />
      <Text
        style={[
          StyleUtils.font_btn_bold,
          StyleUtils.color_txt_dark,
          StyleUtils.font_size_20,
        ]}
      >
        Enviar exercícios?
      </Text>
      <ButtonElement
        text="Confirmar envio"
        colorScheme="sucess"
        onPress={() => {
          if (props.onPress) props.onPress(exerciseTitle);
        }}
      />
    </View>
  );
};

export default AssignHomeworkConfirmation;
