import React, { Component } from "react";
import { Image } from "react-native";

type Props = {
  source;
  style;
};

export default class AsyncImage extends Component<Props, any> {
  render() {
    return <Image source={this.props.source} style={this.props.style} />;
  }
}
