import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
} from "react-native";
import StyleUtils, {
  COLOR_PRIMARY,
  COLOR_TRUE_WHITE,
} from "../Utils/StyleUtils";
import SubjectTestBackground from "../../assets/subjectTestBackground.svg";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  onBack?;
  headerText;
  subHeaderText;
  buttonText;
  partNumber;
};

export default function VTParts(props: Props) {
  return (
    <LinearGradient
      colors={["#272B3E", "#005EC8"]}
      style={[
        StyleUtils.screen_default,
        {
          paddingVertical: 40,
        },
      ]}
    >
      <SVGImage
        source={<SubjectTestBackground />}
        style={{ alignItems: "center", justifyContent: "center", zIndex: 1 }}
      />
      <View
        style={{
          borderRadius: 270,
          backgroundColor: COLOR_PRIMARY,
          left: -124,
          width: 244 + Dimensions.get("window").width,
          height: 750,
          zIndex: 0,
          position: "absolute",
          top: 201,
        }}
      />
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.font_size_28,
            StyleUtils.color_txt_white,
            { textAlign: "center" },
          ]}
        >
          {props.headerText}
        </Text>
        <View
          style={{
            borderRadius: 40,
            backgroundColor: COLOR_TRUE_WHITE,
            width: 116,
            marginVertical: 8,
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.font_size_16,
              StyleUtils.color_txt_primary,
              { textAlign: "center", top: Platform.OS === "ios" ? 3 : 0 },
            ]}
          >
            Parte {props.partNumber} de 3
          </Text>
        </View>
        <Text
          style={[
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_18,
            StyleUtils.color_txt_white,
            { textAlign: "center" },
          ]}
        >
          {props.subHeaderText}
        </Text>
        <TouchableOpacity
          style={[
            StyleUtils.shape_btn_small,
            StyleUtils.color_bg_white,
            { marginTop: 16, width: 327 },
          ]}
          onPress={() => {
            analytics.sendStartVocationalTest();
            props.onContinue();
          }}
        >
          <Text
            style={[
              StyleUtils.font_cera_bold,
              StyleUtils.color_txt_dark,
              StyleUtils.font_size_16,
              { padding: 14, textAlign: "center" },
            ]}
          >
            {props.buttonText}
          </Text>
        </TouchableOpacity>
        {props.partNumber === 1 && (
          <TouchableOpacity
            style={{}}
            onPress={() => {
              props.onBack();
            }}
          >
            <Text
              style={[
                StyleUtils.color_txt_white,
                StyleUtils.font_btn_regular,
                StyleUtils.font_size_16,
                {
                  textDecorationLine: "underline",
                  marginTop: 10,
                },
              ]}
            >
              Voltar
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </LinearGradient>
  );
}
