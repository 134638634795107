import React from "react";
import { Text, TouchableOpacity, StyleSheet } from "react-native";
import StyleUtils, { COLOR_DANGER } from "../Utils/StyleUtils";

import ButtonElement from "../Elements/ButtonElement";
import AnimatedPopUp from "../SignUp/AnimatedPopUp";

export const RescheduleClassPopup = ({
  show,
  close,
  onClose,
  onReturn,
  onAlert,
  classIn24Hours,
}) => {
  return (
    <AnimatedPopUp show={show} close={close} bgColor={COLOR_DANGER}>
      <Text
        style={[
          {
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_bold,
          StyleUtils.font_size_20,
          StyleUtils.color_txt_white,
        ]}
      >
        {"Tem certeza que você\nremarcar esta aula?"}
      </Text>
      <Text
        style={[
          {
            marginTop: 24,
            alignSelf: "center",
            textAlign: "center",
          },
          StyleUtils.font_cera_regular,
          StyleUtils.font_size_16,
          StyleUtils.color_txt_white,
        ]}
      >
        {"Seu professor será notificado após\nvocê escolher o novo horário"}
      </Text>
      <ButtonElement
        colorScheme="danger_white"
        text="Não desejo remarcar"
        onPress={onReturn}
        textStyle={style.popupButtonText}
        styte={[style.popupButton, { marginTop: 32 }]}
      />
      <TouchableOpacity
        style={[{ width: 252, marginTop: 24 }]}
        onPress={classIn24Hours ? onAlert : onClose}
      >
        <Text
          style={[
            StyleUtils.color_txt_white,
            StyleUtils.font_cera_regular,
            StyleUtils.font_size_16,
            { textAlign: "center", textDecorationLine: "underline" },
          ]}
        >
          Sim, quero remarcar
        </Text>
      </TouchableOpacity>
    </AnimatedPopUp>
  );
};

const style = StyleSheet.create({
  popupButton: {
    ...StyleSheet.flatten(StyleUtils.shape_btn_small),
    alignSelf: "stretch",
    margin: undefined,
  },
  popupButtonText: {
    ...StyleSheet.flatten(StyleUtils.font_size_16),
    padding: undefined,
    paddingVertical: 14,
    paddingHorizontal: undefined,
  },
});
