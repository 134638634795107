import React, { useEffect, useState } from "react";
import { View, Text, Dimensions, Image } from "react-native";
import StyleUtils, { COLOR_TRUE_WHITE } from "../Utils/StyleUtils";
import SVGImage from "../Utils/SVGImage";
import LinearGradient from "react-native-linear-gradient";
import { analytics } from "../Analytics";

type Props = {
  onContinue;
  index;
};

export default function StudyPlanWaitResult(props: Props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    analytics.sendShowWaitResult(props.index);
    Dimensions.addEventListener("change", _handleAppStateChange);
    setTimeout(() => {
      props.onContinue();
    }, 3000);
    return () => {
      Dimensions.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    setWindowWidth(nextAppState.window.width);
  };

  return (
    <View style={[StyleUtils.screen_default]}>
      <LinearGradient
        colors={["#011F42", "#016EE8"]}
        style={[
          {
            paddingVertical: 100,
          },
        ]}
      />
      <View
        style={{
          width: windowWidth,
          height: windowWidth,
          borderRadius: windowWidth / 2,
          backgroundColor: COLOR_TRUE_WHITE,
          transform: [{ scaleX: 2 }],
          marginTop: 80,
          position: "absolute",
        }}
      />
      <View
        style={{
          height: 200,
          position: "absolute",
          top: 130,
          width: 327,
          alignSelf: "center",
          zIndex: 1,
        }}
      >
        <Image
          source={require("../../assets/studyPlanResult.gif")}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </View>
      <View
        style={{
          alignItems: "center",
          marginHorizontal: 20,
        }}
      >
        <Text
          style={[
            StyleUtils.font_cera_bold,
            StyleUtils.color_txt_dark,
            {
              textAlign: "center",
              fontSize: windowWidth < 500 ? 20 : 38,
              marginTop: 170,
            },
          ]}
        >
          Ok, preparado para ver{"\n"}seu plano personalizado?
        </Text>
      </View>
    </View>
  );
}
